import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { TextField } from "@mui/material";

import {
  createCostEntry,
  deleteCostEntry,
  updateCostEntry,
} from "../../../redux/slices/planningTool/rfqSlice";
import { moneyAdornment, moneyValidation } from "../../../utility/inputHelpers";
import { formatMoneyString } from "../../../utility/utilityFunctions";
import CrudTable from "../../CrudTable";

const CostTable = ({ type }) => {
  const dispatch = useDispatch();
  const { id, costs: allCosts } = useSelector(
    (state) => state.rfq.currentQuote
  );
  const costs = allCosts.filter((cost) => cost.costType === type);
  const handleCreate = async (data) => {
    await dispatch(createCostEntry(id, type, data));
  };
  const handleDelete = async (id) => await dispatch(deleteCostEntry(id));

  const handleUpdate = async (id, data) =>
    await dispatch(updateCostEntry(id, data));

  const crudActions = {
    handleCreate,
    handleDelete,
    handleUpdate,
  };
  return (
    <CrudTable
      uniqueKey={type}
      columns={[
        {
          id: "name",
          label: "Cost Name",
          checks: { required: true },
        },
        {
          id: "amount",
          label: "Cost Amount",
          align: "right",
          checks: { required: true, ...moneyValidation },
          transform: formatMoneyString,
          renderInput: React.forwardRef((props, ref) => (
            <TextField
              variant="outlined"
              InputProps={moneyAdornment}
              ref={ref}
              {...props}
            />
          )),
        },
      ]}
      data={costs}
      {...crudActions}
    />
  );
};

export default CostTable;
