import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import Typography from "@mui/material/Typography";

import { addToFavoriteItems } from "../../redux/slices/users/userSlice";
import ItemTable from "./ItemTable";

const FavoriteItems = ({ classes }) => {
  const dispatch = useDispatch();
  const userItems = useSelector((state) => state.user.favoriteItems);
  const isLoading = useSelector((state) => state.user.isUpdateLoading);

  const handleDelete = (id) => {
    const newIdArray = userItems
      .map((item) => item.id)
      .filter((itemId) => itemId !== id);
    dispatch(addToFavoriteItems(newIdArray));
  };

  return (
    <>
      <div
        style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
      >
        <Typography
          className={classes.bodyText}
          component={Link}
          to="/items/all"
        >
          Add Items
        </Typography>
      </div>
      <br />
      <ItemTable
        items={userItems}
        isLoading={isLoading}
        handleDelete={handleDelete}
      />
    </>
  );
};

export default FavoriteItems;
