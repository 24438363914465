/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";

import {
  CheckRounded,
  CloseRounded,
  DownloadRounded,
  SummarizeRounded,
  WarningRounded,
} from "@mui/icons-material";
import { CircularProgress, IconButton, Tooltip } from "@mui/material";

import { useInfiniteQuery } from "@tanstack/react-query";
import { saveAs } from "file-saver";
import { StyledButton } from "src/components/StyledComponents";

import client from "@services/api";

import { setReportStatus } from "./globalReportSlice";
import { RStatus, Report, TReportState } from "./types";

type SingleReportProps = {
  report: TReportState;
  reportDefinition: Report;
};

const SingleReport = ({ report, reportDefinition }: SingleReportProps) => {
  const fullFileName = report.filename + "." + reportDefinition.fileExtension;
  const dispatch = useDispatch();
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [reportData, setReportData] = useState<null | any[]>(null);

  const {
    hasNextPage,
    fetchNextPage,
    data: queryData,
    error,
  } = useInfiniteQuery({
    queryKey: ["reports", report.id],
    queryFn: (fnParams) => client.get<any[]>(fnParams.pageParam ?? report.url),
    getNextPageParam: (lastPage) => lastPage?.links?.next?.replace("/api/", ""),
    initialPageParam: null as null | string,
    staleTime: Infinity,
  });

  const data = useMemo(
    () => queryData?.pages.flatMap((d) => d.data) ?? [],
    [queryData]
  );
  const totalEntries = queryData?.pages[0].meta?.total_entries ?? 0;
  const percentLoaded = totalEntries ? data.length / totalEntries : 0;

  const setStatus = (status: RStatus) =>
    dispatch(setReportStatus({ id: report.id, status }));

  const handleDownload = async () => {
    setDownloadLoading(true);
    const blob = await reportDefinition.onDownload(reportData);
    saveAs(blob, fullFileName);
    setStatus("DOWNLOADED");
    setDownloadLoading(false);
  };

  useEffect(() => {
    if (data.length === 0 || report.status !== "LOADING") return;
    if (hasNextPage) {
      fetchNextPage();
    } else {
      setStatus("COMPLETE");
      setReportData(
        reportDefinition.onComplete ? reportDefinition.onComplete(data) : data
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.length, hasNextPage, report.status, reportDefinition]);

  useEffect(() => {
    if (error) setStatus("FAILED");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return (
    <div css={report.status === "ABORTED" && tw`opacity-50`}>
      <div tw="flex gap-4 py-3 items-start justify-between">
        <div tw="flex gap-2 items-start overflow-hidden">
          <SummarizeRounded tw="text-blue-700" />
          <div tw="text-neutral-600 leading-tight" title={fullFileName}>
            {fullFileName}
          </div>
        </div>
        <div tw="flex items-center">
          {report.status === "LOADING" && (
            <>
              <div tw="bg-neutral-100 rounded-full py-1 px-3 flex items-center gap-2 text-sm font-medium text-neutral-600">
                <div>{Math.ceil(percentLoaded * 100)}%</div>
                <CircularProgress size={16} />
              </div>

              <Tooltip title="Cancel">
                <IconButton size="small" onClick={() => setStatus("ABORTED")}>
                  <CloseRounded tw="text-base" />
                </IconButton>
              </Tooltip>
            </>
          )}
          {(report.status === "COMPLETE" || report.status === "DOWNLOADED") && (
            <StyledButton
              outlined
              endIcon={
                report.status === "COMPLETE" ? (
                  <DownloadRounded />
                ) : (
                  <CheckRounded />
                )
              }
              tw="rounded-full text-xs mb-1 py-1 px-3"
              onClick={handleDownload}
              loading={downloadLoading}
            >
              {report.status === "COMPLETE" ? "Download" : "Downloaded"}
            </StyledButton>
          )}
          {report.status === "FAILED" && (
            <div tw="flex gap-1 items-center px-3">
              <WarningRounded tw="text-red-600 text-base" />
              <div tw="text-red-900 text-sm">ERROR</div>
            </div>
          )}
          {report.status === "ABORTED" && (
            <div tw="px-3">
              <div tw="text-neutral-700 text-sm">ABORTED</div>
            </div>
          )}
        </div>
      </div>
      {report.status === "FAILED" && (
        <div tw="bg-red-50 text-sm text-red-900 p-4 whitespace-pre-wrap">
          {error?.message}
        </div>
      )}
    </div>
  );
};

export default SingleReport;
