/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { useDispatch, useSelector } from "react-redux";

import GetAppIcon from "@mui/icons-material/GetApp";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";

import PropTypes from "prop-types";
import { useNoFetch } from "src/hooks/UtilityHooks";
import { resetFilters, setFiltersOpen } from "src/redux/slices/filterSlice";

import { CSVLink } from "@utils/csv";

import { useReportCsv } from "../../hooks/Reports/useReportCsv";
import { clearReports } from "../../redux/slices/reports/reportSlice";
import BackButton from "./BackButton";
import ReportTable from "./ReportTable";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
  ...theme.reports,
}));

const ReduxReportGenerator = ({
  reportTitle,
  fileName,
  reportGetFunc,
  reportResetFunc,
  children,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [hasGenerated, setHasGenerated] = useState(false);

  const isLoading = useSelector((state) => state.reports.isLoading);
  const report = useSelector((state) => state.reports.reportData);
  const reportType = useSelector((state) => state.reports.reportType);

  const { setCsvData, data, headers } = useReportCsv(
    reportType,
    hasGenerated,
    setHasGenerated,
    isLoading,
    report
  );

  const handleReset = () => {
    reportResetFunc();
    dispatch(resetFilters());
    dispatch(clearReports());
    setCsvData({ data: [], headers: [] });
  };

  const handleGetReport = () => {
    reportGetFunc();
    setHasGenerated(true);
  };

  useEffect(() => {
    dispatch(setFiltersOpen({ open: false }));
    dispatch(clearReports());
    dispatch(resetFilters());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useNoFetch(dispatch);

  return (
    <>
      <Helmet>
        <title>RTA | {reportTitle}</title>
      </Helmet>
      <Container className={classes.mainWrapper}>
        <div className={classes.titleBar}>
          <Typography className={classes.titleText}>{reportTitle}</Typography>
          <div className={classes.configButtons}>
            <div className={classes.innerConfigDiv}>
              <div tw="mr-2">
                <BackButton />
              </div>
              <CSVLink data={data} headers={headers} filename={fileName}>
                <Button
                  className={classes.largeButton}
                  variant="contained"
                  color="secondary"
                  startIcon={<GetAppIcon />}
                  disabled={data.length === 0}
                >
                  EXPORT REPORT
                </Button>
              </CSVLink>
            </div>
          </div>
        </div>
        <br />
        {children}
        <br />
        <div className={classes.buttonRow}>
          <Button
            className={classes.largeButton}
            variant="contained"
            color="secondary"
            onClick={() => handleGetReport()}
            style={{ marginRight: "20px" }}
          >
            GENERATE REPORT
          </Button>
          <Button
            className={classes.largeButton}
            variant="contained"
            color="secondary"
            onClick={handleReset}
          >
            CLEAR FILTERS
          </Button>
        </div>
        <br />
        <br />
        {isLoading && (
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <CircularProgress size={100} style={{ marginTop: "50px" }} />
          </div>
        )}
        {!isLoading && report.length > 0 && reportType && (
          <ReportTable report={report} type={reportType} />
        )}
      </Container>
    </>
  );
};

ReduxReportGenerator.propTypes = {
  reportTitle: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired,
  reportGetFunc: PropTypes.func.isRequired,
  reportResetFunc: PropTypes.func.isRequired,
  children: PropTypes.object.isRequired,
};

export default ReduxReportGenerator;
