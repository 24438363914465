import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import CancelIcon from "@mui/icons-material/Cancel";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import PropTypes from "prop-types";

import {
  setUpdateSuccess,
  updateBrandById,
} from "../../redux/slices/items/brandSlice";

const BrandGroupCodeSelector = React.memo(
  ({ handleBrandGroupCode, brandGroupCodes, currentCode }) => {
    return (
      <FormControl
        variant="outlined"
        size="small"
        fullWidth
        style={{ margin: 10 }}
      >
        <InputLabel id="brand-group-code-selector-label">
          Brand Group Code
        </InputLabel>
        <Select
          variant="outlined"
          labelId="brand-group-code-selector-label"
          id="brand-group-code-selector"
          value={currentCode}
          onChange={(evt) => handleBrandGroupCode(evt.target.value)}
        >
          {brandGroupCodes.map((code) => (
            <MenuItem value={code.id} key={code.id}>
              {code.code}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }
);

const useStyles = makeStyles((theme) => ({
  ...theme.global,
}));

const BrandModal = ({ open, handleClose, id, brandList }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [currentBrandGroup, setCurrentBrandGroup] = useState("");
  const [currentBrand, setCurrentBrand] = useState(null);
  const [updateError, setUpdateError] = useState(null);

  const {
    isAssetsLoading,
    isUpdateLoading,
    productFamilyList,
    updateStatus,
    error,
  } = useSelector((state) => state.brands);

  const handleSubmit = () => {
    if (currentBrandGroup.length === 0) {
      setUpdateError("You must complete form before submitting");
    } else {
      dispatch(updateBrandById(id, currentBrandGroup));
    }
  };

  useEffect(() => {
    if (!isUpdateLoading && (!currentBrand || currentBrand.id !== id)) {
      let brand = brandList.find((b) => b.id === id);
      if (brand) {
        setCurrentBrand(brand);
        setCurrentBrandGroup(brand.productFamilyId);
      }
    }
  }, [
    brandList,
    isUpdateLoading,
    updateStatus,
    currentBrand,
    id,
    setCurrentBrand,
    setCurrentBrandGroup,
  ]);

  useEffect(() => {
    dispatch(setUpdateSuccess({ updateStatus: false }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (error) {
      setUpdateError(error);
    }
  }, [error]);

  return (
    <div className={classes.relativeContainer}>
      <Dialog
        open={open}
        onClose={() => {
          setCurrentBrandGroup("");
          handleClose();
        }}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          <Typography className={classes.headerText}>
            Editing {currentBrand?.name ?? ""}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <IconButton
            className={classes.closeButton}
            onClick={() => {
              setCurrentBrandGroup("");
              handleClose();
            }}
            size="large"
          >
            <CancelIcon fontSize="large" color="secondary" />
          </IconButton>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {isAssetsLoading && <CircularProgress />}
            {!isAssetsLoading && (
              <BrandGroupCodeSelector
                classes={classes}
                handleBrandGroupCode={setCurrentBrandGroup}
                brandGroupCodes={productFamilyList}
                currentCode={currentBrandGroup}
              />
            )}
            <Button
              className={classes.largeButton}
              variant="contained"
              color="secondary"
              id="profile"
              onClick={handleSubmit}
              disabled={isAssetsLoading}
              style={{
                float: "right",
                marginBottom: "20px",
                minWidth: "62px",
              }}
            >
              {isUpdateLoading ? <CircularProgress /> : "SUBMIT"}
            </Button>
            {!isUpdateLoading && updateError && (
              <Typography
                className={classes.bodyText}
                style={{ color: "#920000" }}
              >
                {updateError}
              </Typography>
            )}
            {!isUpdateLoading && updateStatus && (
              <Typography className={classes.bodyText}>
                Update Successful!
              </Typography>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

BrandModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  id: PropTypes.string,
  brandList: PropTypes.array,
};

export default React.memo(BrandModal);
