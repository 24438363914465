/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { Add, Close, Remove } from "@mui/icons-material";
import { CircularProgress, Tooltip } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

import { BeaconIcon } from "src/components/StyledComponents";
import { mapSpecifications } from "src/redux/mapHelpers";
import { setModalItemId } from "src/redux/slices/items/itemSlice";

import { CldImage } from "@services/cloudinary";

import Carousel from "../../components/Carousel";
import { PLANNING_TOOL_ROLES } from "../../constants/permissions";
import {
  formatDateString,
  formatMoneyString,
} from "../../utility/utilityFunctions";
import { useItemQuery } from "./queries";

const Tag = tw.div`px-3 py-2 text-sm rounded box-border bg-neutral-50 text-neutral-700 border border-neutral-300`;

const Title = tw.div`uppercase text-sm text-neutral-900 tracking-wider mb-2`;

const LabelValuePair = ({ label, value }) => (
  <div tw="flex px-2 py-1 rounded transition border border-transparent hover:(bg-neutral-50 border-neutral-300)">
    <div tw="flex-none mr-2 w-48 text-neutral-600 font-light">{label}:</div>
    <span>{value}</span>
  </div>
);

const ItemModal = ({
  open,
  itemId,
  handleClose,
}: {
  open: boolean;
  itemId: string;
  handleClose: () => void;
}) => {
  const { data: item } = useItemQuery(itemId);
  const [showAllPrograms, setShowAllPrograms] = useState(false);

  const [isFullSpec, setIsFullSpec] = useState(false);

  const currentUserRole = useSelector((state: any) => state.user.role);

  const specifications = item
    ? mapSpecifications(item.specification, "item-display")
    : [];
  const fullSpecifications = item
    ? mapSpecifications(item.specification, "supplier-display")
    : [];

  const currentSpec = isFullSpec ? fullSpecifications : specifications;

  let mappedSpec =
    currentSpec[0]?.type === "websiteDesc"
      ? Object.entries(currentSpec[0].value).map(([key, value]) => ({
          key,
          value,
        }))
      : currentSpec;

  mappedSpec = mappedSpec.filter(({ key }) => key !== "MOQ" && key !== "MOQ:");

  const amountAvailable = !item?.warehouse
    ? "Currently Unavailable"
    : (item.cachedWarehouseQty ?? 0) <= 0
    ? "Out of Stock"
    : item.cachedWarehouseQty;

  const orderType =
    item?.itemStatus === "Ok"
      ? {
          "pre-order": "Pre Order",
          inventory: "In Stock",
          "on-demand": "On Demand",
        }[item.itemOrderType]
      : item?.itemStatus;

  const info = item
    ? {
        Type: item.itemTypeName,
        Description: item.comment,
        ...(item.itemOrderType && { "Order Status": orderType }),
        MOQ: item.mostRecentMoq ?? "---",
        "Pack Size": item.qtyPerPack,
        ...(item.itemOrderType === "from-inventory" && {
          "Amount available": amountAvailable,
        }),
        ...(item.removeFromCatalogDate && {
          "Available to order until": formatDateString(
            item.removeFromCatalogDate
          ),
        }),
        "Has Beacon": item.includeBeacon ? (
          <div tw="flex gap-1 items-center">
            Yes <BeaconIcon />
          </div>
        ) : (
          "No"
        ),
        ...(item.includeBeacon && {
          "Beacon Cost": formatMoneyString(item.mostRecentBeaconCost),
        }),
      }
    : {};

  const hasFullSpecsPermissions = [
    "super",
    "purchaser",
    "select-purchaser",
  ].includes(currentUserRole);

  const hasProgramPermissions = PLANNING_TOOL_ROLES.includes(currentUserRole);

  const handleModalClose = () => {
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleModalClose}
      disableScrollLock
      fullWidth
      scroll="paper"
      maxWidth="lg"
      style={{ zIndex: "15000" }}
    >
      {!item && (
        <DialogContent tw="flex justify-center py-12">
          <CircularProgress />
        </DialogContent>
      )}
      {item && (
        <DialogContent tw="grid lg:grid-cols-2 gap-4 p-0">
          <IconButton
            tw="absolute top-1 right-1 z-10"
            onClick={handleModalClose}
            size="large"
          >
            <Close color="secondary" />
          </IconButton>
          <div tw="flex items-center justify-center p-4">
            <Carousel
              startIndex={0}
              imageIdsOrUrls={item.images.map((img) => img.cloudinaryId)}
            >
              {item.images
                .filter((img) => img.type === "large")
                ?.map((img) => (
                  <div key={img.id}>
                    <CldImage
                      publicId={img.cloudinaryId}
                      width="100%"
                      height="100%"
                      tw="object-contain"
                    />
                  </div>
                ))}
            </Carousel>
          </div>
          <div tw="lg:max-h-full lg:overflow-y-auto text-base text-neutral-800">
            <Typography
              component="div"
              tw="max-w-prose my-12 p-4 mx-auto lg:mx-0"
            >
              <div tw="text-neutral-500"># {item.itemNumber}</div>
              <h1 tw="text-3xl mt-2">{item.comment ?? item.itemTypeName}</h1>
              <div tw="mt-3 flex items-center text-neutral-400">
                <div tw="font-bold tracking-wider text-neutral-600">
                  {formatMoneyString(item.mostRecentEstimatedCost)}
                </div>
                &nbsp; / &nbsp;
                <div tw="">
                  {item.isInventory
                    ? `${Number(item.cachedWarehouseQty)} in stock`
                    : item.itemOrderType}
                </div>
              </div>

              <Title tw="mt-6">{`Brand${
                item.brands.length !== 1 ? "s" : ""
              }`}</Title>
              <div tw="flex flex-wrap items-start gap-2">
                {item.brands.map((brand) => (
                  <Tag key={brand.id}>{brand.name}</Tag>
                ))}
              </div>

              <div tw="flex justify-between items-center">
                <Title tw="mt-6">{`Program${
                  item.programs.length !== 1 ? "s" : ""
                }`}</Title>
                {item.programs.length > 2 && (
                  <IconButton
                    onClick={() => setShowAllPrograms(!showAllPrograms)}
                  >
                    {showAllPrograms ? <Remove /> : <Add />}
                  </IconButton>
                )}
              </div>

              <div
                css={
                  showAllPrograms
                    ? tw`flex flex-wrap items-start gap-2`
                    : tw`relative flex items-start gap-2 overflow-x-hidden
                    after:(absolute inset-0 left-auto w-16 bg-gradient-to-r from-transparent via-white to-white pointer-events-none)`
                }
              >
                {item.programs.map((program) => (
                  <Link
                    key={program.id}
                    to={
                      hasProgramPermissions
                        ? `/planning/program/${program.id}`
                        : ""
                    }
                    tw="block whitespace-nowrap"
                    target={"_blank"}
                  >
                    <Tooltip
                      title={
                        +program.id === item.mostRecentProgramId
                          ? "Pricing Reference Program"
                          : ""
                      }
                      componentsProps={{ popper: { style: { zIndex: 15001 } } }}
                    >
                      <Tag
                        css={
                          +program.id === item.mostRecentProgramId &&
                          tw`shadow-[inset 0px 0px 0px 2px] shadow-blue-500`
                        }
                      >
                        {program.name}
                      </Tag>
                    </Tooltip>
                  </Link>
                ))}
              </div>

              <Title tw="mt-6">Item info</Title>
              {Object.entries(info).map(([key, value], i) => (
                <LabelValuePair key={i} label={key} value={value} />
              ))}

              {/* {item.variants &&
              item.variants.filter((v) => v.name).length > 0 && (
                <>
                  <Title tw="mt-6">Variants</Title>
                  {item.variants
                    .filter((v) => v.name)
                    .map((v, i) => {
                      const [label, value] = v.name.split(": ");
                      return (
                        <LabelValuePair key={i} label={label} value={value} />
                      );
                    })}
                  <br />
                </>
              )} */}

              <div tw="mt-6 flex justify-between items-center">
                <Title>Specifications</Title>
                {hasFullSpecsPermissions &&
                  fullSpecifications?.length > specifications?.length && (
                    <IconButton
                      onClick={() => setIsFullSpec(!isFullSpec)}
                      title={isFullSpec ? "Less" : "More"}
                    >
                      {isFullSpec ? <Remove /> : <Add />}
                    </IconButton>
                  )}
              </div>
              <div>
                {mappedSpec.map(({ key, value }, i) => {
                  return (
                    <LabelValuePair
                      key={i}
                      label={key.replace(":", "")}
                      value={
                        <span
                          css={
                            (!value ||
                              (value as string).toLowerCase() === "n/a") &&
                            tw`opacity-40`
                          }
                        >
                          {(value as string) || "---"}
                        </span>
                      }
                    />
                  );
                })}
              </div>
            </Typography>
          </div>
        </DialogContent>
      )}
    </Dialog>
  );
};

const ItemModalWrapper = () => {
  const dispatch = useDispatch();
  const { modalItemId } = useSelector((state: any) => state.items);
  return (
    modalItemId && (
      <ItemModal
        open={true}
        itemId={modalItemId}
        handleClose={() => dispatch(setModalItemId(null))}
      />
    )
  );
};
export default ItemModalWrapper;
