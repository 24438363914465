import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import CloseIcon from "@mui/icons-material/Close";
import PublishIcon from "@mui/icons-material/Publish";
import {
  Box,
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { Alert } from "@mui/material";

import { useDebounce } from "../../../../hooks/UtilityHooks";
import {
  createCloudinaryFile,
  sendRFQ,
  updateRFQ,
} from "../../../../redux/slices/planningTool/rfqSlice";
import { openUploadWidget } from "../../../../utility/cloudinary";
import SingleSupplierAutoComplete from "../../../Utility/AutoCompleteFields/SingleSupplierAutoComplete";
import CloudinaryFilesDownloadDelete from "../../CloudinaryFilesDownloadDelete";
import BasicTable from "../BasicTable";
import TierTable from "./TierTable";

const ViewDraft = ({ currentRFQ, handleCancel, classes, isUpdateLoading }) => {
  const {
    id,
    itemNumber,
    itemType,
    programName,
    brands,
    note: initialNote,
    pricingTiers,
    cloudinaryFiles,
  } = currentRFQ;

  const [note, setNote] = useState(initialNote || "");
  const dispatch = useDispatch();

  const uploadFile = () => {
    const options = {
      cloudName: "brandhub",
      uploadPreset: "jyehcpv4",
      showUploadMoreButton: true,
    };
    openUploadWidget(options, (error, file) => {
      if (!error) {
        if (file.event === "success") {
          let fileName = "";
          // if file.info.format then we are dealing with an image, otherwise we are dealing with a raw file
          if (file.info.format) {
            fileName = file.info.original_filename + "." + file.info.format;
          } else {
            fileName =
              file.info.original_filename +
              "." +
              file.info.public_id.split(".").at(-1);
          }
          dispatch(createCloudinaryFile(id, file.info.public_id, fileName));
        }
      } else {
        console.log(error.toString());
      }
    });
  };

  const handleUpdateNote = async () => {
    if (initialNote !== note) {
      await dispatch(updateRFQ(id, { note }));
    }
  };
  const handleSubmit = async () => {
    try {
      if (pricingTiers.length === 0)
        throw new Error("You need at least one pricing tier to send an RFQ");
      if (suppliersSelected.length === 0)
        throw new Error(
          "You need to add a supplier before you can send an RFQ"
        );
      await handleUpdateNote();
      await dispatch(
        sendRFQ(
          id,
          suppliersSelected.map((s) => s.id)
        )
      );
    } catch (error) {
      setError(error.message);
    }
  };

  const debouncedHandleUpdateNote = useDebounce(handleUpdateNote, 5000);

  const [suppliersSelected, setSuppliersSelected] = useState([]);
  const [reset, setReset] = useState(false);
  const [error, setError] = useState(false);

  const handleAddSupplier = (supplier) => {
    try {
      if (suppliersSelected.find((s) => s.id === supplier.id))
        throw new Error("Supplier is already selected");

      setSuppliersSelected([...suppliersSelected, supplier]);
      setReset(true);
      setError(false);
    } catch (err) {
      setError(err.message);
      setReset(true);
    }
  };

  const handleRemoveSupplier = (id) => {
    setSuppliersSelected(suppliersSelected.filter((s) => s.id !== id));
  };

  useEffect(() => {
    debouncedHandleUpdateNote();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [note]);

  useEffect(() => {
    setError(false);
  }, [pricingTiers]);

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "column", gridGap: "16px" }}>
        <Typography variant="h5">
          {itemType} | {itemNumber}
        </Typography>
        <Box sx={{ columns: 2 }}>
          <Typography>
            <b>Program: </b>
            {programName}
          </Typography>
          <Typography>
            <b>Brand(s): </b>
            {brands.join(", ")}
          </Typography>
        </Box>
        <br />
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography>Add tier quantities to price:</Typography>
            <TierTable currentRFQ={currentRFQ} />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography>Add notes:</Typography>
            <TextField
              multiline
              fullWidth
              minRows={5}
              variant="outlined"
              onBlur={handleUpdateNote}
              value={note}
              onChange={(e) => setNote(e.target.value)}
            />
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ mt: 4, mb: 2 }}>
        <Typography variant="h5">Add suppliers</Typography>
      </Box>

      <SingleSupplierAutoComplete
        classes={classes}
        handleChange={handleAddSupplier}
        reset={reset}
        setReset={setReset}
        disabled={false}
        noMargin={false}
      />
      {suppliersSelected.length > 0 && (
        <BasicTable
          rows={[
            ["Name", ""],
            ...suppliersSelected.map((supplier) => [
              supplier.name,
              <IconButton
                aria-label="remove supplier"
                onClick={() => handleRemoveSupplier(supplier.id)}
                size="large"
              >
                <CloseIcon style={{ fontSize: "16px" }} />
              </IconButton>,
            ]),
          ]}
          align={["left", "right"]}
        />
      )}

      {error && (
        <Box component={Alert} severity="warning" sx={{ mt: 4 }}>
          {error}
        </Box>
      )}

      <Button
        className={classes.largeButton}
        variant="contained"
        color="secondary"
        style={{ marginRight: "10px" }}
        startIcon={<PublishIcon />}
        onClick={uploadFile}
        sx={{ mt: 2, mb: 2 }}
      >
        Add Files
      </Button>

      <CloudinaryFilesDownloadDelete
        cloudinaryFiles={cloudinaryFiles}
        rfqid={id}
      />

      <Box sx={{ mt: 4, display: "flex", justifyContent: "space-between" }}>
        <Button
          variant="outlined"
          classes={{ root: classes.cancelBtn }}
          onClick={handleCancel}
        >
          Cancel RFQ
        </Button>
        <Button
          classes={classes.button}
          variant="outlined"
          disabled={isUpdateLoading}
          onClick={handleSubmit}
        >
          Submit and send RFQ
        </Button>
      </Box>
    </>
  );
};

export default ViewDraft;
