/** @jsxImportSource @emotion/react */
import "twin.macro";

import { Autocomplete } from "@mui/material";

import _ from "lodash";
import { DefaultTextField } from "src/components/Forms/DefaultInputs";

import { useCurrentOrderSet } from "./data/CurrentOrderSetContext";

const StateCodeFilter = ({
  stateCodeFilter,
  setStateCodeFilter,
}: {
  stateCodeFilter: string | null;
  setStateCodeFilter: (stateCode: string | null) => void;
}) => {
  const { orders } = useCurrentOrderSet();
  const states = _(orders)
    .map("address.state.code")
    .filter(Boolean)
    .uniq()
    .sort()
    .value();

  return (
    <form autoComplete="off">
      <Autocomplete
        size="small"
        tw="w-24"
        value={stateCodeFilter}
        onChange={(e, v) => setStateCodeFilter(v)}
        options={states}
        renderInput={(params) => <DefaultTextField label="State" {...params} />}
      />
    </form>
  );
};

export default StateCodeFilter;
