import { useMutation, useQueryClient } from "@tanstack/react-query";

import client from "@services/api";

import { BaseEntity, BodyWithId } from "./types";

const buildResourceMutations = <T extends BaseEntity>({
  resource,
  detailQueryKey,
  listQueryKey,
  type = resource,
}: {
  resource: string;
  detailQueryKey: (id: any) => readonly any[];
  listQueryKey: readonly any[];
  type?: string;
}) => {
  const useCreate = () => {
    const queryClient = useQueryClient();

    return useMutation({
      mutationFn: (data: Record<string, any>) =>
        client
          .post<T>(resource, { __type: type, ...data })
          .then((res) => res.data),
      onSuccess: (entity) => {
        queryClient.setQueryData(detailQueryKey(entity.id), entity);
        queryClient.invalidateQueries({ queryKey: listQueryKey });
      },
    });
  };
  const useUpdate = () => {
    const queryClient = useQueryClient();

    return useMutation({
      mutationFn: ({ id, ...data }: BodyWithId) =>
        client
          .update<T>(`${resource}/${id}`, { __type: type, ...data })
          .then((res) => res.data),
      onSuccess: (entity) => {
        queryClient.setQueryData(detailQueryKey(entity.id), entity);
        queryClient.invalidateQueries({ queryKey: listQueryKey });
      },
    });
  };

  const useDelete = () => {
    const queryClient = useQueryClient();

    return useMutation({
      mutationFn: (id: string) =>
        client.delete(`${resource}/${id}`).then((res) => res.id),
      onSuccess: (deletedId) => {
        queryClient.invalidateQueries({ queryKey: listQueryKey });
        queryClient.invalidateQueries({ queryKey: detailQueryKey(deletedId) });
      },
    });
  };

  return {
    useCreate,
    useUpdate,
    useDelete,
  };
};

export default buildResourceMutations;
