/** @jsxImportSource @emotion/react */
import "twin.macro";

import { Link } from "react-router-dom";

import { ArrowBack } from "@mui/icons-material";
import HomeIcon from "@mui/icons-material/Home";
import { IconButton, Tooltip, Typography } from "@mui/material";

import { PLANNING_TOOL_SUPPLIER, RFQ_ROLES } from "../../constants/permissions";
import { Contained, StyledButton } from "../StyledComponents";
import UserCanView from "../Utility/UserCanView";

const PlanningToolNav = ({ title, view, programId, itemId }) => {
  return (
    <Contained tw="flex-col py-4 pt-8">
      <div tw="flex items-center gap-4">
        {itemId && (
          <Tooltip title="Back to all program RFQs" placement="bottom-start">
            <IconButton
              component={Link}
              to={`/planning/rfqs/program/${programId}`}
              size="large"
            >
              <ArrowBack fontSize="large" color="secondary" />
            </IconButton>
          </Tooltip>
        )}
        <Typography tw="text-2xl font-bold text-slate-800">{title}</Typography>
      </div>
      <br />

      <div tw="flex flex-col gap-4 lg:(justify-between flex-row)">
        <div tw="flex gap-4">
          {view === "rfq" && (
            <StyledButton
              component={Link}
              to={"/planning/rfqs"}
              startIcon={<ArrowBack fontSize="large" />}
            >
              Search RFQs
            </StyledButton>
          )}
          {view === "program" && (
            <StyledButton
              startIcon={<ArrowBack fontSize="large" />}
              component={Link}
              to={"/planning/program/search"}
            >
              Search Programs
            </StyledButton>
          )}
          <StyledButton
            startIcon={<HomeIcon />}
            component={Link}
            to={"/planning/dashboard"}
          >
            Planning tool dashboard
          </StyledButton>
        </div>
        <div tw="flex gap-4">
          <StyledButton
            component={Link}
            to={`/planning/program/${programId}`}
            disabled={view === "program"}
            cta={view === "program"}
          >
            PROGRAM VIEW
          </StyledButton>

          <UserCanView allow={RFQ_ROLES}>
            <StyledButton
              component={Link}
              to={`/planning/rfqs/program/${programId}`}
              disabled={view === "rfq"}
              cta={view === "rfq"}
            >
              RFQ VIEW
            </StyledButton>
          </UserCanView>
          <UserCanView deny={[PLANNING_TOOL_SUPPLIER]}>
            <StyledButton
              component={Link}
              to={`/planning/changelog/program/${programId}`}
              disabled={view === "changelog"}
              cta={view === "changelog"}
            >
              LOG VIEW
            </StyledButton>
          </UserCanView>
        </div>
      </div>
    </Contained>
  );
};

export default PlanningToolNav;
