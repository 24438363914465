import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { useDispatch, useSelector } from "react-redux";

import GetAppIcon from "@mui/icons-material/GetApp";
import PrintIcon from "@mui/icons-material/Print";
import { Container, IconButton, Tooltip, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { setFiltersOpen } from "src/redux/slices/filterSlice";

import ComplianceContactTable from "../components/Compliance/ComplianceContactTable";
import EditContactModal from "../components/Compliance/EditContactModal";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
}));

// todo: page currently not in use, but left in for future use

const ComplianceContacts = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [id, setId] = useState(null);

  const { isLoading, contacts } = useSelector(
    (state) => state.complianceContacts
  );

  const handleClose = () => {
    setOpen(false);
  };

  const handleRowClick = (id) => {
    setId(id);
    setOpen(true);
  };

  useEffect(() => {
    dispatch(setFiltersOpen({ open: false }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>RTA | Compliance Contacts</title>
      </Helmet>
      <Container className={classes.mainWrapper}>
        {open && (
          <EditContactModal id={id} open={open} handleClose={handleClose} />
        )}
        <div className={classes.titleBar}>
          <Typography className={classes.titleText}>
            Compliance Contacts
          </Typography>
          <div
            style={{
              display: "flex",
              width: "300px",
              justifyContent: "flex-end",
            }}
          >
            <Tooltip title="Print Items">
              <IconButton size="large">
                <PrintIcon color="secondary" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Export File">
              {/* <CSVLink data={currentOrders} headers={csvHeaders}> */}
              <IconButton size="large">
                <GetAppIcon color="secondary" />
              </IconButton>
              {/* </CSVLink> */}
            </Tooltip>
          </div>
        </div>
        <br />
        <ComplianceContactTable
          contacts={contacts}
          isLoading={isLoading}
          handleRowClick={handleRowClick}
        />
      </Container>
    </>
  );
};

export default ComplianceContacts;
