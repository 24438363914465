import React from "react";

import { Download } from "@mui/icons-material";

import { RequestReportButton, useReport } from "@features/reports";

import useOrderSetId from "../orderSet/data/useOrderSetId";

const OrderSetWrapupButton = () => {
  const orderSetId = useOrderSetId();
  const reportParams = {
    filter: { orderSetId },
  };
  useReport("order-set-wrapup", ["orders", reportParams]);

  return (
    <RequestReportButton
      reportName="order-set-wrapup"
      title="Wrap Up"
      outlined
      startIcon={<Download />}
    >
      Wrap Up
    </RequestReportButton>
  );
};

export default OrderSetWrapupButton;
