import React, { useState } from "react";
import { Link } from "react-router-dom";

import ArrowRight from "@mui/icons-material/ArrowRight";
import { CircularProgress } from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import PropTypes from "prop-types";

const NestedMenuItem = ({
  anchorEl,
  label,
  childItems,
  classes,
  handleClose,
  loading = false,
}) => {
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const [subAnchorEl, setSubAnchorEl] = useState(null);

  const handleMouseEnter = (evt) => {
    setIsSubMenuOpen(true);
    setSubAnchorEl(evt.target);
  };
  const handleMouseLeave = () => {
    setIsSubMenuOpen(false);
  };

  const open = isSubMenuOpen && anchorEl;

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{ paddingTop: "10px", paddingBottom: "10px" }}
    >
      <MenuItem
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
        className={classes.headerListItem}
        disabled={loading}
      >
        {label}
        {loading ? <CircularProgress size={16} /> : <ArrowRight />}
      </MenuItem>
      {!loading && (
        <Menu
          classes={{ paper: classes.menuBackground }}
          transitionDuration={{ enter: 500, exit: 0 }}
          // Set pointer events to 'none' to prevent the invisible Popover div
          // from capturing events for clicks and hovers
          style={{ pointerEvents: "none", zIndex: "3001" }}
          anchorEl={subAnchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          open={Boolean(open)}
          autoFocus={false}
          disableAutoFocus
          disableEnforceFocus
          onClose={() => {
            setIsSubMenuOpen(false);
          }}
        >
          <div style={{ pointerEvents: "auto" }}>
            {childItems
              .filter((item) => item.link && item.primary)
              .map((item, i) => (
                <MenuItem
                  key={i}
                  component={Link}
                  onClick={handleClose}
                  to={item.link}
                  disabled={item.disabled}
                >
                  <ListItemText
                    primaryTypographyProps={{
                      className: classes.headerListItem,
                    }}
                    primary={item.primary}
                  />
                </MenuItem>
              ))}
          </div>
        </Menu>
      )}
    </div>
  );
};

NestedMenuItem.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default NestedMenuItem;
