/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import axios from "axios";
import { format, subDays } from "date-fns";
import { saveAs } from "file-saver";
import SimpleReportGenerator from "src/components/Reporting/SimpleReportGenerator";
import { setError } from "src/redux/slices/errorSlice";

import { formatCsvBlob } from "@utils/csv";

import { DatePicker } from "../components/Forms/DefaultInputs";

const ReportRapid = () => {
  const dispatch = useDispatch();

  const [actualShipFromDate, setActualShipFromDate] = useState(
    format(subDays(new Date(), 7), "MM/dd/yyyy")
  );
  const [actualShipToDate, setActualShipToDate] = useState(
    format(new Date(), "MM/dd/yyyy")
  );

  const isLoading = useSelector((state: any) => state.reports.isLoading);

  const handleGetReport = async () => {
    try {
      const dateRange = `${actualShipFromDate} - ${actualShipToDate}`;
      const { data } = await axios.get(
        `/api/rapid-inbound-inventory-report?filter[date-range]=${dateRange}`
      );

      const blob = formatCsvBlob(data);
      saveAs(blob, `rapid_report.csv`);
    } catch (error: any) {
      dispatch(
        setError({
          error: error.toString(),
          source: "Generating inbound inventory report",
        })
      );
    }
  };

  return (
    <SimpleReportGenerator
      reportTitle={"Inbound Inventory Report"}
      reportGetFunc={handleGetReport}
    >
      <div tw="flex">
        <DatePicker
          label="Actual Ship from Date"
          value={new Date(actualShipFromDate)}
          onChange={(value) =>
            setActualShipFromDate(format(value, "MM/dd/yyyy"))
          }
          disabled={isLoading}
          tw="mr-4"
        />

        <DatePicker
          label="Actual Ship to Date"
          value={new Date(actualShipToDate)}
          onChange={(value) => setActualShipToDate(format(value, "MM/dd/yyyy"))}
          disabled={isLoading}
        />
      </div>
    </SimpleReportGenerator>
  );
};

export default ReportRapid;
