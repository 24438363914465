import React from "react";
import { useSelector } from "react-redux";

import CancelIcon from "@mui/icons-material/Cancel";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";

import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
  confirmDeleteModal: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-around",
    width: "100%",
    height: "auto",
    textAlign: "center",
  },
}));

const ErrorModal = ({ open, handleClose }) => {
  const classes = useStyles();

  const error = useSelector((state) => state.error.currentError);
  const source = useSelector((state) => state.error.errorSource);

  return (
    <div className={classes.relativeContainer}>
      <Dialog
        open={open}
        disableScrollLock
        onClose={handleClose}
        fullWidth
        maxWidth="sm"
        style={{ zIndex: "15000" }}
      >
        <DialogContent>
          <IconButton
            className={classes.closeButton}
            onClick={handleClose}
            size="large"
          >
            <CancelIcon fontSize="large" color="secondary" />
          </IconButton>
          <br />
          <div className={classes.confirmDeleteModal}>
            <Typography className={classes.headerText}>
              It looks like something went wrong, the following error has
              occured:
            </Typography>
            <br />
            <Typography
              className={[classes.bodyText, classes.wrapNewlines].join(" ")}
            >
              {error}
            </Typography>
            <br />
            <Typography
              className={classes.bodyText}
            >{`Source: ${source}`}</Typography>
            <br />
            <div
              style={{
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                onClick={handleClose}
                href="/dashboard"
                color="secondary"
                variant="contained"
                className={classes.largeButton}
              >
                GO BACK HOME
              </Button>
              <Button
                onClick={handleClose}
                color="secondary"
                variant="contained"
                className={classes.largeButton}
                style={{ marginLeft: "10px" }}
              >
                CLOSE WINDOW
              </Button>
            </div>
          </div>
          <br />
        </DialogContent>
      </Dialog>
    </div>
  );
};

ErrorModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default ErrorModal;
