import { createSlice } from "@reduxjs/toolkit";

import { axiosGet, axiosGetWithNext } from "../../../api/axiosCalls";
import { setError } from "../errorSlice";
import { startGlobalLoad, stopGlobalLoad } from "../globalLoadSlice";
import { setFailure as patchFailure } from "../patchOrderSlice";
import { mapChangelogs } from "./maps";

const initialState = {
  isLoading: false,
  isChangesLoading: false,
  isNextLoading: false,
  isSingleLoading: false,
  isUpdateLoading: false,
  pagesLoaded: 0,
  nextPage: null,
  nextLink: null,
  changes: [],
  error: null,
};

const changelogSlice = createSlice({
  name: "changelog",
  initialState,
  reducers: {
    getChangesSuccess(state, action) {
      const { changes, nextLink } = action.payload;
      state.pagesLoaded += 1;
      state.nextLink = nextLink;
      state.changes = [...changes];
      state.isLoading = false;
      state.isNextLoading = false;
      state.error = null;
    },
    getNextChangesSuccess(state, action) {
      const { changes, nextLink } = action.payload;
      state.pagesLoaded += 1;
      state.nextLink = nextLink;
      state.changes = state.changes.concat(changes);
      state.isLoading = false;
      state.isNextLoading = false;
      state.error = null;
    },
    clearChanges(state) {
      state.isLoading = false;
      state.isChangesLoading = false;
      state.isNextLoading = false;
      state.isSingleLoading = false;
      state.isUpdateLoading = false;
      state.nextPage = null;
      state.nextLink = null;
      state.changes = [];
      state.error = null;
    },
    setNextIsLoading(state) {
      state.isNextLoading = true;
    },
    clearNextLink(state) {
      state.nextLink = null;
    },
    setFailure: (state, action) => {
      const { error } = action.payload;
      state.isLoading = false;
      state.isChangesLoading = false;
      state.isNextLoading = false;
      state.changes = [];
      state.error = error;
    },
  },
});

export const {
  getChangesSuccess,
  getNextChangesSuccess,
  clearChanges,
  setNextIsLoading,
  clearNextLink,
  setFailure,
} = changelogSlice.actions;

export default changelogSlice.reducer;

export const fetchChangelog = (id) => async (dispatch) => {
  try {
    dispatch(startGlobalLoad());
    const response = await axiosGet(`/api/changes?filter[program-id]=${id}`);
    if (response.error) throw response.error;
    const mappedData = mapChangelogs(response.data);
    dispatch(
      getChangesSuccess({
        changes: mappedData,
        nextLink: response.data.nextLink,
      })
    );
    dispatch(stopGlobalLoad());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(patchFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Fetch Changelog" }));
    dispatch(stopGlobalLoad());
  }
};

export const fetchFilteredChangelog =
  ({ programId }) =>
  async (dispatch) => {
    try {
      dispatch(startGlobalLoad());
      const queryString = `/api/changes?filter[program-id]=${programId}`;
      const response = await axiosGetWithNext(queryString);
      if (response.error) throw response.error;
      const mappedData = mapChangelogs(response.data.data);
      dispatch(
        getChangesSuccess({
          changes: mappedData,
          nextLink: response.data.nextLink,
        })
      );
      dispatch(stopGlobalLoad());
    } catch (err) {
      dispatch(setFailure({ error: err.toString() }));
      dispatch(patchFailure({ error: err.toString() }));
      dispatch(setError({ error: err.toString(), source: "Fetch Changelog" }));
      dispatch(stopGlobalLoad());
    }
  };

export const fetchNextChanges = (url) => async (dispatch) => {
  try {
    dispatch(setNextIsLoading());
    dispatch(clearNextLink());
    dispatch(startGlobalLoad());
    const response = await axiosGetWithNext(url);
    if (response.error) throw response.error;
    const mappedData = mapChangelogs(response.data.data);
    dispatch(
      getNextChangesSuccess({
        changes: mappedData,
        nextLink: response.data.nextLink,
      })
    );
    dispatch(stopGlobalLoad());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Items" }));
    dispatch(stopGlobalLoad());
  }
};
