import { useMemo } from "react";
import { useSelector } from "react-redux";

import { endOfDay, isAfter, isBefore, startOfDay } from "date-fns";

import { OrderCalendarMonth } from "@models/OrderCalendarMonth";
import { useApiResource } from "@services/api";

import { utcDate } from "../utility/utilityFunctions";

const today = new Date();

// use Pick to insure that the OcmDateFields type is only the keys of OrderCalendarMonth
type OcmDateField = keyof Pick<
  OrderCalendarMonth,
  | "orderWindowOpenDate"
  | "orderWindowCloseDate"
  | "reviewCloseDate"
  | "purchasingCloseDate"
  | "reportDate"
>;

export default function useActiveOrderWindows(ocmEndDateField?: OcmDateField) {
  const { role } = useSelector((state: any) => state.user);

  const { data = [] } = useApiResource("order-calendar-months", {
    params: {
      filter: {
        hasFutureInMarketStartDate: true,
        isAdHoc: false,
      },
      sort: "order-window-open-date",
      page: { size: 100 },
    },

    staleTime: 60 * 60 * 1000,
  });

  const endDateField: OcmDateField =
    ocmEndDateField ??
    ({
      field1: "orderWindowCloseDate",
      field2: "reviewCloseDate",
    }[role] ||
      "purchasingCloseDate");

  const activeOrderWindows = useMemo(
    () =>
      data.filter(
        (ocm) =>
          isBefore(startOfDay(utcDate(ocm.orderWindowOpenDate)), today) &&
          isAfter(endOfDay(utcDate(ocm[endDateField])), today)
      ),
    [endDateField, data]
  );

  return activeOrderWindows;
}
