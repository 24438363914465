/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { useDispatch, useSelector } from "react-redux";

import GetAppIcon from "@mui/icons-material/GetApp";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";

import format from "date-fns/format";
import subMonths from "date-fns/subMonths";
import BackButton from "src/components/Reporting/BackButton";
import { setFiltersOpen } from "src/redux/slices/filterSlice";

import { CSVLink } from "@utils/csv";

import { DatePicker } from "../components/Forms/DefaultInputs";
import ReportTable from "../components/Reporting/ReportTable";
import { useReportCsv } from "../hooks/Reports/useReportCsv";
import { useNoFetch } from "../hooks/UtilityHooks";
import {
  clearReports,
  getOpenPurchaseOrdersReport,
} from "../redux/slices/reports/reportSlice";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
  ...theme.reports,
}));

const ReportOpenPurchaseOrders = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [hasGenerated, setHasGenerated] = useState(false);
  const [fromDate, setFromDate] = useState(
    format(subMonths(new Date(), 1), "MM/dd/yyyy")
  );
  const [toDate, setToDate] = useState(format(new Date(), "MM/dd/yyyy"));

  const isLoading = useSelector((state) => state.reports.isLoading);
  const report = useSelector((state) => state.reports.reportData);
  const reportType = useSelector((state) => state.reports.reportType);
  const currentChannel = useSelector((state) => state.user.currentChannel);

  const { setCsvData, data, headers } = useReportCsv(
    "open-purchase-orders",
    hasGenerated,
    setHasGenerated,
    isLoading,
    report
  );

  const handleReset = () => {
    dispatch(clearReports());
    setFromDate(format(subMonths(new Date(), 1), "MM/dd/yyyy"));
    setToDate(format(new Date(), "MM/dd/yyyy"));
    setCsvData({ data: [], headers: [] });
  };

  const handleGetReport = () => {
    const filterObject = {
      channel: currentChannel,
      poSubmittedFromDate: fromDate,
      poSubmittedToDate: toDate,
    };
    dispatch(getOpenPurchaseOrdersReport(filterObject));
    setHasGenerated(true);
  };

  useNoFetch(dispatch);

  useEffect(() => {
    dispatch(setFiltersOpen({ open: false }));
    dispatch(clearReports());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>RTA | Open Purchase Orders</title>
      </Helmet>
      <Container className={classes.mainWrapper}>
        <div className={classes.titleBar}>
          <Typography className={classes.titleText}>
            Open Purchase Order Report
          </Typography>
          <div className={classes.configButtons}>
            <div className={classes.innerConfigDiv}>
              <div tw="mr-2">
                <BackButton />
              </div>
              <CSVLink
                data={data}
                headers={headers}
                filename="open_purchase_order_report.csv"
              >
                <Button
                  className={classes.largeButton}
                  variant="contained"
                  color="secondary"
                  startIcon={<GetAppIcon />}
                  disabled={data.length === 0}
                >
                  EXPORT TO CSV
                </Button>
              </CSVLink>
            </div>
          </div>
        </div>
        <br />
        <div className={classes.searchComponents}>
          <DatePicker
            fullWidth
            color="secondary"
            className={classes.queryField}
            disableToolbar
            variant="inline"
            format="MM/dd/yyyy"
            margin="normal"
            id="fromDate"
            label="Ship From Date"
            value={fromDate}
            onChange={(value) => setFromDate(format(value, "MM/dd/yyyy"))}
            PopoverProps={{
              style: { zIndex: "16000" },
            }}
            disabled={isLoading}
          />

          <DatePicker
            fullWidth
            color="secondary"
            className={classes.queryField}
            disableToolbar
            variant="inline"
            format="MM/dd/yyyy"
            margin="normal"
            id="toDate"
            label="Ship To Date"
            value={toDate}
            onChange={(value) => setToDate(format(value, "MM/dd/yyyy"))}
            PopoverProps={{
              style: { zIndex: "16000" },
            }}
            disabled={isLoading}
          />
        </div>
        <br />
        <div className={classes.buttonRow}>
          <Button
            className={classes.largeButton}
            variant="contained"
            color="secondary"
            onClick={() => handleGetReport()}
            style={{ marginRight: "20px" }}
          >
            GENERATE REPORT
          </Button>
          <Button
            className={classes.largeButton}
            variant="contained"
            color="secondary"
            onClick={handleReset}
          >
            RESET REPORT
          </Button>
        </div>
        <br />
        <br />
        {isLoading && (
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <CircularProgress size={100} style={{ marginTop: "50px" }} />
          </div>
        )}
        {!isLoading &&
          report.length > 0 &&
          reportType === "open-purchase-orders" && (
            <ReportTable report={report} type="open-purchase-orders" />
          )}
      </Container>
    </>
  );
};

ReportOpenPurchaseOrders.propTypes = {};

export default ReportOpenPurchaseOrders;
