import { createSlice } from "@reduxjs/toolkit";

import { buildFilters } from "../../../api/apiFunctions";
import { axiosGetWithNext } from "../../../api/axiosCalls";
import { handleImages } from "../../mapHelpers";
import { setError } from "../errorSlice";
import { startGlobalLoad, stopGlobalLoad } from "../globalLoadSlice";

let initialState = {
  isLoading: false,
  isNextLoading: false,
  nextLink: null,
  itemHistory: [],
  error: null,
};

const c2mItemHistorySlice = createSlice({
  name: "c2mItemHistory",
  initialState,
  reducers: {
    setIsLoading(state) {
      state.isLoading = true;
    },
    setIsNextLoading(state) {
      state.isNextLoading = true;
    },
    getItemHistorySuccess(state, action) {
      const { itemHistory, nextLink } = action.payload;
      state.nextLink = nextLink;
      state.itemHistory = itemHistory;
      state.isLoading = false;
      state.error = null;
    },
    getNextItemHistorySuccess(state, action) {
      const { itemHistory, nextLink } = action.payload;
      state.nextLink = nextLink;
      state.itemHistory = state.itemHistory.concat(itemHistory);
      state.isNextLoading = false;
      state.error = null;
    },
    setFailure(state, action) {
      const { error } = action.payload;
      state.isLoading = false;
      state.isNextLoading = false;
      state.error = error;
    },
  },
});

export const {
  setIsLoading,
  setIsNextLoading,
  getItemHistorySuccess,
  getNextItemHistorySuccess,
  setFailure,
} = c2mItemHistorySlice.actions;

export default c2mItemHistorySlice.reducer;

const mapItemHistory = (data) => {
  return data.map((dataPoint) => ({
    id: dataPoint.id,
    itemNumber: dataPoint["item-number"] ?? "---",
    brands: dataPoint.brands ?? "---",
    itemType: dataPoint["item-type"] ?? "---",
    programTheme: dataPoint["program-theme"] ?? "---",
    productName: dataPoint.name,
    images: handleImages(dataPoint.images),
  }));
};

export const fetchC2MItemHistory = (filterObject) => async (dispatch) => {
  try {
    dispatch(setIsLoading());
    dispatch(startGlobalLoad());
    let queryString = buildFilters(
      filterObject,
      "",
      "",
      "/api/historical-items",
      "c2m-item-history"
    );
    let itemHistory = await axiosGetWithNext(queryString);
    if (itemHistory.error) throw itemHistory.error;
    const mappedData = mapItemHistory(itemHistory.data.data);
    dispatch(
      getItemHistorySuccess({
        itemHistory: mappedData,
        nextLink: itemHistory.data.nextLink ?? null,
      })
    );
    dispatch(stopGlobalLoad());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "C2M Item History" }));
    dispatch(stopGlobalLoad());
  }
};

export const fetchNextC2MItemHistory = (url) => async (dispatch) => {
  try {
    dispatch(setIsNextLoading());
    dispatch(startGlobalLoad());
    let itemHistory = await axiosGetWithNext(url);
    if (itemHistory.error) {
      throw itemHistory.error;
    }
    const mappedData = mapItemHistory(itemHistory.data.data);
    dispatch(
      getNextItemHistorySuccess({
        itemHistory: mappedData,
        nextLink: itemHistory.data.nextLink ?? null,
      })
    );
    dispatch(stopGlobalLoad());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "C2M Item History" }));
    dispatch(stopGlobalLoad());
  }
};
