import React, { memo } from "react";

import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import PropTypes from "prop-types";

const headCells = [
  { label: "Sequence Number", id: "itemNumber" },
  { label: "Order Type", id: "orderType" },
  { label: "Order Number", id: "orderNumber" },
  { label: "Ordered By", id: "orderedBy" },
  { label: "Ship to Region", id: "territory" },
  { label: "Entity", id: "entity" },
  { label: "Key Account", id: "keyAccount" },
  { label: "Theme Name", id: "themeName" },
  { label: "Program Name", id: "programName" },
  { label: "BU", id: "businessUnit" },
  { label: "Brand(s)", id: "brands" },
  { label: "Item Type", id: "itemType" },
  { label: "Item Description", id: "itemDescription" },
  { label: "ABN", id: "abn" },
  { label: "Distributor Name", id: "distributorName" },
  { label: "Address1", id: "streetAddressOne" },
  { label: "Address2", id: "streetAddressTwo" },
  { label: "City", id: "city" },
  { label: "State", id: "stateCode" },
  { label: "Zip", id: "zip" },
  { label: "Gallo State Subledger", id: "galloStateSubledger" },
  { label: "Total Quantity", id: "qty" },
  { label: "Item Price", id: "itemPrice" },
  { label: "C2M Displayed Cost", id: "c2mDisplayedCost" },
  { label: "Status", id: "complianceStatus" },
  { label: "In Market Date", id: "inMarketDate" },
  { label: "Order Date", id: "orderDate" },
  { label: "Shipped Date", id: "shippedDate" },
  { label: "Order Status", id: "orderStatus" },
];

const useStyles = makeStyles((theme) => ({
  ...theme.global,
}));

const C2MOrderHistoryTable = ({ history, isLoading, scrollRef }) => {
  const classes = useStyles();

  return (
    <TableContainer
      className={classes.tableContainer}
      style={{ maxHeight: "Calc(100vh - 250px)" }}
      ref={scrollRef}
    >
      <Table className={classes.table} aria-label="" stickyHeader>
        <TableHead>
          <TableRow>
            {headCells.map((cell) => (
              <TableCell
                className={classes.headerText}
                key={cell.id}
                align="left"
              >
                {cell.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {!isLoading && history.length === 0 && (
            <TableRow>
              <TableCell
                align="left"
                colSpan={headCells.length}
                className={classes.headerText}
              >
                There are no historical orders that match your current search
                criteria...
              </TableCell>
            </TableRow>
          )}
          {!isLoading &&
            history.length > 0 &&
            history.map((row, i) => (
              <TableRow key={i}>
                {headCells.map((cell, index) => (
                  <TableCell key={`cell-${index}`} align="left">
                    {row[cell.id]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          {isLoading && (
            <TableRow>
              <TableCell align="left" colSpan={headCells.length}>
                <CircularProgress />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

C2MOrderHistoryTable.propTypes = {
  history: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  scrollRef: PropTypes.any,
};

export default memo(C2MOrderHistoryTable, (prev, next) => {
  return (
    prev.history.length === next.history.length &&
    prev.isLoading === next.isLoading
  );
});
