import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import DeleteIcon from "@mui/icons-material/Delete";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import makeStyles from "@mui/styles/makeStyles";

import { useInput } from "../../hooks/InputHooks";
import {
  deleteFavoriteAddressList,
  fetchAddresses,
  updateFavoriteAddressList,
} from "../../redux/slices/addresses/addressSlice";
import AddressTable from "./AddressTable";

const MemoNameInput = React.memo(
  ({ dispatch, id, territoryId }) => {
    const currentValue = useSelector(
      (state) =>
        state.addresses.favoriteAddressList.find(
          (distList) => distList.id === id
        ).name
    );
    const userAddresses = useSelector(
      (state) =>
        state.addresses.favoriteAddressList.find(
          (distList) => distList.id === id
        ).addresses
    );
    const {
      value: name,
      bind: bindName,
      setChange: setNameChange,
      change: nameChange,
    } = useInput(currentValue);
    return (
      <TextField
        style={{ width: "45%" }}
        size="small"
        variant="outlined"
        color="secondary"
        name="listName"
        type="text"
        label="List Name"
        value={name}
        {...bindName}
        onBlur={() => {
          if (nameChange) {
            dispatch(
              updateFavoriteAddressList(id, name, userAddresses, territoryId)
            );
            setNameChange(false);
          }
        }}
      />
    );
  },
  (prev, next) => prev.id === next.id
);

const useStyles = makeStyles((theme) => ({
  ...theme.global,
}));

const FavoriteAddressList = ({ id }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [address, setAddress] = useState("");
  const [currentAddresses, setCurrentAddresses] = useState([]);

  const isLoading = useSelector((state) => state.addresses.isLoading);
  const territoryId = useSelector((state) => state.user.currentTerritory);
  const userStates = useSelector((state) => state.user.states);
  const options = useSelector((state) => state.addresses.addressList);
  const userAddresses = useSelector(
    (state) =>
      state.addresses.favoriteAddressList.find((distList) => distList.id === id)
        .addresses
  );
  const name = useSelector(
    (state) =>
      state.addresses.favoriteAddressList.find((distList) => distList.id === id)
        .name
  );

  const loading = open && isLoading;

  const debounce = useRef(null);

  const handleAddresses = (value) => {
    setCurrentAddresses(value);
    dispatch(updateFavoriteAddressList(id, name, value, territoryId));
  };

  const handleDeleteList = () => {
    dispatch(deleteFavoriteAddressList(id));
  };

  const handleQuery = useCallback(() => {
    clearTimeout(debounce.current);

    debounce.current = setTimeout(() => {
      dispatch(
        fetchAddresses(
          address,
          territoryId,
          userStates.map((state) => state.id).join(","),
          false,
          null
        )
      );
    }, 250);
  }, [address, territoryId, userStates, dispatch]);

  useEffect(() => {
    if (address.length >= 1) {
      handleQuery();
    }
  }, [address, handleQuery, dispatch]);

  useEffect(() => {
    if (userAddresses.length !== currentAddresses.length) {
      setCurrentAddresses(userAddresses);
    }
  }, [currentAddresses, userAddresses]);

  return (
    <div
      style={{
        padding: "10px",
        width: "97%",
        boxSizing: "border-box",
        // marginBottom: "20px",
        backgroundColor: "white",
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <MemoNameInput dispatch={dispatch} id={id} territoryId={territoryId} />
        <Autocomplete
          style={{ width: "45%" }}
          multiple
          autoHighlight
          renderTags={() => null}
          className={classes.queryField}
          id={`address-auto-complete-${id}`}
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          inputValue={address}
          onInputChange={(_evt, value) => setAddress(value)}
          onChange={(_evt, value) => {
            handleAddresses(value);
          }}
          isOptionEqualToValue={(option, value) => option.name === value.name}
          getOptionLabel={(option) =>
            `${option.distributorAbn} - ${option.name}`
          }
          options={options}
          loading={loading}
          value={currentAddresses}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Add Address (Name / ABN)"
              variant="outlined"
              size="small"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? (
                      <CircularProgress color="inherit" size={15} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
        <Tooltip title="Delete List">
          <IconButton onClick={handleDeleteList} size="large">
            <DeleteIcon color="inherit" />
          </IconButton>
        </Tooltip>
      </div>
      <br />
      <AddressTable
        addresses={userAddresses}
        name={name}
        isLoading={false}
        id={id}
      />
      <br />
    </div>
  );
};

export default FavoriteAddressList;
