/** @jsxImportSource @emotion/react */
import "twin.macro";

import React, { useState } from "react";
import { useEffect } from "react";

import { Button, Snackbar } from "@mui/material";
import { Alert } from "@mui/material";

const FormSaveStatus = ({
  isSaved,
  handleSubmit,
  onSave,
  popUpDelay,
  floating,
}) => {
  const [saving, setSaving] = useState(false);
  const [shouldDisplay, setShouldDisplay] = useState(true);

  const handleSave = handleSubmit(
    (data) => {
      setSaving(true);
      onSave(data);
    },
    (e) => {
      console.log("ERROR", e);
    }
  );

  useEffect(() => {
    if (isSaved) {
      setSaving(false);
      setTimeout(() => {
        setShouldDisplay(false);
      }, 1000);
    } else {
      setTimeout(() => {
        setShouldDisplay(true);
      }, popUpDelay);
    }
  }, [isSaved]);

  const SaveAlert = () => (
    <Alert
      severity={isSaved ? "success" : "info"}
      action={
        !isSaved && (
          <Button
            tw="border-[rgb(13, 60, 97)] border-solid text-[rgb(13, 60, 97)]" //these colors match the text of the info Alert
            variant="outlined"
            size="small"
            onClick={handleSave}
            disabled={saving}
          >
            Save
          </Button>
        )
      }
    >
      {saving && "Saving..."}
      {isSaved && "Saved!"}
      {!saving &&
        !isSaved &&
        "Some changes have been made since the last save."}
    </Alert>
  );

  return floating ? (
    <Snackbar
      open={shouldDisplay}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
    >
      <div>
        <SaveAlert />
      </div>
    </Snackbar>
  ) : (
    shouldDisplay && <SaveAlert />
  );
};

FormSaveStatus.defaultProps = {
  isSaved: true,
  handleSubmit: () => {},
  onSave: () => {},
  popUpDelay: 3000,
  floating: true,
};

export default FormSaveStatus;
