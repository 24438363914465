import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, IconButton, TextField } from "@mui/material";

import {
  createPricingTier,
  deletePricingTier,
} from "../../../../redux/slices/planningTool/rfqSlice";
import BasicTable from "../BasicTable";

const sortedByQty = (a) => {
  const _a = [...a];
  _a.sort((a, b) => +a.qty - +b.qty);
  return _a;
};

const TierTable = ({ currentRFQ: { id, pricingTiers } }) => {
  const [tierList, setTierList] = useState(pricingTiers);
  const [tierValue, setTierValue] = useState("");
  const dispatch = useDispatch();

  const handleRemoveTier = (id) => {
    dispatch(deletePricingTier(id));
    setTierList(tierList.filter((t) => t.id !== id));
  };

  const handleTierValueChange = (e) => {
    setTierValue(e.target.value.replace(/[^0-9]/g, ""));
  };

  const handleAddTier = (e) => {
    e.preventDefault();
    dispatch(createPricingTier(id, tierValue));
    const newList = [...tierList, { qty: tierValue }];
    setTierList(sortedByQty(newList));
    setTierValue("");
  };

  useEffect(() => {
    setTierList(sortedByQty(pricingTiers));
  }, [pricingTiers]);

  return (
    <>
      <BasicTable
        paper={false}
        size="small"
        rows={[
          ["Quantity", ""],
          ...tierList.map((t) => [
            t.qty.toLocaleString("en-US"),
            <IconButton
              color="primary"
              aria-label="delete"
              onClick={() => handleRemoveTier(t.id)}
              size="large"
            >
              <CloseIcon style={{ fontSize: "16px", color: "#333" }} />
            </IconButton>,
          ]),
        ]}
        align={["left", "right"]}
      />
      <Box
        component="form"
        onSubmit={handleAddTier}
        sx={{ mt: 2, display: "flex", gridGap: "8px" }}
      >
        <TextField
          variant="outlined"
          value={tierValue}
          fullWidth
          onChange={handleTierValueChange}
          placeholder="Tier Quantity"
        />
        <Button type="submit" variant="outlined">
          Add&nbsp;tier
        </Button>
      </Box>
    </>
  );
};

export default TierTable;
