import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Avatar from "@mui/material/Avatar";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";

import PropTypes from "prop-types";

const DrawerOrdersNav = ({ classes }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const isLoading = useSelector((state) => state.suppliers.isInitialLoading);
  const navValues = useSelector((state) => state.suppliers.navValues);

  const handleOpen = (evt) => {
    setAnchorEl(evt.target);
    evt.stopPropagation();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <IconButton
        style={{ padding: 0 }}
        onClick={(evt) => {
          handleOpen(evt);
          evt.stopPropagation();
        }}
        size="large"
      >
        <Typography variant="h5" className={classes.navigationText}>
          Quote Requests
        </Typography>
        <ExpandMoreIcon fontSize="large" className={classes.expandMoreIcon} />
      </IconButton>
      <Menu
        classes={{ paper: classes.menuBackground }}
        disableScrollLock
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        style={{
          marginTop: "10px",
          zIndex: "3000",
        }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={handleClose}
          component={Link}
          to="/planning/quotes/new"
        >
          <ListItemText
            primaryTypographyProps={{ className: classes.headerListItem }}
            primary="New:"
          />
          <ListItemAvatar>
            <Avatar className={classes.avatar}>
              {isLoading ? <CircularProgress size={20} /> : navValues.newRFQ}
            </Avatar>
          </ListItemAvatar>
        </MenuItem>
        <Divider className={classes.divider} />
        <MenuItem
          onClick={handleClose}
          component={Link}
          to="/planning/quotes/inProgress"
        >
          <ListItemText
            primaryTypographyProps={{ className: classes.headerListItem }}
            primary="In Progress:"
          />
          <ListItemAvatar>
            <Avatar className={classes.avatar}>
              {isLoading ? (
                <CircularProgress size={20} />
              ) : (
                navValues.inProgressRFQ
              )}
            </Avatar>
          </ListItemAvatar>
        </MenuItem>
        <Divider className={classes.divider} key="divider2" />
        <MenuItem
          onClick={handleClose}
          component={Link}
          to="/planning/quotes/awarded"
        >
          <ListItemText
            primaryTypographyProps={{ className: classes.headerListItem }}
            primary="Awarded:"
          />
          <ListItemAvatar>
            <Avatar className={classes.avatar}>
              {isLoading ? (
                <CircularProgress size={20} />
              ) : (
                navValues.completedRFQ
              )}
            </Avatar>
          </ListItemAvatar>
        </MenuItem>
        <Divider className={classes.divider} key="divider3" />
        <MenuItem
          onClick={handleClose}
          component={Link}
          to="/planning/quotes/complete"
        >
          <ListItemText
            primaryTypographyProps={{ className: classes.headerListItem }}
            primary="History"
          />
        </MenuItem>
      </Menu>
    </>
  );
};

DrawerOrdersNav.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default DrawerOrdersNav;
