import React from "react";
import { useSelector } from "react-redux";

import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
}));

const General = () => {
  const classes = useStyles();

  const currentUser = useSelector((state) => state.user);
  // const [info, setInfo] = useState({
  //   first: currentUser.firstName,
  //   last: currentUser.lastName,
  //   email: currentUser.email,
  //   role: currentUser.role,
  // });

  // const { value: firstName, bind: bindFirstName } = useInput(info.first);
  // const { value: lastName, bind: bindLastName } = useInput(info.last);
  // const { value: email, bind: bindEmail } = useInput(info.email);
  // const { value: role, bind: bindRole } = useInput(info.role);
  // const { value: oldPassword, bind: bindOldPassword } = useInput("");
  // const { value: updatePassword, bind: bindUpdatePassword } = useInput("");
  // const { value: confirmPassword, bind: bindConfirmPassword } = useInput("");

  // const handleFormSubmit = (evt) => {
  //   evt.preventDefault();
  //   //temporarily removing unused vars so build will work on netlify, not permenant
  //   console.log(oldPassword, updatePassword, confirmPassword)
  //   if (evt.target.id==="profile") {
  //     setInfo({
  //       first: firstName,
  //       last: lastName,
  //       email: email,
  //       role: role,
  //     })
  //   }
  // };

  return (
    <>
      <Typography className={classes.titleText}>Profile Information</Typography>
      <br />
      <List classes={{ root: classes.root }}>
        <ListItem disableGutters>
          <Typography className={classes.bodyText}>
            Name: {currentUser.name}
          </Typography>
        </ListItem>
        <Divider />
        <ListItem disableGutters>
          <Typography className={classes.bodyText}>
            Email: {currentUser.email}
          </Typography>
        </ListItem>
        <Divider />
        <ListItem disableGutters>
          <Typography className={classes.bodyText}>
            Role:{" "}
            {currentUser.role[0].toUpperCase() + currentUser.role.slice(1)}
          </Typography>
        </ListItem>
        <Divider />
        <ListItem
          disableGutters
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <Typography className={classes.bodyText}>
            Assigned Territories:
          </Typography>
          <br />
          <Typography className={classes.bodyText}>
            {currentUser.territories.map((terr) => terr.name).join(", ")}
          </Typography>
        </ListItem>
        <Divider />
        <ListItem
          disableGutters
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <Typography className={classes.bodyText}>Assigned States:</Typography>
          <br />
          <Typography className={classes.bodyText}>
            {currentUser.states.map((state) => state.code).join(", ")}
          </Typography>
        </ListItem>
        <Divider />
        <ListItem disableGutters>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Typography className={classes.bodyText}>
              On Premise:
              <Checkbox
                disabled
                checked={currentUser.isOnPremise ? true : false}
              />
            </Typography>
            <Typography className={classes.bodyText}>
              Retail:
              <Checkbox
                disabled
                checked={currentUser.isRetail ? true : false}
              />
            </Typography>
          </div>
        </ListItem>
      </List>
    </>
  );
};

export default General;
