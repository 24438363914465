import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { useDispatch, useSelector } from "react-redux";

import GetAppIcon from "@mui/icons-material/GetApp";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";

import PropTypes from "prop-types";
import { setFiltersOpen } from "src/redux/slices/filterSlice";

import { CSVLink } from "@utils/csv";

import ReportTable from "../components/Reporting/ReportTable";
import { useInput } from "../hooks/InputHooks";
import { useReportCsv } from "../hooks/Reports/useReportCsv";
import { useNoFetch } from "../hooks/UtilityHooks";
import {
  clearReports,
  getInvoiceDetailReport,
} from "../redux/slices/reports/reportSlice";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
  ...theme.reports,
}));

const ReportInvoiceDetail = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [hasGenerated, setHasGenerated] = useState(false);

  const {
    value: invoiceNumber,
    bind: bindInvoiceNumber,
    reset: resetInvoiceNumber,
  } = useInput("");

  //const currentUserRole = useSelector((state) => state.user.role);
  const isLoading = useSelector((state) => state.reports.isLoading);
  const report = useSelector((state) => state.reports.reportData);
  const reportType = useSelector((state) => state.reports.reportType);

  const { setCsvData, data, headers } = useReportCsv(
    "invoice-detail",
    hasGenerated,
    setHasGenerated,
    isLoading,
    report
  );

  const handleReset = () => {
    dispatch(clearReports());
    resetInvoiceNumber();
    setCsvData({ data: [], headers: [] });
  };

  const handleGetReport = () => {
    dispatch(getInvoiceDetailReport(invoiceNumber));
    setHasGenerated(true);
    resetInvoiceNumber();
  };

  useEffect(() => {
    dispatch(setFiltersOpen({ open: false }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useNoFetch(dispatch);

  useEffect(() => {
    dispatch(clearReports());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>RTA | Invoice Detail</title>
      </Helmet>
      <Container className={classes.mainWrapper}>
        <div className={classes.titleBar}>
          <Typography className={classes.titleText}>
            Invoice Detail Report
          </Typography>
          <div className={classes.configButtons}>
            <div className={classes.innerConfigDiv}>
              <CSVLink
                data={data}
                headers={headers}
                filename="invoice_detail.csv"
              >
                <Button
                  className={classes.largeButton}
                  variant="contained"
                  color="secondary"
                  startIcon={<GetAppIcon />}
                  disabled={data.length === 0}
                >
                  EXPORT TO CSV
                </Button>
              </CSVLink>
            </div>
          </div>
        </div>
        <br />
        <div className={classes.searchComponents}>
          <TextField
            fullWidth
            variant="outlined"
            color="secondary"
            name="invoiceNumber"
            type="text"
            label="InvoiceNumber"
            className={classes.queryField}
            {...bindInvoiceNumber}
          />
        </div>
        <br />
        <div className={classes.buttonRow}>
          <Button
            className={classes.largeButton}
            variant="contained"
            color="secondary"
            onClick={() => handleGetReport()}
            style={{ marginRight: "20px" }}
          >
            GENERATE REPORT
          </Button>
          <Button
            className={classes.largeButton}
            variant="contained"
            color="secondary"
            onClick={handleReset}
          >
            RESET REPORT
          </Button>
        </div>
        <br />
        <br />
        {isLoading && (
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <CircularProgress size={100} style={{ marginTop: "50px" }} />
          </div>
        )}
        {!isLoading && report.length > 0 && reportType === "invoice-detail" && (
          <ReportTable report={report} type="invoice-detail" />
        )}
      </Container>
    </>
  );
};

ReportInvoiceDetail.propTypes = {};

export default ReportInvoiceDetail;
