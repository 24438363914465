import React from "react";

import CancelIcon from "@mui/icons-material/Cancel";
import { IconButton, TextField } from "@mui/material";

import PropTypes from "prop-types";

import { useInput } from "../../hooks/InputHooks";

const EmailAddressTextField = ({
  handleUpdateEmailAddress,
  handleRemoveEmailAddress,
  index,
  emailAddress,
  classes,
  type,
}) => {
  const { value, bind } = useInput(emailAddress);

  const handleBlur = (evt) => {
    handleUpdateEmailAddress(evt.target.value, index, type);
  };

  return (
    <div className={classes.emailRow}>
      <TextField
        fullWidth
        variant="outlined"
        color="secondary"
        name={`email-${index}`}
        type="text"
        label="Email"
        onBlur={(evt) => handleBlur(evt)}
        {...bind}
      />
      <IconButton
        onClick={() => handleRemoveEmailAddress(value, type)}
        size="large"
      >
        <CancelIcon fontSize="small" />
      </IconButton>
    </div>
  );
};

EmailAddressTextField.propTypes = {
  handleUpdateEmailAddress: PropTypes.func.isRequired,
  handleRemoveEmailAddress: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  emailAddress: PropTypes.string,
  classes: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
};

export default EmailAddressTextField;
