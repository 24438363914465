import { useSelector } from "react-redux";

import { useProgramsPaginated } from "./queries";

const useOnDemandNationalCouponPrograms = () => {
  const currentChannel = useSelector((state: any) => state.user.currentChannel);
  const params = {
    filter: {
      programTypes: "National Coupon",
      isOrderableForOnDemand: true,
      isPreOrder: false,
      channel: currentChannel,
    },
  };
  const { data } = useProgramsPaginated(params);
  return data;
};

export default useOnDemandNationalCouponPrograms;
