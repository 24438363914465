import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Button,
  Checkbox,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
} from "@mui/material";

import format from "date-fns/format";
import PropTypes from "prop-types";

import { setClear } from "../../redux/slices/filterSlice";
import { DatePicker } from "../Forms/DefaultInputs";
import { executeOnEnter } from "./utility";

const FiltersC2MHistory = ({
  classes,
  itemNumber,
  bindSequenceNum,
  orderNum,
  bindOrderNum,
  c2mTerritory,
  bindC2mTerritory,
  c2mProgramName,
  bindC2mProgramName,
  c2mBrands,
  bindC2mBrands,
  c2mBusinessUnit,
  bindC2mBusinessUnit,
  c2mDistributor,
  bindC2mDistributor,
  handleFilters,
  handleSearch,
}) => {
  const dispatch = useDispatch();

  const [filterByOrderDate, setFilterByOrderDate] = useState(false);
  const [filterByInMarketDate, setFilterByInMarketDate] = useState(false);

  const isLoading = useSelector((state) => state.globalLoad.isLoading);
  const {
    c2mOrderDateFrom,
    c2mOrderDateTo,
    c2mInMarketDateFrom,
    c2mInMarketDateTo,
  } = useSelector((state) => state.filters);

  const handleSetFilterByOrderDate = (value) => {
    if (value) {
      setFilterByOrderDate(true);
      handleFilters(
        {
          to: format(new Date(), "MM/dd/yyyy"),
          from: format(new Date("02/01/2021"), "MM/dd/yyyy"),
        },
        "c2mOrderDateRange",
        "c2m-order-history"
      );
    } else {
      setFilterByOrderDate(false);
      handleFilters(
        {
          to: null,
          from: null,
        },
        "c2mOrderDateRange",
        "c2m-order-history"
      );
    }
  };

  const handleSetFilterByInMarketDate = (value) => {
    if (value) {
      setFilterByInMarketDate(true);
      handleFilters(
        {
          to: format(new Date(), "MM/dd/yyyy"),
          from: format(new Date("02/01/2021"), "MM/dd/yyyy"),
        },
        "c2mInMarketDateRange",
        "c2m-order-history"
      );
    } else {
      setFilterByInMarketDate(false);
      handleFilters(
        {
          to: null,
          from: null,
        },
        "c2mInMarketDateRange",
        "c2m-order-history"
      );
    }
  };

  return (
    <>
      <List onKeyPress={executeOnEnter(handleSearch)}>
        <ListItem>
          <TextField
            className={classes.queryField}
            color="secondary"
            fullWidth
            name="sequenceNumber"
            type="text"
            label="Sequence #"
            value={itemNumber}
            {...bindSequenceNum}
            variant="outlined"
            size="small"
            disabled={isLoading}
          />
        </ListItem>
        <ListItem>
          <TextField
            className={classes.queryField}
            color="secondary"
            fullWidth
            name="orderNumber"
            type="text"
            label="Order #"
            value={orderNum}
            {...bindOrderNum}
            variant="outlined"
            size="small"
            disabled={isLoading}
          />
        </ListItem>
        <ListItem>
          <TextField
            className={classes.queryField}
            color="secondary"
            fullWidth
            name="distributor"
            type="text"
            label="Distributor / ABN"
            value={c2mDistributor}
            {...bindC2mDistributor}
            variant="outlined"
            size="small"
            disabled={isLoading}
          />
        </ListItem>
        <ListItem>
          <TextField
            className={classes.queryField}
            color="secondary"
            fullWidth
            name="territory"
            type="text"
            label="Territory"
            value={c2mTerritory}
            {...bindC2mTerritory}
            variant="outlined"
            size="small"
            disabled={isLoading}
          />
        </ListItem>
        <ListItem>
          <TextField
            className={classes.queryField}
            color="secondary"
            fullWidth
            name="program"
            type="text"
            label="Program"
            value={c2mProgramName}
            {...bindC2mProgramName}
            variant="outlined"
            size="small"
            disabled={isLoading}
          />
        </ListItem>
        <ListItem>
          <TextField
            className={classes.queryField}
            color="secondary"
            fullWidth
            name="brand"
            type="text"
            label="Brand"
            value={c2mBrands}
            {...bindC2mBrands}
            variant="outlined"
            size="small"
            disabled={isLoading}
          />
        </ListItem>
        <ListItem>
          <TextField
            className={classes.queryField}
            color="secondary"
            fullWidth
            name="businessUnit"
            type="text"
            label="Business Unit"
            value={c2mBusinessUnit}
            {...bindC2mBusinessUnit}
            variant="outlined"
            size="small"
            disabled={isLoading}
          />
        </ListItem>
        <ListItem>
          <Button
            fullWidth
            className={classes.largeButton}
            variant="contained"
            color="secondary"
            onClick={handleSearch}
            disabled={isLoading}
          >
            SEARCH
          </Button>
        </ListItem>
        <ListItem />
        <Divider />
        <ListItem />
        <ListItem
          role={undefined}
          button
          onClick={() => handleSetFilterByOrderDate(!filterByOrderDate)}
        >
          <ListItemIcon>
            <Checkbox
              edge="start"
              checked={filterByOrderDate}
              tabIndex={-1}
              disableRipple
              inputProps={{ "aria-labelledby": "order-date-filter" }}
              disabled={isLoading}
            />
          </ListItemIcon>
          <ListItemText id="order-date-filter" primary="Filter by Order Date" />
        </ListItem>
        {filterByOrderDate && (
          <>
            <ListItem>
              <DatePicker
                fullWidth
                color="secondary"
                className={classes.dateField}
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                id="c2mOrderDateFrom"
                label="Order From Date"
                value={
                  c2mOrderDateFrom ||
                  format(new Date("02/01/2021"), "MM/dd/yyyy")
                }
                onChange={(value) =>
                  handleFilters(value, "c2mOrderDateFrom", "c2m-order-history")
                }
                PopoverProps={{
                  style: { zIndex: "16000" },
                }}
                disabled={isLoading}
              />
            </ListItem>
            <ListItem>
              <DatePicker
                fullWidth
                color="secondary"
                className={classes.dateField}
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                id="c2mOrderDateTo"
                label="Order To Date"
                value={c2mOrderDateTo || format(new Date(), "MM/dd/yyyy")}
                onChange={(value) =>
                  handleFilters(value, "c2mOrderDateTo", "c2m-order-history")
                }
                PopoverProps={{
                  style: { zIndex: "16000" },
                }}
                disabled={isLoading}
              />
            </ListItem>
          </>
        )}
        <ListItem
          role={undefined}
          button
          onClick={() => handleSetFilterByInMarketDate(!filterByInMarketDate)}
        >
          <ListItemIcon>
            <Checkbox
              edge="start"
              checked={filterByInMarketDate}
              tabIndex={-1}
              disableRipple
              inputProps={{ "aria-labelledby": "in-market-date-filter" }}
              disabled={isLoading}
            />
          </ListItemIcon>
          <ListItemText
            id="in-market-date-filter"
            primary="Filter by In Market Date"
          />
        </ListItem>
        {filterByInMarketDate && (
          <>
            <ListItem>
              <DatePicker
                fullWidth
                color="secondary"
                className={classes.dateField}
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                id="c2mInMarketDateFrom"
                label="In Market From Date"
                value={
                  c2mInMarketDateFrom ||
                  format(new Date("02/01/2021"), "MM/dd/yyyy")
                }
                onChange={(value) =>
                  handleFilters(
                    value,
                    "c2mInMarketDateFrom",
                    "c2m-order-history"
                  )
                }
                PopoverProps={{
                  style: { zIndex: "16000" },
                }}
                disabled={isLoading}
              />
            </ListItem>
            <ListItem>
              <DatePicker
                fullWidth
                color="secondary"
                className={classes.dateField}
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                id="c2mInMarketDateTo"
                label="In Market To Date"
                value={c2mInMarketDateTo || format(new Date(), "MM/dd/yyyy")}
                onChange={(value) =>
                  handleFilters(value, "c2mInMarketDateTo", "c2m-order-history")
                }
                PopoverProps={{
                  style: { zIndex: "16000" },
                }}
                disabled={isLoading}
              />
            </ListItem>
          </>
        )}
        <ListItem />
        <Divider />
        <ListItem />
        <ListItem>
          <Button
            fullWidth
            className={classes.largeButton}
            variant="contained"
            color="secondary"
            onClick={() => {
              dispatch(setClear());
            }}
            disabled={isLoading}
          >
            CLEAR FILTERS
          </Button>
        </ListItem>
      </List>
    </>
  );
};

FiltersC2MHistory.propTypes = {
  classes: PropTypes.object.isRequired,
  itemNumber: PropTypes.string,
  bindSequenceNum: PropTypes.object.isRequired,
  orderNum: PropTypes.string,
  bindOrderNum: PropTypes.object.isRequired,
  c2mTerritory: PropTypes.string,
  bindC2mTerritory: PropTypes.object.isRequired,
  c2mProgramName: PropTypes.string,
  bindC2mProgramName: PropTypes.object.isRequired,
  c2mBrands: PropTypes.string,
  bindC2mBrands: PropTypes.object.isRequired,
  c2mBusinessUnit: PropTypes.string,
  bindC2mBusinessUnit: PropTypes.object.isRequired,
  c2mDistributor: PropTypes.string,
  bindC2mDistributor: PropTypes.object.isRequired,
  handleFilters: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
};

export default FiltersC2MHistory;
