import React from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";

import { Button, TableCell, TableRow, TextField } from "@mui/material";

import { useCustomRegister } from "../../utility/inputHelpers";

const CrudNewRow = ({ columns, onSubmit, uniqueKey }) => {
  const {
    register,
    formState: { errors, isSubmitSuccessful },
    handleSubmit,
    reset,
    setFocus,
  } = useForm({
    mode: "onChange",
  });
  const reg = useCustomRegister(register, errors);

  const unsetValues = React.useMemo(
    () =>
      columns.reduce((a, b) => {
        a[b.id] = "";
        return a;
      }, {}),
    [columns]
  );

  const submit = (e) => {
    e.preventDefault();
    handleSubmit(onSubmit)(e);
    // reset(unsetValues);
  };

  const submitOnEnter = (e) => e.keyCode === 13 && submit(e);

  useEffect(() => {
    if (isSubmitSuccessful) {
      setFocus(columns[0].id);
      reset(unsetValues);
    }
  }, [isSubmitSuccessful, columns, setFocus, reset, unsetValues]);

  return (
    <TableRow onKeyDown={submitOnEnter}>
      {columns.map(({ id, checks, renderInput }, i) => {
        const Component = renderInput || TextField;
        return (
          <TableCell key={`new${id}`}>
            {i === 0 && <form id={uniqueKey} onSubmit={submit} />}
            <Component fullWidth form={uniqueKey} {...reg(id, checks)} />
          </TableCell>
        );
      })}
      <TableCell>
        <Button
          form={uniqueKey}
          role="submit"
          variant="contained"
          onClick={submit}
        >
          Add
        </Button>
      </TableCell>
    </TableRow>
  );
};

export default CrudNewRow;
