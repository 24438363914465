import { useCallback, useEffect, useRef, useState } from "react";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
import Helmet from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";

import GetAppIcon from "@mui/icons-material/GetApp";
import PrintIcon from "@mui/icons-material/Print";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";

import HideFiltersButtonToggle from "src/components/Filtering/HideFiltersButtonToggle";

import { CSVLink } from "@utils/csv";

import FilterChipList from "../components/Filtering/FilterChipList";
import RFPHistoryTable from "../components/Purchasing/RFPHistoryTable";
import { SUPPLIER_ROLES } from "../constants/permissions";
import { useInitialFilters } from "../hooks/UtilityHooks";
import {
  setSorted,
  updateMultipleFilters,
  updateSingleFilter,
} from "../redux/slices/filterSlice";
import { fetchNextFilteredRFPHistory } from "../redux/slices/purchasing/rfpHistorySlice";
import { formatMoney } from "../utility/utilityFunctions";

/*
The request for price history works similarly to the purchase order history, in the fact that different
statuses matter based on the user type.  An rfp that is new for a supplier will have a status of sent in
the API, but  it is a new request for the supplier.
*/

const defaultCurrentFilters = {
  brand: [],
  program: [],
  itemType: [],
  status: "all",
  rfpNum: "",
  itemNumber: "",
  sortOrder: "asc",
  sortOrderBy: "dueDate",
};

const defaultSupplierNewFilters = {
  brand: [],
  program: [],
  itemType: [],
  status: "bid-sent",
  rfpNum: "",
  itemNumber: "",
  sortOrder: "asc",
  sortOrderBy: "dueDate",
};

const defaultSupplierInProgressFilters = {
  brand: [],
  program: [],
  itemType: [],
  status: "bid-accepted",
  rfpNum: "",
  itemNumber: "",
  sortOrder: "asc",
  sortOrderBy: "dueDate",
};

const defaultSupplierAwardedFilters = {
  brand: [],
  program: [],
  itemType: [],
  status: "bid-awarded",
  rfpNum: "",
  itemNumber: "",
  sortOrder: "asc",
  sortOrderBy: "dueDate",
};

const defaultHistoryFilters = {
  brand: [],
  program: [],
  itemType: [],
  status: "complete",
  rfpNum: "",
  itemNumber: "",
  sortOrder: "asc",
  sortOrderBy: "dueDate",
};

const filterOptionMap = {
  current: defaultCurrentFilters,
  new: defaultSupplierNewFilters,
  inProgress: defaultSupplierInProgressFilters,
  awarded: defaultSupplierAwardedFilters,
  complete: defaultHistoryFilters,
};

const useStyles = makeStyles((theme) => ({
  ...theme.global,
}));

const RFPHistory = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { filterOption } = useParams();
  const tableRef = useRef(null);

  const [currentCSV, setCurrentCSV] = useState({ data: [], headers: [] });
  const [currentView, setCurrentView] = useState(filterOption);

  const nextLink = useSelector((state) => state.rfpHistory.nextLink);
  const isNextLoading = useSelector((state) => state.rfpHistory.isNextLoading);
  const filtersOpen = useSelector((state) => state.filters.filtersOpen);

  const handleBottomScroll = () => {
    if (nextLink && !isNextLoading) {
      if (scrollRef.current && scrollRef.current.scrollTop !== 0) {
        dispatch(fetchNextFilteredRFPHistory(nextLink));
      }
    }
  };

  const scrollRef = useBottomScrollListener(handleBottomScroll, {
    offset: 500,
    debounceOptions: {
      leading: true,
      trailing: false,
    },
  });

  const currentUserRole = useSelector((state) => state.user.role);
  const currentChannel = useSelector((state) => state.user.currentChannel);
  const currentChannelFilter = useSelector((state) => state.filters.channel);
  const supplierId = useSelector((state) => state.user.supplierId);
  const isRFPsLoading = useSelector((state) => state.rfpHistory.isLoading);
  const currentRFPs = useSelector((state) => state.rfpHistory.rfps);

  const isSupplier = SUPPLIER_ROLES.includes(currentUserRole);

  const defaultFilters = filterOptionMap[filterOption];

  if (!isSupplier) {
    defaultFilters.channel = currentChannel;
  }

  const handlePrint = useReactToPrint({
    content: () => tableRef.current,
  });

  const handleStatus = useCallback(
    (status, bids) => {
      if (isSupplier && status === "sent") {
        let currentBid = bids.find((bid) => bid.supplierId === supplierId);
        if (currentBid.status === "sent") {
          return "New";
        } else if (currentBid.status === "accepted") {
          return "In Progress";
        } else if (currentBid.status === "declined") {
          return "Declined";
        }
      }
      if (status === "sent") {
        let bidCount = 0;
        bids.forEach((bid) => {
          if (bid.status === "accepted" || bid.status === "declined") {
            bidCount += 1;
          }
        });
        if (bidCount !== bids.length) {
          return `Waiting for Resp. ${bidCount}/${bids.length}`;
        } else {
          return "Ready for Review";
        }
      } else {
        return status[0].toUpperCase() + status.slice(1);
      }
    },
    [supplierId, isSupplier]
  );

  const handleSort = (sortObject) => {
    scrollRef.current.scrollTop = 0;
    dispatch(
      updateMultipleFilters({
        filterObject: {
          sortOrder: sortObject.order,
          sortOrderBy: sortObject.orderBy,
        },
      })
    );
    dispatch(setSorted());
  };

  useEffect(() => {
    if (
      (currentRFPs.length > 0 && currentCSV.data.length === 0) ||
      (currentRFPs.length > 0 &&
        currentCSV.data.length > 0 &&
        currentRFPs.length !== currentCSV.data.length) ||
      (currentRFPs.length > 0 &&
        currentCSV.data.length > 0 &&
        currentRFPs[0].itemNumber !== currentCSV.data[0].itemNumber)
    ) {
      let csvHeaders = [
        { label: "RFP #", key: "rfpNum" },
        { label: "Sequence #", key: "itemNumber" },
        { label: "Program", key: "program" },
        { label: "Brand", key: "brand" },
        { label: "Item Type", key: "itemType" },
        { label: "Item Description", key: "itemDesc" },
        { label: "Total Ordered", key: "totalQty" },
        { label: "Est. Cost", key: "estCost" },
        { label: "Est. Total", key: "totalEstCost" },
        { label: "Act. Total", key: "totalActCost" },
        { label: "Due Date", key: "dueDate" },
        { label: "In Market Date", key: "inMarketDate" },
        { label: "Status", key: "status" },
      ];

      let csvSupplierHeaders = [
        { label: "RFP #", key: "rfpNum" },
        { label: "Sequence #", key: "itemNumber" },
        { label: "Item Type", key: "itemType" },
        { label: "Item Description", key: "itemDesc" },
        { label: "Total Ordered", key: "totalQty" },
        { label: "Due Date", key: "dueDate" },
        { label: "In Market Date", key: "inMarketDate" },
        { label: "Status", key: "status" },
        { label: "Bid", key: "bidValue" },
      ];

      let csvData = [];
      currentRFPs.forEach((rfp) => {
        if (!isSupplier) {
          csvData.push({
            rfpNum: rfp.id,
            itemNumber: rfp.itemNumber,
            program: rfp.program,
            brand: rfp.brand,
            itemType: rfp.itemType,
            itemDesc: rfp.itemDescription,
            totalQty: rfp.totalQty,
            estCost: formatMoney(rfp.estCost, true),
            totalEstCost: formatMoney(rfp.totalEstCost, true),
            totalActCost: rfp.totalActCost
              ? formatMoney(rfp.totalActCost, true)
              : "---",
            dueDate: rfp.dueDate,
            inMarketDate: rfp.inMarketDate,
            status: handleStatus(rfp.status, rfp.bids),
          });
        } else {
          csvData.push({
            rfpNum: rfp.id,
            itemNumber: rfp.itemNumber,
            itemType: rfp.itemType,
            itemDesc: rfp.itemDescription,
            totalQty: rfp.totalQty,
            dueDate: rfp.dueDate,
            inMarketDate: rfp.inMarketDate,
            status: handleStatus(rfp.status, rfp.bids),
            bidValue: rfp.bids.find((bid) => bid.supplierId === supplierId)
              .price
              ? formatMoney(
                  rfp.bids.find((bid) => bid.supplierId === supplierId).price,
                  true
                )
              : "---",
          });
        }
      });
      setCurrentCSV({
        data: csvData,
        headers: !isSupplier ? csvHeaders : csvSupplierHeaders,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRFPs, currentCSV, currentUserRole, handleStatus, supplierId]);

  useEffect(() => {
    if (!isSupplier) {
      if (currentChannel !== currentChannelFilter) {
        dispatch(
          updateSingleFilter({
            filter: "channel",
            value: currentChannel,
          })
        );
        dispatch(setSorted());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUserRole, currentChannel, currentChannelFilter, dispatch]);

  useInitialFilters("history-rfp", defaultFilters);

  useEffect(() => {
    if (currentView !== filterOption) {
      setCurrentView(filterOption);
      dispatch(
        updateMultipleFilters({ filterObject: filterOptionMap[filterOption] })
      );
      dispatch(setSorted());
    }
  }, [currentView, setCurrentView, filterOption, dispatch]);

  return (
    <>
      <Helmet>
        <title>RTA | RFP History</title>
        {isSupplier && !filtersOpen && (
          <script type="text/javascript">{` Beacon('suggest', ['601438c22042ff6d1b2a8abb'])`}</script>
        )}
      </Helmet>
      <Container className={classes.mainWrapper}>
        <div className={classes.titleBar}>
          <Typography className={classes.titleText}>RFP History</Typography>
          <div
            style={{
              display: "flex",
              width: "250px",
              justifyContent: "flex-end",
            }}
          >
            <Tooltip title="Print RFPs">
              <IconButton onClick={handlePrint} size="large">
                <PrintIcon color="secondary" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Export File">
              <span>
                <CSVLink data={currentCSV.data} headers={currentCSV.headers}>
                  <IconButton size="large">
                    <GetAppIcon color="secondary" />
                  </IconButton>
                </CSVLink>
              </span>
            </Tooltip>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignContent: "center",
            marginBottom: "10px",
          }}
        >
          <HideFiltersButtonToggle />
          <FilterChipList classes={classes} />
          <br />
        </div>
        <RFPHistoryTable
          rfps={currentRFPs}
          rfpsLoading={isRFPsLoading}
          handleSort={handleSort}
          scrollRef={scrollRef}
          supplierId={supplierId}
          tableRef={tableRef}
        />
        {isNextLoading && (
          <div style={{ width: "100%" }}>
            <LinearProgress />
          </div>
        )}
        {!isNextLoading && <div style={{ width: "100%", height: "4px" }}></div>}
      </Container>
      <br />
    </>
  );
};

export default RFPHistory;
