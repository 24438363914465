import React, { useEffect } from "react";

import { Divider, List, ListItem } from "@mui/material";

import _ from "lodash";
import { executeOnEnter } from "src/components/Filtering/utility";
import {
  AutocompleteInput,
  TextInput,
} from "src/components/Forms/ControlledInputs";
import { StyledButton } from "src/components/StyledComponents";

import { useFilterContext, useFilterParams } from "@features/filters";
import { ControlledResourceAutocomplete } from "@utils/forms/ControlledResourceAutocomplete";

import fiscalYear from "./fiscalYear";

const channelOptions = [
  { id: "retail", name: "Retail" },
  { id: "on_premise", name: "On Premise" },
];

const combineOptions = (options: any[], uniqueKey = "name") =>
  _(options)
    .groupBy(uniqueKey)
    .values()
    .map((v) => ({ ...v[0], id: _.map(v, "id").join(",") }))
    .value();

export const BudgetFilters = () => {
  const { control, getValues, reset } = useFilterContext();
  const [appliedFilters, applyFilters] = useFilterParams({
    preserveKeys: true,
  });
  const handleSearch = () => {
    applyFilters(getValues());
  };

  const handleReset = () => {
    reset((v) => _.mapValues(v, () => null));
    applyFilters({});
  };

  useEffect(() => {
    const initialValues = { ...appliedFilters };
    reset(initialValues);
    applyFilters(initialValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sharedProps = {
    control,
    fullWidth: true,
    onBlur: handleSearch,
  };

  return (
    <div>
      <List>
        <ListItem>
          <TextInput
            control={control}
            name="budgetId"
            label="Budget ID"
            onKeyDown={executeOnEnter(handleSearch)}
            fullWidth
            type="number"
          />
        </ListItem>
        <ListItem>
          <StyledButton cta onClick={handleSearch} fullWidth>
            Search
          </StyledButton>
        </ListItem>
        <Divider />
        <ListItem>
          <ControlledResourceAutocomplete
            name="brandIds"
            label="Brand"
            multiple
            getOptionText={(option) => option.externalId + " " + option.name}
            resource="brands"
            requestParams={{ filter: { isActive: true } }}
            {...sharedProps}
          />
        </ListItem>
        <ListItem>
          <ControlledResourceAutocomplete
            name="stateIds"
            label="State"
            multiple
            getOptionText={(option) => option.code + " " + option.name}
            resource="sub-states"
            {...sharedProps}
          />
        </ListItem>
        <ListItem>
          <AutocompleteInput
            options={fiscalYear.options}
            name="year"
            label="Fiscal Year"
            {...sharedProps}
          />
        </ListItem>
        <ListItem>
          <ControlledResourceAutocomplete
            name="buIds"
            label="Business Unit"
            multiple
            resource={"business-units" as any}
            tapOptions={combineOptions}
            {...sharedProps}
          />
        </ListItem>
        <ListItem>
          <AutocompleteInput
            options={channelOptions}
            name="channel"
            label="Channel"
            {...sharedProps}
          />
        </ListItem>
        <Divider />
        <ListItem>
          <StyledButton outlined onClick={handleReset} fullWidth>
            Reset
          </StyledButton>
        </ListItem>
      </List>
    </div>
  );
};
