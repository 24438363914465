import { OrderVariant } from "@models/OrderVariant";

export default function getPriorApprovalStatus(orderVariant: OrderVariant) {
  if (orderVariant.priorApprovalDenied) {
    return "Not Compliant";
  }
  if (
    orderVariant.priorApprovalTriggeredRules.some(
      (rule) => rule.status !== "approved"
    )
  ) {
    return "Compliance Pending";
  }

  return "Ok";
}
