export const mapUsers = (users) => {
  const mappedUsers = users.map((user) => ({
    id: user.id,
    role: user.role,
    status: user.status,
    name: user.name,
    email: user.email,
    isOnPremise: user["is-on-premise"],
    isRetail: user["is-retail"],
    states: user.states,
    territories: user.territories,
    supplierId: user.supplier?.id,
    hasLimitedBrandAccess: user["has-limited-brand-access"] ?? false,
    brands: user.brands ?? [],
  }));
  return mappedUsers;
};
