/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useContext } from "react";

import { GetApp } from "@mui/icons-material";

import _ from "lodash";
import { StyledButton } from "src/components/StyledComponents";
import { formatMoneyString } from "src/utility/utilityFunctions";

import { Order } from "@models/Order";
import { OrderSetVariant } from "@models/OrderSetVariant";
import { downloadAsCsv } from "@utils/csv";

import { displayToInventoryAllocation } from "../helpers";
import OrderSetIdContext from "./data/OrderSetIDContext";
import { useOrderSet } from "./data/useOrderSet";

const osvHeaderRows = [
  { key: "variant.item.type", label: "Item Type" },
  { key: "variant.item.comment", label: "Item Description" },
  { key: "variant.item.qtyPerPack", label: "Items Per Pack" },
  { key: "totalQty", label: "Total Qty of Items", f: formatMoneyString },
  { key: "estimatedCost", label: "Item Est Cost", f: formatMoneyString },
  { key: "totalEstimatedCost", label: "Total Est Cost", f: formatMoneyString },
];

const formatCsvData = (
  orderSetVariants: OrderSetVariant[],
  orders: Order[]
) => {
  const csvData: (string | number)[][] = [];
  csvData.push(["Seq #", ..._.map(orderSetVariants, "variant.variantSku")]);

  osvHeaderRows.forEach((row) => {
    csvData.push([
      row.label,
      ..._(orderSetVariants)
        .map(row.key)
        .map((v) => row.f?.(v) ?? v)
        .value(),
    ]);
  });

  orders.forEach((order) => {
    csvData.push([
      order.type === "to-inventory"
        ? `RESTOCK ${displayToInventoryAllocation(order)}`
        : `${order.address.name}: ${order.address.city}, ${order.address.state.code}`,
      ..._.map(order.orderVariants, "qty"),
    ]);
  });
  return csvData;
};

const ExportOrderSetButton = () => {
  const orderSetId = useContext(OrderSetIdContext);
  const { orders, orderSet, orderSetVariants } = useOrderSet(orderSetId);

  if (!orders || !orderSet || !orderSetVariants) return null;

  return (
    <StyledButton
      outlined
      startIcon={<GetApp />}
      onClick={() =>
        downloadAsCsv(
          formatCsvData(orderSetVariants ?? [], orders),
          `order_set_${orderSet?.id}.csv`
        )
      }
    >
      EXPORT FORM
    </StyledButton>
  );
};

export default ExportOrderSetButton;
