export const buildUserQuery = (
  name,
  role,
  status = "active",
  isProgramCreator
) => {
  let queryString =
    name.length > 0
      ? `/api/users?filter[name-or-email]=${name}&filter[status]=${status}`
      : `/api/users?filter[status]=${status}`;
  if (role && role === "super") {
    queryString += "&filter[roles]=purchaser,select-purchaser,super";
  }
  if (role && role === "purchaser") {
    queryString += "&filter[roles]=purchaser,super";
  }
  if (role && role === "select-purchaser") {
    queryString += "&filter[roles]=select-purchaser,super";
  }
  if (role && role === "supplier") {
    queryString += "&filter[roles]=supplier";
  }
  if (isProgramCreator) {
    queryString += "&filter[is-program-creator]=true";
  }

  return queryString;
};

export const buildFavoriteItemsPatch = (idArray) => {
  const ids = idArray.map((id) => ({ type: "item", id: id }));
  return {
    data: {
      type: "current-user",
      relationships: {
        "favorite-items": {
          data: ids,
        },
      },
    },
  };
};
