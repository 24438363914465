import { createSlice } from "@reduxjs/toolkit";

import {
  axiosDelete,
  axiosGet,
  axiosGetWithNext,
  axiosPatch,
  axiosPost,
} from "../../../api/axiosCalls";
import { setError } from "../errorSlice";
import {
  resetStepperValue,
  setIsStepper,
  updateStepperValue,
} from "../globalLoadSlice";
import {
  setFailure as patchFailure,
  setIsLoading as patchLoading,
  patchSuccess,
} from "../patchOrderSlice";
import {
  buildAddress,
  buildAddressPatch,
  buildAddressQuery,
  buildFavAddressListUpdate,
  buildNewFavAddressList,
} from "./helpers";
import { mapAddresses } from "./maps";

let initialState = {
  isLoading: false,
  isUpdateLoading: false,
  isAllAddressesLoading: false,
  isNameCheckLoading: false,
  isAttnLoading: false,
  isFavListLoading: false,
  isNextLoading: false,
  isChangeRequestsLoading: false,
  nextLink: null,
  triggerCustomCSVDownload: false,
  triggerDistributorCSVDownload: false,
  addressList: [],
  allAddressList: [],
  favoriteAddressList: [],
  editAttnList: [],
  changeRequests: [],
  isNameTaken: false,
  namedAddress: null,
  rapidAddress: null,
  championAddress: null,
  updateStatus: false,
  error: null,
};

const loadingFailed = (state, action) => {
  const { error } = action.payload;
  state.isLoading = false;
  state.isUpdateLoading = false;
  state.isAllAddressesLoading = false;
  state.isAttnLoading = false;
  state.isFavListLoading = false;
  state.isNameCheckLoading = false;
  state.isNextLoading = false;
  state.isChangeRequestsLoading = false;
  state.error = error;
};

const addressSlice = createSlice({
  name: "addresses",
  initialState,
  reducers: {
    setIsLoading(state) {
      state.isLoading = true;
    },
    setIsUpdateLoading(state) {
      state.isUpdateLoading = true;
    },
    setIsAllAddressesLoading(state) {
      state.isAllAddressesLoading = true;
    },
    setIsAttnLoading(state) {
      state.isAttnLoading = true;
    },
    setIsFavListLoading(state) {
      state.isFavListLoading = true;
    },
    setIsNameCheckLoading(state) {
      state.isNameCheckLoading = true;
    },
    setIsNextLoading(state) {
      state.isNextLoading = true;
    },
    setIsChangeRequestsLoading(state) {
      state.isChangeRequestsLoading = true;
    },
    getAllAddressSuccess(state, action) {
      const { addresses, type } = action.payload;
      state.allAddressList = [...addresses];
      if (type === "custom") {
        state.triggerCustomCSVDownload = true;
      }
      if (type === "distributor") {
        state.triggerDistributorCSVDownload = true;
      }
      state.isAllAddressesLoading = false;
      state.error = null;
    },
    getAddressesSuccess(state, action) {
      const { addresses, attn, nextLink } = action.payload;
      if (!attn) {
        state.addressList = addresses;
      } else {
        state.editAttnList = addresses;
      }
      state.isLoading = false;
      state.isAttnLoading = false;
      state.nextLink = nextLink;
      state.error = null;
    },
    getNextAddressesSuccess(state, action) {
      const { addresses, attn, nextLink } = action.payload;
      state.nextLink = nextLink;
      if (!attn) {
        state.addressList = state.addressList.concat(addresses);
      } else {
        state.editAttnList = state.editAttnList.concat(addresses);
      }
      state.isNextLoading = false;
      state.error = null;
    },
    getWarehouseSuccess(state, action) {
      const { rapid, champion } = action.payload;
      state.rapidAddress = rapid;
      state.championAddress = champion;
      state.isLoading = null;
      state.error = false;
    },
    getChangeRequestsSuccess(state, action) {
      const { changeRequests } = action.payload;
      state.changeRequests = changeRequests;
      state.isChangeRequestsLoading = false;
    },
    approveOrDenyChangeRequestsSuccess(state, action) {
      const { id, changeRequest } = action.payload;
      if (state.changeRequests.some((cr) => cr.id === id)) {
        state.changeRequests = state.changeRequests.map((cr) => {
          if (cr.id === id) {
            return {
              ...changeRequest,
            };
          } else return { ...cr };
        });
      } else {
        state.changeRequests = state.changeRequests
          .concat(changeRequest)
          .sort((a, b) => (a.id > b.id ? -1 : a.id < b.id ? 1 : 0));
      }
      if (changeRequest.changeStatus === "applied") {
        state.addressList = state.addressList.map((a) => {
          if (a.id === changeRequest.addressToChangeId) {
            return {
              ...a,
              addDisplayId: changeRequest.addDisplayId,
              type: changeRequest.type,
              name: changeRequest.name,
              streetAddressOne: changeRequest.streetAddressOne,
              streetAddressTwo: changeRequest.streetAddressTwo,
              city: changeRequest.city,
              state: changeRequest.state,
              stateCode: changeRequest.stateCode,
              zip: changeRequest.zip,
              country: changeRequest.country,
              distributorAbn: changeRequest.distributorAbn,
              attn: changeRequest.attn,
              defaultAttn: changeRequest.defaultAttn,
              userAttn: changeRequest.userAttn,
              phoneNumber: changeRequest.phoneNumber,
              onPremiseEmailAddresses: changeRequest.onPremiseEmailAddresses,
              retailEmailAddresses: changeRequest.retailEmailAddresses,
              isActive: changeRequest.isActive,
            };
          } else return a;
        });
        state.updateStatus = true;
      }
      state.isUpdateLoading = false;
    },
    createAddressSuccess(state, action) {
      const { address } = action.payload;
      if (address.type === "Change-request") {
        state.changeRequests = state.changeRequests
          .concat(address)
          .sort((a, b) => (a.id > b.id ? -1 : a.id < b.id ? 1 : 0));
      } else {
        state.addressList = state.addressList.concat(address);
      }
      state.isUpdateLoading = false;
      state.updateStatus = true;
      state.error = null;
    },
    updateAddressSuccess(state, action) {
      const { address } = action.payload;
      let updatedAddresses = state.addressList.map((add) => {
        if (add.id === address.id) {
          return address;
        } else return { ...add };
      });
      state.addressList = updatedAddresses;
      state.isUpdateLoading = false;
      state.updateStatus = true;
      state.error = null;
    },
    setUpdateSuccess(state, action) {
      const { updateStatus } = action.payload;
      state.updateStatus = updateStatus;
    },
    getFavAddressesSuccess(state, action) {
      const { addLists } = action.payload;
      state.isFavListLoading = false;
      state.favoriteAddressList = [...addLists];
    },
    createNewFavoriteAddressList(state, action) {
      const { id, name } = action.payload;
      let newAddressList = {
        id: id,
        name: name,
        addresses: [],
      };
      let updatedLists = state.favoriteAddressList.concat([newAddressList]);
      state.favoriteAddressList = updatedLists;
      state.isUpdateLoading = false;
    },
    updateAddressList(state, action) {
      const { id, list } = action.payload;
      let updatedAddresses = state.favoriteAddressList.map((addList) => {
        if (addList.id === id) {
          return {
            ...list,
          };
        } else return { ...addList };
      });
      state.favoriteAddressList = updatedAddresses;
    },
    deleteSingleAddress(state, action) {
      const { id, addressId } = action.payload;
      let updatedAddresses = state.favoriteAddressList.map((addList) => {
        if (addList.id === id) {
          return {
            ...addList,
            addresses: addList.addresses.filter((add) => add.id !== addressId),
          };
        } else return { ...addList };
      });
      state.favoriteAddressList = updatedAddresses;
    },
    deleteAddressList(state, action) {
      const { id } = action.payload;
      let updatedAddresses = state.favoriteAddressList.filter(
        (addList) => addList.id !== id
      );
      state.favoriteAddressList = updatedAddresses;
      state.isUpdateLoading = false;
    },
    updateAttnSuccess(state, action) {
      const { id, attn } = action.payload;
      let updatedAddresses = state.editAttnList.map((add) => {
        if (add.id === id) {
          return {
            ...add,
            attn: attn,
          };
        } else return { ...add };
      });
      state.editAttnList = updatedAddresses;
      state.isLoading = false;
      state.error = null;
    },
    setTriggerCSVFalse(state) {
      state.triggerCustomCSVDownload = false;
      state.triggerDistributorCSVDownload = false;
    },
    clearAllAddressList(state) {
      state.allAddressList = [];
    },
    setNameTaken(state, action) {
      const { value, address } = action.payload;
      state.isNameTaken = value;
      state.namedAddress = address;
      state.isNameCheckLoading = false;
    },
    clearAddressList(state) {
      state.addressList = [];
      state.nextLink = null;
    },
    resetAddresses(state) {
      state.isLoading = false;
      state.isUpdateLoading = false;
      state.isAllAddressesLoading = false;
      state.isNameCheckLoading = false;
      state.triggerCustomCSVDownload = false;
      state.triggerDistributorCSVDownload = false;
      state.isChangeRequestsLoading = false;
      state.addressList = [];
      state.allAddressList = [];
      state.editAttnList = [];
      state.changeRequests = [];
      state.isNameTaken = false;
      state.namedAddress = null;
      state.rapidAddress = null;
      state.championAddress = null;
      state.error = null;
    },
    setFailure: loadingFailed,
  },
});

export const {
  setIsLoading,
  setIsUpdateLoading,
  setIsAllAddressesLoading,
  setIsAttnLoading,
  setIsFavListLoading,
  setIsNameCheckLoading,
  setIsNextLoading,
  setIsChangeRequestsLoading,
  getAllAddressSuccess,
  getAddressesSuccess,
  getNextAddressesSuccess,
  getWarehouseSuccess,
  getChangeRequestsSuccess,
  approveOrDenyChangeRequestsSuccess,
  createAddressSuccess,
  updateAddressSuccess,
  setTriggerCSVFalse,
  setUpdateSuccess,
  getFavAddressesSuccess,
  createNewFavoriteAddressList,
  updateAddressList,
  deleteAddressList,
  deleteSingleAddress,
  updateAttnSuccess,
  setNameTaken,
  clearAllAddressList,
  clearAddressList,
  resetAddresses,
  setFailure,
} = addressSlice.actions;

export default addressSlice.reducer;

export const fetchInitialAddresses = (territoryId) => async (dispatch) => {
  try {
    dispatch(setIsLoading());
    let queryString =
      "/api/addresses?filter[type]=custom&filter[is-active]=true";
    if (territoryId) {
      queryString += `&filter[territory-id]=${territoryId}`;
    }
    const response = await axiosGetWithNext(queryString);
    if (response.error) throw response.error;
    const mappedData = mapAddresses(response.data.data);
    dispatch(
      getAddressesSuccess({
        addresses: mappedData,
        attn: null,
        nextLink: response.data.nextLink,
      })
    );
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Addresses" }));
  }
};

export const fetchAddressReportByType = (type) => async (dispatch) => {
  try {
    dispatch(
      setIsStepper({
        stepBool: true,
        stepTitle: `Generating ${type[0].toUpperCase() + type.slice(1)} Report`,
      })
    );
    let addArray = [];
    let stepValue = 10;

    let initialResponse = await axiosGetWithNext(
      `/api/addresses?filter[type]=${type}&filter[is-active]=true`
    );
    if (initialResponse.error) throw initialResponse.error;

    let initialMappedData = mapAddresses(initialResponse.data.data);
    addArray = addArray.concat(initialMappedData);

    let nextLink = initialResponse.data.nextLink;
    dispatch(updateStepperValue({ value: stepValue }));

    if (nextLink) {
      const pageSize = initialResponse.data.data.length;
      let fetchCount =
        Math.ceil(initialResponse.data.totalEntries / pageSize) - 1;
      stepValue = parseFloat((90 / fetchCount).toFixed(2));

      for (let i = 0; i < fetchCount; i++) {
        let nextResponse = await await axiosGetWithNext(nextLink);
        if (nextResponse.error) {
          throw nextResponse.error;
        }
        nextLink = nextResponse.data.nextLink;
        let mappedData = mapAddresses(nextResponse.data.data);
        addArray = addArray.concat(mappedData);
        dispatch(updateStepperValue({ value: stepValue }));
      }
    } else {
      dispatch(updateStepperValue({ value: 90 }));
    }

    dispatch(resetStepperValue());
    dispatch(getAllAddressSuccess({ addresses: addArray, type: type }));
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Address Reports" }));
    dispatch(resetStepperValue());
  }
};

export const fetchWarehouse = () => async (dispatch) => {
  try {
    dispatch(setIsLoading());
    const response = await axiosGet(
      "/api/addresses?filter[type]=warehouse&filter[is-active]=true"
    );
    if (response.error) throw response.error;
    let rapid = response.data.find((add) => add.warehouse === "rapid");
    let champion = response.data.find((add) => add.warehouse === "champion");

    rapid = rapid ? mapAddresses([rapid])[0] : null;
    champion = champion ? mapAddresses([champion])[0] : null;

    dispatch(getWarehouseSuccess({ rapid: rapid, champion: champion }));
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Addresses" }));
  }
};

export const fetchAddresses =
  (search, territoryId, stateIds, attn = false, type, isActive = true) =>
  async (dispatch) => {
    try {
      if (attn) {
        dispatch(setIsAttnLoading());
      } else {
        dispatch(setIsLoading());
      }
      const queryString = buildAddressQuery(
        search,
        territoryId,
        stateIds,
        type,
        isActive
      );
      const response = await axiosGetWithNext(queryString);
      if (response.error) throw response.error;
      const mappedAddresses = mapAddresses(response.data.data).filter(
        (add) => add.type !== "Warehouse"
      );
      dispatch(
        getAddressesSuccess({
          addresses: mappedAddresses,
          attn: attn,
          nextLink: response.data.nextLink,
        })
      );
    } catch (err) {
      dispatch(setFailure({ error: err.toString() }));
      dispatch(setError({ error: err.toString(), source: "Addresses" }));
    }
  };

export const fetchAnyNextAddresses =
  (url, attn = false) =>
  async (dispatch) => {
    try {
      dispatch(setIsNextLoading());
      const response = await axiosGetWithNext(url);
      if (response.error) throw response.error;
      const mappedAddresses = mapAddresses(response.data.data).filter(
        (add) => add.type !== "Warehouse"
      );
      dispatch(
        getNextAddressesSuccess({
          addresses: mappedAddresses,
          attn: attn,
          nextLink: response.data.nextLink,
        })
      );
    } catch (err) {}
  };

export const fetchAddressChangeRequests = (id) => async (dispatch) => {
  try {
    dispatch(setIsChangeRequestsLoading());
    const response = await axiosGet(
      `/api/address-change-requests?filter[address-to-change-id]=${id}`
    );
    if (response.error) throw response.error;
    const mappedData = mapAddresses(response.data).sort((a, b) =>
      a.id > b.id ? -1 : a.id < b.id ? 1 : 0
    );
    dispatch(getChangeRequestsSuccess({ changeRequests: mappedData }));
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Addresses" }));
  }
};

export const createChangeRequestAndApply = (address) => async (dispatch) => {
  try {
    dispatch(patchLoading());
    dispatch(setIsUpdateLoading());
    const postData = buildAddress(address);
    const addressResponse = await await axiosPost("/api/addresses", postData);
    if (addressResponse.error) throw addressResponse.error;
    const applyPostData = {
      "apply-now": true,
      status: "approved",
    };
    const approveResponse = await axiosPost(
      `/api/address-change-requests/${addressResponse.data.id}/approve-or-deny`,
      applyPostData
    );
    if (approveResponse.error) throw approveResponse.error;
    const mappedData = mapAddresses([approveResponse.data])[0];
    dispatch(
      approveOrDenyChangeRequestsSuccess({
        id: addressResponse.data.id,
        changeRequest: mappedData,
      })
    );
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Addresses" }));
  }
};

export const approveOrDeny = (id, applyNow, status) => async (dispatch) => {
  try {
    dispatch(setIsUpdateLoading());
    const postData = {
      "apply-now": applyNow,
      status: status,
    };
    const response = await axiosPost(
      `/api/address-change-requests/${id}/approve-or-deny`,
      postData
    );
    if (response.error) throw response.error;
    const mappedData = mapAddresses([response.data])[0];
    dispatch(
      approveOrDenyChangeRequestsSuccess({
        id: id,
        changeRequest: mappedData,
      })
    );
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Addresses" }));
  }
};

export const checkName = (name) => async (dispatch) => {
  try {
    dispatch(setIsNameCheckLoading());
    const queryString = buildAddressQuery(name, null, null, "custom", true);
    const response = await axiosGetWithNext(queryString);
    if (response.error) throw response.error;
    let matchedAddress;
    if (response.data.length > 0) {
      matchedAddress = response.data.find(
        (add) => add.name.trim() === name.trim()
      );
    }
    dispatch(
      setNameTaken({
        value: Boolean(matchedAddress),
        address: matchedAddress ? mapAddresses([matchedAddress])[0] : null,
      })
    );
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Addresses" }));
  }
};

export const addNewAddress = (address) => async (dispatch) => {
  try {
    dispatch(patchLoading());
    dispatch(setIsUpdateLoading());
    const postData = buildAddress(address);
    const response = await axiosPost("/api/addresses", postData);
    if (response.error) throw response.error;
    const mappedData = mapAddresses([response.data])[0];
    dispatch(createAddressSuccess({ address: mappedData }));
    dispatch(patchSuccess());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(patchFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Addresses" }));
  }
};

export const updateAddressById = (id, address) => async (dispatch) => {
  try {
    dispatch(patchLoading());
    dispatch(setIsUpdateLoading());
    const patchData = buildAddressPatch(address);
    const response = await axiosPatch(`/api/addresses/${id}`, patchData);
    if (response.error) throw response.error;
    const mappedData = mapAddresses([response.data])[0];
    dispatch(updateAddressSuccess({ address: mappedData }));
    dispatch(patchSuccess());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(patchFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Addresses" }));
  }
};

export const fetchFavAddresses = (territoryId) => async (dispatch) => {
  try {
    dispatch(setIsFavListLoading());
    const queryString = !territoryId
      ? "/api/address-favorite-sets"
      : `/api/address-favorite-sets?filter[territory-ids]=${territoryId}`;
    const response = await axiosGet(queryString);
    if (response.error) throw response.error;
    const mappedData = response.data.map((favList) => ({
      id: favList.id,
      name: favList.name,
      addresses: [...mapAddresses(favList.addresses)],
    }));
    dispatch(getFavAddressesSuccess({ addLists: mappedData }));
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Addresses" }));
  }
};

export const newFavoriteAddressList =
  (index, territoryId) => async (dispatch) => {
    try {
      dispatch(setIsUpdateLoading());
      dispatch(patchLoading());
      const postData = buildNewFavAddressList(index + 1, territoryId);
      const response = await axiosPost("/api/address-favorite-sets", postData);
      if (response.error) throw response.error;
      dispatch(
        createNewFavoriteAddressList({
          id: response.data.id,
          name: response.data.name,
        })
      );
      dispatch(patchSuccess());
    } catch (err) {
      dispatch(setFailure({ error: err.toString() }));
      dispatch(patchFailure({ error: err.toString() }));
      dispatch(setError({ error: err.toString(), source: "Addresses" }));
    }
  };

export const updateFavoriteAddressList =
  (id, name, addArray, territoryId) => async (dispatch) => {
    try {
      dispatch(setIsUpdateLoading());
      dispatch(patchLoading());
      const formattedAddArray = addArray
        .map((list) => ({
          id: list.id,
          type: "address",
        }))
        .filter((add) => add.id);
      const patchData = buildFavAddressListUpdate(
        id,
        name,
        formattedAddArray,
        territoryId
      );
      const response = await axiosPatch(
        `/api/address-favorite-sets/${id}`,
        patchData
      );
      if (response.error) throw response.error;
      const formattedList = {
        id: id,
        addresses: mapAddresses(response.data.addresses),
        name: response.data.name,
      };
      dispatch(updateAddressList({ id: id, list: formattedList }));
      dispatch(patchSuccess());
    } catch (err) {
      dispatch(setFailure({ error: err.toString() }));
      dispatch(patchFailure({ error: err.toString() }));
      dispatch(setError({ error: err.toString(), source: "Addresses" }));
    }
  };

export const deleteFavoriteAddressList = (id) => async (dispatch) => {
  try {
    dispatch(setIsUpdateLoading());
    dispatch(patchLoading());
    const response = await axiosDelete(
      `/api/address-favorite-sets/${id}`,
      null
    );
    if (response.error) throw response.error;
    dispatch(deleteAddressList({ id: id }));
    dispatch(patchSuccess());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(patchFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Addresses" }));
  }
};

export const setCustomAttention = (id, attn) => async (dispatch) => {
  try {
    dispatch(setIsLoading());
    dispatch(patchLoading());
    const patchData = {
      "user-attn": attn,
    };
    const response = await axiosPatch(
      `/api/addresses/${id}/current-user-attn`,
      patchData
    );
    if (response.error) throw response.error;
    dispatch(updateAttnSuccess({ id, attn }));
    dispatch(patchSuccess());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(patchFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Addresses" }));
  }
};
