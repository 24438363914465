/** @jsxImportSource @emotion/react */
import "twin.macro";

import React, { useCallback, useEffect, useState } from "react";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
import Helmet from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import GetAppIcon from "@mui/icons-material/GetApp";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import ViewStreamIcon from "@mui/icons-material/ViewStream";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";

import _ from "lodash";
import PropTypes from "prop-types";
import HideFiltersButtonToggle from "src/components/Filtering/HideFiltersButtonToggle";
import { handleBulkOrder } from "src/components/Ordering/helpers";

import { useCurrentRegionalTerritorySubStates } from "@features/subStates";
import { CSVLink } from "@utils/csv";

import FilterChipList from "../components/Filtering/FilterChipList";
import ItemPreviewModal from "../components/ItemPreview/ItemPreviewModal";
import OrderVariantViewControl from "../components/Ordering/OrderVariantViewControl";
import VariantModal from "../components/Ordering/VariantModal";
import Loading from "../components/Utility/Loading";
import { useChannelUpdate, useInitialFilters } from "../hooks/UtilityHooks";
import { setSorted, updateMultipleFilters } from "../redux/slices/filterSlice";
import {
  clearItemReport,
  fetchItemReport,
  fetchNextFilteredItems,
  setTriggerCSVFalse,
} from "../redux/slices/items/itemSlice";
import {
  clearItemSelections,
  fetchCurrentOrderByType,
} from "../redux/slices/ordering/currentOrderSlice";
import { setIsOrdering } from "../redux/slices/ordering/orderSetSlice";
import { formatMoney } from "../utility/utilityFunctions";

/*
This page holds all the logic for placing inventory (inventory) orders for users. Originally gallo
referred to this as inventory orders, but then changed it to inventory. The api still currently refers
to this as inventory, so be aware that in the case of RTA, inventory and inventory are synonomous.
*/

const defaultFilters = {
  brand: [],
  itemType: [],
  bu: [],
  program: [],
  favItems: [],
  exclusiveTerritory: [],
  orderType: "inventory",
  itemNumber: "",
  itemDesc: "",
  isItemOrderable: true,
  sortOrder: null,
  sortOrderBy: null,
};

const useStyles = makeStyles((theme) => ({
  ...theme.global,
}));

const PlaceInventoryOrder = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const nextLink = useSelector((state) => state.items.nextLink);
  const isNextLoading = useSelector((state) => state.items.isNextLoading);

  const handleBottomScroll = () => {
    if (nextLink && !isNextLoading) {
      if (scrollRef.current && scrollRef.current.scrollTop !== 0) {
        dispatch(fetchNextFilteredItems(nextLink));
      }
    }
  };

  const scrollRef = useBottomScrollListener(handleBottomScroll, {
    offset: 500,
    debounceOptions: {
      leading: true,
      trailing: false,
    },
  });
  const [currentCSV, setCurrentCSV] = useState({ data: [], headers: [] });
  const [currentView, setView] = useState("list");
  const [previewModal, handlePreviewModal] = useState(false);
  const [currentItem, handleCurrentItem] = useState({});
  const [isVariantModalOpen, setVariantModalOpen] = useState(false);

  const currentItems = useSelector((state) => state.items.items);
  const currentChannel = useSelector((state) => state.user.currentChannel);
  const itemsLoading = useSelector((state) => state.items.isLoading);
  const orderLoading = useSelector((state) => state.currentOrder.isLoading);

  const selectedItems = useSelector(
    (state) => state.currentOrder.selectedInventoryItems
  );
  const currentOrder = useSelector((state) => state.currentOrder);
  const isAllRegions = useSelector(
    (state) => state.currentOrder.inventoryOrderIsAllRegions
  );
  const userId = useSelector((state) => state.user.id);
  const currentUserRole = useSelector((state) => state.user.role);
  const territoryId = useSelector((state) => state.user.currentTerritory);
  const isOrdering = useSelector((state) => state.orderSet.isOrdering);
  const orderTerritoryId = useSelector(
    (state) => state.currentOrder.inventoryOrderTerritory
  );
  const orderChannel = useSelector(
    (state) => state.currentOrder.inventoryChannel
  );
  const isUpdateLoading = useSelector(
    (state) => state.currentOrder.orderUpdateLoading
  );
  const itemReport = useSelector((state) => state.items.itemReport);
  const triggerCSV = useSelector((state) => state.items.triggerCSVDownload);
  const filterObject = useSelector((state) => state.filters);

  defaultFilters.channel = currentChannel;
  defaultFilters.currentTerritoryId = territoryId;

  const { isRegional, subStateIds } = useCurrentRegionalTerritorySubStates();

  const handleTerritoryQty = useCallback(
    (variants) => {
      const allocations = variants.flatMap((v) => v.variantAllocations);
      const matchingAllocations = allocations.filter((t) =>
        isRegional
          ? subStateIds.includes(t.subStateId)
          : t.territoryId === territoryId
      );
      if (matchingAllocations.length > 0) {
        return _.sumBy(matchingAllocations, "qty");
      } else return "---";
    },
    [isRegional, subStateIds, territoryId]
  );

  const handlePreview = (itemNumber) => {
    let item = currentItems.find((item) => item.itemNumber === itemNumber);
    handleCurrentItem(item);
    handlePreviewModal(true);
    dispatch(clearItemSelections());
  };

  const handleModalClose = () => {
    handlePreviewModal(false);
  };

  const handleAddToOrder = () => {
    // Get the ids of all the selected item's variants
    let variantIds = currentItems
      .filter((i) => selectedItems.includes(i.id))
      .reduce((acc, item) => acc.concat(item.variants.map((v) => v.id)), []);

    handleBulkOrder({
      variantIds,
      selectedItems,
      currentOrder,
      dispatch,
      territoryId,
      channel: currentChannel,
      currentItems,
      setVariantModalOpen,
      type: "inventory",
    });
  };

  const handleVariantModalClose = () => setVariantModalOpen(false);

  const handleItemReport = () => {
    dispatch(fetchItemReport(filterObject));
  };

  const handleSort = (sortObject) => {
    scrollRef.current.scrollTop = 0;
    dispatch(
      updateMultipleFilters({
        filterObject: {
          sortOrder: sortObject.order,
          sortOrderBy: sortObject.orderBy,
        },
      })
    );
    dispatch(setSorted());
  };

  useEffect(() => {
    dispatch(
      fetchCurrentOrderByType("inventory", userId, currentChannel, territoryId)
    );
  }, [dispatch, currentChannel, territoryId, userId]);

  useEffect(() => {
    if (triggerCSV && currentCSV.data.length === 0) {
      const csvHeaders = [
        { label: "Sequence #", key: "itemNumber" },
        { label: "Brand", key: "brand" },
        { label: "Program", key: "program" },
        { label: "Item Type", key: "itemType" },
        { label: "Item Desc.", key: "itemDescription" },
        { label: "Pack Size", key: "packSize" },
        { label: "On Hand Nat.", key: "stock" },
        { label: "On Hand Terr.", key: "terrStock" },
        { label: "Est. Cost", key: "estCost" },
      ];
      const csvData = [];
      itemReport.forEach((item) => {
        csvData.push({
          itemNumber: item.itemNumber,
          brand: item.brand.join(", "),
          program: item.program.join(", "),
          itemType: item.itemType,
          itemDescription: item.itemDescription,
          packSize: item.packSize,
          stock: !item.warehouse
            ? "Currently Unavailable"
            : item.stock <= 0
            ? "Out of Stock"
            : item.stock,
          terrStock: handleTerritoryQty(item.variants),
          estCost: formatMoney(item.estCost, false),
        });
      });
      setCurrentCSV({ data: csvData, headers: csvHeaders });
    }
  }, [itemReport, triggerCSV, currentCSV, handleTerritoryQty]);

  useChannelUpdate(currentChannel, filterObject.channel, dispatch);

  useInitialFilters("item-inventory", defaultFilters);

  useEffect(() => {
    if (!isAllRegions && !orderTerritoryId && isOrdering) {
      dispatch(setIsOrdering({ status: false }));
    }
  }, [orderTerritoryId, isAllRegions, isOrdering, dispatch]);

  useEffect(() => {
    dispatch(setTriggerCSVFalse());
    dispatch(clearItemReport());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!triggerCSV && currentCSV.data.length > 0) {
      setCurrentCSV({
        data: [],
        headers: [],
      });
    }
  }, [triggerCSV, currentCSV, setCurrentCSV]);

  if (orderLoading) {
    return <Loading />;
  }

  return (
    <>
      <Helmet>
        <title>RTA | Place Inventory Order</title>
      </Helmet>
      {previewModal && (
        <ItemPreviewModal
          type={"inventory"}
          currentItem={currentItem}
          handleClose={handleModalClose}
          previewModal={previewModal}
        />
      )}
      {isVariantModalOpen && (
        <VariantModal
          selectedItems={selectedItems}
          currentItems={currentItems}
          territoryId={territoryId}
          channel={currentChannel}
          orderType={"inventory"}
          currentOrder={currentOrder}
          open={isVariantModalOpen}
          handleClose={handleVariantModalClose}
        />
      )}
      <Container className={classes.mainWrapper}>
        <div className={classes.titleBar}>
          <Typography className={classes.titleText} variant="h5">
            Place an Inventory Order
          </Typography>

          <div className={classes.innerConfigDiv}>
            {!["compliance", "read-only"].includes(currentUserRole) && (
              <>
                <Button
                  className={classes.largeButton}
                  variant="contained"
                  color="secondary"
                  disabled={
                    selectedItems.length === 0 ||
                    (orderChannel && currentChannel !== orderChannel)
                  }
                  onClick={handleAddToOrder}
                  style={{ marginRight: "20px" }}
                >
                  ADD TO ORDER
                </Button>

                <Button
                  component={Link}
                  disabled={isUpdateLoading || !currentOrder.orderId}
                  to={`/orders/open/${currentOrder.orderId}`}
                  className={classes.largeButton}
                  variant="contained"
                  color="secondary"
                  style={{ marginRight: "20px" }}
                >
                  VIEW ORDER
                </Button>
              </>
            )}
            {!triggerCSV && currentCSV.data.length === 0 && (
              <span>
                <Button
                  className={classes.largeButton}
                  variant="contained"
                  onClick={handleItemReport}
                  disabled={
                    currentItems.length === 0 ||
                    (isRegional && subStateIds.length === 0)
                  }
                >
                  GENERATE REPORT
                </Button>
              </span>
            )}
            {triggerCSV && currentCSV.data.length > 0 && (
              <CSVLink
                data={currentCSV.data}
                headers={currentCSV.headers}
                filename={`inventory_items_${new Date().toLocaleDateString()}.csv`}
              >
                <Button
                  className={classes.largeButton}
                  variant="contained"
                  color="secondary"
                  startIcon={<GetAppIcon />}
                >
                  EXPORT REPORT
                </Button>
              </CSVLink>
            )}
            <Tooltip title="View List">
              <IconButton
                onClick={() => {
                  setView("list");
                }}
                size="large"
              >
                <ViewStreamIcon
                  fontSize="large"
                  color={currentView === "list" ? "primary" : "inherit"}
                />
              </IconButton>
            </Tooltip>
            <Tooltip title="View Grid">
              <IconButton
                onClick={() => {
                  setView("grid");
                }}
                size="large"
              >
                <ViewModuleIcon
                  fontSize="large"
                  color={currentView === "grid" ? "primary" : "inherit"}
                />
              </IconButton>
            </Tooltip>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignContent: "center",
            marginBottom: "10px",
          }}
        >
          <HideFiltersButtonToggle />
          <FilterChipList classes={classes} />
          <br />
        </div>
        <OrderVariantViewControl
          type={"inventory"}
          currentView={currentView}
          handlePreview={handlePreview}
          items={currentItems}
          isItemsLoading={itemsLoading}
          scrollRef={scrollRef}
          handleSort={handleSort}
        />
        {isNextLoading && (
          <div style={{ width: "100%" }}>
            <LinearProgress />
          </div>
        )}
        {!isNextLoading && <div style={{ width: "100%", height: "4px" }}></div>}
      </Container>
      <br />
    </>
  );
};

PlaceInventoryOrder.propTypes = {
  userType: PropTypes.string,
};

export default PlaceInventoryOrder;
