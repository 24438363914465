import React from "react";
import { Link } from "react-router-dom";

import {
  Chip,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import PropTypes from "prop-types";

import ImageWrapper from "../../Utility/ImageWrapper";
import StatusChip from "../../Utility/StatusChip";

const statusMap = {
  draft: "info",
  submitted: "success",
  canceled: "error",
};

const headCells = [
  { id: "imgUrl", label: "" },
  {
    id: "id",
    label: "Program Id",
  },
  {
    id: "status",
    label: "Status",
  },
  { id: "workfrontId", label: "Workfront ID" },
  { id: "brands", label: "Brands" },
  { id: "program", label: "Name" },
  { id: "type", label: "Type" },
  { id: "inMarketYear", label: "In Market Date" },
  // { id: "orderWindowMonthYear", label: "Order Window" },
  { id: "count", label: "Count of Items" },
];

const PlanningTableHead = ({ classes }) => (
  <TableHead>
    <TableRow>
      {headCells.map((headCell) => {
        return (
          <TableCell
            className={classes.headerText}
            key={headCell.id}
            align="left"
          >
            {headCell.label}
          </TableCell>
        );
      })}
    </TableRow>
  </TableHead>
);

PlanningTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
};

const useStyles = makeStyles((theme) => ({
  ...theme.global,
}));

const PlanningToolTable = ({ programs, isLoading, scrollRef }) => {
  const classes = useStyles();
  return (
    <>
      <TableContainer
        className={classes.tableContainer}
        style={{ maxHeight: "Calc(100vh - 275px)" }}
        ref={scrollRef}
      >
        <Table
          stickyHeader
          className={classes.table}
          aria-label="planning-tool-programs-table"
        >
          <PlanningTableHead classes={classes} />
          <TableBody>
            {!isLoading && programs.length === 0 && (
              <TableRow>
                <TableCell
                  align="left"
                  colSpan={headCells.length}
                  className={classes.headerText}
                >
                  There are currently no programs that match this search
                  criteria..
                </TableCell>
              </TableRow>
            )}
            {!isLoading &&
              programs.length > 0 &&
              programs.map((row) => (
                <TableRow key={row.id}>
                  <TableCell>
                    <ImageWrapper
                      id={row.id}
                      imgClass={classes.previewImageFloat}
                      alt="Program Image"
                      imgUrl={row.imgUrl}
                      handleClick={(evt) => {
                        evt.stopPropagation();
                      }}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <Chip
                      label={row.id}
                      component={Link}
                      to={`/planning/program/${row.id}`}
                      variant="outlined"
                      clickable
                    />
                  </TableCell>
                  <TableCell align="left">
                    <StatusChip
                      status={statusMap[`${row.status}`]}
                      label={row.status}
                    />
                  </TableCell>
                  <TableCell align="left">{row.externalWorkfrontId}</TableCell>
                  <TableCell align="left">
                    {row.brandAssignmentLevel === "item"
                      ? "Multi-brand"
                      : row.brands.map((brand) => brand.name).join(", ")}
                  </TableCell>
                  <TableCell align="left">{row.name}</TableCell>
                  <TableCell align="left">{row.type}</TableCell>
                  <TableCell align="left">{row.startDate}</TableCell>
                  <TableCell align="left">{row.itemCount}</TableCell>
                </TableRow>
              ))}
            {isLoading && (
              <TableRow>
                <TableCell align="left" colSpan={headCells.length}>
                  <CircularProgress />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

PlanningToolTable.propTypes = {
  programs: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  scrollRef: PropTypes.any,
};

export default React.memo(PlanningToolTable, (prev, next) => {
  return (
    prev.programs.length === next.programs.length &&
    prev.isLoading === next.isLoading
  );
});

// export default PlanningToolTable;
