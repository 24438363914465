import tw, { styled } from "twin.macro";

import { Tooltip, TooltipProps, tooltipClasses } from "@mui/material";

const PaperTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: tw`p-3 mt-3 text-sm font-normal bg-white border shadow-lg border-neutral-400 text-neutral-700`,
});

export default PaperTooltip;
