/** @jsxImportSource @emotion/react */
import "twin.macro";

import { Controller, ControllerProps } from "react-hook-form";

import { AutocompleteChangeDetails, AutocompleteValue } from "@mui/material";

import { routeResourceTypes } from "@services/api";

import {
  ResourceAutocomplete,
  ResourceAutocompleteProps,
} from "./ResourceAutocomplete";

export const ControlledResourceAutocomplete = <
  TResourceName extends keyof routeResourceTypes,
  Multiple extends boolean = false,
  DisableClearble extends boolean = false,
>({
  name,
  control,
  rules,
  onChange,
  ...props
}: Pick<ControllerProps<any>, "control" | "rules" | "name"> &
  ResourceAutocompleteProps<
    TResourceName,
    routeResourceTypes[TResourceName],
    Multiple,
    DisableClearble
  >) => {
  type TValue = AutocompleteValue<
    routeResourceTypes[TResourceName],
    Multiple,
    DisableClearble,
    false
  >;
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { ref: _, ...field } }) => (
        <ResourceAutocomplete
          {...field}
          {...(props as any)}
          value={(field.value ?? (props.multiple ? [] : null)) as TValue}
          onChange={(
            e,
            value: TValue,
            reason,
            details?: AutocompleteChangeDetails<
              routeResourceTypes[TResourceName]
            >
          ) => {
            if (Array.isArray(value)) {
              field.onChange(value?.map((v) => (v.id ? v.id : v)));
            } else {
              field.onChange(value?.id ?? value);
            }
            onChange?.(e, value, reason, details);
          }}
        />
      )}
    />
  );
};
