import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { Box, Button, Grid, Typography } from "@mui/material";
import { Alert } from "@mui/material";

import { sendQuoteAction } from "../../../redux/slices/planningTool/rfqSlice";
import CostTable from "./CostTable";
import PricingTierQuotesTable from "./PricingTierQuotesTable";
import QuoteForm from "./QuoteForm";

const validateTiers = (tiers) =>
  tiers.every((t) => t.price && t.leadTimeInDays);

const QuoteAccepted = ({ currentQuote, classes }) => {
  const dispatch = useDispatch();
  const [errors, setErrors] = useState(null);
  const {
    id,
    pricingTierQuotes,
    preProCost,
    preProLeadTimeInDays,
    itemDimensions,
    pricingBasedOn,
  } = currentQuote;

  const handleComplete = () => {
    setErrors(null);
    try {
      if (!validateTiers(pricingTierQuotes))
        throw new Error("All quote pricing tiers must be filled");
      else if (!preProCost) throw new Error("Pre pro cost is required");
      else if (!preProLeadTimeInDays)
        throw new Error("Pre pro lead time in days is required");
      else if (!itemDimensions) throw new Error("Item dimensions are required");
      else if (!pricingBasedOn) throw new Error("Pricing based on is required");

      dispatch(sendQuoteAction("complete", id));
    } catch (error) {
      setErrors(error.message);
    }
  };

  const handleCancel = () => dispatch(sendQuoteAction("decline", id));

  return (
    <>
      <Grid container direction="row" className={classes.container} spacing={4}>
        <Box sx={{ mt: -2 }}>Note from Gallo:</Box>
        <Box
          component={Grid}
          item
          xs={12}
          sx={{
            mb: 2,
            mt: 1,
            border: "1px solid #C1C1C1",
            borderRadius: "16px",
            color: "#4C4C4C",
          }}
        >
          <Typography className={classes.wrapNewlines} variant="body2">
            {currentQuote.rfqNote}
          </Typography>
        </Box>
        <Grid item xs={12}>
          <Typography variant="subtitle2">
            Required fields are marked with *
          </Typography>
        </Grid>
        <Grid item xs={12} lg={6}>
          <QuoteForm />
        </Grid>
        <Grid item xs={12} lg={6}>
          <PricingTierQuotesTable />
          <br />
          <Typography variant="h6">Set up costs</Typography>
          <CostTable type="set-up" />
          <br />
          <Typography variant="h6">Per unit costs</Typography>
          <CostTable type="per-unit" />
        </Grid>
        {errors && (
          <Grid item xs={12}>
            <Alert severity="error">{errors}</Alert>
          </Grid>
        )}
      </Grid>
      <Box sx={{ background: "whitesmoke", borderRadius: "16px" }}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          className={classes.container}
          style={{
            padding: "16px",
          }}
          spacing={4}
        >
          <Button
            variant="outlined"
            className={classes.cancelBtn}
            onClick={handleCancel}
          >
            Decline quote
          </Button>
          <Button
            variant="contained"
            className={classes.largeButton}
            onClick={handleComplete}
          >
            Complete quote
          </Button>
        </Grid>
      </Box>
      <br />
    </>
  );
};

export default QuoteAccepted;
