/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useRef, useState } from "react";
import { CSVReader } from "react-papaparse";
import { useDispatch, useSelector } from "react-redux";

import { GetApp, Publish } from "@mui/icons-material";

import { useQueryClient } from "@tanstack/react-query";
import _ from "lodash";
import { StyledButton } from "src/components/StyledComponents";
import Loading from "src/components/Utility/Loading";
import { setError } from "src/redux/slices/errorSlice";
import {
  resetStepperValue,
  setIsStepper,
  updateStepperValue,
} from "src/redux/slices/globalLoadSlice";
import { roundUp } from "src/utility/utilityFunctions";

import { Address } from "@models/Address";
import { OrderSetVariant } from "@models/OrderSetVariant";
import client from "@services/api";
import asyncPool from "@utils/asyncPool";
import { downloadAsCsv } from "@utils/csv";

import { orderSetsKeyFactory } from "../queries/orderSetQueries";
import { useCurrentOrderSet } from "./data/CurrentOrderSetContext";
import useCreateOrder from "./data/mutations/useCreateOrder";
import useSetOrderVariantQty from "./data/mutations/useSetOrderVariantQty";

const ABN_ID = "ABN / ID";

const makeCsvTemplate = (orderSetVariants: OrderSetVariant[]) => [
  [ABN_ID, ...orderSetVariants.map((v) => v.variant.variantSku)],
];

const parseCustomAddressId = (id: string) => id.replace(/^c/, "");

const UploadOrdersButtons = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const territory = useSelector((state: any) => state.user.currentTerritory);
  const { orderSet, orderSetVariants } = useCurrentOrderSet();

  const createOrder = useCreateOrder();
  const setOrderVariantQty = useSetOrderVariantQty();
  const csvRef = useRef<CSVReader>(null);
  const [loading, setLoading] = useState(false);

  const handleUploadOrder = (row: any, rows: any[]) =>
    createOrder
      .mutateAsync({
        type: orderSet?.type,
        address: { type: "address", id: row.id },
        orderSet: { type: "order-set", id: orderSet?.id },
        relationshipNames: ["address", "orderSet"],
      })
      .then(async ({ order }) => {
        const ovRes = await asyncPool(5, order.orderVariants, (ov) => {
          const qty = Number(row[ov.variant.variantSku]) || 0;
          if (qty === 0) return;
          return setOrderVariantQty.mutateAsync({
            id: ov.id,
            qty: +roundUp(qty, ov.qtyPerPack),
          });
        });
        dispatch(
          updateStepperValue({
            value: (1 / rows.length) * 100,
          })
        );

        return ovRes;
      })
      .catch(() =>
        Promise.reject(new Error(`Error adding address ${row[ABN_ID]}`))
      );

  const handleFileUpload = async (data: any[]) => {
    try {
      const orderData = _.map(data, "data");
      // Remove last row if all values are empty
      while (_.every(_.values(_.last(orderData)), _.isEmpty)) {
        orderData.pop();
      }

      dispatch(
        setIsStepper({
          stepBool: true,
          stepTitle: "Uploading orders",
        })
      );
      dispatch(updateStepperValue({ value: 0 }));

      const validatedRowsWithIds = await validateAddressIds(
        orderData,
        orderSetVariants ?? [],
        territory
      );

      const res = await asyncPool(2, validatedRowsWithIds, handleUploadOrder);

      queryClient.invalidateQueries(orderSetsKeyFactory.detail(orderSet.id));
      if (res.errors) {
        dispatch(
          setError({
            error: res.errors.map((e) => e.message).join("\n"),
            source: "Upload Orders",
          })
        );
      }
    } catch (err: any) {
      dispatch(
        setError({
          error: err.message,
          source: "Upload Orders",
        })
      );
    } finally {
      setLoading(false);
      dispatch(resetStepperValue());
    }
  };

  const handleFileUploadError = (err: any) => {
    console.error(err);
  };

  return (
    <>
      <StyledButton
        outlined
        startIcon={<GetApp />}
        onClick={() =>
          downloadAsCsv(
            makeCsvTemplate(orderSetVariants ?? []),
            `orders_upload_${orderSet.id}_template.csv`
          )
        }
      >
        Template
      </StyledButton>
      <CSVReader
        ref={csvRef}
        onFileLoad={handleFileUpload}
        onError={handleFileUploadError}
        noClick
        noDrag
        config={{
          header: true,
          beforeFirstChunk: () => setLoading(true),
        }}
        noProgressBar
      >
        {() => (
          <StyledButton
            outlined
            startIcon={<Publish />}
            loading={loading}
            onClick={(e) => csvRef.current?.open(e)}
          >
            Upload Orders
          </StyledButton>
        )}
      </CSVReader>
      {loading && <Loading hidden={false} partial={false} />}
    </>
  );
};

export default UploadOrdersButtons;

async function validateAddressIds(
  rows: Record<string, any>[],
  orderSetVariants: OrderSetVariant[],
  territoryId: null | string
) {
  if (rows.length === 0) {
    throw new Error("No orders provided.");
  }
  // Check for missing headers
  const headers = Object.keys(rows[0]);
  const missingHeaders = [
    ABN_ID,
    ...orderSetVariants.map((v) => v.variant.variantSku),
  ].filter((header) => !headers.includes(header));
  if (missingHeaders.length > 0) {
    throw new Error(
      `Missing headers: ${missingHeaders.join(", ")}. Please use the template.`
    );
  }

  // Check for missing ABN / ID
  const missingABNIndexes = rows.flatMap((row, i) =>
    row[ABN_ID] ? [] : [i + 2]
  );
  if (missingABNIndexes.length > 0) {
    throw new Error(
      `Missing ABN / ID in the following rows: ${missingABNIndexes.join(", ")}`
    );
  }
  // Check for duplicate ABN / ID
  const duplicateIds = rows
    .map((row) => row[ABN_ID])
    .filter((id, i, arr) => arr.indexOf(id) !== i);
  if (duplicateIds.length > 0) {
    throw new Error(`Duplicate ABN / ID: ${duplicateIds.join(", ")}`);
  }

  // values that start with "c" are address ids, otherwise they're ABNs
  const [customIdRows, abnRows] = _.partition(rows, (row) =>
    row[ABN_ID].startsWith("c")
  );

  const invalidAbnIds: string[] = [];

  // Query to api to validate addresses by ABN
  const chunkedAbns = _.chunk(abnRows, 20);
  const resFromAbns = await asyncPool(3, chunkedAbns, (abnRows) => {
    const abns = abnRows.map((r) => r[ABN_ID]);
    return client
      .get("addresses", {
        params: {
          filter: {
            abns: abns.join(","),
            territoryId,
            isActive: true,
          },
        },
      })
      .then((data: any) => {
        const addresses = data.data as Address[];
        if (addresses.length === abnRows.length) {
          // All abns are valid
          // map the ids of the addresses to the rows by their ABN
          const byAbn = _.keyBy(addresses, (d) => d.abn);
          return abnRows.map((r) =>
            Object.assign(r, {
              id: byAbn[r[ABN_ID]].id,
            })
          );
        }
        // Some abns are invalid
        const returnedAbns = addresses.map((d) => d.abn);
        const missingAbns = _.difference(abns, returnedAbns);
        invalidAbnIds.push(...missingAbns);
        return [];
      });
  });
  if (resFromAbns.errors) {
    throw new Error(resFromAbns.errors.map((e) => e.message).join("\n"));
  }

  // Query to api to validate addresses by ID
  const chunkedIds = _.chunk(customIdRows, 20);
  const resFromIds = await asyncPool(3, chunkedIds, (rows) => {
    const ids = rows.map((r) => parseCustomAddressId(r[ABN_ID]));
    return client
      .get("addresses", {
        params: {
          filter: {
            ids: ids.join(","),
            territoryId,
            isActive: true,
          },
        },
      })
      .then((data: any) => {
        const addresses = data.data as Address[];
        if (addresses.length === ids.length) {
          // All ids are valid
          // assign parsed ID to row
          return rows.map((r) =>
            Object.assign(r, {
              id: parseCustomAddressId(r[ABN_ID]),
            })
          );
        }
        const returnedIds = addresses.map((d) => d.id);
        const missingIds = _.difference(ids, returnedIds);
        invalidAbnIds.push(...missingIds.map((id) => `c${id}`));
        return [];
      });
  });

  if (resFromIds.errors) {
    throw new Error(resFromIds.errors.map((e) => e.message).join("\n"));
  }

  if (invalidAbnIds.length > 0) {
    throw new Error(
      `The following ABN / IDs are either invalid or don't match your current territory: ${invalidAbnIds.join(
        ", "
      )}`
    );
  }

  // return normalized rows with address ids
  return _.flatten([...resFromAbns.results, ...resFromIds.results]);
}
