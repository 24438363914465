import format from "date-fns/format";

import { createSlice } from "@reduxjs/toolkit";

import { buildFilters } from "../../../api/apiFunctions";
import {
  axiosDelete,
  axiosGet,
  axiosGetWithNext,
  axiosPost,
} from "../../../api/axiosCalls";
import { camelCaseKeys } from "../../../utility/utilityFunctions";
import { setError } from "../errorSlice";
import {
  resetStepperValue,
  setIsStepper,
  startGlobalLoad,
  stopGlobalLoad,
  updateStepperValue,
} from "../globalLoadSlice";
import {
  setFailure as patchFailure,
  setIsLoading as patchLoading,
  patchSuccess,
} from "../patchOrderSlice";
import { mapOverrideRules, mapRules } from "./maps";

let initialState = {
  isLoading: false,
  isUpdateLoading: false,
  isNextLoading: false,
  rulesPerPage: 20,
  nextPage: null,
  nextLink: null,
  rules: [],
  overrides: [],
  overrideRules: [],
  rulesReport: [],
  triggerCSVDownload: [],
  error: null,
};

const loadingFailed = (state, action) => {
  const { error } = action.payload;
  state.isLoading = false;
  state.isUpdateLoading = false;
  state.isNextLoading = false;
  state.error = error;
};

const complianceRulesSlice = createSlice({
  name: "complianceRules",
  initialState,
  reducers: {
    setIsLoading(state) {
      state.isLoading = true;
    },
    setIsUpdateLoading(state) {
      state.isUpdateLoading = true;
    },
    setNextIsLoading(state) {
      state.isNextLoading = true;
    },
    getRulesSuccess(state, action) {
      const { rules, nextLink } = action.payload;
      state.nextPage = nextLink ? true : false;
      state.nextLink = nextLink;
      state.rules = [...rules];
      state.isLoading = false;
      state.error = null;
    },
    getNextRulesSuccess(state, action) {
      const { rules, nextLink } = action.payload;
      state.nextPage = nextLink ? true : false;
      state.nextLink = nextLink;
      state.rules = state.rules.concat(rules);
      state.isNextLoading = false;
      state.error = null;
    },
    getOverrideRulesSuccess(state, action) {
      const { overrideRules } = action.payload;
      state.overrideRules = overrideRules;
      state.isLoading = false;
      state.isUpdateLoading = false;
      state.error = null;
    },
    getOverridesSuccess(state, action) {
      const { overrides, nextLink } = action.payload;
      state.overrides = overrides;
      state.isLoading = false;
      state.error = null;
      state.nextPage = Boolean(nextLink);
      state.nextLink = nextLink;
    },
    resetOverrides(state) {
      state.overrides = [];
    },
    resetOverrideRules(state) {
      state.overrideRules = [];
    },
    createOverrideSuccess(state, action) {
      const { overrideMap } = action.payload;
      state.overrideRules = state.overrideRules.map((o) => {
        if (Boolean(overrideMap[o.identifier])) {
          return {
            ...o,
            ...overrideMap[o.identifier],
          };
        } else return { ...o };
      });
      state.isUpdateLoading = false;
      state.error = null;
    },
    deleteOverrideSuccess(state, action) {
      const { identifier } = action.payload;
      state.overrideRules = state.overrideRules.map((o) => {
        if (o.identifier === identifier) {
          return { ...o, overrideId: null };
        } else return { ...o };
      });
      state.isUpdateLoading = false;
      state.error = null;
    },
    getRulesReportSuccess(state, action) {
      const { rules } = action.payload;
      state.rulesReport = rules;
      state.triggerCSVDownload = true;
      state.error = null;
    },
    setTriggerCSVFalse(state) {
      state.triggerCSVDownload = false;
    },
    clearRulesReport(state) {
      state.rulesReport = [];
    },
    resetComplianceRules(state) {
      state.isLoading = false;
      state.isUpdateLoading = false;
      state.isNextLoading = false;
      state.rulesPerPage = 20;
      state.nextPage = null;
      state.nextLink = null;
      state.rules = [];
      state.rulesReport = [];
      state.overrideRules = [];
      state.overrides = [];
      state.triggerCSVDownload = false;
      state.error = null;
    },
    setFailure: loadingFailed,
  },
});

export const {
  setIsLoading,
  setIsUpdateLoading,
  setNextIsLoading,
  getRulesSuccess,
  getNextRulesSuccess,
  getOverrideRulesSuccess,
  getOverridesSuccess,
  createOverrideSuccess,
  deleteOverrideSuccess,
  resetOverrides,
  resetOverrideRules,
  getRulesReportSuccess,
  setTriggerCSVFalse,
  clearRulesReport,
  resetComplianceRules,
  setFailure,
} = complianceRulesSlice.actions;

export default complianceRulesSlice.reducer;

export const fetchRules = (filterObject) => async (dispatch) => {
  try {
    dispatch(setIsLoading());
    dispatch(startGlobalLoad());
    const queryString = buildFilters(
      filterObject,
      "",
      "",
      "/api/rules",
      "complianceRules"
    );
    const response = await axiosGetWithNext(queryString);
    if (response.error) throw response.error;
    const mappedData = mapRules(response.data.data);
    dispatch(
      getRulesSuccess({
        rules: mappedData,
        nextLink: response.data.nextLink,
      })
    );
    dispatch(stopGlobalLoad());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Compliance Rules" }));
    dispatch(stopGlobalLoad());
  }
};

export const fetchNextRules = (url) => async (dispatch) => {
  try {
    dispatch(setNextIsLoading());
    dispatch(startGlobalLoad());
    const response = await axiosGetWithNext(url);
    if (response.error) throw response.error;
    const mappedData = mapRules(response.data.data);
    dispatch(
      getNextRulesSuccess({
        rules: mappedData,
        nextLink: response.data.nextLink,
      })
    );
    dispatch(stopGlobalLoad());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Compliance Rules" }));
    dispatch(stopGlobalLoad());
  }
};

export const fetchRulesWithOverrides = (itemNumber) => async (dispatch) => {
  try {
    dispatch(setIsLoading());
    const itemResponse = await axiosGet(
      `/api/items?filter[item-number-exact]=${itemNumber}`
    );

    if (itemResponse.error) throw itemResponse.error;
    if (itemResponse.data.length === 0)
      throw new Error("There are no items matching your search");
    const overrideResponse = await axiosGet(
      `/api/compliance-overrides?filter[item-id]=${itemResponse.data[0].id}`
    );
    if (overrideResponse.error) throw overrideResponse.error;

    let rules = [];
    let next = "start";

    while (next) {
      let ruleResponse = await axiosGetWithNext(
        next === "start"
          ? `/api/rules?filter[item-type-rules-for-item-id]=${itemResponse.data[0].id}`
          : next
      );

      if (ruleResponse.error) throw ruleResponse.error;
      rules = rules.concat(ruleResponse.data.data);
      next = ruleResponse.data.nextLink ?? null;
    }
    if (itemResponse.data[0]["coupon-offer-type-code"]) {
      next = "start";
      while (next) {
        let couponRuleResponse = await axiosGetWithNext(
          next === "start"
            ? `/api/rules?filter[coupon-offer-type-code]=${itemResponse.data[0]["coupon-offer-type-code"]}`
            : next
        );

        if (couponRuleResponse.error) throw couponRuleResponse.error;
        rules = rules.concat(couponRuleResponse.data.data);
        next = couponRuleResponse.data.nextLink ?? null;
      }
    }

    const mappedData = mapOverrideRules(
      itemResponse.data[0],
      overrideResponse.data,
      rules
    );
    dispatch(getOverrideRulesSuccess({ overrideRules: mappedData }));
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Compliance Rules" }));
  }
};

export const fetchOverrideList =
  ({ fromDate, toDate }) =>
  async (dispatch) => {
    try {
      dispatch(setIsLoading());

      const response = await axiosGet(
        `/api/compliance-overrides?filter[inserted-at-range]=${format(
          fromDate,
          "MM/dd/yyyy"
        )} - ${format(toDate, "MM/dd/yyyy")}`
      );
      if (response.error) throw new Error(response.error);

      dispatch(
        getOverridesSuccess({
          overrides: response.data.map(camelCaseKeys),
        })
      );
    } catch (err) {
      dispatch(setFailure({ error: err.message }));
      dispatch(setError({ error: err.message, source: "Compliance Rules" }));
    }
  };

export const createOverrides = (overrides, note) => async (dispatch) => {
  try {
    dispatch(patchLoading());
    dispatch(setIsUpdateLoading());
    let overrideMap = {};
    for (let i = 0; i < overrides.length; i++) {
      let currentOverride = overrides[i];

      let postBody = {
        data: {
          attributes: {
            "item-id": +currentOverride.itemId,
            "rule-id": +currentOverride.ruleId,
            "state-id": +currentOverride.stateId,
            note: note,
          },
        },
      };

      let response = await axiosPost("/api/compliance-overrides", postBody);
      if (response.error) throw response.error;

      overrideMap[currentOverride.identifier] = {
        overrideId: response.data.id,
        overriddenBy: response.data["user-name"],
        overriddenAt: format(
          new Date(response.data["inserted-at"]),
          "MM/dd/yyyy"
        ),
        note: response.data.note,
      };
    }
    dispatch(createOverrideSuccess({ overrideMap: overrideMap }));
    dispatch(patchSuccess());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Compliance Rules" }));
    dispatch(patchFailure());
  }
};

export const deleteOverride = (identifier, id) => async (dispatch) => {
  try {
    dispatch(patchLoading());
    dispatch(setIsUpdateLoading());
    const response = await axiosDelete(`/api/compliance-overrides/${id}`, {});
    if (response.error) throw response.error;
    dispatch(deleteOverrideSuccess({ identifier: identifier }));
    dispatch(patchSuccess());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Compliance Rules" }));
    dispatch(patchFailure());
  }
};

export const fetchRulesReport = (filterObject) => async (dispatch) => {
  try {
    dispatch(
      setIsStepper({
        stepBool: true,
        stepTitle: "Generating Compliance Rules Report",
      })
    );
    let ruleArray = [];
    let stepValue = 10;

    const queryString = buildFilters(
      filterObject,
      "",
      "",
      "/api/rules",
      "complianceRules"
    );
    let initialResponse = await axiosGetWithNext(queryString);
    if (initialResponse.error) throw initialResponse.error;

    let initialMappedData = mapRules(initialResponse.data.data);
    ruleArray = ruleArray.concat(initialMappedData);

    let nextLink = initialResponse.data.nextLink;
    dispatch(updateStepperValue({ value: stepValue }));

    if (nextLink) {
      const pageSize = initialResponse.data.data.length;
      let fetchCount =
        Math.ceil(initialResponse.data.totalEntries / pageSize) - 1;
      stepValue = parseFloat((90 / fetchCount).toFixed(2));

      for (let i = 0; i < fetchCount; i++) {
        let nextResponse = await axiosGetWithNext(nextLink);
        if (nextResponse.error) throw nextResponse.error;
        nextLink = nextResponse.data.nextLink;
        let mappedData = mapRules(nextResponse.data.data);
        ruleArray = ruleArray.concat(mappedData);
        dispatch(updateStepperValue({ value: stepValue }));
      }
    } else {
      dispatch(updateStepperValue({ value: 90 }));
    }

    dispatch(resetStepperValue());
    dispatch(getRulesReportSuccess({ rules: ruleArray }));
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(
      setError({ error: err.toString(), source: "Compliance Items Reports" })
    );
    dispatch(resetStepperValue());
  }
};
