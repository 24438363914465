import React, { useEffect, useRef, useState } from "react";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
import Helmet from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";

import GetAppIcon from "@mui/icons-material/GetApp";
import PrintIcon from "@mui/icons-material/Print";
import {
  Button,
  Container,
  IconButton,
  LinearProgress,
  Tooltip,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { addDays, subMonths } from "date-fns";
import format from "date-fns/format";
import HideFiltersButtonToggle from "src/components/Filtering/HideFiltersButtonToggle";

import { CSVLink } from "@utils/csv";

import ComplianceItemsTable from "../components/Compliance/ComplianceItemsTable";
import RuleApproveDenyNotesModal from "../components/Compliance/RuleApproveDenyNotesModal";
import FilterChipList from "../components/Filtering/FilterChipList";
import ItemPreviewModal from "../components/ItemPreview/ItemPreviewModal";
import OrderPatchLoading from "../components/Utility/OrderPatchLoading";
import { useInitialFilters } from "../hooks/UtilityHooks";
import {
  clearCompItemReport,
  fetchCompItemReport,
  fetchNextTriggeredRules,
  setTriggerCSVFalse,
} from "../redux/slices/compliance/complianceItemsSlice";
import {
  setFiltersOpen,
  setSorted,
  updateMultipleFilters,
} from "../redux/slices/filterSlice";

/*
Compliance Items in this case are what Gallo Users call triggered rules in our api. Each triggered
rule references a single item, so here users can view rules that have triggered on various items,
and approve or deny those triggered rules.
*/

const defaultFilters = {
  fromDate: format(subMonths(new Date(), 6), "MM/dd/yyyy"),
  toDate: format(addDays(new Date(), 1), "MM/dd/yyyy"),
  brand: [],
  //program: [],
  itemType: [],
  tag: [],
  ruleType: "all",
  status: "all",
  itemNumber: "",
  sortOrder: "asc",
  sortOrderBy: "itemNumber",
};

const useStyles = makeStyles((theme) => ({
  ...theme.global,
}));

const ComplianceItems = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const tableRef = useRef(null);

  const [currentCSV, setCurrentCSV] = useState({ data: [], headers: [] });
  const [currentItem, setCurrentItem] = useState(null);
  const [isPreviewOpen, setPreviewOpen] = useState(false);
  const [notesModalOpen, setNotesModalOpen] = useState(false);
  const [approving, setApproving] = useState(true);

  const currentUserRole = useSelector((state) => state.user.role);
  const filtersOpen = useSelector((state) => state.filters.filtersOpen);

  const {
    items: currentItemRules,
    selectedItems,
    isLoading,
    nextLink,
    isNextLoading,
    compItemReport,
    triggerCSVDownload,
  } = useSelector((state) => state.complianceItems);

  const handlePrint = useReactToPrint({
    content: () => tableRef.current,
  });

  const handleBottomScroll = () => {
    if (nextLink && !isNextLoading) {
      if (scrollRef.current && scrollRef.current.scrollTop !== 0) {
        dispatch(fetchNextTriggeredRules(nextLink));
      }
    }
  };

  const scrollRef = useBottomScrollListener(handleBottomScroll, {
    offset: 500,
    debounceOptions: {
      leading: true,
      trailing: false,
    },
  });

  const handleSort = (sortObject) => {
    scrollRef.current.scrollTop = 0;
    dispatch(
      updateMultipleFilters({
        filterObject: {
          sortOrder: sortObject.order,
          sortOrderBy: sortObject.orderBy,
        },
      })
    );
    dispatch(setSorted());
  };

  const handlePreviewOpen = (item) => {
    // let formattedItem = {
    //   id: item.id,
    //   itemNumber: item.itemNumber,
    //   brand: item.brand,
    //   program: [item.program],
    //   itemType: item.itemType,
    //   itemDescription: item.itemDescription,
    //   estCost: item.estCost,`
    //   imgUrlLg: item.imgUrlLg,
    //   packSize: item.packSize,
    //   removeFromCatalogDate: item.removeFromCatalogDate,
    //   specification: item.specification,
    // };

    setCurrentItem(item);
    setPreviewOpen(true);
  };

  const handlePreviewClose = () => {
    setCurrentItem(null);
    setPreviewOpen(false);
  };

  const handleCompItemReport = () => {
    dispatch(fetchCompItemReport());
  };

  useEffect(() => {
    if (triggerCSVDownload && currentCSV.data.length === 0) {
      let csvHeaders = [
        { label: "Sequence #", key: "itemNumber" },
        { label: "Program", key: "program" },
        { label: "Brand", key: "brand" },
        { label: "Item Type", key: "itemType" },
        { label: "State", key: "state" },
        { label: "Rule Type", key: "ruleType" },
        { label: "Rule Description", key: "ruleDesc" },
        { label: "Status", key: "status" },
      ];
      let csvData = [];
      compItemReport.forEach((rule) => {
        const emailSentString =
          rule.emailSent && rule.emailSentDate !== "---"
            ? `Email sent on ${rule.emailSentDate}`
            : rule.emailSent
            ? "Email Sent"
            : "Email not sent";
        csvData.push({
          itemNumber: rule.itemNumber,
          program: rule.program,
          brand: rule.brand,
          itemType: rule.itemType,
          state: rule.state,
          ruleType: rule.ruleType,
          ruleDesc: rule.ruleDesc,
          status:
            rule.status === "Pending" &&
            (rule.ruleType === "Prior Approval" ||
              rule.ruleType === "Coupon Prior Approval" ||
              rule.ruleType === "Internal Approval")
              ? `${rule.status} ${emailSentString}`
              : rule.status,
        });
      });
      setCurrentCSV({ data: csvData, headers: csvHeaders });
    }
  }, [compItemReport, triggerCSVDownload, currentCSV]);

  useInitialFilters("compliance-items", defaultFilters);

  useEffect(() => {
    dispatch(setTriggerCSVFalse());
    dispatch(clearCompItemReport());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!triggerCSVDownload && currentCSV.data.length > 0) {
      setCurrentCSV({
        data: [],
        headers: [],
      });
    }
  }, [triggerCSVDownload, currentCSV, setCurrentCSV]);

  return (
    <>
      {notesModalOpen && (
        <RuleApproveDenyNotesModal
          items={currentItemRules.filter((item) =>
            selectedItems.includes(item.id)
          )}
          closeModal={() => {
            dispatch(setFiltersOpen({ open: !filtersOpen }));
            setNotesModalOpen(false);
          }}
          approving={approving}
        />
      )}
      {currentItem && isPreviewOpen && (
        <ItemPreviewModal
          type="compliance"
          currentItem={currentItem}
          handleClose={handlePreviewClose}
          previewModal={isPreviewOpen}
        />
      )}
      <Helmet>
        <title>RTA | Compliance Items</title>
        {currentUserRole === "purchaser" && !filtersOpen && (
          <script type="text/javascript">{`Beacon('suggest', ['601438192042ff6d1b2a8ab3'])`}</script>
        )}
      </Helmet>
      <Container className={classes.mainWrapper}>
        <div className={classes.titleBar}>
          <Typography className={classes.titleText}>
            Compliance Items
          </Typography>
          <div
            style={{
              display: "flex",
              minWidth: "500px",
              justifyContent: "flex-end",
            }}
          >
            {(currentUserRole === "compliance" ||
              currentUserRole === "super") && (
              <>
                <Tooltip
                  title="Approves a Prior Approval Rule"
                  PopperProps={{ style: { marginRight: "20px" } }}
                >
                  <span>
                    <Button
                      className={classes.largeButton}
                      variant="contained"
                      color="secondary"
                      disabled={selectedItems.length === 0}
                      style={{ marginRight: "20px" }}
                      onClick={() => {
                        dispatch(setFiltersOpen({ open: !filtersOpen }));
                        setNotesModalOpen(true);
                        setApproving(true);
                      }}
                    >
                      APPROVE RULE
                    </Button>
                  </span>
                </Tooltip>
                <Tooltip
                  title="Denies a Prior Approval Rule"
                  PopperProps={{ style: { marginRight: "20px" } }}
                >
                  <span>
                    <Button
                      className={classes.largeButton}
                      variant="contained"
                      color="secondary"
                      disabled={selectedItems.length === 0}
                      style={{ marginRight: "20px" }}
                      onClick={() => {
                        dispatch(setFiltersOpen({ open: !filtersOpen }));
                        setNotesModalOpen(true);
                        setApproving(false);
                      }}
                    >
                      DENY RULE
                    </Button>
                  </span>
                </Tooltip>
              </>
            )}
            {!triggerCSVDownload && currentCSV.data.length === 0 && (
              <span>
                <Button
                  className={classes.largeButton}
                  variant="contained"
                  onClick={handleCompItemReport}
                  disabled={currentItemRules.length === 0}
                >
                  GENERATE REPORT
                </Button>
              </span>
            )}
            {triggerCSVDownload && currentCSV.data.length > 0 && (
              <CSVLink
                data={currentCSV.data}
                headers={currentCSV.headers}
                filename="rta_compliance_items.csv"
              >
                <Button
                  className={classes.largeButton}
                  variant="contained"
                  color="secondary"
                  startIcon={<GetAppIcon />}
                >
                  EXPORT REPORT
                </Button>
              </CSVLink>
            )}
            <Tooltip title="Print Item Rules">
              <IconButton onClick={handlePrint} size="large">
                <PrintIcon color="secondary" />
              </IconButton>
            </Tooltip>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignContent: "center",
            marginBottom: "10px",
          }}
        >
          <HideFiltersButtonToggle />
          <FilterChipList classes={classes} />
          <br />
        </div>
        <ComplianceItemsTable
          items={currentItemRules}
          itemsLoading={isLoading}
          handleSort={handleSort}
          handlePreviewOpen={handlePreviewOpen}
          scrollRef={scrollRef}
          tableRef={tableRef}
        />
        {isNextLoading && (
          <div style={{ width: "100%" }}>
            <LinearProgress />
          </div>
        )}
        {!isNextLoading && <div style={{ width: "100%", height: "4px" }}></div>}
      </Container>
      <OrderPatchLoading />
      <br />
    </>
  );
};

export default ComplianceItems;
