/** @jsxImportSource @emotion/react */
import "twin.macro";

import React, { useEffect, useState } from "react";

import {
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import axios from "axios";

import { setError } from "../../redux/slices/errorSlice";

const formatDate = (date) => {
  const dObj = new Date(date);
  return dObj.toLocaleString();
};

const useStyles = makeStyles((theme) => ({
  ...theme.global,
}));

const arrowStyle = {
  ".MuiTableSortLabel-icon": {
    opacity: 0.5,
  },
  "&.Mui-active .MuiTableSortLabel-icon": {
    color: "#2196f3",
  },
};

const AnaplanTroubleshooting = () => {
  const classes = useStyles();
  const [errors, setErrors] = useState([]);
  const [sortByFilename, setSortByFilename] = useState(false);
  const [sortByOccurredAt, setSortByOccurredAt] = useState(true);
  const [sortByError, setSortByError] = useState(false);
  const [sortByCSVRow, setSortByCSVRow] = useState(false);
  const [sortSelected, setSortSelected] = useState("occurred-at");

  const sortFilenameCol = () => {
    setSortByFilename(!sortByFilename);
    setSortSelected("filename");
    const sortedErrors = errors.sort((a, b) => {
      const filenameA = a.attributes.filename.split("/").at(-1).toLowerCase();
      const filenameB = b.attributes.filename.split("/").at(-1).toLowerCase();
      if (!sortByFilename) {
        if (filenameA < filenameB) {
          return -1;
        } else {
          return 1;
        }
      } else {
        if (filenameA > filenameB) {
          return -1;
        } else {
          return 1;
        }
      }
    });
    setErrors(sortedErrors);
  };

  const sortOccurredAtCol = () => {
    setSortByOccurredAt(!sortByOccurredAt);
    setSortSelected("occurred-at");
    let sortedErrors = [];
    if (!sortByOccurredAt) {
      sortedErrors = errors.sort(
        (a, b) =>
          new Date(b.attributes["updated-at"]) -
          new Date(a.attributes["updated-at"])
      );
    } else {
      sortedErrors = errors.sort(
        (a, b) =>
          new Date(a.attributes["updated-at"]) -
          new Date(b.attributes["updated-at"])
      );
    }
    setErrors(sortedErrors);
  };

  const sortErrorCol = () => {
    setSortByError(!sortByError);
    setSortSelected("error");
    const sortedErrors = errors.sort((a, b) => {
      if (!sortByError) {
        if (
          a.attributes.error.toLowerCase() < b.attributes.error.toLowerCase()
        ) {
          return -1;
        } else {
          return 1;
        }
      } else {
        if (
          a.attributes.error.toLowerCase() > b.attributes.error.toLowerCase()
        ) {
          return -1;
        } else {
          return 1;
        }
      }
    });
    setErrors(sortedErrors);
  };

  const sortCSVRowCol = () => {
    setSortByCSVRow(!sortByCSVRow);
    setSortSelected("csv-row");
    const sortedErrors = errors.sort((a, b) => {
      if (!sortByCSVRow) {
        if (
          a.attributes["csv-row-map"]["Program ID"].toLowerCase() <
          b.attributes["csv-row-map"]["Program ID"].toLowerCase()
        ) {
          return -1;
        } else {
          return 1;
        }
      } else {
        if (
          a.attributes["csv-row-map"]["Program ID"].toLowerCase() >
          b.attributes["csv-row-map"]["Program ID"].toLowerCase()
        ) {
          return -1;
        } else {
          return 1;
        }
      }
    });
    setErrors(sortedErrors);
  };

  useEffect(() => {
    axios
      .get(`/api/anaplan-errors`)
      .then((response) => {
        let errorsRecieved = response.data.data;
        errorsRecieved = errorsRecieved.sort((a, b) => {
          if (b.attributes["updated-at"] === a.attributes["updated-at"]) {
            if (
              a.attributes.error.toLowerCase() <
              b.attributes.error.toLowerCase()
            ) {
              return -1;
            } else {
              return 1;
            }
          } else {
            return (
              new Date(b.attributes["updated-at"]) -
              new Date(a.attributes["updated-at"])
            );
          }
        });
        setErrors(errorsRecieved);
        return errorsRecieved;
      })
      .catch((e) => {
        setError(e);
      });
  }, []);

  return (
    <>
      <Typography className={classes.titleText}>
        Anaplan Troubleshooting
      </Typography>
      <TableContainer style={{ fontSize: 8 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                className={classes.headerText}
                align="left"
                sortDirection={!sortByFilename}
              >
                <TableSortLabel
                  direction={!sortByFilename ? "asc" : "desc"}
                  onClick={sortFilenameCol}
                  active={sortSelected === "filename"}
                  sx={arrowStyle}
                >
                  Filename
                </TableSortLabel>
              </TableCell>
              <TableCell
                className={classes.headerText}
                align="left"
                sortDirection={!sortByOccurredAt}
              >
                <TableSortLabel
                  direction={!sortByOccurredAt ? "asc" : "desc"}
                  onClick={sortOccurredAtCol}
                  active={sortSelected === "occurred-at"}
                  sx={arrowStyle}
                >
                  Occurred At
                </TableSortLabel>
              </TableCell>
              <TableCell
                className={classes.headerText}
                align="left"
                sortDirection={!sortByOccurredAt}
              >
                <TableSortLabel
                  direction={!sortByError ? "asc" : "desc"}
                  onClick={sortErrorCol}
                  active={sortSelected === "error"}
                  sx={arrowStyle}
                >
                  Error
                </TableSortLabel>
              </TableCell>
              <TableCell
                className={classes.headerText}
                align="left"
                sortDirection={!sortByOccurredAt}
              >
                <TableSortLabel
                  direction={!sortByCSVRow ? "asc" : "desc"}
                  onClick={sortCSVRowCol}
                  active={sortSelected === "csv-row"}
                  sx={arrowStyle}
                >
                  CSV Row
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {errors.map((row) => (
              <TableRow
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.attributes.filename.split("/").at(-1)}
                </TableCell>
                <TableCell>
                  {formatDate(row.attributes["updated-at"])}
                </TableCell>
                <TableCell>{row.attributes.error}</TableCell>
                <TableCell>
                  <Stack>
                    <Typography
                      variant="p"
                      key={row.attributes["csv-row-map"]["Program ID"]}
                    >
                      <b>Program ID:</b>{" "}
                      {row.attributes["csv-row-map"]["Program ID"]}
                    </Typography>
                    {Object.entries(row.attributes["csv-row-map"])
                      .filter((attr) => attr[0] !== "Program ID")
                      .map(([key, value]) => {
                        return (
                          <Typography variant="p" key={key}>
                            <b>{key}:</b> {value}
                          </Typography>
                        );
                      })}
                  </Stack>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default AnaplanTroubleshooting;
