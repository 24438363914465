import { useDispatch, useSelector } from "react-redux";

import TuneIcon from "@mui/icons-material/Tune";
import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { setFiltersOpen } from "src/redux/slices/filterSlice";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
}));

const HideFiltersButtonToggle = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const filtersOpen = useSelector((state) => state.filters.filtersOpen);

  return (
    <div
      className={classes.showHideFilters}
      onClick={() => {
        dispatch(setFiltersOpen({ open: !filtersOpen }));
      }}
    >
      <TuneIcon fontSize="small" color="secondary" />
      <Typography
        variant="body2"
        color="textSecondary"
        style={{ margin: "10px 10px" }}
      >
        {filtersOpen ? "Hide Filters" : "Show Filters"}
      </Typography>
    </div>
  );
};

export default HideFiltersButtonToggle;
