import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";

import PropTypes from "prop-types";

const IsAccoladeSelector = ({
  handleIsAccolade,
  isAccolade,
  setIsAccolade,
  classes,
  filterType,
}) => {
  const options = [
    { id: "yes", label: "Is Accolade" },
    { id: "no", label: "Is Not Accolade" },
  ];

  const currentFilter = useSelector((state) => state.filters.isAccolade);
  const isGlobalLoading = useSelector((state) => state.globalLoad.isLoading);

  const handleChangeSelect = (evt) => {
    setIsAccolade(evt.target.value);
    handleIsAccolade(evt.target.value, "isAccolade", filterType);
  };

  useEffect(() => {
    if (isAccolade !== currentFilter) {
      setIsAccolade(currentFilter);
    }
  });

  return (
    <>
      <FormControl
        fullWidth
        variant="outlined"
        size="small"
        className={classes.queryField}
        disabled={isGlobalLoading}
      >
        <InputLabel id="isAccolade-select">Accolade</InputLabel>
        <Select
          variant="outlined"
          label="Accolade"
          name="isAccolade"
          labelId="isAccolade-select"
          id="isAccolade"
          value={isAccolade || ""}
          onChange={handleChangeSelect}
          MenuProps={{
            style: { zIndex: "2500" },

            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          }}
        >
          {options.map((opt, i) => (
            <MenuItem value={opt.id} key={i}>
              <Typography variant="body2">{opt.label}</Typography>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

IsAccoladeSelector.propTypes = {
  handleIsAccolade: PropTypes.func.isRequired,
  isAccolade: PropTypes.string,
  setIsAccolade: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  filterType: PropTypes.string.isRequired,
};

export default IsAccoladeSelector;
