/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useCallback } from "react";
import { Control, useWatch } from "react-hook-form";
import { useSelector } from "react-redux";

import { AutocompleteInput } from "src/components/Forms/ControlledInputs";
import { useItemTypes } from "src/hooks/planningToolHooks";

type ItemType = {
  id: string;
  name: string;
};

const FormItemTypeSelector = ({
  controlled,
  isCoupon,
  onChange,
  ...props
}: {
  controlled: (
    name: string,
    rules?: any
  ) => { name: string; control: Control<any>; [key: string]: any };
  isCoupon?: boolean;
  onChange?: (itemType: ItemType) => void;
  [key: string]: any;
}) => {
  const role = useSelector((state: any) => state.user.role);
  // extract control from the controlled input
  const control = controlled("").control;
  const itemTypeId = useWatch({ name: "itemTypeId", control });

  const isItemTypeRestrictedForUser = (itemType) => {
    if (!itemType) return false;
    return !!itemType.limitedRoles && !itemType.limitedRoles.includes(role);
  };

  const filterFn = useCallback(
    (type) => (type.categoryCode === "COU") === !!isCoupon,
    [isCoupon]
  );

  let itemTypeOptions: ItemType[] = useItemTypes(filterFn);

  const isSelectedRestricted = isItemTypeRestrictedForUser(
    itemTypeOptions.find(({ id }) => id === itemTypeId)
  );

  // only remove disabled item-types if the selected item-type is not disabled
  if (!isSelectedRestricted) {
    itemTypeOptions = itemTypeOptions.filter(
      (it) => !isItemTypeRestrictedForUser(it)
    );
  }

  return (
    <AutocompleteInput
      disableClearable
      label="Item Type"
      tw="grow"
      options={itemTypeOptions}
      onChange={onChange}
      {...controlled("itemTypeId")}
      // override disabled prop from controlled if item-type is restricted
      {...(isSelectedRestricted && {
        disabled: true,
      })}
      {...props}
    />
  );
};

export default FormItemTypeSelector;
