import React from "react";

import { Box, Button, Grid, TextField, Typography } from "@mui/material";

import StatusChip from "../../../Utility/StatusChip";
import CloudinaryFilesDownloadDelete from "../../CloudinaryFilesDownloadDelete";
import BasicTable from "../BasicTable";

const ViewSent = ({ isUpdateLoading, currentRFQ, classes, handleCancel }) => {
  const {
    id,
    itemNumber,
    itemType,
    status,
    programName,
    brands,
    note,
    pricingTiers,
    quotes,
    cloudinaryFiles,
  } = currentRFQ;

  const statusMap = {
    sent: "",
    accepted: "info",
    complete: "success",
    declined: "error",
  };

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "column", gridGap: "16px" }}>
        <Typography variant="h5">
          {itemType} | {itemNumber}
        </Typography>
        <Box sx={{ columns: 2 }}>
          <Typography>
            <b>Program: </b>
            {programName}
          </Typography>
          <Typography>
            <b>Brand(s): </b>
            {brands.join(", ")}
          </Typography>
        </Box>
        <br />
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <BasicTable
              paper={false}
              size="small"
              rows={[
                ["Tier Quantities"],
                ...pricingTiers.map((t) => [t.qty.toLocaleString("en-US")]),
              ]}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography>Notes:</Typography>
            <TextField
              multiline
              fullWidth
              variant="outlined"
              disabled
              value={note || ""}
              placeholder="[no notes]"
            />
          </Grid>
        </Grid>
      </Box>

      {quotes.length > 0 && (
        <>
          <Box sx={{ mt: 4, mb: 2 }}>
            <Typography variant="h5">Sent requests</Typography>
          </Box>
          <BasicTable
            rows={[
              ["Supplier Name", "Quote Status"],
              ...quotes.map((quote) => [
                quote.supplierName,
                <StatusChip
                  status={statusMap[quote.status]}
                  label={quote.status}
                />,
              ]),
            ]}
            align={["left", "left"]}
          />
        </>
      )}

      <Box sx={{ mt: 4, mb: 2 }}>
        <Typography variant="h5">Files</Typography>
      </Box>
      <CloudinaryFilesDownloadDelete
        cloudinaryFiles={cloudinaryFiles}
        rfqid={id}
      />

      {/* Action buttons */}
      {status !== "canceled" && (
        <Box sx={{ mt: 6, display: "flex" }}>
          <Button
            variant="outlined"
            classes={{ root: classes.cancelBtn }}
            onClick={handleCancel}
          >
            Cancel RFQ
          </Button>
        </Box>
      )}
    </>
  );
};

export default ViewSent;
