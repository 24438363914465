import { useSearchParams } from "react-router-dom";

import { pickBy } from "lodash";

function splitObjectKeys(
  obj: Record<string, any>,
  delimiter: string
): Record<string, any> {
  const result: Record<string, any> = {};

  for (const [key, value] of Object.entries(obj)) {
    const parts = key.split(delimiter);
    let currentObj = result;

    for (let i = 0; i < parts.length - 1; i++) {
      const part = parts[i];

      if (!currentObj[part]) {
        currentObj[part] = {};
      }

      currentObj = currentObj[part];
    }

    currentObj[parts[parts.length - 1]] = value;
  }

  return result;
}

const encodeArrays = (obj: Record<string, string | boolean | string[]>) =>
  Object.fromEntries(
    Object.entries(obj).map(([key, value]) =>
      Array.isArray(value)
        ? [key + "[]", value.join(",")]
        : [key, value?.toString()]
    )
  );

const parseSearchParams = (
  searchParams: URLSearchParams
): Record<string, string | string[]> =>
  Object.fromEntries(
    [...searchParams.entries()].map(([key, value]) =>
      key.endsWith("[]") ? [key.slice(0, -2), value.split(",")] : [key, value]
    )
  );

const useFilterParams = (opts?: {
  preserveKeys: boolean;
}): [Record<string, any>, (filterObject: Record<string, any>) => void] => {
  const [searchParams, setSearchParams] = useSearchParams();

  const applyFilters = (formData) => {
    // Heads up: we might eventually have a filter that accepts "false" as a value.
    const filterData = pickBy(
      encodeArrays(formData),
      (val) => val && val !== "false"
    );

    const params = new URLSearchParams(filterData).toString();
    setSearchParams(params, { replace: true });
  };

  let appliedFilters = parseSearchParams(searchParams);
  if (!opts?.preserveKeys) {
    appliedFilters = splitObjectKeys(appliedFilters, ":");
  }

  return [appliedFilters, applyFilters];
};

export default useFilterParams;
