import React, { useState } from "react";
import { Link } from "react-router-dom";

import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import PropTypes from "prop-types";

import { PLANNING_TOOL_SUPPLIER } from "../../constants/permissions";
import ImageWrapper from "../Utility/ImageWrapper";

const FieldDash = ({ classes, name, InfoPopover, role }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const planningToolOpen = anchorEl
    ? anchorEl.id === "planning-tool-parent"
    : false;
  const currentQuotesOpen = anchorEl
    ? anchorEl.id === "current-rfq-parent"
    : false;
  const quoteHistoryOpen = anchorEl
    ? anchorEl.id === "rfq-history-parent"
    : false;
  const currentRFPsOpen = anchorEl
    ? anchorEl.id === "current-rfp-parent"
    : false;
  const rfpHistoryOpen = anchorEl
    ? anchorEl.id === "rfp-history-parent"
    : false;
  const currentPOsOpen = anchorEl ? anchorEl.id === "current-po-parent" : false;
  const poHistoryOpen = anchorEl ? anchorEl.id === "po-history-parent" : false;
  const bulkPOReportOpen = anchorEl
    ? anchorEl.id === "bulk-po-report-parent"
    : false;

  const cardData = [
    role === PLANNING_TOOL_SUPPLIER && {
      titleText: "RTA Planning Tool",
      ariaOwnsState: planningToolOpen,
      ariaOwnsText: "planning-tool",
      id: "planning-tool-parent",
      link: "/planning/dashboard",
      info: "View Planning Tool",
      icon: "https://res.cloudinary.com/brandhub/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1617273998/prod/Icons/SELECT_Icons_Shopper_Marketing_-_POS_el3abn.png",
    },
    {
      titleText: "Quote Requests",
      ariaOwnsState: currentQuotesOpen,
      ariaOwnsText: "current-RFQs",
      id: "current-rfq-parent",
      link: "/planning/quotes/current",
      info: "New and Pending Quotes",
      icon: "https://res.cloudinary.com/brandhub/image/upload/v1610483653/prod/Icons/Place_On_Demand_Order_zoymv2.png",
    },
    {
      titleText: "Quote Requests History",
      ariaOwnsState: quoteHistoryOpen,
      ariaOwnsText: "RFQ-history",
      id: "rfq-history-parent",
      link: "/planning/quotes/complete",
      info: "All Quotes",
      icon: "https://res.cloudinary.com/brandhub/image/upload/v1610483653/prod/Icons/Item_Catalog_Archive_wgetjy.png",
    },
    {
      titleText: "Price Requests",
      ariaOwnsState: currentRFPsOpen,
      ariaOwnsText: "current-RFPs",
      id: "current-rfp-parent",
      link: "/purchasing/rfpHistory/current",
      info: "New and Pending Price requests",
      icon: "https://res.cloudinary.com/brandhub/image/upload/v1610483653/prod/Icons/Place_On_Demand_Order_zoymv2.png",
    },
    {
      titleText: "Price Requests History",
      ariaOwnsState: rfpHistoryOpen,
      ariaOwnsText: "RFP-history",
      id: "rfp-history-parent",
      link: "/purchasing/rfpHistory/complete",
      info: "All Price Requests",
      icon: "https://res.cloudinary.com/brandhub/image/upload/v1610483653/prod/Icons/Item_Catalog_Archive_wgetjy.png",
    },
    {
      titleText: "Current Purchase Orders",
      ariaOwnsState: currentPOsOpen,
      ariaOwnsText: "current-po",
      id: "current-po-parent",
      link: "/purchasing/poHistory/current",
      info: "New and Pending Purchase Orders",
      icon: "https://res.cloudinary.com/brandhub/image/upload/v1610483653/prod/Icons/Order_History_jvvgmh.png",
    },
    {
      titleText: "Purchase Order History",
      ariaOwnsState: poHistoryOpen,
      ariaOwnsText: "po-history",
      id: "po-history-parent",
      link: "/purchasing/poHistory/complete",
      info: "All Purchase Orders",
      icon: "https://res.cloudinary.com/brandhub/image/upload/v1610483653/prod/Icons/Item_Catalog_Current_ykkrrc.png",
    },
    {
      titleText: "Bulk PO Shipping Report",
      ariaOwnsState: bulkPOReportOpen,
      ariaOwnsText: "bulk-po-report",
      id: "bulk-po-report-parent",
      link: "/reports/po-ship-list",
      info: "View and upload shipping parameters from multiple purchase orders.",
      icon: "https://res.cloudinary.com/brandhub/image/upload/v1610483653/prod/Icons/Wrap_Up_Reports_hwy0en.png",
    },
  ].filter(Boolean);
  return (
    <Grid
      container
      spacing={4}
      justifyContent="center"
      style={{ width: "80%" }}
    >
      <div style={{ paddingLeft: "24px", width: "100%", textAlign: "left" }}>
        <Typography className={classes.titleText}>
          {`Welcome back ${name}!`}
        </Typography>
      </div>
      {cardData.map((data, index) => (
        <Grid
          item
          lg={3}
          md={4}
          sm={6}
          xs={12}
          component={Link}
          to={data.link}
          key={index}
        >
          <div className={classes.dashboardGridItem}>
            <Paper className={classes.dashPaper} elevation={5}>
              <div
                id={data.id}
                className={classes.innerPaper}
                aria-owns={data.ariaOwnsState ? data.ariaOwnsText : undefined}
                aria-haspopup="true"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
              >
                <div
                  style={{
                    width: "100%",
                    height: "30%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography className={classes.titleText}>
                    {data.titleText}
                  </Typography>
                </div>
                <Divider className={classes.divider} />
                <ImageWrapper
                  imgUrl={data.icon}
                  alt={data.titleText}
                  imgClass={classes.icon}
                  id={`${data.id}-image`}
                  handleClick={null}
                />
                {/* <img className={classes.icon} src={data.icon} /> */}
              </div>
            </Paper>
          </div>
          <InfoPopover
            id={data.id}
            info={data.info}
            classes={classes}
            open={data.ariaOwnsState}
            anchorEl={anchorEl}
            handlePopoverClose={handlePopoverClose}
          />
        </Grid>
      ))}
    </Grid>
  );
};

FieldDash.propTypes = {
  classes: PropTypes.object.isRequired,
  InfoPopover: PropTypes.func.isRequired,
};

export default FieldDash;
