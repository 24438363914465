import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import Container from "@mui/material/Container";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";

import { setFiltersOpen } from "src/redux/slices/filterSlice";

import PlanningDash from "../components/Dashboard/PlanningDash";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
  ...theme.dashboard,
  paper: {
    padding: theme.spacing(1),
  },
}));

const InfoPopover = ({
  id,
  info,
  classes,
  open,
  anchorEl,
  handlePopoverClose,
}) => {
  return (
    <>
      <Popover
        id={id}
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={handlePopoverClose}
        marginThreshold={25}
        disableRestoreFocus
        disableScrollLock
      >
        <Typography className={classes.bodyText}>{info}</Typography>
      </Popover>
    </>
  );
};

const PlanningTool = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setFiltersOpen({ open: false }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Container
        className={classes.mainWrapper}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <br />
        <br />
        <br />
        <br />
        <PlanningDash classes={classes} InfoPopover={InfoPopover} />
      </Container>
      <br />
      <br />
    </>
  );
};

export default PlanningTool;
