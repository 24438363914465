import React from "react";
import { useDispatch } from "react-redux";

import { Chip } from "@mui/material";

import { openRFQModal } from "../../../redux/slices/planningTool/rfqSlice";
import StatusChip from "../../Utility/StatusChip";
import BasicTable from "./BasicTable";

const statusCounts = (quotes) =>
  quotes.reduce(
    (a, b) => {
      a[b.status] += 1;
      return a;
    },
    { sent: 0, accepted: 0, complete: 0, total: quotes.length }
  );
const statusMap = {
  draft: "",
  sent: "info",
  canceled: "error",
};

const RFQsTable = ({ data }) => {
  const dispatch = useDispatch();

  const handleViewRfq = (e, id) => {
    e.stopPropagation();
    dispatch(openRFQModal({ loadRFQ: id }));
  };

  const rfqs = data.map((row) => ({
    ...row,
    statusCounts: statusCounts(row.quotes),
  }));

  const headCells = [
    { key: "blank" },
    { key: "itemNumber", label: "Sequence #" },
    {
      key: "id",
      label: "RFQ",
      fn: (id) => (
        <Chip label="View RFQ" onClick={(e) => handleViewRfq(e, id)} />
      ),
    },
    { key: "itemType", label: "Item Type" },
    // { key: "supplier", lable: "Supplier" },
    { key: "round", label: "Quote Round" },
    {
      key: "statusCounts",
      label: "Accepted",
      fn: ({ accepted, complete, total }) => `${accepted + complete}/${total}`,
    },
    {
      key: "statusCounts",
      label: "Completed",
      fn: ({ complete, total }) => `${complete}/${total}`,
    },
    {
      key: "status",
      label: "Status",
      fn: (v) => <StatusChip status={statusMap[v]} label={v} />,
    },
    // { key: "dateCreated", label: "Date Submitted" },
  ];

  return (
    <BasicTable
      headerStyles={{}}
      // align={["left", "left", "left", "left", "left", "left", "left", "right"]}
      rows={[
        headCells.map((c) => c.label),
        ...rfqs.map((row) =>
          headCells.map(({ key, fn }) => (fn ? fn(row[key]) : row[key]))
        ),
      ]}
    />
  );
};

export default RFQsTable;
