import React, { useEffect } from "react";
import Helmet from "react-helmet";
import { useDispatch, useSelector } from "react-redux";

import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";

import clsx from "clsx";
import BackButton from "src/components/Reporting/BackButton";
import { setFiltersOpen } from "src/redux/slices/filterSlice";

import { useInput } from "../hooks/InputHooks";
import { useNoFetch } from "../hooks/UtilityHooks";
import {
  clearReports,
  getSingleItemInventoryReport,
} from "../redux/slices/reports/reportSlice";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
  ...theme.reports,
  variantBlock: {
    width: "50%",
    boxSizing: "border-box",
    padding: "15px",
    margin: "10px",
    border: `2px solid ${theme.palette.secondary.main}`,
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      width: "75%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

const ReportSingleItemInventory = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    value: itemNumber,
    bind: bindItemNumber,
    reset: resetItemNumber,
  } = useInput("");

  const {
    isLoading,
    reportData: report,
    reportType,
  } = useSelector((state) => state.reports);
  const { territoryList } = useSelector((state) => state.territories);

  const handleReset = () => {
    dispatch(clearReports());
    resetItemNumber();
  };

  const handleGetReport = () => {
    dispatch(getSingleItemInventoryReport(itemNumber));
    resetItemNumber();
  };

  useEffect(() => {
    dispatch(setFiltersOpen({ open: false }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useNoFetch(dispatch);

  useEffect(() => {
    dispatch(clearReports());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>RTA | Inventory Check</title>
      </Helmet>
      <Container className={classes.mainWrapper}>
        <div className={classes.titleBar}>
          <Typography className={classes.titleText}>
            Item Inventory Check Report
          </Typography>
          <BackButton />
        </div>
        <br />
        <div className={classes.searchComponents}>
          <TextField
            fullWidth
            variant="outlined"
            color="secondary"
            name="itemNumber"
            type="text"
            label="Sequence Number"
            className={classes.queryField}
            {...bindItemNumber}
          />
        </div>
        <br />
        <div className={classes.buttonRow}>
          <Button
            className={classes.largeButton}
            variant="contained"
            color="secondary"
            onClick={() => handleGetReport()}
            style={{ marginRight: "20px" }}
          >
            GENERATE REPORT
          </Button>
          <Button
            className={classes.largeButton}
            variant="contained"
            color="secondary"
            onClick={handleReset}
          >
            RESET REPORT
          </Button>
        </div>
        <br />
        <br />
        {isLoading && (
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <CircularProgress size={100} style={{ marginTop: "50px" }} />
          </div>
        )}
        {!isLoading && (!report || report.length === 0) && (
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <Typography className={classes.headerText}>
              No items were found that match the given sequence number.
            </Typography>
          </div>
        )}
        {!isLoading &&
          report.length > 0 &&
          reportType === "single-item-inventory" && (
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "stretch",
                textAlign: "center",
              }}
            >
              {report.map((v, i) => (
                <div className={classes.variantBlock} key={i}>
                  <Typography className={classes.headerText}>
                    {`Inventory Information for Sequence #${v.itemNumber}:`}
                  </Typography>
                  <br />
                  <Typography
                    className={clsx(classes.bodyText, classes.settingsMargin)}
                  >
                    <b>Warehouse:</b>
                    {` ${v.warehouse}`}
                  </Typography>
                  <Typography
                    className={clsx(classes.bodyText, classes.settingsMargin)}
                  >
                    <b>Recorded Warehouse Qty:</b>
                    {` ${v.warehouseQty}`}
                  </Typography>
                  <Typography
                    className={clsx(classes.bodyText, classes.settingsMargin)}
                  >
                    <b>Pending Qty:</b>
                    {` ${v.pendingQty}`}
                  </Typography>
                  <Typography
                    className={clsx(classes.bodyText, classes.settingsMargin)}
                  >
                    <b>Territory Allocations:</b>
                  </Typography>
                  {v.territoryQtys && v.territoryQtys.length > 0 ? (
                    v.territoryQtys.map((tq, i) => (
                      <Typography
                        key={`territory-qty-${i}`}
                        className={clsx(
                          classes.bodyText,
                          classes.settingsMargin
                        )}
                      >
                        <b>{`${
                          territoryList.find((t) => t.id === tq.territoryId)
                            .name
                        }:`}</b>
                        {` ${tq.qty}`}
                      </Typography>
                    ))
                  ) : (
                    <Typography
                      className={clsx(classes.bodyText, classes.settingsMargin)}
                    >
                      No allocations set for this item.
                    </Typography>
                  )}
                  <br />
                  <Typography
                    className={clsx(classes.bodyText, classes.settingsMargin)}
                  >
                    <b>Available Qty:</b>
                    {` ${v.availableQty}`}
                  </Typography>
                </div>
              ))}
            </div>
          )}
      </Container>
    </>
  );
};

ReportSingleItemInventory.propTypes = {};

export default ReportSingleItemInventory;
