import { useCallback, useEffect, useState } from "react";
import Helmet from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";

import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";

import { ItemModal } from "@features/items";
import { GlobalReportManager } from "@features/reports";
import client from "@services/api";

import { logoutUser } from "./api/userApi";
import FilterDrawer from "./components/Filtering/FilterDrawer";
import TopDrawerNav from "./components/Navigation/TopDrawerNav";
import ErrorModal from "./components/Utility/ErrorModal";
import Loading from "./components/Utility/Loading";
import StepperLoader from "./components/Utility/StepperLoader";
import {
  FREIGHT_FORWARDER,
  PLANNING_TOOL_SUPPLIER,
  READ_ONLY,
  SUPPLIER_ROLES,
} from "./constants/permissions";
import {
  fetchWarehouse,
  resetAddresses,
} from "./redux/slices/addresses/addressSlice";
import { resetComplianceItems } from "./redux/slices/compliance/complianceItemsSlice";
import { resetComplianceRules } from "./redux/slices/compliance/complianceRulesSlice";
import { clearError } from "./redux/slices/errorSlice";
import { resetFilters, updateSingleFilter } from "./redux/slices/filterSlice";
import { fetchBUs } from "./redux/slices/items/businessUnitSlice";
import { resetItems } from "./redux/slices/items/itemSlice";
import {
  fetchAllItemSubTypes,
  fetchAllItemTypes,
} from "./redux/slices/items/itemTypeSlice";
import { clearSharedItems } from "./redux/slices/items/sharedItemsSlice";
import {
  clearCurrentOrder,
  clearItemSelections,
  clearOrderByType,
  fetchCurrentOrderByType,
} from "./redux/slices/ordering/currentOrderSlice";
import { resetOrderHistory } from "./redux/slices/ordering/orderHistorySlice";
import { resetOrderSetHistory } from "./redux/slices/ordering/orderSetHistorySlice";
import { clearAdHocPrograms } from "./redux/slices/programs/adHocProgramSlice";
import {
  clearProgramTypes,
  getProgramTypes,
} from "./redux/slices/programs/programTypeSlice";
import {
  clearPrograms,
  setIsLoading,
} from "./redux/slices/programs/programsSlice";
import { resetPoHistory } from "./redux/slices/purchasing/purchaseOrderHistorySlice";
import { resetRfqHistory } from "./redux/slices/purchasing/rfpHistorySlice";
import { clearReports } from "./redux/slices/reports/reportSlice";
import {
  clearSuppliers,
  fetchAllSuppliers,
  fetchInitialValues,
} from "./redux/slices/suppliers/supplierSlice";
import {
  clearTerritories,
  fetchStates,
  fetchTerritories,
} from "./redux/slices/territories/territorySlice";
import {
  fetchUser,
  removeUser,
  setExpires,
  setTimeoutSet,
} from "./redux/slices/users/userSlice";
import { clearUserUpdate } from "./redux/slices/users/userUpdateSlice";
import RTARoutes from "./routes/RTARoutes";

axios.defaults.headers.get["Cache-Control"] = "no-cache";
axios.defaults.timeout = 35000;

const App = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const [currentUser, setCurrentUser] = useState(
    window.localStorage.getItem("brandhub-user")
  );
  const [logoutTimeout, setLogoutTimeout] = useState(null);
  const [isErrorOpen, setErrorOpen] = useState(false);

  const {
    id: userId,
    role,
    status,
    supplierId,
    sessionExpire,
    timeOutSet,
    error: userError,
    territories,
    currentTerritory,
    currentChannel,
    isLoading,
    loggedIn,
  } = useSelector((state) => state.user);
  const programsIsLoading = useSelector((state) => state.programs.isLoading);
  const couponIsLoading = useSelector(
    (state) => state.orderSet.isCouponLoading
  );
  const currentError = useSelector((state) => state.error.currentError);
  const isStepperLoad = useSelector((state) => state.globalLoad.isStepperLoad);
  const filtersOpen = useSelector((state) => state.filters.filtersOpen);
  const { isAuthenticated, isLoading: isAuthLoading, logout } = useAuth0();

  const handleErrorClose = () => {
    setErrorOpen(false);
    dispatch(clearError());
  };

  const handleLogout = useCallback(
    (expired = false) => {
      let url = expired
        ? `${window.location.origin}?expired=true`
        : `${window.location.origin}`;
      logoutUser();
      logout({ returnTo: url });
      setCurrentUser(null);
      dispatch(removeUser());
      dispatch(clearPrograms());
      dispatch(clearCurrentOrder());
      dispatch(clearOrderByType({ type: "inventory" }));
      dispatch(clearOrderByType({ type: "onDemand" }));
      dispatch(clearItemSelections());
      dispatch(resetItems());
      dispatch(resetOrderHistory());
      dispatch(resetOrderSetHistory());
      dispatch(clearTerritories());
      dispatch(clearSuppliers());
      dispatch(resetComplianceItems());
      dispatch(resetComplianceRules());
      dispatch(clearSharedItems());
      dispatch(resetAddresses());
      dispatch(resetRfqHistory());
      dispatch(resetPoHistory());
      dispatch(clearUserUpdate());
      dispatch(resetFilters());
      dispatch(clearReports());
      dispatch(clearAdHocPrograms());
      dispatch(clearProgramTypes());
    },
    [dispatch, logout]
  );

  const handleTimeout = useCallback(() => {
    handleLogout(true);
  }, [handleLogout]);

  useEffect(() => {
    const fetchCurrentUser = (token) => {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      client.headers["Authorization"] = `Bearer ${token}`;

      dispatch(fetchUser());
    };

    if (currentUser && role && status === "active" && isAuthenticated) {
      if (!SUPPLIER_ROLES.includes(role) && role !== FREIGHT_FORWARDER) {
        if (territories.length > 0) {
          if (role !== READ_ONLY) {
            dispatch(
              fetchCurrentOrderByType(
                "inventory",
                userId,
                currentChannel,
                currentTerritory
              )
            );
            dispatch(
              fetchCurrentOrderByType(
                "onDemand",
                userId,
                currentChannel,
                currentTerritory
              )
            );
          }
          dispatch(fetchAllItemTypes());
          dispatch(fetchAllItemSubTypes());
          dispatch(fetchAllSuppliers());
          dispatch(fetchTerritories());
          dispatch(fetchStates());
          dispatch(fetchBUs());
          dispatch(fetchWarehouse());
          dispatch(getProgramTypes());
          dispatch(
            updateSingleFilter({
              filter: "currentTerritoryId",
              value: currentTerritory,
            })
          );
        } else {
          dispatch(clearPrograms());
        }
      } else {
        if (SUPPLIER_ROLES.includes(role) && supplierId) {
          if (role === PLANNING_TOOL_SUPPLIER) {
            dispatch(fetchAllItemTypes());
            dispatch(fetchAllItemSubTypes());
            dispatch(fetchAllSuppliers());
            dispatch(getProgramTypes());
          }
          dispatch(fetchInitialValues());
          dispatch(clearPrograms());
        }
      }
    } else if (currentUser && JSON.parse(currentUser).access_token) {
      if (new Date(JSON.parse(currentUser).expires_at) < new Date()) {
        localStorage.removeItem("brandhub-user");
        localStorage.removeItem("brandhub-role");
        setCurrentUser(null);
        window.location.assign("/login");
      } else {
        if (isAuthenticated && !isAuthLoading) {
          dispatch(setIsLoading());
          fetchCurrentUser(JSON.parse(currentUser).access_token);
        }
      }
    } else {
      setCurrentUser(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, currentUser, role, loggedIn, isAuthenticated, isAuthLoading]);

  useEffect(() => {
    if (loggedIn && !currentUser) {
      setCurrentUser(window.localStorage.getItem("brandhub-user"));
    }
  }, [loggedIn, currentUser]);

  useEffect(() => {
    if (currentUser && JSON.parse(currentUser).expires_at) {
      if (sessionExpire !== JSON.parse(currentUser).expires_at) {
        dispatch(
          setExpires({
            expires: JSON.parse(currentUser).expires_at,
          })
        );
      }
    }
  }, [sessionExpire, currentUser, dispatch]);

  useEffect(() => {
    if (sessionExpire && !timeOutSet) {
      dispatch(setTimeoutSet());
      let msToLogout = new Date(sessionExpire) - new Date();
      setLogoutTimeout(setTimeout(handleTimeout, msToLogout));
    }

    return () => {
      clearTimeout(logoutTimeout);
    };
  }, [
    sessionExpire,
    timeOutSet,
    dispatch,
    setLogoutTimeout,
    handleTimeout,
    logoutTimeout,
  ]);

  useEffect(() => {
    if (currentError) {
      setErrorOpen(true);
    }
  }, [currentError]);

  if (userError) {
    window.location.assign("/whoops");
  }

  return (
    <>
      {!loggedIn && !currentUser ? (
        <>
          {!location.pathname.includes("/login") &&
            !location.pathname.includes("/approveOrDenyItem") && (
              <Navigate to="/" />
            )}
          {isErrorOpen && (
            <ErrorModal open={isErrorOpen} handleClose={handleErrorClose} />
          )}
          <RTARoutes
            loggedIn={loggedIn}
            currentUser={currentUser}
            role={role}
            isAuthenticated={isAuthenticated}
            handleLogout={handleLogout}
          />
        </>
      ) : role && isAuthenticated ? (
        <div
          id="main-container"
          style={{ marginLeft: filtersOpen ? "300px" : "0px" }}
        >
          {loggedIn && (
            <>
              <TopDrawerNav userType={role} handleLogout={handleLogout} />
              <Helmet>
                <script type="text/javascript">{`!function(e,t,n){function a(){var e=t.getElementsByTagName("script")[0],n=t.createElement("script");n.type="text/javascript",n.async=!0,n.src="https://beacon-v2.helpscout.net",e.parentNode.insertBefore(n,e)}if(e.Beacon=n=function(t,n,a){e.Beacon.readyQueue.push({method:t,options:n,data:a})},n.readyQueue=[],"complete"===t.readyState)return a();e.attachEvent?e.attachEvent("onload",a):e.addEventListener("load",a,!1)}(window,document,window.Beacon||function(){});window.Beacon('init', '521f5954-7022-46e2-9707-6a82501f23e7');Beacon('on', 'article-viewed', ({id}) => { Beacon('article', id , { type: 'modal' })})`}</script>
              </Helmet>
            </>
          )}
          {isErrorOpen && (
            <ErrorModal open={isErrorOpen} handleClose={handleErrorClose} />
          )}
          <FilterDrawer />

          <GlobalReportManager />
          <ItemModal />

          {isStepperLoad && <StepperLoader />}
          {couponIsLoading && (
            <Loading partial={true} hidden={programsIsLoading ? true : false} />
          )}
          {isLoading && <Loading partial={false} />}
          {role === READ_ONLY && territories.length === 0 && (
            <Navigate to="/newUser" />
          )}
          {status === "inactive" && <Navigate to="/deactivatedUser" />}
          {SUPPLIER_ROLES.includes(role) && !supplierId && (
            <Navigate to="/newSupplier" />
          )}
          {(location.pathname === "/" ||
            location.pathname.includes("/login")) && (
            <Navigate to="/dashboard" />
          )}
          {role === READ_ONLY && territories.length === 0 && (
            <Navigate to="/newUser" />
          )}
          <RTARoutes
            loggedIn={loggedIn}
            currentUser={currentUser}
            role={role}
            isAuthenticated={isAuthenticated}
            handleLogout={handleLogout}
          />
        </div>
      ) : (
        <Loading partial={false} />
      )}
    </>
  );
};

export default App;
