import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";

import PropTypes from "prop-types";

const StatusSelector = ({
  handleStatus,
  status,
  setStatus,
  classes,
  filterType,
}) => {
  const ordersStatusList = [
    { status: "draft", label: "Draft" },
    { status: "submitted", label: "Submitted" },
    { status: "approved", label: "Approved" },
    { status: "order-canceled", label: "Order Item Canceled" },
    { status: "compliance-canceled", label: "Compliance Canceled" },
    { status: "destroyed", label: "Destroyed" },
    { status: "not-draft", label: "All Orders" },
  ];
  const historyStatusList = [
    { status: "in-progress", label: "In Progress" },
    { status: "submitted", label: "Submitted" },
    { status: "all-orders", label: "All Orders" },
  ];
  const complianceStatusList = [
    { status: "approved", label: "Approved" },
    { status: "denied", label: "Denied" },
    { status: "in-violation", label: "In Violation" },
    { status: "prior-approval-pending", label: "Pending" },
    { status: "all", label: "All Status" },
  ];
  const rfpStatusList = [
    { status: "draft", label: "Draft" },
    { status: "sent", label: "Waiting for Resp." },
    { status: "ready-for-review", label: "Ready for Review" },
    { status: "awarded", label: "Awarded" },
    { status: "complete", label: "Complete" },
    { status: "all", label: "All Status" },
  ];
  const rfpSupplierStatusList = [
    { status: "bid-sent", label: "New" },
    { status: "bid-accepted", label: "In Progress" },
    { status: "bid-awarded", label: "Awarded" },
    { status: "all", label: "All Status" },
  ];
  const poSupplierStatusList = [
    { status: "submitted", label: "New" },
    { status: "in-progress", label: "In Progress" },
    { status: "shipping-hold", label: "Shipping Hold" },
    { status: "complete", label: "Complete" },
    { status: "not-invoiced", label: "Not Invoiced" },
    { status: "all", label: "All Status" },
  ];
  let poStatusList = [
    { status: "draft", label: "Draft" },
    { status: "submitted", label: "Submitted" },
    { status: "in-progress", label: "In Progress" },
    { status: "shipping-hold", label: "Shipping Hold" },
    { status: "complete", label: "Complete" },
    { status: "sent-to-flow", label: "Sent To Flow" },
    { status: "all", label: "All Status" },
  ];
  const complianceRulesStatusList = [
    { status: "active", label: "Active" },
    { status: "inactive", label: "Inactive" },
  ];

  const filterMap = {
    history: historyStatusList,
    compliance: complianceStatusList,
    complianceRules: complianceRulesStatusList,
    rfp: rfpStatusList,
    po: poStatusList,
    rfpSupplier: rfpSupplierStatusList,
    poSupplier: poSupplierStatusList,
    orders: ordersStatusList,
  };

  const lableMap = {
    history: "Order Status",
    orders: "Order Status",
    compliance: "Item Status",
    complianceRules: "Rule Status",
    rfp: "RFP Status",
    rfpSupplier: "RFP Status",
    po: "PO Status",
    poSupplier: "PO Status",
  };

  const currentStatus = useSelector((state) => state.filters.status);
  const isGlobalLoading = useSelector((state) => state.globalLoad.isLoading);
  const role = useSelector((state) => state.user.role);

  if (role === "purchaser") {
    poStatusList = poStatusList.filter((s) => s.status !== "sent-to-flow");
  }

  const handleChangeSelect = (evt) => {
    setStatus(evt.target.value);
    handleStatus(evt.target.value, "status", filterType);
  };

  useEffect(() => {
    let currentKeys = filterMap[filterType].map((key) => key.status);
    if (currentStatus !== status && currentKeys.includes(currentStatus)) {
      setStatus(currentStatus);
    } else if (currentStatus !== status) {
      setStatus(currentStatus);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStatus, status, filterType, setStatus]);

  return (
    <>
      <FormControl
        fullWidth
        variant="outlined"
        size="small"
        className={classes.queryField}
        disabled={isGlobalLoading}
      >
        <InputLabel id="status-select">{lableMap[filterType]}</InputLabel>
        <Select
          variant="outlined"
          label={lableMap[filterType]}
          name="status"
          labelId="status-select"
          id="status"
          value={
            status &&
            filterMap[filterType].map((ele) => ele.status).includes(status)
              ? status
              : ""
          }
          defaultValue=""
          onChange={handleChangeSelect}
          MenuProps={{
            style: { zIndex: "2500" },

            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          }}
        >
          {filterType === "history" &&
            historyStatusList.map((status, index) => (
              <MenuItem value={status.status} key={index}>
                <Typography variant="body2">{status.label}</Typography>
              </MenuItem>
            ))}
          {filterType === "compliance" &&
            complianceStatusList.map((status, index) => (
              <MenuItem value={status.status} key={index}>
                <Typography variant="body2">{status.label}</Typography>
              </MenuItem>
            ))}
          {filterType === "complianceRules" &&
            complianceRulesStatusList.map((status, index) => (
              <MenuItem value={status.status} key={index}>
                <Typography variant="body2">{status.label}</Typography>
              </MenuItem>
            ))}
          {filterType === "rfp" &&
            rfpStatusList.map((status, index) => (
              <MenuItem value={status.status} key={index}>
                <Typography variant="body2">{status.label}</Typography>
              </MenuItem>
            ))}
          {filterType === "rfpSupplier" &&
            rfpSupplierStatusList.map((status, index) => (
              <MenuItem value={status.status} key={index}>
                <Typography variant="body2">{status.label}</Typography>
              </MenuItem>
            ))}
          {filterType === "po" &&
            poStatusList.map((status, index) => (
              <MenuItem value={status.status} key={index}>
                <Typography variant="body2">{status.label}</Typography>
              </MenuItem>
            ))}
          {filterType === "poSupplier" &&
            poSupplierStatusList.map((status, index) => (
              <MenuItem value={status.status} key={index}>
                <Typography variant="body2">{status.label}</Typography>
              </MenuItem>
            ))}
          {filterType === "orders" &&
            ordersStatusList.map((status, index) => (
              <MenuItem value={status.status} key={index}>
                <Typography variant="body2">{status.label}</Typography>
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </>
  );
};

StatusSelector.propTypes = {
  handleStatus: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  setStatus: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default StatusSelector;
