/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import Close from "@mui/icons-material/Close";
import {
  Dialog,
  FormControl,
  IconButton,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
} from "@mui/material";

import { addDays, format } from "date-fns";
import { saveAs } from "file-saver";
import { asBlob as htmlToDocxBlob } from "html-docx-js-typescript";
import { without } from "lodash";
import fp from "lodash/fp";

import templates from "../../../../constants/couponCopyTemplates";
import { getMultiBrand } from "../../../../redux/slices/items/brandSlice";
import { updateItemProgram } from "../../../../redux/slices/planningTool/itemProgramSlice";
import {
  moneyAdornment,
  moneyValidation,
} from "../../../../utility/inputHelpers";
import {
  formatDate,
  formatDateString,
  formatMoneyString,
} from "../../../../utility/utilityFunctions";
import {
  DateInput,
  SelectInput,
  TextInput,
} from "../../../Forms/ControlledInputs";
import { StyledButton } from "../../../StyledComponents";
import LocalErrorBoundary from "../../../Utility/LocalErrorBoundary";
import { commonOptions, templateMenuOptions } from "./templateMenuOptions";

const SectionTitle = tw.div`text-sm text-neutral-600 mb-3 uppercase tracking-wide`;

const templateValueReg = /{{([^}{]+)}}/gim; // matches a template variable

// The order of the keys is preserved when
// displaying the fields.
const editableFields = {
  couponIssueDate: {
    label: "Coupon Start Date",
    input: DateInput,
    transform: formatDateString,
  },
  couponExpirationDate: {
    label: "Offer Expiration Date",
    input: DateInput,
    transform: formatDateString,
  },
  couponMailInExpirationDate: {
    label: "Coupon Mail In Expiration Date",
    input: DateInput,
    transform: formatDateString,
  },

  couponFaceValue: {
    label: "Coupon Value",
    rules: moneyValidation,
    input: (props) => <TextInput {...props} InputProps={moneyAdornment} />,
    transform: formatMoneyString,
  },
  couponOfferDescription: {
    css: tw`w-full`,
    label: "Quantity and Package",
    input: (props) => <TextInput multiline {...props} />,
    helperText:
      "Please format this field as follows: “Quantity (Number) of Package Type” (Example: “One (1) 750 ML Bottle”)",
  },
  couponPurchaseRequirementCode: {
    label: "Purchase Requirement",
  },

  "Offer Tier 1": {
    helperText: "(ex: Buy 3 Save $9)",
  },
  "States on the States list": {
    helperText:
      "Please enter the states where this coupon will be available, ex. CA, NY, CT",
    css: tw`w-full`,
  },
  "NAB Item": {
    helperText: "Non-Alcoholic Beverage Item",
    css: tw`w-full`,
  },
  brandMandatory: {
    label: "Brand Mandatory Line",
    helperText: "This value wasn't in the database, so you may enter it here",
    css: tw`w-full`,
  },
  "Coupon Fees Internal Order": { css: tw`flex-1 min-w-[250px]` },
  "Coupon Redemptions Internal Order": { css: tw`flex-1 min-w-[250px]` },
  "Campaign Contact": { css: tw`flex-1 min-w-[250px]` },
  "Campaign Contact Email": { css: tw`flex-1 min-w-[250px]` },
  "Suggested Keyword(s)": {
    css: tw`flex-1 min-w-[250px]`,
    helperText: "Text <keyword> to 73876",
  },
  "Rebate Max Quantity": {
    css: tw`flex-1 min-w-[250px]`,
    helperText: 'Format as "Quantity (Number)". Eg. "One (1)"',
  },

  "Type of Offer Request": {
    input: (props) => (
      <SelectInput
        {...props}
        options={[
          { id: "Text", label: "Text" },
          { id: "QR Code", label: "QR Code" },
        ]}
      />
    ),
  },

  "Register Mark": {
    input: (props) => (
      <SelectInput
        {...props}
        options={[
          { id: "®", label: "®" },
          { id: "™", label: "™" },
        ]}
      />
    ),
  },

  brandBUCode: { label: "Business Unit" },
  brandCode: { label: "Brand Code(s)" },
};

// These fields won't show up as inputs
// Their values can only come from the coupon Item or the brands table.
const hiddenFields = [
  "couponBarcodeId",
  "brandName",
  "brandNameRegister",
  "brandMandatory",
  "brandCompany",
];
const readOnlyFields = [
  "couponMailInExpirationDate",
  "couponExpirationDate",
  "couponIssueDate",
];

const REGISTERED = "®";

const registeredBrand = (brand) =>
  brand.includes(REGISTERED) ? brand : brand + REGISTERED;

const sortedByPositionInFields = (arr) => {
  const sortOrder = Object.keys(editableFields);
  const idx = (a) => sortOrder.indexOf(a) + 1 || 999;
  let sorted = [...arr];
  return sorted.sort((a, b) => idx(a) - idx(b));
};

const getVariablesInTemplate = fp.pipe(
  (str) => [...str.matchAll(templateValueReg)],
  fp.map((m) => m[1]),
  fp.uniq,
  sortedByPositionInFields
);

const applyValuesToTemplate = (valueObj, templateStr) =>
  templateStr.replace(templateValueReg, (_, key) => {
    const { transform } = editableFields[key] || {};
    const val = valueObj[key] ?? key;
    return transform?.(val) ?? val;
  });

const setYear = (textContainingYear, newYear) =>
  textContainingYear.replace(/\b\d{4}\b/, newYear);

const convertToPreview = (html) =>
  html.replace(/font-size: (\d+\.?\d*)pt/gm, (_, d) => `font-size: ${d}px`);

const prepareHTMLForDownload = (el) => {
  // Remove text-transformations from template as they cause issues
  // when users try to edit the documents.
  const tags = el.querySelectorAll("[style*='text-transform']");
  const TEXT_NODE_TYPE = 3;

  Array.from(tags).forEach((tag) => {
    const transformation = tag.style.textTransform;
    tag.style.removeProperty("text-transform");
    walkAndTransform(tag, transformation);
  });

  function walkAndTransform(node, tf) {
    if (node.style.textTransform) return;
    node.childNodes.forEach((cn) => {
      if (cn.nodeType === TEXT_NODE_TYPE) {
        cn.textContent =
          tf === "uppercase"
            ? cn.textContent.toUpperCase()
            : tf === "lowercase"
            ? cn.textContent.toLowerCase()
            : cn.textContent;
      } else {
        walkAndTransform(cn, tf);
      }
    });
  }

  // Remove the red coloring and return;
  const cleaned = el.innerHTML
    .replace(/color: red;/g, "")
    .replace(/font-size: (\d+\.?\d*)px/gm, (_, d) => `font-size: ${d}pt`);

  // Important here to specify the encoding!
  return `<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8">
</head>
<body>
  ${cleaned}
</body>
</html>`;
};

const exportToWord = async (el) => {
  const cleanedHTML = prepareHTMLForDownload(el);
  const inchToTwip = (d) => d * 1440;
  const fileBlob = await htmlToDocxBlob(cleanedHTML, {
    margins: {
      top: inchToTwip(0.45),
      bottom: inchToTwip(0.2),
      left: inchToTwip(0.7),
      right: inchToTwip(0.7),
    },
  });

  saveAs(fileBlob, "word.docx");
};

const CouponCopyModalContent = ({ item, handleClose }) => {
  const dispatch = useDispatch();
  const { multiBrand } = useSelector((state) => state.brands);

  const [generatedHTML, setGeneratedHTML] = useState("");
  const [template, setTemplate] = useState("");
  const [inputsToShow, setInputsToShow] = useState([]);
  const documentContainerRef = useRef(null);

  const brand = item.brands.length > 1 ? multiBrand : item.brands[0];
  const { control, handleSubmit, setValue } = useForm({
    defaultValues: {
      ...item,
      // 15 days after expiration
      couponMailInExpirationDate: format(
        addDays(formatDate(item.couponExpirationDate), 15),
        "yyyy-MM-dd"
      ),
      "Offer Tier 1": "",
      "Offer Tier 2": "",
      "Offer Tier 3": "",
    },
  });

  const templateMenuArray = [
    ...Object.entries(templateMenuOptions[item.couponTypeId]),
    ...Object.entries(commonOptions),
  ];

  const handleApplyValuesToTemplate = handleSubmit((values) => {
    setGeneratedHTML(applyValuesToTemplate(values, templates[template]));
  });

  const controlled = (name, rules) => ({
    name,
    control,
    rules,
    disabled: readOnlyFields.includes(name),
  });

  const handleExport = () => {
    exportToWord(documentContainerRef.current);
    dispatch(updateItemProgram(item, { couponCopyDone: true }));
  };

  useEffect(() => {
    if (template) {
      const editableVariables = getVariablesInTemplate(templates[template]);
      // Only allow mandatory line to be editable if the value is null in the DB
      const hiddenVariables = brand?.mandatoryLine
        ? hiddenFields
        : without(hiddenFields, "brandMandatory");
      setInputsToShow(without(editableVariables, ...hiddenVariables));
    }
    setGeneratedHTML("");
  }, [template, brand]);

  // Set brands
  useEffect(() => {
    if (!brand) dispatch(getMultiBrand());
    else {
      setValue("brandName", brand.name);
      setValue("brandCode", brand.brandCode);
      setValue("brandBUCode", brand.buCode);
      setValue("brandNameRegister", registeredBrand(brand.name));
      setValue("brandCompany", brand.company);
      if (brand.mandatoryLine) {
        setValue(
          "brandMandatory",
          setYear(
            brand.mandatoryLine,
            formatDate(item.couponExpirationDate)?.getFullYear()
          )
        );
      }
    }
  }, [brand, item.couponExpirationDate, setValue, dispatch]);

  return (
    <div tw="px-6 py-12 w-full flex flex-col items-center mx-auto">
      <IconButton tw="absolute top-2 right-2" onClick={handleClose}>
        <Close />
      </IconButton>
      <h2 tw="text-xl text-neutral-600 mb-6">
        Generate Coupon Terms & Conditions
      </h2>
      <SectionTitle>Select template</SectionTitle>

      <FormControl fullWidth size="small">
        <InputLabel id="template-select-label">Template</InputLabel>
        <Select
          labelId="template-select-label"
          id="template-select"
          value={template}
          label="Template"
          onChange={(e) => setTemplate(e.target.value)}
        >
          {templateMenuArray.map(([key, value]) =>
            value ? (
              <MenuItem key={key} value={key} tw="text-neutral-500 text-sm">
                {value}
              </MenuItem>
            ) : (
              <ListSubheader key={key} tw="text-neutral-700 text-base mt-2">
                {key}
              </ListSubheader>
            )
          )}
        </Select>
      </FormControl>

      {inputsToShow.length > 0 && (
        <>
          <SectionTitle tw="mt-6">Review values</SectionTitle>
          <form tw="flex flex-wrap gap-4">
            {inputsToShow.map((name) => {
              const opts = editableFields[name] || {};
              const InputComponent = opts.input || TextInput;
              return (
                <InputComponent
                  css={opts.css || tw`flex-1 min-w-[150px]`}
                  key={name}
                  type={opts.type}
                  label={opts.label || name}
                  helperText={opts.helperText}
                  {...controlled(name, { ...opts.rules })}
                />
              );
            })}
          </form>
        </>
      )}

      {template && inputsToShow.length > 0 && (
        <StyledButton tw="mt-4" cta onClick={handleApplyValuesToTemplate}>
          Generate Copy
        </StyledButton>
      )}

      {generatedHTML && (
        <>
          <div tw="mt-6 flex items-center border-2 border-neutral-200 border-solid w-[620px]">
            {generatedHTML ? (
              <div tw="py-12 px-12 w-full bg-white">
                <div
                  tw="text-sm leading-tight"
                  ref={documentContainerRef}
                  dangerouslySetInnerHTML={{
                    __html: convertToPreview(generatedHTML),
                  }}
                ></div>
              </div>
            ) : (
              <div tw="flex justify-center items-center bg-neutral-100 shadow-inner rounded-lg h-full">
                <span tw="text-xl text-neutral-400">Nothing to preview</span>
              </div>
            )}
          </div>
          <StyledButton tw="mt-4" cta onClick={handleExport}>
            Export to Word document
          </StyledButton>
        </>
      )}
    </div>
  );
};

const CouponCopyModal = ({ open, handleClose, item }) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll="body"
      css={{
        "&": tw`mt-20`,
        "& .MuiPaper-root": tw`w-full max-w-2xl`,
      }}
    >
      <LocalErrorBoundary>
        <CouponCopyModalContent item={item} handleClose={handleClose} />
      </LocalErrorBoundary>
    </Dialog>
  );
};

export default CouponCopyModal;
