/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { TextField } from "@mui/material";
import { Autocomplete } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import makeStyles from "@mui/styles/makeStyles";

import {
  clearItems,
  fetchFilteredItems,
} from "../../../../redux/slices/items/itemSlice";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
}));

const itemLabel = (item) =>
  `${item.itemNumber || "---"} | ${item.itemType} | ${item.itemDescription}`;

const AutocompleteItemFromProgram = ({
  itemIdState,
  programIdState: [programId],
  ...props
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [itemId, setItemId] = itemIdState;
  const [shadowItemId, setShadowItemId] = useState(null);
  const itemList = useSelector((state) => state.items.items);

  const options = useMemo(
    () =>
      itemList.map((item) => ({
        id: item.id,
        label: itemLabel(item),
      })),
    [itemList]
  );

  // Create a lookup for faster fetching
  const optionsLookup = useMemo(
    () =>
      options.reduce((obj, a) => {
        obj[a.id] = a;
        return obj;
      }, {}),
    [options]
  );

  const getOpObj = (option) => {
    if (!option?.id) option = optionsLookup[option];
    return option;
  };

  useEffect(() => {
    const itemFilter = {
      program: [{ id: programId }],
    };
    dispatch(fetchFilteredItems(itemFilter));
  }, [programId, fetchFilteredItems, dispatch]);

  useEffect(() => {
    if (itemList.length === 1) {
      setItemId(itemList[0].id);
    }
  }, [itemList]);

  useEffect(() => {
    if (!itemList.length) return;
    setShadowItemId(itemId);
  }, [itemId, itemList]);

  // Clean up on dismount
  useEffect(() => () => dispatch(clearItems()), [dispatch]);

  return (
    <Autocomplete
      id="item-auto-complete"
      className={classes.queryField}
      classes={{
        popper: classes.liftedPopper,
      }}
      loading={!!itemList.length}
      options={options}
      onChange={(_, v) => setItemId(v?.id || null)}
      value={shadowItemId}
      isOptionEqualToValue={(option, value) => option.id === value}
      getOptionLabel={(option) => getOpObj(option)?.label || ""}
      {...props}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Select Item"
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {!itemList.length ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default AutocompleteItemFromProgram;
