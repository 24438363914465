import { useDispatch } from "react-redux";

import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  clearCurrentOrder,
  clearOrderByType,
} from "src/redux/slices/ordering/currentOrderSlice";

import { OrderSet } from "@models/OrderSet";
import client from "@services/api";

import { orderSetsKeyFactory } from "../../../queries/orderSetQueries";
import { useCurrentOrderSet } from "../CurrentOrderSetContext";
import useOrderSetId from "../useOrderSetId";

export default function useDeleteOrderSetMutation() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const orderSetId = useOrderSetId();
  const { onOrderSetDelete } = useCurrentOrderSet();
  return useMutation({
    mutationFn: () => client.delete(`/order-sets/${orderSetId}`),
    onSuccess: () => {
      // get order-set type
      const deletedOrderSet = queryClient.getQueryData(
        orderSetsKeyFactory.detail(orderSetId).queryKey
      ) as OrderSet;
      dispatch(
        clearOrderByType({
          type: deletedOrderSet.type === "on-demand" ? "onDemand" : "inventory",
        })
      );
      dispatch(clearCurrentOrder());
      queryClient.invalidateQueries({
        queryKey: orderSetsKeyFactory.detail(orderSetId).queryKey,
      });

      queryClient.invalidateQueries({
        queryKey: orderSetsKeyFactory.paginated._def,
      });

      onOrderSetDelete(deletedOrderSet);
    },
  });
}
