/** @jsxImportSource @emotion/react */
import "twin.macro";

const ReadOnlyField = ({ label, value }) => (
  <div tw="w-full max-w-xs">
    <div tw="text-xs text-gray-600 mx-1 mb-0.5">{label}</div>
    <div tw="bg-gray-50 rounded text-gray-700 px-3 py-1.5 shadow-inner">
      {value}
    </div>
  </div>
);

export default ReadOnlyField;
