/** @jsxImportSource @emotion/react */
import "twin.macro";

import { Tooltip } from "@mui/material";

import { formatMoneyString } from "src/utility/utilityFunctions";

import { useCurrentOrderSet } from "./data/CurrentOrderSetContext";

export default function TotalCost({
  children: cost,
}: {
  children?: string | number;
}) {
  const { orderSet } = useCurrentOrderSet();
  if (+(cost ?? 0) > 0 && orderSet.type === "from-inventory") {
    return (
      <>
        <Tooltip title="Item costs aren't charged on inventory orders.">
          <span tw="line-through opacity-50">
            &nbsp;{formatMoneyString(cost)}&nbsp;
          </span>
        </Tooltip>
        <span tw="pl-px">$0.00</span>
      </>
    );
  }
  return <span>{formatMoneyString(cost)}</span>;
}
