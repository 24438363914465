/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { useDispatch, useSelector } from "react-redux";

import GetAppIcon from "@mui/icons-material/GetApp";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";

import BackButton from "src/components/Reporting/BackButton";

import { CSVLink } from "@utils/csv";

import {
  PurchasingSelector,
  StatusSelector,
} from "../components/Reporting/ReportSelectors";
import ReportTable from "../components/Reporting/ReportTable";
import ProgramAutoComplete from "../components/Utility/AutoCompleteFields/ProgramAutoComplete";
import OrderWindowSelector from "../components/Utility/Selectors/OrderWindowSelector";
import { useReportCsv } from "../hooks/Reports/useReportCsv";
import { useNoFetch } from "../hooks/UtilityHooks";
import {
  resetFilters,
  setFiltersOpen,
  updateSingleFilter,
} from "../redux/slices/filterSlice";
import {
  clearReports,
  getReportById,
} from "../redux/slices/reports/reportSlice";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
  ...theme.reports,
}));

const ReportOrderWindowSummary = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [hasGenerated, setHasGenerated] = useState(false);
  const [orderWindow, setOrderWindow] = useState(null);
  const [currentStatus, setCurrentStatus] = useState("submitted,approved");
  const [currentPurchaser, setCurrentPurchaser] = useState("all");
  const [currentPrograms, setCurrentPrograms] = useState([]);
  const [reset, setReset] = useState(false);

  const isLoading = useSelector((state) => state.reports.isLoading);
  const report = useSelector((state) => state.reports.reportData);
  const reportType = useSelector((state) => state.reports.reportType);
  const currentChannel = useSelector((state) => state.user.currentChannel);
  const currentProgramFilter = useSelector((state) => state.filters.program);

  const { setCsvData, data, headers } = useReportCsv(
    "order-window-summary",
    hasGenerated,
    setHasGenerated,
    isLoading,
    report
  );

  const handleProgram = (value, _type, _filter) => {
    setCurrentPrograms(value);
    dispatch(updateSingleFilter({ filter: "program", value: value }));
  };

  const handleProgramChipClick = (id) => {
    let currentProgArray = currentProgramFilter.filter(
      (prog) => prog.id !== id
    );
    setCurrentPrograms(currentProgArray);
    dispatch(
      updateSingleFilter({ filter: "program", value: currentProgArray })
    );
  };

  const handleReset = () => {
    setCurrentPurchaser("all");
    setOrderWindow(null);
    setCurrentStatus("submitted,approved");
    setCurrentPrograms([]);
    dispatch(clearReports());
    dispatch(resetFilters());
    setCsvData({ data: [], headers: [] });
    setReset(true);
  };

  const handleGetReport = (_) => {
    const filterObject = {
      isPurchaserSelect: currentPurchaser,
      orderStatus: currentStatus,
      program: currentPrograms,
      channel: currentChannel,
      ocmIds: orderWindow,
    };
    dispatch(getReportById("order-window-summary", filterObject));
    setHasGenerated(true);
  };

  useEffect(() => {
    dispatch(setFiltersOpen({ open: false }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useNoFetch(dispatch);

  useEffect(() => {
    dispatch(clearReports());
    dispatch(resetFilters());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>RTA | Order Window Summary</title>
      </Helmet>
      <Container className={classes.mainWrapper}>
        <div className={classes.titleBar}>
          <Typography className={classes.titleText}>
            Order Window Summary Report
          </Typography>
          <div className={classes.configButtons}>
            <div className={classes.innerConfigDiv}>
              <div tw="mr-2">
                <BackButton />
              </div>
              <CSVLink
                data={data}
                headers={headers}
                filename="focus_summary.csv"
              >
                <Button
                  className={classes.largeButton}
                  variant="contained"
                  color="secondary"
                  startIcon={<GetAppIcon />}
                  disabled={data.length === 0}
                >
                  EXPORT REPORT
                </Button>
              </CSVLink>
            </div>
          </div>
        </div>
        <br />
        <div className={classes.searchComponents}>
          <PurchasingSelector
            currentPurchaser={currentPurchaser}
            setCurrentPurchaser={setCurrentPurchaser}
            classes={classes}
          />
          <OrderWindowSelector
            orderWindow={orderWindow}
            setOrderWindow={setOrderWindow}
          />
        </div>
        <br />
        <div className={classes.searchComponents}>
          <StatusSelector
            currentStatus={currentStatus}
            setCurrentStatus={setCurrentStatus}
            classes={classes}
          />
          <ProgramAutoComplete
            classes={classes}
            handleChange={handleProgram}
            reset={reset}
            setReset={setReset}
            filterType="report"
            id="report-program-complete"
          />
        </div>
        <br />
        <div style={{ width: "100%", textAlign: "center" }}>
          <Typography
            className={classes.headerText}
            style={{ marginRight: "10px" }}
          >
            Program Filters:
          </Typography>
        </div>
        <div className={classes.chipRow}>
          {currentPrograms.length > 0 &&
            currentPrograms.map((user) => (
              <Chip
                style={{ margin: "auto 2.5px" }}
                color="primary"
                key={user.id}
                label={user.name}
                onDelete={() => handleProgramChipClick(user.id)}
              />
            ))}
        </div>
        <br />
        <div className={classes.buttonRow}>
          <Button
            className={classes.largeButton}
            variant="contained"
            color="secondary"
            onClick={() => handleGetReport("focus")}
            style={{ marginRight: "20px" }}
          >
            GENERATE BY IN MARKET MONTH
          </Button>
          <Button
            className={classes.largeButton}
            variant="contained"
            color="secondary"
            onClick={() => handleGetReport("order")}
            style={{ marginRight: "20px" }}
          >
            GENERATE BY ORDER MONTH
          </Button>
          <Button
            className={classes.largeButton}
            variant="contained"
            color="secondary"
            onClick={handleReset}
          >
            CLEAR FILTERS
          </Button>
        </div>
        <br />
        <br />
        {isLoading && (
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <CircularProgress size={100} style={{ marginTop: "50px" }} />
          </div>
        )}
        {!isLoading &&
          report.length > 0 &&
          reportType === "order-window-summary" && (
            <ReportTable report={report} type="order-window-summary" />
          )}
      </Container>
    </>
  );
};

ReportOrderWindowSummary.propTypes = {};

export default ReportOrderWindowSummary;
