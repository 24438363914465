/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { useDispatch, useSelector } from "react-redux";

import GetAppIcon from "@mui/icons-material/GetApp";
import {
  Button,
  Checkbox,
  CircularProgress,
  Container,
  InputLabel,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import format from "date-fns/format";
import subMonths from "date-fns/subMonths";
import BackButton from "src/components/Reporting/BackButton";
import DateRangeSelector from "src/components/Utility/Selectors/DateRangeSelector";

import { CSVLink } from "@utils/csv";

import {
  PODateRangeSelector,
  POStatusSelector,
} from "../components/Reporting/ReportSelectors";
import ReportTable from "../components/Reporting/ReportTable";
import { useReportCsv } from "../hooks/Reports/useReportCsv";
import { useNoFetch } from "../hooks/UtilityHooks";
import { resetFilters, setFiltersOpen } from "../redux/slices/filterSlice";
import {
  clearReports,
  getPurchaseOrderReport,
} from "../redux/slices/reports/reportSlice";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
  ...theme.reports,
}));

const defaultFromDate = format(subMonths(new Date(), 1), "MM/dd/yyyy");
const defaultToDate = format(new Date(), "MM/dd/yyyy");

const ReportPurchaseOrders = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [hasGenerated, setHasGenerated] = useState(false);
  const [status, setStatus] = useState("all");
  const [showSecondary, setShowSecondary] = useState(true);
  const [dateRange, setDateRange] = useState("submitted");
  const [fromDate, setFromDate] = useState(defaultFromDate);
  const [toDate, setToDate] = useState(defaultToDate);

  const {
    isLoading,
    reportData: report,
    reportType,
  } = useSelector((state) => state.reports);
  const currentChannel = useSelector((state) => state.user.currentChannel);

  const { setCsvData, data, headers } = useReportCsv(
    "purchase-order-report",
    hasGenerated,
    setHasGenerated,
    isLoading,
    report
  );

  const handleReset = () => {
    setStatus("all");
    setDateRange("submitted");
    setFromDate(format(subMonths(new Date(), 1), "MM/dd/yyyy"));
    setToDate(format(new Date(), "MM/dd/yyyy"));
    setCsvData({ data: [], headers: [] });
    dispatch(resetFilters());
    dispatch(clearReports());
  };

  const handleGetReport = () => {
    const filterObject = {
      channel: currentChannel,
      poSubmittedFromDate: dateRange === "submitted" ? fromDate : null,
      poSubmittedToDate: dateRange === "submitted" ? toDate : null,
      poInMarketFromDate: dateRange === "in-market" ? fromDate : null,
      poInMarketToDate: dateRange === "in-market" ? toDate : null,
      poStatus: status,
      ...(!showSecondary &&
        dateRange === "in-market" && { orderWindowType: "national" }),
    };

    dispatch(getPurchaseOrderReport(filterObject));
    setHasGenerated(true);
  };

  useEffect(() => {
    dispatch(setFiltersOpen({ open: false }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useNoFetch(dispatch);

  useEffect(() => {
    dispatch(clearReports());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>RTA | Purchase Order Report</title>
      </Helmet>
      <Container className={classes.mainWrapper}>
        <div className={classes.titleBar}>
          <Typography className={classes.titleText}>
            Purchase Order Report
          </Typography>
          <div className={classes.configButtons}>
            <div className={classes.innerConfigDiv}>
              <div tw="mr-2">
                <BackButton />
              </div>
              <CSVLink
                data={data}
                headers={headers}
                filename="purchase-orders.csv"
              >
                <Button
                  className={classes.largeButton}
                  variant="contained"
                  color="secondary"
                  startIcon={<GetAppIcon />}
                  disabled={data.length === 0}
                >
                  EXPORT REPORT
                </Button>
              </CSVLink>
            </div>
          </div>
        </div>
        <br />
        <div className={classes.searchComponents}>
          <POStatusSelector
            currentStatus={status}
            setStatus={setStatus}
            classes={classes}
          />
          <PODateRangeSelector
            currentDateRange={dateRange}
            setDateRange={setDateRange}
            classes={classes}
          />
        </div>
        <br />
        <div className={classes.searchComponents}>
          {dateRange === "in-market" && (
            <InputLabel>
              <Checkbox
                checked={showSecondary}
                onChange={() => setShowSecondary(!showSecondary)}
              />
              Show Secondary Order Windows
            </InputLabel>
          )}
          <DateRangeSelector
            classes={classes}
            fromLabel={
              dateRange === "submitted"
                ? "Submitted From Date"
                : "In Market From Date"
            }
            toLabel={
              dateRange === "submitted"
                ? "Submitted To Date"
                : "In Market To Date"
            }
            fromDate={fromDate ? new Date(fromDate) : new Date(defaultFromDate)}
            toDate={toDate ? new Date(toDate) : new Date(defaultToDate)}
            onChangeFromDate={(value) => {
              setFromDate(format(value, "MM/dd/yyyy"));
            }}
            onChangeToDate={(value) => setToDate(format(value, "MM/dd/yyyy"))}
            disabled={isLoading}
          />
        </div>
        <br />
        <div className={classes.buttonRow}>
          <Button
            className={classes.largeButton}
            variant="contained"
            color="secondary"
            onClick={() => handleGetReport()}
            style={{ marginRight: "20px" }}
          >
            GENERATE REPORT
          </Button>
          <Button
            className={classes.largeButton}
            variant="contained"
            color="secondary"
            onClick={handleReset}
          >
            CLEAR FILTERS
          </Button>
        </div>
        <br />
        <br />
        {isLoading && (
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <CircularProgress size={100} style={{ marginTop: "50px" }} />
          </div>
        )}
        {!isLoading &&
          report.length > 0 &&
          reportType === "purchase-order-report" && (
            <ReportTable report={report} type="purchase-order-report" />
          )}
      </Container>
    </>
  );
};

ReportPurchaseOrders.propTypes = {};

export default ReportPurchaseOrders;
