import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import makeStyles from "@mui/styles/makeStyles";

import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import { fetchOrderVariantReport } from "src/redux/slices/ordering/orderHistorySlice";

import { SUPPLIER_ROLES } from "../../constants/permissions";
import { StyledButton } from "../StyledComponents";
import ItemPDF from "./ItemPDF";
import OrderWindowSummaryPresPDF from "./OrderWindowSummaryPresPDF";
import PurchaseOrderPDF from "./PurchaseOrderPDF";
import RFPPDF from "./RFPPDF";

/*
Each PDF loader uses a pdf component, and generates it with asynchronously on click so that each
pdf is created with the most recent set of data that they are working with.
*/

const useStyles = makeStyles((theme) => ({
  ...theme.global,
}));

export const handlePDFDownload = async (doc, fileName, setPDFLoading) => {
  setPDFLoading(true);
  const newPDF = pdf([]);
  newPDF.updateContainer(doc);
  const blob = await newPDF.toBlob();
  saveAs(blob, fileName);
  setPDFLoading(false);
};

export const POPDFLoader = ({ currentPO, fileName, styles }) => {
  const classes = useStyles();
  const { role } = useSelector((state) => state.user);
  const displayGalloCost = !SUPPLIER_ROLES.includes(role);
  const [isPDFLoading, setPDFLoading] = useState(false);

  return (
    <Button
      className={classes.largeButton}
      variant="contained"
      color="secondary"
      style={{ ...styles, minWidth: "87px" }}
      startIcon={<PictureAsPdfIcon />}
      disabled={isPDFLoading}
      onClick={() =>
        handlePDFDownload(
          <PurchaseOrderPDF
            po={currentPO}
            displayGalloCost={displayGalloCost}
          />,
          fileName,
          setPDFLoading
        )
      }
    >
      {isPDFLoading ? <CircularProgress size={28} /> : "PDF"}
    </Button>
  );
};

export const RFPPDFLoader = ({ currentRFP, fileName, styles }) => {
  const classes = useStyles();
  const [isPDFLoading, setPDFLoading] = useState(false);

  return (
    <Button
      className={classes.largeButton}
      variant="contained"
      color="secondary"
      style={{ ...styles, minWidth: "87px" }}
      startIcon={<PictureAsPdfIcon />}
      disabled={isPDFLoading}
      onClick={() =>
        handlePDFDownload(
          <RFPPDF currentRFP={currentRFP} />,
          fileName,
          setPDFLoading
        )
      }
    >
      {isPDFLoading ? <CircularProgress size={28} /> : "PDF"}
    </Button>
  );
};

export const ItemPDFLoader = ({ items, fileName, styles, disabled }) => {
  const classes = useStyles();
  const [isPDFLoading, setPDFLoading] = useState(false);

  return (
    <Button
      className={classes.largeButton}
      variant="contained"
      color="secondary"
      style={{ minWidth: "182px", ...styles }}
      startIcon={<PictureAsPdfIcon />}
      disabled={isPDFLoading || disabled}
      onClick={() =>
        handlePDFDownload(<ItemPDF items={items} />, fileName, setPDFLoading)
      }
    >
      {isPDFLoading ? (
        <CircularProgress size={28} />
      ) : (
        "DOWNLOAD ITEM PREVIEW PDF"
      )}
    </Button>
  );
};

export const OrderWindowSummaryPresPDFLoader = ({
  focusItems,
  geographyFilterName,
  geographyFilterArray,
  currentMonthName,
  currentYear,
  programArray,
  fileName,
}) => {
  const [isPDFLoading, setPDFLoading] = useState(false);

  return (
    <StyledButton
      cta
      startIcon={<PictureAsPdfIcon />}
      disabled={isPDFLoading}
      onClick={() =>
        handlePDFDownload(
          <OrderWindowSummaryPresPDF
            focusItems={focusItems}
            geographyFilterName={geographyFilterName}
            geographyFilterArray={geographyFilterArray}
            currentMonthName={currentMonthName}
            currentYear={currentYear}
            programArray={programArray}
          />,
          fileName,
          setPDFLoading
        )
      }
    >
      {isPDFLoading ? <CircularProgress size={28} /> : "DOWNLOAD PDF"}
    </StyledButton>
  );
};

export const OrderHistorySalesSummaryPDFLoader = () => {
  const dispatch = useDispatch();

  const [isPDFLoading, setPDFLoading] = useState(false);

  const download = () => {
    dispatch(fetchOrderVariantReport(true, setPDFLoading));
  };

  return (
    <StyledButton
      cta
      startIcon={<PictureAsPdfIcon />}
      disabled={isPDFLoading}
      onClick={() => download()}
      style={{ marginLeft: 10 }}
    >
      {isPDFLoading ? <CircularProgress size={28} /> : "Sales Report PDF"}
    </StyledButton>
  );
};
