/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { useDispatch, useSelector } from "react-redux";

import GetAppIcon from "@mui/icons-material/GetApp";
import {
  Button,
  Chip,
  CircularProgress,
  Container,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import BackButton from "src/components/Reporting/BackButton";

import { CSVLink } from "@utils/csv";

import ReportTable from "../components/Reporting/ReportTable";
import TerritoryAutoComplete from "../components/Utility/AutoCompleteFields/TerritoryAutoComplete";
import { useNumberOnlyInput } from "../hooks/InputHooks";
import { useReportCsv } from "../hooks/Reports/useReportCsv";
import { useNoFetch } from "../hooks/UtilityHooks";
import {
  resetFilters,
  setFiltersOpen,
  updateSingleFilter,
} from "../redux/slices/filterSlice";
import {
  clearReports,
  getVariantAllocationReport,
} from "../redux/slices/reports/reportSlice";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
  ...theme.reports,
}));

const ReportShelfInventory = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [hasGenerated, setHasGenerated] = useState(false);
  const [reset, setReset] = useState(false);
  const [currentTerritories, setCurrentTerritories] = useState([]);

  const { value: sku, bind: bindSku, reset: resetSku } = useNumberOnlyInput("");

  const { reportData, reportType, isLoading } = useSelector(
    (state) => state.reports
  );
  const currentTerritoryFilter = useSelector(
    (state) => state.filters.territory
  );

  const { setCsvData, data, headers } = useReportCsv(
    "variant-allocation",
    hasGenerated,
    setHasGenerated,
    isLoading,
    reportData
  );

  const handleReset = () => {
    setReset(true);
    dispatch(clearReports());
    dispatch(resetFilters());
    setCurrentTerritories([]);
    resetSku();
    setCsvData({ data: [], headers: [] });
  };

  const handleTerritory = (value, _type, _filter) => {
    setCurrentTerritories(value);
    dispatch(updateSingleFilter({ filter: "territory", value: value }));
  };

  const handleTerritoryChipClick = (id) => {
    let currentTerrArray = currentTerritoryFilter.filter(
      (terr) => terr.id !== id
    );
    setCurrentTerritories(currentTerrArray);
    dispatch(
      updateSingleFilter({ filter: "territory", value: currentTerrArray })
    );
  };

  const handleGetReport = () => {
    const filterObject = {
      sku: sku,
      territories: currentTerritories,
    };
    dispatch(getVariantAllocationReport(filterObject));
    setHasGenerated(true);
  };

  useNoFetch(dispatch);

  useEffect(() => {
    dispatch(setFiltersOpen({ open: false }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(clearReports());
    dispatch(resetFilters());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>RTA | Shelf Inventory Report</title>
      </Helmet>
      <Container className={classes.mainWrapper}>
        <div className={classes.titleBar}>
          <Typography className={classes.titleText}>
            Shelf Inventory Report
          </Typography>
          <div className={classes.configButtons}>
            <div className={classes.innerConfigDiv}>
              <div tw="mr-2">
                <BackButton />
              </div>
              <CSVLink
                data={data}
                headers={headers}
                filename="shelf_inventory.csv"
              >
                <Button
                  className={classes.largeButton}
                  variant="contained"
                  color="secondary"
                  startIcon={<GetAppIcon />}
                  disabled={data.length === 0}
                >
                  EXPORT REPORT
                </Button>
              </CSVLink>
            </div>
          </div>
        </div>
        <br />
        <div className={classes.searchComponents}>
          <TextField
            className={classes.queryField}
            name="item-number"
            color="secondary"
            size="small"
            type="text"
            label="Sequence #"
            variant="outlined"
            {...bindSku}
          />
          <TerritoryAutoComplete
            classes={classes}
            handleChange={handleTerritory}
            reset={reset}
            setReset={setReset}
            filterType="report"
            tw="max-w-[250px]"
          />
        </div>
        <br />
        <br />
        <br />
        <div style={{ width: "100%", textAlign: "center" }}>
          <Typography
            className={classes.headerText}
            style={{ marginRight: "10px" }}
          >
            Territory Filters:
          </Typography>
        </div>
        <div className={classes.chipRow}>
          {currentTerritories.length > 0 &&
            currentTerritories.map((terr) => (
              <Chip
                style={{ margin: "auto 2.5px" }}
                color="primary"
                key={terr.id}
                label={terr.name}
                onDelete={() => handleTerritoryChipClick(terr.id)}
              />
            ))}
        </div>
        <br />
        <div className={classes.buttonRow}>
          <Button
            className={classes.largeButton}
            variant="contained"
            color="secondary"
            onClick={() => handleGetReport()}
            style={{ marginRight: "20px" }}
          >
            GENERATE REPORT
          </Button>
          <Button
            className={classes.largeButton}
            variant="contained"
            color="secondary"
            onClick={handleReset}
          >
            CLEAR FILTERS
          </Button>
        </div>
        <br />
        <br />
        {isLoading && (
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <CircularProgress size={100} style={{ marginTop: "50px" }} />
          </div>
        )}
        {!isLoading &&
          reportData.length > 0 &&
          reportType === "variant-allocation" && (
            <ReportTable report={reportData} type="variant-allocation" />
          )}
      </Container>
    </>
  );
};

ReportShelfInventory.propTypes = {};

export default ReportShelfInventory;
