import React, { useState } from "react";
import { useSelector } from "react-redux";

import CancelIcon from "@mui/icons-material/Cancel";
import EditIcon from "@mui/icons-material/Edit";
import InfoIcon from "@mui/icons-material/Info";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";

import PropTypes from "prop-types";

import { formatMoney } from "../../utility/utilityFunctions";
import ImageWrapper from "../Utility/ImageWrapper";
import CancelInfoModal from "../Utility/Modals/CancelInfoModal";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
  clickableCell: {
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#737373",
      color: "white",
    },
  },
  cancelRow: {
    backgroundColor: "#920000",
  },
  cancelCell: {
    color: "white",
  },
}));

const SingleOrderDetailTable = ({
  items,
  handleTrackingClick,
  isEditing,
  handleCancelOpen,
  handleItemEditOpen,
}) => {
  const classes = useStyles();
  const [isCancelInfoOpen, setCancelInfoOpen] = useState(false);
  const [cancelInfo, setCancelInfo] = useState(null);

  const role = useSelector((state) => state.user.role);

  const handleClose = () => {
    setCancelInfo(null);
    setCancelInfoOpen(false);
  };

  const handleOpen = (info) => {
    setCancelInfo(info);
    setCancelInfoOpen(true);
  };

  return (
    <>
      {isCancelInfoOpen && (
        <CancelInfoModal
          open={isCancelInfoOpen}
          handleClose={handleClose}
          info={cancelInfo}
        />
      )}
      <TableContainer className={classes.tableContainer}>
        <Table className={classes.table} aria-label="order-variants">
          <TableHead>
            <TableRow>
              {isEditing && (
                <>
                  <TableCell padding="checkbox" />
                  <TableCell padding="checkbox" />
                </>
              )}
              <TableCell className={classes.headerText} align="left">
                Preview
              </TableCell>
              <TableCell className={classes.headerText} align="left">
                Sequence #
              </TableCell>
              {[
                "super",
                "purchaser",
                "select-purchaser",
                "compliance",
              ].includes(role) && (
                <TableCell className={classes.headerText} align="left">
                  Purchase Order #
                </TableCell>
              )}
              <TableCell className={classes.headerText} align="left">
                Program
              </TableCell>
              <TableCell className={classes.headerText} align="left">
                Brand
              </TableCell>
              <TableCell className={classes.headerText} align="left">
                Item Type
              </TableCell>
              <TableCell className={classes.headerText} align="left">
                Variant
              </TableCell>
              <TableCell className={classes.headerText} align="left">
                Qty / Pack
              </TableCell>
              <TableCell className={classes.headerText} align="left">
                Est. Cost
              </TableCell>
              <TableCell className={classes.headerText} align="left">
                Qty
              </TableCell>
              <TableCell className={classes.headerText} align="left">
                Est. Total
              </TableCell>
              <TableCell className={classes.headerText} align="left">
                Act. Total
              </TableCell>
              <TableCell className={classes.headerText} align="left">
                In Market Date
              </TableCell>
              <TableCell className={classes.headerText} align="left">
                Act. Ship Date
              </TableCell>
              <TableCell className={classes.headerText} align="left">
                Carrier
              </TableCell>
              <TableCell className={classes.headerText} align="left">
                Tracking
              </TableCell>
              <TableCell className={classes.headerText} align="left">
                Compliance Status
              </TableCell>
              <TableCell className={classes.headerText} align="left">
                Beacon
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item) => (
              <TableRow
                key={item.itemNumber}
                className={
                  item.isComplianceCanceled || item.isCanceled
                    ? classes.cancelRow
                    : null
                }
              >
                {isEditing && (
                  <>
                    <TableCell
                      padding="checkbox"
                      align="center"
                      className={
                        item.isComplianceCanceled || item.isCanceled
                          ? classes.cancelCell
                          : null
                      }
                    >
                      <IconButton
                        disabled={
                          item.actualShipDate !== "---" &&
                          item.tracking !== "---" &&
                          item.carrier !== "---"
                        }
                        onClick={(evt) => {
                          evt.stopPropagation();
                          handleItemEditOpen(item);
                        }}
                        size="large"
                      >
                        <EditIcon color="inherit" />
                      </IconButton>
                    </TableCell>

                    <TableCell
                      padding="checkbox"
                      align="center"
                      className={
                        item.isComplianceCanceled || item.isCanceled
                          ? classes.cancelCell
                          : null
                      }
                    >
                      <IconButton
                        disabled={
                          item.actualShipDate !== "---" &&
                          item.tracking !== "---" &&
                          item.carrier !== "---"
                        }
                        onClick={(evt) => {
                          evt.stopPropagation();
                          handleCancelOpen("item", item.id);
                        }}
                        size="large"
                      >
                        <CancelIcon color="inherit" />
                      </IconButton>
                    </TableCell>
                  </>
                )}
                <TableCell align="left">
                  <ImageWrapper
                    id={item.itemNumber}
                    imgClass={classes.previewImg}
                    alt={item.itemType}
                    imgUrl={item.imgUrlThumb}
                  />
                </TableCell>
                <TableCell
                  align="left"
                  className={
                    item.isComplianceCanceled || item.isCanceled
                      ? classes.cancelCell
                      : null
                  }
                >
                  {item.itemNumber}
                </TableCell>
                {[
                  "super",
                  "purchaser",
                  "select-purchaser",
                  "read-only",
                  "compliance",
                ].includes(role) && (
                  <TableCell
                    align="left"
                    className={
                      item.isComplianceCanceled || item.isCanceled
                        ? classes.cancelCell
                        : null
                    }
                  >
                    {item.poId}
                  </TableCell>
                )}
                <TableCell
                  align="left"
                  className={
                    item.isComplianceCanceled || item.isCanceled
                      ? classes.cancelCell
                      : null
                  }
                >
                  {item.program}
                </TableCell>
                <TableCell
                  align="left"
                  className={
                    item.isComplianceCanceled || item.isCanceled
                      ? classes.cancelCell
                      : null
                  }
                >
                  {item.brand}
                </TableCell>
                <TableCell
                  align="left"
                  className={
                    item.isComplianceCanceled || item.isCanceled
                      ? classes.cancelCell
                      : null
                  }
                >
                  {item.itemType}
                </TableCell>
                <TableCell
                  align="left"
                  className={
                    item.isComplianceCanceled || item.isCanceled
                      ? classes.cancelCell
                      : null
                  }
                >
                  {item.variantName ?? "---"}
                </TableCell>
                <TableCell
                  align="left"
                  className={
                    item.isComplianceCanceled || item.isCanceled
                      ? classes.cancelCell
                      : null
                  }
                >
                  {item.packSize}
                </TableCell>
                <TableCell
                  align="left"
                  className={
                    item.isComplianceCanceled || item.isCanceled
                      ? classes.cancelCell
                      : null
                  }
                >{`${formatMoney(item.estCost, false)}`}</TableCell>
                <TableCell
                  align="left"
                  className={
                    item.isComplianceCanceled || item.isCanceled
                      ? classes.cancelCell
                      : null
                  }
                >
                  {item.isComplianceCanceled || item.isCanceled ? (
                    item.isCanceled ? (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Typography style={{ fontSize: "14px" }}>
                          Canceled
                        </Typography>
                        <IconButton
                          onClick={(evt) => {
                            evt.stopPropagation();
                            handleOpen(item.canceledNote);
                          }}
                          size="large"
                        >
                          <InfoIcon />
                        </IconButton>
                      </div>
                    ) : (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Typography style={{ fontSize: "14px" }}>
                          Compliance Canceled
                        </Typography>
                        <IconButton
                          onClick={(evt) => {
                            evt.stopPropagation();
                            handleOpen(item.canceledNote);
                          }}
                          size="large"
                        >
                          <InfoIcon />
                        </IconButton>
                      </div>
                    )
                  ) : (
                    item.totalQty
                  )}
                </TableCell>
                <TableCell
                  align="left"
                  className={
                    item.isComplianceCanceled || item.isCanceled
                      ? classes.cancelCell
                      : null
                  }
                >{`${formatMoney(item.totalEstCost, false)}`}</TableCell>
                <TableCell
                  align="left"
                  className={
                    item.isComplianceCanceled || item.isCanceled
                      ? classes.cancelCell
                      : null
                  }
                >
                  {item.totalActCost === 0
                    ? "---"
                    : `${formatMoney(item.totalActCost, false)}`}
                </TableCell>
                <TableCell
                  align="left"
                  className={
                    item.isComplianceCanceled || item.isCanceled
                      ? classes.cancelCell
                      : null
                  }
                >
                  {item.isDestroyed
                    ? "---"
                    : item.estShipDate ?? item.inMarketDate}
                </TableCell>
                <TableCell
                  align="left"
                  className={
                    item.isComplianceCanceled || item.isCanceled
                      ? classes.cancelCell
                      : null
                  }
                >
                  {item.isDestroyed ? "DESTROYED" : item.actShipDate}
                </TableCell>
                <TableCell
                  align="left"
                  className={
                    item.isComplianceCanceled || item.isCanceled
                      ? classes.cancelCell
                      : null
                  }
                >
                  {item.carrier}
                </TableCell>
                <TableCell
                  align="center"
                  className={
                    item.tracking !== "---" &&
                    !item.isComplianceCanceled &&
                    !item.isCanceled
                      ? classes.clickableCell
                      : null
                  }
                  onClick={
                    item.tracking !== "---" &&
                    !item.isComplianceCanceled &&
                    !item.isCanceled
                      ? (evt) => {
                          evt.stopPropagation();
                          handleTrackingClick(item.id);
                        }
                      : null
                  }
                >
                  {item.isTransferred
                    ? "Transferred qty’s from other territories"
                    : item.tracking}
                </TableCell>
                <TableCell
                  align="left"
                  className={
                    item.isComplianceCanceled || item.isCanceled
                      ? classes.cancelCell
                      : null
                  }
                >
                  {item.complianceDisplay}
                </TableCell>
                <TableCell
                  align="left"
                  className={
                    item.isComplianceCanceled || item.isCanceled
                      ? classes.cancelCell
                      : null
                  }
                >
                  {item.poId !== "---"
                    ? item.includeBeacon
                      ? "Y"
                      : "N"
                    : "---"}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

SingleOrderDetailTable.propTypes = {
  items: PropTypes.array.isRequired,
  handleTrackingClick: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired,
  handleCancelOpen: PropTypes.func.isRequired,
  handleItemEditOpen: PropTypes.func.isRequired,
};

export default SingleOrderDetailTable;
