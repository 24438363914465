import React from "react";

import { Box } from "@mui/material";

import QuoteDetails from "./QuoteDetails";

const QuoteCompleted = ({ currentQuote, classes }) => {
  return (
    <Box
      className={classes.container}
      style={{
        color: "#4C4C4C",
      }}
    >
      <QuoteDetails classes="classes" currentQuote={currentQuote} />
      <br />
    </Box>
  );
};

export default QuoteCompleted;
