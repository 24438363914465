/** @jsxImportSource @emotion/react */
import tw, { css } from "twin.macro";

import { useState } from "react";
import { useDispatch } from "react-redux";

import {
  Cancel,
  Info,
  KeyboardArrowDown,
  KeyboardArrowUp,
  Warning,
} from "@mui/icons-material";
import {
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";

import { BeaconIcon } from "src/components/StyledComponents";
import { setModalItemId } from "src/redux/slices/items/itemSlice";
import { formatMoneyString, formatUtcDate } from "src/utility/utilityFunctions";

import { getItemPreviewImage } from "@features/items/helpers";
import { OrderSetVariant } from "@models/OrderSetVariant";
import { CldImage } from "@services/cloudinary";

import { getVariantName } from "../helpers";
import EditOrderVariantDeliveryDateModal from "./EditOrderVariantDeliveryDateModal";
import StateCodeFilter from "./StateCodeFilter";
import TotalCost from "./TotalCost";
import OrderSetAddressSection from "./addressSelection/OrderSetAddressSection";
import { useCurrentOrderSet } from "./data/CurrentOrderSetContext";
import useDeleteOrderSetVariant from "./data/mutations/useDeleteOrderSetVariant";

const subTableStyles = css({
  td: tw`first-of-type:(bg-white! left-0 sticky font-medium) first-of-type:text-left
      bg-neutral-100! text-center`,
});

const OrderSetHead = ({
  measuredRef,
  stateCodeFilter,
  setStateCodeFilter,
}: {
  measuredRef: (node: HTMLTableSectionElement) => void;
  stateCodeFilter: string | null;
  setStateCodeFilter: (stateCode: string | null) => void;
}) => {
  const dispatch = useDispatch();
  const { orderSet, orderSetVariants } = useCurrentOrderSet();
  const deleteOrderSetVariant = useDeleteOrderSetVariant();
  const [orderDetailsOpen, setOrderDetailsOpen] = useState(true);

  const [editingOrderSetVariantDelivery, setEditingOrderSetVariantDelivery] =
    useState<null | OrderSetVariant>(null);

  return (
    <>
      {editingOrderSetVariantDelivery && (
        <EditOrderVariantDeliveryDateModal
          orderSetVariant={editingOrderSetVariantDelivery}
          onClose={() => setEditingOrderSetVariantDelivery(null)}
        />
      )}
      <TableHead ref={measuredRef} tw="sticky top-0 z-30">
        <TableRow>
          <TableCell tw="sticky left-0 px-3 py-2 align-top">
            <OrderSetAddressSection />
          </TableCell>
          {orderSetVariants.map((osv, i) => (
            <TableCell tw="align-top sticky left-0" key={`head-cell-${i}`}>
              <div tw="absolute z-10 top-0 right-0 flex gap-2">
                {osv.variant.item.isCustomCoupon && (
                  <Tooltip title="If you are ordering Coupon Tear Sheets or Post It Tear Pad Coupons, please remember that there are 10 coupons per sheet or pad. Please order the number of Sheets or Pads you want.">
                    <Info fontSize="small" />
                  </Tooltip>
                )}
                {osv.isRush && orderSet.status === "submitted" && (
                  <Tooltip
                    title={`Standard Delivery: ${formatUtcDate(
                      osv.standardDeliveryDate
                    )}, Requested Delivery: ${formatUtcDate(
                      osv.inMarketDate!
                    )}, Click to change.`}
                  >
                    <IconButton
                      onClick={() => setEditingOrderSetVariantDelivery(osv)}
                    >
                      <Warning fontSize="small" />
                    </IconButton>
                  </Tooltip>
                )}
                <Tooltip title="Remove from Order">
                  <IconButton
                    disabled={deleteOrderSetVariant.isPending}
                    onClick={() => {
                      if (
                        window.confirm(
                          `You are attempting to remove Sku #${osv.variant.variantSku} from the current order, are you sure you wish to continue?`
                        )
                      ) {
                        deleteOrderSetVariant.mutate(osv.id, {
                          onError: console.error,
                        });
                      }
                    }}
                  >
                    <Cancel fontSize="small" />
                  </IconButton>
                </Tooltip>
              </div>

              <button
                tw="block w-full relative"
                onClick={() => dispatch(setModalItemId(osv.variant.item.id))}
              >
                <CldImage
                  tw="w-full object-contain object-center max-h-24"
                  publicId={getItemPreviewImage(osv.variant.item)?.cloudinaryId}
                />
                {osv.variant.item.includeBeacon && (
                  <BeaconIcon tw="absolute -right-1 -bottom-1" />
                )}
              </button>
              <Typography tw="mt-2 font-medium text-center">
                {osv.variant.item.brands.map((b) => b.name).join(", ")}
              </Typography>
            </TableCell>
          ))}
        </TableRow>
        <TableRow>
          <TableCell tw="sticky left-0 bg-white border-t border-neutral-200 border-b-0">
            <div tw="flex w-full justify-between items-center">
              <Typography>Order Details</Typography>
              <StateCodeFilter
                stateCodeFilter={stateCodeFilter}
                setStateCodeFilter={setStateCodeFilter}
              />
              <IconButton
                aria-label="expand row"
                onClick={() => {
                  setOrderDetailsOpen(!orderDetailsOpen);
                }}
                size="large"
              >
                {orderDetailsOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
              </IconButton>
            </div>
          </TableCell>
          {orderSetVariants.map((osv) => (
            <TableCell
              tw="border-t border-neutral-200 border-b-0 text-center"
              key={`sku-${osv.id}`}
            >
              <Typography variant="body2" color="textSecondary">
                {/* TODO: Do we want run number to display here? */}
                {`${osv.variant.item.type} | ${osv.variant.variantSku}`}
              </Typography>
            </TableCell>
          ))}
        </TableRow>
        <TableRow>
          <TableCell colSpan={orderSetVariants.length + 1} tw="p-0">
            <Collapse in={orderDetailsOpen} timeout="auto">
              <Table size="small" css={subTableStyles} tw="border-separate">
                <TableBody>
                  <TableRow>
                    <TableCell tw="sticky left-0 bg-white border-t border-neutral-200">
                      Item Description
                    </TableCell>
                    {orderSetVariants.map((osv, i) => (
                      <TableCell
                        key={`variant-names-${i}`}
                        tw="border-t border-neutral-200"
                      >
                        {osv.variant.selectedVariantOptions.length > 0
                          ? `${getVariantName(osv.variant)} - ${
                              osv.variant.item.comment
                            }`
                          : osv.variant.item.comment}
                      </TableCell>
                    ))}
                  </TableRow>
                  <TableRow>
                    <TableCell>Items Per Pack</TableCell>
                    {orderSetVariants.map((osv) => (
                      <TableCell key={`pack-size-${osv.id}`}>
                        {osv.variant.item.qtyPerPack}
                      </TableCell>
                    ))}
                  </TableRow>
                  <TableRow>
                    <TableCell>MOQ</TableCell>
                    {orderSetVariants.map((osv) => (
                      <TableCell key={`moq-${osv.id}`}>
                        {osv.moq ?? "---"}
                      </TableCell>
                    ))}
                  </TableRow>
                  <TableRow>
                    <TableCell>Total Qty of Items</TableCell>
                    {orderSetVariants.map((osv) => (
                      <TableCell key={osv.id}>{osv.totalQty}</TableCell>
                    ))}
                  </TableRow>
                  <TableRow>
                    <TableCell>Item Est Cost</TableCell>
                    {orderSetVariants.map((osv) => (
                      <TableCell key={`price-${osv.id}`}>
                        {`${formatMoneyString(osv.estimatedCost)}`}
                      </TableCell>
                    ))}
                  </TableRow>
                  <TableRow>
                    <TableCell>Total Est Cost</TableCell>
                    {orderSetVariants.map((osv) => (
                      <TableCell key={osv.id}>
                        <TotalCost>{osv.totalEstimatedCost}</TotalCost>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableBody>
              </Table>
            </Collapse>
          </TableCell>
        </TableRow>
      </TableHead>
    </>
  );
};

export default OrderSetHead;
