/** @jsxImportSource @emotion/react */
import tw, { css, styled } from "twin.macro";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { CalendarMonth, Sync } from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { format } from "date-fns/esm";
import { uniqBy, without } from "lodash";

import { axiosGetWithNext } from "../../../api/axiosCalls";
import {
  PLANNING_TOOL_ROLES,
  PLANNING_TOOL_SUPPLIER,
} from "../../../constants/permissions";
import { useCurrentUserRole } from "../../../hooks/permissionHooks";
import { useAnaplanPrograms } from "../../../hooks/planningToolHooks";
import useIsFirstRender from "../../../hooks/useIsFirstRender";
import { mapAnaplanProgram } from "../../../redux/slices/planningTool/maps";
import {
  cancelProgram,
  submitProgram,
  updateProgram,
} from "../../../redux/slices/planningTool/programSlice";
import { useCustomForm } from "../../../utility/inputHelpers";
import { formatDate } from "../../../utility/utilityFunctions";
import {
  CheckboxInput,
  SelectInput,
  TextInput,
} from "../../Forms/ControlledInputs";
import { StyledButton } from "../../StyledComponents";
import BrandSelector from "../../Utility/Selectors/BrandSelector";
import ProgramTypeSelector from "../../Utility/Selectors/ProgramTypeSelector";
import TerritorySelector from "../../Utility/Selectors/TerritorySelector";
import UserCanView from "../../Utility/UserCanView";
import AnaplanSyncTooltip from "./AnaplanSyncTooltip";
import NoteBox from "./NoteBox";
import ProgramCopyModal from "./ProgramCopyModal";
import ProgramEdit from "./ProgramEdit";

const FormRow = styled.div(
  css(tw`flex items-start justify-start gap-x-4`, {
    "& > *": tw`flex-1`,
  })
);
const StyledSelectInput = tw(SelectInput)`flex-grow`;

const orderActTypes = [
  { label: "Pre-Order", id: "pre-order" },
  { label: "In Stock", id: "inventory" },
  { label: "On-Demand", id: "on-demand" },
];

const channelOptions = [
  { label: "Retail", id: "retail" },
  { label: "On-Premise", id: "on_premise" },
];

const useStyles = makeStyles((theme) => ({
  ...theme.global,
}));

const ProgramDetails = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isFirstRender = useIsFirstRender();

  const currentProgram = useSelector((state) => state.program.currentProgram);
  const userRole = useCurrentUserRole();
  const userCanEdit = without(
    PLANNING_TOOL_ROLES,
    PLANNING_TOOL_SUPPLIER
  ).includes(userRole);

  const { isUpdateLoading, updateError } = useSelector(
    (state) => state.program
  );

  const itemProgramsLength = useSelector(
    (state) => Object.values(state.itemPrograms.entities).length
  );

  /* translate resources */
  const programTypes = useSelector((state) => state.programTypes.programTypes);

  const {
    id,
    channel,
    name,
    createdBy,
    brand,
    clonedFromProgram,
    externalId, // Gallo ID
    programTypeId,
    territories,
    status,
    startDate,
    endDate,
    orderCalendarMonth,
    isTerritoryExclusive,
    workfrontUrl,
    cprSubmissionUrl,
    anaplanProgramCodes,
    brandAssignmentLevel,
  } = currentProgram;

  const filterObject = {
    channel: channel,
    isActivated: false,
  };
  const { programs } = useAnaplanPrograms(filterObject);

  /* handle state from selectors */
  const [error, setError] = useState(null);
  const [primaryBrand, setPrimaryBrand] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [exclusiveTerrs, setExclusiveTerrs] = useState([]);
  const [isExclusive, setIsExclusive] = useState(false);
  const [copyProgModalOpen, setCopyProgModalOpen] = useState(false);
  const [currentAnaplanProgram, setCurrentAnaplanProgram] = useState(null);
  const [anaplanProgramToConfirm, setAnaplanProgramToConfirm] = useState(null);
  const [openConfirmAnaplan, setOpenConfirmAnaplan] = useState(false);

  const [initialLoad, setInitialLoad] = useState(false);

  const programOptions = uniqBy([currentAnaplanProgram, ...programs], "id");
  const currentProgramType =
    programTypes.length > 0
      ? `${programTypeId}-${
          programTypes.find((type) => type.id === programTypeId)?.description
        }`
      : "";

  /* handle form */
  const { control, handleSubmit } = useCustomForm({
    mode: "onBlur",
    defaultValues: currentProgram,
  });

  const controlled = (name, rules) => ({
    name,
    control,
    rules,
    onBlur: handleSubmit(handleSendUpdates),
    disabled: !userCanEdit,
  });

  const handleSendUpdates = async (data) => {
    if (isExclusive === true && exclusiveTerrs.length === 0) {
      setError("Must provide an exclusive territory");
      setTimeout(() => {
        setError(null);
      }, 3000);
    } else {
      dispatch(
        updateProgram({
          id,
          programData: data,
          brands: selectedBrands,
          territories: exclusiveTerrs,
          isExclusive,
          primaryBrand,
          ...(currentAnaplanProgram && {
            anaplanProgramId: currentAnaplanProgram.id,
          }),
        })
      );
    }
  };

  const handleSubmitProgram = (data) => {
    if (brandAssignmentLevel === "item" && itemProgramsLength === 0) {
      alert(
        "We currently require that multi-brand programs have at least one item before submitting. Please add at least one item to this program before submitting."
      );
      return;
    }
    if (isExclusive === true && exclusiveTerrs.length === 0) {
      setError("Must provide an exclusive territory");
      setTimeout(() => {
        setError(null);
      }, 3000);
    } else {
      setError(null);
      dispatch(
        submitProgram(
          id,
          data,
          selectedBrands,
          exclusiveTerrs,
          isExclusive,
          primaryBrand
        )
      );
    }
  };

  const handleCancel = () => {
    dispatch(cancelProgram(id));
  };

  const handleConfirmAnaplanCancel = () => {
    setOpenConfirmAnaplan(false);
  };

  const handleConfirmAnaplan = () => {
    setCurrentAnaplanProgram(anaplanProgramToConfirm);
    setOpenConfirmAnaplan(false);
  };

  /**
   * Call use effect to get anaplan programs for program, setstate
   * when updating, pass anaplan programs in along with the currentProgram
   */

  useEffect(() => {
    const getAnaplanProgramsForProgram = async () => {
      const queryString = `/api/anaplan-programs?filter[program_id]=${id}`;
      const response = await axiosGetWithNext(queryString);
      if (!response.error && response.data.data.length > 0) {
        setCurrentAnaplanProgram(mapAnaplanProgram(response.data.data[0]));
      }
    };

    if (id !== null && !initialLoad) {
      setSelectedBrands(brand);
      setExclusiveTerrs(territories);
      setIsExclusive(isTerritoryExclusive);
      setInitialLoad(true);
      getAnaplanProgramsForProgram();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProgram]);

  useEffect(() => {
    if (currentAnaplanProgram && anaplanProgramToConfirm) {
      if (currentAnaplanProgram.id === anaplanProgramToConfirm.id) {
        handleSubmit(handleSendUpdates)();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAnaplanProgram, isFirstRender]);

  useEffect(() => {
    if (selectedBrands) {
      setPrimaryBrand(selectedBrands[0]);
    }
  }, [selectedBrands]);

  return (
    <>
      {copyProgModalOpen && (
        <ProgramCopyModal
          classes={classes}
          open={copyProgModalOpen}
          handleClose={() => {
            setCopyProgModalOpen(false);
          }}
          program={currentProgram}
        />
      )}
      {userRole === "super" && (
        <div tw="flex gap-4 text-sm mb-2 text-neutral-500">
          <span>Gallo ID: {externalId || "N/A"} </span>
        </div>
      )}

      <Paper tw="bg-white py-4 px-6 mb-4 space-y-4">
        <div tw="flex justify-between items-center flex-wrap">
          <div tw="flex gap-x-4 items-center">
            <h2 tw="text-xl font-medium text-neutral-700">{name}</h2>
            <div tw="flex gap-1.5 items-center bg-neutral-100 rounded-full py-1.5 px-4">
              <CalendarMonth tw="text-neutral-500 text-xl" />
              <span>
                In Market{" "}
                <span tw="font-medium">
                  {orderCalendarMonth &&
                    format(
                      formatDate(orderCalendarMonth.inMarketStartDate),
                      "MMMM yyyy"
                    )}
                  {!orderCalendarMonth && startDate && endDate && (
                    <>
                      {format(formatDate(startDate), "PP")} -{" "}
                      {format(formatDate(endDate), "PP")}
                    </>
                  )}
                </span>
              </span>
            </div>

            {currentProgram.type.includes("National") && (
              <UserCanView deny={[PLANNING_TOOL_SUPPLIER]}>
                <ProgramEdit />
              </UserCanView>
            )}
          </div>

          <div tw="flex items-center gap-x-4 uppercase text-sm text-neutral-500 font-medium tracking-wider">
            {status === "draft" ? (
              <span>draft</span>
            ) : status === "canceled" ? (
              <span tw="text-red-900">canceled</span>
            ) : (
              <AnaplanSyncTooltip lastSync={Boolean(anaplanProgramCodes)}>
                <span tw="cursor-pointer">
                  <Sync tw="text-xl mr-1 " />
                  submitted
                </span>
              </AnaplanSyncTooltip>
            )}
            <UserCanView deny={[PLANNING_TOOL_SUPPLIER]}>
              <StyledButton
                cta
                onClick={handleSubmit(handleSendUpdates)}
                loading={isUpdateLoading}
              >
                Save
              </StyledButton>
            </UserCanView>
          </div>
        </div>
        {createdBy && (
          <div>
            <span>
              Created By: <span tw="font-medium">{createdBy}</span>
            </span>
          </div>
        )}
        <div tw="mt-2! flex justify-start gap-4 text-neutral-600 text-sm">
          {clonedFromProgram && (
            <span>
              Cloned from{" "}
              <Link
                tw="underline font-medium"
                target="_blank"
                to={`/planning/program/${clonedFromProgram.id}`}
              >
                Program #{clonedFromProgram.id}
              </Link>
            </span>
          )}

          <span>
            ID:
            <span tw="font-medium"> {id}</span>
          </span>
          <span>
            Anaplan Code{anaplanProgramCodes?.split(",").length > 1 ? "s" : ""}:
            {anaplanProgramCodes && (
              <span tw="font-medium"> {anaplanProgramCodes}</span>
            )}
            {!anaplanProgramCodes && <span tw="opacity-75"> pending</span>}
          </span>
        </div>
        <FormRow>
          {brandAssignmentLevel === "program" && (
            <div tw="flex-grow-[2]!">
              <BrandSelector
                handleSelectedBrands={setSelectedBrands}
                selectedBrands={selectedBrands}
                handleSelectedPrimaryBrand={setPrimaryBrand}
                disabled={!userCanEdit}
              />
            </div>
          )}
          {programTypeId !== "1" && (
            <div tw="flex-grow-[2]!">
              <Autocomplete
                loading={programs.length === 0}
                disabled={programs.length === 0}
                value={currentAnaplanProgram}
                onChange={(_e, newValue) => {
                  setAnaplanProgramToConfirm(newValue);
                  setOpenConfirmAnaplan(true);
                }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                options={programOptions}
                getOptionLabel={(p) =>
                  `${p.code} ${p.name} ${p.startDate?.slice(0, 4)}`
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    label="Anaplan Program"
                    variant="outlined"
                  />
                )}
              />
              <Dialog
                open={openConfirmAnaplan}
                onClose={handleConfirmAnaplanCancel}
              >
                <DialogTitle>Confirm Selection</DialogTitle>
                <DialogContent>
                  Are You Sure you want to assign a new anaplan program to this
                  RTA Program: <br />
                  <br />
                  New Program:{" "}
                  <b>
                    {anaplanProgramToConfirm &&
                      `${anaplanProgramToConfirm.code} ${
                        anaplanProgramToConfirm.name
                      } ${anaplanProgramToConfirm.startDate?.slice(0, 4)}`}
                  </b>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleConfirmAnaplanCancel}>Cancel</Button>
                  <Button onClick={handleConfirmAnaplan} color="secondary">
                    Confirm
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          )}
          <StyledSelectInput
            label="Activation Type"
            options={orderActTypes}
            {...controlled("orderType", { required: true })}
            disabled
          />
          <ProgramTypeSelector
            classes={classes}
            type={currentProgramType}
            disabled
          />
        </FormRow>
        <FormRow>
          <TextInput
            label="CPR ID"
            {...controlled("externalWorkfrontId")}
            disabled
          />
          <TextInput
            label="EPR ID"
            {...controlled("externalWorkfrontEprId")}
            disabled
          />
          <FormControl size="small" disabled={true}>
            <InputLabel>Trade Class</InputLabel>
            <Select
              disabled={true}
              label="Trade Class"
              options={channelOptions}
              value={channel}
            >
              {channelOptions.map((c) => (
                <MenuItem key={c.id} value={c.id}>
                  {c.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </FormRow>
        <FormRow>
          <div tw="flex-none flex gap-4">
            <CheckboxInput
              label="Is brand funded?"
              {...controlled("isBrandFunded")}
            />
            <Typography>
              <Checkbox
                checked={isExclusive}
                onChange={() => setIsExclusive(!isExclusive)}
                name="isExclusive"
                disabled={!userCanEdit}
              />
              Is exclusive?
            </Typography>
          </div>
          {isExclusive && (
            <TerritorySelector
              tw="flex-1"
              handleTerritories={setExclusiveTerrs}
              selectedTerritories={exclusiveTerrs}
              disabled={!userCanEdit}
            />
          )}
        </FormRow>
        {error && (
          <Typography className={classes.bodyText} style={{ color: "#ff0000" }}>
            {error}
          </Typography>
        )}

        <UserCanView deny={[PLANNING_TOOL_SUPPLIER]}>
          <NoteBox programId={id} />

          <div tw="flex gap-2">
            {workfrontUrl && (
              <StyledButton href={workfrontUrl} target="_blank">
                Workfront URL
              </StyledButton>
            )}
            {cprSubmissionUrl && (
              <StyledButton href={cprSubmissionUrl} target="_blank">
                CPR Submission URL
              </StyledButton>
            )}
          </div>
          <div tw="flex justify-between">
            <div>
              {status !== "canceled" && (
                <StyledButton
                  danger
                  onClick={handleCancel}
                  disabled={isUpdateLoading}
                >
                  CANCEL PROGRAM
                </StyledButton>
              )}
            </div>
            <div tw="space-x-2">
              <StyledButton
                outlined
                onClick={() => {
                  setCopyProgModalOpen(true);
                }}
              >
                COPY PROGRAM
              </StyledButton>
              {status === "draft" && (
                <StyledButton
                  cta
                  onClick={handleSubmit(handleSubmitProgram)}
                  disabled={isUpdateLoading}
                >
                  Submit
                </StyledButton>
              )}
            </div>
            {!isUpdateLoading && updateError && (
              <Typography
                className={classes.bodyText}
                style={{ color: "#ff0000" }}
              >
                {updateError}
              </Typography>
            )}
          </div>
        </UserCanView>
      </Paper>
    </>
  );
};

export default React.memo(ProgramDetails);
