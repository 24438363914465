/** @jsxImportSource @emotion/react */
import "twin.macro";

import { Suspense } from "react";
import { useImage } from "react-image";

import { CircularProgress } from "@mui/material";

import { cloudinary } from "./cloudinary";

const LazyImage = ({ src, ...props }) => {
  const { src: renderSrc } = useImage({
    srcList: [src, "/images/NotFound.png"],
  });
  return <img src={renderSrc} alt="" {...props} />;
};

const CldImage = ({
  src: providedSrc,
  publicId,
  imageType = "jpg",
  ...props
}: {
  src?: string;
  publicId?: string;
  imageType?: string;
} & React.HTMLProps<HTMLImageElement>) => {
  const src = publicId
    ? cloudinary.url(`${publicId}.${imageType}`)
    : providedSrc;

  return (
    <Suspense
      fallback={
        <div
          tw="flex items-center justify-center min-w-[2em] min-h-[2em]"
          className={props.className}
        >
          <CircularProgress tw="text-neutral-300" size={16} />
        </div>
      }
    >
      <LazyImage src={src} {...props} />
    </Suspense>
  );
};

export default CldImage;
