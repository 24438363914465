import { createSlice } from "@reduxjs/toolkit";

import { axiosGet } from "../../../api/axiosCalls";
import { setError } from "../errorSlice";

let initialState = {
  isLoading: false,
  programTypes: [],
  error: null,
};

const loadingFailed = (state, action) => {
  const { error } = action.payload;
  state.isLoading = false;
  state.error = error;
};

const programTypeSlice = createSlice({
  name: "programTypes",
  initialState,
  reducers: {
    setIsLoading(state) {
      state.isLoading = true;
    },
    getProgramTypesSuccess(state, action) {
      const { types } = action.payload;
      state.programTypes = types.map((type) => ({
        ...type,
        name: type.description,
      }));
      state.isLoading = false;
      state.error = null;
    },
    clearProgramTypes(state) {
      state.isLoading = false;
      state.programTypes = [];
      state.error = null;
    },
    setFailure: loadingFailed,
  },
});

export const {
  setIsLoading,
  getProgramTypesSuccess,
  clearProgramTypes,
  setFailure,
} = programTypeSlice.actions;

export default programTypeSlice.reducer;

export const getProgramTypes = () => async (dispatch) => {
  try {
    dispatch(setIsLoading());
    const response = await axiosGet("/api/program-types");
    if (response.error) {
      throw response.error;
    }
    dispatch(getProgramTypesSuccess({ types: response.data }));
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Program Types" }));
  }
};
