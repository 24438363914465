import React, { memo, useState } from "react";

import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import PropTypes from "prop-types";

import ImageWrapper from "../Utility/ImageWrapper";
import C2MItemPreviewModal from "./C2MItemPreviewModal";

const headCells = [
  { label: "Preview", id: "preview" },
  { label: "Sequence Number", id: "itemNumber" },
  { label: "Brand(s)", id: "brands" },
  { label: "Item Type", id: "itemType" },
  { label: "Program Theme", id: "programTheme" },
  { label: "Product Name", id: "productName" },
];

const useStyles = makeStyles((theme) => ({
  ...theme.global,
}));

const C2MItemHistoryTable = ({ itemHistory, isLoading, scrollRef }) => {
  const classes = useStyles();

  const [previewModal, setPreviewModal] = useState({
    item: null,
    open: false,
  });

  const handlePreview = (item) => {
    setPreviewModal({
      item: item,
      open: true,
    });
  };

  const handlePreviewClose = () => setPreviewModal({ item: null, open: false });

  return (
    <>
      {previewModal.open && (
        <C2MItemPreviewModal
          {...previewModal}
          handleClose={handlePreviewClose}
        />
      )}
      <TableContainer
        className={classes.tableContainer}
        style={{ maxHeight: "Calc(100vh - 250px)" }}
        ref={scrollRef}
      >
        <Table className={classes.table} aria-label="" stickyHeader>
          <TableHead>
            <TableRow>
              {headCells.map((cell) => (
                <TableCell
                  className={classes.headerText}
                  key={cell.id}
                  align="left"
                >
                  {cell.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {!isLoading && itemHistory.length === 0 && (
              <TableRow>
                <TableCell
                  align="left"
                  colSpan={headCells.length}
                  className={classes.headerText}
                >
                  There are no historical items that match your current search
                  criteria...
                </TableCell>
              </TableRow>
            )}
            {!isLoading &&
              itemHistory.length > 0 &&
              itemHistory.map((row, i) => (
                <TableRow key={i}>
                  <TableCell align="left">
                    <ImageWrapper
                      id={row.id}
                      imgClass={classes.previewImageFloat}
                      alt={row.productName}
                      imgUrl={row.images.imgUrlThumb}
                      handleClick={() => handlePreview(row)}
                    />
                  </TableCell>
                  {headCells
                    .filter((cell) => cell.id !== "preview")
                    .map((cell, index) => (
                      <TableCell key={`cell-${index}`} align="left">
                        {row[cell.id]}
                      </TableCell>
                    ))}
                </TableRow>
              ))}
            {isLoading && (
              <TableRow>
                <TableCell align="left" colSpan={headCells.length}>
                  <CircularProgress />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

C2MItemHistoryTable.propTypes = {
  itemHistory: PropTypes.array,
  isLoading: PropTypes.bool.isRequired,
  scrollRef: PropTypes.any,
};

export default memo(C2MItemHistoryTable);
