import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

//simpole authenticatino route that redirects if the current role isn't in the routes allowed roles.
const AuthRoute = ({ allowedRoles }) => {
  const { role } = useSelector((state) => state.user);

  return allowedRoles.includes(role) ? <Outlet /> : <Navigate to="/whoops" />;
};

export default AuthRoute;
