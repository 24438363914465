import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";

import ReduxReportGenerator from "src/components/Reporting/ReduxReportGenerator";

import { PurchasingSelector } from "../components/Reporting/ReportSelectors";
import ProgramAutoComplete from "../components/Utility/AutoCompleteFields/ProgramAutoComplete";
import OrderWindowSelector from "../components/Utility/Selectors/OrderWindowSelector";
import { updateSingleFilter } from "../redux/slices/filterSlice";
import { getReportById } from "../redux/slices/reports/reportSlice";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
  ...theme.reports,
}));

const ReportOrderWindowSummary = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [orderWindow, setOrderWindow] = useState(null);
  const [currentPurchaser, setCurrentPurchaser] = useState("all");
  const [currentPrograms, setCurrentPrograms] = useState([]);
  const [reset, setReset] = useState(false);

  const currentChannel = useSelector((state) => state.user.currentChannel);
  const currentProgramFilter = useSelector((state) => state.filters.program);

  const handleProgram = (value, _type, _filter) => {
    setCurrentPrograms(value);
    dispatch(updateSingleFilter({ filter: "program", value: value }));
  };

  const handleProgramChipClick = (id) => {
    let currentProgArray = currentProgramFilter.filter(
      (prog) => prog.id !== id
    );
    setCurrentPrograms(currentProgArray);
    dispatch(
      updateSingleFilter({ filter: "program", value: currentProgArray })
    );
  };

  const handleReset = () => {
    setCurrentPurchaser("all");
    setCurrentPrograms([]);
    setOrderWindow(null);
    setReset(true);
  };

  const handleGetReport = () => {
    const filterObject = {
      isPurchaserSelect: currentPurchaser,
      orderStatus: "approved",
      qtyNotZero: true,
      program: currentPrograms,
      ocmIds: orderWindow && [orderWindow],
      channel: currentChannel,
    };
    dispatch(getReportById("rfp-summary", filterObject));
  };

  return (
    <>
      <ReduxReportGenerator
        reportTitle="Request for Price Summary Report"
        fileName="rfp_summary.csv"
        reportResetFunc={handleReset}
        reportGetFunc={handleGetReport}
        reportType={"rfp-summary"}
      >
        <>
          <div className={classes.searchComponents}>
            <PurchasingSelector
              currentPurchaser={currentPurchaser}
              setCurrentPurchaser={setCurrentPurchaser}
              classes={classes}
            />
            <OrderWindowSelector
              orderWindow={orderWindow}
              setOrderWindow={setOrderWindow}
              classes={classes}
            />
          </div>
          <br />
          <div className={classes.searchComponents}>
            <ProgramAutoComplete
              classes={classes}
              handleChange={handleProgram}
              reset={reset}
              setReset={setReset}
              filterType="report"
              id="report-program-complete"
            />
          </div>
          <br />
          <div style={{ width: "100%", textAlign: "center" }}>
            <Typography
              className={classes.headerText}
              style={{ marginRight: "10px" }}
            >
              Program Filters:
            </Typography>
          </div>
          <div className={classes.chipRow}>
            {currentPrograms.length > 0 &&
              currentPrograms.map((user) => (
                <Chip
                  style={{ margin: "auto 2.5px" }}
                  color="primary"
                  key={user.id}
                  label={user.name}
                  onDelete={() => handleProgramChipClick(user.id)}
                />
              ))}
          </div>
        </>
      </ReduxReportGenerator>
    </>
  );
};

ReportOrderWindowSummary.propTypes = {};

export default ReportOrderWindowSummary;
