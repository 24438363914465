import { useDispatch } from "react-redux";

import { useMutation, useQueryClient } from "@tanstack/react-query";
import _ from "lodash";
import { setError } from "src/redux/slices/errorSlice";

import { Order } from "@models/Order";
import client from "@services/api";
import asyncPool from "@utils/asyncPool";

import { orderSetsKeyFactory } from "../../../queries/orderSetQueries";
import { useCurrentOrderSet } from "../CurrentOrderSetContext";

export default function useAddOrders() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const {
    orderSetId,
    orderSet: { type },
  } = useCurrentOrderSet();

  return useMutation({
    mutationFn: (addressIds: string[]) => {
      return asyncPool(5, addressIds, (addressId) =>
        client
          .post<Order>("orders", {
            __type: "order",
            type: type,
            address: { type: "address", id: addressId },
            orderSet: { type: "order-set", id: orderSetId },
            relationshipNames: ["address", "orderSet"],
          })
          .then((res) => res.data)
      )
        .then((res) => {
          if (res.errors) throw res.errors[0];
          return res.results;
        })
        .then((res) => ({ newOrders: res }));
    },
    onSuccess: ({ newOrders }) => {
      queryClient.setQueryData(
        orderSetsKeyFactory.detail(orderSetId)._ctx.orders.queryKey,
        (orders: Order[]) => [...orders, ...newOrders]
      );
    },
    onError: (error) => {
      dispatch(
        setError({
          error: error.message,
          source: "useAddOrders",
        })
      );
      queryClient.invalidateQueries({
        queryKey: orderSetsKeyFactory.detail(orderSetId)._ctx.orders.queryKey,
      });
    },
  });
}
