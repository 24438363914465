import axios from "axios";

import { handleErrors } from "../../../api/apiFunctions";

export const fetchInitialSupplierValues = async () => {
  const response = { status: "", error: null, data: null };
  const valueObj = {};
  const errors = [];
  const endpointData = {
    newRFQ: "/api/quotes?filter[status]=sent",
    inProgressRFQ: "/api/quotes?filter[status]=accepted",
    completedRFQ: "/api/quotes?filter[status]=completed",
    newRFP: "/api/request-for-prices?filter[bid-status]=sent",
    inProgressRFP: "/api/request-for-prices?filter[bid-status]=accepted",
    awardedRFP:
      "/api/request-for-quotes?filter[bid-status]=awarded&filter[status]=awarded",
    newPO: "/api/purchase-order-variants?filter[status]=submitted",
    inProgressPO:
      "/api/purchase-order-variants?filter[status]=in-progress&filter[has-ship-hold]=false",
    shipHoldPO:
      "/api/purchase-order-variants?filter[status]=in-progress&filter[has-ship-hold]=true",
  };

  const endpointPromises = Object.entries(endpointData).map(
    async ([key, url]) => {
      try {
        const res = await axios.get(`${url}&page[size]=1`);
        valueObj[key] = res.data.meta.total_entries;
      } catch (err) {
        const handledError = handleErrors(err);
        console.log(handledError);
        errors.push(handledError);
      }
    }
  );

  await Promise.all(endpointPromises);

  if (errors.length === 0) {
    response.status = "Ok";
    response.error = null;
    response.data = valueObj;
  } else {
    response.status = "error";
    response.error = errors.join(", ");
  }

  return response;
};

export const buildSupplier = ({
  externalId,
  name,
  isPurchaserSelect,
  goesToRFP,
  isActive,
  sapId,
}) => {
  return {
    data: {
      type: "supplier",
      attributes: {
        "external-code": externalId,
        name,
        "is-purchaser-select": isPurchaserSelect,
        "goes-to-rfp": goesToRFP,
        "is-active": isActive,
        "sap-id": sapId,
      },
    },
  };
};

export const buildSupplierUserUpdate = (
  userId,
  supplierId,
  states,
  territories
) => {
  const stateIds = states.map((state) => ({
    type: "state",
    id: state.id,
  }));
  const territoryIds = territories.map((terr) => ({
    type: "territory",
    id: terr.id,
  }));
  return {
    data: {
      type: "user",
      attributes: {
        "supplier-id": supplierId ? supplierId : null,
      },
      relationships: {
        states: {
          data: stateIds,
        },
        territories: {
          data: territoryIds,
        },
      },
    },
  };
};
