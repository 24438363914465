import { createQueryKeys } from "@lukemorales/query-key-factory";
import { useQuery } from "@tanstack/react-query";

import { Territory } from "@models/Territory";
import client from "@services/api";

export const territoriesKeyFactory = createQueryKeys("territories", {
  list: (params) => ({
    queryKey: [params],
    queryFn: () =>
      client
        .get<Territory[]>("territories", { params })
        .then((res) => res.data),
  }),
});

export const useTerritories = (filter: Record<string, any>) => {
  return useQuery({
    ...territoriesKeyFactory.list({ filter }),
    staleTime: Infinity,
  });
};
