import React from "react";
import { useSelector } from "react-redux";

import { CSVLink } from "@utils/csv";

import { StyledButton } from "../StyledComponents";

const csvHeaders = [
  { key: "itemNumber", label: "Item Number" },
  { key: "brandNames", label: "Brand" },
  { key: "itemType", label: "Item Type" },
  { key: "ruleType", label: "Rule Type" },
  { key: "ruleDescription", label: "Rule Description" },
  { key: "stateCode", label: "State" },
  { key: "insertedAt", label: "Date Added" },
  { key: "userName", label: "Overriden By" },
  { key: "note", label: "Notes" },
];

const OverrideListExportButton = () => {
  const { overrides, isLoading } = useSelector(
    (state) => state.complianceRules
  );

  return overrides.length === 0 ? null : (
    <CSVLink
      data={overrides}
      headers={csvHeaders}
      filename={"override-list.csv"}
      target="_blank"
    >
      <StyledButton outlined loading={isLoading}>
        Export
      </StyledButton>
    </CSVLink>
  );
};

export default OverrideListExportButton;
