/** @jsxImportSource @emotion/react */
import "twin.macro";

import { format } from "date-fns";

import { formatDate } from "../../utility/utilityFunctions";

const mapInMarket = (ocm) => ({
  ...ocm,
  label: format(formatDate(ocm.inMarketStartDate), "MMMM yyyy"),
});
const formatInMarketMonthOptions = (ocms) => {
  const groupByType = ocms.reduce(
    (grp, ocm) => {
      grp[ocm.orderWindowType].push(mapInMarket(ocm));
      return grp;
    },
    { national: [], secondary: [] }
  );

  return [
    ...groupByType.national,
    ...(groupByType.secondary.length > 0
      ? [{ label: "Secondary Order Windows", header: true }]
      : []),
    ...groupByType.secondary.map((ocm) => ({
      id: ocm.id,
      label: (
        <div tw="flex gap-3 justify-between w-full">
          <div>{ocm.label}</div>
          <div tw="text-neutral-500">{ocm.orderWindowName}</div>
        </div>
      ),
    })),
  ];
};
export default formatInMarketMonthOptions;
