import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";

import PropTypes from "prop-types";

import {
  clearAddressList,
  fetchFavAddresses,
  fetchInitialAddresses,
} from "../../../redux/slices/addresses/addressSlice";
import {
  setSorted,
  updateSingleFilter,
} from "../../../redux/slices/filterSlice";
import {
  clearOrderByType,
  fetchCurrentOrderByType,
} from "../../../redux/slices/ordering/currentOrderSlice";
import { fetchStatesByIds } from "../../../redux/slices/territories/territorySlice";
import { updateCurrentTerritory } from "../../../redux/slices/users/userSlice";

const RegionSelector = ({ classes }) => {
  const dispatch = useDispatch();

  const [region, updateRegion] = useState("");

  const regions = useSelector((state) => state.user.territories);
  const currentRegion = useSelector((state) => state.user.currentTerritory);
  const currentChannel = useSelector((state) => state.user.currentChannel);
  const currentUserId = useSelector((state) => state.user.id);
  const isOrdering = useSelector((state) => state.orderSet.isOrdering);
  const isStepperLoad = useSelector(
    (state) => state.globalLoad.isProgStepperLoad
  );

  const handleChangeSelect = (evt) => {
    dispatch(clearOrderByType({ type: "inventory" }));
    dispatch(clearOrderByType({ type: "onDemand" }));
    let currentTerritory = regions.find((reg) => reg.name === evt.target.value);
    if (window.location.hash === "#addressBook") {
      dispatch(fetchInitialAddresses(currentTerritory.id));
    }
    if (window.location.hash !== "#addressBook") {
      window.location.hash = "";
    }
    updateRegion(evt.target.value);
    dispatch(clearAddressList());
    dispatch(updateCurrentTerritory({ territory: currentTerritory.id }));

    dispatch(fetchStatesByIds([currentTerritory.id]));
    dispatch(
      updateSingleFilter({
        filter: "currentTerritoryId",
        value: currentTerritory.id,
      })
    );
    dispatch(fetchFavAddresses(currentTerritory.id));
    dispatch(
      fetchCurrentOrderByType(
        "inventory",
        currentUserId,
        currentChannel,
        currentTerritory.id
      )
    );
    dispatch(
      fetchCurrentOrderByType(
        "onDemand",
        currentUserId,
        currentChannel,
        currentTerritory.id
      )
    );
    dispatch(setSorted());
  };

  useEffect(() => {
    const nextRegion = regions.find((reg) => reg.id === currentRegion);
    if (nextRegion) {
      updateRegion(nextRegion.name);
    }
  }, [regions, currentRegion]);

  if (regions.length === 1) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "103.5px",
          height: "41px",
          margin: "0 5px",
          backgroundColor: "white",
          borderRadius: "5px",
        }}
      >
        <Typography className={classes.regionText} variant="body2">
          {regions[0].name}
        </Typography>
      </div>
    );
  } else
    return (
      <>
        <FormControl
          variant="outlined"
          size="small"
          style={{
            margin: "0 5px",
            borderRadius: "5px",
            backgroundColor: "white",
          }}
        >
          <Select
            name="regions"
            labelId="region-select"
            id="regions"
            value={region}
            onChange={handleChangeSelect}
            MenuProps={{
              style: { zIndex: "10001" },
            }}
            disabled={
              (isOrdering && window.location.href.includes("/orders/open")) ||
              isStepperLoad
            }
          >
            {regions.map((region, index) => (
              <MenuItem value={region.name} key={index}>
                <Typography variant="body2">{region.name}</Typography>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </>
    );
};

RegionSelector.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default React.memo(RegionSelector);
