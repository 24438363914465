/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useEffect, useRef, useState } from "react";
import Helmet from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";

import GetAppIcon from "@mui/icons-material/GetApp";
import PrintIcon from "@mui/icons-material/Print";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";

import { keepPreviousData } from "@tanstack/react-query";
import "date-fns";
import HideFiltersButtonToggle from "src/components/Filtering/HideFiltersButtonToggle";
import { SelectField } from "src/components/Forms/DefaultInputs";
import { FixedHeightScrollLastChild } from "src/components/StyledComponents";
import useActiveOrderWindows from "src/hooks/useActiveOrderWindows";

import OverBudgetByBrandTable from "@features/budgets/OverBudgetByBrandTable";
import { useFilterParams } from "@features/filters";
import {
  useOrderSetVariantSummariesPaginated,
  useOrderSetsPaginated,
} from "@features/orders";
import ApprovalsOrderSetTable from "@features/orders/approvals/ApprovalsOrderSetTable";
import ApprovalsOrderSetVariantSummariesTable from "@features/orders/approvals/ApprovalsOrderSetVariantSummariesTable";
import { CSVLink } from "@utils/csv";

import FilterChipList from "../components/Filtering/FilterChipList";
import {
  orderApprovalItem,
  orderApprovalOrder,
} from "../components/OrderManagement/inViewReporting";
import { useChannelUpdate, useInitialFilters } from "../hooks/UtilityHooks";
import { setSorted, updateSingleFilter } from "../redux/slices/filterSlice";
import {
  fetchOrderSetReport,
  fetchOrderSetVariantReport,
} from "../redux/slices/ordering/orderSetHistorySlice";

/*
Not to be confused with the purchase order or rfp rollups, this view handles reviewing pre orders
for admin users. It is called Rollup because Gallo calls the view a pre order rollup (a rolled up
version of all the pre orders)
*/

const defaultFilters: Record<string, any> = {
  user: [],
  program: [],
  brand: [],
  itemType: [],
  territory: [],
  itemNumber: "",
  orderNum: "",
  groupBy: "item",
  status: "submitted",
  sortOrder: "asc",
  sortOrderBy: "orderDate",
  ocmIds: null,
  preventFetch: true,
};

const getIds = (ent: any) => ent.map((e) => e.id).join(",") || undefined;

const useStyles = makeStyles((theme: any) => ({
  ...theme.global,
}));

const Rollup = () => {
  const classes: any = useStyles();
  const dispatch = useDispatch();

  const printTableRef = useRef(null);

  const { role, currentChannel, currentTerritory } = useSelector(
    (state: any) => state.user
  );
  const currentChannelFilter = useSelector(
    (state: any) => state.filters.channel
  );
  const currentGrouping = useSelector((state: any) => state.filters.groupBy);

  const filterObject = useSelector((state: any) => state.filters);

  const showBudgets = filterObject.preventFetch;

  const activeOrderWindows = useActiveOrderWindows("purchasingCloseDate");
  const [orderWindowId, setOrderWindowId] = useState(
    activeOrderWindows[0]?.id ?? ""
  );

  const [{ sort }] = useFilterParams();

  const sharedFilters = {
    channel: currentChannel,
    territoryIds: currentTerritory,
    orderCalendarMonthIds: orderWindowId,
    budgetId: getIds(filterObject.budgets),

    status:
      filterObject.status === "all-orders"
        ? "in-progress,submitted"
        : filterObject.status,

    userIds: getIds(filterObject.user),
    brandIds: getIds(filterObject.brand),
    programIds: getIds(filterObject.program),
    itemTypeIds: getIds(filterObject.itemType),
    stateIds: getIds(filterObject.stateIds),
    ...(filterObject.territory?.length > 0 && {
      // override territoryIds if territory is selected
      territoryIds: getIds(filterObject.territory),
    }),
    supplierIds: getIds(filterObject.supplier),
  };

  // Order Set Variant Summaries
  const { data: osvs = [], ...osvsTableProps } =
    useOrderSetVariantSummariesPaginated(
      {
        filter: {
          ...sharedFilters,
          orderSetId: filterObject.orderNum || null,
          orderSetStatus: sharedFilters.status,
        },
        sort: sort,
      },
      {
        enabled:
          !filterObject.preventFetch &&
          !!orderWindowId &&
          currentGrouping === "item",
        placeholderData: keepPreviousData,
        staleTime: 5 * 60 * 1000,
      }
    );

  // Order Sets
  const { data: orderSets = [], ...orderSetsTableProps } =
    useOrderSetsPaginated(
      {
        filter: {
          ...sharedFilters,
          id: filterObject.orderNum || null,
        },
        sort: sort,
      },
      {
        enabled:
          !filterObject.preventFetch &&
          !!orderWindowId &&
          currentGrouping === "order",
        placeholderData: keepPreviousData,
        staleTime: 5 * 60 * 1000,
      }
    );

  const setToShowBudgets = () => {
    dispatch(updateSingleFilter({ filter: "preventFetch", value: true }));
    dispatch(setSorted());
  };

  const {
    triggerCSVDownload: triggerCSV,
    orderSetReport,
    orderSetItemReport,
  } = useSelector((state: any) => state.orderSetHistory);

  const handlePrintTable = useReactToPrint({
    content: () => printTableRef.current,
  });

  const handleOrderSetReport = () => {
    dispatch(fetchOrderSetReport(filterObject));
  };

  const handleOrderSetVariantReport = () => {
    dispatch(fetchOrderSetVariantReport(filterObject));
  };

  useChannelUpdate(currentChannel, currentChannelFilter, dispatch);

  useInitialFilters("history-rollup", defaultFilters);

  const selectedReport =
    filterObject.groupBy === "order" ? orderApprovalOrder : orderApprovalItem;
  const selectedReportData = selectedReport.mapFunction(
    filterObject.groupBy === "order" ? orderSetReport : orderSetItemReport
  );

  const handleSetOrderWindowId = (id) => {
    setOrderWindowId(id);
    dispatch(
      updateSingleFilter({
        filter: "ocmIds",
        value: id,
      })
    );
  };

  useEffect(() => {
    if (activeOrderWindows.length > 0) {
      handleSetOrderWindowId(activeOrderWindows[0].id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeOrderWindows]);

  return (
    <>
      <Helmet>
        <title>RTA | Order Window Rollup</title>
        {role === "field2" && (
          <script type="text/javascript">{` Beacon('suggest', ['600af2ff1c64ad47e4b7201d'])`}</script>
        )}
      </Helmet>
      <Container className={classes.mainWrapper}>
        <FixedHeightScrollLastChild tw="[--space-after-header: 42px]">
          <div className={classes.titleBar} tw="flex-wrap">
            <Typography className={classes.titleText}>
              Order Window Rollup
            </Typography>
            <div tw="flex items-center gap-4">
              <Tooltip title="Print Order History">
                <IconButton onClick={() => handlePrintTable()} size="large">
                  <PrintIcon color="secondary" />
                </IconButton>
              </Tooltip>
              {!showBudgets && (
                <Button
                  className={classes.largeButton}
                  variant="contained"
                  onClick={setToShowBudgets}
                >
                  Back to Overview
                </Button>
              )}
              {!triggerCSV && (
                <Button
                  className={classes.largeButton}
                  variant="contained"
                  onClick={() => {
                    if (currentGrouping === "order") handleOrderSetReport();
                    else handleOrderSetVariantReport();
                  }}
                >
                  GENERATE REPORT
                </Button>
              )}
              {triggerCSV && (
                <CSVLink
                  data={selectedReportData}
                  headers={selectedReport.headers}
                  filename="rta_rollup.csv"
                >
                  <Button
                    className={classes.largeButton}
                    variant="contained"
                    color="secondary"
                    startIcon={<GetAppIcon />}
                  >
                    EXPORT REPORT
                  </Button>
                </CSVLink>
              )}
            </div>
          </div>
          <div tw="flex items-center mt-2 gap-4">
            <SelectField
              label="Order Window"
              disabled={activeOrderWindows.length <= 1}
              value={orderWindowId}
              onChange={(e) => handleSetOrderWindowId(e.target.value)}
              options={activeOrderWindows.map((ow) => ({
                name: ow.orderWindowName,
                id: ow.id,
              }))}
            />
            <HideFiltersButtonToggle />
            <FilterChipList />
          </div>
          {showBudgets ? (
            <OverBudgetByBrandTable ocmId={orderWindowId} />
          ) : (
            <>
              {currentGrouping === "order" && (
                <ApprovalsOrderSetTable
                  data={orderSets}
                  {...orderSetsTableProps}
                  tableRef={printTableRef}
                  showAnaplanPrograms
                />
              )}
              {currentGrouping === "item" && (
                <ApprovalsOrderSetVariantSummariesTable
                  data={osvs}
                  {...osvsTableProps}
                  tableRef={printTableRef}
                  showAnaplanPrograms
                />
              )}
            </>
          )}
        </FixedHeightScrollLastChild>
      </Container>
    </>
  );
};

export default Rollup;
