import React, { Suspense, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

import CancelIcon from "@mui/icons-material/Cancel";
import MuiAlert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Fade from "@mui/material/Fade";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import Snackbar from "@mui/material/Snackbar";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";

import PropTypes from "prop-types";

import { groupByThree } from "../../../utility/utilityFunctions";
import Loading from "../Loading";

const ItemPDFLoader = React.lazy(() => import("../../PDFs/PDFLoader"));

const useStyles = makeStyles((theme) => ({
  ...theme.global,
  shareItemModal: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-around",
    width: "100%",
    height: "200px",
    textAlign: "center",
  },
  alertColor: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.secondary.dark,
  },
}));

const FadeTransition = (props) => (
  <Fade {...props} timeout={{ enter: 250, exit: 1000 }} />
);

const Alert = React.forwardRef((props, ref) => (
  <MuiAlert ref={ref} elevation={6} variant="filled" {...props} />
));

const ItemShareModal = ({ modalOpen, handleClose, shareLink }) => {
  const classes = useStyles();
  const copyRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [groupedItems, setGroupedItems] = useState(null);

  const isLoading = useSelector((state) => state.sharedItems.isLoading);
  const items = useSelector((state) => state.sharedItems.items);

  const handleCopy = (evt) => {
    setOpen(true);
    copyRef.current.firstChild.select();
    document.execCommand("copy");
    evt.target.focus();
  };

  useEffect(() => {
    let timeOut;
    if (open) {
      timeOut = setTimeout(() => {
        setOpen(false);
      }, 1000);
    }
    return () => {
      clearTimeout(timeOut);
    };
  }, [setOpen, open]);

  useEffect(() => {
    if (!groupedItems && !isLoading && items.length > 0) {
      let currentItems = groupByThree(items);
      setGroupedItems(currentItems);
    }
  }, [groupedItems, items, isLoading, setGroupedItems]);

  return (
    <div className={classes.relativeContainer}>
      <Dialog
        open={modalOpen}
        disableScrollLock
        onClose={() => {
          handleClose();
          setGroupedItems(null);
        }}
        fullWidth
        maxWidth="sm"
        style={{ zIndex: "15000" }}
      >
        <DialogContent>
          <IconButton
            className={classes.closeButton}
            onClick={() => {
              handleClose();
              setGroupedItems(null);
            }}
            size="large"
          >
            <CancelIcon fontSize="large" color="secondary" />
          </IconButton>
          <br />
          <div className={classes.shareItemModal}>
            <Typography className={classes.headerText}>Share Link:</Typography>
            <InputBase
              className={classes.bodyText}
              id="share-link"
              value={shareLink}
              inputProps={{
                "aria-label": "naked",
                style: { textAlign: "center" },
              }}
              style={{
                readonly: "readonly",
              }}
              fullWidth
              ref={copyRef}
            />
            <div style={{ display: "flex", alignItems: "center" }}>
              <Button
                className={classes.largeButton}
                variant="contained"
                color="secondary"
                onClick={handleCopy}
              >
                COPY LINK
              </Button>
              {!isLoading && groupedItems && (
                <Suspense fallback={<Loading partial={false} />}>
                  <ItemPDFLoader
                    items={groupedItems}
                    fileName="rta-items.pdf"
                    styles={{ marginLeft: "20px" }}
                  />
                </Suspense>
              )}
            </div>
          </div>
        </DialogContent>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        open={open}
        TransitionComponent={FadeTransition}
        style={{ zIndex: "16000" }}
      >
        <Alert severity="info" classes={{ filledInfo: classes.alertColor }}>
          Copied to Clipboard!
        </Alert>
      </Snackbar>
    </div>
  );
};

ItemShareModal.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  shareLink: PropTypes.string,
};

export default ItemShareModal;
