/** @jsxImportSource @emotion/react */
import "twin.macro";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ButtonGroup } from "@mui/material";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import PropTypes from "prop-types";
import { resetItems } from "src/redux/slices/items/itemSlice";

import { useAlert } from "@features/confirm/useAlert";

import { axiosGet } from "../../api/axiosCalls";
import { setClear } from "../../redux/slices/filterSlice";
import { mapPrograms } from "../../redux/slices/programs/maps";
import BUAutoComplete from "../Utility/AutoCompleteFields/BUAutoComplete";
import BrandAutoComplete from "../Utility/AutoCompleteFields/BrandAutoComplete";
import ItemTypeAutoComplete from "../Utility/AutoCompleteFields/ItemTypeAutoComplete";
import ProgramAutoComplete from "../Utility/AutoCompleteFields/ProgramAutoComplete";
import TerritoryAutoComplete from "../Utility/AutoCompleteFields/TerritoryAutoComplete";
import IsAccoladeSelector from "../Utility/Selectors/IsAccoladeSelector";
import { executeOnEnter } from "./utility";

const FiltersOnDemandItems = ({
  reset,
  setReset,
  handleFilters,
  classes,
  itemNumber,
  bindSequenceNum,
  itemDesc,
  bindItemDesc,
  handleSearch,
}) => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const [isAccolade, setIsAccolade] = useState(null);

  const [orderBy, setOrderBy] = useState("program");

  const favoriteItems = useSelector((state) => state.user.favoriteItems);
  const isLoading = useSelector((state) => state.globalLoad.isLoading);
  const currentUserRole = useSelector((state) => state.user.role);
  const { onDemandProgram } = useSelector((state) => state.currentOrder);
  const { program } = useSelector((state) => state.filters);
  const [anaplanOrRtaProgramId, setAnaplanOrRtaProgramId] = useState("");

  const handleProgramSearch = async (e) => {
    e.stopPropagation();
    const res = await axiosGet(
      `/api/programs?filter[program-id-or-anaplan-code]=${anaplanOrRtaProgramId.toUpperCase()}`
    );
    if (res.data.length === 0)
      await alert(
        `No Programs match this ID: ${anaplanOrRtaProgramId.toUpperCase()}`
      );
    else {
      handleFilters(mapPrograms(res.data), "program", "item-all");
      setAnaplanOrRtaProgramId("");
    }
  };

  useEffect(() => {
    if (onDemandProgram && program?.[0]?.id !== onDemandProgram.id) {
      handleFilters([onDemandProgram], "program", "item-all");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onDemandProgram?.id]);

  return (
    <>
      <List onKeyPress={executeOnEnter(handleSearch)}>
        <h2 tw="font-medium text-neutral-700">Order By:</h2>
        <ListItem>
          <ButtonGroup fullWidth color="secondary">
            <Button
              className={
                orderBy === "program"
                  ? classes.largeButton
                  : classes.selectedButton
              }
              variant={orderBy === "program" ? "contained" : "outlined"}
              onClick={() => {
                setOrderBy("program");
                dispatch(setClear());
                dispatch(resetItems());
              }}
              disabled={isLoading}
            >
              PROGRAM
            </Button>
            <Button
              className={
                orderBy === "misc"
                  ? classes.largeButton
                  : classes.selectedButton
              }
              variant={orderBy === "misc" ? "contained" : "outlined"}
              onClick={() => {
                setOrderBy("misc");
                // handleFilters("misc", "groupBy", "history");
              }}
              disabled={isLoading}
            >
              MISC
            </Button>
          </ButtonGroup>
        </ListItem>
        <ListItem>
          <p tw="text-neutral-700 -mt-2">
            {orderBy === "program"
              ? "Order items within a single program"
              : "Order items across multiple programs"}
          </p>
        </ListItem>
        <Divider />
        <ListItem />
        {orderBy === "program" && (
          <>
            <h2 tw="font-medium text-neutral-700">Order By Program</h2>
            <ListItem>
              <ProgramAutoComplete
                classes={classes}
                handleChange={handleFilters}
                reset={reset}
                setReset={setReset}
                filterType={"item-all"}
              />
            </ListItem>
            <ListItem>
              <TextField
                className={classes.queryField}
                color="secondary"
                fullWidth
                name="programId"
                type="text"
                label="Program ID"
                value={anaplanOrRtaProgramId}
                onChange={(e) => setAnaplanOrRtaProgramId(e.target.value)}
                variant="outlined"
                size="small"
                disabled={isLoading}
                onKeyPress={executeOnEnter(handleProgramSearch)}
              />
            </ListItem>
            <ListItem>
              <Button
                fullWidth
                className={classes.largeButton}
                variant="contained"
                color="secondary"
                onClick={handleProgramSearch}
                disabled={isLoading}
              >
                SEARCH
              </Button>
            </ListItem>
          </>
        )}
        {orderBy === "misc" && (
          <>
            <h2 tw="font-medium text-neutral-700">Order By Miscellaneous</h2>
            <ListItem>
              <TextField
                color="secondary"
                fullWidth
                name="itemNumber"
                type="text"
                label="Sequence #"
                variant="outlined"
                size="small"
                value={itemNumber}
                {...bindSequenceNum}
                disabled={isLoading}
              />
            </ListItem>
            <ListItem>
              <TextField
                color="secondary"
                fullWidth
                name="itemNumber"
                type="text"
                label="Item Description"
                variant="outlined"
                size="small"
                value={itemDesc}
                {...bindItemDesc}
                disabled={isLoading}
              />
            </ListItem>
            <ListItem>
              <Button
                fullWidth
                className={classes.largeButton}
                variant="contained"
                color="secondary"
                onClick={handleSearch}
                disabled={isLoading}
              >
                SEARCH
              </Button>
            </ListItem>
            <ListItem />
            <Divider />
            <ListItem />
            <ListItem>
              <Button
                fullWidth
                className={classes.largeButton}
                variant="contained"
                color="secondary"
                disabled={favoriteItems.length === 0 || isLoading}
                onClick={() =>
                  handleFilters([...favoriteItems], "favItems", "item-all")
                }
              >
                FILTER FAVORITES
              </Button>
            </ListItem>
            <ListItem>
              <BrandAutoComplete
                classes={classes}
                handleChange={handleFilters}
                reset={reset}
                setReset={setReset}
                filterType={"item-all"}
              />
            </ListItem>
            <ListItem>
              <ItemTypeAutoComplete
                classes={classes}
                handleChange={handleFilters}
                reset={reset}
                setReset={setReset}
                filterType={"item-all"}
              />
            </ListItem>
            <ListItem>
              <BUAutoComplete
                classes={classes}
                handleChange={handleFilters}
                reset={reset}
                setReset={setReset}
                filterType={"item-all"}
              />
            </ListItem>
            <ListItem>
              <IsAccoladeSelector
                handleIsAccolade={handleFilters}
                isAccolade={isAccolade}
                setIsAccolade={setIsAccolade}
                classes={classes}
                filterType={"item-all"}
              />
            </ListItem>
            {currentUserRole !== "field1" && currentUserRole !== "field2" && (
              <ListItem>
                <Typography className={classes.bodyText}>
                  Exclusive Territory:
                </Typography>
              </ListItem>
            )}
            <ListItem>
              <TerritoryAutoComplete
                classes={classes}
                handleChange={handleFilters}
                reset={reset}
                setReset={setReset}
                filterType={"item-all"}
                type="exclusive"
              />
            </ListItem>
            <Divider />
            <ListItem />
            <ListItem>
              <Button
                fullWidth
                className={classes.largeButton}
                variant="contained"
                color="secondary"
                onClick={() => {
                  dispatch(setClear());
                }}
                disabled={isLoading}
              >
                CLEAR FILTERS
              </Button>
            </ListItem>
          </>
        )}
      </List>
    </>
  );
};

FiltersOnDemandItems.propTypes = {
  reset: PropTypes.bool.isRequired,
  setReset: PropTypes.func.isRequired,
  handleFilters: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  itemNumber: PropTypes.string.isRequired,
  bindSequenceNum: PropTypes.object.isRequired,
};

export default FiltersOnDemandItems;
