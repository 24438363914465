export const buildTerritoryPost = ({
  name,
  states,
  externalCode,
  billingId,
  externalAnaplanId,
  exemptFromBudgeting,
  type,
}) => {
  const stateArray = states.map((st) => ({ type: "state", id: st.id }));
  return {
    data: {
      type: "territory",
      attributes: {
        name: name,
        type: type,
        "external-code": externalCode,
        "external-billing-id": billingId,
        "external-anaplan-id": externalAnaplanId,
        "exempt-from-budgeting": exemptFromBudgeting,
      },
      relationships: {
        states: {
          data: stateArray,
        },
      },
    },
  };
};

export const buildTerritoryPatch = (
  id,
  {
    name,
    states,
    externalCode,
    billingId,
    externalAnaplanId,
    exemptFromBudgeting,
  }
) => {
  const stateArray = states.map((st) => ({ type: "state", id: st.id }));
  return {
    data: {
      type: "territory",
      id: id,
      attributes: {
        name: name,
        "external-code": externalCode,
        "external-billing-id": billingId,
        "external-anaplan-id": externalAnaplanId,
        "exempt-from-budgeting": exemptFromBudgeting,
      },
      relationships: {
        states: {
          data: stateArray,
        },
      },
    },
  };
};
