/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { CircularProgress } from "@mui/material";

import _ from "lodash";
import { ORDER_TO_INVENTORY_FROM_ON_DEMAND } from "src/constants/permissions";

import { AddressSearch } from "@features/addresses";

import { useCurrentOrderSet } from "../data/CurrentOrderSetContext";
import useAddOrders from "../data/mutations/useAddOrders";
import FavoriteAddressSetsMenuButton from "./FavoriteAddressSetsMenuButton";
import RestockOrdersButton from "./RestockOrdersButton";

const OrderSetAddressSection = () => {
  const { role } = useSelector((state: any) => state.user);
  const addOrders = useAddOrders();
  const { orderSet, orders } = useCurrentOrderSet();
  const orderType = orderSet.type;
  const [queuedAddressIds, setQueuedAddressIds] = useState<string[]>([]);

  const handleCreateOrders = (addressIds: string[]) => {
    setQueuedAddressIds([...queuedAddressIds, ...addressIds]);
    addOrders.mutate(addressIds, {
      onError: () => setQueuedAddressIds((ids) => _.xor(ids, addressIds)),
    });
  };

  const allowToInventoryOrders =
    orderType === "pre-order" ||
    (orderType === "on-demand" &&
      ORDER_TO_INVENTORY_FROM_ON_DEMAND.includes(role));

  const orderSetAddressIds = orders.flatMap((o) =>
    o.address ? [o.address.id] : []
  );
  const loadingOrders = _.difference(queuedAddressIds, orderSetAddressIds);

  useEffect(() => {
    if (loadingOrders.length === 0) {
      setQueuedAddressIds([]);
    }
  }, [loadingOrders.length]);

  return (
    <div tw="space-y-3">
      <AddressSearch
        multiple
        autoFocus
        currentTerritory
        onChange={(addresses) =>
          handleCreateOrders([addresses.at(-1)?.id].filter(Boolean) as string[])
        }
        renderTags={() => null}
        getOptionDisabled={(option) =>
          orderSetAddressIds.includes(option.id) ||
          queuedAddressIds.includes(option.id)
        }
      />
      <div tw="flex gap-2 mt-3">
        <FavoriteAddressSetsMenuButton
          handleAddAddressSet={(set) =>
            handleCreateOrders(set.addresses.map((a) => a.id))
          }
        />
        <RestockOrdersButton disabled={!allowToInventoryOrders} />
      </div>
      {loadingOrders.length > 0 && (
        <div tw="bg-neutral-100 p-3 rounded-lg text-neutral-600 tracking-wide text-base flex items-center justify-between">
          <div>Creating orders...</div>
          <CircularProgress size={24} />
        </div>
      )}
    </div>
  );
};

export default OrderSetAddressSection;
