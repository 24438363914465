import { createSlice } from "@reduxjs/toolkit";

import { axiosGet } from "../../../api/axiosCalls";
import { setError } from "../errorSlice";
import { startGlobalLoad, stopGlobalLoad } from "../globalLoadSlice";
import { buildProgramQuery } from "./helpers";
import { mapPrograms } from "./maps";

let initialState = {
  isLoading: false,
  isProgramListLoading: false,
  isNextProgramListLoading: false,
  isUpdateLoading: false,
  nextLink: null,
  isItemsLoading: false,
  programs: [],
  programList: [],
  currentAdHocVariants: [],
  updateStatus: false,
  error: null,
};

const loadingFailed = (state, action) => {
  const { error } = action.payload;
  state.isLoading = false;
  state.isProgramListLoading = false;
  state.isNextProgramListLoading = false;
  state.isItemsLoading = false;
  state.isUpdateLoading = false;
  state.error = error;
};

const adHocProgramSlice = createSlice({
  name: "adHocPrograms",
  initialState,
  reducers: {
    setIsLoading(state) {
      state.isLoading = true;
    },
    getAdHocProgramsSuccess(state, action) {
      const { programs } = action.payload;
      state.programs = programs;
      state.isLoading = false;
      state.error = null;
    },
    addAdHocVariants(state, action) {
      const { variants } = action.payload;
      const newVariants = state.currentAdHocVariants.concat(variants);
      state.currentAdHocVariants = newVariants;
    },
    clearAdHocPrograms(state) {
      state.isLoading = false;
      state.isItemsLoading = false;
      state.isProgramListLoading = false;
      state.isUpdateLoading = false;
      state.programs = [];
      state.programList = [];
      state.currentAdHocVariants = [];
      state.error = null;
    },
    setFailure: loadingFailed,
  },
});

export const {
  setIsLoading,
  getAdHocProgramsSuccess,
  addAdHocVariants,
  clearAdHocPrograms,
  setFailure,
} = adHocProgramSlice.actions;

export default adHocProgramSlice.reducer;

export const fetchAllAdHocPrograms =
  (id, channel, active) => async (dispatch) => {
    try {
      dispatch(setIsLoading());
      dispatch(startGlobalLoad());
      const queryString = buildProgramQuery({
        territoryId: id,
        channel,
        isAdHoc: true,
        active,
      });
      const response = await axiosGet(queryString);
      if (response.error) throw response.error;
      const mappedData = mapPrograms(response.data, active);
      dispatch(getAdHocProgramsSuccess({ programs: mappedData }));
      dispatch(stopGlobalLoad());
    } catch (err) {
      dispatch(stopGlobalLoad());
      dispatch(setError({ error: err.toString(), source: "Ad Hoc Programs" }));
      dispatch(setFailure({ error: err.toString() }));
    }
  };
