/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

import { setFiltersOpen } from "src/redux/slices/filterSlice";

import CouponSection from "../components/PlanningTool/ItemPrograms/CouponSection";
import ItemSection from "../components/PlanningTool/ItemPrograms/ItemSection";
import RerunSection from "../components/PlanningTool/ItemPrograms/RerunSection";
import TemplatedCouponSection from "../components/PlanningTool/ItemPrograms/TemplatedCouponSection";
import PlanningToolNav from "../components/PlanningTool/PlanningToolNav";
import CreateNationalProgram from "../components/PlanningTool/Programs/CreateNationalProgram";
import ProgramDetails from "../components/PlanningTool/Programs/ProgramDetails";
import RfqModal from "../components/PlanningTool/RFQ/RfqModal/RfqModal";
import { Contained } from "../components/StyledComponents";
import { PLANNING_TOOL_SUPPLIER } from "../constants/permissions";
import { useCurrentUserRole } from "../hooks/permissionHooks";
import {
  clearItemPrograms,
  fetchItemPrograms,
  refreshItemProgram,
} from "../redux/slices/planningTool/itemProgramSlice";
import { fetchSingleProgram } from "../redux/slices/planningTool/programSlice";

const PlanningToolCreate = () => {
  const { pid } = useParams();
  const dispatch = useDispatch();
  const userRole = useCurrentUserRole();
  const navigate = useNavigate();
  const [modal, handleModal] = useState(false);

  const { currentProgram } = useSelector((state) => state.program);

  const entities = useSelector((state) =>
    Object.values(state.itemPrograms.entities)
  );
  entities.reverse();

  const {
    rerun: reruns,
    coupon: coupons,
    item: items,
    templatedCoupon: templatedCoupons,
  } = entities.reduce(
    (a, b) => {
      a[b.itemProgramType].push(b);
      return a;
    },
    { item: [], rerun: [], coupon: [], templatedCoupon: [] }
  );

  const handleModalClose = () => {
    handleModal(false);
    navigate("/dashboard");
  };

  useEffect(() => {
    if (pid !== undefined) {
      dispatch(fetchSingleProgram(pid));
      dispatch(fetchItemPrograms(pid));
      handleModal(false);
      return () => {
        dispatch(clearItemPrograms());
      };
    } else {
      handleModal(true);
    }
  }, [pid, dispatch]);

  useEffect(() => {
    if (currentProgram.status === "draft") return;
    // Refresh item if submitted and still no itemNumber
    let refreshIntervals = [];
    entities.forEach((item) => {
      if (item.designType === "epr" && !item.itemNumber) {
        refreshIntervals[item.id] = setInterval(() => {
          dispatch(
            refreshItemProgram(
              item.id,
              (update) =>
                update.itemNumber && clearInterval(refreshIntervals[item.id])
            )
          );
        }, 20_000);
      }
    });
    return () =>
      Object.values(refreshIntervals).forEach((interval) =>
        clearInterval(interval)
      );
  }, [entities.length, currentProgram.status, dispatch, entities]);

  useEffect(() => {
    dispatch(setFiltersOpen({ open: false }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      tw="bg-slate-100 pb-36"
      css={{
        "& p": tw`text-slate-700`,
        "& .MuiTableRow-root": tw`relative`,
      }}
    >
      <Helmet>
        <title>Planning Tool | Create Program</title>
      </Helmet>
      {modal && <CreateNationalProgram handleClose={handleModalClose} />}
      {pid === currentProgram.id && (
        <>
          <PlanningToolNav
            title="Edit Program"
            programId={pid}
            view="program"
          />
          <Contained>
            <RfqModal />
            <ProgramDetails />
            <ItemSection items={items} />
            {userRole !== PLANNING_TOOL_SUPPLIER && (
              <RerunSection items={reruns} countOffset={items.length} />
            )}

            <CouponSection
              items={coupons}
              countOffset={items.length + reruns.length}
            />

            {(currentProgram.orderType !== "from-inventory" ||
              templatedCoupons.length > 0) && (
              <TemplatedCouponSection
                items={templatedCoupons}
                countOffset={items.length + reruns.length + coupons.length}
              />
            )}
          </Contained>
        </>
      )}
    </div>
  );
};

PlanningToolCreate.propTypes = {};

export default PlanningToolCreate;
