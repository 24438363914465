/** @jsxImportSource @emotion/react */
import "twin.macro";

import React, { useState } from "react";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";

import { Typography } from "@mui/material";

import axios from "axios";
import BackButton from "src/components/Reporting/BackButton";
import { setFiltersOpen } from "src/redux/slices/filterSlice";

import { SelectField } from "../components/Forms/DefaultInputs";
import { StyledButton } from "../components/StyledComponents";
import { setError } from "../redux/slices/errorSlice";
import { saveAsCsv } from "../utility/utilityFunctions";

const startYear = 2022;
const thisYear = new Date().getFullYear();
const yearOptions = Array(thisYear - startYear + 7)
  .fill(startYear)
  .map((v, i) => ({ value: v + i, name: v + i }));

const ReportPOAccrual = () => {
  const dispatch = useDispatch();
  const [accrualYear, setAccrualYear] = useState(thisYear);
  const [loading, setLoading] = useState(null);

  const handleSubmit = (type) => {
    setLoading(type);
    const url = {
      summary: `/api/accrual-summary-purchase-order-report?filter[accrual-year]=${accrualYear}`,
      details: `/api/accrual-details-purchase-order-report?accrual-year=${accrualYear}`,
    }[type];
    axios
      .get(url)
      .then((res) => {
        saveAsCsv(res.data, `po-accrual-${accrualYear}.csv`);
      })
      .catch((error) =>
        dispatch(
          setError({ error: error.toString(), source: "Accrual PO filter" })
        )
      )
      .finally(() => setLoading(null));
  };

  useEffect(() => {
    dispatch(setFiltersOpen({ open: false }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Helmet>
        <title>RTA | PO Accrual Reporting</title>
      </Helmet>

      <div tw="flex flex-col">
        <div tw="flex justify-end m-4">
          <BackButton />
        </div>
        <div tw="flex flex-col items-center">
          <Typography tw="text-xl mt-8 text-neutral-700 font-bold">
            PO Accrual Reporting
          </Typography>
          <div tw="mt-8">
            <SelectField
              label="Accrual Year"
              tw="w-48"
              value={accrualYear}
              onChange={(e) => setAccrualYear(e.target.value)}
              name="accrualYear"
              options={yearOptions}
              required
            />
          </div>
          <div tw="space-x-4 mt-6">
            <StyledButton
              cta
              onClick={() => handleSubmit("summary")}
              disabled={!!loading}
              loading={loading === "summary"}
            >
              PO Summary Report
            </StyledButton>
            <StyledButton
              cta
              onClick={() => handleSubmit("details")}
              disabled={!!loading}
              loading={loading === "details"}
            >
              PO Details Report
            </StyledButton>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReportPOAccrual;
