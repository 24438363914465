import React from "react";

import { Box, Stack, Typography } from "@mui/material";

import SpecDetails from "../../Utility/SpecDetails";
import CloudinaryFilesDownloadDelete from "../CloudinaryFilesDownloadDelete";

const RfqDetails = ({ currentQuote, classes }) => {
  const {
    itemSpecification,
    itemType,
    itemNumber,
    round,
    cloudinaryFiles,
    rfqId,
  } = currentQuote;

  return (
    <Box className={classes.container}>
      <Typography variant="h5">
        {itemType} #{itemNumber} - round {round}
      </Typography>
      <br />
      <Box
        sx={{
          borderRadius: "16px",
          background: "whitesmoke",
          mb: 4,
          p: 3,
          color: "#4C4C4C",
          columns: 3,
        }}
      >
        <SpecDetails classes={classes} specArray={itemSpecification} />
      </Box>
      <Stack>
        <Typography variant="h5">Files: </Typography>
        <CloudinaryFilesDownloadDelete
          cloudinaryFiles={cloudinaryFiles}
          rfqid={rfqId}
          showDelete={false}
        />
      </Stack>
    </Box>
  );
};

export default RfqDetails;
