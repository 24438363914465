/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import React from "react";

import { Typography } from "@mui/material";

import { formatMoneyString } from "src/utility/utilityFunctions";

import { OrderVariant } from "@models/OrderVariant";

import PaperTooltip from "../../ui/PaperTooltip";

const OrderVariantBudget = ({
  orderVariant,
}: {
  orderVariant: OrderVariant;
}) => {
  const { budgets } = orderVariant;
  // orderVariant.qty > 0 && console.log(orderVariant.budgets[0]);
  return (
    <PaperTooltip
      title={
        budgets.length <= 1 ? null : (
          <>
            <h3 tw="font-medium mb-3">Multi-budget</h3>
            <div tw="grid grid-cols-[auto 1fr] gap-x-4 gap-y-1 text-sm">
              {budgets.map((budget) => (
                <React.Fragment key={budget.id}>
                  <div tw="text-neutral-500 tracking-wide">
                    {budget.subStateCode} x {budget.brandExternalId}
                  </div>
                  <div tw="text-right">
                    {formatMoneyString(budget.availableAmount)}
                  </div>
                </React.Fragment>
              ))}
            </div>
          </>
        )
      }
      placement="top-end"
    >
      <div tw="text-xs absolute z-10 top-0.5 left-3 right-3 grid grid-cols-[1fr auto] gap-2 items-baseline text-neutral-400 hover:text-neutral-800">
        {budgets.length === 1 && (
          <>
            <Typography noWrap tw="text-xs">
              {budgets[0].subStateCode} x {budgets[0].brandExternalId}
            </Typography>
            <div
              css={[
                budgets.length === 1 &&
                  +budgets[0].availableAmount < 0 &&
                  tw`text-red-600 hover:text-red-900`,
              ]}
            >
              {formatMoneyString(budgets[0].availableAmount)}
            </div>
          </>
        )}
        {budgets.length > 1 && (
          <>
            <div>{"Multi"}</div>
            <div>
              {formatMoneyString(
                budgets.reduce((a, b) => a + +b.availableAmount, 0)
              )}
            </div>
          </>
        )}
      </div>
    </PaperTooltip>
  );
};

export default OrderVariantBudget;
