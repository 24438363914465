/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

const Tag = tw.div`inline-block px-1 py-0.5 mr-2 text-xs font-medium tracking-wider rounded bg-neutral-100 text-neutral-800`;

const Tags = {
  Restock: () => <Tag tw="bg-blue-100 text-blue-900">RESTOCK</Tag>,
  DirectShip: () => <Tag>DIRECT SHIP</Tag>,
};

export default Tags;
