import { useEffect, useRef, useState } from "react";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
import Helmet from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import GetAppIcon from "@mui/icons-material/GetApp";
import { CircularProgress } from "@mui/material";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";

import _ from "lodash";
import HideFiltersButtonToggle from "src/components/Filtering/HideFiltersButtonToggle";
import AssignWarehouseToItemModal from "src/components/Purchasing/AssignWarehouseToItemModal";
import FulfillFromInventoryModal from "src/components/Purchasing/FulfillFromInventoryModal";

import { query } from "@services/api";
import { CSVLink } from "@utils/csv";

import { axiosGet } from "../api/axiosCalls";
import FilterChipList from "../components/Filtering/FilterChipList";
import ItemRollupTable from "../components/Purchasing/ItemRollupTable";
import MultipleVariantModal from "../components/Purchasing/MultipleVariantModal";
import WarningModal from "../components/Utility/Modals/WarningModal";
import {
  PLANNING_TOOL_SUPPLIER,
  SUPPLIER_ROLES,
} from "../constants/permissions";
import { useChannelUpdate, useInitialFilters } from "../hooks/UtilityHooks";
import { setError } from "../redux/slices/errorSlice";
import { setSorted, updateMultipleFilters } from "../redux/slices/filterSlice";
import { mapItemRollups } from "../redux/slices/purchasing/maps";
import {
  clearPORollupReport,
  createPO,
  fetchNextPORollupItems,
  fetchPORollupReport,
  setItemRollupIdSelection,
  setTriggerCSVFalse,
} from "../redux/slices/purchasing/purchaseOrderSlice";
import { createNewRFP } from "../redux/slices/purchasing/rfpSlice";
import { formatMoney } from "../utility/utilityFunctions";
import MarkAsTransferredModal from "./MarkAsTransferredModal";

/*
The Purchase Order Rollup utilizes the item-rollups endpoint, and shows all items that are ready
to be purchased via purchase order. Each row represents multiple order variants, that have been
'rolled up' into a single order. These groupings are based on pre order programs, and the item
sequence number. The grouping is also slightly different if the user is filtering by on demand,
in which case the groupings are even more specific.
*/

const defaultFilters = {
  orderType: "on-demand",
  brand: [],
  program: [],
  itemType: [],
  bus: [],
  itemNumber: "",
  excludedSupplier: null,
  sortOrder: "desc",
  sortOrderBy: "approvedDate",
  rtaDeployment: true,
};

const useStyles = makeStyles((theme) => ({
  ...theme.global,
}));

const PurchaseOrderRollup = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { channel: channelFilter, orderType: orderTypeFilter } = useSelector(
    (state) => state.filters
  );

  const { role, currentChannel } = useSelector((state) => state.user);
  const isSupplier = SUPPLIER_ROLES.includes(role);

  const {
    isLoading: isItemRollupsLoading,
    isNextLoading,
    itemRollupIdSelection,
    itemRollups,
    nextLink,
    poRollupReport,
    triggerCSVDownload,
  } = useSelector((state) => state.purchaseOrder);

  // A path is an array of function to be called in order
  // Any of these functions may open a modal or require user input
  const paths = {
    createPO: [
      checkForVariantsOfSelectedItem,
      handleWarehouseAssignmentRequirement,
      handleCreatePurchaseOrder,
    ],
    fulfillWithInventory: [
      checkForVariantsOfSelectedItem,
      handleFulfillWithInventory,
    ],
    markAsTransfer: [checkForVariantsOfSelectedItem, handleMarkAsTransferred],
  };

  // Hold these values as refs to avoid re-rendering, or
  // having to wait for a re-render to access the values
  const pathRef = useRef(null);
  const stepRef = useRef(0);
  const selectedItemRollupsRef = useRef([]);

  const doStep = () => paths[pathRef.current][stepRef.current]();

  const startPath = (path) => {
    // [rowId, variantId, itemId][]
    const idsArray = itemRollupIdSelection.map((idString) =>
      idString.split("-")
    );

    // Get the item rollups for the selected items
    selectedItemRollupsRef.current = idsArray.map((id) =>
      itemRollups.find((ir) => ir.id === id[0])
    );

    // This shouldn't happen, but just in case
    if (selectedItemRollupsRef.current.length === 0) return;

    pathRef.current = path;
    stepRef.current = 0;

    doStep();
  };

  const finishPath = () => {
    pathRef.current = null;
    stepRef.current = 0;
    selectedItemRollupsRef.current = [];
  };

  const nextStep = () => {
    if (stepRef.current < paths[pathRef.current].length - 1) {
      stepRef.current++;
      doStep();
    } else {
      finishPath();
    }
  };

  const [warning, setWarning] = useState(null);
  const [markAsTransferredModalOpen, setMarkAsTransferredModalOpen] =
    useState(null);
  const [multipleVariantModal, setMultipleVariantModal] = useState(null);
  const [assignWarehouseItemId, setAssignWarehouseItemId] = useState(null);
  const [isVariantCheckLoading, setVariantCheckLoading] = useState(false);
  const [inventoryFulfillmentModalOpen, setInventoryFulfillmentModalOpen] =
    useState(false);
  const [currentCSV, setCurrentCSV] = useState({ data: [], headers: [] });

  const handleNewRFP = () => {
    let currentItem = itemRollups.find(
      (item) => item.itemId === +itemRollupIdSelection[0].split("-")[2]
    );
    dispatch(
      createNewRFP(
        itemRollupIdSelection[0].split("-")[2],
        currentItem.programId,
        currentItem.orderVariantIds
      )
    );
    dispatch(setItemRollupIdSelection({ selectedItems: [] }));
  };

  async function checkForVariantsOfSelectedItem() {
    const itemNumber =
      selectedItemRollupsRef.current[0].itemNumber.split("-")[0];

    setVariantCheckLoading(true);

    const params = {
      skipPagination: true,
      filter: {
        isForRfp: false,
        itemNumber: itemNumber,
        channel: channelFilter,
        orderSetType: orderTypeFilter,
        ...(orderTypeFilter === "pre-order" && {
          isAdHoc: false,
        }),
      },
    };

    let matchingRollupItems = await axiosGet(
      `/api/item-rollups?${query(params)}`
    );
    if (matchingRollupItems.error) {
      return dispatch(
        setError({
          error: "Error fetching additional rollup items",
          souce: "Purchase Order Rollup",
        })
      );
    }
    // We only want to show item-rollups that are not already selected
    matchingRollupItems = _.differenceBy(
      mapItemRollups(matchingRollupItems.data),
      selectedItemRollupsRef.current,
      "id"
    );

    // If the user is not filtering by on-demand, fetch on-demand rollup items
    // so we can offer the user to combine them into a single PO
    let onDemandRollupItems = [];
    if (orderTypeFilter !== "on-demand") {
      const onDemandParams = {
        skipPagination: true,
        filter: {
          isForRfp: false,
          itemNumber: itemNumber,
          channel: channelFilter,
          orderSetType: "on-demand",
        },
      };
      onDemandRollupItems = await axiosGet(
        `/api/item-rollups?${query(onDemandParams)}`
      );
      if (onDemandRollupItems.error) {
        return dispatch(
          setError({
            error: "Error fetching additional rollup items",
            souce: "Purchase Order Rollup",
          })
        );
      }
      onDemandRollupItems = mapItemRollups(onDemandRollupItems.data);
    }

    setVariantCheckLoading(false);
    if (matchingRollupItems.length > 0 || onDemandRollupItems.length > 0) {
      setMultipleVariantModal({
        matchingRollupItems,
        onDemandRollupItems,
      });
    } else {
      nextStep();
    }
  }

  function handleWarehouseAssignmentRequirement() {
    const needsWarehouseAssignment = selectedItemRollupsRef.current.some(
      (ir) => ir.needsWarehouseAssignment
    );

    if (needsWarehouseAssignment) {
      setAssignWarehouseItemId(selectedItemRollupsRef.current[0].itemId);
    } else {
      nextStep();
    }
  }

  async function handleCreatePurchaseOrder() {
    const orderVariantIds = selectedItemRollupsRef.current.flatMap(
      (ir) => ir.orderVariantIds
    );

    const programId = selectedItemRollupsRef.current[0].programId;

    dispatch(
      createPO({
        idArray: orderVariantIds,
        orderType: orderTypeFilter,
        programId,
      })
    );
    dispatch(setItemRollupIdSelection({ selectedItems: [] }));
    nextStep();
  }

  function handleFulfillWithInventory() {
    const pendingCompliance = selectedItemRollupsRef.current.some(
      (ir) => ir.qtyPendingCompliance > 0
    );
    if (pendingCompliance) {
      setWarning(
        "There are compliance issues that need to be resolved on this item before you are able to fulfill with inventory."
      );
      finishPath();
    } else {
      setInventoryFulfillmentModalOpen(true);
    }
  }

  function handleMarkAsTransferred() {
    setMarkAsTransferredModalOpen(true);
  }

  const handlePORollupReport = () => {
    dispatch(fetchPORollupReport());
  };

  // Table state
  const handleBottomScroll = () => {
    if (nextLink && !isNextLoading) {
      if (scrollRef.current && scrollRef.current.scrollTop !== 0) {
        dispatch(fetchNextPORollupItems(nextLink));
      }
    }
  };

  const scrollRef = useBottomScrollListener(handleBottomScroll, {
    offset: 500,
    debounceOptions: {
      leading: true,
      trailing: false,
    },
  });

  defaultFilters.channel = currentChannel;

  const handleSort = (sortObject) => {
    scrollRef.current.scrollTop = 0;
    dispatch(
      updateMultipleFilters({
        filterObject: {
          sortOrder: sortObject.order,
          sortOrderBy: sortObject.orderBy,
        },
      })
    );
    dispatch(setSorted());
  };

  useEffect(() => {
    if (triggerCSVDownload && currentCSV.data.length === 0) {
      let csvHeaders = [
        { label: "Sequence #", key: "itemNumber" },
        { label: "Supplier", key: "supplier" },
        { label: "Ordered By", key: "user" },
        { label: "Territory", key: "territory" },
        { label: "Program", key: "program" },
        { label: "Brand", key: "brand" },
        { label: "Project #", key: "projectNum" },
        { label: "Item Type", key: "itemType" },
        { label: "Item Desc.", key: "itemDesc" },
        { label: "Total Ordered", key: "totalQty" },
        { label: "Pending Compliance", key: "qtyPendingCompliance" },
        { label: "Est. Cost", key: "estCost" },
        { label: "Est. Total", key: "totalEstCost" },
        { label: "Order Approval Date", key: "approvedDate" },
        { label: "In-Market Date", key: "inMarketDate" },
        { label: "Spec. Code", key: "standardSpecificationCode" },
      ];
      if (isSupplier) {
        csvHeaders = csvHeaders.filter(
          (header) => header.key !== "estCost" && header.key !== "supplier"
        );
      }
      let csvData = [];
      poRollupReport.forEach((item) => {
        let dataObject = {
          itemNumber: item.itemNumber,
          user: item.user,
          territory: item.territory.join(", "),
          program: item.program,
          brand: item.brand,
          projectNum: item.projectNum,
          itemType: item.itemType,
          itemDesc: item.itemDescription,
          standardSpecificationCode: item.standardSpecificationCode,
          totalQty: item.totalQty,
          qtyPendingCompliance: item.qtyPendingCompliance,
          totalEstCost: formatMoney(item.totalEstCost, true),
          approvedDate: item.approvedDate,
          inMarketDate: item.inMarketDate,
        };
        if (!isSupplier) {
          dataObject.supplier = item.supplier;
          dataObject.estCost = formatMoney(item.estCost, true);
        }
        csvData.push(dataObject);
      });
      setCurrentCSV({ data: csvData, headers: csvHeaders });
    } else if (!triggerCSVDownload && currentCSV.data.length > 0) {
      setCurrentCSV({
        data: [],
        headers: [],
      });
    }
  }, [poRollupReport, triggerCSVDownload, currentCSV, role, isSupplier]);

  useChannelUpdate(currentChannel, channelFilter, dispatch);

  useInitialFilters("itemRollup-po", defaultFilters);

  useEffect(() => {
    dispatch(setTriggerCSVFalse());
    dispatch(clearPORollupReport());
  }, [dispatch]);

  return (
    <>
      <Helmet>
        <title>RTA | Purchase Order Rollup</title>
        {role === "purchaser" && (
          <script type="text/javascript">{` Beacon('suggest', ['601438192042ff6d1b2a8ab3'])`}</script>
        )}
      </Helmet>
      {assignWarehouseItemId && (
        <AssignWarehouseToItemModal
          itemId={assignWarehouseItemId}
          handleContinue={() => {
            nextStep();
            setAssignWarehouseItemId(null);
          }}
          handleCancel={() => {
            finishPath();
            setAssignWarehouseItemId(null);
          }}
        />
      )}
      {inventoryFulfillmentModalOpen && (
        <FulfillFromInventoryModal
          handleClose={() => setInventoryFulfillmentModalOpen(false)}
          itemRollups={selectedItemRollupsRef.current}
        />
      )}
      {warning && (
        <WarningModal
          open
          handleClose={() => setWarning(null)}
          message={warning}
        />
      )}
      {markAsTransferredModalOpen && (
        <MarkAsTransferredModal
          handleClose={() => {
            finishPath();
            setMarkAsTransferredModalOpen(false);
          }}
          itemRollups={selectedItemRollupsRef.current}
        />
      )}
      {multipleVariantModal && (
        <MultipleVariantModal
          {...multipleVariantModal}
          handleContinue={() => {
            nextStep();
            setMultipleVariantModal(null);
          }}
          handleCancel={() => {
            finishPath();
            setMultipleVariantModal(null);
          }}
          selectedItemRollupsRef={selectedItemRollupsRef}
        />
      )}
      <Container className={classes.mainWrapper}>
        <div className={classes.titleBar}>
          <Typography className={classes.titleText}>
            Purchase Order Rollup
          </Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            {role !== PLANNING_TOOL_SUPPLIER && (
              <Button
                className={classes.largeButton}
                variant="contained"
                color="secondary"
                disabled={
                  itemRollupIdSelection.length !== 1 || isVariantCheckLoading
                }
                style={{ marginRight: "20px" }}
                onClick={() => {
                  handleNewRFP();
                  navigate("/purchasing/rfp#new");
                }}
              >
                REQUEST PRICE
              </Button>
            )}
            <Button
              className={classes.largeButton}
              variant="contained"
              color="secondary"
              disabled={
                itemRollupIdSelection.length === 0 || isVariantCheckLoading
              }
              style={{ marginRight: "20px", minWidth: "120px" }}
              onClick={() => startPath("createPO")}
            >
              {isVariantCheckLoading ? (
                <CircularProgress size={25} />
              ) : (
                "CREATE PO"
              )}
            </Button>
            {role !== PLANNING_TOOL_SUPPLIER && (
              <>
                <Button
                  className={classes.largeButton}
                  variant="contained"
                  color="secondary"
                  style={{ marginRight: "20px" }}
                  disabled={
                    isVariantCheckLoading || itemRollupIdSelection.length === 0
                  }
                  onClick={() => startPath("markAsTransfer")}
                >
                  MARK AS TRANSFERRED
                </Button>
                <Button
                  className={classes.largeButton}
                  variant="contained"
                  color="secondary"
                  style={{ marginRight: "20px" }}
                  disabled={
                    isVariantCheckLoading || itemRollupIdSelection.length === 0
                  }
                  onClick={() => startPath("fulfillWithInventory")}
                >
                  USE INVENTORY
                </Button>
              </>
            )}
            {!triggerCSVDownload && currentCSV.data.length === 0 && (
              <span>
                <Button
                  className={classes.largeButton}
                  variant="contained"
                  onClick={handlePORollupReport}
                  disabled={itemRollups.length === 0}
                >
                  GENERATE REPORT
                </Button>
              </span>
            )}
            {triggerCSVDownload && currentCSV.data.length > 0 && (
              <CSVLink
                data={currentCSV.data}
                headers={currentCSV.headers}
                filename="rta_po_rollup.csv"
              >
                <Button
                  className={classes.largeButton}
                  variant="contained"
                  color="secondary"
                  startIcon={<GetAppIcon />}
                >
                  EXPORT REPORT
                </Button>
              </CSVLink>
            )}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignContent: "center",
            marginBottom: "10px",
          }}
        >
          <HideFiltersButtonToggle />
          <FilterChipList classes={classes} />
          <br />
        </div>
        <ItemRollupTable
          items={itemRollups}
          isItemsLoading={isItemRollupsLoading}
          handleSort={handleSort}
          scrollRef={scrollRef}
          type={"po"}
        />
        {isNextLoading && (
          <div style={{ width: "100%" }}>
            <LinearProgress />
          </div>
        )}
        {!isNextLoading && <div style={{ width: "100%", height: "4px" }}></div>}
      </Container>
      <br />
    </>
  );
};

export default PurchaseOrderRollup;
