/** @jsxImportSource @emotion/react */
import "twin.macro";
import tw from "twin.macro";

import React from "react";

import { MoreHoriz, Warning } from "@mui/icons-material";
import { Tooltip } from "@mui/material";

import { addDays, endOfDay, isBefore } from "date-fns";
import _, { capitalize } from "lodash";
import { SmartTable } from "src/components/SmartTable";
import {
  Column,
  PaginatedResourceProps,
} from "src/components/SmartTable/SmartTable";
import {
  formatDateString,
  formatMoneyString,
  formatUtcDate,
  utcDate,
} from "src/utility/utilityFunctions";

import { getAnaplanProgramsLabel } from "@features/anaplanPrograms/helpers";
import BudgetStatusChip from "@features/budgets/BudgetStatusChip";
import { OrderSet } from "@models/OrderSet";

type ApprovalsOrderSetTableProps = PaginatedResourceProps & {
  data: OrderSet[];
  showAnaplanPrograms?: boolean;
  tableRef: React.MutableRefObject<any>;
};

const getBrands = (os: OrderSet): string[] =>
  _(os.orderSetVariants)
    .flatMap("variant.item.brands")
    .map("name")
    .uniq()
    .value();

const isOverDue = (os: OrderSet) =>
  os.orderCalendarMonth && os.status === "in-progress"
    ? isBefore(
        addDays(
          endOfDay(utcDate(os.orderCalendarMonth.purchasingCloseDate)),
          1
        ),
        endOfDay(new Date())
      )
    : false;

const formatStatus = (status) => {
  if (status === "inactive") {
    return "Not Started";
  } else if (status === "in-progress") {
    return "In Progress";
  } else if (status === "submitted") {
    return "Order Submitted";
  } else {
    return capitalize(status);
  }
};

const costColumn = {
  align: "right" as const,
  render: (value) => formatMoneyString(value),
};

const ApprovalsOrderSetTable = ({
  data,
  showAnaplanPrograms,
  ...tableProps
}: ApprovalsOrderSetTableProps) => {
  const rowLink = (row: OrderSet) => `/orders/open/${row.id}#rollup`;
  const columns: Column<OrderSet>[] = [
    {
      id: "budgets",
      label: "Budget Status",
      render: (budgets) => <BudgetStatusChip budgets={budgets} />,
      to: rowLink,
    },
    {
      id: "user.name",
      label: "Ordered By",
      render: (name, row) => (
        <div tw="flex items-center">
          {isOverDue(row) && (
            <Tooltip
              placement="top-start"
              title="This order was not submitted on it's intended schedule, please notify your administrator if you are seeing this message."
            >
              <Warning fontSize="small" color="inherit" tw="pl-2" />
            </Tooltip>
          )}
          {name}
        </div>
      ),
      to: rowLink,
    },
    { id: "territory.name", label: "Territory", to: rowLink },
    { id: "program.name", label: "Program", sort: "program-name" },
    ...(showAnaplanPrograms
      ? [
          {
            id: "anaplanPrograms",
            label: "Anaplan Program",
            render: getAnaplanProgramsLabel,
          },
        ]
      : []),
    {
      id: "_brands",
      label: "Brand",
      render: (_, row) => {
        const brands = getBrands(row);
        return (
          <Tooltip
            placement="left"
            title={brands.length > 1 && `${brands.join(", ")}`}
          >
            <div>
              {brands[0]}
              {brands.length > 1 && (
                <MoreHoriz fontSize="small" color="inherit" />
              )}
            </div>
          </Tooltip>
        );
      },
    },
    { id: "stateNames", label: "State" },
    {
      id: "totalEstimatedCost",
      label: "Est. Unit Costs",
      ...costColumn,
      render: (v, row) =>
        formatMoneyString(row.type === "from-inventory" ? 0 : v),
    },
    {
      id: "totalBeaconCost",
      label: "Total Beacon Cost",
      ...costColumn,
    },
    {
      id: "totalEstimatedFreight",
      label: "Est. Freight",
      ...costColumn,
    },
    {
      id: "totalEstimatesTaxes",
      label: "Est. Taxes",
      ...costColumn,
    },
    {
      id: "totalExtendedCost",
      label: "Est. Extended Total",
      ...costColumn,
      render: (v, row) =>
        formatMoneyString(
          row.type === "from-inventory" ? v - +row.totalEstimatedCost : v
        ),
    },
    {
      id: "submittedAt",
      label: "Order Submitted",
      render: (v) => formatUtcDate(v),
    },
    {
      id: "orderCalendarMonth.orderWindowCloseDate",
      label: "Order Window Close",
      render: formatDateString,
    },
    {
      id: "orderCalendarMonth.inMarketStartDate",
      label: "In Market Date",
      render: formatDateString,
    },
    { id: "status", label: "Status", render: formatStatus },
  ];

  return (
    <SmartTable
      columns={columns}
      rows={data}
      {...tableProps}
      rowCss={(row) => isOverDue(row) && tw`bg-red-100 hover:bg-red-50`}
    />
  );
};

export default ApprovalsOrderSetTable;
