import React from "react";
import { useDispatch } from "react-redux";

import DeleteIcon from "@mui/icons-material/Delete";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { Button, Stack, Typography } from "@mui/material";

import { deleteCloudinaryFile } from "../../redux/slices/planningTool/rfqSlice";
import { downloadFileCloudinary } from "../../utility/cloudinary";

const CloudinaryFilesDownloadDelete = ({
  cloudinaryFiles,
  rfqid,
  showDelete = true,
}) => {
  const dispatch = useDispatch();

  const deleteFileCloudinary = (cloudinaryFileId, rfqId) => {
    dispatch(deleteCloudinaryFile(cloudinaryFileId, rfqId));
  };

  return (
    <Stack>
      {cloudinaryFiles.map((file, index) => {
        return (
          <Stack key={index} direction="horizontal">
            <Typography sx={{ display: "flex", alignItems: "center" }}>
              {file.fileName}
            </Typography>
            <Button
              onClick={() => {
                downloadFileCloudinary(file.cloudinaryId, file.fileName);
              }}
            >
              <FileDownloadIcon style={{ margin: 2 }} />
            </Button>
            {showDelete && (
              <Button onClick={() => deleteFileCloudinary(file.id, rfqid)}>
                <DeleteIcon />
              </Button>
            )}
          </Stack>
        );
      })}
    </Stack>
  );
};

export default CloudinaryFilesDownloadDelete;
