import { kebabCaseKeys } from "src/utility/utilityFunctions";

import { buildFilters } from "../../../api/apiFunctions";

export const buildRFQQuery = (filterObject) => {
  const sortMap = {
    itemNumber: "item-number",
    stock: "cached-warehouse-qty",
  };
  const sortString =
    filterObject.sortOrder && filterObject.sortOrderBy
      ? `sort=${filterObject.sortOrder === "desc" ? "-" : ""}${
          sortMap[filterObject.sortOrderBy]
        }`
      : "";
  return buildFilters(
    filterObject,
    "",
    sortString,
    "/api/request-for-quotes",
    "rfq"
  );
};

export const buildFilteredPlanningProgramQuery = (filterObject) => {
  let queryString = buildFilters(
    filterObject,
    "",
    "",
    "/api/programs",
    "program"
  );

  return queryString;
};

export const buildFilteredAnaplanProgramQuery = (filterObject) => {
  let queryString = buildFilters(
    filterObject,
    "",
    "",
    "/api/anaplan-programs",
    "anaplanProgram"
  );

  return queryString;
};

export const buildDraftProgram = ({
  brands,
  brandAssignmentLevel,
  name,
  orderType,
  programTypeId,
  channel,
  orderCalendarMonthId,
  startDate,
  endDate,
  anaplanProgramId,
}) => {
  const primaryBrand = brands?.[0];
  const brandList = (brands ?? []).map((brand) => ({
    type: "brand",
    id: brand.id,
  }));

  let anaplanProgramList = [];
  if (anaplanProgramId) {
    anaplanProgramList = [
      {
        type: "anaplan-program",
        id: anaplanProgramId,
      },
    ];
  }

  const attributes = kebabCaseKeys({
    status: "draft",
    brandAssignmentLevel,
    channel,
    endDate,
    name,
    orderType,
    programTypeId,
    startDate,
  });

  return {
    data: {
      type: "program",
      attributes,
      relationships: {
        brands: { data: brandList },
        ...(orderType === "pre-order" &&
          programTypeId === "1" && {
            "order-calendar-month": {
              data: {
                type: "order-calendar-month",
                id: orderCalendarMonthId,
              },
            },
          }),
        "anaplan-programs": { data: anaplanProgramList },
        ...(primaryBrand && {
          "primary-brand": {
            data: { type: "primary-brand", id: primaryBrand.id },
          },
        }),
      },
    },
  };
};

export const buildDraftClonedProgram = ({
  orderType,
  name,
  brandAssignmentLevel,
  programTypeId,
  channel,
  orderCalendarMonthId,
  startDate,
  endDate,
  anaplanProgramId,
}) => {
  return kebabCaseKeys({
    brandAssignmentLevel,
    channel,
    endDate,
    name,
    orderCalendarMonthId,
    orderType,
    programTypeId,
    startDate,
    ...(anaplanProgramId && { anaplanProgramIds: [anaplanProgramId] }),
  });
};

export const buildProgram = ({
  programData,
  brands,
  territories,
  isExclusive,
  primaryBrand,
  anaplanProgramId,
}) => {
  let anaplanProgramList = [];
  if (anaplanProgramId) {
    anaplanProgramList = [
      {
        type: "anaplan-program",
        id: anaplanProgramId,
      },
    ];
  }
  return {
    data: {
      type: "program",
      attributes: {
        name: programData.name,
        "order-type": programData.orderType,
        status: programData.status,
        "is-territory-exclusive": isExclusive,
        "is-brand-funded": programData.isBrandFunded,
        ...(programData.orderType !== "pre-order" && {
          "start-date": programData.startDate,
          "end-date": programData.endDate,
        }),
      },
      relationships: {
        ...(brands && {
          brands: {
            data: brands.map((brand) => ({
              type: "brand",
              id: brand.id,
            })),
          },
        }),
        ...(programData.sponsorTerritoryId && {
          "sponsor-territory": {
            data: {
              type: "sponsor-territory",
              id: programData.sponsorTerritoryId,
            },
          },
        }),
        ...(territories &&
          isExclusive && {
            territories: {
              data: territories.map((terr) => ({
                type: "territory",
                id: terr.id,
              })),
            },
          }),
        ...(primaryBrand && {
          "primary-brand": {
            data: { type: "primary-brand", id: primaryBrand.id },
          },
        }),
        ...(programData.orderType === "pre-order" &&
          programData.orderCalendarMonthId && {
            "order-calendar-month": {
              data: {
                type: "order-calendar-month",
                id: programData.orderCalendarMonthId,
              },
            },
          }),
        "anaplan-programs": { data: anaplanProgramList },
      },
    },
  };
};

export const buildItemQuery = (filterObject) => {
  const sortMap = {
    itemNumber: "item-number",
    stock: "cached-warehouse-qty",
  };
  const sortString =
    filterObject.sortOrder && filterObject.sortOrderBy
      ? `sort=${filterObject.sortOrder === "desc" ? "-" : ""}${
          sortMap[filterObject.sortOrderBy]
        }`
      : "";
  return buildFilters(filterObject, "", sortString, "/api/items", "item");
};

export const buildQuotesQuery = (filterObject) => {
  const sortMap = {
    itemNumber: "item-number",
    stock: "cached-warehouse-qty",
  };
  const sortString =
    filterObject.sortOrder && filterObject.sortOrderBy
      ? `sort=${filterObject.sortOrder === "desc" ? "-" : ""}${
          sortMap[filterObject.sortOrderBy]
        }`
      : "";
  return buildFilters(filterObject, "", sortString, "/api/quotes", "quotes");
};

export const buildVariantAllocationRequest = (qty, variantId, territoryId) => {
  return {
    data: {
      attributes: {
        "allocated-qty": +qty,
        "variant-id": +variantId,
        "territory-id": +territoryId,
      },
    },
  };
};

export const buildNote = (id, note) => {
  return {
    data: {
      type: "note",
      attributes: {
        message: note,
      },
      relationships: {
        program: {
          data: {
            type: "program",
            id: +id,
          },
        },
      },
    },
  };
};
