import React from "react";

import { format } from "date-fns";

import GeneralSelector from "./GeneralSelector";

const thisYear = new Date().getFullYear();

const options = Array(6)
  .fill(null)
  .map((_, i) => thisYear - 3 + i)
  .map((year) =>
    Array(12)
      .fill(null)
      .map((_, i) => [String(i + 1), year])
  )
  .flat()
  .map(([m, y]) => ({
    id: m + "-" + y,
    label: format(
      new Date(`${y}-${String(m).padStart(2, "0")}-02`),
      "yyyy MMMM"
    ),
  }));

const MonthYearSelector = (props) => {
  return <GeneralSelector label="Month-Years" options={options} {...props} />;
};

export default MonthYearSelector;
