import React from "react";

import {
  Chip,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import PropTypes from "prop-types";

import { formatDateString } from "../../../utility/utilityFunctions";

const headCells = [
  { id: "id", label: "Program Id" },
  { id: "name", label: "Program Name" },
  { id: "inMarketStart", label: "In Market Start Date" },
  { id: "inMarketEnd", label: "In Market End Date" },
  { id: "activate", label: "Activate" },
];

const PlanningTableHead = ({ classes }) => (
  <TableHead>
    <TableRow>
      {headCells.map((headCell) => {
        return (
          <TableCell
            className={classes.headerText}
            key={headCell.id}
            align="left"
          >
            {headCell.label}
          </TableCell>
        );
      })}
    </TableRow>
  </TableHead>
);

PlanningTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
};

const useStyles = makeStyles((theme) => ({
  ...theme.global,
}));

const ProgramActivationTable = ({
  programs,
  isLoading,
  scrollRef,
  setSelectedProgram,
}) => {
  const classes = useStyles();
  return (
    <>
      <TableContainer
        className={classes.tableContainer}
        style={{ maxHeight: "Calc(100vh - 275px)" }}
        ref={scrollRef}
      >
        <Table
          stickyHeader
          className={classes.table}
          aria-label="planning-tool-programs-table"
        >
          <PlanningTableHead classes={classes} />
          <TableBody>
            {!isLoading && programs.length === 0 && (
              <TableRow>
                <TableCell
                  align="left"
                  colSpan={headCells.length}
                  className={classes.headerText}
                >
                  There are currently no programs that match this search
                  criteria..
                </TableCell>
              </TableRow>
            )}
            {!isLoading &&
              programs.length > 0 &&
              programs.map((row, i) => (
                <TableRow key={row.id + "-" + i}>
                  <TableCell>{row.code}</TableCell>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>
                    {row.startDate ? formatDateString(row.startDate) : "---"}
                  </TableCell>
                  <TableCell>
                    {row.endDate ? formatDateString(row.endDate) : "---"}
                  </TableCell>
                  <TableCell>
                    <Chip
                      label="Activate"
                      variant="outlined"
                      clickable
                      onClick={() => {
                        setSelectedProgram(row);
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            {isLoading && (
              <TableRow>
                <TableCell align="left" colSpan={headCells.length}>
                  <CircularProgress />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

ProgramActivationTable.propTypes = {
  programs: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  scrollRef: PropTypes.any,
};

export default React.memo(ProgramActivationTable, (prev, next) => {
  return (
    prev.programs.length === next.programs.length &&
    prev.isLoading === next.isLoading
  );
});
