import React from "react";

import { Box, Typography } from "@mui/material";

import PropTypes from "prop-types";

const SpecItem = ({ label, value }) => (
  <Box component={Typography} sx={{ mb: 1, fontSize: 14 }}>
    <b>{label}</b>&nbsp;{value}
  </Box>
);

const SpecDetails = ({ classes, specArray }) => {
  return (
    <Box>
      {specArray.map(({ key, value, type }, i) =>
        type === "spec" ? (
          <SpecItem key={key} label={`${key}:`} value={value} />
        ) : (
          Object.keys(value).map((wSpec) => (
            <SpecItem key={wSpec} label={wSpec} value={value[wSpec]} />
          ))
        )
      )}
    </Box>
  );
};

SpecDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  specArray: PropTypes.array,
};

export default React.memo(
  SpecDetails,
  (prev, next) =>
    prev.specArray &&
    next.specArray &&
    prev.specArray.length === next.specArray.length
);
