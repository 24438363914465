import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import CancelIcon from "@mui/icons-material/Cancel";
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import PropTypes from "prop-types";

import {
  addBulkOrderVariants,
  createNewBulkVariantOrder,
} from "../../redux/slices/ordering/currentOrderSlice";
import { clearItemSelections } from "../../redux/slices/ordering/currentOrderSlice";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
}));

const VariantList = ({
  classes,
  name,
  variants,
  selectedVariants,
  currentVariantIds,
  handleSelect,
}) => {
  return (
    <>
      <Typography className={classes.headerText}>{name}</Typography>
      <br />
      <List>
        {variants
          .filter((v) => v.name)
          .map((v, i) => (
            <ListItem
              key={`${i}-${v.id}`}
              role={undefined}
              dense
              button
              onClick={() => handleSelect(v.id)}
            >
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={selectedVariants.includes(v.id)}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ "aria-labelledby": v.id }}
                  disabled={currentVariantIds.includes(v.id)}
                />
              </ListItemIcon>
              <ListItemText id={v.id} primary={v.name} />
            </ListItem>
          ))}
      </List>
    </>
  );
};

VariantList.propTypes = {
  classes: PropTypes.object.isRequired,
  name: PropTypes.string,
  variants: PropTypes.array.isRequired,
  selectedVariants: PropTypes.array.isRequired,
  currentVariantIds: PropTypes.array.isRequired,
  handleSelect: PropTypes.func.isRequired,
};

const VariantModal = ({
  selectedItems,
  currentItems,
  territoryId,
  channel,
  orderType,
  currentOrder,
  open,
  handleClose,
  programIdFromFilter,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [variantIds, setVariantIds] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [singleVariantItems, setSingleVariantItems] = useState([]);
  const [multipleVariantItems, setMultipleVariantItems] = useState([]);

  const currentInventoryIds = useSelector(
    (state) => state.currentOrder.selectedInventoryItems
  );
  const currentOnDemandIds = useSelector(
    (state) => state.currentOrder.selectedOnDemandItems
  );

  const resetState = () => {
    setVariantIds([]);
    setSelectedIds([]);
    setSingleVariantItems([]);
    setMultipleVariantItems([]);
  };

  const handleSelect = (id) => {
    if (selectedIds.includes(id)) {
      setSelectedIds(selectedIds.filter((i) => i !== id));
    } else {
      setSelectedIds(selectedIds.concat(id));
    }
  };

  const handleAddToOrder = () => {
    let singleVariantIds = singleVariantItems.map((i) => i.variants[0].id);
    if (!currentOrder.orderId) {
      dispatch(
        createNewBulkVariantOrder(
          orderType,
          selectedIds.concat(singleVariantIds),
          territoryId,
          channel,
          programIdFromFilter
        )
      );
    } else {
      // If already an order, get the variants for it and only send new selectedIds.
      dispatch(
        addBulkOrderVariants(
          currentOrder.orderId,
          selectedIds.concat(singleVariantIds),
          orderType
        )
      );
    }
    resetState();
    dispatch(clearItemSelections());
    handleClose();
  };

  useEffect(() => {
    if (variantIds.length === 0) {
      let currentVariantIds =
        orderType === "inventory" ? currentInventoryIds : currentOnDemandIds;
      const selectedItemIds = selectedItems.map((item) =>
        item.id ? item.id : item
      );
      let newVariantIds = currentItems
        .filter((i) => selectedItemIds.includes(i.id))
        .filter((i) => i.variants.length > 1)
        .reduce(
          (acc, item) =>
            acc.concat(item.variants.filter((v) => v.name).map((v) => v.id)),
          []
        );
      setSelectedIds(
        newVariantIds.filter((id) => !currentVariantIds.includes(id))
      );
      setSingleVariantItems(
        currentItems
          .filter((i) => selectedItemIds.includes(i.id))
          .filter((i) => i.variants.length === 1)
      );
      setMultipleVariantItems(
        currentItems
          .filter((i) => selectedItemIds.includes(i.id))
          .filter((i) => i.variants.length > 1)
      );
      setVariantIds(newVariantIds);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.relativeContainer}>
      <Dialog
        open={open}
        disableScrollLock
        onClose={handleClose}
        fullWidth
        maxWidth="sm"
        style={{ zIndex: "15000" }}
      >
        <DialogContent>
          <IconButton
            className={classes.closeButton}
            onClick={() => {
              resetState();
              handleClose();
            }}
            size="large"
          >
            <CancelIcon fontSize="large" color="secondary" />
          </IconButton>
          <br />
          {variantIds.length === 0 ? (
            <div style={{ width: "100%" }}>
              <br />
              <CircularProgress color="secondary" />
              <br />
            </div>
          ) : (
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography className={classes.headerText}>
                It looks like some items you have selected have multiple
                variants. Please select which variants you would like to add to
                your order.
              </Typography>
              <br />
              {multipleVariantItems.map((item, i) => (
                <div style={{ width: "100%" }} key={i}>
                  <VariantList
                    classes={classes}
                    name={`${item.itemNumber} - ${item.itemType}`}
                    variants={item.variants}
                    selectedVariants={selectedIds}
                    currentVariantIds={
                      orderType === "inventory"
                        ? currentInventoryIds
                        : currentOnDemandIds
                    }
                    handleSelect={handleSelect}
                  />
                  {i !== multipleVariantItems.length - 1 ? (
                    <>
                      <br />
                      <Divider />
                      <br />
                    </>
                  ) : (
                    <br />
                  )}
                </div>
              ))}
              <Button
                className={classes.largeButton}
                variant="contained"
                color="secondary"
                onClick={handleAddToOrder}
              >
                ADD TO ORDER
              </Button>
            </div>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

VariantModal.propTypes = {
  selectedItems: PropTypes.array.isRequired,
  currentItems: PropTypes.array.isRequired,
  territoryId: PropTypes.string.isRequired,
  channel: PropTypes.string,
  orderType: PropTypes.string.isRequired,
  currentOrder: PropTypes.object,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default memo(VariantModal);
