import { useEffect, useRef } from "react";

import { isEqual, kebabCase } from "lodash";

import { HookOptions } from "./types";

export const buildFilters = (filterObj) =>
  Object.entries(filterObj).reduce((obj, [key, val]) => {
    if (val !== undefined && val !== null) {
      key = kebabCase(key);
      if (Array.isArray(val)) {
        key += "][";
        val = val.map((n) => (typeof n === "object" ? n?.id : n)).join(",");
      }
      obj[key] = val;
    }
    return obj;
  }, {});

export const jsonApiPaginate =
  (initialParams: [string, ...any[]] | null) =>
  (pageIndex, previousPageData) => {
    let params = null as [string, ...any[]] | null;
    if (pageIndex === 0) params = initialParams;
    else if (previousPageData.links?.next) {
      params = [previousPageData.links.next?.replace(/^\/api/, "")];
    }
    return params;
  };

function useDeepCompareMemoize(value) {
  const ref = useRef();
  // it can be done by using useMemo as well
  // but useRef is rather cleaner and easier

  if (!isEqual(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
}

export function useDeepCompareEffect(callback, dependencies) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(callback, dependencies.map(useDeepCompareMemoize));
}

export const splitOptions = (options: HookOptions = {}) => {
  return {
    requestOptions: {
      params: {
        filter: { ...options.filter },
        sort: options.sort,
        ...options.params,
      },
    },
    queryOptions: {
      ...(options.refetch === false && {
        staleTime: Infinity,
      }),
      ...options,
    },
  };
};
