import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

import PropTypes from "prop-types";

const ProgramTypeAutoComplete = ({
  classes,
  handleChange,
  reset,
  setReset,
  filterType,
}) => {
  const [open, setOpen] = useState(false);
  const [programType, setProgramType] = useState("");
  const [currentProgramTypes, setCurrentProgramTypes] = useState([]);

  const programTypes = useSelector((state) => state.programTypes.programTypes);
  const currentFilter = useSelector((state) => state.filters.programType);
  const isGlobalLoading = useSelector((state) => state.globalLoad.isLoading);

  const handleProgramTypes = (value) => {
    setCurrentProgramTypes(value);
  };

  useEffect(() => {
    if (currentFilter.length === 0) {
      setProgramType("");
      setCurrentProgramTypes([]);
      setReset(false);
    }
  }, [currentFilter]);

  useEffect(() => {
    if (reset) {
      setProgramType("");
      setCurrentProgramTypes([]);
      setReset(false);
    }
  }, [reset, setProgramType, setReset]);

  return (
    <>
      <Autocomplete
        multiple
        autoHighlight
        renderTags={() => null}
        fullWidth
        className={classes.queryField}
        classes={{
          popper: classes.liftedPopper,
        }}
        id="programType-auto-complete"
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        inputValue={programType}
        onInputChange={(_evt, value) => setProgramType(value)}
        onChange={(evt, value) => {
          handleChange(value, "programType", filterType);
          handleProgramTypes(value);
        }}
        isOptionEqualToValue={(option, value) =>
          option.description === value.description
        }
        getOptionLabel={(option) => option.description}
        options={programTypes}
        value={currentProgramTypes}
        disabled={isGlobalLoading}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Program Type"
            variant="outlined"
            size="small"
            InputProps={{
              ...params.InputProps,
              autoComplete: "new-password",
              endAdornment: <>{params.InputProps.endAdornment}</>,
            }}
          />
        )}
      />
    </>
  );
};

ProgramTypeAutoComplete.propTypes = {
  classes: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  reset: PropTypes.bool.isRequired,
  setReset: PropTypes.func.isRequired,
  filterType: PropTypes.string.isRequired,
};

export default ProgramTypeAutoComplete;
