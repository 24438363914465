/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import React from "react";

import SmartTable, {
  Column,
  PaginatedResourceProps,
} from "src/components/SmartTable/SmartTable";
import { formatMoneyString } from "src/utility/utilityFunctions";

import { Budget } from "@models/Budget";

export const budgetTableColumns: Column<Budget>[] = [
  { id: "id", label: "ID", to: (row) => `/budgets/${row.id}` },
  {
    id: "externalId",
    label: "External ID",
    to: (row) => `/budgets/${row.id}`,
  },
  {
    id: "_brand",
    label: "Brand",
    render: (_, budget) => budget.brandExternalId + " " + budget.brandName,
  },
  {
    id: "_subState",
    label: "State",
    render: (_, budget) =>
      budget.subStateCode === budget.subStateName
        ? budget.subStateCode
        : budget.subStateCode + " " + (budget.subStateName ?? ""),
  },
  { id: "year", label: "year", align: "right" },
  {
    id: "totalAmount",
    label: "Initial Amount",
    align: "right",
    render: formatMoneyString,
  },
  {
    id: "availableAmount",
    label: "Available Amount",
    align: "right",
    renderText: (v) => formatMoneyString(v),
    render: (v) => (
      <span css={[v < 0 && tw`font-medium text-red-700`]}>
        {formatMoneyString(v)}
      </span>
    ),
  },
];

type BudgetsTableProps = PaginatedResourceProps & {
  rows: Budget[];
  onRowClick?: (row: Budget) => void;
};

const BudgetsTable = (tableProps: BudgetsTableProps) => {
  return <SmartTable columns={budgetTableColumns} {...tableProps} />;
};

export default BudgetsTable;
