// https://github.com/marudhupandiyang/react-csv-to-table
import React from "react";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

const parseCsvToRowsAndColumn = (csvText, csvColumnDelimiter = ",") => {
  const rows = csvText.split("\n");
  if (!rows || rows.length === 0) {
    return [];
  }

  return rows.map((row) => row.split(csvColumnDelimiter));
};

const CsvTable = ({
  data,
  csvDelimiter,
  hasHeader,
  tableClassName,
  tableRowClassName,
  tableColumnClassName,
  rowKey,
  colKey,
  renderCell,
}) => {
  const rowsWithColumns = parseCsvToRowsAndColumn(data.trim(), csvDelimiter);
  let headerRow = undefined;
  if (hasHeader) {
    headerRow = rowsWithColumns.splice(0, 1)[0];
  }

  const renderTableHeader = (row) => {
    if (row && row.map) {
      return (
        <TableHead style={{ background: "whitesmoke" }}>
          <TableRow>
            {row.map((column, i) => (
              <TableCell key={`header-${i}`}>{column}</TableCell>
            ))}
          </TableRow>
        </TableHead>
      );
    }
  };

  const renderTableBody = (rows) => {
    if (rows && rows.map) {
      return (
        <TableBody>
          {rows.map((row, rowIdx) => (
            <TableRow
              className={tableRowClassName}
              key={typeof rowKey === "function" ? rowKey(row, rowIdx) : rowIdx}
            >
              {row.map &&
                row.map((column, colIdx) => (
                  <TableCell
                    className={tableColumnClassName}
                    key={
                      typeof rowKey === "function"
                        ? colKey(row, colIdx, rowIdx)
                        : column[colKey]
                    }
                  >
                    {typeof renderCell === "function"
                      ? renderCell(column, colIdx, rowIdx)
                      : column}
                  </TableCell>
                ))}
            </TableRow>
          ))}
        </TableBody>
      );
    }
  };

  return (
    <Table size="small" className={`csv-html-table ${tableClassName}`}>
      {renderTableHeader(headerRow)}
      {renderTableBody(rowsWithColumns)}
    </Table>
  );
};

CsvTable.defaultProps = {
  data: "",
  rowKey: (row, rowIdx) => `row-${rowIdx}`,
  colKey: (col, colIdx, rowIdx) => `col-${colIdx}`,
  hasHeader: true,
  csvDelimiter: ",",
  tableClassName: "",
  tableRowClassName: "",
  tableColumnClassName: "",
};

export default React.memo(CsvTable);
