import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import PropTypes from "prop-types";

const ProgramTypeSelector = ({
  handleType,
  className,
  disabled,
  type,
  hideNational,
  ...props
}) => {
  const [programType, setProgramType] = useState(type || "");
  let programTypes = useSelector((state) => state.programTypes.programTypes);
  if (hideNational) {
    programTypes = programTypes.filter((t) => t.description !== "National");
  }

  const isLoading = useSelector((state) => state.programTypes.isLoading);

  const handleChangeSelect = (evt) => {
    setProgramType(evt.target.value);
    handleType(evt.target.value.split("-")[0], evt.target.value.split("-")[1]);
  };

  useEffect(() => {
    setProgramType(type);
  }, [type]);

  return (
    <>
      <FormControl
        variant="outlined"
        size="small"
        className={className}
        style={{ minWidth: "250px" }}
        disabled={isLoading}
        {...props}
      >
        <InputLabel id="program-type-select">Program Type</InputLabel>
        <Select
          variant="outlined"
          label="Program Type"
          name="program-type"
          labelId="program-type-select"
          id="program-type"
          value={programType}
          onChange={handleChangeSelect}
          disabled={disabled}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          }}
        >
          {programTypes.map((type, index) => {
            return (
              <MenuItem key={index} value={`${type.id}-${type.description}`}>
                {type.description}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </>
  );
};

ProgramTypeSelector.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default React.memo(ProgramTypeSelector);
