import React from "react";

import CancelIcon from "@mui/icons-material/Cancel";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";

import PropTypes from "prop-types";

import { formatMoney } from "../../utility/utilityFunctions";
import ImageWrapper from "../Utility/ImageWrapper";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
}));

const ItemTable = ({ items, isLoading, handleDelete }) => {
  const classes = useStyles();

  return (
    <>
      <TableContainer className={classes.tableContainer}>
        <Table stickyHeader className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.headerText} align="left">
                Preview
              </TableCell>
              <TableCell className={classes.headerText} align="left">
                Sequence #
              </TableCell>
              <TableCell className={classes.headerText} align="left">
                Program
              </TableCell>
              <TableCell className={classes.headerText} align="left">
                Item Type
              </TableCell>
              <TableCell className={classes.headerText} align="left">
                Est. Cost
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {!isLoading && items.length === 0 && (
              <TableRow>
                <TableCell align="left" colSpan={8}>
                  <Typography className={classes.headerText}>
                    {`You currently don't have any favorite items set...`}
                  </Typography>
                </TableCell>
              </TableRow>
            )}
            {!isLoading &&
              items.length > 0 &&
              items.map((item) => (
                <TableRow key={item.id}>
                  <TableCell align="left">
                    <ImageWrapper
                      id={item.itemNumber}
                      imgClass={classes.previewImg}
                      alt={item.itemType}
                      imgUrl={item.imgUrlThumb}
                    />
                  </TableCell>
                  <TableCell align="left">{item.itemNumber}</TableCell>
                  <TableCell align="left">{item.program}</TableCell>
                  <TableCell align="left">{item.itemType}</TableCell>
                  <TableCell align="left">
                    {formatMoney(item.estCost, false)}
                  </TableCell>
                  <TableCell align="right">
                    <Tooltip title="Remove Favorite">
                      <IconButton
                        onClick={() => handleDelete(item.id)}
                        size="large"
                      >
                        <CancelIcon fontSize="small" color="inherit" />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            {isLoading && (
              <TableRow>
                <TableCell align="left" colSpan={8}>
                  <CircularProgress />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

ItemTable.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  items: PropTypes.array.isRequired,
};

export default React.memo(ItemTable);
