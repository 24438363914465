import React from "react";
import { Route, Routes } from "react-router-dom";

import useRedirect from "src/hooks/useRedirect";

import AuthOLanding from "../pages/AuthOLanding";
import FourOhFour from "../pages/FourOhFour";
import Landing from "../pages/Landing";

const UnauthRoutes = () => {
  useRedirect();

  return (
    <>
      <Routes>
        <Route path="/login" element={<AuthOLanding />} />
        <Route path="/login/:code" element={<AuthOLanding />} />
        <Route path="/login/error/:error" element={<Landing />} />
        <Route path="/" element={<Landing />} />
        <Route path="/whoops" element={<FourOhFour />} />
        <Route path="*" compoonent={<Landing />} />
      </Routes>
    </>
  );
};

export default UnauthRoutes;
