import React, { useEffect } from "react";
import Helmet from "react-helmet";
import { useDispatch, useSelector } from "react-redux";

import GetAppIcon from "@mui/icons-material/GetApp";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";

import PropTypes from "prop-types";
import { setFiltersOpen } from "src/redux/slices/filterSlice";

import { useNoFetch } from "../hooks/UtilityHooks";

/*
The help page houses many pdf documents that users can download or view, that act as instruction
manuals for various poritons of the site.
*/

const useStyles = makeStyles((theme) => ({
  ...theme.global,
  divider: {
    width: "100%",
  },
}));

const Help = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const currentUserEmail = useSelector((state) => state.user.email);
  const userRole = useSelector((state) => state.user.role);
  const helpData = [
    {
      text: "Where's My Stuff",
      linkTo:
        "https://res.cloudinary.com/brandhub/image/upload/v1621008600/prod/HelpDocs/SELDE.2101.RTA_IMPLEMENTATION_DOCUMENTATION_WHERESMYSTUFF_R3_118501_ozx2fe.pdf",
      id: "118501_ozx2fe",
      display: true,
    },
    {
      text: "Field User Guide -- BDM's", //field1 aka sales
      linkTo:
        "https://res.cloudinary.com/brandhub/image/upload/v1623089964/prod/HelpDocs/SALES0514_q4yc4e.pdf",
      id: "5ffdf334b9a8501b295cf995",
      display: true,
    },
    {
      text: "Adding Custom Addresses",
      linkTo:
        "https://res.cloudinary.com/brandhub/image/upload/v1623089786/prod/HelpDocs/SELDE.2101.RTA_IMPLEMENTATION_DOCUMENTATION_CUSTOM_ADDRESSES_R4_118501_1_hx4hvt.pdf",
      id: "118501_1_hx4hvt",
      display: true,
    },
    {
      text: "Order Review Guide -- BAM's/CAM's", // field2, aka "brand activation manager", aka forward deployed
      linkTo:
        "https://res.cloudinary.com/brandhub/image/upload/v1623089915/prod/HelpDocs/OrderReview_oeta5b.pdf",
      id: "600af2ff1c64ad47e4b7201d",
      display: true,
    },
    {
      text: "Order Type Guide",
      linkTo:
        "https://res.cloudinary.com/brandhub/image/upload/v1626697454/prod/HelpDocs/SELDE.2101.RTA_IMPLEMENTATION_DOCUMENTATION_ORDER_TYPE_SUMMARY_118501_1_ske0j9.pdf",
      id: "118501_1_ske0j9",
      display: true,
    },
    {
      text: "Ops User Guide", // aka purchaser
      linkTo:
        "https://s3.amazonaws.com/helpscout.net/docs/assets/5fd9057b7fd4415d7b898720/attachments/6014436c6867724dfc6ed8c9/SELDE.2101.RTA_IMPLEMENTATION_DOCUMENTATION_PURCHASERGUIDE_118501.pdf",
      id: "601438192042ff6d1b2a8ab3",
      display: true,
    },
    {
      text: "View Only User Guide",
      linkTo:
        "https://res.cloudinary.com/brandhub/image/upload/v1626697454/prod/HelpDocs/SELDE.2101.RTA_IMPLEMENTATION_DOCUMENTATION_VIEWONLYGUIDE_R2_118501_vh036h.pdf",
      id: "600ed315c64fe14d0e1fe351",
      display: true,
    },
    {
      text: "Finance User Guide",
      linkTo:
        "https://s3.amazonaws.com/helpscout.net/docs/assets/5fd9057b7fd4415d7b898720/attachments/601441c5a4cefb30ae5c560a/SELDE.2101.RTA_IMPLEMENTATION_DOCUMENTATION_FINANCEGUIDE_118501.pdf",
      id: "600ed398cfe30d219ccdb224",
      display: true,
    },
    {
      text: "Supplier User Guide",
      linkTo:
        "https://res.cloudinary.com/brandhub/image/upload/v1623763893/prod/HelpDocs/SELDE.2101.RTA_IMPLEMENTATION_DOCUMENTATION_SUPPLIERQUICKSTARTGUIDE_R2_118501_ikjaav.pdf",
      id: "601438c22042ff6d1b2a8abb",
      display: true,
    },
    {
      text: "Reporting Guide",
      linkTo:
        "https://res.cloudinary.com/brandhub/image/upload/v1618578806/prod/HelpDocs/SELDE.2101.RTA_IMPLEMENTATION_DOCUMENTATION_REPORTSGUIDE_R3_118501_kx2blt.pdf",
      id: "118501_kx2blt",
      display: true,
    },
    {
      text: "Ordering Calendar",
      linkTo:
        "https://res.cloudinary.com/brandhub/image/upload/v1619620643/prod/HelpDocs/2021_RTA_Ordering_Calendar_1_pissti.pdf",
      id: "1_pissti",
      display: true,
    },
    {
      text: "Shelf Inventory",
      linkTo:
        "https://res.cloudinary.com/brandhub/image/upload/v1622051494/prod/HelpDocs/SELDE.2101.RTA_IMPLEMENTATION_DOCUMENTATION_SHELF_INVENTORY_GUIDE_118501_1_ruyxgf.pdf",
      id: "118501_1_ruyxgf",
      display: [
        "molly.mwopes@ejgallo.com",
        "felicia.livengood@ejgallo.com",
        "super@super.com",
      ].includes(currentUserEmail.toLowerCase()),
    },
    {
      text: "Distributor Address Update",
      linkTo:
        "https://res.cloudinary.com/brandhub/image/upload/v1647262523/prod/HelpDocs/RTA_Distributor_Address_Change_Request_shyapd.pdf",
      id: "shyapd",
      display: true,
    },
    {
      text: "Distributor Shipment Notifications",
      linkTo:
        "https://res.cloudinary.com/brandhub/image/upload/v1647262523/prod/HelpDocs/RTA_Distributor_Email_Notifications_r7ue5f.pdf",
      id: "r7ue5f",
      display: true,
    },
    {
      text: "Planning Tool Guide",
      linkTo:
        "https://res.cloudinary.com/brandhub/image/upload/v1693419366/SELDE.2210.RTA_OPS_GUIDE_R6_122804_ujd79w.pdf",
      display: !["read-only", "field1"].includes(userRole), // Don't show to read-only and field 1 users
    },
  ];

  useEffect(() => {
    dispatch(setFiltersOpen({ open: false }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useNoFetch(dispatch);

  const openGuide = (url) => {
    window.open(url, "_blank");
  };

  return (
    <>
      <Helmet>
        <title>RTA | Help</title>
      </Helmet>
      <Container
        className={classes.mainWrapper}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <br />
        <br />
        <Grid
          container
          spacing={3}
          justifyContent="flex-start"
          style={{ width: "80%" }}
        >
          <div
            style={{
              paddingLeft: "24px",
              width: "100%",
              textAlign: "left",
              marginBottom: "50px",
            }}
          >
            <Typography
              style={{
                fontWeight: "400",
                fontSize: "2rem",
                color: "#4C4C4C",
              }}
            >
              How can we help?
            </Typography>
          </div>
          {helpData.map((data, i) => (
            <Grid item lg={4} md={6} sm={12} xs={12} key={i}>
              <Divider className={classes.divider} />
              <div
                style={{
                  display: "flex",
                  height: "175px",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Typography className={classes.titleText}>
                  {data.text}
                </Typography>
                <IconButton onClick={() => openGuide(data.linkTo)} size="large">
                  <GetAppIcon color="secondary" />
                </IconButton>
              </div>
              <Divider className={classes.divider} />
            </Grid>
          ))}
          <div
            style={{ width: "100%", textAlign: "center", marginTop: "75px" }}
          >
            <Typography className={classes.bodyText}>
              If you have any immediate questions, please contact Select Design.
              We are happy to help!
            </Typography>
            <br />
            <Typography className={classes.headerText}>
              General Contact Information
            </Typography>
            <Typography className={classes.bodyText}>
              Phone: 802-864-9075
            </Typography>
            <Typography className={classes.bodyText}>
              Email: help@readytoactivate.com
            </Typography>
            <Typography className={classes.bodyText}>
              Please allow up to 12 hours to receive a response from us.
            </Typography>
          </div>
        </Grid>
      </Container>
    </>
  );
};

Help.propTypes = {};

export default Help;
