/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Edit } from "@mui/icons-material";
import { LinearProgress, Paper, Skeleton } from "@mui/material";

import { StyledButton } from "src/components/StyledComponents";
import { formatMoneyString } from "src/utility/utilityFunctions";

import { useMutateError } from "@features/errors";

import { getExtendedCost } from "../helpers";
import TotalCost from "../orderSet/TotalCost";
import { useCurrentOrderSet } from "../orderSet/data/CurrentOrderSetContext";
import useDeleteOrderSetMutation from "../orderSet/data/mutations/useDeleteOrderSetMutation";
import useSubmitOrderSetMutation from "../orderSet/data/mutations/useSubmitOrderSetMutation";
import useUpdateOrderSetMutation from "../orderSet/data/mutations/useUpdateOrderSetMutation";
import SetIsWorkInProgressButton from "../preOrder/SetIsWorkInProgressButton";
import OrderSetWrapupButton from "./OrderSetWrapupButton";

const OrderSetSummaryActionPanel = () => {
  const navigate = useNavigate();
  const setMutateError = useMutateError();
  const deleteOrderSet = useDeleteOrderSetMutation();
  const submitOrderSet = useSubmitOrderSetMutation();
  const updateOrderSet = useUpdateOrderSetMutation();
  const { orderSet, isFetching } = useCurrentOrderSet();
  const [isUpdateLoading, setIsUpdateLoading] = useState(false);

  useEffect(() => {
    let timeout;
    if (orderSet.status === "submitted") {
      timeout = setTimeout(() => {
        navigate("/orders/history/group/byOrder");
      }, 5000);
    }
    return () => clearTimeout(timeout);
  }, [orderSet.status, navigate]);

  if (!orderSet) return <Skeleton tw="h-60" />;

  const {
    totalEstimatedCost,
    totalEstimatedFreight,
    totalEstimatedTaxes,
    totalBeaconCost,
    totalQuantity,
    type: orderSetType,
    status,
  } = orderSet;

  const extendedCost = getExtendedCost(orderSet, orderSetType);

  const handleSubmitOrderSet = () => {
    setIsUpdateLoading(true);
    submitOrderSet
      .mutateAsync()
      .catch(setMutateError)
      .finally(() => setIsUpdateLoading(false));
  };

  const toggleIsWorkComplete = () => {
    setIsUpdateLoading(true);
    return updateOrderSet
      .mutateAsync({
        isWorkComplete: !orderSet?.isWorkComplete,
      })
      .catch(setMutateError)
      .finally(() => setIsUpdateLoading(false));
  };

  const rows = [
    ["Total Item Cost", <TotalCost>{totalEstimatedCost}</TotalCost>],
    ["Freight", totalEstimatedFreight],
    ["Taxes", totalEstimatedTaxes],
    ["Beacon Cost", totalBeaconCost],
  ];
  return (
    <>
      <Paper tw="p-4 space-y-6 relative">
        <div tw="grid grid-cols-2 gap-3">
          <StyledButton
            component={Link}
            to="#"
            tw="whitespace-nowrap"
            outlined
            startIcon={<Edit />}
          >
            Edit Order
          </StyledButton>
          <OrderSetWrapupButton />
        </div>
        <div
          css={[
            tw`grid grid-cols-2 gap-1 transition-opacity text-neutral-500`,
            isFetching && tw`opacity-50`,
          ]}
        >
          <div>Total Items</div>
          <div tw="text-right">{totalQuantity}</div>

          {rows.map(([label, value], i) => (
            <React.Fragment key={i}>
              <div tw="text-neutral-500">{label}</div>
              <div tw="text-right text-neutral-700 font-medium">
                {typeof value !== "object" ? formatMoneyString(value) : value}
              </div>
            </React.Fragment>
          ))}
        </div>
        <div
          css={[
            tw`flex items-baseline justify-between text-lg`,
            isFetching && tw`opacity-50`,
          ]}
        >
          <div tw="text-neutral-500">Est. Extended Cost</div>
          <div tw="text-right text-neutral-700 font-medium">
            {formatMoneyString(extendedCost)}
          </div>
        </div>
        {orderSetType === "pre-order" ? (
          <SetIsWorkInProgressButton
            toggleWorkInProgress={toggleIsWorkComplete}
            isUpdateLoading={isUpdateLoading}
          />
        ) : (
          <>
            {["inactive", "in-progress"].includes(status) && (
              <StyledButton
                fullWidth
                cta
                disabled={isFetching}
                loading={isUpdateLoading}
                onClick={handleSubmitOrderSet}
              >
                Submit Order
              </StyledButton>
            )}
            {status === "submitted" && (
              <div tw="text-lg font-medium text-neutral-700">
                Thank you for your submission. Redirecting you in 5 seconds...
              </div>
            )}
          </>
        )}
        {isFetching && <LinearProgress tw="absolute inset-0 top-auto" />}
      </Paper>
      {orderSetType !== "pre-order" && (
        <div tw="mt-4 flex justify-end">
          <StyledButton
            danger
            onClick={() =>
              window.confirm(
                "Are you sure you want to delete this order-set?"
              ) && deleteOrderSet.mutate()
            }
          >
            Delete Order Set
          </StyledButton>
        </div>
      )}
    </>
  );
};

export default OrderSetSummaryActionPanel;
