import { createQueryKeys } from "@lukemorales/query-key-factory";

import { Order } from "@models/Order";
import { OrderSet } from "@models/OrderSet";
import client from "@services/api";
import { buildPaginatedQuery, buildResourceMutations } from "@utils/reactQuery";

export const orderSetsKeyFactory = createQueryKeys("order-sets", {
  detail: (orderSetId) => ({
    queryKey: [orderSetId],
    queryFn: () =>
      client.get<OrderSet>(`order-sets/${orderSetId}`).then((res) => res!.data),
    contextQueries: {
      orders: {
        queryKey: null, // ['order-sets', 'detail', orderSetId, 'orders']
        queryFn: () =>
          client
            .get<Order[]>("orders", {
              params: { filter: { orderSetId }, skipPagination: true },
            })
            .then((res) => res.data),
      },
    },
  }),
  paginated: (params) => ({
    queryKey: [params],
    queryFn: () => client.get<OrderSet[]>("order-sets", { params }),
  }),
});

export const { useCreate: useCreateOrderSetMutation } =
  buildResourceMutations<OrderSet>({
    resource: "order-sets",
    type: "order-set",
    detailQueryKey: (id) => orderSetsKeyFactory.detail(id).queryKey,
    listQueryKey: orderSetsKeyFactory.paginated._def,
  });

export const useOrderSetsPaginated = buildPaginatedQuery(
  orderSetsKeyFactory.paginated
);
