/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import { useEffect, useState } from "react";

import { Add, Remove } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";

const CollapsibleComponent = ({ title, subtitle, children, css }) => {
  const [open, setOpen] = useState(false);
  const [render, setRender] = useState(false);
  const [accOpen, setAccOpen] = useState(false);

  useEffect(() => {
    if (open) {
      setRender(true);
      // Render content before opening accordion
      setTimeout(() => setAccOpen(true), 1);
    }
    if (!open) {
      setAccOpen(false);
      setTimeout(() => setRender(false), 300);
    }
  }, [open]);

  return (
    <Accordion
      elevation={0}
      css={{
        "&": tw`-mx-3 transition border border-transparent border-solid rounded before:hidden`,
        "&:not(.Mui-expanded)": tw`bg-neutral-100 hover:(bg-neutral-200)`,
        "&.Mui-expanded": tw`my-0 -mx-3 border-neutral-200 bg-neutral-50`,
        css,
      }}
      expanded={accOpen}
      onChange={() => setOpen(!open)}
    >
      <AccordionSummary
        expandIcon={accOpen ? <Remove /> : <Add />}
        css={[tw`flex-row-reverse pl-0 ml-2`]}
      >
        <div tw="flex justify-between items-center w-full">
          <span tw="ml-3 font-bold">{title}</span>
          <span tw="text-neutral-500">{subtitle}</span>
        </div>
      </AccordionSummary>
      <AccordionDetails tw="px-3 pt-0">{render && children}</AccordionDetails>
    </Accordion>
  );
};

export default CollapsibleComponent;
