import { createSlice } from "@reduxjs/toolkit";

let initialState = {
  isOrdering: false,
  orderVariantErrors: {},
};

const orderSetSlice = createSlice({
  name: "orderSet",
  initialState,
  reducers: {
    setIsOrdering(state, action) {
      const { status } = action.payload;
      state.isOrdering = status;
    },
    setOrderVariantError(state, action) {
      const { id, error, maxQuantity } = action.payload;
      state.orderVariantErrors[id] = { error, maxQuantity };
    },
    removeOrderVariantError(state, action) {
      const { id } = action.payload;
      delete state.orderVariantErrors[id];
    },
  },
});

export const { setIsOrdering, setOrderVariantError, removeOrderVariantError } =
  orderSetSlice.actions;

export default orderSetSlice.reducer;
