import React, { useState } from "react";
import { Link } from "react-router-dom";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";

import PropTypes from "prop-types";
import { ADMIN_ROLES } from "src/constants/permissions.js";

import NestedMenuItem from "./NestedMenuItem.js";

const DrawerToolkitNav = ({ classes, role }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpen = (evt) => {
    setAnchorEl(evt.target);
    evt.stopPropagation();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        style={{ padding: 0 }}
        onClick={(evt) => {
          handleOpen(evt);
          evt.stopPropagation();
        }}
        size="large"
      >
        <Typography variant="h5" className={classes.navigationText}>
          Toolkit
        </Typography>
        <ExpandMoreIcon fontSize="large" className={classes.expandMoreIcon} />
      </IconButton>
      <Menu
        classes={{ paper: classes.menuBackground }}
        disableScrollLock
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        style={{
          marginTop: "10px",
          zIndex: "3000",
        }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {ADMIN_ROLES.includes(role) && (
          <MenuItem
            onClick={handleClose}
            component={Link}
            to="/purchasing/poRollup"
          >
            <ListItemText
              primaryTypographyProps={{
                className: classes.headerListItemNew,
              }}
              primary="+ New Purchase Order"
            />
          </MenuItem>
        )}
        {ADMIN_ROLES.includes(role) && (
          <MenuItem
            onClick={handleClose}
            component={Link}
            to="/purchasing/rfpRollup"
            key="toolkit-menu-item-0"
          >
            <ListItemText
              primaryTypographyProps={{
                className: classes.headerListItemNew,
              }}
              primary="+ New RFP"
            />
          </MenuItem>
        )}
        {[...ADMIN_ROLES, "field2"].includes(role) && (
          <MenuItem
            onClick={handleClose}
            component={Link}
            to="/shelf-inventory-admin"
            key="tookit-menu-item-1"
          >
            <ListItemText
              primaryTypographyProps={{
                className: classes.headerListItemNew,
              }}
              primary=" + Shelf Inventory"
            />
          </MenuItem>
        )}

        <MenuItem onClick={handleClose} component={Link} to="/reports/items">
          <ListItemText
            primaryTypographyProps={{
              className: classes.headerListItemNew,
            }}
            primary=" + New Item PDF Report"
          />
        </MenuItem>
        <MenuItem onClick={handleClose} component={Link} to="/reports/landing">
          <ListItemText
            primaryTypographyProps={{
              className: classes.headerListItemNew,
            }}
            primary=" + Reports"
          />
        </MenuItem>
        <MenuItem onClick={handleClose} component={Link} to="/help">
          <ListItemText
            primaryTypographyProps={{
              className: classes.headerListItemNew,
            }}
            primary=" + Help &amp; Info"
          />
        </MenuItem>
        {ADMIN_ROLES.includes(role) && [
          <Divider className={classes.divider} key="divider-toolkit-1" />,
          <NestedMenuItem
            key="nested-menu-toolkit-1"
            anchorEl={anchorEl}
            handleClose={handleClose}
            label="Purchase Orders"
            classes={classes}
            childItems={[
              {
                link: "/purchasing/poHistory/current",
                primary: "Current",
              },
              {
                link: "/purchasing/poHistory/complete",
                primary: "History",
              },
            ]}
          />,
          <Divider className={classes.divider} key="divider-toolkit-2" />,
          <NestedMenuItem
            key="nested-menu-toolkit-2"
            anchorEl={anchorEl}
            handleClose={handleClose}
            label="Requests for Price"
            classes={classes}
            childItems={[
              {
                link: "/purchasing/rfpHistory/current",
                primary: "Current",
              },
              {
                link: "/purchasing/rfpHistory/complete",
                primary: "History",
              },
            ]}
          />,
        ]}
        <Divider className={classes.divider} />
        <NestedMenuItem
          anchorEl={anchorEl}
          handleClose={handleClose}
          label="Compliance"
          classes={classes}
          childItems={[
            {
              link: "/compliance/items",
              primary: "Item Rules",
            },
            {
              link: "/compliance/rules",
              primary: "General Rules",
            },
            {
              link:
                role === "compliance" || role === "super"
                  ? "/compliance/overrides"
                  : null,
              primary:
                role === "compliance" || role === "super" ? "Overrides" : null,
            },
            // {
            //   link:
            //     role === "compliance" || role === "super"
            //       ? "/compliance/contacts"
            //       : null,
            //   primary:
            //     role === "compliance" || role === "super" ? "Contacts" : null,
            // },
          ]}
        />
      </Menu>
    </>
  );
};

DrawerToolkitNav.propTypes = {
  classes: PropTypes.object.isRequired,
  role: PropTypes.string.isRequired,
};

export default DrawerToolkitNav;
