/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import { useEffect } from "react";

import { Checkbox } from "@mui/material";

import _ from "lodash";

import { useOrderVariantsQuery } from "@features/orders";
import { OrderVariant } from "@models/OrderVariant";

import { SmartTable } from "../SmartTable";

const OrderVariantList = ({
  singleItemRollup,
  orderVariantIds,
  selectedOvs,
  setSelectedOvs,
  setValid,
}: {
  singleItemRollup: any;
  orderVariantIds: string[];
  selectedOvs: OrderVariant[];
  setSelectedOvs: React.Dispatch<React.SetStateAction<OrderVariant[]>>;
  setValid?: (val: boolean) => void;
}) => {
  const { data = [], ...tableProps } = useOrderVariantsQuery({
    filter: { ids: orderVariantIds.join(",") },
    skipPagination: true,
  });

  const orderVariants = _.sortBy(data, "order.id");
  const intersect = _.intersectionBy(orderVariants, selectedOvs, "id");
  const qtySelected = _.sumBy(intersect, "qty");
  const over = qtySelected > singleItemRollup.stock;

  useEffect(() => {
    if (setValid) {
      setValid(!over);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [over]);

  return (
    <div>
      <div
        css={[
          tw`flex items-center justify-between px-2 py-1 -mx-2 text-sm font-medium border rounded bg-neutral-100 border-neutral-200`,
          setValid && over && tw`border-red-100 bg-red-50`,
        ]}
      >
        <h3>
          {singleItemRollup.itemNumber} /{" "}
          {singleItemRollup.variantName ?? "Default variant"}
        </h3>
        {setValid && (
          <div>
            <span
              css={[qtySelected > singleItemRollup.stock && tw`text-red-700`]}
            >
              {qtySelected}
            </span>
            <span> / {singleItemRollup.stock}</span>
          </div>
        )}
      </div>
      <SmartTable
        rows={orderVariants}
        columns={[
          {
            id: "id",
            label: (
              <Checkbox
                tw="-m-4"
                size="small"
                checked={intersect.length === orderVariants.length}
                indeterminate={
                  intersect.length > 0 &&
                  intersect.length < orderVariantIds.length
                }
                onChange={() =>
                  setSelectedOvs((ovs) =>
                    intersect.length === orderVariants.length
                      ? _.differenceBy(ovs, orderVariants, "id")
                      : _.unionBy(ovs, orderVariants, "id")
                  )
                }
              />
            ),
            render: (id, ov) => (
              <Checkbox
                tw="-m-4"
                size="small"
                checked={selectedOvs.some((ov) => ov.id === id)}
                onChange={() =>
                  setSelectedOvs((ovs) => _.xorBy(ovs, [ov], "id"))
                }
              />
            ),
          },
          { id: "order.id", label: "Order #" },
          {
            id: "_address",
            label: "Address",
            render: (_, ov) => [ov.addressName, ov.state].join(", "),
          },
          { id: "itemTypeDescription", label: "Item Desc." },
          { id: "qty", label: "Qty", align: "right" },
        ]}
        {...tableProps}
        maxHeight={300}
      />
    </div>
  );
};

export default OrderVariantList;
