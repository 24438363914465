import { GlobalStyles } from "twin.macro";

import React from "react";
import ReactDOM from "react-dom/client";
import Helmet from "react-helmet";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import {
  ThemeProvider as MuiThemeProvider,
  StyledEngineProvider,
  createTheme,
} from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { Auth0Provider } from "@auth0/auth0-react";
import { QueryClientProvider } from "@tanstack/react-query";

import { GlobalConfirmProvider } from "@features/confirm";
import { queryClient } from "@utils/reactQuery";

import * as serviceWorker from "./serviceWorker";
import App from "./App";
import "./Index.css";
import ErrorBoundary from "./components/Utility/ErrorBoundary";
import { FilterContextWrapper } from "./features/filters";
import { store } from "./redux/store";
import DateAdapter from "./utility/DateAdapter";
import themeFile from "./utility/theme.js";

const theme = createTheme(themeFile);

// const onRedirectCallback = (appState) =>
//   navigate(appState?.returnTo ?? window.location.pathname);

const auth0ProviderConfig = {
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  redirectUri: `${window.location.origin}/login`,
  // onRedirectCallback: onRedirectCallback,
};

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <QueryClientProvider client={queryClient}>
    <Auth0Provider {...auth0ProviderConfig}>
      <StyledEngineProvider injectFirst>
        <MuiThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={DateAdapter}>
            <BrowserRouter>
              <Provider store={store}>
                <ErrorBoundary>
                  <Helmet>
                    <meta charset="utf-8" />
                    <title>Ready to Activate</title>
                    <link rel="icon" href="/favicon.ico" />
                    <meta
                      name="viewport"
                      content="width=device-width, initial-scale=1"
                    />
                    <meta name="theme-color" content="#000000" />
                    <link rel="apple-touch-icon" href="logo192.png" />
                    <link rel="manifest" href="/manifest.json" />
                    <meta
                      name="insight-app-sec-validation"
                      content="b0e47055-1c1d-42af-ac4c-c21e7b18370e"
                    />
                  </Helmet>
                  <GlobalStyles />
                  <GlobalConfirmProvider>
                    <FilterContextWrapper>
                      <App />
                    </FilterContextWrapper>
                  </GlobalConfirmProvider>
                </ErrorBoundary>
              </Provider>
            </BrowserRouter>
          </LocalizationProvider>
        </MuiThemeProvider>
      </StyledEngineProvider>
    </Auth0Provider>
  </QueryClientProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
