import React from "react";
import { useDispatch } from "react-redux";

import { Box, Button, Grid, Typography } from "@mui/material";

import { sendQuoteAction } from "../../../redux/slices/planningTool/rfqSlice";
import BasicTable from "../RFQ/BasicTable";

const QuoteSent = ({ currentQuote, classes }) => {
  const dispatch = useDispatch();
  const { id, rfqNote, pricingTierQuotes } = currentQuote;

  const handleSendAction = (action) => {
    dispatch(sendQuoteAction(action, id));
  };
  return (
    <>
      <Grid
        container
        direction="column"
        className={classes.container}
        spacing={4}
      >
        <Grid item>
          <Grid container direction="row" spacing={4}>
            <Grid item xs={12} md={6}>
              <Typography>Notes:</Typography>
              <Typography
                variant="body2"
                style={{ color: "#4C4C4C" }}
                className={classes.wrapNewlines}
              >
                {rfqNote}
              </Typography>
            </Grid>

            <Grid item xs={12} md={6}>
              <BasicTable
                rows={[
                  ["Quantities to quote"],
                  ...pricingTierQuotes.map((t) => [
                    t.qty.toLocaleString("en-US"),
                  ]),
                ]}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <br />
      <Box sx={{ background: "whitesmoke", borderRadius: "16px" }}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          className={classes.container}
          style={{
            padding: "16px",
          }}
          spacing={4}
        >
          <Button
            variant="outlined"
            className={classes.cancelBtn}
            onClick={() => handleSendAction("decline")}
          >
            Decline quote
          </Button>
          <Button
            variant="contained"
            className={classes.largeButton}
            onClick={() => handleSendAction("accept")}
          >
            Accept quote
          </Button>
        </Grid>
      </Box>
      <br />
    </>
  );
};

export default QuoteSent;
