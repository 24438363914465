import { QueryCache, QueryClient } from "@tanstack/react-query";

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error: any) => {
      // Catch 401, invalid_token errors and redirect to login
      if (error.status === 401) {
        console.error(error);
        window.location.href = "/login";
      }
    },
  }),
  defaultOptions: {
    queries: {
      // because most of our data contains circular data, we can't use this optimization
      structuralSharing: false,
      retry: false,
    },
  },
});

export default queryClient;
