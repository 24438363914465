import { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import ViewStreamIcon from "@mui/icons-material/ViewStream";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";

import PropTypes from "prop-types";

import usePreOrderPrograms from "@features/orders/preOrder/usePreOrderPrograms";

import ItemPreviewModal from "../components/ItemPreview/ItemPreviewModal";
import OrderVariantViewControl from "../components/Ordering/OrderVariantViewControl";
import ProgramDetails from "../components/Ordering/ProgramDetails";
import ItemShareModal from "../components/Utility/Modals/ItemShareModal";
import OrderPatchLoading from "../components/Utility/OrderPatchLoading";
import StepperLoader from "../components/Utility/StepperLoader";
import { useWindowHash } from "../hooks/UtilityHooks";
import { setError } from "../redux/slices/errorSlice";
import { setFiltersOpen } from "../redux/slices/filterSlice";
import { clearItemSelection } from "../redux/slices/items/itemSlice";
import {
  clearSharedItems,
  fetchSharedItemsByIds,
} from "../redux/slices/items/sharedItemsSlice";
import {
  addVariantsToPreOrder,
  fetchItemPrograms,
  fetchProgramById,
} from "../redux/slices/programs/programsSlice";
import { addToFavoriteItems } from "../redux/slices/users/userSlice";

/*
The program page gives the user detailed information on a single program, and allows them to
view the items in the program, and print a pdf of the items in the program.
*/

const useStyles = makeStyles((theme) => ({
  ...theme.global,
}));

const Program = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { programId } = useParams();

  const [value, updateValue] = useState(0);
  const [currentView, setView] = useState("list");
  const [previewModal, handlePreviewModal] = useState(false);
  const [currentItem, handleCurrentItem] = useState({});
  const [currentProgram, setCurrentProgram] = useState(null);
  const [currentLink, setCurrentLink] = useState(null);
  const [isLinkModalOpen, setLinkModalOpen] = useState(false);
  const handleChangeTab = useWindowHash(["#details", "#items"], updateValue);

  const programs = useSelector((state) => state.programs.programs);
  const adHocPrograms = useSelector((state) => state.adHocPrograms.programs);
  const adHocProgramList = useSelector(
    (state) => state.adHocPrograms.programList
  );
  const itemsLoading = useSelector((state) => state.programs.isItemsLoading);
  const adHocItemsLoading = useSelector(
    (state) => state.adHocPrograms.isItemsLoading
  );
  const selectedItems = useSelector((state) => state.items.selectedItems);
  const favoriteItems = useSelector((state) => state.user.favoriteItems);
  const currentUserRole = useSelector((state) => state.user.role);
  const isStepperLoad = useSelector(
    (state) => state.globalLoad.isProgStepperLoad
  );

  const { data } = usePreOrderPrograms(programId.split("-")[1]);
  const selected = data?.find(
    ({ program }) => program.id === programId.split("-")[0]
  );
  const preOrderId = selected?.orderSet?.id;

  useEffect(() => {
    let program;
    if (programId.split("-")[2] === "preOrder") {
      program = programs.find(
        (prog) =>
          prog.id === programId.split("-")[0] &&
          prog.orderMonthId === programId.split("-")[1]
      );
    } else if (programId.split("-")[2] === "settings") {
      program = adHocProgramList.find(
        (prog) =>
          prog.id === programId.split("-")[0] &&
          prog.orderMonthId === programId.split("-")[1]
      );
    } else {
      program = adHocPrograms.find(
        (prog) =>
          prog.id === programId.split("-")[0] &&
          prog.orderMonthId === programId.split("-")[1]
      );
    }

    if (program) {
      setCurrentProgram(program);
      if (program.items.length === 0 && !program.isItemsFetched) {
        dispatch(
          fetchItemPrograms(programId.split("-")[0], program.orderMonthId)
        );
      }
    } else {
      dispatch(fetchProgramById(programId.split("-")[0]));
    }
  }, [
    programId,
    setCurrentProgram,
    programs,
    adHocPrograms,
    dispatch,
    adHocProgramList,
  ]);

  const handlePreview = (itemNumber) => {
    let item = currentProgram.items.find(
      (item) => item.itemNumber === itemNumber
    );
    handleCurrentItem(item);
    handlePreviewModal(true);
  };

  const handleModalClose = () => {
    handlePreviewModal(false);
  };

  const handleShareClose = () => {
    setLinkModalOpen(false);
    dispatch(clearItemSelection());
  };

  const handleAddItem = (id, variants, preOrderVariants) => {
    if (programId.split("-")[2] === "settings") {
      return dispatch(
        setError({
          error: "Items cannot be ordered while in preview.",
          source: "Pre Order Programs",
        })
      );
    }
    let currentIds = preOrderVariants
      .filter((v) => v.itemId === id)
      .map((v) => v.itemNumber);
    let variantsToAdd =
      variants.length === 1 && !variants[0].name
        ? variants
        : variants
            .filter((v) => v.name)
            .filter((v) => !currentIds.includes(v.sku));

    if (preOrderId) {
      dispatch(
        addVariantsToPreOrder(
          preOrderId,
          variantsToAdd.map((v) => v.id)
        )
      );
    } else {
      dispatch(
        setError({
          error:
            "You currently do not have a pre order generated for this program, please click Order to set up your pre order.",
          source: "Pre Order Programs",
        })
      );
    }
  };

  const handleFavoriteItems = () => {
    const uniqueArray = [
      ...new Set(selectedItems.concat(favoriteItems.map((i) => i.id))),
    ];
    if (uniqueArray.length > 0) {
      dispatch(addToFavoriteItems(uniqueArray));
    }
    dispatch(clearItemSelection());
  };

  const handleShareLink = () => {
    dispatch(clearSharedItems());
    const baseUrl = window.location.origin;
    let urlString = `${baseUrl}/shared/items/${selectedItems.join("-")}`;
    dispatch(fetchSharedItemsByIds(selectedItems));
    setCurrentLink(urlString);
    setLinkModalOpen(true);
  };

  useEffect(() => {
    dispatch(setFiltersOpen({ open: false }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isStepperLoad) {
    return <StepperLoader isProgStepper={true} />;
  }

  if (!currentProgram) {
    return <CircularProgress />;
  }

  return (
    <>
      <Helmet>
        <title>RTA | Program</title>
      </Helmet>
      {isLinkModalOpen && (
        <ItemShareModal
          modalOpen={isLinkModalOpen}
          handleClose={handleShareClose}
          shareLink={currentLink}
        />
      )}
      {previewModal && currentItem && (
        <ItemPreviewModal
          type="program"
          currentItem={currentItem}
          handleClose={handleModalClose}
          previewModal={previewModal}
        />
      )}
      <Container className={classes.mainWrapper}>
        <div className={classes.titleBar}>
          <div className={classes.titleImage}>
            <Tooltip title="Back to All Programs" placement="bottom-start">
              <IconButton component={Link} to={"/programs"} size="large">
                <ArrowBackIcon fontSize="large" color="secondary" />
              </IconButton>
            </Tooltip>
            <Typography
              className={classes.titleText}
              style={{ marginLeft: "5px", marginTop: "5px" }}
            >
              {programId.split("-")[2] === "preOrder"
                ? `${currentProgram.name} - ${currentProgram.focusMonth}`
                : `${currentProgram.name}`}
            </Typography>
          </div>
          <div className={classes.configButtons}>
            <div className={classes.innerConfigDiv}>
              {programId.split("-")[2] !== "settings" && (
                <Button
                  className={classes.largeButton}
                  style={{ marginRight: value !== 0 ? "20px" : "0px" }}
                  variant="contained"
                  color="secondary"
                  startIcon={<ExitToAppIcon />}
                  component={Link}
                  to={`/orders/${currentProgram.orderMonthId}/${currentProgram.id}`}
                >
                  ORDER
                </Button>
              )}
              {value !== 0 && (
                <>
                  {!["read-only", "compliance"].includes(currentUserRole) && (
                    <Button
                      className={classes.largeButton}
                      style={{ marginRight: "20px" }}
                      variant="contained"
                      color="secondary"
                      disabled={selectedItems.length === 0}
                      onClick={handleFavoriteItems}
                    >
                      ADD TO FAVORITES
                    </Button>
                  )}
                  <Button
                    className={classes.largeButton}
                    style={{ marginRight: "20px" }}
                    variant="contained"
                    color="secondary"
                    disabled={selectedItems.length === 0}
                    onClick={handleShareLink}
                  >
                    CREATE PDF
                  </Button>
                  <Tooltip title="View List">
                    <IconButton
                      onClick={() => {
                        setView("list");
                      }}
                      size="large"
                    >
                      <ViewStreamIcon
                        fontSize="large"
                        color={currentView === "list" ? "primary" : "inherit"}
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="View Grid">
                    <IconButton
                      onClick={() => {
                        setView("grid");
                      }}
                      size="large"
                    >
                      <ViewModuleIcon
                        fontSize="large"
                        color={currentView === "grid" ? "primary" : "inherit"}
                      />
                    </IconButton>
                  </Tooltip>
                </>
              )}
            </div>
          </div>
        </div>
        <Tabs value={value} onChange={handleChangeTab} indicatorColor="primary">
          <Tab className={classes.headerText} label="Details" value={0} />
          <Tab className={classes.headerText} label="Items" value={1} />
        </Tabs>
        <Divider />
        <br />
        <br />

        {value === 0 && <ProgramDetails program={currentProgram} />}
        {value === 1 && (
          <>
            {itemsLoading ? (
              <CircularProgress />
            ) : (
              <OrderVariantViewControl
                type={"catalog"}
                currentView={currentView}
                handlePreview={handlePreview}
                items={currentProgram.items}
                isItemsLoading={itemsLoading || adHocItemsLoading}
                addPreOrderVariant={preOrderId ? handleAddItem : undefined}
                isAdHoc={currentProgram.isAdHoc}
              />
            )}
          </>
        )}
      </Container>
      <br />
      <OrderPatchLoading />
    </>
  );
};

Program.propTypes = {
  programId: PropTypes.string,
};

export default Program;
