import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import CancelIcon from "@mui/icons-material/Cancel";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";

import PropTypes from "prop-types";

import {
  clearAddressList,
  fetchAddresses,
} from "../../../redux/slices/addresses/addressSlice";
import { setOrderHasUpdated } from "../../../redux/slices/ordering/orderHistorySlice";
import { updateOrderAddress } from "../../../redux/slices/patchOrderSlice";
import { fetchAllCompliantStates } from "../../../redux/slices/territories/territorySlice";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
  popperIndex: {
    zIndex: "16000",
  },
}));

const EditOrderAddressModal = ({ modalOpen, handleClose, order }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [address, setAddress] = useState("");
  const [selectedAddress, setSelectedAddress] = useState(null);

  const options = useSelector((state) => state.addresses.addressList);
  const { compliantStateList, isStatesLoading } = useSelector(
    (state) => state.territories
  );
  const isLoading = useSelector((state) => state.addresses.isLoading);
  const isPatchLoading = useSelector((state) => state.patchOrder.isLoading);
  const hasUpdated = useSelector(
    (state) => state.orderHistory.singleOrder.hasUpdated
  );

  const loading = open && isLoading;

  const debounce = useRef(null);

  const handleAddresses = (value) => {
    setSelectedAddress(value);
  };

  const handleQuery = useCallback(() => {
    clearTimeout(debounce.current);

    debounce.current = setTimeout(() => {
      dispatch(
        fetchAddresses(
          address,
          null,
          compliantStateList.map((st) => st.id).join(","),
          false,
          null
        )
      );
    }, 250);
  }, [address, compliantStateList, dispatch]);

  const handleAddressUpdate = () => {
    dispatch(updateOrderAddress(order.id, selectedAddress.id));
  };

  useEffect(() => {
    if (address.length >= 1) {
      handleQuery();
    }
  }, [address, handleQuery, dispatch]);

  useEffect(() => {
    dispatch(fetchAllCompliantStates(order.id, "order"));
    dispatch(clearAddressList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.relativeContainer}>
      <Dialog
        open={modalOpen}
        disableScrollLock
        onClose={() => {
          dispatch(setOrderHasUpdated({ value: false, newOrderId: null }));
          setSelectedAddress(null);
          handleClose();
        }}
        fullWidth
        maxWidth="sm"
        style={{ zIndex: "15000" }}
      >
        <DialogContent>
          {(isStatesLoading || isPatchLoading) && (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingTop: "100px",
                paddingBottom: "100px",
              }}
            >
              <CircularProgress />
            </div>
          )}
          {hasUpdated && (
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                paddingTop: "100px",
                paddingBottom: "100px",
                textAlign: "center",
              }}
            >
              <Typography className={classes.headerText}>
                Update Successful!
              </Typography>
              <br />
              <br />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  className={classes.largeButton}
                  variant="contained"
                  color="secondary"
                  style={{ marginRight: "20px" }}
                  onClick={() =>
                    dispatch(
                      setOrderHasUpdated({ value: false, newOrderId: null })
                    )
                  }
                >
                  CONTINUE EDITING
                </Button>
                <Button
                  className={classes.largeButton}
                  variant="contained"
                  color="secondary"
                  style={{ marginRight: "20px" }}
                  onClick={() => {
                    dispatch(
                      setOrderHasUpdated({ value: false, newOrderId: null })
                    );
                    setSelectedAddress(null);
                    handleClose();
                  }}
                >
                  EXIT
                </Button>
              </div>
            </div>
          )}
          {!isStatesLoading && !isPatchLoading && !hasUpdated && (
            <>
              <IconButton
                className={classes.closeButton}
                onClick={() => {
                  setSelectedAddress(null);
                  handleClose();
                }}
                size="large"
              >
                <CancelIcon fontSize="large" color="secondary" />
              </IconButton>
              <div className={classes.flexColumnModal}>
                <br />
                <Divider />
                <br />
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                >
                  <Typography className={classes.headerText}>
                    Current Address:
                  </Typography>
                  <br />
                  <Typography className={classes.bodyText}>
                    {order.address.name}
                  </Typography>
                  <Typography className={classes.bodyText}>
                    {order.address.streetAddress1}
                  </Typography>
                  {order.address.streetAddress2 && (
                    <Typography className={classes.bodyText}>
                      {order.address.streetAddress2}
                    </Typography>
                  )}
                  <Typography className={classes.bodyText}>
                    {`${order.address.city}, ${order.address.state.code}, ${order.address.zip}`}
                  </Typography>
                </div>
                <br />
                <br />
                <Autocomplete
                  fullWidth
                  autoHighlight
                  className={classes.queryField}
                  id="address-auto-complete"
                  open={open}
                  onOpen={() => setOpen(true)}
                  onClose={() => setOpen(false)}
                  inputValue={address}
                  onInputChange={(_evt, value) => setAddress(value)}
                  onChange={(evt, value) => {
                    handleAddresses(value);
                  }}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) =>
                    option.name === value.name
                  }
                  options={options}
                  loading={loading}
                  classes={{ popper: classes.popperIndex }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Name / ABN"
                      variant="outlined"
                      size="small"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {loading ? (
                              <CircularProgress
                                color="inherit"
                                size={15}
                                style={{
                                  marginRight: "-12px",
                                  padding: "12px",
                                }}
                              />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />
                <br />
                <br />
                {selectedAddress && (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      textAlign: "center",
                    }}
                  >
                    <Typography className={classes.headerText}>
                      Selected Address:
                    </Typography>
                    <Typography className={classes.bodyText}>
                      {selectedAddress.name}
                    </Typography>
                    <Typography className={classes.bodyText}>
                      {selectedAddress.streetAddressOne}
                    </Typography>
                    {selectedAddress.addressStreetTwo && (
                      <Typography className={classes.bodyText}>
                        {selectedAddress.streetAddressTwo}
                      </Typography>
                    )}
                    {selectedAddress.attn !== "---" && (
                      <Typography className={classes.bodyText}>
                        {`Attn: ${selectedAddress.attn}`}
                      </Typography>
                    )}
                    <Typography className={classes.bodyText}>
                      {`${selectedAddress.city}, ${selectedAddress.stateCode}, ${selectedAddress.zip}`}
                    </Typography>
                    <br />
                    <br />
                  </div>
                )}
                <Button
                  className={classes.largeButton}
                  variant="contained"
                  color="secondary"
                  onClick={() => handleAddressUpdate()}
                >
                  UPDATE ADDRESS
                </Button>
                <br />
              </div>
            </>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

EditOrderAddressModal.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  order: PropTypes.object,
};

export default React.memo(EditOrderAddressModal);
