/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import { useSelector } from "react-redux";

import { useProgramInfo } from "src/hooks/planningToolHooks";

import CollapsibleTable from "../../CollapsibleTable";
import { SmallIndexCounter } from "../../StyledComponents";
import ScrollIntoView from "../../Utility/ScrollIntoView";
import NoItemsToShow from "./NoItemsToShow";
import TemplatedCouponEdit from "./TemplatedCouponEdit";
import TemplatedCouponNew from "./TemplatedCouponNew";

const TemplatedCouponSection = ({ items, countOffset }) => {
  const { isLoading } = useSelector((state) => state.itemPrograms);

  const { brandAssignmentLevel } = useProgramInfo();
  const whenItemBrands = (col) =>
    brandAssignmentLevel === "item" ? [col] : [];

  return (
    <>
      <TemplatedCouponNew />
      {!isLoading && items.length > 0 ? (
        <CollapsibleTable
          unmountOnExit={false}
          headers={[
            { cell: "Sequence #" },
            ...whenItemBrands({ cell: "Brand" }),
            { cell: "Item Type" },
            { cell: "Description" },
            { cell: "Status" },
            { cell: "" },
          ]}
          rows={items.map(({ openItemRow, ...item }, i) => ({
            key: item.id,
            row: [
              {
                cell: item.itemNumber || "---",
              },
              ...whenItemBrands({
                cell: item.brands.map((i) => i.name).join(", "),
              }),
              { cell: item.type },
              {
                cell: item.comment || "",
              },
              {
                cell: item.itemStatus || "",
              },
              {
                cell: openItemRow ? (
                  <ScrollIntoView trigger={openItemRow} />
                ) : (
                  ""
                ),
              },
              {
                cell: (
                  <SmallIndexCounter>{countOffset + i + 1}</SmallIndexCounter>
                ),
                style: tw`absolute inset-0 left-auto border-0`,
              },
            ],
            content: <TemplatedCouponEdit item={item} />,
            expanded: openItemRow,
          }))}
        />
      ) : (
        <NoItemsToShow name="templated coupons" loading={isLoading} />
      )}
    </>
  );
};

export default TemplatedCouponSection;
