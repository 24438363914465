/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { useDispatch, useSelector } from "react-redux";

import GetAppIcon from "@mui/icons-material/GetApp";
import {
  Button,
  Chip,
  CircularProgress,
  Container,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import BackButton from "src/components/Reporting/BackButton";

import { CSVLink } from "@utils/csv";

import ReportTable from "../components/Reporting/ReportTable";
import ProgramAutoComplete from "../components/Utility/AutoCompleteFields/ProgramAutoComplete";
import OrderWindowSelector from "../components/Utility/Selectors/OrderWindowSelector";
import { useReportCsv } from "../hooks/Reports/useReportCsv";
import { useNoFetch } from "../hooks/UtilityHooks";
import {
  resetFilters,
  setFiltersOpen,
  updateSingleFilter,
} from "../redux/slices/filterSlice";
import {
  clearReports,
  getOrderWindowItemsReport,
} from "../redux/slices/reports/reportSlice";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
  ...theme.reports,
}));

const ReportOrderWindowItems = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [hasGenerated, setHasGenerated] = useState(false);
  const [orderWindows, setOrderWindows] = useState([]);
  const [currentPrograms, setCurrentPrograms] = useState([]);
  const [reset, setReset] = useState(false);

  const isLoading = useSelector((state) => state.reports.isLoading);
  const report = useSelector((state) => state.reports.reportData);
  const reportType = useSelector((state) => state.reports.reportType);
  const currentProgramFilter = useSelector((state) => state.filters.program);
  const currentChannel = useSelector((state) => state.user.currentChannel);

  const { setCsvData, data, headers } = useReportCsv(
    "order-window-items",
    hasGenerated,
    setHasGenerated,
    isLoading,
    report
  );

  const handleProgram = (value, _type, _filter) => {
    setCurrentPrograms(value);
    dispatch(updateSingleFilter({ filter: "program", value: value }));
  };

  const handleProgramChipClick = (id) => {
    let currentProgArray = currentProgramFilter.filter(
      (prog) => prog.id !== id
    );
    setCurrentPrograms(currentProgArray);
    dispatch(
      updateSingleFilter({ filter: "program", value: currentProgArray })
    );
  };

  const handleReset = () => {
    setOrderWindows([]);
    setCurrentPrograms([]);
    dispatch(clearReports());
    dispatch(resetFilters());
    setCsvData({ data: [], headers: [] });
    setReset(true);
  };

  const handleGetReport = () => {
    const filterObject = {
      program: currentPrograms,
      ocmIds: orderWindows.join(","),
      channel: currentChannel,
    };
    dispatch(getOrderWindowItemsReport(filterObject));
    setHasGenerated(true);
  };

  useEffect(() => {
    dispatch(setFiltersOpen({ open: false }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useNoFetch(dispatch);

  useEffect(() => {
    dispatch(clearReports());
    dispatch(resetFilters());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>RTA | Order Window Items</title>
      </Helmet>
      <Container className={classes.mainWrapper}>
        <div className={classes.titleBar}>
          <Typography className={classes.titleText}>
            Order Window Items Report
          </Typography>
          <div className={classes.configButtons}>
            <div className={classes.innerConfigDiv}>
              <div tw="mr-2">
                <BackButton />
              </div>
              <CSVLink
                data={data}
                headers={headers}
                filename="order_window_items.csv"
              >
                <Button
                  className={classes.largeButton}
                  variant="contained"
                  color="secondary"
                  startIcon={<GetAppIcon />}
                  disabled={data.length === 0}
                >
                  EXPORT REPORT
                </Button>
              </CSVLink>
            </div>
          </div>
        </div>
        <br />
        <div tw="flex flex-col items-center gap-2">
          <OrderWindowSelector
            multiple
            orderWindow={orderWindows}
            setOrderWindow={setOrderWindows}
          />
          <ProgramAutoComplete
            classes={classes}
            handleChange={handleProgram}
            reset={reset}
            setReset={setReset}
            filterType="report"
            id="report-program-complete"
          />
        </div>
        <br />
        <div style={{ width: "100%", textAlign: "center" }}>
          <Typography
            className={classes.headerText}
            style={{ marginRight: "10px" }}
          >
            Filters:
          </Typography>
        </div>
        <div className={classes.chipRow}>
          {currentPrograms.length > 0 &&
            currentPrograms.map((p) => (
              <Chip
                style={{ margin: "auto 2.5px" }}
                color="primary"
                key={p.id}
                label={p.name}
                onDelete={() => handleProgramChipClick(p.id)}
              />
            ))}
        </div>
        <br />
        <div className={classes.buttonRow}>
          <Button
            className={classes.largeButton}
            variant="contained"
            color="secondary"
            onClick={() => handleGetReport()}
            style={{ marginRight: "20px" }}
          >
            GENERATE REPORT
          </Button>
          <Button
            className={classes.largeButton}
            variant="contained"
            color="secondary"
            onClick={handleReset}
          >
            CLEAR FILTERS
          </Button>
        </div>
        <br />
        <br />
        {isLoading && (
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <CircularProgress size={100} style={{ marginTop: "50px" }} />
          </div>
        )}
        {!isLoading &&
          report.length > 0 &&
          reportType === "order-window-items" && (
            <ReportTable report={report} type="order-window-items" />
          )}
      </Container>
    </>
  );
};

ReportOrderWindowItems.propTypes = {};

export default ReportOrderWindowItems;
