import { createSlice } from "@reduxjs/toolkit";

import { axiosGet, axiosPatch, axiosPost } from "../../../api/axiosCalls";
import { setError } from "../errorSlice";
import {
  setFailure as patchFailure,
  setIsLoading as patchLoading,
  patchSuccess,
} from "../patchOrderSlice";
import {
  buildSupplier,
  buildSupplierUserUpdate,
  fetchInitialSupplierValues,
} from "./helpers";
import { mapSuppliers } from "./maps";

let initialState = {
  isLoading: false,
  isInitialLoading: false,
  isUpdateLoading: false,
  navValues: {
    newRFQ: 0,
    inProgressRFQ: 0,
    completedRFQ: 0,
    newRFP: 0,
    inProgressRFP: 0,
    awardedRFP: 0,
    newPO: 0,
    inProgressPO: 0,
    shipHoldPO: 0,
  },
  supplierList: [],
  filteredSupplierList: [],
  updateStatus: false,
  error: null,
};

const loadingFailed = (state, action) => {
  const { error } = action.payload;
  state.isLoading = false;
  state.isInitialLoading = false;
  state.isUpdateLoading = false;
  state.error = error;
};

const supplierSlice = createSlice({
  name: "suppliers",
  initialState,
  reducers: {
    setIsLoading(state) {
      state.isLoading = true;
    },
    setInitialLoading(state) {
      state.isInitialLoading = true;
    },
    setIsUpdateLoading(state) {
      state.isUpdateLoading = true;
    },
    getSuppliersSuccess(state, action) {
      const { suppliers } = action.payload;
      state.supplierList = suppliers;
      state.isLoading = false;
      state.error = null;
    },
    getFilteredSuppliersSuccess(state, action) {
      const { suppliers } = action.payload;
      state.filteredSupplierList = suppliers;
      state.isLoading = false;
      state.error = null;
    },
    getNavValueSuccess(state, action) {
      const { navValues } = action.payload;
      state.navValues = { ...navValues };
      state.isInitialLoading = false;
      state.error = null;
    },
    updateValues(state, action) {
      const { values } = action.payload;
      let currentValues = { ...state.navValues };
      values.forEach((val) => {
        currentValues[val.key] = currentValues[val.key] + val.value;
      });
      state.navValues = { ...currentValues };
    },
    setAllSuppliersList(state) {
      let allSuppliers = [...state.supplierList];
      state.filteredSupplierList = allSuppliers;
    },
    createSupplierSuccess(state, action) {
      const { supplier } = action.payload;
      let newSuppliers = state.filteredSupplierList.concat(supplier);
      let newAllSuppliers = state.supplierList.concat(supplier);
      state.filteredSupplierList = newSuppliers;
      state.supplierList = newAllSuppliers;
      state.isUpdateLoading = false;
      state.updateStatus = true;
      state.error = null;
    },
    updateSupplierSuccess(state, action) {
      const { supplier } = action.payload;
      let updatedSuppliers = state.filteredSupplierList.map((sup) => {
        if (sup.id === supplier.id) {
          return supplier;
        } else return { ...sup };
      });
      let updatedAllSuppliers = state.supplierList.map((sup) => {
        if (sup.id === supplier.id) {
          return supplier;
        } else return { ...sup };
      });
      if (!supplier.isActive) {
        updatedAllSuppliers = updatedAllSuppliers.filter(
          (sup) => sup.id !== supplier.id
        );
      }
      state.filteredSupplierList = updatedSuppliers;
      state.supplierList = updatedAllSuppliers;
      state.isUpdateLoading = false;
      state.updateStatus = true;
      state.error = null;
    },
    setUpdateSuccess(state, action) {
      const { updateStatus } = action.payload;
      state.updateStatus = updateStatus;
    },
    clearSuppliers(state) {
      state.isLoading = false;
      state.isInitialLoading = false;
      state.isUpdateLoading = false;
      state.navValues.newRFP = 0;
      state.navValues.inProgressRFP = 0;
      state.navValues.awardedRFP = 0;
      state.navValues.newPO = 0;
      state.navValues.inProgressPO = 0;
      state.navValues.shipHoldPO = 0;
      state.supplierList = [];
      state.filteredSupplierList = [];
      state.updateStatus = false;
      state.error = null;
    },
    setFailure: loadingFailed,
  },
});

export const {
  setIsLoading,
  setIsUpdateLoading,
  setInitialLoading,
  getSuppliersSuccess,
  getFilteredSuppliersSuccess,
  getNavValueSuccess,
  updateValues,
  setAllSuppliersList,
  createSupplierSuccess,
  updateSupplierSuccess,
  setUpdateSuccess,
  clearSuppliers,
  setFailure,
} = supplierSlice.actions;

export default supplierSlice.reducer;

export const fetchAllSuppliers = () => async (dispatch) => {
  try {
    dispatch(setIsLoading());
    const response = await axiosGet("/api/suppliers?filter[is-active]=true");
    if (response.error) throw response.error;
    const mappedData = mapSuppliers(response.data).sort((a, b) => {
      return +a.id < +b.id ? -1 : +a.id > +b.id ? 1 : 0;
    });
    dispatch(getSuppliersSuccess({ suppliers: mappedData }));
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Suppliers" }));
  }
};

export const fetchSuppliersByName =
  (name, isActive = true) =>
  async (dispatch) => {
    try {
      dispatch(setIsLoading());
      const response = await axiosGet(
        `/api/suppliers?filter[name]=${name}&filter[is-active]=${isActive}`
      );
      if (response.error) throw response.error;
      const mappedData = mapSuppliers(response.data);
      dispatch(getFilteredSuppliersSuccess({ suppliers: mappedData }));
    } catch (err) {
      dispatch(setFailure({ error: err.toString() }));
      dispatch(setError({ error: err.toString(), source: "Suppliers" }));
    }
  };

export const fetchInitialValues = () => async (dispatch) => {
  try {
    dispatch(setInitialLoading());
    const response = await fetchInitialSupplierValues();
    if (response.error) throw response.error;
    dispatch(getNavValueSuccess({ navValues: response.data }));
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Suppliers" }));
  }
};

export const addNewSupplier =
  ({
    externalId,
    name,
    isPurchaserSelect,
    goesToRFP,
    isActive,
    sapId,
    users,
  }) =>
  async (dispatch) => {
    try {
      dispatch(patchLoading());
      dispatch(setIsUpdateLoading());
      const postData = buildSupplier({
        externalId,
        name,
        isPurchaserSelect,
        goesToRFP,
        isActive,
        sapId,
      });
      const response = await axiosPost("/api/suppliers", postData);
      if (response.error) throw response.error;
      for (let i = 0; i < users.length; i++) {
        let patchData = buildSupplierUserUpdate(
          users[i].id,
          response.data.id,
          users[i].states,
          users[i].territories
        );
        let userResponse = await axiosPatch(
          `/api/users/${users[i].id}`,
          patchData
        );
        if (userResponse.error) throw userResponse.error;
      }
      const supplierResponse = await axiosGet(
        `/api/suppliers/${response.data.id}`
      );
      if (supplierResponse.error) throw supplierResponse.error;
      const mappedData = mapSuppliers([supplierResponse.data]);
      dispatch(createSupplierSuccess({ supplier: mappedData[0] }));
      dispatch(patchSuccess());
    } catch (err) {
      dispatch(setFailure({ error: err.toString() }));
      dispatch(patchFailure({ error: err.toString() }));
      dispatch(setError({ error: err.toString(), source: "Suppliers" }));
    }
  };

export const updateSupplierById =
  (
    id,
    { externalId, name, isPurchaserSelect, goesToRFP, isActive, users, sapId }
  ) =>
  async (dispatch) => {
    try {
      dispatch(patchLoading());
      dispatch(setIsUpdateLoading());
      const patchData = buildSupplier({
        externalId,
        name,
        isPurchaserSelect,
        goesToRFP,
        isActive,
        sapId,
      });
      const response = await axiosPatch(`/api/suppliers/${id}`, patchData);
      if (response.error) throw response.error;
      for (let i = 0; i < users.length; i++) {
        if (users[i].supplierId !== id) {
          let patchData = buildSupplierUserUpdate(
            users[i].id,
            response.data.id,
            users[i].states,
            users[i].territories
          );
          let userResponse = await axiosPatch(
            `/api/users/${users[i].id}`,
            patchData
          );
          if (userResponse.error) throw userResponse.error;
        }
      }
      const supplierResponse = await axiosGet(
        `/api/suppliers/${response.data.id}`
      );
      if (supplierResponse.error) throw supplierResponse.error;
      const mappedData = mapSuppliers([supplierResponse.data]);
      dispatch(updateSupplierSuccess({ supplier: mappedData[0] }));
      dispatch(patchSuccess());
    } catch (err) {
      dispatch(setFailure({ error: err.toString() }));
      dispatch(patchFailure({ error: err.toString() }));
      dispatch(setError({ error: err.toString(), source: "Suppliers" }));
    }
  };
