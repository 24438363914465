import { Order, OrderSet, OrderSetStatus, OrderType, Variant } from "@models";

type HasShipping =
  | {
      totalEstimatedShippingCost: string | number;
      totalEstimatedFreight?: never;
    }
  | {
      totalEstimatedShippingCost?: never;
      totalEstimatedFreight: string | number;
    };

type HasTaxes =
  | {
      totalEstimatedTax: string | number;
      totalEstimatedTaxes?: never;
    }
  | {
      totalEstimatedTax?: never;
      totalEstimatedTaxes: string | number;
    };

type EntityWithCost = HasShipping &
  HasTaxes & {
    totalEstimatedCost: string | number;
    totalBeaconCost?: string | number;
    totalActualCost?: string | number;
    [key: string]: any;
  };

// Insures a number is returned
const n = (v: unknown) => (v ? +v : 0);

export const getExtendedCost = (e: EntityWithCost, orderType?: OrderType) =>
  n(e.totalEstimatedShippingCost) +
  n(e.totalEstimatedFreight) +
  n(e.totalEstimatedTax) +
  n(e.totalEstimatedTaxes) +
  n(e.totalBeaconCost) +
  (orderType === "from-inventory"
    ? 0
    : n(e.totalActualCost) === 0
    ? n(e.totalEstimatedCost)
    : n(e.totalActualCost));

export const getVariantName = (variant: Variant) =>
  variant.selectedVariantOptions.map((option) => option.name).join(" / ");

export const displayOrderSetStatus = (
  status: OrderSetStatus,
  orderSet?: OrderSet
) => {
  if (orderSet?.isWorkComplete && status === "in-progress")
    return "Work Complete";

  const t: Record<OrderSetStatus, string> = {
    inactive: "Not Started",
    "in-progress": "In Progress",
    submitted: "Submitted",
    approved: "Approved",
    canceled: "Canceled",
    "partial-approval-error": "Partial Approval Error",
  };
  return t[status];
};

export const displayToInventoryAllocation = (order: Order) =>
  order.type === "to-inventory"
    ? order.subState
      ? order.subState.name
      : order.territory
      ? order.territoryName
      : "National"
    : null;
