import { format } from "date-fns";

import { createSlice } from "@reduxjs/toolkit";

import {
  axiosDelete,
  axiosGet,
  axiosGetWithNext,
  axiosPost,
  axiosPut,
} from "../../../api/axiosCalls";
import { setError } from "../errorSlice";
import {
  setRedirect,
  startGlobalLoad,
  stopGlobalLoad,
} from "../globalLoadSlice";
import {
  setFailure as patchFailure,
  setIsLoading as patchLoading,
  patchSuccess,
} from "../patchOrderSlice";
import { buildNote } from "./helpers";
import { mapNotes } from "./maps";

let unsetCurrentNote = {
  note: null,
  userName: null,
  updatedAt: null,
};

let initialState = {
  isLoading: false,
  isNextLoading: false,
  isSingleLoading: false,
  isUpdateLoading: false,
  currentNote: { ...unsetCurrentNote },
  notes: [],
  isNotesLoading: false,
  error: null,
};

const loadingFailed = (state, action) => {
  const { error } = action.payload;
  state.isLoading = false;
  state.isNotesLoading = false;
  state.isNextLoading = false;
  state.isSingleLoading = false;
  state.isUpdateLoading = false;
  state.error = error;
};

const noteSlice = createSlice({
  name: "note",
  initialState,
  reducers: {
    setIsLoading(state) {
      state.isLoading = true;
    },
    setNextIsLoading(state) {
      state.isNextLoading = true;
    },
    setIsUpdateLoading(state) {
      state.isUpdateLoading = true;
    },
    setIsSingleLoading(state) {
      state.isSingleLoading = true;
    },
    createNoteSuccess(state, action) {
      const { note } = action.payload;
      let currentNotes = [...state.notes];
      let updatedNotes = currentNotes.concat(note);
      state.notes = updatedNotes;
      state.isUpdateLoading = false;
      state.error = null;
    },
    getNotesSuccess(state, action) {
      const { notes } = action.payload;
      state.notes = [...notes];
      state.pagesLoaded += 1;
      // state.nextLink = nextLink;
      state.isLoading = false;
      state.error = null;
    },
    deleteNoteSuccess(state, action) {
      const { id } = action.payload;
      state.notes = state.notes.filter((n) => n.id !== id);
      state.isUpdateLoading = false;
      state.error = null;
    },
    updateNoteSuccess(state, action) {
      const { note } = action.payload;
      let currentNotes = [...state.notes];
      let updatedNotes = currentNotes.concat(note);
      state.notes = updatedNotes;
      state.isLoading = false;
    },
    clearNotes(state) {
      state.notes = [];
      state.isLoading = false;
    },
    clearNextLink(state) {
      state.nextLink = null;
    },
    clearIsSingleLoading(state) {
      state.isSingleLoading = false;
    },
    clearIsUpdateLoading(state) {
      state.isUpdateLoading = false;
    },
    clearSingleProgram(state) {
      state.currentNote = { ...unsetCurrentNote };
    },
    clearIsNextLoading(state) {
      state.isNextLoading = false;
    },
    clearIsLoading(state) {
      state.isLoading = false;
    },
    setFailure: loadingFailed,
  },
});

export const {
  updateNoteSuccess,
  createNoteSuccess,
  getNotesSuccess,
  deleteNoteSuccess,
  setIsSingleLoading,
  setIsUpdateLoading,
  setNextIsLoading,
  setIsLoading,
  clearNotes,
  clearNextLink,
  clearIsLoading,
  clearIsSingleLoading,
  clearIsUpdateLoading,
  clearSingleProgram,
  clearIsNextLoading,
  setFailure,
} = noteSlice.actions;

export default noteSlice.reducer;

export const createNote = (id, note, maybeCallback) => async (dispatch) => {
  try {
    dispatch(patchLoading());
    dispatch(setIsUpdateLoading());
    const postData = buildNote(id, note);
    const response = await axiosPost(`/api/notes`, postData);
    if (response.error) throw response.error;
    const mappedData = {
      id: response.data.id,
      note: response.data.message,
      userName: response.data["user-name"],
      updatedAt: response.data["updated-at"],
    };
    dispatch(createNoteSuccess({ note: mappedData }));
    dispatch(patchSuccess());
    maybeCallback?.();
  } catch (err) {
    console.log(err);
    dispatch(setFailure({ error: err.toString() }));
    dispatch(patchFailure({ error: err.toString() }));
    dispatch(
      setError({ error: err.toString(), source: "Program Note Update" })
    );
  }
};

export const fetchNotes = (programId) => async (dispatch) => {
  try {
    dispatch(setIsLoading());
    const notes = await axiosGet(`/api/notes?filter[program_id]=${programId}`);
    if (notes.error) throw notes.error;
    const mappedNotes = mapNotes(notes.data);
    dispatch(getNotesSuccess({ notes: mappedNotes }));
    dispatch(clearIsLoading);
  } catch (err) {
    console.log(err);
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Fetch Notes" }));
    dispatch(stopGlobalLoad());
  }
};

export const deleteNote = (id) => async (dispatch) => {
  try {
    dispatch(setIsLoading());
    const response = await axiosDelete(`/api/notes/${id}`, {});
    if (response.error) throw response.error;
    dispatch(deleteNoteSuccess({ id: id }));
    dispatch(clearIsLoading());
  } catch (err) {
    console.log(err);
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Delete Note" }));
    dispatch(stopGlobalLoad());
  }
};
