import { createQueryKeys } from "@lukemorales/query-key-factory";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { kebabCaseKeys } from "src/utility/utilityFunctions";

import { VariantAllocation } from "@models/VariantAllocation";
import { VariantAllocationHistory } from "@models/VariantAllocationHistory";
import client from "@services/api";
import { buildPaginatedQuery } from "@utils/reactQuery";

import { itemsKeyFactory } from "./itemQueries";

export const variantAllocationHistoryKeyFactory = createQueryKeys(
  "variant-allocation-history",
  {
    paginated: (params) => ({
      queryKey: [params],
      queryFn: () =>
        client.get<VariantAllocationHistory[]>("variant-allocation-history", {
          params,
        }),
    }),
  }
);

export const useVariantAllocationHistoryPaginated = buildPaginatedQuery(
  variantAllocationHistoryKeyFactory.paginated
);

type TransferAllocationPayload = {
  itemId: string;
  /** The variant ID */
  id: string;

  /** The qty to transfer */
  qty: number;

  /** The donor allocation, if both are null, donor is National */
  donorTerritoryId?: string;
  donorSubStateId?: string;

  /** The recipient allocation, if both are null, recipient is National */
  recipientTerritoryId?: string;
  recipientSubStateId?: string;
};
export const useTransferVariantAllocationMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ itemId: _, id, ...data }: TransferAllocationPayload) =>
      client.put<VariantAllocation>(
        `variants/${id}/transfer-allocation`,
        kebabCaseKeys(data),
        { serializeBody: false }
      ),
    onSettled: (_res, _err, { itemId }) => {
      queryClient.invalidateQueries({
        queryKey: itemsKeyFactory.detail(itemId).queryKey,
      });
    },
  });
};
