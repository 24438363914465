import { createQueryKeys } from "@lukemorales/query-key-factory";
import { keepPreviousData } from "@tanstack/react-query";
import _ from "lodash";

import { User } from "@models";
import client from "@services/api";
import { buildPaginatedQuery, buildResourceMutations } from "@utils/reactQuery";

export const usersKeyFactory = createQueryKeys("users", {
  detail: (userId: string) => ({
    queryKey: [userId],
    queryFn: () => client.get<User>(`users/${userId}`).then((res) => res.data),
  }),
  paginated: (params) => ({
    queryKey: [params],
    queryFn: () => client.get<User[]>("users", { params }),
  }),
});

export const useUsersPaginated = buildPaginatedQuery(
  usersKeyFactory.paginated,
  {
    placeholderData: keepPreviousData,
  }
);

export const {
  useCreate: useCreateUserMutation,
  useUpdate: useUpdateUserMutation,
  // useDelete,
} = buildResourceMutations({
  resource: "users",
  type: "user",
  detailQueryKey: (id) => usersKeyFactory.detail(id).queryKey,
  listQueryKey: usersKeyFactory.paginated._def,
});
