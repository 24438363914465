import { Document, Font, Image, Page, Text, View } from "@react-pdf/renderer";
import colors from "tailwindcss/colors";

Font.register({
  family: "Roboto",
  fonts: [
    { src: "/fonts/Roboto-Regular.ttf" },
    { src: "/fonts/Roboto-Bold.ttf", fontWeight: "bold" },
  ],
});

const columns = [
  {
    label: "Image Preview",
    key: "imgUrlThumb",
    width: "100px",
  },
  { label: "Sequence #", key: "itemNumber", width: "100px", align: "right" },
  {
    label: "Order Type / Window",
    key: "orderType",
    width: "120px",
  },
  {
    label: "In Market Month",
    key: "inMarketMonth",
    width: "100px",
  },
  { label: "Brand", key: "brand", width: "80px" },
  { label: "BU", key: "businessUnit", width: "90px" },
  { label: "Program", key: "program", width: "200px" },
  { label: "Item Type", key: "itemType", width: "90px" },
  {
    label: "Item Description",
    key: "itemDescription",
    width: "150px",
  },
  {
    label: "Distributor / Name",
    key: "addressName",
    width: "130px",
  },
  { label: "Total Qty", key: "totalQty", width: "60px", align: "right" },
  {
    label: "In Market Date",
    key: "inMarketDate",
    width: "120px",
  },
  { label: "Act. Ship Date", key: "shipDate", width: "120px" },
  { label: "Tracking #", key: "tracking", width: "120px" },
];

const OrderHistorySalesSummaryPDF = ({ data }) => {
  return (
    <Document>
      <Page
        size={[600, 1000]}
        orientation="landscape"
        style={{ fontFamily: "Roboto" }}
      >
        <View
          style={{
            flexDirection: "row",
            fontSize: 10,
            fontWeight: "bold",
          }}
          fixed
        >
          {columns.map((col) => (
            <View
              style={{
                width: col.width,
                padding: 5,
              }}
              key={col.key}
            >
              <Text style={{ textAlign: "left" }}>{col.label}</Text>
            </View>
          ))}
        </View>
        {data.map((row, i) => (
          <View
            key={i}
            style={{
              // borderBottom: `1px solid ${colors.neutral[200]}`,
              ...(i % 2 === 0 && { backgroundColor: colors.gray[100] }),
              flexDirection: "row",
              fontSize: 10,
              marginVertical: 10,
              marginRight: 2,
            }}
            wrap={false}
          >
            {columns.map((col) => (
              <View
                style={{
                  width: col.width,
                  padding: 5,
                  // borderLeft: `1px solid ${colors.neutral[200]}`,
                }}
                key={col.key}
              >
                {col.key === "imgUrlThumb" ? (
                  <Image source={row.imgUrlThumb} />
                ) : (
                  <Text
                    style={{
                      textAlign: col.align || "left",
                      width: "100%",
                    }}
                  >
                    {col.tf ? col.tf(row[col.key], row) : row[col.key]}
                  </Text>
                )}
              </View>
            ))}
          </View>
        ))}
      </Page>
    </Document>
  );
};

export default OrderHistorySalesSummaryPDF;
