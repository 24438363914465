/** @jsxImportSource @emotion/react */
import tw, { styled } from "twin.macro";

import { useEffect, useState } from "react";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
import Helmet from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import ViewModuleIcon from "@mui/icons-material/ViewModule";
import ViewStreamIcon from "@mui/icons-material/ViewStream";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";

import PropTypes from "prop-types";
import HideFiltersButtonToggle from "src/components/Filtering/HideFiltersButtonToggle";
import { handleBulkOrder } from "src/components/Ordering/helpers";

import { useAlert } from "@features/confirm/useAlert";
import client from "@services/api";

import FilterChipList from "../components/Filtering/FilterChipList";
import ItemPreviewModal from "../components/ItemPreview/ItemPreviewModal";
import OrderVariantViewControl from "../components/Ordering/OrderVariantViewControl";
import VariantModal from "../components/Ordering/VariantModal";
import Loading from "../components/Utility/Loading";
import { useChannelUpdate, useInitialFilters } from "../hooks/UtilityHooks";
import { setSorted, updateMultipleFilters } from "../redux/slices/filterSlice";
import { fetchNextFilteredItems } from "../redux/slices/items/itemSlice";
import {
  clearCurrentOrder,
  clearItemSelections,
  clearOrderByType,
  fetchCurrentOrderByType,
  updateSelection,
} from "../redux/slices/ordering/currentOrderSlice";
import { setIsOrdering } from "../redux/slices/ordering/orderSetSlice";

const PaperTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: tw`p-3 mt-3 text-sm font-normal bg-white shadow-md drop-shadow text-neutral-700`,
  [`& .${tooltipClasses.arrow}`]: tw`text-white`,
});

/*
This page holds all the logic for placing on demand orders. On demand orders are orders for items
that need to be produced, and all on demand orders will go to the purchase order work flow.
*/
const defaultFilters = {
  brand: [],
  itemType: [],
  bu: [],
  program: [],
  favItems: [],
  exclusiveTerritory: [],
  orderType: "on-demand",
  itemNumber: "",
  itemDesc: "",
  isItemOrderable: true,
};

const useStyles = makeStyles((theme) => ({
  ...theme.global,
}));

const PlaceOnDemandOrder = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const nextLink = useSelector((state) => state.items.nextLink);
  const isNextLoading = useSelector((state) => state.items.isNextLoading);
  const filtersOpen = useSelector((state) => state.filters.filtersOpen);
  const alert = useAlert();

  const handleBottomScroll = () => {
    if (nextLink && !isNextLoading) {
      if (scrollRef.current && scrollRef.current.scrollTop !== 0) {
        dispatch(fetchNextFilteredItems(nextLink));
      }
    }
  };

  const scrollRef = useBottomScrollListener(handleBottomScroll, {
    offset: 500,
    debounceOptions: {
      leading: true,
      trailing: false,
    },
  });
  const [currentView, setView] = useState("list");
  const [previewModal, handlePreviewModal] = useState(false);
  const [currentItem, handleCurrentItem] = useState({});
  const [isVariantModalOpen, setVariantModalOpen] = useState(false);
  const [showExistingOrderPopper, setShowExistingOrderPopper] = useState(false);

  const {
    items: currentItems,
    isLoading: itemsLoading,
    pagesLoaded,
  } = useSelector((state) => state.items);
  const currentChannel = useSelector((state) => state.user.currentChannel);
  const currentUserRole = useSelector((state) => state.user.role);
  const territoryId = useSelector((state) => state.user.currentTerritory);
  const orderLoading = useSelector((state) => state.currentOrder.isLoading);
  const selectedItems = useSelector(
    (state) => state.currentOrder.selectedOnDemandItems
  );

  const currentOrder = useSelector((state) => state.currentOrder);
  const isAllRegions = useSelector(
    (state) => state.currentOrder.onDemandOrderIsAllRegions
  );
  const userId = useSelector((state) => state.user.id);
  const isOrdering = useSelector((state) => state.orderSet.isOrdering);
  const orderTerritoryId = useSelector(
    (state) => state.currentOrder.onDemandOrderTerritory
  );
  const orderChannel = useSelector(
    (state) => state.currentOrder.onDemandChannel
  );

  const { channel: currentChannelFilter, program: filterProgram } = useSelector(
    (state) => state.filters
  );

  const programIdFromFilter =
    filterProgram?.length === 1 ? filterProgram[0]?.id : null;

  const isUpdateLoading = useSelector(
    (state) => state.currentOrder.orderUpdateLoading
  );

  const dynamicFilters = {};
  dynamicFilters.channel = currentChannel;
  dynamicFilters.currentTerritoryId = territoryId;
  if (currentOrder.onDemandProgram) {
    dynamicFilters.program = [currentOrder.onDemandProgram];
  }

  const handlePreview = (itemNumber) => {
    let item = currentItems.find((item) => item.itemNumber === itemNumber);
    handleCurrentItem(item);
    handlePreviewModal(true);
    dispatch(clearItemSelections());
  };

  const handleModalClose = () => {
    handlePreviewModal(false);
  };

  const handleAddToOrder = async () => {
    const items = currentItems.filter((i) => selectedItems.includes(i.id));
    let variantIds = items.flatMap((item) => item.variants.map((v) => v.id));

    if (
      currentOrder.orderId &&
      currentOrder.onDemandProgram &&
      currentOrder.onDemandProgram.id !== programIdFromFilter
    ) {
      await alert(
        "This item is not part of the selected program and cannot be added to this order. Please finish checking out and create a separate order for this item.",
        {
          confirmButtonText: "cancel current action and return to cart",
        }
      );
      return;
    }

    handleBulkOrder({
      variantIds,
      selectedItems,
      currentOrder,
      dispatch,
      territoryId,
      channel: currentChannel,
      programIdFromFilter,
      currentItems,
      setVariantModalOpen,
      type: "onDemand",
    });
  };

  const handleVariantModalClose = () => setVariantModalOpen(false);

  const handleDeleteExistingOrder = () => {
    client.delete(`order-sets/${currentOrder.orderId}`);
    dispatch(clearOrderByType({ type: "onDemand" }));
    dispatch(clearCurrentOrder());
    setShowExistingOrderPopper(false);
  };

  const handleSort = (sortObject) => {
    scrollRef.current.scrollTop = 0;
    dispatch(
      updateMultipleFilters({
        filterObject: {
          sortOrder: sortObject.order,
          sortOrderBy: sortObject.orderBy,
        },
      })
    );
    dispatch(setSorted());
  };

  useChannelUpdate(currentChannel, currentChannelFilter, dispatch);

  useInitialFilters("item-onDemand", { ...defaultFilters, ...dynamicFilters });

  useEffect(() => {
    if (
      programIdFromFilter &&
      currentItems.length > 0 &&
      !currentOrder.orderId
    ) {
      dispatch(
        updateSelection({
          type: "selectedOnDemandItems",
          selectedItems: currentItems.map(({ id }) => id),
        })
      );
    }
  }, [programIdFromFilter, currentItems, dispatch, currentOrder.orderId]);

  useEffect(() => {
    if (
      (userId && !currentOrder.orderId) ||
      (userId && currentOrder.type !== "On Demand")
    ) {
      dispatch(
        fetchCurrentOrderByType("onDemand", userId, currentChannel, territoryId)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrder.type]);

  useEffect(() => {
    if (!isAllRegions && !orderTerritoryId && isOrdering) {
      dispatch(setIsOrdering({ status: false }));
    }
  }, [orderTerritoryId, isAllRegions, isOrdering, dispatch]);

  useEffect(() => {
    if (currentOrder.isLoading) return;
    if (currentOrder.source === "fetch") {
      setShowExistingOrderPopper(Boolean(currentOrder.orderId));
    }
  }, [currentOrder.isLoading, currentOrder.orderId, currentOrder.source]);

  if (orderLoading) {
    return <Loading />;
  }

  return (
    <>
      <Helmet>
        <title>RTA | On-Demand Order</title>
        {currentUserRole === "field2" && !filtersOpen && (
          <script type="text/javascript">{`Beacon('suggest', ['600af2ff1c64ad47e4b7201d'], )`}</script>
        )}
        {currentUserRole === "field1" && !filtersOpen && (
          <script type="text/javascript">{`Beacon('suggest', ['5ffdf334b9a8501b295cf995'])`}</script>
        )}
      </Helmet>
      {previewModal && (
        <ItemPreviewModal
          type={"onDemand"}
          currentItem={currentItem}
          handleClose={handleModalClose}
          previewModal={previewModal}
        />
      )}
      {isVariantModalOpen && (
        <VariantModal
          selectedItems={selectedItems}
          currentItems={currentItems}
          territoryId={territoryId}
          channel={currentChannel}
          orderType={"onDemand"}
          currentOrder={currentOrder}
          open={isVariantModalOpen}
          handleClose={handleVariantModalClose}
          programIdFromFilter={programIdFromFilter}
        />
      )}
      <Container className={classes.mainWrapper}>
        <div className={classes.titleBar}>
          <Typography className={classes.titleText} variant="h5">
            Place an On-Demand Order
          </Typography>

          <div className={classes.innerConfigDiv}>
            {!["compliance", "read-only"].includes(currentUserRole) && (
              <>
                <Button
                  className={classes.largeButton}
                  variant="contained"
                  color="secondary"
                  disabled={
                    selectedItems.length === 0 ||
                    (orderChannel && currentChannel !== orderChannel)
                  }
                  onClick={handleAddToOrder}
                  style={{ marginRight: "20px" }}
                >
                  ADD TO ORDER
                </Button>
                <PaperTooltip
                  PopperProps={{ disablePortal: true }}
                  open={showExistingOrderPopper}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  arrow
                  title={
                    <div tw="text-base">
                      Looks like you have an existing order. You can{" "}
                      <button
                        tw="text-red-600 underline"
                        onClick={handleDeleteExistingOrder}
                      >
                        delete
                      </button>{" "}
                      it or continue to add items.{" "}
                      <Button
                        tw="float-right"
                        color="secondary"
                        onClick={() => setShowExistingOrderPopper(false)}
                      >
                        dismiss
                      </Button>
                    </div>
                  }
                >
                  <Button
                    component={Link}
                    disabled={isUpdateLoading || !currentOrder.orderId}
                    to={`/orders/open/${currentOrder.orderId}`}
                    className={classes.largeButton}
                    variant="contained"
                    color="secondary"
                    style={{ marginRight: "20px" }}
                  >
                    VIEW ORDER{" "}
                    {currentOrder.onDemandProgram
                      ? `(${currentOrder.onDemandProgram.id})`
                      : currentOrder.orderId
                      ? "(Misc)"
                      : ""}
                  </Button>
                </PaperTooltip>
              </>
            )}
            <Tooltip title="View List">
              <IconButton
                onClick={() => {
                  setView("list");
                }}
                size="large"
              >
                <ViewStreamIcon
                  fontSize="large"
                  color={currentView === "list" ? "primary" : "inherit"}
                />
              </IconButton>
            </Tooltip>
            <Tooltip title="View Grid">
              <IconButton
                onClick={() => {
                  setView("grid");
                }}
                size="large"
              >
                <ViewModuleIcon
                  fontSize="large"
                  color={currentView === "grid" ? "primary" : "inherit"}
                />
              </IconButton>
            </Tooltip>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignContent: "center",
            marginBottom: "10px",
          }}
        >
          <HideFiltersButtonToggle />
          <FilterChipList classes={classes} />
          <br />
        </div>
        {itemsLoading || pagesLoaded > 0 ? (
          <OrderVariantViewControl
            type={"onDemand"}
            currentView={currentView}
            handlePreview={handlePreview}
            items={currentItems}
            isItemsLoading={itemsLoading}
            scrollRef={scrollRef}
            handleSort={handleSort}
          />
        ) : (
          <Typography variant="h5">Add some filters to get started</Typography>
        )}
        {isNextLoading && (
          <div style={{ width: "100%" }}>
            <LinearProgress />
          </div>
        )}
        {!isNextLoading && <div style={{ width: "100%", height: "4px" }}></div>}
      </Container>
      <br />
    </>
  );
};

PlaceOnDemandOrder.propTypes = {
  userType: PropTypes.string,
};

export default PlaceOnDemandOrder;
