/** @jsxImportSource @emotion/react */
import "twin.macro";

import React, { Component, useEffect, useState } from "react";

import PropTypes from "prop-types";

const ErrorMessage = ({ message }) => {
  const [timer, setTimer] = useState(5);
  useEffect(() => {
    if (timer > 0) {
      setTimeout(() => setTimer(timer - 1), 1000);
    }
  }, [timer]);

  return (
    <div tw="py-12 px-6">
      <h1 tw="text-2xl text-red-700">Something went wrong</h1>
      <p tw="text-lg text-neutral-700">{message}</p>
      <h2 tw="text-xl text-neutral-400">Reloading in {timer}</h2>
    </div>
  );
};

class LocalErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: "",
    };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, info) {
    console.error("ErrorBoundary caught an error", error, info);
    setTimeout(() => {
      this.setState({ hasError: false, error: "" });
    }, 5000);
    //add logging service like Sentry
  }

  render() {
    if (this.state.hasError) {
      return (
        <>
          <ErrorMessage message={this.state.error.message} />
        </>
      );
    }

    return this.props.children;
  }
}

LocalErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LocalErrorBoundary;
