import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  AutocompleteInput,
  ControlledAutocompleteInputProps,
} from "src/components/Forms/ControlledInputs";
import { fetchBrands } from "src/redux/slices/items/brandSlice";

const ControlledBrandAutocomplete = <
  Multiple extends boolean = false,
  DisableClearable extends boolean = false,
>(
  props: Omit<
    ControlledAutocompleteInputProps<Multiple, DisableClearable>,
    "options"
  >
) => {
  const dispatch = useDispatch();
  const brands = useSelector((state: any) => state.brands.brandList);

  useEffect(() => {
    dispatch(fetchBrands());
  }, [dispatch]);
  return <AutocompleteInput label="Brands *" options={brands} {...props} />;
};

export default ControlledBrandAutocomplete;
