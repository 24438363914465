import React, { useState } from "react";

import CancelIcon from "@mui/icons-material/Cancel";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import IconButton from "@mui/material/IconButton";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";

import PropTypes from "prop-types";

import { useInput } from "../../../hooks/InputHooks";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
}));

const ConfirmCancelOrder = ({ open, handleClose, handleCancel, id, type }) => {
  const classes = useStyles();

  const [error, setError] = useState(null);
  const [option, setOption] = useState("comp");

  const {
    value: note,
    bind: bindNote,
    reset: resetNote,
    setValue: setNote,
  } = useInput("Compliance Canceled");

  const handleOption = (event) => {
    let opt = event.target.value;
    if (opt === "comp") {
      setNote("Compliance Canceled");
    } else if (opt === "moq") {
      setNote("MOQ not reached");
    } else setNote("");
    setOption(opt);
  };

  return (
    <div className={classes.relativeContainer}>
      <Dialog
        open={open}
        disableScrollLock
        onClose={() => {
          resetNote();
          setError(null);
          handleClose();
        }}
        fullWidth
        maxWidth="sm"
        style={{ zIndex: "15000" }}
      >
        <DialogContent>
          <IconButton
            className={classes.closeButton}
            onClick={() => {
              resetNote();
              setError(null);
              handleClose();
            }}
            size="large"
          >
            <CancelIcon fontSize="large" color="secondary" />
          </IconButton>
          <br />
          <div className={classes.flexColumnModal}>
            <br />
            <Typography className={classes.headerText}>
              {type === "order"
                ? "Canceling this order will cancel all order items contained in this order."
                : `Canceling Order Item #${id}.`}
            </Typography>
            <Typography className={classes.titleText}>
              This action cannot be undone.
            </Typography>
            <br />
            <FormControl
              variant="outlined"
              component="fieldset"
              focused={false}
            >
              <FormLabel component="legend">
                {"Cancellation Reason (Required)"}
              </FormLabel>
              <RadioGroup
                aria-label="reason"
                name="cancel-reason"
                value={option}
                onChange={handleOption}
              >
                <FormControlLabel
                  value="comp"
                  control={<Radio />}
                  label="Compliance Canceled"
                />
                <FormControlLabel
                  value="moq"
                  control={<Radio />}
                  label="MOQ not reached"
                />
                <FormControlLabel
                  value="other"
                  control={<Radio />}
                  label="Other"
                />
              </RadioGroup>
            </FormControl>
            <br />
            <TextField
              color="secondary"
              multiline
              fullWidth
              variant="outlined"
              size="small"
              minRows={3}
              disabled={option !== "other"}
              {...bindNote}
            />
            <br />
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-around",
              }}
            >
              <Button
                variant="contained"
                className={classes.largeButton}
                color="secondary"
                onClick={() => {
                  if (note.length === 0) {
                    setError("Must provide a reason for cancelling item...");
                  } else {
                    handleCancel(id, "compliance", note, type);
                  }
                }}
              >
                COMPLIANCE CANCEL
              </Button>
              <Button
                variant="contained"
                className={classes.largeButton}
                color="secondary"
                onClick={() => {
                  if (note.length === 0) {
                    setError("Must provide a reason for cancelling item...");
                  } else {
                    handleCancel(id, "order", note, type);
                  }
                }}
              >
                {`CANCEL ${type.toUpperCase()}`}
              </Button>
              <Button
                variant="contained"
                className={classes.largeButton}
                color="secondary"
                onClick={() => {
                  resetNote();
                  setError(null);
                  handleClose();
                }}
              >
                CLOSE WINDOW
              </Button>
            </div>
            {error && (
              <>
                <br />
                <Typography
                  className={classes.bodyText}
                  style={{ color: "#920000" }}
                >
                  {error}
                </Typography>
              </>
            )}
            <br />
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

ConfirmCancelOrder.propTypes = {
  open: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  itemId: PropTypes.any,
  type: PropTypes.string,
};

export default ConfirmCancelOrder;
