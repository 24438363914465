import React from "react";

import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";

import { formatMoney } from "../../utility/utilityFunctions";

Font.register({
  family: "Roboto",
  src: "/fonts/Roboto-Regular.ttf",
});

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    alignItems: "center",
  },
  titleText: {
    fontFamily: "Roboto",
    fontSize: 14,
    fontWeight: 800,
    color: "white",
  },
  headerText: {
    fontFamily: "Roboto",
    fontSize: 12,
    fontWeight: 800,
  },
  text: {
    fontFamily: "Roboto",
    fontSize: 8,
    paddingBottom: "4px",
  },
  specText: {
    fontFamily: "Roboto",
    fontSize: 7,
    paddingBottom: 3,
  },
  sectionWrapper: {
    display: "flex",
    flexDirection: "column",
    width: 600,
    height: 240,
    alignItems: "center",
  },
  titleBar: {
    width: 590,
    height: 60,
    marginTop: 10,
    dispaly: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "black",
  },
  section: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    width: 600,
    height: 220,
  },
  imageSection: {
    width: 195,
    height: 220,
    padding: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  smImageWrapper: {
    width: 145,
    height: 70,
    display: "flex",
    flexDirection: "row",
    alignItems: "space-around",
    justifyContent: "center",
  },
  imageSm: {
    height: 70,
    width: 70,
  },
  image: {
    objectFit: "contain",
  },
  detailSection: {
    width: 165,
    height: 220,
    padding: 10,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-around",
  },
  detailTextSection: {
    width: 145,
    height: 120,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-around",
  },
  textSection: {
    width: 170,
    height: 220,
    padding: 10,
    marginRight: 10,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  sectionBreak: {
    width: 150,
    height: 1,
    backgroundColor: "black",
    marginBottom: "4px",
  },
  pageBreak: {
    width: 600,
    height: 1,
    marginTop: 10,
    backgroundColor: "black",
  },
});

const ItemPDF = ({ items }) => {
  return (
    <Document>
      {items.map((group, index) => (
        <Page key={index} size="LETTER" style={styles.page}>
          <View style={styles.titleBar}>
            <Text style={styles.titleText}>{`${group[0].unit} - ${
              group[0].brand.length > 1 ? "Multi Brand" : group[0].brand[0]
            }`}</Text>
          </View>
          {group.map((item, i) => (
            <View key={`${item.id}-${i}`} style={styles.sectionWrapper}>
              <View style={styles.section}>
                <View style={styles.imageSection}>
                  <Image
                    source={{
                      uri: item.imgUrlLg[0],
                      method: "get",
                      headers: {
                        "Access-Control-Allow-Origin": "*",
                        crossOrigin: "anonymous",
                      },
                    }}
                    cache="reload"
                    allowDangerousPaths={true}
                    style={styles.image}
                  />
                </View>
                <View style={styles.detailSection}>
                  <View style={styles.detailTextSection}>
                    <Text style={styles.headerText}>
                      {item.itemNumber
                        ? `#${item.itemNumber}`
                        : "No Sequence Number Found"}
                    </Text>
                    <Text style={styles.headerText}>
                      {`Item Type:  ${item.itemType}`}
                    </Text>
                    <Text style={styles.text}>{`Brand(s):  ${item.brand.join(
                      ", "
                    )}`}</Text>
                    <Text style={styles.text}>{`Program:  ${item.program.join(
                      ", "
                    )}`}</Text>
                    <Text style={styles.text}>
                      {`Item Description:  ${item.itemDescription}`}
                    </Text>
                    {item.variantNames && (
                      <Text style={styles.text}>
                        {`Variants: ${item.variantNames}`}
                      </Text>
                    )}
                  </View>
                  <View style={styles.smImageWrapper}>
                    {item.imgUrlLg.slice(1, 3).map((img, index) => (
                      <View
                        style={styles.imageSm}
                        key={`sm-img-${img}-${index}`}
                      >
                        <Image
                          source={{
                            uri: img,
                            method: "get",
                            headers: {
                              "Access-Control-Allow-Origin": "*",
                              crossOrigin: "anonymous",
                            },
                          }}
                          cache="reload"
                          allowDangerousPaths={true}
                          style={styles.image}
                        />
                      </View>
                    ))}
                  </View>
                </View>
                <View style={styles.textSection}>
                  <Text style={styles.text}>
                    {`In Market: ${item.inMarketDate}`}
                  </Text>
                  <Text style={styles.text}>
                    {`Pack Size: ${item.packSize}`}
                  </Text>
                  {item.moq && (
                    <Text style={styles.text}>{`MOQ:  ${item.moq}`}</Text>
                  )}
                  <Text style={styles.text}>
                    {`Est Cost: ${formatMoney(item.estCost)}`}
                  </Text>
                  {item.isInventory && (
                    <Text style={styles.text}>{`On Hand: ${
                      item.stock
                    } - ${new Date().toLocaleDateString()}`}</Text>
                  )}
                  <View style={styles.sectionBreak} />
                  {item.specification ? (
                    item.specification.map((spec, index) => {
                      if (spec.type === "spec") {
                        return (
                          spec.value.toLowerCase().trim() !== "n/a" && (
                            <Text style={styles.specText} key={index}>
                              {`${spec.key}:  ${spec.value}`}
                            </Text>
                          )
                        );
                      } else {
                        return (
                          <>
                            <Text style={styles.text} key={"websiteDesc"}>
                              Website Description:
                            </Text>
                            {Object.keys(spec.value).map(
                              (wSpec, i) =>
                                spec.value[wSpec].toLowerCase().trim() !==
                                  "n/a" && (
                                  <Text style={styles.specText} key={`wd-${i}`}>
                                    {`${wSpec}:  ${spec.value[wSpec]}`}
                                  </Text>
                                )
                            )}
                          </>
                        );
                      }
                    })
                  ) : (
                    <Text style={styles.text}>
                      Item Specifications are Pending
                    </Text>
                  )}
                </View>
              </View>
              {i !== group.length - 1 && <View style={styles.pageBreak} />}
            </View>
          ))}
        </Page>
      ))}
    </Document>
  );
};

export default ItemPDF;
