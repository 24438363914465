const HEADER = null;

const insAB = {
  ins_ab: "AB Standard Template",
  ins_dp: "AB Dual Purchase",
  ins_nab: "NAB Standard Template",

  "Special Package Types:": HEADER,
  ins_ab_box: "Box",
  ins_ab_can: "Cans",
  ins_ab_4pack: "4 Pack",

  "Other Templates:": HEADER,
  ins_ab_itp: "Invitation to Purchase",
};

const mirAB = {
  SOAK: HEADER,
  // Don't have yet
  // mir_ab_soak_northeast: "Northeast",
  mir_ab_soak: "Standard Soak Template",
  // MIR NAB: We should call this Non Alcohol Beverage and put it under SOAK
  mir_nab: "Non Alcohol Beverage",
  // MIR DP: We should Call this Dual Purchase and put it under SOAK
  mir_dp: "Dual Purchase",

  "Special Packaging": HEADER,
  mir_ab_can: "Cans",
  mir_ab_nosoak_box: "Box",
  mir_ab_noglass: "No Glass",

  "No Soak": HEADER,
  mir_ab_nosoak_northeast: "Northeast",
  mir_ab_nosoak_bogo: "BOGO",
  mir_ab_nosoak_bottle_tetra: "Bottle No Label or Tetra",
  // "Special MIR DP No Soak": Put this under No Soak, and call it Dual Purchase - No Soak
  mir_dp_nosoak: "Dual Purchase - No Soak",
  // Dont have that one yet
  // mir_ab_nosoak_glass: "Glass",
  mir_ab_nosoak_premiumportfolio: "Premium Portfolio",

  // not sure about that one
  // "Special MIR Portfolio No Soak: Under No Soak, call it: AB Progressive Portfolio No Soak
  mir_ab_portfolio_nosoak_northeast: "Portfolio/Progressive",
  "Other Special Templates": HEADER,
  mir_ab_progressive: "Progressive",
};

export const templateMenuOptions = {
  // INS AB
  1: insAB,
  // MIR AB
  2: mirAB,
  // INS NAB
  3: { ins_nab: "INS NAB Template" },
  // MIR NAB
  4: { mir_nab: "MIR NAB Template" },
  // MBG AB
  5: {},
  // INS AB/AB
  6: insAB,
  // INS AB/NAB
  7: {
    ins_dp: "INS DP Template",
  },
  // MIR AB/AB
  8: mirAB,
  // MIR AB/NAB
  9: {
    mir_dp: "MIR DP",
    mir_dp_nosoak: "MIR DP No SOAK",
  },
};

export const commonOptions = {
  // "Digital Coupon": HEADER,
  ins_ab_digital: "Digital Coupon",
  Forms: HEADER,
  digital_coupon_request_form: "Digital Coupon Request Form",
};
