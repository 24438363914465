import { format } from "date-fns";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";

import { createSlice } from "@reduxjs/toolkit";

import { RStatus, TGlobalReports, TReportState } from "./types";

const emptyReport: TReportState = {
  name: "",
  filename: "",
  id: "",
  url: "",
  status: "LOADING",
};

let initialState: TGlobalReports = {
  reportQueue: [],
  requestReport: null,
  filenames: [],
  reportTrayOpen: true,
};

const date = format(new Date(), "yyyy-MM-dd");
const createFilename = (reportName, filenames) => {
  let name: string;
  let i = 0;
  do {
    name = `${reportName}-${date}`;
    if (i > 0) name += `-v${i}`;
    i++;
  } while (filenames.includes(name));
  return name;
};

const reportSlice = createSlice({
  name: "globalReports",
  initialState,
  reducers: {
    requestReport(state, { payload: reportName }) {
      state.requestReport = reportName;
    },
    queueReport(state, action) {
      const { reportName: name, url } = action.payload;
      const filename = createFilename(name, state.filenames);
      const newReport = {
        ...emptyReport,
        url,
        name,
        filename,
        id: uuidv4() as string,
      };
      state.reportQueue.unshift(newReport);
      state.filenames.push(filename);
      state.requestReport = null;
      state.reportTrayOpen = true;
    },
    setReportStatus(
      state,
      { payload: { id, status } }: { payload: { id: string; status: RStatus } }
    ) {
      state.reportQueue = state.reportQueue.map((rep) =>
        rep.id === id ? { ...rep, status } : rep
      );
    },
    clearReport(state, { payload: id }) {
      state.reportQueue = state.reportQueue.filter(
        (report) => report.id !== id
      );
    },
    clearAllReports(state) {
      state.reportQueue = [];
    },
    setReportTrayOpen(state, { payload: open }) {
      state.reportTrayOpen = open;
    },
  },
});

export const {
  requestReport,
  queueReport,
  setReportStatus,
  clearReport,
  clearAllReports,
  setReportTrayOpen,
} = reportSlice.actions;

export default reportSlice.reducer;
