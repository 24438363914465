/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useEffect, useState } from "react";
import {
  FormProvider,
  useForm,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { useSelector } from "react-redux";

import { Close } from "@mui/icons-material";
import {
  Alert,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Radio,
  Skeleton,
  Typography,
} from "@mui/material";

import _ from "lodash";
import { intValidation } from "src/utility/inputHelpers";
import { roundUp } from "src/utility/utilityFunctions";

import {
  allocationName,
  useTransferVariantAllocationMutation,
} from "@features/items";
import { useSubStates } from "@features/subStates";
import { useTerritories } from "@features/territories";
import { Item } from "@models/Item";
import { Variant } from "@models/Variant";
import { VariantAllocation } from "@models/VariantAllocation";

import {
  ControlledRadioGroup,
  SelectInput,
  TextInput,
} from "../../../components/Forms/ControlledInputs";
import { StyledButton } from "../../../components/StyledComponents";
import AllocationHistoryTable from "./AllocationHistoryTable";

type TAllocationType = "Customer" | "Regional" | "National";

const TerritorySelector = () => {
  const { control } = useFormContext();
  const { data } = useTerritories({
    isAwaitingCode: false,
    isActive: true,
  });

  if (!data) return <Skeleton variant="rectangular" height={40} />;

  const territoryList = _.sortBy(data, "name").filter(
    (terr) => terr.type === "Customer"
  );

  return (
    <SelectInput
      fullWidth
      options={territoryList}
      control={control}
      name="territoryId"
      label="Territory"
      required
    />
  );
};

const SubStateSelector = () => {
  const { control } = useFormContext();
  const { data } = useSubStates();

  if (!data) return <Skeleton variant="rectangular" height={40} />;

  const subStateList = _.sortBy(data, "name").filter((s) => !!s.name);

  return (
    <SelectInput
      fullWidth
      options={subStateList}
      control={control}
      name="subStateId"
      label="Sub State"
      required
    />
  );
};

const AllocationDestinationSelector = ({ title }) => {
  const { control } = useFormContext();
  const allocationType = useWatch({ name: "allocationType" });
  return (
    <div tw="space-y-4 -mx-3 p-3 rounded-lg bg-neutral-100 border border-neutral-200">
      <h3>{title}</h3>
      <ControlledRadioGroup row control={control} name="allocationType">
        <FormControlLabel
          value="Customer"
          control={<Radio />}
          label="Key Account"
        />
        <FormControlLabel
          value="Regional"
          control={<Radio />}
          label="Regional"
        />
      </ControlledRadioGroup>
      {allocationType === "Customer" && <TerritorySelector />}
      {allocationType === "Regional" && <SubStateSelector />}
    </div>
  );
};

const VariantAllocationModal = ({
  variant,
  allocation,
  handleClose,
  item,
}: {
  variant: Variant;
  allocation: null | VariantAllocation;
  item: Item;
  handleClose: () => void;
}) => {
  const editingSingleAllocation = !!allocation;

  const [transfering, setTransfering] = useState(false);
  const [workingAllocation, setWorkingAllocation] =
    useState<null | VariantAllocation>(allocation);

  const transferVariantAllocationMutation =
    useTransferVariantAllocationMutation();

  const mutationError = transferVariantAllocationMutation.error;

  const { role } = useSelector((state: any) => state.user);

  const formMethods = useForm({
    defaultValues: {
      allocationType: "Customer" as TAllocationType,
      territoryId: "",
      subStateId: "",
      quantity: "",
    },
  });
  const { handleSubmit, control, reset, watch, setValue } = formMethods;

  const [allocationType, quantity, territoryId, subStateId] = watch([
    "allocationType",
    "quantity",
    "territoryId",
    "subStateId",
  ]);

  const exceedsAvailable =
    quantity.length > 0 &&
    +quantity - (workingAllocation?.qty || 0) >
      variant.nationalAvailableToOrderQty;

  const transferDestinationInvalid =
    transfering &&
    (allocationType === "Customer"
      ? allocation?.territory?.id === territoryId
      : allocation?.subState?.id === subStateId);

  function onSubmit(data) {
    if (transfering && allocation) {
      transferVariantAllocationMutation.mutate(
        {
          itemId: item.id,
          id: variant.id,
          qty: +data.quantity,

          donorTerritoryId: allocation.territory?.id,
          donorSubStateId: allocation.subState?.id,

          recipientTerritoryId:
            allocationType === "Customer" ? territoryId : undefined,
          recipientSubStateId:
            allocationType === "Regional" ? subStateId : undefined,
        },
        { onSuccess: handleClose }
      );
      return;
    }

    const transferAmount = +data.quantity - (workingAllocation?.qty ?? 0);

    if (transferAmount > 0) {
      transferVariantAllocationMutation.mutate(
        {
          itemId: item.id,
          id: variant.id,
          qty: transferAmount,
          // Donor is National
          recipientTerritoryId:
            allocationType === "Customer" ? territoryId : undefined,
          recipientSubStateId:
            allocationType === "Regional" ? subStateId : undefined,
        },
        { onSuccess: handleClose }
      );
    } else if (transferAmount < 0) {
      transferVariantAllocationMutation.mutate(
        {
          itemId: item.id,
          id: variant.id,
          qty: transferAmount * -1,
          donorTerritoryId:
            allocationType === "Customer" ? territoryId : undefined,
          donorSubStateId:
            allocationType === "Regional" ? subStateId : undefined,
          // Recipient is National
        },
        { onSuccess: handleClose }
      );
    } else {
      handleClose();
    }
  }

  useEffect(() => {
    if (allocation) {
      reset({
        territoryId: allocation.territory?.id ?? "",
        subStateId: allocation.subState?.id ?? "",
        allocationType: allocation.territory?.id
          ? "Customer"
          : allocation.subState?.id
          ? "Regional"
          : "National",
        quantity: "",
      });
    }
  }, [allocation, reset]);

  useEffect(() => {
    if (transfering) return;
    let foundAllocation: null | VariantAllocation = null;
    // Try to find an existing allocation for the selected territory or substate
    if (allocationType === "Customer" && territoryId) {
      foundAllocation =
        variant.variantAllocations.find(
          (a) => a.territory?.id === territoryId
        ) ?? null;
    } else if (subStateId) {
      foundAllocation =
        variant.variantAllocations.find((a) => a.subState?.id === subStateId) ??
        null;
    }

    setWorkingAllocation(foundAllocation);
    if (foundAllocation) {
      setValue("quantity", foundAllocation.qty.toString());
    }
  }, [variant, allocationType, territoryId, subStateId, setValue, transfering]);

  return (
    <FormProvider {...formMethods}>
      <Dialog
        open
        disableScrollLock
        onClose={handleClose}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle tw="flex items-center justify-between">
          <span>
            {role === "field2" ? "" : allocation ? "Creating " : "Editing "}
            Shelf Inventory for Sku {variant.variantSku}
          </span>
          <div tw="flex items-center space-x-2">
            {editingSingleAllocation && role !== "field2" && (
              <StyledButton
                cta
                disabled={allocation.qty === 0}
                onClick={() => setTransfering(!transfering)}
              >
                {transfering ? "Cancel Transfer" : "Transfer"}
              </StyledButton>
            )}
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          <div tw="space-y-4">
            <div>
              <Typography>
                <b>Shipped in Packs Of: </b>
                {item.qtyPerPack}
              </Typography>

              <Typography>
                <b>Available to allocate: </b>
                {variant.nationalAvailableToOrderQty}
              </Typography>
            </div>
            {transfering && (
              <Typography>
                <b>Transfering From: </b>
                {allocationName(allocation!)}
              </Typography>
            )}
            {(!editingSingleAllocation || transfering) && (
              <AllocationDestinationSelector
                title={transfering ? "Transfer Recipient" : "Allocation"}
              />
            )}
            {workingAllocation && !transfering && (
              <>
                <h3>
                  <b>Allocation: </b>
                  {allocationName(workingAllocation)}
                </h3>
                <AllocationHistoryTable
                  allocationId={workingAllocation?.id as string}
                />
              </>
            )}
            {workingAllocation && (
              <Typography tw="mt-6">
                <b>Allocated: </b>
                {workingAllocation.qty}
              </Typography>
            )}
            {role !== "field2" && (
              <>
                <TextInput
                  fullWidth
                  control={control}
                  name="quantity"
                  label={
                    transfering
                      ? "Quantity to transfer"
                      : workingAllocation
                      ? "New Quantity"
                      : "Quantity"
                  }
                  rules={{
                    ...intValidation,
                    transformOnBlur: (qty) => roundUp(qty, item.qtyPerPack),
                  }}
                />

                <Typography color="textSecondary">
                  <em>
                    * Shelf Inventory entries must be made in multiples of the
                    items pack size, your allocation will automatically be
                    rounded up to meet this requirement
                  </em>
                </Typography>
                {exceedsAvailable && (
                  <Typography color="error" tw="mt-6">
                    <em>Shelf Inventory exceeds available quantity</em>
                  </Typography>
                )}
                {transferDestinationInvalid && (
                  <Typography color="error" tw="mt-6">
                    <em>Can't transfer to the same allocation</em>
                  </Typography>
                )}
                {mutationError && (
                  <Alert severity="error" tw="whitespace-pre-wrap">
                    {mutationError.message.replace("Base ", "")}
                  </Alert>
                )}
                <StyledButton
                  cta
                  onClick={handleSubmit(onSubmit)}
                  disabled={
                    exceedsAvailable ||
                    !quantity ||
                    (allocationType === "Customer"
                      ? !territoryId
                      : !subStateId) ||
                    transferDestinationInvalid
                  }
                  loading={transferVariantAllocationMutation.isPending}
                >
                  SUBMIT
                </StyledButton>
              </>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </FormProvider>
  );
};

export default VariantAllocationModal;
