import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import CancelIcon from "@mui/icons-material/Cancel";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import clsx from "clsx";
import PropTypes from "prop-types";

import { useInput } from "../../hooks/InputHooks";
import {
  setUpdateSuccess,
  updateAddressById,
} from "../../redux/slices/addresses/addressSlice";
import EmailAddressTextField from "./EmailAddressTextField";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
  emailRow: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    marginBottom: "15px",
  },
  changeRequest: {
    width: "100%",
    boxSizing: "border-box",
    padding: "10px",
    border: `2px solid ${theme.palette.primary.light}`,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "15px",
    textAlign: "center",
  },
}));

const DistributorEmailModal = ({ open, id, handleClose }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { addressList, isUpdateLoading, updateStatus } = useSelector(
    (state) => state.addresses
  );
  const { role } = useSelector((state) => state.user);

  const { value: email, bind: bindEmail, reset: resetEmail } = useInput("");

  const [onPremiseEmailAddresses, setOnPremiseEmailAddresses] = useState([]);
  const [retailEmailAddresses, setRetailEmailAddresses] = useState([]);
  const [currentAddress, setCurrentAddress] = useState(null);

  const handleUpdateEmailAddress = (value, index, type) => {
    if (type === "onPremise") {
      setOnPremiseEmailAddresses(
        onPremiseEmailAddresses.map((e, i) => {
          if (i === index) {
            return value;
          } else return e;
        })
      );
    } else {
      setRetailEmailAddresses(
        retailEmailAddresses.map((e, i) => {
          if (i === index) {
            return value;
          } else return e;
        })
      );
    }
  };

  const handleRemoveEmailAddress = (value, type) => {
    if (type === "onPremise") {
      setOnPremiseEmailAddresses(
        onPremiseEmailAddresses.filter((e) => e !== value)
      );
    } else {
      setRetailEmailAddresses(retailEmailAddresses.filter((e) => e !== value));
    }
  };

  const handleAddEmailAddress = (type) => {
    if (type === "onPremise") {
      setOnPremiseEmailAddresses(onPremiseEmailAddresses.concat(""));
    } else {
      setRetailEmailAddresses(retailEmailAddresses.concat(""));
    }
  };

  const clearAddress = () => {
    setOnPremiseEmailAddresses([]);
    setRetailEmailAddresses([]);
  };

  const handleSubmit = () => {
    const address = {
      name: currentAddress.name,
      abn: currentAddress.distributorAbn,
      addressOne: currentAddress.streetAddressOne,
      addressTwo: currentAddress.streetAddressTwo,
      attn: currentAddress.attn,
      city: currentAddress.city,
      state: currentAddress.state.id,
      zip: currentAddress.zip,
      phonNumber: currentAddress.phoneNumber,
      country: currentAddress.country,
      substate: currentAddress.substate ? currentAddress.substate.id : null,
      isActive: currentAddress.isActive === "Yes" ? true : false,
      onPremiseEmailAddresses: onPremiseEmailAddresses.filter(
        (e) => e.length > 0
      ),
      retailEmailAddresses: retailEmailAddresses.filter((e) => e.length > 0),
      type: "distributor",
    };
    dispatch(updateAddressById(id, address));
  };

  useEffect(() => {
    if (
      !isUpdateLoading &&
      (!currentAddress || currentAddress.id !== id || updateStatus)
    ) {
      let address = addressList.find((add) => add.id === id);
      if (address) {
        setCurrentAddress(address);
        setOnPremiseEmailAddresses(address.onPremiseEmailAddresses);
        setRetailEmailAddresses(address.retailEmailAddresses);
      }
    }
  }, [
    role,
    addressList,
    isUpdateLoading,
    updateStatus,
    currentAddress,
    id,
    setCurrentAddress,
    dispatch,
  ]);

  useEffect(() => {
    dispatch(setUpdateSuccess({ updateStatus: false }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.relativeContainer}>
      <Dialog
        open={open}
        onClose={() => {
          clearAddress();
          handleClose();
        }}
        fullWidth
        maxWidth="sm"
        style={{ zIndex: "15000" }}
      >
        <DialogTitle>
          <Typography className={classes.headerText}>
            {currentAddress
              ? `Updating emails for ${currentAddress.name}`
              : "Updating Emails"}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <IconButton
            className={classes.closeButton}
            onClick={() => {
              clearAddress();
              handleClose();
            }}
            size="large"
          >
            <CancelIcon fontSize="large" color="secondary" />
          </IconButton>
          <br />
          {role === "field1" && (
            <div className={classes.fullWidthCenterColumn}>
              <Typography
                className={clsx(classes.bodyText, classes.settingsMargin)}
              >
                <b>On Premise Emails: </b>
                {onPremiseEmailAddresses.join(", ")}
              </Typography>
              <Typography
                className={clsx(classes.bodyText, classes.settingsMargin)}
              >
                <b>Retail Emails: </b>
                {retailEmailAddresses.join(", ")}
              </Typography>
              <TextField
                fullWidth
                className={classes.settingsMargin}
                variant="outlined"
                color="secondary"
                name="email"
                type="text"
                label="Email"
                {...bindEmail}
              />
              <div className={classes.fullWidthSpaceAround}>
                <Button
                  className={classes.largeButton}
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    setOnPremiseEmailAddresses(
                      onPremiseEmailAddresses.concat(email)
                    );
                    resetEmail();
                  }}
                >
                  + ON PREMISE
                </Button>
                <Button
                  className={classes.largeButton}
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    setRetailEmailAddresses(retailEmailAddresses.concat(email));
                    resetEmail();
                  }}
                >
                  + RETAIL
                </Button>
              </div>
              <br />
              <br />
            </div>
          )}
          {role !== "field1" && (
            <div className={classes.fullWidthCenterColumn}>
              <Typography
                className={clsx(classes.headerText, classes.settingsMargin)}
              >
                On Premise Email Addresses
              </Typography>
              {onPremiseEmailAddresses.map((e, i) => (
                <EmailAddressTextField
                  key={`on-premise-${e}-${i}`}
                  handleUpdateEmailAddress={handleUpdateEmailAddress}
                  handleRemoveEmailAddress={handleRemoveEmailAddress}
                  index={i}
                  emailAddress={e}
                  classes={classes}
                  type="onPremise"
                />
              ))}
              <Button
                variant="contained"
                color="secondary"
                className={clsx(classes.largeButton, classes.settingsMargin)}
                onClick={() => handleAddEmailAddress("onPremise")}
              >
                + ADD EMAIL
              </Button>
              <Typography
                className={clsx(classes.headerText, classes.settingsMargin)}
              >
                Retail Email Addresses
              </Typography>
              {retailEmailAddresses.map((e, i) => (
                <EmailAddressTextField
                  key={`retail-${e}-${i}`}
                  handleUpdateEmailAddress={handleUpdateEmailAddress}
                  handleRemoveEmailAddress={handleRemoveEmailAddress}
                  index={i}
                  emailAddress={e}
                  classes={classes}
                  type="retail"
                />
              ))}
              <Button
                variant="contained"
                color="secondary"
                className={clsx(classes.largeButton, classes.settingsMargin)}
                onClick={() => handleAddEmailAddress("retail")}
              >
                + ADD EMAIL
              </Button>
              <br />
              <br />
            </div>
          )}
          <div className={classes.fullWidthCenterColumn}>
            {!isUpdateLoading && updateStatus && (
              <Typography
                className={clsx(classes.settingsMargin, classes.bodyText)}
              >
                Update Successful!
              </Typography>
            )}
            <Button
              className={classes.largeButton}
              variant="contained"
              color="secondary"
              id="profile"
              onClick={handleSubmit}
              style={{
                float: "right",
                marginBottom: "20px",
                minWidth: "62px",
              }}
            >
              {isUpdateLoading ? <CircularProgress /> : "SUBMIT"}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

DistributorEmailModal.propTypes = {
  open: PropTypes.bool.isRequired,
  id: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
};

export default DistributorEmailModal;
