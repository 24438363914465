/** @jsxImportSource @emotion/react */
import "twin.macro";

import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { useDispatch, useSelector } from "react-redux";

import GetAppIcon from "@mui/icons-material/GetApp";
import {
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Container,
  InputLabel,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import PropTypes from "prop-types";

import {
  MonthSelector,
  YearSelector,
} from "../components/Reporting/ReportSelectors";
import BUAutoComplete from "../components/Utility/AutoCompleteFields/BUAutoComplete";
import BrandAutoComplete from "../components/Utility/AutoCompleteFields/BrandAutoComplete";
import ProgramAutoComplete from "../components/Utility/AutoCompleteFields/ProgramAutoComplete";
import FileDownloadLink from "../components/Utility/FileDownloadLink";
import GeneralSelector from "../components/Utility/Selectors/GeneralSelector";
import MonthYearSelector from "../components/Utility/Selectors/MonthYearSelector";
import { useNoFetch } from "../hooks/UtilityHooks";
import {
  resetFilters,
  setFiltersOpen,
  updateSingleFilter,
} from "../redux/slices/filterSlice";
import {
  clearReports,
  getAuditReport,
} from "../redux/slices/reports/reportSlice";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
  ...theme.reports,
}));

const thisYear = new Date().getFullYear();

const ReportAudit = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [error, setError] = useState(null);
  const [hasGenerated, setHasGenerated] = useState(false);
  const [brands, setBrands] = useState([]);
  const [bus, setBus] = useState([]);
  const [currentPrograms, setCurrentPrograms] = useState([]);
  const [monthYears, setMonthYears] = useState([]);
  const [years, setYears] = useState([]);
  const [isActive, setIsActive] = useState(false);
  const [showSecondary, setShowSecondary] = useState(true);
  const [isRtaDeployment, setIsRtaDeployment] = useState(false);

  const [reset, setReset] = useState(false);

  const currentProgramFilter = useSelector((state) => state.filters.program);

  const { isLoading, reportData: report } = useSelector(
    (state) => state.reports
  );
  const { bu: currentBuFilter, brand: currentBrandFilter } = useSelector(
    (state) => state.filters
  );

  const handleBrand = (value, _type, _filter) => {
    setBrands(value);
    dispatch(updateSingleFilter({ filter: "brand", value }));
  };

  const handleBu = (value, _type, _filter) => {
    setBus(value);
    dispatch(updateSingleFilter({ filter: "bu", value }));
  };

  const handleProgram = (value, _type, _filter) => {
    setCurrentPrograms(value);
    dispatch(updateSingleFilter({ filter: "program", value }));
  };

  const handleMonthYears = (value) => {
    setMonthYears(value);
    dispatch(updateSingleFilter({ filter: "inMarketMonthYears", value }));
    setYears([]);
    dispatch(updateSingleFilter({ filter: "inMarketYears", value: [] }));
  };
  const handleYears = (value) => {
    setYears(value);
    dispatch(updateSingleFilter({ filter: "inMarketYears", value }));
    setMonthYears([]);
    dispatch(updateSingleFilter({ filter: "inMarketMonthYears", value: [] }));
  };

  const handleChipClick = (type, value) => {
    if (type === "brand") {
      let currentBrands = currentBrandFilter.filter((b) => b.id !== value);
      setBrands(currentBrands);
      dispatch(updateSingleFilter({ filter: "brand", value: currentBrands }));
    }
    if (type === "bu") {
      let currentBus = currentBuFilter.filter((b) => b.id !== value);
      setBus(currentBus);
      dispatch(updateSingleFilter({ filter: "bu", value: currentBus }));
    }
    if (type === "program") {
      let currentProgArray = currentProgramFilter.filter(
        (prog) => prog.id !== value
      );
      setCurrentPrograms(currentProgArray);
      dispatch(
        updateSingleFilter({ filter: "program", value: currentProgArray })
      );
    }
  };

  const handleReset = () => {
    setBrands([]);
    setBus([]);
    setCurrentPrograms([]);
    setMonthYears([]);
    dispatch(resetFilters());
    dispatch(clearReports());
    setReset(true);
  };

  const handleGetReport = () => {
    const filterObject = {
      brand: brands,
      bu: bus,
      program: currentPrograms,
      inMarketMonthYears: monthYears,
      inMarketYears: years,
      ...(isActive && { isActive: true }),
      ...(!showSecondary && { orderWindowType: "national" }),
      ...(isRtaDeployment && { rtaDeployment: true }),
    };

    if (
      Object.values(filterObject).every(
        (filter) => typeof filter !== "object" || filter.length === 0
      )
    ) {
      setError("You must select at least one filter to generate this report");
      setTimeout(() => {
        setError(null);
      }, 3000);
    } else {
      dispatch(getAuditReport(filterObject));
      setHasGenerated(true);
    }
  };

  useNoFetch(dispatch);

  useEffect(() => {
    dispatch(setFiltersOpen({ open: false }));
    dispatch(clearReports());
    dispatch(resetFilters());
    setError(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>RTA | Audit Report</title>
      </Helmet>
      <Container className={classes.mainWrapper}>
        <div className={classes.titleBar}>
          <Typography className={classes.titleText}>Audit Report</Typography>
          <div className={classes.configButtons}>
            <div className={classes.innerConfigDiv}>
              <FileDownloadLink file={report} filename="audit_report.csv">
                <Button
                  className={classes.largeButton}
                  variant="contained"
                  color="secondary"
                  startIcon={<GetAppIcon />}
                  disabled={report.length === 0}
                >
                  EXPORT REPORT
                </Button>
              </FileDownloadLink>
            </div>
          </div>
        </div>
        <br />
        <div className={classes.searchComponents} tw="flex gap-2">
          <InputLabel>
            Active only{" "}
            <Checkbox
              checked={isActive}
              onChange={() => setIsActive(!isActive)}
            />
          </InputLabel>
          <InputLabel>
            Show Secondary Order Windows{" "}
            <Checkbox
              checked={showSecondary}
              onChange={() => setShowSecondary(!showSecondary)}
            />
          </InputLabel>
          <InputLabel>
            Show manually deployed?{" "}
            <Checkbox
              checked={!isRtaDeployment}
              onChange={() => setIsRtaDeployment(!isRtaDeployment)}
            />
          </InputLabel>
        </div>
        <br />
        <div className={classes.searchComponents}>
          <BrandAutoComplete
            classes={classes}
            handleChange={handleBrand}
            reset={reset}
            setReset={setReset}
            filterType={"brand"}
          />

          <BUAutoComplete
            classes={classes}
            handleChange={handleBu}
            reset={reset}
            setReset={setReset}
            filterType={"bu"}
          />
          <ProgramAutoComplete
            classes={classes}
            handleChange={handleProgram}
            reset={reset}
            setReset={setReset}
            filterType="report"
            id="report-program-complete"
          />
        </div>
        <br />

        <div className={classes.searchComponents} tw="flex gap-2">
          <Typography tw="flex-1">In Market Month and Year:</Typography>
          <MonthYearSelector
            tw="flex-1"
            label="In market months"
            multiple
            selected={monthYears}
            setSelected={handleMonthYears}
            selectProps={{ renderValue: () => null }}
          />
          <GeneralSelector
            label="In market year"
            tw="flex-1"
            multiple
            options={Array(15)
              .fill(null)
              .map((_, i) => thisYear - 5 + i)
              .map((y) => ({ id: y, label: y }))}
            selectProps={{ renderValue: () => null }}
            selected={years}
            setSelected={handleYears}
          />
        </div>
        <br />
        <div style={{ width: "100%", textAlign: "center" }}>
          <Typography
            className={classes.headerText}
            style={{ marginRight: "10px" }}
          >
            Filters:
          </Typography>
        </div>
        <div className={classes.chipRow}>
          {brands.length > 0 &&
            brands.map((b) => (
              <Chip
                style={{ margin: "auto 2.5px" }}
                color="primary"
                key={b.id}
                label={b.name}
                onDelete={() => handleChipClick("brand", b.id)}
              />
            ))}
          {bus.length > 0 &&
            bus.map((b) => (
              <Chip
                style={{ margin: "auto 2.5px" }}
                color="primary"
                key={b.id}
                label={b.name}
                onDelete={() => handleChipClick("bu", b.id)}
              />
            ))}
          {currentPrograms.length > 0 &&
            currentPrograms.map((p) => (
              <Chip
                style={{ margin: "auto 2.5px" }}
                color="primary"
                key={p.id}
                label={p.name}
                onDelete={() => handleChipClick("program", p.id)}
              />
            ))}
          {monthYears.map((my) => (
            <Chip
              style={{ margin: "auto 2.5px" }}
              color="primary"
              key={my}
              label={my}
              onDelete={() => setMonthYears(monthYears.filter((m) => m !== my))}
            />
          ))}
          {years.map((my) => (
            <Chip
              style={{ margin: "auto 2.5px" }}
              color="primary"
              key={my}
              label={my}
              onDelete={() => setYears(years.filter((m) => m !== my))}
            />
          ))}
        </div>
        <br />
        <div style={{ display: "flex", justifyContent: "center" }}>
          {error && (
            <>
              <br />
              <Typography
                className={classes.bodyText}
                style={{ color: "#ff0000" }}
              >
                {error}
              </Typography>
              <br />
            </>
          )}
        </div>
        <br />
        <div className={classes.buttonRow}>
          <Button
            className={classes.largeButton}
            variant="contained"
            color="secondary"
            onClick={() => handleGetReport()}
            style={{ marginRight: "20px" }}
          >
            GENERATE REPORT
          </Button>
          <Button
            className={classes.largeButton}
            variant="contained"
            color="secondary"
            onClick={handleReset}
          >
            CLEAR FILTERS
          </Button>
        </div>
        <br />
        <br />
        {isLoading && (
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <CircularProgress size={100} style={{ marginTop: "50px" }} />
          </div>
        )}
      </Container>
    </>
  );
};

ReportAudit.propTypes = {};

export default ReportAudit;
