import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

import { fetchBrands } from "../../../redux/slices/items/brandSlice";

const BrandSelector = ({
  handleSelectedPrimaryBrand,
  handleSelectedBrands,
  selectedBrands,
  disabled,
}) => {
  const dispatch = useDispatch();
  const [currentBrands, setCurrentBrands] = useState([]);

  const isLoading = useSelector((state) => state.brands.isLoading);
  const options = useSelector((state) => state.brands.brandList);

  const loading = isLoading;

  const handleBrands = (value) => {
    setCurrentBrands(value);
  };

  useEffect(() => {
    if (options.length === 0) {
      dispatch(fetchBrands());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  useEffect(() => {
    setCurrentBrands(selectedBrands);
    if (selectedBrands.length > 0) {
      handleSelectedPrimaryBrand(selectedBrands[0]);
    } else {
      handleSelectedPrimaryBrand("");
    }
  }, [selectedBrands, handleSelectedPrimaryBrand]);

  return (
    <div>
      <Autocomplete
        multiple
        autoHighlight
        onChange={(_evt, value) => {
          handleSelectedBrands(value);
          handleBrands(value);
        }}
        getOptionLabel={(option) => option.label}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        options={options}
        loading={loading}
        value={currentBrands}
        limitTags={3}
        disabled={disabled}
        size="small"
        renderInput={(params) => (
          <TextField required {...params} label="Brand" variant="outlined" />
        )}
      />
    </div>
  );
};

export default BrandSelector;
