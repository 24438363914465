import { createSlice } from "@reduxjs/toolkit";

import { axiosGet, axiosPatch } from "../../../api/axiosCalls";
import { setError } from "../errorSlice";
import { startGlobalLoad, stopGlobalLoad } from "../globalLoadSlice";
import {
  setFailure as patchFailure,
  setIsLoading as patchLoading,
  patchSuccess,
} from "../patchOrderSlice";
import { buildBrand } from "./helpers";
import { mapBrands } from "./maps";

let initialState = {
  isLoading: false,
  isAssetsLoading: false,
  isUpdateLoading: false,
  isAllBrandsLoading: false,
  brandList: [],
  buList: [],
  productFamilyList: [],
  allBrandList: [],
  multiBrand: null,
  triggerCSVDownload: false,
  updateStatus: false,
  error: null,
};

const loadingFailed = (state, action) => {
  const { error } = action.payload;
  state.isLoading = false;
  state.isAssetsLoading = false;
  state.isUpdateLoading = false;
  state.isAllBrandsLoading = false;
  state.error = error;
};

const brandSlice = createSlice({
  name: "brands",
  initialState,
  reducers: {
    setIsLoading(state) {
      state.isLoading = true;
    },
    setIsAssetsLoading(state) {
      state.isAssetsLoading = true;
    },
    setIsUpdateLoading(state) {
      state.isUpdateLoading = true;
    },
    setAllBrandsLoading(state) {
      state.isAllBrandsLoading = true;
    },
    getBrandsSuccess(state, action) {
      const { brands } = action.payload;
      state.brandList = brands;
      state.isLoading = false;
      state.error = null;
    },
    getAllBrandsSuccess(state, action) {
      const { brands } = action.payload;
      state.allBrandList = brands;
      state.isAllBrandsLoading = false;
      state.triggerCSVDownload = true;
      state.error = null;
    },
    getMultiBrandSuccess(state, action) {
      state.multiBrand = action.payload;
    },
    getAssetsSuccess(state, action) {
      const { bus, families } = action.payload;
      state.buList = bus;
      state.productFamilyList = families;
      state.isAssetsLoading = false;
      state.error = null;
    },
    updateBrandSuccess(state, action) {
      const { brand } = action.payload;
      let updatedBrands = state.brandList.map((b) => {
        if (b.id === brand.id) {
          return brand;
        } else return { ...b };
      });
      state.brandList = updatedBrands;
      state.isUpdateLoading = false;
      state.updateStatus = true;
      state.error = null;
    },
    setUpdateSuccess(state, action) {
      const { updateStatus } = action.payload;
      state.updateStatus = updateStatus;
    },
    setTriggerCSVFalse(state) {
      state.triggerCSVDownload = false;
    },
    clearAllBrands(state) {
      state.allBrandList = [];
    },
    clearBrands(state) {
      state.isLoading = false;
      state.isAssetsLoading = false;
      state.isUpdateLoading = false;
      state.isAllBrandsLoading = false;
      state.brandList = [];
      state.buList = [];
      state.productFamilyList = [];
      state.triggerCSVDownload = false;
      state.allBrandList = [];
      state.error = null;
    },
    setFailure: loadingFailed,
  },
});

export const {
  setIsLoading,
  setIsAssetsLoading,
  setIsUpdateLoading,
  setAllBrandsLoading,
  getBrandsSuccess,
  getAllBrandsSuccess,
  getMultiBrandSuccess,
  getAssetsSuccess,
  createBrandSuccess,
  updateBrandSuccess,
  setUpdateSuccess,
  setTriggerCSVFalse,
  clearAllBrands,
  clearBrands,
  setFailure,
} = brandSlice.actions;

export default brandSlice.reducer;

export const fetchBrands =
  (name, isActive = true) =>
  async (dispatch) => {
    try {
      dispatch(setIsLoading());
      const queryString =
        name && name.length > 0
          ? `/api/brands?filter[name]=${name}&filter[is-active]=${isActive}`
          : `/api/brands?filter[is-active]=${isActive}`;
      const response = await axiosGet(queryString);
      if (response.error) throw response.error;
      const mappedData = mapBrands(response.data);
      dispatch(getBrandsSuccess({ brands: mappedData }));
    } catch (err) {
      dispatch(setFailure({ error: err.toString() }));
      dispatch(setError({ error: err.toString(), source: "Brands" }));
    }
  };
export const getMultiBrand = () => async (dispatch) => {
  try {
    const response = await axiosGet(
      "/api/brands?filter[name]=Multi&filter[is-active]=false"
    );
    if (response.error) throw response.error;
    const mappedData = mapBrands(response.data)[0];
    mappedData.name = mappedData.company; // Not sure why we set this up like this.
    dispatch(getMultiBrandSuccess(mappedData));
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Brands" }));
  }
};

export const fetchAssets = () => async (dispatch) => {
  try {
    dispatch(setIsAssetsLoading());
    const buResponse = await axiosGet("/api/business-units");
    if (buResponse.error) throw buResponse.error;
    let mappedBus = buResponse.data.map((bu) => ({
      id: bu.id,
      code: bu["external-id"],
    }));
    const pfResponse = await axiosGet("/api/product-families");
    if (pfResponse.error) throw pfResponse.error;
    let mappedFamilies = pfResponse.data.map((fam) => ({
      id: fam.id,
      code: fam["external-id"],
    }));
    dispatch(getAssetsSuccess({ bus: mappedBus, families: mappedFamilies }));
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Brands" }));
  }
};

export const updateBrandById = (id, productFamilyId) => async (dispatch) => {
  try {
    dispatch(patchLoading());
    dispatch(setIsUpdateLoading());
    const patchData = buildBrand(productFamilyId);
    const response = await axiosPatch(`/api/brands/${id}`, patchData);
    if (response.error) throw response.error;
    const mappedData = mapBrands([response.data])[0];
    dispatch(updateBrandSuccess({ brand: mappedData }));
    dispatch(patchSuccess());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(patchFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Brands" }));
  }
};

export const fetchBrandReport = () => async (dispatch) => {
  try {
    dispatch(setAllBrandsLoading());
    dispatch(startGlobalLoad());
    const response = await axiosGet("/api/brands");
    if (response.error) throw response.error;
    const mappedData = mapBrands(response.data);
    dispatch(getAllBrandsSuccess({ brands: mappedData }));
    dispatch(stopGlobalLoad());
  } catch (err) {
    dispatch(stopGlobalLoad());
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Brands" }));
  }
};
