import { FC, Suspense, lazy } from "react";

import Loading from "src/components/Utility/Loading";

interface SuspenseRouteProps {
  componentImport: () => Promise<{ default: FC<any> }>;
}

const SuspenseRoute: FC<SuspenseRouteProps> = ({ componentImport }) => {
  const LazyComponent = lazy(componentImport);

  return (
    <Suspense fallback={<Loading partial={false} />}>
      <LazyComponent />
    </Suspense>
  );
};

export default SuspenseRoute;
