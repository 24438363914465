/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import { Typography } from "@mui/material";

import { formatMoneyString } from "src/utility/utilityFunctions";

import PaperTooltip from "@features/ui/PaperTooltip";
import { Budget } from "@models/Budget";

const Chip = tw.span`whitespace-nowrap px-1 rounded text-sm`;

export default function BudgetStatusChip({ budgets }: { budgets: Budget[] }) {
  const openBudgets = budgets.filter((b) => +b.availableAmount < 0);

  if (budgets.length === 0)
    return <Chip tw="text-neutral-700 bg-neutral-50">Exempt</Chip>;

  if (openBudgets.length > 0)
    return (
      <>
        <PaperTooltip
          enterDelay={500}
          placement="bottom-start"
          title={
            <div tw="p-4" onClick={(e) => e.stopPropagation()}>
              <Typography variant="h6" gutterBottom>
                Over Budget
              </Typography>
              <Typography variant="body1" gutterBottom>
                The following budgets are over budget:
              </Typography>
              <ul tw="list-disc ml-4">
                {openBudgets.map((budget) => (
                  <li key={budget.id}>
                    {budget.externalId}{" "}
                    <span tw="text-red-700 pl-3">
                      ({formatMoneyString(budget.availableAmount)})
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          }
        >
          <Chip tw="text-red-900 bg-red-50">Over Budget</Chip>
        </PaperTooltip>
      </>
    );

  return <Chip tw="text-green-900 bg-green-50">OK</Chip>;
}
