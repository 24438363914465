/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useEffect, useRef } from "react";

const ScrollIntoView = ({ trigger }) => {
  const scrollRef = useRef(null);
  useEffect(() => {
    setTimeout(() => {
      scrollRef?.current?.scrollIntoView({ behavior: "smooth" });
    }, 400);
  }, [trigger]);
  return <div tw="scroll-mt-40" ref={scrollRef}></div>;
};

export default ScrollIntoView;
