/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import { CircularProgress } from "@mui/material";

const NoItemsToShow = ({ name, loading }) => {
  return (
    <div
      css={[
        tw`px-4 py-6 transition duration-300 rounded shadow-inner bg-slate-200 text-slate-600`,
        loading && tw`pl-8 bg-slate-100`,
      ]}
    >
      {loading ? (
        <CircularProgress />
      ) : (
        `No ${name} have been added to this program.`
      )}
    </div>
  );
};

export default NoItemsToShow;
