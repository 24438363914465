/** @jsxImportSource @emotion/react */
import "twin.macro";

import React from "react";

import { ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";

import ExportOrderSetButton from "../orderSet/ExportOrderSetButton";
import PreOrderProgramSelector from "./PreOrderProgramSelector";
import PreOrderProgramsSummary from "./PreOrderProgramsSummary";

const PreOrderHeader = () => {
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <div tw="flex justify-between items-center w-full mr-3">
          <PreOrderProgramSelector />
          <ExportOrderSetButton />
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <PreOrderProgramsSummary />
      </AccordionDetails>
    </Accordion>
  );
};

export default PreOrderHeader;
