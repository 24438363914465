import React from "react";
import { useSelector } from "react-redux";

import { Box, Grid, Typography } from "@mui/material";

import { format } from "date-fns";

import { SUPPLIER_ROLES } from "../../../constants/permissions";
import { formatMoneyString } from "../../../utility/utilityFunctions";
import BasicTable from "../RFQ/BasicTable";

const StaticCostTable = ({ label, data }) => (
  <>
    <BasicTable
      size="small"
      rows={[[label, ""], ...data]}
      align={["left", "right"]}
    />
    <br />
  </>
);

const QuoteDetails = ({ currentQuote, classes }) => {
  const {
    // itemType,
    // itemNumber,
    supplierName,
    createdAt,
    updatedAt,
    rfqNote,
    supplierQuoteNumber,
    description,
    ideas,
    brandingArea,
    preProCost,
    colorOptions,
    decorationOptions,
    itemDimensions,
    preProLeadTimeInDays,
    pricingBasedOn,
    pricingTierQuotes,
    costs,
  } = currentQuote;

  const isSupplier = useSelector((state) =>
    SUPPLIER_ROLES.includes(state.user.role)
  );

  const { "per-unit": perUnitCosts, "set-up": setUpCosts } = costs.reduce(
    (a, b) => {
      if (!a[b.costType]) a[b.costType] = [];
      a[b.costType].push([b.name, formatMoneyString(b.amount)]);
      return a;
    },
    {}
  );

  const pricingTierData = pricingTierQuotes.map((t) => [
    t.qty,
    `${t.leadTimeInDays} days`,
    `$${Number(t.price).toLocaleString("en-US", {
      minimumFractionDigits: 2,
    })}`,
  ]);

  const quoteInfo = {
    "Supplier quote number": supplierQuoteNumber,
    // Comments: description,
    // "Ideas for cost savings, enhancements or other ideas": ideas,
    "Item dimensions": itemDimensions,
    "Branding area": brandingArea,
    "Decoration options": decorationOptions,
    "Color options": colorOptions,
    "Pre pro cost": formatMoneyString(preProCost),
    "Pre pro lead time": `${preProLeadTimeInDays} days`,
    "Pricing based on": pricingBasedOn,
  };

  return (
    <>
      <Grid container direction="row" spacing={4}>
        <Grid item xs={6}>
          <Typography variant="h6">Notes</Typography>
          <Box
            sx={{
              p: 3,
              border: "1px solid #C1C1C1",
              borderRadius: 8,
            }}
            className={classes.wrapNewlines}
          >
            {rfqNote}
          </Box>
          <Box color="secondary" sx={{ fontSize: 12, mt: 1, mb: 2 }}>
            {`${isSupplier ? "Gallo's" : "Your"} note, submitted ${format(
              new Date(createdAt),
              "MM/dd/yyyy h:mm aa"
            )}`}
          </Box>
          <Box
            sx={{
              p: 3,
              background: "#D5E1EE",
              color: "#222",
              borderRadius: 8,
            }}
            className={classes.wrapNewlines}
          >
            {description}
          </Box>
          <Box color="secondary" sx={{ fontSize: 12, mt: 1, mb: 2 }}>
            {`${
              isSupplier ? "Your" : `${supplierName}'s`
            } note, submitted ${format(
              new Date(updatedAt),
              "MM/dd/yyyy h:mm aa"
            )}`}
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h6">Costs</Typography>
          <BasicTable
            size="small"
            rows={[["Quantity tier", "Lead time", "Price"], ...pricingTierData]}
            align={["left", "left", "right"]}
          />
          <br />
          {setUpCosts && (
            <StaticCostTable label="Set up costs" data={setUpCosts} />
          )}
          {perUnitCosts && (
            <StaticCostTable label="Per unit costs" data={perUnitCosts} />
          )}
        </Grid>
      </Grid>
      <br />
      <Typography variant="h6">Details from supplier</Typography>
      <Box
        sx={{
          mt: 1,
          mb: 3,
          p: 3,
          background: "whitesmoke",
          borderRadius: 8,
          columns: 3,
        }}
      >
        {Object.entries(quoteInfo).map(([key, val]) => (
          <Box component={Typography} sx={{ mb: 1, fontSize: 14 }} key={key}>
            <b>{key}:</b>&nbsp;{val}
          </Box>
        ))}
      </Box>
      <Typography variant="h6">
        Additional notes (Ideas for Cost Savings, Enhancements or other ideas)
      </Typography>
      <Box
        sx={{
          mt: 1,
          mb: 3,
          p: 3,
          background: "whitesmoke",
          borderRadius: 8,
        }}
      >
        {ideas}
      </Box>
    </>
  );
};

export default QuoteDetails;
