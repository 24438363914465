import { createContext, useContext } from "react";
import { FieldValues, UseFormReturn, useForm } from "react-hook-form";

type ContextProps = UseFormReturn<FieldValues, any>;

const FilterContext = createContext<ContextProps>({} as any);

const FilterContextProvider = ({ children, ...formProps }) => {
  return (
    <FilterContext.Provider value={formProps as unknown as UseFormReturn}>
      {children}
    </FilterContext.Provider>
  );
};

// For some reason, using useForm() inside the same component as the Provider
// will cause watch() to not trigger rerenders.
// My solution is to create a wrapper component that provides the state through props.
export const FilterContextWrapper = ({ children }) => {
  const data = useForm();
  return <FilterContextProvider {...data}>{children}</FilterContextProvider>;
};

export const useFilterContext = () => useContext(FilterContext);
