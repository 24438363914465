import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import GetAppIcon from "@mui/icons-material/GetApp";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";

import { CSVLink } from "@utils/csv";

import { useDetailedInput } from "../../hooks/InputHooks";
import { fetchFilteredItemTypes } from "../../redux/slices/items/itemTypeSlice";
import ItemSubTypeModal from "./ItemSubTypeModal";
import ItemTypeModal from "./ItemTypeModal";
import ItemTypeTable from "./ItemTypeTable";

const newCSVHeaders = [
  { label: "Item Type Code", key: "externalId" },
  { label: "Category Code", key: "categoryCode" },
  { label: "Description", key: "name" },
  { label: "Pack Size", key: "packSize" },
  { label: "Lead Time In Days", key: "leadTime" },
  { label: "Send to content hub", key: "sendToContentHub" },
  { label: "RTA deployment", key: "rtaDeployment" },
  { label: "Spec Type", key: "specType" },
  { label: "SubType Label", key: "label" },
  { label: "Estimated Cost", key: "estimatedCost" },
  { label: "MOQ", key: "moq" },
  { label: "Lead Time (Days)", key: "leadTimeInDays" },
  { label: "Complexity Score", key: "complexityScore" },
  { label: "POS Type", key: "posType" },
];

const useStyles = makeStyles((theme) => ({
  ...theme.global,
  inputField: {
    marginBottom: "15px",
    width: "48%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
}));

const ItemTypes = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const debounce = useRef(null);

  const [itemTypeModal, handleItemTypeModal] = useState(false);
  const [itemSubTypeModal, handleItemSubTypeModal] = useState(false);
  const [currentItemTypeId, setCurrentItemTypeId] = useState(null);
  const [currentItemSubType, setCurrentItemSubType] = useState({});

  const { isLoading, itemTypeList, filteredItemTypeList } = useSelector(
    (state) => state.itemTypes
  );

  const handleItemTypeModalClose = () => {
    handleItemTypeModal(false);
    setCurrentItemTypeId(null);
  };

  const handleItemTypeModalOpen = (id) => {
    setCurrentItemTypeId(id);
    handleItemTypeModal(true);
  };

  const handleItemSubTypeModalClose = () => {
    handleItemSubTypeModal(false);
    setCurrentItemSubType({});
  };

  const handleItemSubTypeModalOpen = (subType) => {
    setCurrentItemSubType(subType);
    handleItemSubTypeModal(true);
  };

  const handleItemTypeSearch = (value, _type, _filter) => {
    clearTimeout(debounce.current);

    debounce.current = setTimeout(() => {
      dispatch(fetchFilteredItemTypes(value));
    }, 250);
  };

  const { value: search, bind: bindSearch } = useDetailedInput(
    "",
    handleItemTypeSearch
  );

  useEffect(() => {
    dispatch(fetchFilteredItemTypes(""));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const generateSubTypeCSVList = () => {
    return filteredItemTypeList.flatMap(({ itemSubTypes, ...itemProps }) =>
      itemSubTypes.map((subType) => ({
        ...itemProps,
        ...subType,
      }))
    );
  };

  return (
    <>
      {itemTypeModal && (
        <ItemTypeModal
          open={itemTypeModal}
          handleClose={handleItemTypeModalClose}
          id={currentItemTypeId}
          itemTypeList={filteredItemTypeList}
        />
      )}
      {itemSubTypeModal && (
        <ItemSubTypeModal
          open={itemSubTypeModal}
          handleClose={handleItemSubTypeModalClose}
          subType={currentItemSubType}
        />
      )}
      <div className={classes.titleBar}>
        <Typography className={classes.titleText}>Edit Item Types</Typography>
        <div className={classes.innerConfigDiv}>
          {itemTypeList.length > 0 && (
            <CSVLink
              data={generateSubTypeCSVList()}
              headers={newCSVHeaders}
              filename="rta_item_types.csv"
            >
              <Button
                className={classes.largeButton}
                variant="contained"
                color="secondary"
                style={{ marginRight: "10px" }}
                startIcon={<GetAppIcon />}
              >
                EXPORT ITEM TYPE REPORT
              </Button>
            </CSVLink>
          )}
        </div>
      </div>
      <br />
      <div style={{ width: "100%" }}>
        <TextField
          size="small"
          className={classes.inputField}
          variant="outlined"
          color="secondary"
          name="search"
          type="text"
          label="Search Item Types"
          value={search}
          {...bindSearch}
        />
      </div>
      <br />
      <ItemTypeTable
        handleItemTypeClick={handleItemTypeModalOpen}
        handleItemSubTypeClick={handleItemSubTypeModalOpen}
        itemTypes={filteredItemTypeList}
        isLoading={isLoading}
      />
    </>
  );
};

export default ItemTypes;
