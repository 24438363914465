import { useMutation, useQueryClient } from "@tanstack/react-query";

import { Order } from "@models/Order";
import client from "@services/api";

import { orderSetsKeyFactory } from "../../../queries/orderSetQueries";
import useOrderSetId from "../useOrderSetId";

export default function useDeleteOrder() {
  const queryClient = useQueryClient();
  const orderSetId = useOrderSetId();

  return useMutation({
    mutationFn: (orderId: string) => {
      return client.remove(`orders/${orderId}`).then(() => ({ orderId }));
    },
    onSuccess: ({ orderId }) => {
      return queryClient.setQueryData(
        orderSetsKeyFactory.detail(orderSetId)._ctx.orders.queryKey,
        (orders: Order[]) => {
          return orders.filter((o: any) => o.id !== orderId);
        }
      );
    },
  });
}
