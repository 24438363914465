import React from "react";

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import { getWindowSelections, monthMap } from "../../utility/utilityFunctions";

export const OrderMonthYearSelector = React.memo(
  ({ handleAddOrderMonthYear, classes, label, wide }) => {
    let orderWindows = getWindowSelections(null);

    return (
      <FormControl
        className={wide ? classes.queryFieldWide : classes.queryField}
        size="small"
        variant="outlined"
      >
        <InputLabel id="order-window-label">{label}</InputLabel>
        <Select
          variant="outlined"
          labelId="order-window-label"
          label={label}
          id="order-window-selector"
          value=""
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "center",
            },
          }}
          onChange={(evt) => handleAddOrderMonthYear(evt.target.value)}
        >
          {orderWindows.map((ow, i) => (
            <MenuItem key={i} value={ow}>{`${monthMap[ow.month]} - ${
              ow.year
            }`}</MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }
);

export const MonthSelector = React.memo(
  ({ currentMonth, setCurrentMonth, classes, wide }) => {
    return (
      <FormControl
        className={wide ? classes.queryFieldWide : classes.queryField}
        size="small"
        variant="outlined"
      >
        <InputLabel id="month-selector-label">Month</InputLabel>
        <Select
          variant="outlined"
          labelId="month-selector-label"
          label="Month"
          id="month-selector"
          value={currentMonth}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "center",
            },
          }}
          onChange={(evt) => setCurrentMonth(evt.target.value)}
        >
          <MenuItem value={"1"}>January</MenuItem>
          <MenuItem value={"2"}>February</MenuItem>
          <MenuItem value={"3"}>March</MenuItem>
          <MenuItem value={"4"}>April</MenuItem>
          <MenuItem value={"5"}>May</MenuItem>
          <MenuItem value={"6"}>June</MenuItem>
          <MenuItem value={"7"}>July</MenuItem>
          <MenuItem value={"8"}>August</MenuItem>
          <MenuItem value={"9"}>September</MenuItem>
          <MenuItem value={"10"}>October</MenuItem>
          <MenuItem value={"11"}>November</MenuItem>
          <MenuItem value={"12"}>December</MenuItem>
        </Select>
      </FormControl>
    );
  }
);

export const YearSelector = React.memo(
  ({ currentYear, setCurrentYear, classes, wide }) => {
    return (
      <FormControl
        className={wide ? classes.queryFieldWide : classes.queryField}
        size="small"
        variant="outlined"
      >
        <InputLabel id="focus-year-selector-lable">Year</InputLabel>
        <Select
          variant="outlined"
          labelId="focus-year-selector-lable"
          label="Year"
          id="focus-year-selector"
          value={currentYear}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "center",
            },
          }}
          onChange={(evt) => setCurrentYear(evt.target.value)}
        >
          <MenuItem value={"2019"}>2019</MenuItem>
          <MenuItem value={"2020"}>2020</MenuItem>
          <MenuItem value={"2021"}>2021</MenuItem>
          <MenuItem value={"2022"}>2022</MenuItem>
          <MenuItem value={"2023"}>2023</MenuItem>
          <MenuItem value={"2024"}>2024</MenuItem>
          <MenuItem value={"2025"}>2025</MenuItem>
          <MenuItem value={"2026"}>2026</MenuItem>
          <MenuItem value={"2027"}>2027</MenuItem>
          <MenuItem value={"2028"}>2028</MenuItem>
          <MenuItem value={"2029"}>2029</MenuItem>
          <MenuItem value={"2030"}>2030</MenuItem>
        </Select>
      </FormControl>
    );
  }
);

export const PurchasingSelector = React.memo(
  ({ currentPurchaser, setCurrentPurchaser, classes, wide }) => {
    return (
      <FormControl
        className={wide ? classes.queryFieldWide : classes.queryField}
        size="small"
        variant="outlined"
      >
        <InputLabel id="purchaser-selector-lable">Purchasing Group</InputLabel>
        <Select
          variant="outlined"
          labelId="purchaser-selector-lable"
          label="Purchasing Group"
          id="purchaser-selector"
          value={currentPurchaser}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "center",
            },
          }}
          onChange={(evt) => setCurrentPurchaser(evt.target.value)}
        >
          <MenuItem value={"gallo"}>Gallo</MenuItem>
          <MenuItem value={"select"}>Select</MenuItem>
          <MenuItem value={"all"}>All</MenuItem>
        </Select>
      </FormControl>
    );
  }
);

export const StatusSelector = React.memo(
  ({ currentStatus, setCurrentStatus, classes, wide }) => {
    return (
      <FormControl
        className={wide ? classes.queryFieldWide : classes.queryField}
        size="small"
        variant="outlined"
      >
        <InputLabel id="status-selector-lable">Order Status</InputLabel>
        <Select
          variant="outlined"
          labelId="status-selector-lable"
          label="Order Status"
          id="status-selector"
          value={currentStatus}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "center",
            },
          }}
          onChange={(evt) => setCurrentStatus(evt.target.value)}
        >
          <MenuItem value={"submitted"}>Submitted</MenuItem>
          <MenuItem value={"approved"}>Approved</MenuItem>
          <MenuItem value={"submitted,approved"}>Submitted / Approved</MenuItem>
          <MenuItem value={"draft,in-progress,submitted,approved"}>
            All Statuses
          </MenuItem>
        </Select>
      </FormControl>
    );
  }
);

export const CanceledStatusSelector = React.memo(
  ({ currentStatus, setCurrentStatus, classes, wide }) => {
    return (
      <FormControl
        className={wide ? classes.queryFieldWide : classes.queryField}
        size="small"
        variant="outlined"
      >
        <InputLabel id="status-selector-lable">Order Status</InputLabel>
        <Select
          variant="outlined"
          labelId="status-selector-lable"
          label="Order Status"
          id="status-selector"
          value={currentStatus}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "center",
            },
          }}
          onChange={(evt) => setCurrentStatus(evt.target.value)}
        >
          <MenuItem value={""}>---</MenuItem>
          <MenuItem value={"not-ordered"}>Not Ordered</MenuItem>
          <MenuItem value={"draft"}>Draft</MenuItem>
          <MenuItem value={"submitted"}>Submitted</MenuItem>
          <MenuItem value={"approved"}>Approved</MenuItem>
          <MenuItem value={"submitted,approved"}>Submitted / Approved</MenuItem>
          <MenuItem value={"order-canceled"}>Order Canceled</MenuItem>
          <MenuItem value={"compliance-canceled"}>Compliance Canceled</MenuItem>
        </Select>
      </FormControl>
    );
  }
);

export const WarehouseSelector = React.memo(
  ({ currentWarehouse, setCurrentWarehouse, classes, wide }) => {
    return (
      <FormControl
        className={wide ? classes.queryFieldWide : classes.queryField}
        size="small"
        variant="outlined"
      >
        <InputLabel id="warehouse-selector-lable">Warehouse</InputLabel>
        <Select
          variant="outlined"
          labelId="warehouse-selector-lable"
          label="Warehouse"
          id="warehouse-selector"
          value={currentWarehouse}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "center",
            },
          }}
          onChange={(evt) => setCurrentWarehouse(evt.target.value)}
        >
          <MenuItem value={"all"}>All</MenuItem>
          <MenuItem value={"rapid"}>Rapid</MenuItem>
          <MenuItem value={"champion"}>Champion</MenuItem>
        </Select>
      </FormControl>
    );
  }
);

export const ReportOrderTypeSelector = React.memo(
  ({
    currentOrderType,
    setCurrentOrderType,
    classes,
    wide,
    noInventory = false,
  }) => {
    return (
      <FormControl
        className={wide ? classes.queryFieldWide : classes.queryField}
        size="small"
        variant="outlined"
      >
        <InputLabel id="order-type-selector-lable">Order Type</InputLabel>
        <Select
          variant="outlined"
          labelId="order-type-selector-lable"
          label="order-type"
          id="order-type-selector"
          value={currentOrderType}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "center",
            },
          }}
          onChange={(evt) => setCurrentOrderType(evt.target.value)}
        >
          <MenuItem value={"all"}>All</MenuItem>
          <MenuItem value={"pre-order"}>Pre Order</MenuItem>
          <MenuItem value={"on-demand"}>On Demand</MenuItem>
          {!noInventory && (
            <MenuItem value={"from-inventory"}>Inventory</MenuItem>
          )}
        </Select>
      </FormControl>
    );
  }
);

export const DateRangeSelector = React.memo(
  ({ currentDateRange, setDateRange, classes, wide }) => {
    return (
      <FormControl
        className={wide ? classes.queryFieldWide : classes.queryField}
        size="small"
        variant="outlined"
      >
        <InputLabel id="date-range-selector-lable">Date Range</InputLabel>
        <Select
          variant="outlined"
          labelId="date-range-selector-lable"
          label="order-type"
          id="date-range-selector"
          value={currentDateRange}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "center",
            },
          }}
          onChange={(evt) => setDateRange(evt.target.value)}
        >
          <MenuItem value={"actual"}>Actual Ship Date</MenuItem>
          <MenuItem value={"estimated"}>Estimated Ship Date</MenuItem>
          <MenuItem value={"order"}>Order Date</MenuItem>
        </Select>
      </FormControl>
    );
  }
);

export const PODateRangeSelector = React.memo(
  ({ currentDateRange, setDateRange, classes, wide }) => {
    return (
      <FormControl
        className={wide ? classes.queryFieldWide : classes.queryField}
        size="small"
        variant="outlined"
      >
        <InputLabel id="date-range-selector-lable">Date Range</InputLabel>
        <Select
          variant="outlined"
          labelId="date-range-selector-lable"
          label="order-type"
          id="date-range-selector"
          value={currentDateRange}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "center",
            },
          }}
          onChange={(evt) => setDateRange(evt.target.value)}
        >
          <MenuItem value={"submitted"}>Submitted At Date</MenuItem>
          <MenuItem value={"in-market"}>In Market Date</MenuItem>
        </Select>
      </FormControl>
    );
  }
);

export const POStatusSelector = React.memo(
  ({ currentStatus, setStatus, classes, wide }) => {
    return (
      <FormControl
        className={wide ? classes.queryFieldWide : classes.queryField}
        size="small"
        variant="outlined"
      >
        <InputLabel id="po-status-selector-lable">Status</InputLabel>
        <Select
          variant="outlined"
          labelId="po-status-selector-lable"
          label="Status"
          id="po-status-selector"
          value={currentStatus}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "center",
            },
          }}
          onChange={(evt) => setStatus(evt.target.value)}
        >
          <MenuItem value={"all"}>All</MenuItem>
          <MenuItem value={"submitted"}>Submitted</MenuItem>
          <MenuItem value={"in-progress"}>In Progress</MenuItem>
          <MenuItem value={"shipping-hold"}>Shipping Hold</MenuItem>
          <MenuItem value={"complete"}>Complete</MenuItem>
        </Select>
      </FormControl>
    );
  }
);
