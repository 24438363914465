import { sortBy } from "lodash";

import { Image } from "@models/Image";
import { Item } from "@models/Item";
import { VariantAllocation } from "@models/VariantAllocation";

export function getItemPreviewImage(item: Item): Image | null {
  const sortedImages = sortBy(item.images, "position");
  const thumb = sortedImages.find((i) => i.type === "thumbnail");
  // replicating the old behavior of returning the second image if there is no thumbnail
  // image at index 1 seems to be the coupon design on templated-coupons
  return thumb ?? sortedImages[1] ?? sortedImages[0] ?? null;
}

export const allocationName = (alloc: VariantAllocation): string =>
  alloc.subStateName ?? alloc.territoryName ?? "";
