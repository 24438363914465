import _ from "lodash";

import { AnaplanProgram } from "@models/AnaplanProgram";

export function getAnaplanProgramsLabel(anaplanPrograms: AnaplanProgram[]) {
  return (
    _(anaplanPrograms)
      .groupBy("name")
      .entries()
      .map(([name, programs]) => {
        const codes = programs.map((program) => program.code);
        return `${name} (${codes.join(", ")})`;
      })
      .value()
      .join(", ") || "Miscellaneous Program"
  );
}
