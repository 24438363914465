import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Button, CircularProgress, Divider, Typography } from "@mui/material";

import clsx from "clsx";
import PropTypes from "prop-types";

import { approveOrDeny } from "../../redux/slices/addresses/addressSlice";

const AddressChangeRequest = ({ classes, changeRequest, address }) => {
  const dispatch = useDispatch();

  const [hasClicked, setHasClicked] = useState(false);

  const { isUpdateLoading } = useSelector((state) => state.addresses);

  const handleApproveOrDeny = (action) => {
    if (action === "approveAndApply") {
      dispatch(approveOrDeny(changeRequest.id, true, "approved"));
    } else if (action === "approve") {
      dispatch(approveOrDeny(changeRequest.id, false, "approved"));
    } else dispatch(approveOrDeny(changeRequest.id, false, "denied"));
    setHasClicked(true);
  };

  const determineColor = (field, value) => {
    if (
      address[field] === value ||
      ["approved", "applied", "denied"].includes(changeRequest.changeStatus)
    ) {
      return "#4C4C4C";
    } else return "#920000";
  };

  useEffect(() => {
    if (hasClicked && !isUpdateLoading) setHasClicked(false);
  }, [hasClicked, isUpdateLoading]);

  return (
    <div className={classes.changeRequest}>
      <Typography className={clsx(classes.settingsMargin, classes.bodyText)}>
        <b>Change Requested By: </b>
        {`${changeRequest.changeRequestedByName}, ${changeRequest.changeRequestedByEmail}`}
      </Typography>
      <Typography className={clsx(classes.settingsMargin, classes.bodyText)}>
        <b>Effective Date: </b>
        {changeRequest.changeEffectiveDate}
      </Typography>
      <Typography className={clsx(classes.settingsMargin, classes.bodyText)}>
        <b>Note: </b>
        {changeRequest.changeNote}
      </Typography>
      <br />
      <Typography className={classes.headerText}>Requested Changes:</Typography>
      <br />
      <Typography
        className={classes.bodyText}
        style={{ color: determineColor("name", changeRequest.name) }}
      >
        {changeRequest.name}
      </Typography>
      <Typography
        className={classes.bodyText}
        style={{ color: determineColor("isActive", changeRequest.isActive) }}
      >
        <b>Is Active: </b>
        {changeRequest.isActive}
      </Typography>
      <Typography
        className={classes.bodyText}
        style={{
          color: determineColor(
            "streetAddressOne",
            changeRequest.streetAddressOne
          ),
        }}
      >
        <b>Street Address One: </b>
        {changeRequest.streetAddressOne}
      </Typography>
      {changeRequest.streetAddressTwo && (
        <Typography
          className={classes.bodyText}
          style={{
            color: determineColor(
              "streetAddressTwo",
              changeRequest.streetAddressTwo
            ),
          }}
        >
          <b>Street Address Two: </b>
          {changeRequest.streetAddressTwo}
        </Typography>
      )}
      {changeRequest.defaultAttn && (
        <Typography
          className={classes.bodyText}
          style={{
            color: determineColor("defaultAttn", changeRequest.defaultAttn),
          }}
        >
          <b>Attention: </b>
          {changeRequest.defaultAttn}
        </Typography>
      )}
      <Typography
        className={classes.bodyText}
        style={{
          color: determineColor("phoneNumber", changeRequest.phoneNumber),
        }}
      >
        <b>Phone Number: </b>
        {changeRequest.phoneNumber}
      </Typography>
      <Typography
        className={classes.bodyText}
        style={{ color: determineColor("city", changeRequest.city) }}
      >
        <b>City: </b>
        {changeRequest.city}
      </Typography>
      <Typography
        className={classes.bodyText}
        style={{ color: determineColor("country", changeRequest.country) }}
      >
        <b>Country: </b>
        {changeRequest.country}
      </Typography>
      <Typography
        className={classes.bodyText}
        style={{ color: determineColor("stateCode", changeRequest.stateCode) }}
      >
        <b>State: </b>
        {changeRequest.stateCode}
      </Typography>
      <Typography
        className={classes.bodyText}
        style={{ color: determineColor("zip", changeRequest.zip) }}
      >
        <b>Zip: </b>
        {changeRequest.zip}
      </Typography>
      <br />
      <Divider className={classes.fullWidth} />
      <br />
      {!isUpdateLoading &&
        !hasClicked &&
        changeRequest.changeStatus === "pending" && (
          <>
            <Typography
              className={clsx(classes.bodyText, classes.settingsMargin)}
            >
              <em>
                If you would like the change to be applied on the effective
                date, click APPROVE, otherwise click APPROVE AND APPLY NOW to
                make the change effective immediately. Change requests that are
                approved are applied at midnight each day.
              </em>
            </Typography>
            <div className={classes.fullWidthSpaceAround}>
              <Button
                className={classes.largeButton}
                variant="contained"
                color="secondary"
                onClick={() => handleApproveOrDeny("approveAndApply")}
              >
                APPROVE AND APPLY NOW
              </Button>
              <Button
                className={classes.largeButton}
                variant="contained"
                color="secondary"
                onClick={() => handleApproveOrDeny("approve")}
              >
                APPROVE
              </Button>
            </div>
            <br />
            <Button
              className={classes.largeButton}
              variant="contained"
              color="secondary"
              onClick={() => handleApproveOrDeny("deny")}
            >
              DENY
            </Button>
          </>
        )}
      {!isUpdateLoading &&
        !hasClicked &&
        ["approved", "denied", "applied"].includes(
          changeRequest.changeStatus
        ) && (
          <>
            <Typography className={classes.headerText}>{`Status: ${
              changeRequest.changeStatus[0].toUpperCase() +
              changeRequest.changeStatus.slice(1)
            }`}</Typography>
            <br />
            <Typography
              className={clsx(classes.settingsMargin, classes.bodyText)}
            >
              <b>{`Change ${
                ["approved", "applied"].includes(changeRequest.changeStatus)
                  ? "Approved"
                  : "Denied"
              } By: `}</b>
              {`${changeRequest.approvedOrDeniedByName}, ${changeRequest.approvedOrDeniedByEmail}`}
            </Typography>
            <Typography
              className={clsx(classes.settingsMargin, classes.bodyText)}
            >
              <b>{`Change ${
                changeRequest.changeStatus === "approved"
                  ? "Approved"
                  : changeRequest.changeStatus === "applied"
                  ? "Applied"
                  : "Denied"
              } On: `}</b>
              {changeRequest.approvedOrDeniedAt}
            </Typography>
          </>
        )}
      {isUpdateLoading && hasClicked && (
        <>
          <br />
          <CircularProgress />
          <br />
        </>
      )}
    </div>
  );
};

AddressChangeRequest.propTypes = {
  classes: PropTypes.object.isRequired,
  changeRequest: PropTypes.object.isRequired,
};

export default AddressChangeRequest;
