import { useEffect, useState } from "react";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
import Helmet from "react-helmet";
import { useDispatch, useSelector } from "react-redux";

import { Container, LinearProgress, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import HideFiltersButtonToggle from "src/components/Filtering/HideFiltersButtonToggle";

import FilterChipList from "../components/Filtering/FilterChipList";
import ItemPreviewModal from "../components/ItemPreview/ItemPreviewModal";
import ShelfInventoryTable from "../components/Settings/ShelfInventoryTable";
import { useChannelUpdate, useInitialFilters } from "../hooks/UtilityHooks";
import {
  clearCurrentItem,
  fetchNextFilteredItems,
} from "../redux/slices/items/itemSlice";

/*
The shelf inventory admin is a high level view of all current inventory items, and gives access
to the shelf inventory create or update view
*/

const defaultFilters = {
  brand: [],
  itemType: [],
  bu: [],
  program: [],
  favItems: [],
  exclusiveTerritory: [],
  orderType: "inventory",
  itemNumber: "",
  itemDesc: "",
  itemOrderType: "all",
  isItemArchived: false,
};

const useStyles = makeStyles((theme) => ({
  ...theme.global,
}));

const ShelfInventoryAdmin = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [previewModal, handlePreviewModal] = useState(false);
  const [currentItem, handleCurrentItem] = useState({});

  const filtersOpen = useSelector((state) => state.filters.filtersOpen);
  const currentChannel = useSelector((state) => state.user.currentChannel);
  const nextLink = useSelector((state) => state.items.nextLink);
  const isNextLoading = useSelector((state) => state.items.isNextLoading);

  const handleBottomScroll = () => {
    if (nextLink && !isNextLoading) {
      if (scrollRef.current && scrollRef.current.scrollTop !== 0) {
        dispatch(fetchNextFilteredItems(nextLink));
      }
    }
  };

  const scrollRef = useBottomScrollListener(handleBottomScroll, {
    offset: 500,
    debounceOptions: {
      leading: true,
      trailing: false,
    },
  });
  const currentItems = useSelector((state) => state.items.items);
  const itemsLoading = useSelector((state) => state.items.isLoading);

  const currentChannelFilter = useSelector((state) => state.filters.channel);
  const currentUserRole = useSelector((state) => state.user.role);

  defaultFilters.channel = currentChannel;

  const handlePreview = (itemNumber) => {
    let item = currentItems.find((item) => item.itemNumber === itemNumber);
    handleCurrentItem(item);
    handlePreviewModal(true);
  };

  const handleModalClose = () => {
    handlePreviewModal(false);
  };

  useChannelUpdate(currentChannel, currentChannelFilter, dispatch);

  useInitialFilters(`item-all`, defaultFilters);

  useEffect(() => {
    dispatch(clearCurrentItem());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>RTA | Shelf Inventory Admin</title>
        {currentUserRole === "purchaser" && !filtersOpen && (
          <script type="text/javascript">{` Beacon('suggest', ['601438192042ff6d1b2a8ab3'])`}</script>
        )}
        {currentUserRole === "read-only" && !filtersOpen && (
          <script type="text/javascript">{` Beacon('suggest', ['600ed315c64fe14d0e1fe351'])`}</script>
        )}
      </Helmet>
      {previewModal && currentItem && (
        <ItemPreviewModal
          type={"catalog"}
          currentItem={currentItem}
          handleClose={handleModalClose}
          previewModal={previewModal}
        />
      )}
      <Container className={classes.mainWrapper}>
        <div className={classes.titleBar}>
          <Typography className={classes.titleText} variant="h5">
            Shelf Inventory Admin
          </Typography>
        </div>
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignContent: "center",
              marginBottom: "10px",
            }}
          >
            <HideFiltersButtonToggle />
            <FilterChipList classes={classes} />
            <br />
          </div>
          <ShelfInventoryTable
            handlePreview={handlePreview}
            currentItems={currentItems}
            isItemsLoading={itemsLoading}
            scrollRef={scrollRef}
          />
          {isNextLoading && (
            <div style={{ width: "100%" }}>
              <LinearProgress />
            </div>
          )}
          {!isNextLoading && (
            <div style={{ width: "100%", height: "4px" }}></div>
          )}
        </>
      </Container>
      <br />
    </>
  );
};

export default ShelfInventoryAdmin;
