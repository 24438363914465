/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useProgramInfo } from "../../../hooks/planningToolHooks";
import { fetchItemPrograms } from "../../../redux/slices/planningTool/itemProgramSlice";
import CollapsibleTable from "../../CollapsibleTable";
import { SmallIndexCounter } from "../../StyledComponents";
import NoItemsToShow from "./NoItemsToShow";
import RerunEdit from "./RerunEdit";
import RerunNew from "./RerunNew";

const displayNames = (arr) => arr.map((i) => i.name).join(", ");

const RerunSection = ({ items, countOffset }) => {
  const dispatch = useDispatch();

  const { programId, brandAssignmentLevel } = useProgramInfo();
  const { isLoading } = useSelector((state) => state.itemPrograms);

  useEffect(() => {
    dispatch(fetchItemPrograms(programId));
  }, [programId, dispatch]);

  return (
    <>
      <RerunNew />

      {!isLoading && items.length ? (
        <CollapsibleTable
          unmountOnExit={false}
          headers={[
            { cell: "Sequence #" },
            { cell: "Brand" },
            { cell: "Item Type" },
            { cell: "Description" },
            { cell: "Status" },
            { cell: "" },
          ]}
          rows={items.map(({ openItemRow, ...item }, i) => ({
            key: item.id,
            row: [
              {
                cell: <b>#{item.itemNumber}</b>,
              },
              {
                cell: displayNames(
                  brandAssignmentLevel === "item"
                    ? item.brands
                    : item.itemBrands
                ),
              },
              { cell: item.type },
              { cell: item.comment },
              { cell: item.itemStatus },
              { cell: "" },

              {
                cell: (
                  <SmallIndexCounter>{countOffset + i + 1}</SmallIndexCounter>
                ),
                style: tw`absolute inset-0 left-auto border-0`,
              },
            ],
            content: <RerunEdit item={item} />,
            expanded: openItemRow,
          }))}
        />
      ) : (
        <NoItemsToShow name="re-runs" loading={isLoading} />
      )}
    </>
  );
};

export default RerunSection;
