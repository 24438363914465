import { createSlice } from "@reduxjs/toolkit";

import { axiosGetWithNext } from "../../../api/axiosCalls";
import { stringToCents } from "../../../utility/utilityFunctions";
import { setError } from "../errorSlice";
import { setProgramStatus } from "../programs/programsSlice";

let initialState = {
  isLoading: false,
  preOrderSummary: [],
  territories: [],
  preOrderTotal: 0,
  preOrderTotalMod: 0,
  error: null,
};

const loadingFailed = (state, action) => {
  const { error } = action.payload;
  state.isLoading = false;
  state.error = error;
};

const preOrderDetailSlice = createSlice({
  name: "preOrderDetails",
  initialState,
  reducers: {
    setIsLoading(state) {
      state.isLoading = true;
    },
    setPreOrderSummary(state, action) {
      const { preOrders, totalCost, totalModCost } = action.payload;
      state.preOrderSummary = [...preOrders];
      state.preOrderTotal = totalCost;
      state.preOrderTotalMod = totalModCost;
      state.isLoading = false;
      state.error = null;
    },
    setPreOrderDetails(state, action) {
      const { territories, programId } = action.payload;
      state.programId = programId;
      state.territories =
        territories && territories.length > 0 ? [...territories] : [];
    },
    setProgramName(state, action) {
      const { name } = action.payload;
      state.name = name;
    },
    clearPreOrderDetail(state) {
      state.isLoading = false;
      state.preOrderSummary = [];
      state.territories = [];
      state.preOrderTotal = 0;
      state.preOrderTotalMod = 0;
      state.error = null;
    },
    setFailure: loadingFailed,
  },
});

export const {
  setIsLoading,
  setPreOrderSummary,
  setPreOrderDetails,
  setProgramName,
  clearPreOrderDetail,
  setFailure,
} = preOrderDetailSlice.actions;

export default preOrderDetailSlice.reducer;

export const fetchPreOrders = (id, program, terrId) => async (dispatch) => {
  const monthMap = {
    "01": "January",
    "02": "February",
    "03": "March",
    "04": "April",
    "05": "May",
    "06": "June",
    "07": "July",
    "08": "August",
    "09": "September",
    10: "October",
    11: "November",
    12: "December",
  };
  try {
    dispatch(setIsLoading());
    let allOrders = [];
    let totalCost = 0;
    let totalModCost = 0;
    let nextLink = "next";
    const queryString = !terrId
      ? `/api/order-sets?filter[type]=pre-order&filter[is-ad-hoc]=false&filter[user-ids]=${id}&filter[all-regions]=true&filter[is-pre-order-active]=true`
      : `/api/order-sets?filter[type]=pre-order&filter[is-ad-hoc]=false&filter[user-ids]=${id}&filter[territory-ids]=${terrId}&filter[is-pre-order-active]=true`;
    while (nextLink) {
      let response =
        nextLink === "next"
          ? await axiosGetWithNext(queryString)
          : await axiosGetWithNext(nextLink);
      if (response.error) {
        throw response.error;
      }
      nextLink = response.data.nextLink;
      let grandTotal = 0;
      let totalMod = 0;

      let preOrders = response.data.data.map((order) => {
        let total = stringToCents(order["total-estimated-cost"]);
        grandTotal += total;
        if (order.program.id !== program) {
          totalMod += total;
        }
        return {
          preOrderId: order.id,
          orderMonthId: order["order-calendar-month"].id,
          name: `${
            order.program.brands.length > 1
              ? "Multi Brand"
              : order.program.brands[0].name
          } - ${order.program.name} - ${
            monthMap[
              order["order-calendar-month"]["purchasing-close-date"].split(
                "-"
              )[1]
            ]
          }`,
          orderCloseMonth:
            monthMap[
              order["order-calendar-month"]["purchasing-close-date"].split(
                "-"
              )[1]
            ],
          programId: order.program.id,
          territories:
            order.program.type === "National"
              ? ["National"]
              : order["territory-names"].split(", "),
          totalQty: order["total-quantity"],
          totalEstCost: total,
          status: order.status,
          isComplete: order["is-work-complete"],
        };
      });
      totalCost += grandTotal;
      totalModCost += totalMod;
      preOrders.forEach((order) => {
        let status;
        if (order.status === "in-progress" && order.isComplete) {
          status = "complete";
        } else status = order.status;
        dispatch(
          setProgramStatus({
            program: order.programId,
            orderMonthId: order.orderMonthId,
            status: status,
          })
        );
      });
      allOrders = allOrders.concat(preOrders);
    }

    dispatch(
      setPreOrderSummary({
        preOrders: allOrders,
        totalCost: totalCost,
        totalModCost: totalModCost,
      })
    );
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Pre Order Details" }));
  }
};
