/*
All items images are stored on Cloudinary, so we get the cloudinary id from the api, and need
to generate a url based on the id. Items have a variety of images, and this function handles setting
up the urls, and fallback urls if images don't exist.
*/
export const handleImages = (images) => {
  if (images.length === 0) {
    return {
      imgUrlThumb:
        "https://res.cloudinary.com/joshdowns-dev/image/upload/v1607091694/Select/NotFound_v0kyue.png",
      imgUrlLg: [
        "https://res.cloudinary.com/joshdowns-dev/image/upload/v1607091694/Select/NotFound_v0kyue.png",
      ],
    };
  } else {
    let thumb = images.find((img) => img.type === "thumbnail");
    if (!thumb) {
      thumb = images[1] ? images[1] : images[0];
    }
    let largeArray = images
      .filter((img) => img.type === "large")
      .sort((a, b) => {
        return a.position < b.position ? -1 : a.position > b.position ? 1 : 0;
      })
      .flatMap((img) =>
        Array(img["pdf-page-count"] || 1)
          .fill(0)
          .map(
            (_, i) =>
              `https://res.cloudinary.com/brandhub/image/upload/${
                img["pdf-page-count"] ? `pg_${i + 1}/` : ``
              }${img["cloudinary-id"]}.jpg`
          )
      );
    if (largeArray.length === 0) {
      largeArray = [
        "https://res.cloudinary.com/joshdowns-dev/image/upload/v1607091694/Select/NotFound_v0kyue.png",
      ];
    }
    return {
      imgUrlThumb: thumb
        ? `https://res.cloudinary.com/brandhub/image/upload/c_limit,h_120,w_120/${thumb["cloudinary-id"]}.jpg`
        : "https://res.cloudinary.com/joshdowns-dev/image/upload/v1607091694/Select/NotFound_v0kyue.png",
      imgUrlLg: largeArray,
    };
  }
};

/*
Items can have allocated qty in inventory, so we store allocations on the item so the UI
can display the correct available amount based on the users territory.
*/
export const handleVariantAllocations = (variantAllocations) => {
  if (!variantAllocations || variantAllocations.length === 0) return [];
  const allocations = variantAllocations.map((it) => ({
    id: it.id,
    territoryId: it.territory?.id,
    subStateId: it["sub-state"]?.id,
    subStateName: it["sub-state-name"],
    territoryName: it["territory-name"],
    availableToOrderQty: it["available-to-order-qty"],
    qty: it["qty"],
    allocationName: it["sub-state-name"] ?? it["territory-name"] ?? "",
  }));
  return allocations;
};

/*
All items have variants. A variant in the case of Gallo is just sizes. If an item does not have
any size variants, it will just have a default variant that is used for ordering purposes.
*/
export const handleVariants = (variants) => {
  if (!variants || variants.length === 0) return [];
  const mappedVariants = variants.map((variant) => {
    let variantNames =
      variant["selected-variant-options"].length > 0
        ? variant["selected-variant-options"]
            .filter((v) => v.name?.length)
            .map((v) => `${v["variant-category"].name}: ${v.name}`)
            .join(", ")
        : null;
    return {
      id: variant.id,
      name: variantNames,
      sku: variant["variant-sku"],
      qty: variant["national-available-to-order-qty"],
      warehouseQty: variant["cached-warehouse-qty"],
      isActive: variant["is-active"],
      selectedVariantOptions: variant["selected-variant-options"],
      availableToOrderQty: variant["available-to-order-qty"],
      variantAllocations: handleVariantAllocations(
        variant["variant-allocations"]
      ),
    };
  });
  return mappedVariants;
};

/*
Part of the item specifications is a field called Website Description. This is a free type field
on Gallo's side, that they try to always format in the same way. The UI then parses that input based
on the fields in the websiteDescSpecs array, and creates a new array that is used to display
the specifications as a table in the UI.
*/
export const websiteDescSpecs = [
  "Item Name",
  "MOQ",
  "Estimated Cost Based Off QTY",
  "Standard Lead Time",
  "Materials",
  "Overall Size",
  "Bottle Size",
  "Bottle Capacity",
  "Graphics Included",
  "Inventory Item",
  "Intended Usage/Channel",
  "Special Instructions/Description",
  "Build Time",
  "Shipper Box Size",
  "Other Notes",
];

// Website Description is a string with no field delimeters. We just know that it should contain the above fields
// 1) find all the indexes of the above fields
// 2) sort in increasing order
// 3) the field's value should be the index after the field label, to the following field's index, or the end of the string
// 4) return an object with the labels as keys, with their respective values.
export const parseWebsiteDescription = (webDesc) => {
  const parsed = {};
  const labels = websiteDescSpecs;

  if (!webDesc) return {};

  const indexedLabels = labels.reduce((a, b) => {
    const index = webDesc.indexOf(b);
    index !== -1 && a.push([b, index]);
    return a;
  }, []);

  indexedLabels.sort((a, b) => a[1] - b[1]);

  indexedLabels.forEach(([label, index], i) => {
    parsed[label] = webDesc
      .slice(index + label.length + 2, indexedLabels[i + 1]?.[1])
      .trim();
  });

  return parsed;
};

const filterSpecs = [
  "Website Description",
  "Stock",
  "Front Finish",
  "Flat Size",
  "Back Finish",
];

export const mapSpecifications = (specs, type) => {
  if (!specs) return [];
  const hasFullWebsiteDesc =
    specs["Website Description"] &&
    websiteDescSpecs.reduce(
      (acc, field) => (acc += specs["Website Description"].includes(field)),
      0
    ) > 5;
  const mappedSpecs = Object.keys(specs).flatMap((keyName) => {
    if (keyName === "Website Description" && hasFullWebsiteDesc) {
      return [
        {
          key: keyName,
          value: parseWebsiteDescription(specs[keyName]),
          type: "websiteDesc",
        },
      ];
    } else if (
      (type === "item-display" && filterSpecs.includes(keyName)) ||
      type === "supplier-display"
    ) {
      return [
        {
          key: keyName,
          value: specs[keyName]?.length > 1 ? specs[keyName] : "N/A",
          type: "spec",
        },
      ];
    } else return [];
  });

  return mappedSpecs;
};
