import React, { Suspense, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import CancelIcon from "@mui/icons-material/Cancel";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";

import PropTypes from "prop-types";

import { fetchFocusPDF } from "../../../redux/slices/programs/programsSlice";
import Loading from "../Loading";

const ItemPDFLoader = React.lazy(() => import("../../PDFs/PDFLoader"));

const monthValues = {
  January: 1,
  February: 2,
  March: 3,
  April: 4,
  May: 5,
  June: 6,
  July: 7,
  August: 8,
  September: 9,
  October: 10,
  November: 11,
  December: 12,
};

const useStyles = makeStyles((theme) => ({
  ...theme.global,
  focusPDFButtons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    width: "100%",
  },
}));

const FocusPDFModal = ({ open, handleClose, programs }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [currentMonths, setCurrentMonths] = useState([]);

  const focusItems = useSelector((state) => state.programs.focusPDFItems);
  const isFocusPDFLoading = useSelector(
    (state) => state.programs.isFocusPDFLoading
  );

  const handleClick = (month) => {
    let focusPrograms = programs
      .filter((prog) => prog.focusMonth === month)
      .map((prog) => ({
        id: prog.id,
        orderMonthId: prog.orderMonthId,
        name: prog.name,
        inMarketDate: prog.inMarketDate,
      }));
    dispatch(fetchFocusPDF(focusPrograms));
  };

  useEffect(() => {
    setIsLoading(true);
    let focusMonths = [
      ...new Set(programs.map((prog) => prog.focusMonth)),
    ].sort((a, b) => monthValues[a] - monthValues[b]);
    setCurrentMonths(focusMonths);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isLoading && currentMonths.length > 0) {
      setIsLoading(false);
    }
  }, [isLoading, currentMonths]);

  return (
    <div className={classes.relativeContainer}>
      <Dialog
        open={open}
        disableScrollLock
        onClose={() => {
          handleClose();
          setCurrentMonths([]);
        }}
        fullWidth
        maxWidth="sm"
        style={{ zIndex: "15000" }}
      >
        <DialogContent>
          <IconButton
            className={classes.closeButton}
            onClick={handleClose}
            size="large"
          >
            <CancelIcon fontSize="large" color="secondary" />
          </IconButton>
          <br />
          {(isLoading || currentMonths.length === 0) && (
            <div
              style={{
                width: "100%",
                height: "200px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </div>
          )}
          {!isLoading && currentMonths.length > 0 && (
            <>
              <div style={{ textAlign: "center", width: "100%" }}>
                <Typography className={classes.headerText}>
                  Select Focus Month to Generate PDF
                </Typography>
              </div>
              <br />
              <br />
              <div className={classes.focusPDFButtons}>
                {currentMonths.map((month, i) => (
                  <Button
                    variant="contained"
                    className={classes.largeButton}
                    color="secondary"
                    key={i}
                    onClick={() => handleClick(month)}
                  >
                    {month}
                  </Button>
                ))}
              </div>
              <br />
              <br />
              {focusItems.length > 0 && !isFocusPDFLoading && (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Suspense fallback={<Loading partial={false} />}>
                    <ItemPDFLoader
                      items={focusItems}
                      fileName="focus-month.pdf"
                      styles={{ marginBottom: "20px" }}
                    />
                  </Suspense>
                  <br />
                  <br />
                </div>
              )}
            </>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

FocusPDFModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  programs: PropTypes.array,
};

export default React.memo(FocusPDFModal);
