import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";

import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import { Box, IconButton, Tooltip } from "@mui/material";
import { Button, Container, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import PropTypes from "prop-types";
import { setFiltersOpen } from "src/redux/slices/filterSlice";

import CloudinaryFilesDownloadDelete from "../components/PlanningTool/CloudinaryFilesDownloadDelete";
import QuoteAccepted from "../components/PlanningTool/Quotes/QuoteAccepted";
import QuoteCompleted from "../components/PlanningTool/Quotes/QuoteCompleted";
import QuoteSent from "../components/PlanningTool/Quotes/QuoteSent";
import RfqDetails from "../components/PlanningTool/Quotes/RfqDetails";
import {
  clearSingleQuote,
  fetchSingleQuote,
} from "../redux/slices/planningTool/rfqSlice";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
}));

const PlanningToolQuote = () => {
  const { qid } = useParams(); // get seqNum from url path
  const classes = useStyles();
  const dispatch = useDispatch();

  const { currentQuote } = useSelector((state) => state.rfq);
  const { currentItem } = useSelector((state) => state.items);

  useEffect(() => {
    dispatch(fetchSingleQuote(qid));
    return () => {
      dispatch(clearSingleQuote());
    };
  }, [qid]);

  const viewProps = {
    currentQuote,
    classes,
  };

  useEffect(() => {
    dispatch(setFiltersOpen({ open: false }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>Planning Tool | Quote Request #{qid}</title>
      </Helmet>

      {qid === currentQuote.id && (
        <Container
          className={classes.mainWrapper}
          style={{ flexDirection: "column" }}
        >
          <Box
            className={classes.container}
            sx={{ display: "flex", alignItems: "center", gridGap: "1em" }}
          >
            <Tooltip title="Back to quotes" placement="bottom-start">
              <IconButton component={Link} to={`/planning/quotes`} size="large">
                <ArrowBackIcon fontSize="large" color="secondary" />
              </IconButton>
            </Tooltip>

            <Typography className={classes.titleText}>
              Request For Quotes
            </Typography>
          </Box>
          <br />
          <br />
          <br />
          <RfqDetails {...viewProps} />
          <Box sx={{ mt: 2 }}>
            {currentQuote.status === "sent" && <QuoteSent {...viewProps} />}

            {currentQuote.status === "accepted" && (
              <QuoteAccepted {...viewProps} />
            )}
            {currentQuote.status === "complete" && (
              <QuoteCompleted {...viewProps} />
            )}
          </Box>
        </Container>
      )}
    </>
  );
};

PlanningToolQuote.propTypes = {};

export default PlanningToolQuote;
