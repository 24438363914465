import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { TextField } from "@mui/material";
import { Autocomplete } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import makeStyles from "@mui/styles/makeStyles";

import { useDebounce } from "../../../../hooks/UtilityHooks";
import {
  clearItems,
  fetchFilteredItems,
} from "../../../../redux/slices/items/itemSlice";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
}));

const AutocompleteItem = ({
  itemIdState,
  programIdState: [programId],
  ...props
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [itemNumber, setItemNumber] = useState("");
  const [itemId, setItemId] = itemIdState;
  const [loading, setLoading] = useState(false);
  const itemList = useSelector((state) => state.items.items);

  const itemNums = itemList.map((p) => `${p.itemNumber}`);

  const handleSelectItem = (v) => {
    const pId = itemList.find((p) => p.itemNumber === v)?.id;
    setItemId(pId || null);
  };

  const fetchItemList = (itemNumber) => {
    const itemFilter = {
      itemNumber,
      // infoComplete: true /* isCoupon: false */,
      hasItemNumber: true,
    };
    if (programId) {
      itemFilter.program = [{ id: programId }];
    }
    dispatch(fetchFilteredItems(itemFilter));
  };

  const debouncedFetchItemList = useDebounce(
    () => fetchItemList(itemNumber),
    500
  );

  useEffect(() => {
    setLoading(true);
    fetchItemList("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [programId]);

  useEffect(() => {
    if (!programId) {
      setLoading(true);
      debouncedFetchItemList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [programId, itemNumber]);

  useEffect(() => {
    setLoading(false);
    if (itemList.length === 1) {
      setItemNumber(itemList[0].itemNumber);
      setItemId(itemList[0].id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setLoading, itemList]);

  useEffect(() => {
    if (!itemId) return;
    const selectedItem = itemList.find(({ id }) => id === itemId);
    if (selectedItem) {
      setItemNumber(selectedItem.itemNumber);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemId, itemList]);

  // Clean up on dismount
  useEffect(() => () => dispatch(clearItems()), [dispatch]);

  return (
    <Autocomplete
      id="item-auto-complete"
      options={itemNums}
      className={classes.queryField}
      classes={{
        popper: classes.liftedPopper,
      }}
      loading={loading}
      onChange={(e, v) => handleSelectItem(v)}
      onInputChange={(e, v) => setItemNumber(v)}
      value={itemNumber}
      inputValue={itemNumber}
      {...props}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Item Number"
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default AutocompleteItem;
