/** @jsxImportSource @emotion/react */
import "twin.macro";
import tw from "twin.macro";

import CircularProgress from "@mui/material/CircularProgress";

const Loading = ({ partial = false, hidden = false }) => {
  return (
    <div
      css={[
        tw`fixed inset-0 overflow-hidden flex flex-col items-center justify-center bg-white z-[2499]`,
        partial && tw`bg-opacity-50`,
      ]}
    >
      <br />

      {!hidden && (
        <CircularProgress color="secondary" disableShrink size={100} />
      )}
    </div>
  );
};

export default Loading;
