import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Button, Divider, List, ListItem, TextField } from "@mui/material";

import PropTypes from "prop-types";

import { setClear } from "../../redux/slices/filterSlice";
import InMarketYearAutoComplete from "../Utility/AutoCompleteFields/InMarketYearAutoComplete";
import ProgramTypeAutoComplete from "../Utility/AutoCompleteFields/ProgramTypeAutoComplete";
import { executeOnEnter } from "./utility";

const FiltersPlanningToolActivate = ({
  classes,
  codeOrName,
  bindCodeOrName,
  reset,
  setReset,
  handleFilters,
  handleSearch,
}) => {
  const dispatch = useDispatch();

  const isLoading = useSelector((state) => state.globalLoad.isLoading);

  return (
    <>
      <List onKeyPress={executeOnEnter(handleSearch)}>
        <ListItem>
          <TextField
            color="secondary"
            fullWidth
            name="codeOrName"
            type="text"
            label="Anaplan Id/Name"
            variant="outlined"
            size="small"
            value={codeOrName}
            {...bindCodeOrName}
            disabled={isLoading}
          />
        </ListItem>
        <ListItem>
          <Button
            fullWidth
            className={classes.largeButton}
            variant="contained"
            color="secondary"
            onClick={handleSearch}
            disabled={isLoading}
          >
            SEARCH
          </Button>
        </ListItem>
        <ListItem />

        <Divider />

        <ListItem>
          <InMarketYearAutoComplete
            classes={classes}
            handleChange={handleFilters}
            reset={reset}
            setReset={setReset}
            filterType={"planningToolActivate"}
          />
        </ListItem>
        <ListItem>
          <ProgramTypeAutoComplete
            classes={classes}
            handleChange={handleFilters}
            reset={reset}
            setReset={setReset}
            filterType={"planningToolActivate"}
          />
        </ListItem>
        {/* <ListItem>Customer Selector</ListItem> */}
        <ListItem>
          <Button
            fullWidth
            className={classes.largeButton}
            variant="contained"
            color="secondary"
            onClick={() => {
              dispatch(setClear());
            }}
            disabled={isLoading}
          >
            CLEAR FILTERS
          </Button>
        </ListItem>
      </List>
    </>
  );
};

FiltersPlanningToolActivate.propTypes = {
  reset: PropTypes.bool.isRequired,
  setReset: PropTypes.func.isRequired,
  handleFilters: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  codeOrName: PropTypes.string.isRequired,
  bindCodeOrName: PropTypes.object.isRequired,
};

export default FiltersPlanningToolActivate;
