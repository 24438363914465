import {
  addBulkOrderVariants,
  clearItemSelections,
  createNewBulkVariantOrder,
  updateSelection,
} from "src/redux/slices/ordering/currentOrderSlice";

type CreateBulkOrderParams = {
  dispatch: Function;
  type: "inventory" | "onDemand";
  variantIds: string[];
  territoryId: string;
  channel: string;
  programIdFromFilter?: string | null;
};

const createBulkOrder = ({
  dispatch,
  type,
  variantIds,
  territoryId,
  channel,
  programIdFromFilter,
}: CreateBulkOrderParams): void => {
  dispatch(
    createNewBulkVariantOrder(
      type,
      variantIds,
      territoryId,
      channel,
      programIdFromFilter
    )
  );
};

type HandleBulkOrderParams = {
  variantIds: string[];
  selectedItems: any[]; // Assuming any type for items
  currentOrder: {
    orderId?: string;
  };
  dispatch: Function;
  territoryId: string;
  channel: string;
  programIdFromFilter?: string | null;
  currentItems: any[]; // Assuming any type for items
  setVariantModalOpen: (open: boolean) => void;
  type: "onDemand" | "inventory";
};

export const handleBulkOrder = ({
  variantIds,
  selectedItems,
  currentOrder,
  dispatch,
  territoryId,
  channel,
  programIdFromFilter,
  currentItems,
  setVariantModalOpen,
  type,
}: HandleBulkOrderParams): void => {
  if (variantIds.length === selectedItems.length) {
    if (!currentOrder.orderId) {
      // If there isn't a current order, create a new one
      createBulkOrder({
        dispatch,
        type,
        variantIds,
        territoryId,
        channel,
        programIdFromFilter,
      });
    } else {
      dispatch(addBulkOrderVariants(currentOrder.orderId, variantIds, type));
    }
    dispatch(clearItemSelections());
  } else {
    // Otherwise check which items have active base variants and add those to bulk order
    let itemsLeft: any[] = [];
    currentItems
      .filter((item) => selectedItems.includes(item.id))
      .forEach((i) => {
        // Add the base variant to the order
        if (i.baseVariantIsActive) {
          const baseVariant = i.variants.find(
            (v) => v.isActive && v.selectedVariantOptions.length === 0
          );
          if (!currentOrder.orderId) {
            // If there isn't a current order, create a new one
            createBulkOrder({
              dispatch,
              type,
              variantIds: [baseVariant.id],
              territoryId,
              channel,
              programIdFromFilter,
            });
          } else {
            dispatch(
              addBulkOrderVariants(currentOrder.orderId, variantIds, type)
            );
          }
        } else {
          // Otherwise add the item to the list of items to show in the variant modal
          itemsLeft.push(i);
        }
      });
    // Update currentItems with itemsLeft so that they will show in the variant modal.
    dispatch(
      updateSelection({
        type: "selectedInventoryItems",
        selectedItems: itemsLeft,
      })
    );
    if (itemsLeft.length > 0) {
      // If there are any items left after that, open the variant modal
      setVariantModalOpen(true);
    } else {
      dispatch(clearItemSelections());
    }
  }
};
