import { createQueryKeys } from "@lukemorales/query-key-factory";
import { useQuery } from "@tanstack/react-query";

import { SubState } from "@models/SubState";
import client from "@services/api";

export const subStatesKeyFactory = createQueryKeys("sub-states", {
  list: (params) => ({
    queryKey: [params],
    queryFn: () =>
      client.get<SubState[]>("sub-states", { params }).then((res) => res.data),
  }),
});

export const useSubStates = (
  filter?: Record<string, any>,
  config?: { enabled: boolean }
) => {
  return useQuery({
    ...subStatesKeyFactory.list({ filter }),
    staleTime: Infinity,
    ...config,
  });
};
