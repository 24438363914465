import { createSlice } from "@reduxjs/toolkit";

import { buildFilters } from "../../../api/apiFunctions";
import { axiosGetWithNext } from "../../../api/axiosCalls";
import { formatDateString } from "../../../utility/utilityFunctions";
import { setError } from "../errorSlice";
import {
  resetStepperValue,
  setIsStepper,
  startGlobalLoad,
  stopGlobalLoad,
  updateStepperValue,
} from "../globalLoadSlice";

let initialState = {
  isLoading: false,
  isNextLoading: false,
  nextLink: null,
  history: [],
  historyReport: [],
  triggerCSVDownload: false,
  error: null,
};

const c2mOrderHistorySlice = createSlice({
  name: "c2mOrderHistory",
  initialState,
  reducers: {
    setIsLoading(state) {
      state.isLoading = true;
    },
    setIsNextLoading(state) {
      state.isNextLoading = true;
    },
    getHistorySuccess(state, action) {
      const { history, nextLink } = action.payload;
      state.nextLink = nextLink;
      state.history = history;
      state.isLoading = false;
      state.error = null;
    },
    getNextHistorySuccess(state, action) {
      const { history, nextLink } = action.payload;
      state.nextLink = nextLink;
      state.history = state.history.concat(history);
      state.isNextLoading = false;
      state.error = null;
    },
    getHistoryReportSuccess(state, action) {
      const { report } = action.payload;
      state.historyReport = report;
      state.triggerCSVDownload = true;
      state.error = null;
    },
    setTriggerCSVFalse(state) {
      state.triggerCSVDownload = false;
    },
    resetHistoryReport(state) {
      state.historyReport = [];
    },
    setFailure(state, action) {
      const { error } = action.payload;
      state.isLoading = false;
      state.isNextLoading = false;
      state.error = error;
    },
  },
});

export const {
  setIsLoading,
  setIsNextLoading,
  getHistorySuccess,
  getNextHistorySuccess,
  getHistoryReportSuccess,
  setTriggerCSVFalse,
  resetHistoryReport,
  setFailure,
} = c2mOrderHistorySlice.actions;

export default c2mOrderHistorySlice.reducer;

const mapHistory = (data) => {
  return data.map((dataPoint) => ({
    id: dataPoint.id,
    itemNumber: dataPoint["item-number"] ?? "---",
    orderType: dataPoint["order-type"] ?? "---",
    orderNumber: dataPoint["order-number"] ?? "---",
    orderedBy: dataPoint["ordered-by"] ?? "---",
    territory: dataPoint.territory ?? "---",
    entity: dataPoint.entity ?? "---",
    keyAccount: dataPoint["key-account"] ?? "---",
    themeName: dataPoint["theme-name"] ?? "---",
    programName: dataPoint["program-name"] ?? "---",
    businessUnit: dataPoint["business-unit"] ?? "---",
    brands: dataPoint.brands ?? "---",
    itemType: dataPoint["item-type"] ?? "---",
    itemDescription: dataPoint["item-description"] ?? "---",
    abn: dataPoint.abn ?? "---",
    distributorName: dataPoint["distributor-name"] ?? "---",
    streetAddressOne: dataPoint["street-address-1"] ?? "---",
    streetAddressTwo: dataPoint["street-address-2"] ?? "---",
    city: dataPoint.city ?? "---",
    stateCode: dataPoint["state-code"] ?? "---",
    zip: dataPoint.zip ?? "---",
    galloStateSubledger: dataPoint["gallo-state-subledger"] ?? "---",
    qty: dataPoint.qty ?? "---",
    itemPrice: dataPoint["item-price"] ?? "---",
    c2mDisplayedCost: dataPoint["c2m-displayed-cost"] ?? "---",
    complianceStatus: dataPoint["compliance-status"] ?? "---",
    inMarketDate: dataPoint["in-market-date"]
      ? formatDateString(dataPoint["in-market-date"])
      : "---",
    orderDate: dataPoint["order-date"]
      ? formatDateString(dataPoint["order-date"])
      : "---",
    shippedDate: dataPoint["shipped-date"]
      ? formatDateString(dataPoint["shipped-date"])
      : "---",
    orderStatus: dataPoint["order-status"] ?? "---",
  }));
};

export const fetchC2MOrderHistory = (filterObject) => async (dispatch) => {
  try {
    dispatch(setIsLoading());
    dispatch(startGlobalLoad());
    let queryString = buildFilters(
      filterObject,
      "",
      "",
      "/api/historical-order-items",
      "c2m-order-history"
    );
    let history = await axiosGetWithNext(queryString);
    if (history.error) {
      throw history.error;
    }
    const mappedData = mapHistory(history.data.data);
    dispatch(
      getHistorySuccess({
        history: mappedData,
        nextLink: history.data.nextLink ?? null,
      })
    );
    dispatch(stopGlobalLoad());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "C2M Order History" }));
    dispatch(stopGlobalLoad());
  }
};

export const fetchNextC2MOrderHistory = (url) => async (dispatch) => {
  try {
    dispatch(setIsNextLoading());
    dispatch(startGlobalLoad());
    let history = await axiosGetWithNext(url);
    if (history.error) {
      throw history.error;
    }
    const mappedData = mapHistory(history.data.data);
    dispatch(
      getNextHistorySuccess({
        history: mappedData,
        nextLink: history.data.nextLink ?? null,
      })
    );
    dispatch(stopGlobalLoad());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "C2M Order History" }));
    dispatch(stopGlobalLoad());
  }
};

export const fetchC2MOrderHistoryReport =
  (filterObject) => async (dispatch) => {
    try {
      dispatch(
        setIsStepper({
          stepBool: true,
          stepTitle: "Generating C2M Order History Report",
        })
      );
      let orderArray = [];
      let stepValue = 10;

      let queryString = buildFilters(
        filterObject,
        "",
        "",
        "/api/historical-order-items",
        "c2m-order-history"
      );
      let initialOrders = await axiosGetWithNext(queryString);
      if (initialOrders.error) {
        throw initialOrders.error;
      }
      let initialMappedOrders = mapHistory(initialOrders.data.data);
      orderArray = orderArray.concat(initialMappedOrders);

      let nextLink = initialOrders.data.nextLink;
      dispatch(updateStepperValue({ value: stepValue }));

      if (nextLink) {
        let fetchCount = Math.ceil((initialOrders.data.totalEntries - 20) / 20);
        stepValue = parseFloat((90 / fetchCount).toFixed(2));

        for (let i = 0; i < fetchCount; i++) {
          let nextOrders = await axiosGetWithNext(nextLink);
          if (nextOrders.error) {
            throw nextOrders.error;
          }
          nextLink = nextOrders.data.nextLink;
          let mappedOrders = mapHistory(nextOrders.data.data);
          orderArray = orderArray.concat(mappedOrders);
          dispatch(updateStepperValue({ value: stepValue }));
        }
      } else {
        dispatch(updateStepperValue({ value: 90 }));
      }

      dispatch(resetStepperValue());
      dispatch(getHistoryReportSuccess({ report: orderArray }));
    } catch (err) {
      dispatch(setFailure({ error: err.toString() }));
      dispatch(
        setError({ error: err.toString(), source: "C2M Order History Reports" })
      );
      dispatch(resetStepperValue());
    }
  };
