import React from "react";

import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

import CrudNewRow from "./CrudNewRow";
import CrudTableRow from "./CrudTableRow";

const CrudTable = ({
  columns,
  data,
  handleCreate,
  handleDelete,
  handleUpdate,
  uniqueKey,
}) => {
  /**
   * columns: [
   * {
   *      id: 'data_obj_key',
   *      label: 'displayed_text'
   *      align: text_align
   *      transform (optional):  function applied to value to display cell value
   *      Component: React.forwardRef
   * }
   *
   * ]
   */

  const rows = data.reduce((obj, { id: rowId, ...entry }) => {
    obj[rowId] = columns.reduce((a, { id }) => {
      a[id] = entry[id];
      return a;
    }, {});
    return obj;
  }, {});

  return (
    <>
      <Box component={Paper}>
        <Table>
          <TableHead bgcolor="whitesmoke">
            <TableRow>
              {columns.map(({ id, label, align }) => (
                <TableCell key={id} style={{ textAlign: align || "left" }}>
                  {label}
                </TableCell>
              ))}
              <TableCell style={{ width: "24px" }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(rows).map(([rowId, row]) => (
              <CrudTableRow
                key={rowId}
                id={rowId}
                columns={columns}
                row={row}
                handleDelete={handleDelete}
                handleUpdate={handleUpdate}
              />
            ))}
            <CrudNewRow
              columns={columns}
              onSubmit={handleCreate}
              uniqueKey={uniqueKey}
            />
          </TableBody>
        </Table>
      </Box>
    </>
  );
};

export default CrudTable;
