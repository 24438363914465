import React from "react";
import { useDispatch, useSelector } from "react-redux";

import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import TextField from "@mui/material/TextField";

import PropTypes from "prop-types";

import { setClear } from "../../redux/slices/filterSlice";
import BUAutoComplete from "../Utility/AutoCompleteFields/BUAutoComplete";
import BrandAutoComplete from "../Utility/AutoCompleteFields/BrandAutoComplete";
import ItemTypeAutoComplete from "../Utility/AutoCompleteFields/ItemTypeAutoComplete";
import { executeOnEnter } from "./utility";

const FiltersRFQs = ({
  reset,
  setReset,
  handleFilters,
  classes,
  itemNumber,
  bindSequenceNum,
  programName,
  bindProgramName,
  itemDesc,
  bindItemDesc,
  handleSearch,
  // itemFilterType,
}) => {
  const dispatch = useDispatch();

  const isLoading = useSelector((state) => state.globalLoad.isLoading);
  // const currentUserRole = useSelector((state) => state.user.role);

  return (
    <>
      <List onKeyPress={executeOnEnter(handleSearch)}>
        <ListItem>
          <TextField
            color="secondary"
            fullWidth
            name="itemNumber"
            type="text"
            label="Sequence #"
            variant="outlined"
            size="small"
            value={itemNumber}
            {...bindSequenceNum}
            disabled={isLoading}
          />
        </ListItem>
        <ListItem>
          <TextField
            color="secondary"
            fullWidth
            name="programName"
            type="text"
            label="Program"
            variant="outlined"
            size="small"
            value={programName}
            {...bindProgramName}
            disabled={isLoading}
          />
        </ListItem>
        <ListItem>
          <TextField
            color="secondary"
            fullWidth
            name="itemDesc"
            type="text"
            label="Item Description"
            variant="outlined"
            size="small"
            value={itemDesc}
            {...bindItemDesc}
            disabled={isLoading}
          />
        </ListItem>
        <ListItem>
          <Button
            fullWidth
            className={classes.largeButton}
            variant="contained"
            color="secondary"
            onClick={handleSearch}
            disabled={isLoading}
          >
            SEARCH
          </Button>
        </ListItem>
        <ListItem />
        <Divider />
        <ListItem />

        {/* {(itemFilterType === "all" ||
          itemFilterType === "archive" ||
          itemFilterType === "visible") && (
          <ListItem>
            <ItemOrderTypeSelector
              classes={classes}
              handleOrderType={handleFilters}
              setType={setType}
              orderType={type}
              filterType={"item-all"}
            />
          </ListItem>
        )} */}
        <ListItem>
          <BrandAutoComplete
            classes={classes}
            handleChange={handleFilters}
            reset={reset}
            setReset={setReset}
            filterType={"item-all"}
          />
        </ListItem>
        {/* <ListItem>
          <ProgramAutoComplete
            classes={classes}
            handleChange={handleFilters}
            reset={reset}
            setReset={setReset}
            filterType={"item-all"}
          />
        </ListItem> */}
        <ListItem>
          <ItemTypeAutoComplete
            classes={classes}
            handleChange={handleFilters}
            reset={reset}
            setReset={setReset}
            filterType={"item-all"}
          />
        </ListItem>
        <ListItem>
          <BUAutoComplete
            classes={classes}
            handleChange={handleFilters}
            reset={reset}
            setReset={setReset}
            filterType={"item-all"}
          />
        </ListItem>
        <Divider />
        <ListItem />
        <ListItem>
          <Button
            fullWidth
            className={classes.largeButton}
            variant="contained"
            color="secondary"
            onClick={() => {
              dispatch(setClear());
            }}
            disabled={isLoading}
          >
            CLEAR FILTERS
          </Button>
        </ListItem>
      </List>
    </>
  );
};

FiltersRFQs.propTypes = {
  reset: PropTypes.bool.isRequired,
  setReset: PropTypes.func.isRequired,
  handleFilters: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  itemNumber: PropTypes.string.isRequired,
  bindSequenceNum: PropTypes.object.isRequired,
};

export default FiltersRFQs;
