import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Container from "@mui/material/Container";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";

import { without } from "lodash";
import PropTypes from "prop-types";
import { setFiltersOpen } from "src/redux/slices/filterSlice";

import ComplianceDash from "../components/Dashboard/ComplianceDash";
import FieldDash from "../components/Dashboard/FieldDash";
import SupplierDash from "../components/Dashboard/SupplierDash";
import {
  COMPLIANCE,
  FREIGHT_FORWARDER,
  NON_SUPPLIER_ROLES,
  SUPPLIER_ROLES,
} from "../constants/permissions";
import { useNoFetch } from "../hooks/UtilityHooks";
import FreightForwarderDashboard from "./FreightForwarderDashboard";

/*
There are a couple different dashboards based on the user type. Compliance and Supplier type users
have very specific workflows, so they have dashboards that are more catered to their work.
*/

const useStyles = makeStyles((theme) => ({
  ...theme.global,
  ...theme.dashboard,
  paper: {
    padding: theme.spacing(1),
  },
}));

const InfoPopover = ({
  id,
  info,
  classes,
  open,
  anchorEl,
  handlePopoverClose,
}) => {
  return (
    <>
      <Popover
        id={id}
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={handlePopoverClose}
        marginThreshold={25}
        disableRestoreFocus
        disableScrollLock
      >
        <Typography className={classes.bodyText}>{info}</Typography>
      </Popover>
    </>
  );
};

const Dashboard = ({ role }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const name = useSelector((state) => state.user.firstName);

  useEffect(() => {
    dispatch(setFiltersOpen({ open: false }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useNoFetch(dispatch);

  return (
    <>
      <Container
        className={classes.mainWrapper}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <br />
        <br />
        {SUPPLIER_ROLES.includes(role) && (
          <SupplierDash
            name={name}
            classes={classes}
            InfoPopover={InfoPopover}
            role={role}
          />
        )}
        {role === COMPLIANCE && (
          <ComplianceDash
            name={name}
            classes={classes}
            InfoPopover={InfoPopover}
          />
        )}
        {without(NON_SUPPLIER_ROLES, COMPLIANCE).includes(role) && (
          <FieldDash name={name} classes={classes} InfoPopover={InfoPopover} />
        )}
        {role === FREIGHT_FORWARDER && <FreightForwarderDashboard />}
      </Container>
      <br />
      <br />
    </>
  );
};

Dashboard.propTypes = {
  role: PropTypes.string,
};

export default Dashboard;
