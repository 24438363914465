/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useState } from "react";
import { useDispatch } from "react-redux";

import { Close } from "@mui/icons-material";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";

import { addDays } from "date-fns";
import _ from "lodash";
import { allocateToInventory } from "src/redux/slices/purchasing/purchaseOrderSlice";
import { utcDate } from "src/utility/utilityFunctions";

import { OrderVariant } from "@models/OrderVariant";

import { DatePicker } from "../Forms/DefaultInputs";
import { StyledButton } from "../StyledComponents";
import OrderVariantList from "./OrderVariantList";

const FulfillFromInventoryModal = ({ handleClose, itemRollups }) => {
  const dispatch = useDispatch();

  const {
    inMarketDate: dateFromItemRollup,
    programId,
    itemNumber,
  } = itemRollups[0];
  const orderVariantIdsByVariant = _(itemRollups)
    .groupBy("itemNumber")
    .mapValues((irs) => irs.flatMap((ir) => ir.orderVariantIds))
    .value();

  const [valid, setValid] = useState<boolean[]>(
    new Array(Object.keys(orderVariantIdsByVariant).length).fill(true)
  );
  const [loading, setLoading] = useState(false);
  const [selectedOvs, setSelectedOvs] = useState<OrderVariant[]>([]);
  const [inMarketDate, setInMarketDate] = useState<Date | null>(
    _.isDate(utcDate(dateFromItemRollup))
      ? utcDate(dateFromItemRollup)
      : addDays(new Date(), 5)
  );

  const handleCreateFulfillmentPurchaseOrder = async () => {
    setLoading(true);
    const ovIds = _.map(selectedOvs, "id");
    dispatch(allocateToInventory(ovIds, inMarketDate, +programId));
  };

  return (
    <Dialog open onClose={handleClose} fullWidth>
      <DialogTitle tw="flex justify-between items-center">
        Creating Fulfillment PO for #{itemNumber.split("-")[0]}
        <IconButton onClick={handleClose} edge="end">
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent tw="space-y-6">
        {Object.entries(orderVariantIdsByVariant).map(
          ([itemNumber, orderVariantIds], i) => (
            <OrderVariantList
              key={itemNumber}
              singleItemRollup={itemRollups.find(
                (ir) => ir.itemNumber === itemNumber
              )}
              orderVariantIds={orderVariantIds}
              selectedOvs={selectedOvs}
              setSelectedOvs={setSelectedOvs}
              setValid={(val) =>
                setValid((valid) =>
                  valid.map((v, idx) => (idx === i ? val : v))
                )
              }
            />
          )
        )}
      </DialogContent>
      <DialogActions tw="justify-between px-6">
        <DatePicker
          label="In Market Date"
          disablePast
          value={inMarketDate}
          onChange={setInMarketDate}
        />
        <StyledButton
          cta
          onClick={handleCreateFulfillmentPurchaseOrder}
          loading={loading}
          disabled={selectedOvs.length === 0 || valid.some((v) => !v)}
        >
          Create Fulfillment PO
        </StyledButton>
      </DialogActions>
    </Dialog>
  );
};

export default FulfillFromInventoryModal;
