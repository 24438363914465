import React from "react";
import { useSelector } from "react-redux";

import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";

const LabeledLoader = ({ value, classes }) => {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress
        variant="determinate"
        color="secondary"
        size={100}
        value={value}
      />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          className={classes.headerText}
          variant="caption"
          component="div"
        >
          {`${value <= 100 ? Math.round(value) : 100} %`}
        </Typography>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  ...theme.global,
}));

const StepperLoader = ({ isProgStepper }) => {
  const classes = useStyles();

  const stepperValue = useSelector((state) => state.globalLoad.stepperValue);
  const stepperTitle = useSelector((state) => state.globalLoad.stepperTitle);
  const progStepperValue = useSelector(
    (state) => state.globalLoad.progStepperValue
  );
  const progStepperTitle = useSelector(
    (state) => state.globalLoad.progStepperTitle
  );

  return (
    <Fade in={true}>
      <div
        style={{
          position: "fixed",
          top: isProgStepper ? "87px" : 0,
          left: 0,
          width: "100vw",
          height: "100vh",
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "rgb(255,255,255,.9)",
          zIndex: isProgStepper ? "2900" : "25000",
        }}
      >
        <br />
        <Typography className={classes.headerText}>
          {isProgStepper ? progStepperTitle : stepperTitle}
        </Typography>
        <br />
        <LabeledLoader
          classes={classes}
          value={isProgStepper ? progStepperValue : stepperValue}
        />
      </div>
    </Fade>
  );
};

export default StepperLoader;
