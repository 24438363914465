import { useMutation, useQueryClient } from "@tanstack/react-query";

import { Order } from "@models/Order";
import client from "@services/api";

import { orderSetsKeyFactory } from "../../../queries/orderSetQueries";
import { useCurrentOrderSet } from "../CurrentOrderSetContext";

export default function useCreateOrder() {
  const queryClient = useQueryClient();
  const { orderSetId } = useCurrentOrderSet();

  return useMutation({
    mutationFn: (orderBody: Record<string, any>) => {
      return client
        .post<Order>(`orders`, {
          __type: "order",
          ...orderBody,
        })
        .then((res) => ({ order: res.data }));
    },
    onSuccess: ({ order }) => {
      queryClient.setQueryData(
        orderSetsKeyFactory.detail(orderSetId)._ctx.orders.queryKey,
        (orders: Order[]) => [...orders, order]
      );
    },
  });
}
