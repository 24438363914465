import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import PropTypes from "prop-types";

import { setClear } from "../../redux/slices/filterSlice";
import BUAutoComplete from "../Utility/AutoCompleteFields/BUAutoComplete";
import BrandAutoComplete from "../Utility/AutoCompleteFields/BrandAutoComplete";
import ItemTypeAutoComplete from "../Utility/AutoCompleteFields/ItemTypeAutoComplete";
import ProgramAutoComplete from "../Utility/AutoCompleteFields/ProgramAutoComplete";
import SupplierAutoComplete from "../Utility/AutoCompleteFields/SupplierAutoComplete";
import { executeOnEnter } from "./utility";

const FiltersItemRollup = ({
  reset,
  setReset,
  handleFilters,
  classes,
  itemNumber,
  bindSequenceNum,
  handleSearch,
  rollupType,
}) => {
  const dispatch = useDispatch();
  const [orderType, setOrderType] = useState("on-demand");
  const [excludeSupplier, setExcludeSupplier] = useState(false);

  const currentOrderType = useSelector((state) => state.filters.orderType);
  const currentUserRole = useSelector((state) => state.user.role);
  const isLoading = useSelector((state) => state.globalLoad.isLoading);

  useEffect(() => {
    if (orderType !== currentOrderType) {
      setOrderType(currentOrderType);
    }
  }, [orderType, currentOrderType, setOrderType]);

  return (
    <>
      <List onKeyPress={executeOnEnter(handleSearch)}>
        <ListItem
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography className={classes.headerText}>Order Type:</Typography>
          <br />
          <ButtonGroup
            orientation="vertical"
            fullWidth
            color="secondary"
            aria-label="order-variant-type"
          >
            {rollupType === "po" && (
              <Button
                className={
                  orderType === "on-demand"
                    ? classes.largeButton
                    : classes.selectedButton
                }
                variant={orderType === "on-demand" ? "contained" : "outlined"}
                onClick={() => {
                  setOrderType("on-demand");
                  handleFilters("on-demand", "orderType", "itemRollup");
                }}
                disabled={isLoading}
              >
                ON-DEMAND
              </Button>
            )}
            <Button
              className={
                orderType === "pre-order"
                  ? classes.largeButton
                  : classes.selectedButton
              }
              variant={orderType === "pre-order" ? "contained" : "outlined"}
              onClick={() => {
                setOrderType("pre-order");
                handleFilters("pre-order", "orderType", "itemRollup");
              }}
              disabled={isLoading}
            >
              PRE-ORDER WINDOW
            </Button>
          </ButtonGroup>
        </ListItem>
        <ListItem />
        <Divider />

        <ListItem />
        <ListItem>
          <TextField
            className={classes.queryField}
            color="secondary"
            fullWidth
            name="sequenceNumber"
            type="text"
            label="Sequence #"
            value={itemNumber}
            {...bindSequenceNum}
            variant="outlined"
            size="small"
            disabled={isLoading}
          />
        </ListItem>
        <ListItem>
          <Button
            fullWidth
            className={classes.largeButton}
            variant="contained"
            color="secondary"
            onClick={handleSearch}
            disabled={isLoading}
          >
            SEARCH
          </Button>
        </ListItem>
        <ListItem />
        <Divider />
        <ListItem />
        <ListItem>
          <BrandAutoComplete
            classes={classes}
            handleChange={handleFilters}
            reset={reset}
            setReset={setReset}
            filterType={"itemRollup"}
          />
        </ListItem>
        <ListItem>
          <ProgramAutoComplete
            classes={classes}
            handleChange={handleFilters}
            reset={reset}
            setReset={setReset}
            filterType={"itemRollup"}
          />
        </ListItem>
        <ListItem>
          <ItemTypeAutoComplete
            classes={classes}
            handleChange={handleFilters}
            reset={reset}
            setReset={setReset}
            filterType={"itemRollup"}
          />
        </ListItem>
        <ListItem>
          <BUAutoComplete
            classes={classes}
            handleChange={handleFilters}
            reset={reset}
            setReset={setReset}
            filterType={"itemRollup"}
          />
        </ListItem>
        <ListItem>
          <SupplierAutoComplete
            classes={classes}
            handleChange={handleFilters}
            reset={reset}
            setReset={setReset}
            filterType={"itemRollup"}
          />
        </ListItem>
        {currentUserRole === "purchaser" && (
          <ListItem
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography className={classes.headerText}>
              Exclude Select:
            </Typography>
            <br />
            <ButtonGroup
              orientation="vertical"
              fullWidth
              color="secondary"
              aria-label="exclude-select"
            >
              <Button
                className={
                  excludeSupplier ? classes.largeButton : classes.selectedButton
                }
                variant={excludeSupplier ? "contained" : "outlined"}
                onClick={() => {
                  setExcludeSupplier(true);
                  handleFilters("22", "excludedSupplier", "itemRollup");
                }}
                disabled={isLoading}
              >
                YES
              </Button>
              <Button
                className={
                  !excludeSupplier
                    ? classes.largeButton
                    : classes.selectedButton
                }
                variant={!excludeSupplier ? "contained" : "outlined"}
                onClick={() => {
                  setExcludeSupplier(false);
                  handleFilters(null, "excludedSupplier", "itemRollup");
                }}
                disabled={isLoading}
              >
                NO
              </Button>
            </ButtonGroup>
          </ListItem>
        )}
        <ListItem />
        <ListItem>
          <Button
            fullWidth
            className={classes.largeButton}
            variant="contained"
            color="secondary"
            onClick={() => {
              dispatch(setClear());
            }}
            disabled={isLoading}
          >
            CLEAR FILTERS
          </Button>
        </ListItem>
      </List>
    </>
  );
};

FiltersItemRollup.propTypes = {
  reset: PropTypes.bool.isRequired,
  setReset: PropTypes.func.isRequired,
  handleFilters: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  itemNumber: PropTypes.string.isRequired,
  bindSequenceNum: PropTypes.object.isRequired,
  handleSearch: PropTypes.func.isRequired,
};

export default FiltersItemRollup;
