import format from "date-fns/format";

import {
  camelCaseKeys,
  formatDateString,
} from "../../../utility/utilityFunctions";

export const mapAddresses = (addresses) => {
  const mappedAddresses = addresses
    .map((add) => ({
      id: add.id,
      addDisplayId: add.type === "custom" ? `c${add.id}` : add.abn,
      type: add.type[0].toUpperCase() + add.type.slice(1),
      name: add.name.replace(/,/g, ""),
      streetAddressOne: add["street-address-1"],
      streetAddressTwo: add["street-address-2"],
      city: add.city,
      state: camelCaseKeys(add.state),
      stateCode: add.state.code,
      zip: add.zip,
      country: add.state.country,
      distributorAbn: add.abn ?? "---",
      attn: add["current-user-attn"]
        ? add["current-user-attn"]
        : add["default-attn"]
        ? add["default-attn"]
        : "---",
      defaultAttn: add["default-attn"] ? add["default-attn"] : null,
      userAttn: add["current-user-attn"] ? add["current-user-attn"] : null,
      phoneNumber: add["phone-number"],
      onPremiseEmailAddresses: add["on-premise-email-addresses"] ?? [],
      retailEmailAddresses: add["retail-email-addresses"] ?? [],
      isActive: add["is-active"] ? "Yes" : "No",
      hasChangeRequests: add["has-change-requests"] ?? false,
      changeNote: add["change-note"] ?? null,
      changeStatus: add["change-status"] ?? null,
      changeEffectiveDate: add["change-effective-date"]
        ? formatDateString(add["change-effective-date"])
        : null,
      approvedOrDeniedAt: add["approved-or-denied-at"]
        ? format(new Date(add["approved-or-denied-at"]), "MM/dd/yyyy")
        : null,
      changeRequestedByName: add["change-requested-by-name"] ?? null,
      changeRequestedByEmail: add["change-requested-by-email"] ?? null,
      approvedOrDeniedByName: add["approved-or-denied-by-name"] ?? null,
      approvedOrDeniedByEmail: add["approved-or-denied-by-email"] ?? null,
      addressToChangeId: add?.["address-to-change"]?.id ?? null,
      deactivatedBy: add?.["deactivated-by-name"] ?? null,
      deactivatedAt: add["deactivated-at"]
        ? format(new Date(add["deactivated-at"]), "MM/dd/yyyy")
        : null,
      deactivationNote: add?.["deactivation-note"] ?? null,
      substate: add?.["sub-state"],
    }))
    .sort((a, b) => {
      return a.state < b.state ? -1 : a.state > b.state ? 1 : 0;
    });
  return mappedAddresses;
};
