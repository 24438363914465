import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";

import PropTypes from "prop-types";

import {
  clearOrderByType,
  fetchCurrentOrderByType,
} from "../../../redux/slices/ordering/currentOrderSlice";
import { updateCurrentChannel } from "../../../redux/slices/users/userSlice";

const OnPremiseRetailSelector = ({ classes }) => {
  const dispatch = useDispatch();

  const [channel, setChannel] = useState("");

  const isRetail = useSelector((state) => state.user.isRetail);
  const isOnPremise = useSelector((state) => state.user.isOnPremise);
  const currentUserId = useSelector((state) => state.user.id);
  const currentChannel = useSelector((state) => state.user.currentChannel);
  const currentTerritory = useSelector((state) => state.user.currentTerritory);
  const isOrdering = useSelector((state) => state.orderSet.isOrdering);
  const isStepperLoad = useSelector(
    (state) => state.globalLoad.isProgStepperLoad
  );

  const handleChangeSelect = (evt) => {
    setChannel(evt.target.value);
    let channel = evt.target.value;
    dispatch(clearOrderByType({ type: "inventory" }));
    dispatch(clearOrderByType({ type: "onDemand" }));
    dispatch(
      fetchCurrentOrderByType(
        "inventory",
        currentUserId,
        channel,
        currentTerritory
      )
    );
    dispatch(
      fetchCurrentOrderByType(
        "onDemand",
        currentUserId,
        channel,
        currentTerritory
      )
    );
    dispatch(updateCurrentChannel({ channel: evt.target.value }));
  };

  useEffect(() => {
    if (currentChannel !== channel) {
      setChannel(currentChannel);
    }
  }, [currentChannel, channel]);

  if ((isRetail && !isOnPremise) || (!isRetail && isOnPremise)) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "103.5px",
          height: "41px",
          margin: "0 5px",
          backgroundColor: "white",
          borderRadius: "5px",
        }}
      >
        <Typography className={classes.regionText} variant="body2">
          {currentChannel}
        </Typography>
      </div>
    );
  } else {
    return (
      <>
        <FormControl
          variant="outlined"
          size="small"
          disabled={
            (isOrdering && window.location.href.includes("/orders/open")) ||
            isStepperLoad
          }
          style={{
            margin: "0 5px",
            borderRadius: "5px",
            backgroundColor: "white",
          }}
        >
          <Select
            name="regions"
            labelId="region-select"
            id="regions"
            value={channel}
            onChange={handleChangeSelect}
            MenuProps={{
              style: { zIndex: "10001" },
            }}
          >
            <MenuItem value={"retail"}>
              <Typography variant="body2">Retail</Typography>
            </MenuItem>
            <MenuItem value={"on_premise"}>
              <Typography variant="body2">On Premise</Typography>
            </MenuItem>
          </Select>
        </FormControl>
      </>
    );
  }
};

OnPremiseRetailSelector.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default React.memo(OnPremiseRetailSelector);
