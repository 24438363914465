import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import PropTypes from "prop-types";

import {
  setUpdateSuccess,
  updateItemSubTypeById,
} from "../../redux/slices/items/itemTypeSlice";
import { intValidation } from "../../utility/inputHelpers";
import { TextInput } from "../Forms/ControlledInputs";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
}));

const ItemSubTypeModal = ({ open, handleClose, subType }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isUpdateLoading, updateStatus, error } = useSelector(
    (state) => state.itemSubTypes
  );

  const controlled = (name) => ({
    name,
    control,
  });

  const { handleSubmit, control } = useForm({
    defaultValues: {
      label: subType ? subType.label : "",
      moq: subType ? subType.moq : "",
      estimated_cost: subType ? subType.estimatedCost : "",
      complexity_score: subType ? subType.complexityScore : "",
    },
  });

  const onSubmit = (data) => {
    console.log(data);
    dispatch(
      updateItemSubTypeById({
        id: subType.id,
        ...data,
      })
    );
  };

  return (
    <div className={classes.relativeContainer}>
      <Dialog
        open={open}
        onClose={() => {
          handleClose();
        }}
        fullWidth
      >
        <DialogTitle>
          <Typography className={classes.headerText}>
            {`Editing ${subType.label}`}
          </Typography>
        </DialogTitle>

        <DialogContent tw="mt-2">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "15px",
                mt: 1,
              }}
            >
              <TextInput
                name="label"
                label="Label"
                fullWidth
                {...controlled("label", { required: true })}
              />
              <TextInput
                name="moq"
                label="MOQ"
                fullWidth
                {...controlled("moq", { required: true }, intValidation)}
              />
              <TextInput
                name="estimatedCost"
                label="Estimated Cost"
                fullWidth
                {...controlled("estimated_cost", { required: true })}
              />
              <TextInput
                name="complexityScore"
                label="Complexity Score"
                fullWidth
                {...controlled("complexity_score", { required: true })}
              />
              <Button
                className={classes.largeButton}
                variant="contained"
                color="secondary"
                id="profile"
                fullWidth
                onClick={handleSubmit(onSubmit)}
              >
                {isUpdateLoading ? <CircularProgress /> : "SUBMIT"}
              </Button>
            </Box>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

ItemSubTypeModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  type: PropTypes.string,
  id: PropTypes.string,
  itemSubTypeList: PropTypes.array,
};

export default React.memo(ItemSubTypeModal);
