/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { TextField, Typography } from "@mui/material";

import axios from "axios";
import { without } from "lodash";

import {
  parseWebsiteDescription,
  websiteDescSpecs,
} from "../../../../redux/mapHelpers";
import { setError } from "../../../../redux/slices/errorSlice";
import { updateSpecification } from "../../../../redux/slices/planningTool/itemProgramSlice";
import {
  useCustomForm,
  useCustomRegister,
} from "../../../../utility/inputHelpers";
import { AutocompleteInput } from "../../../Forms/ControlledInputs";

/**
 * specType is either "spec" or "description"
 * "spec" is used for paper items
 * "description" is used for non-paper items and uses the "Website Description" field
 */

const specLabels = {
  // non-paper specs
  description: websiteDescSpecs,
  // paper specs
  spec: [
    "Standard Spec Code",
    "Back 4-Color",
    "Back Finish",
    "Dieline",
    "Embossing",
    "Finishing Type",
    "Flat Size",
    "Front 4-Color",
    "Front Finish",
    "Hot Stamp",
    "Pack Out",
    "Perf",
    "Reference Number",
    "Score",
    "Stock",
    "Supplier Instructions",
    "Other Notes",
  ],
};

const shouldNotDisplay = ["Standard Spec Code", "MOQ"];

const encodeWebsiteDescription = (data) => ({
  "Website Description": specLabels.description
    .map((key) => `${key}: ${data[key] ?? " "}`)
    .join("\r\n"),
});

const parseItemProgKeyNames = (specs) => {
  const parsedKeyValPairs = {};
  for (const [key, value] of Object.entries(specs)) {
    let keyName = key
      .split(" ")
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(" ");
    parsedKeyValPairs[keyName] = value;
  }
  return parsedKeyValPairs;
};

const SpecSection = ({
  itemProgramId,
  itemId,
  specs,
  specType,
  itemTypeId,
  couponSpecCode,
  isCoupon,
}) => {
  const dispatch = useDispatch();
  const [specCodes, setSpecCodes] = useState([]);

  const parsed =
    specType === "description"
      ? parseWebsiteDescription(specs["Website Description"])
      : specs;

  const {
    register,
    formState: errors,
    getValues,
    reset,
    control,
  } = useCustomForm({
    defaultValues: {
      ...parsed,
    },
  });

  const fieldsToShow = without(specLabels[specType] ?? [], ...shouldNotDisplay);

  const handleSave = () => {
    const values = getValues();
    const data =
      specType === "description" ? encodeWebsiteDescription(values) : values;
    dispatch(updateSpecification(itemProgramId, itemId, data));
  };

  const reg = useCustomRegister(register, errors, {
    onBlur: handleSave,
  });

  const fillFormFields = (enteredCode) => {
    if (enteredCode?.id) {
      const specs = specCodes.find(
        (obj) => obj.id === enteredCode.id
      )?.attributes;

      const formValuesObj = parseItemProgKeyNames(specs);
      formValuesObj["Standard Spec Code"] = enteredCode.id;
      reset(formValuesObj);
    }
  };

  useEffect(() => {
    if (itemTypeId) {
      axios
        .get(`/api/standard-specs?filter[item-type-id]=${itemTypeId}`)
        .then((response) => {
          setSpecCodes(
            response.data.data.map((obj) => ({
              ...obj,
              id: obj.attributes["Reference Number"],
            }))
          );
        })
        .catch((e) => {
          dispatch(
            setError({ error: e.message, source: "Fetch Standard Specs" })
          );
        });
    }
  }, [itemTypeId, dispatch]);

  return (
    <div>
      {couponSpecCode && (
        <Typography tw="mb-4">
          Gallo Coupon Standard Spec Code: {couponSpecCode}
        </Typography>
      )}
      {specType === "spec" && (
        <AutocompleteInput
          disabled={isCoupon}
          options={specCodes.map(({ id }) => ({
            id,
            label: id,
          }))}
          tw="mb-4 max-w-full w-72"
          onChange={(data) => {
            fillFormFields(data);
          }}
          onBlur={() => {
            handleSave();
          }}
          control={control}
          name={"Standard Spec Code"}
          renderInput={(params) => <TextField {...params} label="Spec Code" />}
        />
      )}
      <div tw="columns-sm w-full">
        {fieldsToShow.map((label) => (
          <div
            tw="p-2 flex items-start rounded transition border border-solid border-transparent hover:bg-slate-100 hover:border-slate-200"
            key={label}
          >
            <label htmlFor={label} tw="w-40 mr-4 cursor-pointer">
              {label}:
            </label>
            <TextField
              id={label}
              size="small"
              tw="flex-1"
              multiline
              css={{
                ".MuiInputBase-input": tw`px-2 py-1 text-xs bg-white`,
                ".MuiInputBase-root": tw`p-0`,
              }}
              {...reg(label)}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default SpecSection;
