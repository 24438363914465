import OrderHistorySalesSummaryPDF from "src/components/PDFs/OrderHistorySalesSummaryPDF";
import { handlePDFDownload } from "src/components/PDFs/PDFLoader";

import { createSlice } from "@reduxjs/toolkit";

import { axiosGet, axiosGetWithNext } from "../../../api/axiosCalls";
import { setError } from "../errorSlice";
import {
  resetStepperValue,
  setIsStepper,
  startGlobalLoad,
  stopGlobalLoad,
  updateStepperValue,
} from "../globalLoadSlice";
import {
  buildOrderHistoryQuery,
  buildOrderVariantHistoryQuery,
} from "./helpers";
import {
  mapOrderHistoryOrders,
  mapOrderHistoryVariants,
  mapOrderVariants,
  mapSingleOrder,
} from "./maps";

let initialState = {
  isLoading: false,
  isNextLoading: false,
  nextLink: null,
  orders: [],
  variants: [],
  orderReport: [],
  variantReport: [],
  triggerCSVDownload: false,
  singleOrder: {
    orderNumber: null,
    user: null,
    address: null,
    distributorAbn: null,
    type: null,
    status: null,
    orderDate: null,
    shipDate: null,
    trackingNum: null,
    totalQty: 0,
    totalEstFreight: 0,
    totalActFreight: 0,
    totalEstTax: 0,
    totalActTax: 0,
    totalEstCost: 0,
    totalActCost: 0,
    totalBeaconCost: 0,
    variants: [],
    note: null,
    attn: null,
    hasUpdated: false,
    newOrderId: null,
  },
  error: null,
};

const loadingFailed = (state, action) => {
  const { error } = action.payload;
  state.isLoading = false;
  state.error = error;
};

const orderHistorySlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    setIsLoading(state) {
      state.isLoading = true;
    },
    setNextIsLoading(state) {
      state.isNextLoading = true;
    },
    getSingleOrderSuccess(state, action) {
      const { order, variants } = action.payload;
      state.singleOrder = order;
      state.singleOrder.variants = variants;
      state.isLoading = false;
      state.error = null;
    },
    getOrderHistorySuccess(state, action) {
      const { orders, nextLink } = action.payload;
      state.nextLink = nextLink;
      state.orders = [...orders];
      state.isLoading = false;
      state.error = null;
    },
    getNextHistorySuccess(state, action) {
      const { orders, nextLink } = action.payload;
      state.nextLink = nextLink;
      state.orders = state.orders.concat(orders);
      state.isNextLoading = false;
      state.error = null;
    },
    getOrderVariantsSuccess(state, action) {
      const { variants, nextLink } = action.payload;
      state.nextLink = nextLink;
      state.variants = [...variants];
      state.isLoading = false;
      state.error = null;
    },
    getNextVariantsSuccess(state, action) {
      const { variants, nextLink } = action.payload;
      state.nextLink = nextLink;
      state.variants = state.variants.concat(variants);
      state.isNextLoading = false;
      state.error = null;
    },
    getOrderReportSuccess(state, action) {
      const { orders } = action.payload;
      state.orderReport = orders;
      state.triggerCSVDownload = true;
      state.error = null;
    },
    getItemReportSuccess(state, action) {
      const { variants } = action.payload;
      state.variantReport = variants;
      state.triggerCSVDownload = true;
      state.error = null;
    },
    setTriggerCSVFalse(state) {
      state.triggerCSVDownload = false;
    },
    clearOrderHistoryReports(state) {
      state.orderReport = [];
      state.variantReport = [];
    },
    setOrderHasUpdated(state, action) {
      const { value, newOrderId } = action.payload;
      state.singleOrder.hasUpdated = value;
      state.singleOrder.newOrderId = newOrderId;
    },
    resetOrderHistory(state) {
      state.isLoading = false;
      state.isNextLoading = false;
      state.nextLink = null;
      state.orders = [];
      state.variants = [];
      state.orderReport = [];
      state.variantReport = [];
      state.triggerCSVDownload = false;
      state.singleOrder = {
        orderNumber: null,
        user: null,
        address: null,
        distributorAbn: null,
        type: null,
        status: null,
        orderDate: null,
        shipDate: null,
        trackingNum: null,
        totalQty: 0,
        totalEstFreight: 0,
        totalActFreight: 0,
        totalEstTax: 0,
        totalActTax: 0,
        totalEstCost: 0,
        totalActCost: 0,
        variants: [],
        note: null,
        hasUpdated: false,
        newOrderId: null,
      };
      state.error = null;
    },
    setFailure: loadingFailed,
  },
});

export const {
  setIsLoading,
  setNextIsLoading,
  getSingleOrderSuccess,
  getOrderHistorySuccess,
  getOrderVariantsSuccess,
  getNextVariantsSuccess,
  getNextHistorySuccess,
  getOrderReportSuccess,
  getItemReportSuccess,
  clearOrderHistoryReports,
  setTriggerCSVFalse,
  setOrderHasUpdated,
  resetOrderHistory,
  setFailure,
} = orderHistorySlice.actions;

export default orderHistorySlice.reducer;

export const fetchOrderHistory = (filterObject) => async (dispatch) => {
  try {
    dispatch(setIsLoading());
    dispatch(startGlobalLoad());
    const queryString = buildOrderHistoryQuery(filterObject);
    const response = await axiosGetWithNext(queryString);
    if (response.error) throw response.error;
    const mappedData = mapOrderHistoryOrders(response.data.data);
    dispatch(
      getOrderHistorySuccess({
        orders: mappedData,
        nextLink: response.data.nextLink,
      })
    );
    dispatch(stopGlobalLoad());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Order History" }));
    dispatch(stopGlobalLoad());
  }
};

export const fetchNextOrderHistory = (url) => async (dispatch) => {
  try {
    dispatch(setNextIsLoading());
    dispatch(startGlobalLoad());
    const response = await axiosGetWithNext(url);
    if (response.error) throw response.error;
    const mappedData = mapOrderHistoryOrders(response.data.data);
    dispatch(
      getNextHistorySuccess({
        orders: mappedData,
        nextLink: response.data.nextLink,
      })
    );
    dispatch(stopGlobalLoad());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Order History" }));
    dispatch(stopGlobalLoad());
  }
};

export const fetchOrder = (id) => async (dispatch) => {
  try {
    dispatch(setIsLoading());
    dispatch(startGlobalLoad());
    const response = await axiosGet(`/api/orders/${id}`);
    if (response.error) throw response.error;
    let mappedOrder = mapSingleOrder(response.data);
    let mappedVariants = mapOrderVariants(response.data["order-variants"]);
    dispatch(
      getSingleOrderSuccess({
        order: mappedOrder,
        variants: mappedVariants,
      })
    );
    dispatch(stopGlobalLoad());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Order History" }));
    dispatch(stopGlobalLoad());
  }
};

export const fetchOrderVariantHistory = (filterObject) => async (dispatch) => {
  try {
    dispatch(setIsLoading());
    dispatch(startGlobalLoad());
    const queryString = buildOrderVariantHistoryQuery(filterObject);
    const response = await axiosGetWithNext(queryString);
    if (response.error) throw response.error;
    let mappedData = mapOrderHistoryVariants(response.data.data);
    dispatch(
      getOrderVariantsSuccess({
        variants: mappedData,
        nextLink: response.data.nextLink,
      })
    );
    dispatch(stopGlobalLoad());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Order History" }));
    dispatch(stopGlobalLoad());
  }
};

export const fetchNextOrderVariantHistory = (url) => async (dispatch) => {
  try {
    dispatch(setNextIsLoading());
    dispatch(startGlobalLoad());
    const response = await axiosGetWithNext(url);
    if (response.error) throw response.error;
    let mappedData = mapOrderHistoryVariants(response.data.data);
    dispatch(
      getNextVariantsSuccess({
        variants: mappedData,
        nextLink: response.data.nextLink,
      })
    );
    dispatch(stopGlobalLoad());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Order History" }));
    dispatch(stopGlobalLoad());
  }
};

export const fetchOrderReport = (filters) => async (dispatch, getState) => {
  const filterObject = filters ?? getState().filters;
  try {
    dispatch(
      setIsStepper({
        stepBool: true,
        stepTitle: "Generating Order History Report",
      })
    );
    let orderArray = [];
    let stepValue = 10;

    let queryString = buildOrderHistoryQuery(filterObject);
    queryString += "&page[size]=50";
    let initialResponse = await axiosGetWithNext(queryString);
    if (initialResponse.error) throw initialResponse.error;
    let initialMappedData = mapOrderHistoryOrders(initialResponse.data.data);
    orderArray = orderArray.concat(initialMappedData);

    let nextLink = initialResponse.data.nextLink;
    dispatch(updateStepperValue({ value: stepValue }));

    if (nextLink) {
      const pageSize = initialResponse.data.data.length;
      let fetchCount =
        Math.ceil(initialResponse.data.totalEntries / pageSize) - 1;
      stepValue = parseFloat((90 / fetchCount).toFixed(2));

      for (let i = 0; i < fetchCount; i++) {
        let response = await axiosGetWithNext(nextLink);
        if (response.error) throw response.error;
        nextLink = response.data.nextLink;
        let mappedData = mapOrderHistoryOrders(response.data.data);
        orderArray = orderArray.concat(mappedData);
        dispatch(updateStepperValue({ value: stepValue }));
      }
    } else {
      dispatch(updateStepperValue({ value: 90 }));
    }

    dispatch(resetStepperValue());
    dispatch(getOrderReportSuccess({ orders: orderArray }));
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(
      setError({ error: err.toString(), source: "Order History Reports" })
    );
    dispatch(resetStepperValue());
  }
};

export const fetchOrderVariantReport =
  (pdf, setPDFLoading) => async (dispatch, getState) => {
    const filterObject = getState().filters;
    try {
      dispatch(
        setIsStepper({
          stepBool: true,
          stepTitle: "Generating Order History Report",
        })
      );
      let orderVariantArray = [];
      let stepValue = 10;

      let queryString = buildOrderVariantHistoryQuery(filterObject);
      queryString += "&page[size]=50";
      let initialResponse = await axiosGetWithNext(queryString);
      if (initialResponse.error) throw initialResponse.error;
      let initialMappedData = mapOrderHistoryVariants(
        initialResponse.data.data
      );
      orderVariantArray = orderVariantArray.concat(initialMappedData);

      let nextLink = initialResponse.data.nextLink;
      dispatch(updateStepperValue({ value: stepValue }));

      if (nextLink) {
        const pageSize = initialResponse.data.data.length;
        let fetchCount =
          Math.ceil(initialResponse.data.totalEntries / pageSize) - 1;
        stepValue = parseFloat((90 / fetchCount).toFixed(2));

        for (let i = 0; i < fetchCount; i++) {
          let response = await axiosGetWithNext(nextLink);
          if (response.error) throw response.error;
          nextLink = response.data.nextLink;
          let mappedData = mapOrderHistoryVariants(response.data.data);
          orderVariantArray = orderVariantArray.concat(mappedData);
          dispatch(updateStepperValue({ value: stepValue }));
        }
      } else {
        dispatch(updateStepperValue({ value: 90 }));
      }

      dispatch(resetStepperValue());
      if (!pdf) {
        dispatch(getItemReportSuccess({ variants: orderVariantArray }));
      } else {
        handlePDFDownload(
          <OrderHistorySalesSummaryPDF data={orderVariantArray} />,
          "sales-summary",
          setPDFLoading ? setPDFLoading : () => {}
        );
      }
    } catch (err) {
      dispatch(setFailure({ error: err.toString() }));
      dispatch(
        setError({ error: err.toString(), source: "Order History Reports" })
      );
      dispatch(resetStepperValue());
    }
  };
