/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import { useEffect } from "react";

import { useQueryClient } from "@tanstack/react-query";
import { Contained } from "src/components/StyledComponents";

import { useCurrentOrderSet } from "../orderSet/data/CurrentOrderSetContext";
import useOrderSetId from "../orderSet/data/useOrderSetId";
import { orderSetsKeyFactory } from "../queries/orderSetQueries";
import OrderSetBudgetImpact from "./OrderSetBudgetImpact";
import OrderSetOrdersOverview from "./OrderSetOrdersOverview";
import OrderSetSummaryActionPanel from "./OrderSetSummaryActionPanel";
import OrderSetVariantsOverview from "./OrderSetVariantsOverview";

const SectionHeader = tw.h4`mb-2 font-medium text-neutral-600`;

const OrderOverview = () => {
  const queryClient = useQueryClient();
  const orderSetId = useOrderSetId();
  const { orders } = useCurrentOrderSet();

  const hasBudgets = orders?.some((o) =>
    o.orderVariants.some((ov) => ov.budgets.length > 0)
  );

  useEffect(() => {
    queryClient.invalidateQueries(orderSetsKeyFactory.detail(orderSetId));
  }, [orderSetId, queryClient]);

  return (
    <Contained tw="py-8">
      {/* minmax trick: https://css-tricks.com/preventing-a-grid-blowout/ */}
      <div tw="grid grid-flow-dense grid-cols-1 lg:grid-cols-[minmax(0, 1fr) 320px] gap-4 lg:gap-6 relative">
        <div tw="lg:col-start-2 relative">
          <div tw="sticky top-[var(--header-height)]">
            <SectionHeader>Summary</SectionHeader>
            <OrderSetSummaryActionPanel />
          </div>
        </div>
        <div tw="space-y-8">
          {orders && hasBudgets && (
            <div>
              <SectionHeader>Budget Impact</SectionHeader>
              <OrderSetBudgetImpact />
            </div>
          )}

          <div>
            <SectionHeader>Item Overview</SectionHeader>
            <OrderSetVariantsOverview />
          </div>

          <div>
            <SectionHeader>Order Overview</SectionHeader>
            <OrderSetOrdersOverview />
          </div>
        </div>
      </div>
    </Contained>
  );
};

export default OrderOverview;
