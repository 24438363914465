import { createQueryKeys } from "@lukemorales/query-key-factory";

import { Order } from "@models/Order";
import client from "@services/api";
import { buildPaginatedQuery } from "@utils/reactQuery";

export const ordersKeyFactory = createQueryKeys("orders", {
  paginated: (params) => ({
    queryKey: [params],
    queryFn: () =>
      client.get<Order[]>("orders", {
        params,
      }),
  }),
});

export const useOrdersPaginated = buildPaginatedQuery<Order>(
  ordersKeyFactory.paginated
);
