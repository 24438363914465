import React from "react";
import { useSelector } from "react-redux";

const UserCanView = ({ allow, deny, children }) => {
  const { role } = useSelector((state) => state.user);
  return (
    (!allow.length || allow.includes(role)) && !deny.includes(role) && children
  );
};

UserCanView.defaultProps = {
  allow: [],
  deny: [],
};

export default UserCanView;
