/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useEffect, useState } from "react";
import { Controller } from "react-hook-form";

import {
  Button,
  Checkbox,
  Chip,
  FormControl,
  Popover,
  Typography,
} from "@mui/material";

import { capitalize } from "lodash";

const VariantAccSection = ({
  varOptionName,
  options,
  disabled,
  categories,
  control,
  setValue,
  getValues,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredOptions, setFilteredOptions] = useState([]);

  // Filtering options based on search term
  useEffect(() => {
    if (options) {
      setFilteredOptions(
        options.filter((opt) =>
          opt.name.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    }
  }, [options, searchTerm]);

  // Setting the data in the form for each selected option
  useEffect(() => {
    selected.forEach((item) => setValue(`${varOptionName}-${item.id}`, true));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  // Set the selected state with form values so that chips will show
  useEffect(() => {
    const formValues = getValues();

    const selectedOptions = options.filter(
      (opt) => formValues[`${varOptionName}-${opt.id}`]
    );

    setSelected(selectedOptions);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  return (
    <>
      <div tw="flex justify-between">
        <Typography>{capitalize(varOptionName)}</Typography>
        <FormControl variant="outlined">
          <Button
            variant="contained"
            onClick={(event) => {
              setAnchorEl(event.currentTarget);
              setOpen(true);
            }}
            disabled={disabled}
          >
            Select {capitalize(varOptionName)}
          </Button>
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={() => {
              setAnchorEl(null);
              setOpen(false);
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
          >
            <div tw="p-4 max-h-[400px] overflow-y-auto">
              {" "}
              <input
                type="text"
                placeholder={"Search " + varOptionName + "..."}
                tw="w-full p-2 mb-2 border rounded"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <hr />
              {categories && (
                <ul tw="list-none mt-2">
                  {categories.map((category) => (
                    <li key={category}>
                      <Checkbox
                        checked={selectedCategories.includes(category)}
                        onChange={(e) => {
                          let categoryOptions = [];
                          if (category === "All") {
                            categoryOptions = options;
                          } else {
                            categoryOptions = options.filter((opt) =>
                              opt.name.includes(category)
                            );
                          }

                          // If the box was checked add the right options to selected and the category to selectedCategories
                          if (e.target.checked) {
                            setSelected((prev) => [
                              ...new Set([...prev, ...categoryOptions]),
                            ]);
                            setSelectedCategories((prev) => [
                              ...prev,
                              category,
                            ]);
                          } else {
                            // Otherwise remove these options/category
                            setSelected((prev) =>
                              prev.filter(
                                (item) => !categoryOptions.includes(item)
                              )
                            );
                            setSelectedCategories((prev) =>
                              prev.filter((cat) => cat !== category)
                            );
                          }

                          categoryOptions.forEach((opt) => {
                            setValue(
                              `${varOptionName}-${opt.id}`,
                              e.target.checked
                            );
                          });
                        }}
                      />
                      {category}
                    </li>
                  ))}
                </ul>
              )}
              <hr />
              <ul tw="list-none mt-2">
                {filteredOptions.map((opt) => (
                  <li key={opt.id}>
                    <Controller
                      name={`${varOptionName}-${opt.id}`}
                      control={control}
                      defaultValue={false}
                      render={({ field }) => (
                        <Checkbox
                          checked={field.value}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setSelected((prev) => [...prev, opt]);
                            } else {
                              setSelected((prev) =>
                                prev.filter((item) => item.id !== opt.id)
                              );
                              // Set the form value to false for this unchecked option
                              setValue(`${varOptionName}-${opt.id}`, false);
                            }
                            field.onChange(e.target.checked);
                          }}
                        />
                      )}
                    />
                    {opt.name}
                  </li>
                ))}
              </ul>
            </div>
          </Popover>
        </FormControl>
      </div>

      <div tw="mt-4">
        {selected.map((opt) => (
          <Chip
            key={opt.id}
            label={opt.name}
            onDelete={() => {
              setSelected((prev) => prev.filter((item) => item.id !== opt.id));
              setValue(`${varOptionName}-${opt.id}`, false);
            }}
            color="primary"
            style={{ marginRight: 8, marginBottom: 8 }}
            disabled={disabled}
          />
        ))}
      </div>
    </>
  );
};

export default VariantAccSection;
