import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { useAuth0 } from "@auth0/auth0-react";

import Loading from "../components/Utility/Loading";
import { loginWithCode } from "../redux/slices/users/userSlice";

const AuthOLanding = () => {
  const dispatch = useDispatch();
  const {
    loginWithRedirect,
    getAccessTokenSilently,
    getIdTokenClaims,
    isLoading: isAuthLoading,
  } = useAuth0();
  const navigate = useNavigate();

  const [code, setCode] = useState(null);
  const [hasGotUser, setHasGotUser] = useState(false);
  const [loginError, setLoginError] = useState(null);
  const { isLoading, loginIsLoading } = useSelector((state) => state.user);
  const currentRole = useSelector((state) => state.user.role);
  const error = useSelector((state) => state.user.logInError);

  const getUser = useCallback(async () => {
    const token = await getAccessTokenSilently();
    const claims = await getIdTokenClaims();
    dispatch(loginWithCode(token, new Date(claims.exp * 1000)));
  }, [dispatch, getAccessTokenSilently, getIdTokenClaims]);

  useEffect(() => {
    const params = new URLSearchParams(document.location.search.substring(1));
    setCode(params.get("code"));
    setLoginError(params.get("error") ?? null);
  }, []);

  // if not loading, no user, and no code in params, redirect to auth 0
  useEffect(() => {
    if (
      !code &&
      !currentRole &&
      !error &&
      !loginIsLoading &&
      !isLoading &&
      !isAuthLoading &&
      !hasGotUser
    ) {
      loginWithRedirect();
    }
  }, [
    code,
    currentRole,
    error,
    isAuthLoading,
    isLoading,
    loginIsLoading,
    loginWithRedirect,
    hasGotUser,
  ]);

  // if code exists, login to api with code
  useEffect(() => {
    if (
      code &&
      !currentRole &&
      !error &&
      !loginIsLoading &&
      !isLoading &&
      !isAuthLoading &&
      !hasGotUser
    ) {
      getUser();
      setHasGotUser(true);
    }
  }, [
    code,
    currentRole,
    error,
    getUser,
    hasGotUser,
    isAuthLoading,
    isLoading,
    loginIsLoading,
  ]);

  // if user exists, redirect to the dashboard
  useEffect(() => {
    if (currentRole) {
      navigate("/dashboard");
    }
  }, [currentRole, navigate]);

  // if there is an error logging in, redirect to login page with error
  useEffect(() => {
    if (loginError) {
      let encodedError = `You have been denied access from Auth 0. This could be due to your single sign on application, please contact your administrator or RTA support.  Error: ${loginError}`;
      navigate(`/login/error/${encodedError}`);
    }
  }, [loginError, navigate]);

  // if generic error, redirect to home
  useEffect(() => {
    if (error) {
      navigate("/");
    }
  }, [error, navigate]);

  // returns loading state while checks are being made
  return <Loading />;
};

export default AuthOLanding;
