import React from "react";
import { useDispatch, useSelector } from "react-redux";

import CancelIcon from "@mui/icons-material/Cancel";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";

import PropTypes from "prop-types";

import {
  deleteSingleAddress,
  updateFavoriteAddressList,
} from "../../redux/slices/addresses/addressSlice";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
}));

const AddressTable = ({ addresses, name, isLoading, id }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const territoryId = useSelector((state) => state.user.currentTerritory);

  const handleDelete = (addressId) => {
    let filteredAddList = addresses.filter((add) => add.id !== addressId);
    dispatch(updateFavoriteAddressList(id, name, filteredAddList, territoryId));
    dispatch(deleteSingleAddress({ id: id, addressId: addressId }));
  };

  return (
    <>
      <TableContainer
        className={classes.tableContainer}
        style={{ maxHeight: "Calc(100vh - 200px)" }}
      >
        <Table stickyHeader className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.headerText} align="left">
                Distributor / Name
              </TableCell>
              <TableCell className={classes.headerText} align="left">
                Phone Number
              </TableCell>
              <TableCell className={classes.headerText} align="left">
                Street Address
              </TableCell>
              <TableCell className={classes.headerText} align="left">
                City
              </TableCell>
              <TableCell className={classes.headerText} align="left">
                State
              </TableCell>
              <TableCell className={classes.headerText} align="left">
                Zip
              </TableCell>
              <TableCell className={classes.headerText} align="left">
                Type
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {!isLoading && addresses.length === 0 && (
              <TableRow>
                <TableCell align="left" colSpan={6}>
                  <Typography className={classes.headerText}>
                    {`You currently don't have any favorite address sets...`}
                  </Typography>
                </TableCell>
              </TableRow>
            )}
            {!isLoading &&
              addresses.length > 0 &&
              addresses.map((add) => (
                <TableRow key={add.id}>
                  <TableCell align="left">{add.name}</TableCell>
                  <TableCell align="left">{add.phoneNumber ?? "---"}</TableCell>
                  <TableCell align="left">
                    {`${add.streetAddressOne} ${
                      add.streetAddressTwo ? `, ${add.streetAddressTwo} ` : ""
                    }`}
                  </TableCell>
                  <TableCell align="left">{add.city}</TableCell>
                  <TableCell align="left">{add.state.code}</TableCell>
                  <TableCell align="left">{add.zip}</TableCell>
                  <TableCell align="left">{add.type}</TableCell>
                  <TableCell align="right">
                    <Tooltip title="Remove Favorite">
                      <IconButton
                        onClick={() => handleDelete(add.id)}
                        size="large"
                      >
                        <CancelIcon fontSize="small" color="inherit" />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            {isLoading && (
              <TableRow>
                <TableCell align="left" colSpan={9}>
                  <CircularProgress />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

AddressTable.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  addresses: PropTypes.array.isRequired,
};

export default React.memo(AddressTable);
