import { Route, Routes } from "react-router-dom";

import { without } from "lodash";
import useRedirect from "src/hooks/useRedirect";
import BudgetDetail from "src/pages/BudgetDetail";
import BudgetSummary from "src/pages/BudgetSummary";
import Order from "src/pages/Order";
import PlaceOnDemandNationalCouponOrder from "src/pages/PlaceOnDemandNationalCouponOrder";
import PreOrder from "src/pages/PreOrder";
import ReportRapid from "src/pages/ReportRapid";

import AuthRoute from "../components/Utility/AuthRoute";
import {
  ALL_ROLES,
  NON_SUPPLIER_ROLES,
  PLANNING_TOOL_ROLES,
  PLANNING_TOOL_SUPPLIER,
  READ_ONLY,
  RFQ_ROLES,
  SUPPLIER_ROLES,
} from "../constants/permissions";
import C2MItemHistory from "../pages/C2MItemHistory";
import C2MOrderHistory from "../pages/C2MOrderHistory";
import ComplianceContacts from "../pages/ComplianceContacts";
import ComplianceItems from "../pages/ComplianceItems";
import ComplianceOverrides from "../pages/ComplianceOverrides";
import ComplianceRules from "../pages/ComplianceRules";
import Dashboard from "../pages/Dashboard";
import DeactivatedUser from "../pages/DeactivatedUser";
import FourOhFour from "../pages/FourOhFour";
import Help from "../pages/Help";
import ItemCatalog from "../pages/ItemCatalog";
import NewSupplier from "../pages/NewSupplier";
import NewUser from "../pages/NewUser";
import OrderApprovals from "../pages/OrderApprovals";
import OrderHistory from "../pages/OrderHistory";
import PendingCompliance from "../pages/PendingCompliance";
import PlaceInventoryOrder from "../pages/PlaceInventoryOrder";
import PlaceOnDemandOrder from "../pages/PlaceOnDemandOrder";
import PlanningTool from "../pages/PlanningTool";
import PlanningToolActivate from "../pages/PlanningToolActivate";
import PlanningToolChangelog from "../pages/PlanningToolChangelog";
import PlanningToolCreate from "../pages/PlanningToolCreate";
import PlanningToolQuote from "../pages/PlanningToolQuote";
import PlanningToolQuoteSearch from "../pages/PlanningToolQuoteSearch";
import PlanningToolRFQs from "../pages/PlanningToolRFQs";
import PlanningToolRFQsSearch from "../pages/PlanningToolRFQsSearch";
import PlanningToolReports from "../pages/PlanningToolReports";
import PlanningToolSearch from "../pages/PlanningToolSearch";
import Profile from "../pages/Profile";
import Program from "../pages/Program";
import Programs from "../pages/Programs";
import PurchaseOrderHistory from "../pages/PurchaseOrderHistory";
import PurchaseOrderRollup from "../pages/PurchaseOrderRollup";
import RFPHistory from "../pages/RFPHistory";
import RFPRollup from "../pages/RFPRollup";
import ReportAudit from "../pages/ReportAudit";
import ReportBulkPOShipParams from "../pages/ReportBulkPOShipParams";
import ReportCDC from "../pages/ReportCDC";
import ReportCalendar from "../pages/ReportCalendar";
import ReportComplianceAudit from "../pages/ReportComplianceAudit";
import ReportCoupon from "../pages/ReportCoupon";
import ReportFinanceBilling from "../pages/ReportFinanceBilling";
import ReportFinanceJournal from "../pages/ReportFinanceJournal";
import ReportFinanceTracker from "../pages/ReportFinanceTracker";
import ReportInventory from "../pages/ReportInventory";
import ReportInvoiceDetail from "../pages/ReportInvoiceDetail";
import ReportInvoiceDoubleCheck from "../pages/ReportInvoiceDoubleCheck";
import ReportInvoicing from "../pages/ReportInvoicing";
import ReportOpenPurchaseOrders from "../pages/ReportOpenPurchaseOrders";
import ReportOrderDetail from "../pages/ReportOrderDetail";
import ReportOrderWindowDetail from "../pages/ReportOrderWindowDetail";
import ReportOrderWindowItems from "../pages/ReportOrderWindowItems";
import ReportOrderWindowSummary from "../pages/ReportOrderWindowSummary";
import ReportOrderedItems from "../pages/ReportOrderedItems";
import ReportPOAccrual from "../pages/ReportPOAccrual";
import ReportPOCompliance from "../pages/ReportPOCompliance";
import ReportPODeliveryDate from "../pages/ReportPODeliveryDate";
import ReportPreOrderMOQAnalysis from "../pages/ReportPreOrderMOQAnalysis";
import ReportPrintInvoice from "../pages/ReportPrintInvoice";
import ReportProductList from "../pages/ReportProductList";
import ReportPurchaseOrders from "../pages/ReportPurchaseOrders";
import ReportRFPSummary from "../pages/ReportRFPSummary";
import ReportRoadmapForecast from "../pages/ReportRoadmapForecast";
import ReportShelfInventory from "../pages/ReportShelfInventory";
import ReportSingleItemInventory from "../pages/ReportSingleItemInventory";
import ReportSupplierSpend from "../pages/ReportSupplierSpend";
import ReportsLanding from "../pages/ReportsLanding";
import Rollup from "../pages/Rollup";
import Settings from "../pages/Settings";
import SharedItems from "../pages/SharedItems";
import ShelfInventory from "../pages/ShelfInventory";
import ShelfInventoryAdmin from "../pages/ShelfInventoryAdmin";
import SingleOrder from "../pages/SingleOrder";
import SuspenseRoute from "./SuspenseRoute";

const AuthRoutes = ({ role, handleLogout }) => {
  useRedirect();
  return (
    <>
      <Routes>
        <Route element={<AuthRoute allowedRoles={ALL_ROLES} />}>
          <Route path="/dashboard" element={<Dashboard role={role} />} />
        </Route>
        <Route path="/shared/items/:itemIds" element={<SharedItems />} />
        <Route
          path="/newSupplier"
          element={<NewSupplier handleLogout={handleLogout} />}
        />
        <Route
          path="/newUser"
          element={<NewUser handleLogout={handleLogout} />}
        />
        <Route
          path="/deactivatedUser"
          element={<DeactivatedUser handleLogout={handleLogout} />}
        />
        <Route element={<AuthRoute allowedRoles={PLANNING_TOOL_ROLES} />}>
          <Route
            path="/planning/dashboard"
            element={<PlanningTool userType={role} />}
          />
        </Route>
        <Route element={<AuthRoute allowedRoles={PLANNING_TOOL_ROLES} />}>
          <Route
            path="/planning/program/search"
            element={<PlanningToolSearch userType={role} />}
          />
        </Route>
        <Route
          element={
            <AuthRoute
              allowedRoles={without(
                PLANNING_TOOL_ROLES,
                PLANNING_TOOL_SUPPLIER,
                "field2"
              )}
            />
          }
        >
          <Route
            path="/planning/program/create"
            element={<PlanningToolCreate userType={role} />}
          />
        </Route>
        <Route
          element={
            <AuthRoute
              allowedRoles={without(
                PLANNING_TOOL_ROLES,
                PLANNING_TOOL_SUPPLIER
              )}
            />
          }
        >
          <Route
            path="/planning/program/activate"
            element={<PlanningToolActivate userType={role} />}
          />
        </Route>
        <Route element={<AuthRoute allowedRoles={PLANNING_TOOL_ROLES} />}>
          <Route
            path="/planning/program/:pid"
            element={<PlanningToolCreate userType={role} />}
          />
        </Route>
        <Route element={<AuthRoute allowedRoles={PLANNING_TOOL_ROLES} />}>
          <Route
            path="/planning/changelog/program/:pid"
            element={<PlanningToolChangelog userType={role} />}
          />
        </Route>

        {/* RFQs */}
        <Route element={<AuthRoute allowedRoles={RFQ_ROLES} />}>
          <Route
            path="/planning/rfqs/program/:programId/item/:itemId"
            element={<PlanningToolRFQs userType={role} />}
          />
        </Route>
        <Route element={<AuthRoute allowedRoles={RFQ_ROLES} />}>
          <Route
            path="/planning/rfqs/program/:programId"
            element={<PlanningToolRFQs userType={role} />}
          />
        </Route>
        <Route element={<AuthRoute allowedRoles={RFQ_ROLES} />}>
          <Route
            path="/planning/rfqs"
            element={<PlanningToolRFQsSearch userType={role} />}
          />
        </Route>
        <Route element={<AuthRoute allowedRoles={SUPPLIER_ROLES} />}>
          <Route
            path="/planning/quote/:qid"
            element={<PlanningToolQuote userType={role} />}
          />
        </Route>
        <Route element={<AuthRoute allowedRoles={SUPPLIER_ROLES} />}>
          <Route
            path="/planning/quotes"
            element={<PlanningToolQuoteSearch userType={role} />}
          />
        </Route>
        <Route element={<AuthRoute allowedRoles={PLANNING_TOOL_ROLES} />}>
          <Route path="/planning/reports" element={<PlanningToolReports />} />
        </Route>
        <Route element={<AuthRoute allowedRoles={NON_SUPPLIER_ROLES} />}>
          <Route
            path="/programs"
            element={<Programs orderWindowType="national" />}
          />
        </Route>

        <Route element={<AuthRoute allowedRoles={NON_SUPPLIER_ROLES} />}>
          <Route
            path="/secondary-order-windows"
            element={<Programs orderWindowType="secondary" />}
          />
        </Route>
        <Route element={<AuthRoute allowedRoles={NON_SUPPLIER_ROLES} />}>
          <Route
            path="/program/:programId"
            element={<Program userType={role} />}
          />
        </Route>
        <Route
          element={
            <AuthRoute
              allowedRoles={[
                "field1",
                "field2",
                "purchaser",
                "select-purchaser",
                "super",
                PLANNING_TOOL_SUPPLIER,
              ]}
            />
          }
        >
          <Route path="/orders/open/:orderSetId" element={<Order />} />
          <Route
            path="/orders/:orderWindowId/:programId"
            element={<PreOrder />}
          />
        </Route>

        <Route element={<AuthRoute allowedRoles={NON_SUPPLIER_ROLES} />}>
          <Route
            path="/orders/items/inventory"
            element={<PlaceInventoryOrder userType={role} />}
          />
        </Route>
        <Route
          element={
            <AuthRoute
              allowedRoles={[...NON_SUPPLIER_ROLES, PLANNING_TOOL_SUPPLIER]}
            />
          }
        >
          <Route
            path="/orders/items/onDemand"
            element={<PlaceOnDemandOrder userType={role} />}
          />
        </Route>
        <Route
          element={
            <AuthRoute
              allowedRoles={[...NON_SUPPLIER_ROLES, PLANNING_TOOL_SUPPLIER]}
            />
          }
        >
          <Route
            path="/orders/items/national-coupons"
            element={<PlaceOnDemandNationalCouponOrder userType={role} />}
          />
        </Route>
        <Route
          element={
            <AuthRoute
              allowedRoles={[
                "purchaser",
                "select-purchaser",
                "super",
                READ_ONLY,
              ]}
            />
          }
        >
          <Route path="/purchasing/rfpRollup" element={<RFPRollup />} />
        </Route>

        <Route
          element={
            <AuthRoute
              allowedRoles={[
                "purchaser",
                "select-purchaser",
                "super",
                ...SUPPLIER_ROLES,
              ]}
            />
          }
        >
          <Route
            path="/purchasing/rfp"
            element={
              <SuspenseRoute componentImport={() => import("../pages/RFP")} />
            }
          />
        </Route>

        <Route
          element={
            <AuthRoute
              allowedRoles={[
                "purchaser",
                "select-purchaser",
                "super",
                ...SUPPLIER_ROLES,
              ]}
            />
          }
        >
          <Route
            path="/purchasing/rfpHistory/:filterOption"
            element={<RFPHistory />}
          />
        </Route>
        <Route
          element={
            <AuthRoute
              allowedRoles={[
                "purchaser",
                "select-purchaser",
                "super",
                "planning-tool-supplier",
              ]}
            />
          }
        >
          <Route
            path="/purchasing/poRollup"
            element={<PurchaseOrderRollup />}
          />
        </Route>
        <Route
          element={
            <AuthRoute
              allowedRoles={[
                "purchaser",
                "select-purchaser",
                "super",
                ...SUPPLIER_ROLES,
              ]}
            />
          }
        >
          <Route
            path="/purchasing/purchase-order/:purchaseOrderId"
            element={
              <SuspenseRoute
                componentImport={() => import("../pages/PurchaseOrder")}
              />
            }
          />
        </Route>
        <Route
          element={
            <AuthRoute
              allowedRoles={[
                "purchaser",
                "select-purchaser",
                "super",
                ...SUPPLIER_ROLES,
              ]}
            />
          }
        >
          <Route
            path="/purchasing/poHistory/:filterOption"
            element={<PurchaseOrderHistory />}
          />
        </Route>
        <Route element={<AuthRoute allowedRoles={NON_SUPPLIER_ROLES} />}>
          <Route
            path="/orders/history/single-order/:orderId"
            element={<SingleOrder />}
          />
        </Route>
        <Route element={<AuthRoute allowedRoles={NON_SUPPLIER_ROLES} />}>
          <Route path="/historical-items" element={<C2MItemHistory />} />
        </Route>
        <Route element={<AuthRoute allowedRoles={NON_SUPPLIER_ROLES} />}>
          <Route
            path="/orders/history/c2m-order-history"
            element={<C2MOrderHistory />}
          />
        </Route>
        <Route element={<AuthRoute allowedRoles={NON_SUPPLIER_ROLES} />}>
          <Route
            path="/orders/history/group/:filterOption"
            element={<OrderHistory />}
          />
        </Route>
        <Route
          element={
            <AuthRoute
              allowedRoles={[
                "field2",
                "purchaser",
                "select-purchaser",
                "super",
                READ_ONLY,
                "compliance",
              ]}
            />
          }
        >
          <Route
            path="/orders/approvals"
            element={<OrderApprovals userType={role} />}
          />
        </Route>
        <Route
          element={
            <AuthRoute
              allowedRoles={[
                "field2",
                "purchaser",
                "select-purchaser",
                "super",
                READ_ONLY,
                "compliance",
              ]}
            />
          }
        >
          <Route path="/rollup" element={<Rollup />} />
        </Route>
        <Route element={<AuthRoute allowedRoles={NON_SUPPLIER_ROLES} />}>
          <Route
            path="/items/:catalogType"
            element={<ItemCatalog userType={role} />}
          />
        </Route>
        <Route
          element={
            <AuthRoute
              allowedRoles={[
                "field2",
                "compliance",
                "purchaser",
                "select-purchaser",
                "super",
                READ_ONLY,
              ]}
            />
          }
        >
          <Route
            path="/compliance/pending/:orderIds"
            element={<PendingCompliance />}
          />
        </Route>
        <Route
          element={
            <AuthRoute
              allowedRoles={[
                "field2",
                "compliance",
                "purchaser",
                "select-purchaser",
                "super",
                READ_ONLY,
              ]}
            />
          }
        >
          <Route
            path="/compliance/contacts"
            element={<ComplianceContacts userType={role} />}
          />
        </Route>
        <Route element={<AuthRoute allowedRoles={NON_SUPPLIER_ROLES} />}>
          <Route path="/compliance/items" element={<ComplianceItems />} />
        </Route>
        <Route element={<AuthRoute allowedRoles={["compliance", "super"]} />}>
          <Route
            path="/compliance/overrides"
            element={<ComplianceOverrides />}
          />
        </Route>
        <Route element={<AuthRoute allowedRoles={NON_SUPPLIER_ROLES} />}>
          <Route path="/compliance/rules" element={<ComplianceRules />} />
        </Route>
        <Route element={<AuthRoute allowedRoles={NON_SUPPLIER_ROLES} />}>
          <Route path="/profile" element={<Profile userType={role} />} />
        </Route>
        <Route element={<AuthRoute allowedRoles={["purchaser", "super"]} />}>
          <Route path="/settings" element={<Settings userType={role} />} />
        </Route>
        <Route element={<AuthRoute allowedRoles={NON_SUPPLIER_ROLES} />}>
          <Route path="/reports/landing" element={<ReportsLanding />} />
        </Route>
        <Route
          element={
            <AuthRoute
              allowedRoles={[
                "field1",
                "field2",
                "purchaser",
                "select-purchaser",
                "super",
              ]}
            />
          }
        >
          <Route
            path="/reports/must-haves"
            element={
              <SuspenseRoute
                componentImport={() => import("../pages/ReportMustHaves")}
              />
            }
          />
        </Route>
        <Route
          element={
            <AuthRoute
              allowedRoles={[
                "purchaser",
                "select-purchaser",
                "super",
                ...SUPPLIER_ROLES,
              ]}
            />
          }
        >
          <Route
            path="/reports/po-ship-list"
            element={<ReportBulkPOShipParams />}
          />
        </Route>
        <Route
          element={<AuthRoute allowedRoles={["select-purchaser", "super"]} />}
        >
          <Route path="/reports/cdc" element={<ReportCDC />} />
        </Route>
        <Route
          element={
            <AuthRoute
              allowedRoles={[
                "purchaser",
                "select-purchaser",
                "super",
                "field2",
                READ_ONLY,
                "compliance",
              ]}
            />
          }
        >
          <Route path="/reports/custom-coupon" element={<ReportCoupon />} />
        </Route>
        <Route element={<AuthRoute allowedRoles={["super", "purchasers"]} />}>
          <Route path="/reports/rapid-report" element={<ReportRapid />} />
        </Route>
        <Route element={<AuthRoute allowedRoles={NON_SUPPLIER_ROLES} />}>
          <Route
            path="/reports/roadmap-forecast"
            element={<ReportRoadmapForecast />}
          />
        </Route>
        <Route
          element={
            <AuthRoute
              allowedRoles={[
                "purchaser",
                "select-purchaser",
                "super",
                "compliance",
              ]}
            />
          }
        >
          <Route
            path="/reports/finance-billing"
            element={<ReportFinanceBilling />}
          />
        </Route>
        <Route
          element={
            <AuthRoute
              allowedRoles={[
                "purchaser",
                "select-purchaser",
                "super",
                "compliance",
              ]}
            />
          }
        >
          <Route
            path="/reports/finance-journal"
            element={<ReportFinanceJournal />}
          />
        </Route>
        <Route element={<AuthRoute allowedRoles={NON_SUPPLIER_ROLES} />}>
          <Route
            path="/reports/finance-tracker"
            element={<ReportFinanceTracker />}
          />
        </Route>
        <Route
          element={
            <AuthRoute
              allowedRoles={[
                "purchaser",
                "select-purchaser",
                "super",
                "field2",
                READ_ONLY,
                "compliance",
              ]}
            />
          }
        >
          <Route
            path="/reports/order-window-items"
            element={<ReportOrderWindowItems />}
          />
        </Route>
        <Route
          element={
            <AuthRoute
              allowedRoles={[
                "purchaser",
                "select-purchaser",
                "super",
                "field2",
                READ_ONLY,
                "compliance",
              ]}
            />
          }
        >
          <Route
            path="/reports/order-window-detail"
            element={<ReportOrderWindowDetail />}
          />
        </Route>
        <Route
          element={
            <AuthRoute
              allowedRoles={[
                "purchaser",
                "select-purchaser",
                "super",
                READ_ONLY,
              ]}
            />
          }
        >
          <Route
            path="/reports/open-purchase-orders"
            element={<ReportOpenPurchaseOrders />}
          />
        </Route>
        <Route
          element={
            <AuthRoute
              allowedRoles={[
                "purchaser",
                "select-purchaser",
                "super",
                READ_ONLY,
              ]}
            />
          }
        >
          <Route path="/reports/order-detail" element={<ReportOrderDetail />} />
        </Route>
        <Route element={<AuthRoute allowedRoles={["super"]} />}>
          <Route
            path="/reports/ordered-items"
            element={<ReportOrderedItems />}
          />
        </Route>

        <Route element={<AuthRoute allowedRoles={NON_SUPPLIER_ROLES} />}>
          <Route
            path="/reports/order-window-summary-presentation"
            element={
              <SuspenseRoute
                componentImport={() =>
                  import("../pages/ReportOrderWindowSummaryPres")
                }
              />
            }
          />
        </Route>

        <Route
          element={
            <AuthRoute
              allowedRoles={[
                "purchaser",
                "select-purchaser",
                "super",
                "field2",
                READ_ONLY,
                "compliance",
              ]}
            />
          }
        >
          <Route
            path="/reports/order-window-summary"
            element={<ReportOrderWindowSummary />}
          />
        </Route>
        <Route
          element={
            <AuthRoute
              allowedRoles={[
                "purchaser",
                "select-purchaser",
                "super",
                "field2",
                READ_ONLY,
                "compliance",
              ]}
            />
          }
        >
          <Route path="/reports/rfp-summary" element={<ReportRFPSummary />} />
        </Route>

        <Route element={<AuthRoute allowedRoles={NON_SUPPLIER_ROLES} />}>
          <Route
            path="/reports/items"
            element={
              <SuspenseRoute
                componentImport={() => import("../pages/ReportItems")}
              />
            }
          />
        </Route>

        <Route
          element={
            <AuthRoute
              allowedRoles={[
                "field2",
                "purchaser",
                "select-purchaser",
                "super",
              ]}
            />
          }
        >
          <Route path="/reports/inventory" element={<ReportInventory />} />
        </Route>
        <Route
          element={
            <AuthRoute
              allowedRoles={[
                "super",
                READ_ONLY,
                "purchaser",
                "select-purchaser",
              ]}
            />
          }
        >
          <Route
            path="/reports/invoice-detail"
            element={<ReportInvoiceDetail />}
          />
        </Route>
        <Route
          element={<AuthRoute allowedRoles={["select-purchaser", "super"]} />}
        >
          <Route
            path="/reports/invoice-double-check"
            element={<ReportInvoiceDoubleCheck />}
          />
        </Route>
        <Route
          element={<AuthRoute allowedRoles={["select-purchaser", "super"]} />}
        >
          <Route path="/reports/invoicing" element={<ReportInvoicing />} />
        </Route>
        <Route
          element={
            <AuthRoute
              allowedRoles={[
                "purchaser",
                "select-purchaser",
                "super",
                "compliance",
              ]}
            />
          }
        >
          <Route
            path="/reports/po-compliance"
            element={<ReportPOCompliance />}
          />
        </Route>
        <Route
          element={
            <AuthRoute
              allowedRoles={[
                "purchaser",
                "select-purchaser",
                "super",
                "compliance",
              ]}
            />
          }
        >
          <Route
            path="/reports/compliance-audit"
            element={<ReportComplianceAudit />}
          />
        </Route>
        <Route
          element={
            <AuthRoute
              allowedRoles={[
                "super",
                READ_ONLY,
                "purchaser",
                "select-purchaser",
              ]}
            />
          }
        >
          <Route path="/reports/qn-invoice" element={<ReportPrintInvoice />} />
        </Route>
        <Route
          element={
            <AuthRoute
              allowedRoles={[
                "super",
                READ_ONLY,
                "purchaser",
                "select-purchaser",
              ]}
            />
          }
        >
          <Route
            path="/reports/purchase-order-report"
            element={<ReportPurchaseOrders />}
          />
        </Route>
        <Route
          element={
            <AuthRoute
              allowedRoles={[
                "field1",
                "field2",
                "purchaser",
                "select-purchaser",
                "super",
              ]}
            />
          }
        >
          <Route
            path="/reports/variant-allocation"
            element={<ReportShelfInventory />}
          />
        </Route>
        <Route
          element={
            <AuthRoute
              allowedRoles={["purchaser", "select-purchaser", "super"]}
            />
          }
        >
          <Route
            path="/reports/po-delivery-date-report"
            element={<ReportPODeliveryDate />}
          />
        </Route>
        <Route
          element={
            <AuthRoute
              allowedRoles={[
                "field2",
                "purchaser",
                "select-purchaser",
                "super",
              ]}
            />
          }
        >
          <Route
            path="/reports/single-item-inventory"
            element={<ReportSingleItemInventory />}
          />
        </Route>
        <Route element={<AuthRoute allowedRoles={["super"]} />}>
          <Route
            path="/reports/supplier-spend"
            element={<ReportSupplierSpend />}
          />
        </Route>
        <Route
          element={
            <AuthRoute
              allowedRoles={[
                "super",
                READ_ONLY,
                "purchaser",
                "select-purchaser",
              ]}
            />
          }
        >
          <Route
            path="/reports/pre-order-moq-analysis"
            element={<ReportPreOrderMOQAnalysis />}
          />
        </Route>
        <Route
          element={
            <AuthRoute
              allowedRoles={[
                "super",
                "purchaser",
                "select-purchaser",
                "field2",
              ]}
            />
          }
        >
          <Route
            path="/reports/order-window-moq"
            element={
              <SuspenseRoute
                componentImport={() => import("../pages/ReportOrderWindowMOQ")}
              />
            }
          />
        </Route>
        <Route
          element={
            <AuthRoute
              allowedRoles={[
                "super",
                "purchaser",
                "select-purchaser",
                "field2",
              ]}
            />
          }
        >
          <Route
            path="/reports/order-window-spend"
            element={
              <SuspenseRoute
                componentImport={() =>
                  import("../pages/ReportOrderWindowSpend")
                }
              />
            }
          />
        </Route>
        <Route
          element={
            <AuthRoute
              allowedRoles={[
                "super", // TODO: Determine what roles should be able to see this
              ]}
            />
          }
        >
          <Route
            path="/reports/brand-recap"
            element={
              <SuspenseRoute
                componentImport={() => import("../pages/ReportBrandRecap")}
              />
            }
          />
        </Route>
        <Route element={<AuthRoute allowedRoles={PLANNING_TOOL_ROLES} />}>
          <Route path="/reports/audit" element={<ReportAudit />} />
        </Route>
        <Route element={<AuthRoute allowedRoles={PLANNING_TOOL_ROLES} />}>
          <Route path="/reports/calendar" element={<ReportCalendar />} />
        </Route>
        <Route
          element={
            <AuthRoute
              allowedRoles={["purchaser", "select-purchaser", "super"]}
            />
          }
        >
          <Route path="/reports/po-accrual" element={<ReportPOAccrual />} />
        </Route>
        <Route element={<AuthRoute allowedRoles={["super"]} />}>
          <Route path="/reports/product-list" element={<ReportProductList />} />
        </Route>
        <Route element={<AuthRoute allowedRoles={["super"]} />}>
          <Route
            path="/reports/product-list-pdf"
            element={
              <SuspenseRoute
                componentImport={() => import("../pages/ReportProductListPDF")}
              />
            }
          />
        </Route>
        <Route
          element={
            <AuthRoute
              allowedRoles={[
                READ_ONLY,
                "field2",
                "purchaser",
                "select-purchaser",
                "super",
              ]}
            />
          }
        >
          <Route path="/shelf-inventory/:id" element={<ShelfInventory />} />
        </Route>
        <Route
          element={
            <AuthRoute
              allowedRoles={[
                "field2",
                "purchaser",
                "select-purchaser",
                "super",
              ]}
            />
          }
        >
          <Route
            path="/shelf-inventory-admin"
            element={<ShelfInventoryAdmin />}
          />
        </Route>
        <Route element={<AuthRoute allowedRoles={NON_SUPPLIER_ROLES} />}>
          <Route path="/budgets" element={<BudgetSummary />} />
        </Route>
        <Route element={<AuthRoute allowedRoles={NON_SUPPLIER_ROLES} />}>
          <Route path="/budgets/:id" element={<BudgetDetail />} />
        </Route>
        <Route element={<AuthRoute allowedRoles={ALL_ROLES} />}>
          <Route path="/help" element={<Help />} />
        </Route>
        <Route path="/whoops" element={<FourOhFour />} />
      </Routes>
    </>
  );
};

export default AuthRoutes;
