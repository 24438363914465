import { createSlice } from "@reduxjs/toolkit";

import { axiosGetWithNext } from "../../../api/axiosCalls";
import { setError } from "../errorSlice";
import {
  resetStepperValue,
  setIsStepper,
  startGlobalLoad,
  stopGlobalLoad,
  updateStepperValue,
} from "../globalLoadSlice";
import { buildPurchaseOrderHistoryQuery } from "./helpers";
import { mapPOHistoryVariants } from "./maps";

let initialState = {
  isLoading: false,
  isNextLoading: false,
  posPerPage: 20,
  nextPage: null,
  nextLink: null,
  pos: [],
  poReport: [],
  triggerCSVDownload: false,
  error: null,
};

const startLoading = (state) => {
  state.isLoading = true;
};

const startNextLoading = (state) => {
  state.isNextLoading = true;
};

const loadingFailed = (state, action) => {
  const { error } = action.payload;
  state.isLoading = false;
  state.error = error;
};

const purchaseOrderHistorySlice = createSlice({
  name: "poHistory",
  initialState,
  reducers: {
    setIsLoading: startLoading,
    setNextIsLoading: startNextLoading,
    getPoHistorySuccess(state, action) {
      const { pos, nextLink } = action.payload;
      state.nextPage = nextLink ? true : false;
      state.nextLink = nextLink;
      state.pos = [...pos];
      state.isLoading = false;
      state.error = null;
    },
    getNextPoHistorySuccess(state, action) {
      const { pos, nextLink } = action.payload;
      state.nextPage = nextLink ? true : false;
      state.nextLink = nextLink;
      state.pos = state.pos.concat(pos);
      state.isNextLoading = false;
      state.error = null;
    },
    getPOReportSuccess(state, action) {
      const { pos } = action.payload;
      state.poReport = pos;
      state.triggerCSVDownload = true;
      state.error = null;
    },
    setTriggerCSVFalse(state) {
      state.triggerCSVDownload = false;
    },
    clearPOReport(state) {
      state.poReport = [];
    },
    resetPoHistory(state) {
      state.isLoading = false;
      state.isNextLoading = false;
      state.posPerPage = 20;
      state.nextPage = null;
      state.nextLink = null;
      state.pos = [];
      state.poReport = [];
      state.triggerCSVDownload = false;
      state.error = null;
    },
    setFailure: loadingFailed,
  },
});

export const {
  setIsLoading,
  setNextIsLoading,
  getPoHistorySuccess,
  getNextPoHistorySuccess,
  getPOReportSuccess,
  setTriggerCSVFalse,
  clearPOReport,
  resetPoHistory,
  setFailure,
} = purchaseOrderHistorySlice.actions;

export default purchaseOrderHistorySlice.reducer;

export const fetchFilteredPOHistory = (filterObject) => async (dispatch) => {
  try {
    dispatch(setIsLoading());
    dispatch(startGlobalLoad());
    const queryString = buildPurchaseOrderHistoryQuery(filterObject);
    const response = await axiosGetWithNext(queryString);
    if (response.error) throw response.error;
    const mappedData = mapPOHistoryVariants(response.data.data);
    dispatch(
      getPoHistorySuccess({
        pos: mappedData,
        nextLink: response.data.nextLink,
      })
    );
    dispatch(stopGlobalLoad());
  } catch (err) {
    console.error(err);
    dispatch(setFailure({ error: err.toString() }));
    dispatch(
      setError({ error: err.toString(), source: "Purchase Order History" })
    );
    dispatch(stopGlobalLoad());
  }
};

export const fetchNextFilteredPOHistory = (url) => async (dispatch) => {
  try {
    dispatch(setNextIsLoading());
    dispatch(startGlobalLoad());
    const response = await axiosGetWithNext(url);
    if (response.error) throw response.error;
    const mappedData = mapPOHistoryVariants(response.data.data);
    dispatch(
      getNextPoHistorySuccess({
        pos: mappedData,
        nextLink: response.data.nextLink,
      })
    );
    dispatch(stopGlobalLoad());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(
      setError({ error: err.toString(), source: "Purchase Order History" })
    );
    dispatch(stopGlobalLoad());
  }
};

export const fetchPOReport = (filterObject) => async (dispatch) => {
  try {
    dispatch(
      setIsStepper({
        stepBool: true,
        stepTitle: "Generating Purchase Order History Report",
      })
    );
    let poArray = [];
    let stepValue = 25;

    let queryString = buildPurchaseOrderHistoryQuery(filterObject);
    let initialResponse = await axiosGetWithNext(queryString);
    if (initialResponse.error) throw initialResponse.error;
    let initialMappedData = mapPOHistoryVariants(initialResponse.data.data);
    poArray = poArray.concat(initialMappedData);

    let nextLink = initialResponse.data.nextLink;
    dispatch(updateStepperValue({ value: stepValue }));

    if (nextLink) {
      const pageSize = initialResponse.data.data.length;
      let fetchCount =
        Math.ceil(initialResponse.data.totalEntries / pageSize) - 1;
      stepValue = parseFloat((90 / fetchCount).toFixed(2));

      for (let i = 0; i < fetchCount; i++) {
        let nextResponse = await axiosGetWithNext(nextLink);
        if (nextResponse.error) throw nextResponse.error;
        nextLink = nextResponse.data.nextLink;
        let mappedData = mapPOHistoryVariants(nextResponse.data.data);
        poArray = poArray.concat(mappedData);
        dispatch(updateStepperValue({ value: stepValue }));
      }
    } else {
      dispatch(updateStepperValue({ value: 90 }));
    }

    dispatch(resetStepperValue());
    dispatch(getPOReportSuccess({ pos: poArray }));
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(
      setError({
        error: err.toString(),
        source: "Purchase Order History Reports",
      })
    );
    dispatch(resetStepperValue());
  }
};
