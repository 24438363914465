/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import { useDispatch, useSelector } from "react-redux";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";

import PropTypes from "prop-types";

import {
  clearItemSelection,
  updateItemSelection,
} from "../../redux/slices/items/itemSlice";
import { formatMoney } from "../../utility/utilityFunctions";
import { BeaconIcon } from "../StyledComponents";
import ImageWrapper from "../Utility/ImageWrapper";

const headCells = [
  { id: "preview", label: "Preview" },
  { id: "itemNumber", label: "Sequence #" },
  { id: "brand", label: "Brand" },
  { id: "program", label: "Program" },
  { id: "itemType", label: "Item Type" },
  { id: "itemDescription", label: "Item Desc." },
  { id: "packSize", label: "Pack Size" },
  { id: "stock", label: "On Hand" },
  { id: "estCost", label: "Est. Cost" },
  { id: "orderType", label: "Order Status" },
  { id: "addItem", label: "Add" },
];

const EnhancedTableHead = (props) => {
  const {
    classes,
    rowCount,
    onSelectAllClick,
    numSelected,
    type,
    forProgram,
    role,
  } = props;

  const currentHeadCells =
    type === "inventory"
      ? headCells.filter((cell) => cell.id !== "addItem")
      : !forProgram
      ? headCells.filter((cell) => cell.id !== "stock" && cell.id !== "addItem")
      : role !== "read-only" && role !== "compliance"
      ? headCells.filter((cell) => cell.id !== "stock")
      : headCells.filter(
          (cell) => cell.id !== "stock" && cell.id !== "addItem"
        );

  return (
    <TableHead css={{ th: tw`whitespace-nowrap` }}>
      <TableRow>
        <TableCell padding="checkbox" align="center">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all items" }}
          />
          <div tw="m-1 -mt-2 font-normal text-neutral-500">Select all</div>
        </TableCell>
        {currentHeadCells.map((headCell) => (
          <TableCell
            className={classes.headerText}
            key={headCell.id}
            align="left"
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  ...theme.global,
}));

const ItemCatalogTable = ({
  currentItems,
  handlePreview,
  isItemsLoading,
  catalogType,
  scrollRef,
  addPreOrderVariant,
  isAdHoc,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const selectedItems = useSelector((state) => state.items.selectedItems);
  const preOrderVariants = useSelector(
    (state) => state.programs.currentPreOrderVariants
  );
  const adHocVariants = useSelector(
    (state) => state.adHocPrograms.currentAdHocVariants
  );
  const isPreOrderLoading = useSelector((state) => state.orderSet.isLoading);
  const patchLoading = useSelector((state) => state.patchOrder.isLoading);
  const currentUserRole = useSelector((state) => state.user.role);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = currentItems.map((item) => item.id);
      dispatch(updateItemSelection({ selectedItems: newSelecteds }));
      return;
    }
    dispatch(clearItemSelection({ selectedItems: [] }));
  };

  const handleClick = (_event, id) => {
    const selectedIndex = selectedItems.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedItems, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedItems.slice(1));
    } else if (selectedIndex === selectedItems.length - 1) {
      newSelected = newSelected.concat(selectedItems.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedItems.slice(0, selectedIndex),
        selectedItems.slice(selectedIndex + 1)
      );
    }

    dispatch(updateItemSelection({ selectedItems: newSelected }));
  };

  const isSelected = (id) => selectedItems.indexOf(id) !== -1;

  return (
    <>
      <TableContainer
        className={classes.tableContainer}
        style={{ maxHeight: "Calc(100vh - 250px)" }}
        ref={scrollRef}
      >
        <Table className={classes.table} aria-label="item-catalog" stickyHeader>
          <EnhancedTableHead
            classes={classes}
            numSelected={selectedItems.length}
            onSelectAllClick={handleSelectAllClick}
            rowCount={currentItems.length}
            type={catalogType}
            forProgram={addPreOrderVariant ? true : false}
            role={currentUserRole}
          />
          <TableBody>
            {!isItemsLoading && currentItems.length === 0 && (
              <TableRow>
                <TableCell align="left" colSpan={9}>
                  <Typography className={classes.headerText}>
                    {`There are no items that match the current search criteria..`}
                  </Typography>
                </TableCell>
              </TableRow>
            )}
            {!isItemsLoading &&
              !isPreOrderLoading &&
              currentItems.length > 0 &&
              currentItems.map((item, index) => {
                const isItemSelected = isSelected(item.id);
                const labelId = `item-Checkbox-${index}`;
                return (
                  <TableRow key={item.id} hover>
                    <TableCell padding="checkbox" align="center">
                      <Checkbox
                        checked={isItemSelected}
                        inputProps={{ "aria-labelledby": labelId }}
                        onClick={(event) => event.stopPropagation()}
                        onChange={(event) => {
                          handleClick(event, item.id);
                          event.stopPropagation();
                        }}
                      />
                    </TableCell>
                    <TableCell align="left">
                      <div tw="relative">
                        <ImageWrapper
                          id={item.itemNumber}
                          imgClass={classes.previewImageFloat}
                          alt={item.itemType}
                          imgUrl={item.imgUrlThumb}
                          handleClick={() => handlePreview(item.itemNumber)}
                        />
                        {item.includeBeacon && (
                          <BeaconIcon
                            tw="absolute -right-1 -bottom-1"
                            title="Item has a beacon"
                          />
                        )}
                      </div>
                    </TableCell>
                    <TableCell align="left">{item.itemNumber}</TableCell>
                    {item.brand !== "---" && item.brand.length > 1 ? (
                      <Tooltip
                        placement="left"
                        title={`${item.brand.join(", ")}`}
                      >
                        <TableCell align="left">
                          {item.brand[0]}
                          <MoreHorizIcon
                            fontSize="small"
                            color="inherit"
                            style={{ float: "right" }}
                          />
                        </TableCell>
                      </Tooltip>
                    ) : (
                      <TableCell align="left">
                        {item.brand !== "---" ? item.brand[0] : "---"}
                      </TableCell>
                    )}
                    {item.program !== "---" && item.program.length > 1 ? (
                      <Tooltip
                        placement="left"
                        title={`${item.program.join(", ")}`}
                      >
                        <TableCell align="left">
                          {item.program[0]}
                          <MoreHorizIcon
                            fontSize="small"
                            color="inherit"
                            style={{ float: "right" }}
                          />
                        </TableCell>
                      </Tooltip>
                    ) : (
                      <TableCell align="left">
                        {item.program !== "---" ? item.program[0] : "---"}
                      </TableCell>
                    )}
                    <TableCell align="left">{item.itemType}</TableCell>
                    <TableCell align="left">{item.itemDescription}</TableCell>
                    <TableCell align="left">{item.packSize}</TableCell>
                    {catalogType === "inventory" && (
                      <TableCell align="left">
                        {item.stock ? item.stock : "---"}
                      </TableCell>
                    )}
                    <TableCell>{`${formatMoney(
                      item.estCost,
                      false
                    )}`}</TableCell>
                    <TableCell align="left">{item.orderType}</TableCell>
                    {!patchLoading &&
                      addPreOrderVariant &&
                      currentUserRole !== "read-only" && (
                        <TableCell padding="checkbox" align="center">
                          <Tooltip title="Add to Pre Order">
                            <span>
                              <IconButton
                                onClick={() =>
                                  addPreOrderVariant(
                                    item.id,
                                    item.variants,
                                    isAdHoc ? adHocVariants : preOrderVariants
                                  )
                                }
                                disabled={
                                  isPreOrderLoading ||
                                  (!isAdHoc &&
                                    preOrderVariants.filter(
                                      (v) => v.itemId === item.id
                                    ).length ===
                                      (item.variants.length === 1
                                        ? item.variants.length
                                        : item.variants.length - 1)) ||
                                  (isAdHoc &&
                                    adHocVariants.filter(
                                      (v) => v.itemId === item.id
                                    ).length ===
                                      (item.variants.length === 1
                                        ? item.variants.length
                                        : item.variants.length - 1))
                                }
                                size="large"
                              >
                                <AddCircleIcon />
                              </IconButton>
                            </span>
                          </Tooltip>
                        </TableCell>
                      )}
                    {patchLoading && addPreOrderVariant && (
                      <TableCell padding="checkbox" align="center">
                        <CircularProgress size={25} />
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
            {(isItemsLoading || isPreOrderLoading) && (
              <TableRow>
                <TableCell align="left" colSpan={9}>
                  <CircularProgress />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

ItemCatalogTable.propTypes = {
  currentItems: PropTypes.array,
  handlePreview: PropTypes.func.isRequired,
  isItemsLoading: PropTypes.bool.isRequired,
  isAdHoc: PropTypes.bool,
};

export default ItemCatalogTable;
