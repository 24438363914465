import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import PropTypes from "prop-types";

import CollapsibleTable from "../CollapsibleTable";

const itemSubTypeHeadCells = [
  { id: "blank", label: "" },
  { id: "label", label: "Label" },
  { id: "estimatedCost", label: "Estimated Cost" },
  { id: "moq", label: "MOQ" },
  { id: "leadTimeInDays", label: "Lead Time (Days)" },
  { id: "complexityScore", label: "Complexity Score" },
];

const useStyles = makeStyles((theme) => ({
  ...theme.global,
  clickableRow: {
    "&&:hover": {
      cursor: "pointer",
    },
  },
}));

const ItemTypeTable = ({
  handleItemTypeClick,
  handleItemSubTypeClick,
  itemTypes,
  isLoading,
}) => {
  const classes = useStyles();

  return !isLoading && itemTypes.length > 0 ? (
    <CollapsibleTable
      unmountOnExit={false}
      headers={[
        { cell: "Item Type Code" },
        { cell: "Category Code" },
        { cell: "Description" },
        { cell: "Pack Size" },
        { cell: "Lead Time In Days" },
        { cell: "Spec Type" },
        { cell: "POS Type" },
      ]}
      rows={itemTypes.map(({ openItemRow, ...item }) => ({
        key: item.id,
        row: [
          { cell: <b>#{item.externalId ? item.externalId : "N/A"}</b> },
          { cell: item.categoryCode ? item.categoryCode : "N/A" },
          { cell: item.name ? item.name : "N/A" },
          { cell: item.packSize ? item.packSize : "N/A" },
          { cell: item.leadTime ? item.leadTime : "N/A" },
          { cell: item.specType ? item.specType : "N/A" },
          { cell: item.posType ? item.posType : "N/A" },
        ],
        content: (
          <Table>
            <TableHead>
              <TableRow>
                {itemSubTypeHeadCells.map((headCell) => {
                  return (
                    <TableCell key={headCell.id} align="left">
                      {headCell.label}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {item.itemSubTypes.map((subType) => (
                <TableRow
                  key={subType.id}
                  hover
                  onClick={() => {
                    handleItemSubTypeClick(subType);
                  }}
                >
                  <TableCell />
                  <TableCell align="left">
                    {subType.label ? subType.label : "N/A"}
                  </TableCell>
                  <TableCell align="left">
                    {subType.estimatedCost ? subType.estimatedCost : "N/A"}
                  </TableCell>
                  <TableCell align="left">
                    {subType.moq ? subType.moq : "N/A"}
                  </TableCell>
                  <TableCell align="left">
                    {subType.leadTimeInDays ? subType.leadTimeInDays : "N/A"}
                  </TableCell>
                  <TableCell align="left">
                    {subType.complexityScore ? subType.complexityScore : "N/A"}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ),
        expanded: openItemRow,
        onClickRow: () => {
          handleItemTypeClick(item.id);
        },
      }))}
    />
  ) : (
    <Table>
      <TableBody>
        <TableRow>
          <TableCell align="left" colSpan={5}>
            <Typography className={classes.headerText}>
              {`There are currently no item types that match your search..`}
            </Typography>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

ItemTypeTable.propTypes = {
  handleItemTypeClick: PropTypes.func,
  itemTypes: PropTypes.array,
  isLoading: PropTypes.bool.isRequired,
};

export default ItemTypeTable;
