import { useCallback, useEffect, useRef, useState } from "react";
import Helmet from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import GetAppIcon from "@mui/icons-material/GetApp";
import PrintIcon from "@mui/icons-material/Print";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";

import PropTypes from "prop-types";

import { CSVLink } from "@utils/csv";

import PendingComplianceTable from "../components/Compliance/PendingComplianceTable";
import Loading from "../components/Utility/Loading";
import ConfirmComplianceCancelModal from "../components/Utility/Modals/ConfirmComplianceCancelModal";
import OrderPatchLoading from "../components/Utility/OrderPatchLoading";
import { fetchTriggeredRulesByOrders } from "../redux/slices/compliance/complianceItemsSlice";
import { setFiltersOpen } from "../redux/slices/filterSlice";
import { complianceCancelOrderVariants } from "../redux/slices/patchOrderSlice";

/*
While creating purchase orders, users can see if there are items that are pending compliance approval
before creating the purchase order. This view gives a detailed list of order variants that are pending
compliance, and allows the user to cancel them if necessary.
*/

const useStyles = makeStyles((theme) => ({
  ...theme.global,
}));

const PendingCompliance = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { orderIds } = useParams();

  const tableRef = useRef(null);

  const [confirmOpen, setConfirmOpen] = useState(false);
  const [currentCSV, setCurrentCSV] = useState({ data: [], headers: [] });

  const currentOrderVariants = useSelector(
    (state) => state.complianceItems.pendingOrderVariants
  );
  const currentSelectedItems = useSelector(
    (state) => state.complianceItems.selectedItems
  );
  const currentUserRole = useSelector((state) => state.user.role);
  const isLoading = useSelector((state) => state.complianceItems.isLoading);

  const handlePrint = useReactToPrint({
    content: () => tableRef.current,
  });

  const handleOpenConfirm = useCallback(() => {
    setConfirmOpen(true);
  }, [setConfirmOpen]);

  const handleCloseConfirm = useCallback(() => {
    setConfirmOpen(false);
  }, [setConfirmOpen]);

  const handleCancelOrders = useCallback(
    (note) => {
      dispatch(complianceCancelOrderVariants(currentSelectedItems, note));
      setConfirmOpen(false);
    },
    [setConfirmOpen, currentSelectedItems, dispatch]
  );

  useEffect(() => {
    if (
      currentOrderVariants.length > 0 &&
      currentCSV.data.length === 0 &&
      orderIds.split(",").length === currentOrderVariants.length
    ) {
      let csvHeaders = [
        { label: "State", key: "state" },
        { label: "Person", key: "user" },
        { label: "Distributor / Name", key: "addressName" },
        { label: "Total Items", key: "totalQty" },
        { label: "Rule", key: "rule" },
      ];
      let csvData = [];
      currentOrderVariants.forEach((item) => {
        const combinedRules = [
          ...item.triggeredRules,
          ...item.triggeredPriorApprovalRules,
        ];
        csvData.push({
          state: item.state,
          user: item.user,
          addressName: item.addressName,
          totalQty:
            item.isComplianceCanceled || item.isCanceled
              ? "Canceled"
              : item.totalQty,
          rule:
            !item["overrides-compliance"] && combinedRules.length > 0
              ? combinedRules.map((ruleObj) => ruleObj.description).join(", ")
              : "---",
        });
      });
      setCurrentCSV({ data: csvData, headers: csvHeaders });
    }
  }, [currentOrderVariants, currentCSV.data, orderIds]);

  useEffect(() => {
    if (currentUserRole.length > 0) {
      dispatch(fetchTriggeredRulesByOrders(orderIds.split(",")));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(setFiltersOpen({ open: false }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <Helmet>
        <title>RTA | Pending complianceCancelOrderVariants</title>
      </Helmet>
      <ConfirmComplianceCancelModal
        open={confirmOpen}
        handleClose={handleCloseConfirm}
        handleCancel={handleCancelOrders}
      />
      <Container className={classes.mainWrapper}>
        <div className={classes.titleBar}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Tooltip title="Back to PO Rollup" placement="bottom-start">
              <IconButton
                component={Link}
                to="/purchasing/poRollup"
                size="large"
              >
                <ArrowBackIcon fontSize="large" color="secondary" />
              </IconButton>
            </Tooltip>
            <Typography
              className={classes.titleText}
              style={{ marginTop: "5px" }}
            >
              {`Pending Compliance for Order Item ${window.location.hash}`}
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              width: "350px",
              height: "48px",
              justifyContent: "flex-end",
            }}
          >
            <Button
              className={classes.largeButton}
              variant="contained"
              color="secondary"
              disabled={currentSelectedItems.length === 0}
              style={{ marginRight: "20px" }}
              onClick={() => {
                handleOpenConfirm();
              }}
            >
              CANCEL ORDER
            </Button>
            <Tooltip title="Print Pending Compliance Items">
              <IconButton onClick={handlePrint} size="large">
                <PrintIcon color="secondary" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Export File">
              <IconButton size="large">
                <CSVLink data={currentCSV.data} headers={currentCSV.headers}>
                  <GetAppIcon color="secondary" />
                </CSVLink>
              </IconButton>
            </Tooltip>
          </div>
        </div>
        <br />
        <br />
        {currentOrderVariants.length > 0 && (
          <PendingComplianceTable
            items={currentOrderVariants}
            itemsLoading={isLoading}
            tableRef={tableRef}
          />
        )}
        <br />
      </Container>
      <OrderPatchLoading />
    </>
  );
};

PendingCompliance.propTypes = {
  itemId: PropTypes.string,
};

export default PendingCompliance;
