/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { ArrowBackIosSharp, ExitToApp } from "@mui/icons-material";
import { Chip, IconButton } from "@mui/material";

import { Contained, StyledButton } from "src/components/StyledComponents";
import { setFiltersOpen } from "src/redux/slices/filterSlice";
import { setIsOrdering } from "src/redux/slices/ordering/orderSetSlice";
import {
  updateCurrentChannel,
  updateCurrentTerritory,
} from "src/redux/slices/users/userSlice";

import { getAnaplanProgramsLabel } from "@features/anaplanPrograms/helpers";
import { OrderSetActionBar, OrderSetOverview } from "@features/orders";
import ExportOrderSetButton from "@features/orders/orderSet/ExportOrderSetButton";
import OrderSetApproveDenyBar from "@features/orders/orderSet/OrderSetApproveDenyBar";
import OrderSetTable from "@features/orders/orderSet/OrderSetTable";
import {
  CurrentOrderSetContextProvider,
  useCurrentOrderSet,
} from "@features/orders/orderSet/data/CurrentOrderSetContext";
import { OrderSetBudgetImpactAccordion } from "@features/orders/orderSetOverview/OrderSetBudgetImpact";
import { OrderSet } from "@models/OrderSet";

const OrderPageContent = () => {
  const dispatch = useDispatch();
  const { orderSet } = useCurrentOrderSet();
  const { hash } = useLocation();
  const { currentChannel, currentTerritory } = useSelector(
    (state: any) => state.user
  );

  useEffect(() => {
    dispatch(updateCurrentChannel({ channel: orderSet.channel }));
    if (orderSet.territory && orderSet.territory.id !== currentTerritory) {
      dispatch(updateCurrentTerritory({ territory: orderSet.territory.id }));
    }
  }, [dispatch, currentChannel, currentTerritory, orderSet]);

  const addItemsUrl = `/orders/items/${
    orderSet.programType === "National Coupon"
      ? "national-coupons"
      : orderSet.type === "from-inventory"
      ? "inventory"
      : "onDemand"
  }`;

  const backButtonUrl =
    {
      "#overview": "#",
      "#approval": "/orders/approvals",
      "#rollup": "/rollup",
      "#rollup-overview": "#rollup",
    }[hash] ?? addItemsUrl;

  return (
    <div tw="bg-neutral-100 min-h-[calc(100vh - var(--header-height))]">
      <Contained tw="py-6 space-y-6">
        <div tw="flex justify-between items-baseline">
          <div tw="flex gap-2 items-center">
            <IconButton component={Link} to={backButtonUrl}>
              <ArrowBackIosSharp tw="text-neutral-600" />
            </IconButton>
            <h1 tw="text-neutral-600 text-2xl font-medium">
              {
                {
                  "on-demand": "Current On Demand Order",
                  "pre-order": "Current Pre-Order",
                  "from-inventory": "Current Inventory Order",
                }[orderSet.type]
              }

              <Chip
                tw="ml-3"
                label={
                  orderSet.anaplanPrograms.length > 0
                    ? getAnaplanProgramsLabel(orderSet.anaplanPrograms)
                    : orderSet.programName ?? "Miscellaneous Program"
                }
                {...(orderSet.program && {
                  component: Link,
                  to: `/planning/program/${orderSet.program.id}`,
                  target: "_blank",
                })}
              />
            </h1>
          </div>
          <div tw="flex items-center gap-2">
            {["inactive", "in-progress"].includes(orderSet.status) && (
              <StyledButton
                component={Link}
                outlined
                to={addItemsUrl}
                startIcon={<ExitToApp tw="rotate-180" />}
              >
                Add Items
              </StyledButton>
            )}
            <ExportOrderSetButton />
          </div>
        </div>
        {hash === "#overview" ? (
          <OrderSetOverview />
        ) : (
          <>
            <OrderSetBudgetImpactAccordion />
            <OrderSetTable />
            {orderSet.type !== "pre-order" &&
            ["#approval", "#rollup"].includes(hash) ? (
              <OrderSetApproveDenyBar backUrl={backButtonUrl} />
            ) : (
              <OrderSetActionBar />
            )}
          </>
        )}
      </Contained>
    </div>
  );
};

const Order = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onOrderSetDelete = (set: OrderSet) => {
    const addItemsUrl = `/orders/items/${
      set.type === "from-inventory" ? "inventory" : "onDemand"
    }`;

    navigate(set.type === "pre-order" ? "/rollup" : addItemsUrl);
  };

  useEffect(() => {
    dispatch(setFiltersOpen({ open: false }));
    dispatch(setIsOrdering({ status: true }));
    return () => {
      dispatch(setIsOrdering({ status: false }));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CurrentOrderSetContextProvider onOrderSetDelete={onOrderSetDelete}>
      <OrderPageContent />
    </CurrentOrderSetContextProvider>
  );
};

export default Order;
