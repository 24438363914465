import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import GetAppIcon from "@mui/icons-material/GetApp";
import {
  Button,
  FormControlLabel,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { CSVLink } from "@utils/csv";

import { useDetailedInput } from "../../hooks/InputHooks";
import {
  fetchSuppliersByName,
  setAllSuppliersList,
} from "../../redux/slices/suppliers/supplierSlice";
import Loading from "../Utility/Loading";
import SupplierModal from "./SupplierModal";
import SupplierTable from "./SupplierTable";

const csvHeaders = [
  { label: "Supplier Id", key: "id" },
  { label: "Name", key: "name" },
  { label: "Supplier Code", key: "externalId" },
  { label: "Goes to RFP", key: "goesToRFP" },
  { label: "Is Select", key: "isPurchaserSelect" },
  { label: "Assigned Users", key: "assignedUsers" },
];

const useStyles = makeStyles((theme) => ({
  ...theme.global,
  inputField: {
    marginBottom: "15px",
    width: "48%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
}));

const Suppliers = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const debounce = useRef(null);

  const [modal, handleModal] = useState(false);
  const [modalType, setModalType] = useState("edit");
  const [currentSupplierId, setCurrentSupplierId] = useState(null);
  const [isActive, setIsActive] = useState(true);

  const { supplierList, filteredSupplierList, isLoading } = useSelector(
    (state) => state.suppliers
  );

  const handleModalClose = () => {
    handleModal(false);
    setCurrentSupplierId(null);
  };

  const handleModalOpen = (id, type) => {
    setCurrentSupplierId(id);
    setModalType(type);
    handleModal(true);
  };

  const handleSupplierSearch = (value, _type, _filter) => {
    clearTimeout(debounce.current);

    debounce.current = setTimeout(() => {
      dispatch(fetchSuppliersByName(value, isActive));
    }, 250);
  };

  const { value: search, bind: bindSearch } = useDetailedInput(
    "",
    handleSupplierSearch
  );

  useEffect(() => {
    dispatch(setAllSuppliersList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (supplierList.length === 0) {
    return <Loading partial={true} />;
  }

  return (
    <>
      {modal && (
        <SupplierModal
          open={modal}
          handleClose={handleModalClose}
          type={modalType}
          id={currentSupplierId}
          supplierList={filteredSupplierList}
        />
      )}
      <div className={classes.titleBar}>
        <Typography className={classes.titleText}>Edit Suppliers</Typography>
        <div className={classes.innerConfigDiv}>
          {supplierList.length === 0 && (
            <Button
              className={classes.largeButton}
              style={{ marginRight: "10px", minWidth: "242px" }}
              variant="contained"
              disabled={true}
            >
              EXPORT SUPPLIER REPORT
            </Button>
          )}
          {supplierList.length > 0 && (
            <CSVLink
              data={supplierList.map((sup) => ({
                ...sup,
                isPurchaserSelect: sup.isPurchaserSelect ? "Yes" : "No",
                assignedUsers: sup.assignedUsers
                  .map((user) => user.name)
                  .join(", "),
              }))}
              headers={csvHeaders}
              filename="rta_suppliers.csv"
            >
              <Button
                className={classes.largeButton}
                variant="contained"
                color="secondary"
                style={{ marginRight: "10px" }}
                startIcon={<GetAppIcon />}
              >
                EXPORT SUPPLIER REPORT
              </Button>
            </CSVLink>
          )}
          <Button
            className={classes.largeButton}
            variant="contained"
            onClick={() => handleModalOpen(null, "new")}
          >
            NEW SUPPLIER
          </Button>
        </div>
      </div>
      <br />
      <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
        <TextField
          size="small"
          className={classes.inputField}
          variant="outlined"
          color="secondary"
          name="search"
          type="text"
          label="Search Suppliers"
          value={search}
          {...bindSearch}
        />
        <div
          style={{
            width: "22%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            marginLeft: "3%",
          }}
        >
          <Typography className={classes.bodyText}>Status:</Typography>
          <FormControlLabel
            style={{ marginLeft: "5px" }}
            control={
              <Switch
                checked={isActive}
                onChange={() => {
                  dispatch(fetchSuppliersByName(search, !isActive));
                  setIsActive(!isActive);
                }}
                name="active-switch"
              />
            }
            label={isActive ? "Active" : "Inactive"}
          />
        </div>
      </div>
      <br />
      <br />
      <SupplierTable
        handleSupplierClick={handleModalOpen}
        suppliers={filteredSupplierList}
        isLoading={isLoading}
      />
    </>
  );
};

export default Suppliers;
