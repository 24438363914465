import React, { memo } from "react";

import { Button, Dialog, DialogContent, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
}));

const SuccessModal = ({ message, open, options, handleClose }) => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      disableScrollLock
      fullWidth
      maxWidth="sm"
      style={{ zIndex: "15000" }}
      keepMounted
    >
      <DialogContent>
        <br />
        <div className={classes.flexColumnModal}>
          <Typography className={classes.headerText}>{message}</Typography>
          <br />
          <div className={classes.fullWidthSpaceBetween}>
            {options.map((opt, i) => (
              <Button
                key={i}
                variant="contained"
                color="secondary"
                className={classes.largeButton}
                onClick={() => handleClose(opt.arg)}
              >
                {opt.label}
              </Button>
            ))}
          </div>
        </div>
        <br />
      </DialogContent>
    </Dialog>
  );
};

SuccessModal.propTypes = {
  message: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  options: PropTypes.array.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default memo(SuccessModal);
