import { useSelector } from "react-redux";

import { ResourceAutocomplete } from "@utils/forms";

const StateSelector = ({ state, setState, countryFilter, ...inputProps }) => {
  const currentTerritory = useSelector((state) => state.user.currentTerritory);
  return (
    <ResourceAutocomplete
      label={countryFilter === "CAN" ? "Province" : "State"}
      skipPagination
      resource="states"
      requestParams={{ territoryId: currentTerritory }}
      tapOptions={
        countryFilter
          ? (options) =>
              options.filter((state) => state.country === countryFilter)
          : undefined
      }
      getOptionText={(state) => state.code}
      isOptionEqualToValue={(option, value) => option.id === value}
      value={state?.id ?? ""}
      onChange={(_, val) => setState(val)}
      slotProps={{
        popper: { placement: "bottom-start" },
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "center",
        },
      }}
      size="medium"
      fullWidth
      {...inputProps}
    />
  );
};

export default StateSelector;
