import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import GetAppIcon from "@mui/icons-material/GetApp";
import {
  Button,
  CircularProgress,
  FormControlLabel,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { CSVLink } from "@utils/csv";

import { useDetailedInput } from "../../hooks/InputHooks";
import {
  clearAllBrands,
  clearBrands,
  fetchAssets,
  fetchBrandReport,
  fetchBrands,
  setTriggerCSVFalse,
} from "../../redux/slices/items/brandSlice";
import BrandModal from "./BrandModal";
import BrandTable from "./BrandTable";

const csvHeaders = [
  { label: "Brand Id", key: "id" },
  { label: "Name", key: "name" },
  { label: "Brand Code", key: "brandCode" },
  { label: "Brand Type Code", key: "buCode" },
  { label: "Brand Group Code", key: "productFamilyCode" },
];

const useStyles = makeStyles((theme) => ({
  ...theme.global,
  inputField: {
    marginBottom: "15px",
    width: "48%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
}));

const Brands = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const debounce = useRef(null);

  const [modal, handleModal] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [currentBrandId, setCurrentBrandId] = useState(null);

  const {
    brandList,
    isLoading,
    isAllBrandsLoading,
    allBrandList,
    triggerCSVDownload,
  } = useSelector((state) => state.brands);

  const handleModalClose = () => {
    handleModal(false);
    setCurrentBrandId(null);
  };

  const handleModalOpen = (id) => {
    setCurrentBrandId(id);
    handleModal(true);
  };

  const handleFetchAllBrands = () => {
    dispatch(fetchBrandReport());
  };

  const handleBrandSearch = (value, _type, _filter) => {
    clearTimeout(debounce.current);

    debounce.current = setTimeout(() => {
      dispatch(fetchBrands(value, isActive));
    }, 250);
  };

  const { value: search, bind: bindSearch } = useDetailedInput(
    "",
    handleBrandSearch
  );

  useEffect(() => {
    dispatch(fetchBrands());
    dispatch(fetchAssets());
    dispatch(clearAllBrands());
    dispatch(setTriggerCSVFalse());
    return () => dispatch(clearBrands());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {modal && (
        <BrandModal
          open={modal}
          handleClose={handleModalClose}
          id={currentBrandId}
          brandList={brandList}
        />
      )}
      <div className={classes.titleBar}>
        <Typography className={classes.titleText}>Edit Brands</Typography>
        <div className={classes.innerConfigDiv}>
          {!triggerCSVDownload && allBrandList.length === 0 && (
            <Button
              className={classes.largeButton}
              style={{ marginRight: "10px", minWidth: "242px" }}
              variant="contained"
              onClick={handleFetchAllBrands}
            >
              {isAllBrandsLoading ? (
                <CircularProgress size={28} />
              ) : (
                "GENERATE BRAND REPORT"
              )}
            </Button>
          )}
          {triggerCSVDownload && allBrandList.length > 0 && (
            <CSVLink
              data={allBrandList}
              headers={csvHeaders}
              filename="rta_brands.csv"
            >
              <Button
                className={classes.largeButton}
                variant="contained"
                color="secondary"
                style={{ marginRight: "10px" }}
                startIcon={<GetAppIcon />}
              >
                EXPORT BRAND REPORT
              </Button>
            </CSVLink>
          )}
        </div>
      </div>
      <br />
      <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
        <TextField
          size="small"
          className={classes.inputField}
          variant="outlined"
          color="secondary"
          name="search"
          type="text"
          label="Search Brands"
          value={search}
          {...bindSearch}
        />
        <div
          style={{
            width: "22%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            marginLeft: "3%",
          }}
        >
          <Typography className={classes.bodyText}>Status:</Typography>
          <FormControlLabel
            style={{ marginLeft: "5px" }}
            control={
              <Switch
                checked={isActive}
                onChange={() => {
                  dispatch(fetchBrands(search, !isActive));
                  setIsActive(!isActive);
                }}
                name="active-switch"
              />
            }
            label={isActive ? "Active" : "Inactive"}
          />
        </div>
      </div>
      <br />
      <BrandTable
        handleBrandClick={handleModalOpen}
        brands={brandList}
        isLoading={isLoading}
      />
    </>
  );
};

export default Brands;
