import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import WarningIcon from "@mui/icons-material/Warning";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";

import PropTypes from "prop-types";

import Tags from "@features/ui/Tags";

import { cancelMultipleOrderVariants } from "../../redux/slices/patchOrderSlice";
import { setItemRollupIdSelection } from "../../redux/slices/purchasing/purchaseOrderSlice";
import { setSelectedRFPItem } from "../../redux/slices/purchasing/rfpSlice";
import { formatMoney } from "../../utility/utilityFunctions";
import ImageWrapper from "../Utility/ImageWrapper";
import ConfirmCancelRollupItem from "../Utility/Modals/ConfirmCancelRollupItem";
import OrderPatchLoading from "../Utility/OrderPatchLoading";
import EditSupplierModal from "./EditSupplierModal";

const headCells = [
  { id: "preview", disablePadding: false, label: "Preview", sort: false },
  { id: "itemNumber", disablePadding: false, label: "Sequence #", sort: true },
  { id: "supplier", disablePadding: false, label: "Supplier", sort: false },
  { id: "user", disablePadding: false, label: "Ordered By", sort: false },
  { id: "territory", disablePadding: false, label: "Territory", sort: false },
  { id: "program", disablePadding: false, label: "Program", sort: true },
  { id: "brand", disablePadding: false, label: "Brand", sort: false },
  { id: "projectNum", disablePadding: false, label: "Project #", sort: false },
  { id: "itemType", disablePadding: false, label: "Item Type", sort: true },
  { id: "variantName", disablePadding: false, label: "Variant", sort: false },
  { id: "itemDesc", disablePadding: false, label: "Item Desc.", sort: true },
  {
    id: "totalQty",
    disablePadding: false,
    label: "Total Ordered",
    sort: false,
  },
  {
    id: "moq",
    disablePadding: false,
    label: "MOQ",
    sort: false,
  },
  {
    id: "qtyPendingCompliance",
    disablePadding: false,
    label: "Pending Compliance",
    sort: false,
  },
  { id: "estCost", disablePadding: false, label: "Est. Cost", sort: false },
  {
    id: "totalEstCost",
    disablePadding: false,
    label: "Est. Total",
    sort: false,
  },
  {
    id: "approvedDate",
    disablePadding: false,
    label: "Order Approval Date",
    sort: true,
  },
  {
    id: "inMarketDate",
    disablePadding: false,
    label: "In-Market Date",
    sort: true,
  },
];

const EnhancedTableHead = (props) => {
  const { classes, order, orderBy, onRequestSort, type } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const currentHeader =
    type === "po"
      ? headCells
      : headCells
          .filter((cell) => cell.id !== "supplier")
          .filter((cell) => cell.id !== "qtyPendingCompliance");

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox" />
        {currentHeader.map((headCell) => {
          if (!headCell.sort) {
            return (
              <TableCell
                className={classes.headerText}
                key={headCell.id}
                align="left"
              >
                {headCell.label}
              </TableCell>
            );
          } else {
            return (
              <TableCell
                className={classes.headerText}
                key={headCell.id}
                align="left"
                padding={headCell.disablePadding ? "none" : "normal"}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <span className={classes.visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </span>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            );
          }
        })}
        <TableCell align="right" />
        <TableCell align="right" />
      </TableRow>
    </TableHead>
  );
};

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};

const useStyles = makeStyles((theme) => ({
  ...theme.global,
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  clickableCell: {
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#737373",
      color: "white",
    },
  },
}));

const ItemRollupTable = ({
  items,
  handleSort,
  isItemsLoading,
  scrollRef,
  type,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("approvedDate");
  const [selected, setSelected] = useState([]);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [supplierEditOpen, setSupplierEditOpen] = useState(false);
  const [currentVariantId, setCurrentVariantId] = useState(null);
  const [currentIds, setCurrentIds] = useState(null);

  const currentFilters = useSelector((state) => state.filters);
  const itemRollupIdSelection = useSelector(
    (state) => state.purchaseOrder.itemRollupIdSelection
  );
  const selectedRFPItem = useSelector((state) => state.rfp.selectedRFPItem);

  const handleEditSupplier = (itemId) => {
    setCurrentVariantId(itemId);
    setSupplierEditOpen(true);
  };

  const handleEditSupplierClose = () => {
    setCurrentVariantId(null);
    setSupplierEditOpen(false);
  };

  const handleOpenConfirm = useCallback(
    (id) => {
      setCurrentIds(id);
      setConfirmOpen(true);
    },
    [setConfirmOpen, setCurrentIds]
  );

  const handleCancel = useCallback(
    (ids, note) => {
      dispatch(cancelMultipleOrderVariants(ids, currentFilters, type, note));
      setConfirmOpen(false);
    },
    [setConfirmOpen, currentFilters, type, dispatch]
  );

  const handleCloseConfirm = useCallback(() => {
    setConfirmOpen(false);
  }, [setConfirmOpen]);

  const handleRequestSort = (_event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    handleSort({ order: isAsc ? "desc" : "asc", orderBy: property });
  };

  const handleClick = (_event, id, variantId, itemId) => {
    const selectedIndex = selected.indexOf(`${id}-${variantId}-${itemId}`);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(
        selected,
        `${id}-${variantId}-${itemId}`
      );
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    if (type === "rfp") {
      if (newSelected.length === 0) {
        dispatch(setSelectedRFPItem({ itemId: null }));
      } else {
        dispatch(setSelectedRFPItem({ itemId: newSelected[0].split("-")[2] }));
      }
    }

    if (type === "po") {
      dispatch(setItemRollupIdSelection({ selectedItems: newSelected }));
    }

    setSelected(newSelected);
  };

  const isSelected = (id, variantId, itemId) =>
    selected.indexOf(`${id}-${variantId}-${itemId}`) !== -1;

  const handleComplianceClick = (itemNumber, orderIds) => {
    navigate(`/compliance/pending/${orderIds.join(",")}#${itemNumber}`);
  };

  useEffect(() => {
    if (
      type === "po" &&
      selected.length > 0 &&
      itemRollupIdSelection.length === 0
    ) {
      setSelected([]);
    }
  }, [type, selected, itemRollupIdSelection]);

  useEffect(() => {
    if (type === "rfp" && selected.length > 0 && !selectedRFPItem) {
      setSelected([]);
    }
  }, [type, selected, selectedRFPItem]);

  return (
    <>
      {confirmOpen && (
        <ConfirmCancelRollupItem
          open={confirmOpen}
          handleClose={handleCloseConfirm}
          handleCancel={handleCancel}
          itemIds={currentIds}
          type={type}
        />
      )}
      {supplierEditOpen && (
        <EditSupplierModal
          itemId={currentVariantId}
          modalOpen={supplierEditOpen}
          handleClose={handleEditSupplierClose}
          type="item"
        />
      )}
      <TableContainer
        className={classes.tableContainer}
        style={{ maxHeight: "Calc(100vh - 250px)" }}
        ref={scrollRef}
      >
        <Table
          stickyHeader
          className={classes.table}
          style={{ minWidth: "1325px" }}
        >
          <EnhancedTableHead
            classes={classes}
            onRequestSort={handleRequestSort}
            order={order}
            orderBy={orderBy}
            type={type}
          />
          <TableBody>
            {!isItemsLoading && items.length === 0 && (
              <TableRow>
                <TableCell align="left" colSpan={11}>
                  <Typography className={classes.headerText}>
                    {`There are no items that match the current search criteria..`}
                  </Typography>
                </TableCell>
              </TableRow>
            )}
            {!isItemsLoading &&
              items.length > 0 &&
              items.map((row, index) => {
                const isItemSelected = isSelected(
                  row.id,
                  row.variantId,
                  row.itemId
                );
                const labelId = `po-rollup-Checkbox-${index}`;
                return (
                  <TableRow key={index} hover>
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isItemSelected}
                        inputProps={{ "aria-labelledby": labelId }}
                        onClick={(event) => event.stopPropagation()}
                        disabled={
                          (type === "rfp" &&
                            selected.length >= 1 &&
                            selected[0] !==
                              `${row.id}-${row.variantId}-${row.itemId}`) ||
                          (type === "po" &&
                            !isItemSelected &&
                            selected.length >= 1 &&
                            !selected
                              .map((item) => +item.split("-")[2])
                              .includes(row.itemId)) ||
                          isNaN(row.totalEstCost)
                        }
                        onChange={(event) => {
                          handleClick(event, row.id, row.variantId, row.itemId);
                          event.stopPropagation();
                        }}
                      />
                    </TableCell>
                    <TableCell align="left">
                      <ImageWrapper
                        id={row.itemNumber}
                        imgClass={classes.previewImg}
                        alt={row.itemType}
                        imgUrl={row.imgUrl}
                      />
                    </TableCell>
                    <TableCell align="left">
                      {row.isRush ? (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Tooltip title="Order On Rush Status">
                            <WarningIcon
                              fontSize="small"
                              style={{ margin: "0 5px 0 0" }}
                            />
                          </Tooltip>
                          {row.itemNumber}
                        </div>
                      ) : (
                        row.itemNumber
                      )}
                      {row.hasToInventoryOrders && <Tags.Restock />}
                    </TableCell>
                    {type === "po" && (
                      <TableCell align="left">{row.supplier}</TableCell>
                    )}
                    <TableCell align="left">{row.user}</TableCell>
                    {row.territory.length > 1 ? (
                      <Tooltip
                        placement="left"
                        title={`${row.territory.join(", ")}`}
                      >
                        <TableCell align="left">
                          {row.territory[0]}
                          <MoreHorizIcon
                            fontSize="small"
                            color="inherit"
                            style={{ float: "right" }}
                          />
                        </TableCell>
                      </Tooltip>
                    ) : (
                      <TableCell align="left">{row.territory[0]}</TableCell>
                    )}
                    <TableCell align="left">{row.program}</TableCell>
                    <TableCell align="left">{row.brand}</TableCell>
                    <TableCell align="left">{row.projectNum}</TableCell>
                    <TableCell align="left">{row.itemType}</TableCell>
                    <TableCell align="left">
                      {row.variantName ?? "---"}
                    </TableCell>
                    <TableCell align="left">{row.itemDescription}</TableCell>
                    <TableCell align="left">{row.totalQty}</TableCell>
                    <TableCell align="left">{row.moq}</TableCell>
                    {type === "po" && (
                      <TableCell
                        align="left"
                        className={
                          row.qtyPendingCompliance > 0
                            ? classes.clickableCell
                            : null
                        }
                        onClick={() => {
                          if (row.qtyPendingCompliance > 0) {
                            handleComplianceClick(
                              row.itemNumber,
                              row.orderVariantIds
                            );
                          }
                        }}
                      >
                        {row.qtyPendingCompliance}
                      </TableCell>
                    )}
                    <TableCell align="left">
                      {formatMoney(row.estCost, true)}
                    </TableCell>
                    <TableCell align="left">
                      {formatMoney(row.totalEstCost, true)}
                    </TableCell>
                    <TableCell align="left">{row.approvedDate}</TableCell>
                    <TableCell align="left">{row.inMarketDate}</TableCell>
                    <TableCell align="right" padding="checkbox">
                      <Tooltip title="Edit Item Supplier">
                        <IconButton
                          onClick={() => handleEditSupplier(row.itemId)}
                          size="large"
                        >
                          <EditIcon color="inherit" />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                    <TableCell align="right" padding="checkbox">
                      <Tooltip
                        title={`Cancel ${
                          type === "po" ? "Purchase Order Item" : "RFP Item"
                        }`}
                      >
                        <IconButton
                          onClick={() => handleOpenConfirm(row.orderVariantIds)}
                          size="large"
                        >
                          <DeleteIcon color="inherit" />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                );
              })}
            {isItemsLoading && (
              <TableRow>
                <TableCell align="left" colSpan={9}>
                  <CircularProgress />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <OrderPatchLoading />
    </>
  );
};

ItemRollupTable.propTypes = {
  items: PropTypes.array,
  handleSort: PropTypes.func.isRequired,
  isItemsLoading: PropTypes.bool.isRequired,
  scrollRef: PropTypes.any,
  itemSelected: PropTypes.bool,
  type: PropTypes.string.isRequired,
};

export default ItemRollupTable;
