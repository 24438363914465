export const buildAddressQuery = (
  search,
  territoryId,
  stateIds,
  type,
  isActive
) => {
  let queryString = "/api/addresses";
  let territoryString = territoryId
    ? `filter[territory-id]=${territoryId}`
    : "";
  let stateString =
    stateIds && stateIds.length > 0 ? `filter[state-ids]=${stateIds}` : "";
  let searchString =
    search && search.length > 0 ? `filter[search]=${search}` : "";
  let typeString = type && type.length > 0 ? `filter[type]=${type}` : "";
  let activeString = `filter[is-active]=${isActive}`;

  let filteredStrings = [
    territoryString,
    stateString,
    searchString,
    typeString,
    activeString,
  ]
    .filter((s) => s.length > 0)
    .join("&");
  if (filteredStrings.length > 0) {
    queryString = queryString + "?" + filteredStrings;
  }

  return queryString;
};

/*
Addresses can be of 4 types in RTA: custom, distributor, warehouse, change-request.  The change-request
is the only of these that doesn't represent an actual address, but a request to change an address.
It is created as an address though, because it has all the same fields that an address normally would,
and only adds a couple more fields.

Each type has its own limitations on how users can create and edit them. Custom addresses are free
game for the most part, as long as it was created in a territory the user is assigned to, they can edit
it freely. Distributor type addresses can only be edited through change requests, that super users and
purchaser users can then approve or deny. Warehouse addresses are manually added, and are not editable.
*/
export const buildAddress = (attrs) => {
  let attributes = {
    name: attrs.name,
    "default-attn": attrs.attn,
    "street-address-1": attrs.addressOne,
    "street-address-2": attrs.addressTwo,
    city: attrs.city,
    zip: attrs.zip,
    country: attrs.country,
    "phone-number": attrs.phoneNumber,
    type: attrs.type,
    "is-active": attrs.isActive,
    "on-premise-email-addresses": attrs.onPremiseEmailAddresses,
    "retail-email-addresses": attrs.retailEmailAddresses,
  };

  if (attrs.type === "change-request") {
    attributes["abn"] = attrs.abn;
    attributes["change-note"] = attrs.changeNote;
    attributes["change-effective-date"] = attrs.changeEffectiveDate;
    attributes["address-to-change-id"] = +attrs.addressToChangeId;
  }

  if (attrs.type === "distributor") {
    attributes["abn"] = attrs.abn;
  }

  return {
    data: {
      type: "address",
      attributes: {
        ...attributes,
      },
      relationships: {
        state: {
          data: attrs.state
            ? {
                type: "state",
                id: +attrs.state,
              }
            : null,
        },
        "sub-state": {
          data: attrs.substate
            ? {
                type: "sub-state",
                id: +attrs.substate,
              }
            : null,
        },
        territory: {
          data: attrs.territoryId
            ? {
                type: "territory",
                id: +attrs.territoryId,
              }
            : null,
        },
      },
    },
  };
};

export const buildAddressPatch = (attrs) => {
  let attributes = {
    name: attrs.name,
    "default-attn": attrs.attn,
    "street-address-1": attrs.addressOne,
    "street-address-2": attrs.addressTwo,
    city: attrs.city,
    zip: attrs.zip,
    country: attrs.country,
    "phone-number": attrs.phoneNumber,
    type: attrs.type,
    "is-active": attrs.isActive,
    "on-premise-email-addresses": attrs.onPremiseEmailAddresses,
    "retail-email-addresses": attrs.retailEmailAddresses,
    "deactivation-note": attrs.deactivationNote ?? null,
  };

  if (attrs.type === "distributor") {
    attributes["abn"] = attrs.abn;
  }

  return {
    data: {
      type: "address",
      attributes: { ...attributes },
      relationships: {
        state: {
          data: attrs.state
            ? {
                type: "state",
                id: +attrs.state,
              }
            : null,
        },
        "sub-state": {
          data: attrs.substate
            ? {
                type: "sub-state",
                id: +attrs.substate,
              }
            : null,
        },
        territory: {
          data: attrs.territoryId
            ? {
                type: "territory",
                id: +attrs.territoryId,
              }
            : null,
        },
      },
    },
  };
};

export const buildNewFavAddressList = (index, territoryId) => {
  return {
    data: {
      type: "address-favorite-set",
      attributes: {
        name: `New List ${territoryId} - ${index}`,
      },
      relationships: {
        territory: {
          data: territoryId
            ? {
                type: "territory",
                id: territoryId,
              }
            : null,
        },
      },
    },
  };
};

export const buildFavAddressListUpdate = (id, name, addArray, territoryId) => {
  return {
    data: {
      type: "address-favorite-set",
      attributes: {
        name: name,
      },
      relationships: {
        addresses: {
          data: addArray,
        },
        territory: {
          data: territoryId
            ? {
                type: "territory",
                id: territoryId,
              }
            : null,
        },
      },
    },
  };
};
