/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useEffect, useState } from "react";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
import Helmet from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { ExitToApp } from "@mui/icons-material";
import { Button, Container, LinearProgress, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import HideFiltersButtonToggle from "src/components/Filtering/HideFiltersButtonToggle";
import { setFiltersOpen } from "src/redux/slices/filterSlice";

import FilterChipList from "../components/Filtering/FilterChipList";
import ProgramActivateModal from "../components/PlanningTool/Programs/ProgramActivateModal";
import ProgramActivationTable from "../components/PlanningTool/Programs/ProgramActivationTable";
import { useInitialFilters } from "../hooks/UtilityHooks";
import { fetchNextFilteredPrograms } from "../redux/slices/planningTool/programSlice";

const defaultAnaplanProgramFilters = {
  isActivated: false,
  codeOrName: "",
  inMarketYear: [],
  programType: [],
  sortOrder: "desc",
  sortOrderBy: "startDate",
};

const useStyles = makeStyles((theme) => ({
  ...theme.global,
}));

const PlanningToolActivate = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { nextLink, isNextLoading, pagesLoaded, isLoading, programs } =
    useSelector((state) => state.anaplanPrograms);

  const [selectedProgram, setSelectedProgram] = useState(null);

  const handleBottomScroll = () => {
    if (nextLink && !isNextLoading) {
      if (scrollRef.current && scrollRef.current.scrollTop !== 0) {
        dispatch(fetchNextFilteredPrograms(nextLink));
      }
    }
  };

  const scrollRef = useBottomScrollListener(handleBottomScroll, {
    offset: 500,
    debounceOptions: {
      leading: true,
      trailing: false,
    },
  });

  const handleClose = () => {
    setSelectedProgram(null);
  };

  useInitialFilters("planningToolActivate", defaultAnaplanProgramFilters);

  useEffect(() => {
    if (selectedProgram) {
      dispatch(setFiltersOpen({ open: false }));
    } else {
      dispatch(setFiltersOpen({ open: true }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProgram]);

  return (
    <>
      {selectedProgram && (
        <ProgramActivateModal
          handleClose={handleClose}
          anaplanProgram={selectedProgram}
        />
      )}
      <Helmet>
        <title>RTA | Planning Tool</title>
      </Helmet>
      <Container className={classes.mainWrapper}>
        <div className={classes.titleBar}>
          <Typography className={classes.titleText}>
            Activate Programs
          </Typography>
          <div className={classes.configButtons}>
            <div className={classes.innerConfigDiv}>
              <Button
                className={classes.largeButton}
                tw="mr-3"
                variant="contained"
                color="secondary"
                startIcon={
                  <ExitToApp style={{ transform: "rotate(180deg)" }} />
                }
                component={Link}
                to={"/planning/dashboard"}
              >
                BACK TO PLANNING TOOL
              </Button>
            </div>
          </div>
        </div>
        <br />
        <>
          <div tw="flex items-center mb-3">
            <HideFiltersButtonToggle />
            <FilterChipList classes={classes} />
          </div>
          {pagesLoaded > 0 ? (
            <ProgramActivationTable
              programs={programs}
              isLoading={isLoading}
              scrollRef={scrollRef}
              setSelectedProgram={setSelectedProgram}
            />
          ) : (
            <Typography variant="h5">
              Add some filters to get started
            </Typography>
          )}
          {isNextLoading && (
            <div style={{ width: "100%" }}>
              <LinearProgress />
            </div>
          )}
          {!isNextLoading && (
            <div style={{ width: "100%", height: "4px" }}></div>
          )}
        </>
      </Container>
      <br />
    </>
  );
};

export default PlanningToolActivate;
