import { createSlice } from "@reduxjs/toolkit";

import { axiosGetWithNext } from "../../../api/axiosCalls";
import { setError } from "../errorSlice";
import {
  resetStepperValue,
  setIsStepper,
  startGlobalLoad,
  stopGlobalLoad,
  updateStepperValue,
} from "../globalLoadSlice";
import {
  buildOrderSetHistoryQuery,
  buildOrderSetVariantHistoryQuery,
  buildOrderVariantQuery,
} from "./helpers";
import {
  mapOrderSetHistory,
  mapOrderSetVariants,
  mapOrderVariantReport,
} from "./maps";

let initialState = {
  isLoading: false,
  isNextLoading: false,
  nextLink: null,
  orderSets: [],
  itemGroups: [],
  orderSetReport: [],
  orderSetItemReport: [],
  triggerCSVDownload: false,
  error: null,
};

const loadingFailed = (state, action) => {
  const { error } = action.payload;
  state.isLoading = false;
  state.error = error;
  state.orderSets = [];
  state.itemGroups = [];
};

const orderSetHistorySlice = createSlice({
  name: "orderSetHistory",
  initialState,
  reducers: {
    setIsLoading(state) {
      state.isLoading = true;
    },
    setNextIsLoading(state) {
      state.isNextLoading = true;
    },
    getOrderSetsSuccess(state, action) {
      const { orderSets, nextLink } = action.payload;
      state.nextLink = nextLink;
      state.orderSets = [...orderSets];
      state.isLoading = false;
      state.error = null;
    },
    getNextOrderSetsSuccess(state, action) {
      const { orderSets, nextLink } = action.payload;
      state.nextLink = nextLink;
      state.orderSets = state.orderSets.concat(orderSets);
      state.isNextLoading = false;
      state.error = null;
    },
    getOrderSetVariantsSuccess(state, action) {
      const { itemGroups, nextLink } = action.payload;
      state.nextLink = nextLink;
      state.itemGroups = [...itemGroups];
      state.isLoading = false;
      state.error = null;
    },
    getNextOrderSetVariantsSuccess(state, action) {
      const { itemGroups, nextLink } = action.payload;
      state.nextLink = nextLink;
      state.itemGroups = state.itemGroups.concat(itemGroups);
      state.isNextLoading = false;
      state.error = null;
    },
    getOrderSetReportSuccess(state, action) {
      const { orderSets } = action.payload;
      state.orderSetReport = orderSets;
      state.triggerCSVDownload = true;
      state.error = null;
    },
    getOrderSetVariantsReportSuccess(state, action) {
      const { orderSetItems } = action.payload;
      state.orderSetItemReport = orderSetItems;
      state.triggerCSVDownload = true;
      state.error = null;
    },
    setTriggerCSVFalse(state) {
      state.triggerCSVDownload = false;
    },
    clearOrderSetReports(state) {
      state.orderSetReport = [];
      state.orderSetItemReport = [];
    },
    resetOrderSetHistory(state) {
      state.isLoading = false;
      state.isNextLoading = false;
      state.nextLink = null;
      state.orderSets = [];
      state.itemGroups = [];
      state.orderSetReport = [];
      state.orderSetItemReport = [];
      state.triggerCSVDownload = false;
      state.error = null;
    },
    getOrderSetDetailSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    setFailure: loadingFailed,
  },
});

export const {
  setIsLoading,
  setNextIsLoading,
  getOrderSetsSuccess,
  getNextOrderSetsSuccess,
  getOrderSetVariantsSuccess,
  getNextOrderSetVariantsSuccess,
  getOrderSetReportSuccess,
  getOrderSetVariantsReportSuccess,
  setTriggerCSVFalse,
  clearOrderSetReports,
  resetOrderSetHistory,
  getOrderSetDetailSuccess,
  setFailure,
} = orderSetHistorySlice.actions;

export default orderSetHistorySlice.reducer;

export const fetchFilteredOrderSets = (filterObject) => async (dispatch) => {
  try {
    dispatch(setIsLoading());
    dispatch(startGlobalLoad());
    const queryString = buildOrderSetHistoryQuery(filterObject);
    const response = await axiosGetWithNext(queryString);
    if (response.error) throw response.error;
    const mappedData = mapOrderSetHistory(response.data.data);
    dispatch(
      getOrderSetsSuccess({
        orderSets: mappedData,
        nextLink: response.data.nextLink,
      })
    );
    dispatch(stopGlobalLoad());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Order Set History" }));
    dispatch(stopGlobalLoad());
  }
};

export const fetchNextFilteredOrderSets = (url) => async (dispatch) => {
  try {
    dispatch(setNextIsLoading());
    dispatch(startGlobalLoad());
    const response = await axiosGetWithNext(url);
    if (response.error) throw response.error;
    let mappedOrderSets = mapOrderSetHistory(response.data.data);
    dispatch(
      getNextOrderSetsSuccess({
        orderSets: mappedOrderSets,
        nextLink: response.data.nextLink,
      })
    );
    dispatch(stopGlobalLoad());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Order Set History" }));
    dispatch(stopGlobalLoad());
  }
};

export const fetchFilteredOrderSetVariants =
  (filterObject) => async (dispatch) => {
    try {
      dispatch(setIsLoading());
      dispatch(startGlobalLoad());
      const queryString = buildOrderSetVariantHistoryQuery(filterObject);
      const response = await axiosGetWithNext(queryString);
      if (response.error) throw response.error;
      let mappedData = mapOrderSetVariants(response.data.data);
      dispatch(
        getOrderSetVariantsSuccess({
          itemGroups: mappedData,
          nextLink: response.data.nextLink,
        })
      );
      dispatch(stopGlobalLoad());
    } catch (err) {
      dispatch(setFailure({ error: err.toString() }));
      dispatch(
        setError({ error: err.toString(), source: "Order Set History" })
      );
      dispatch(stopGlobalLoad());
    }
  };

export const fetchNextFilteredOrderSetVariants = (url) => async (dispatch) => {
  try {
    dispatch(setNextIsLoading());
    dispatch(startGlobalLoad());
    const response = await axiosGetWithNext(url);
    if (response.error) throw response.error;
    let mappedData = mapOrderSetVariants(response.data.data);
    dispatch(
      getNextOrderSetVariantsSuccess({
        itemGroups: mappedData,
        nextLink: response.data.nextLink,
      })
    );
    dispatch(stopGlobalLoad());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Order Set History" }));
    dispatch(stopGlobalLoad());
  }
};

export const fetchOrderSetReport = (filterObject) => async (dispatch) => {
  try {
    dispatch(
      setIsStepper({ stepBool: true, stepTitle: "Generating Order Set Report" })
    );
    let orderSetArray = [];
    let stepValue = 15;

    let queryString = buildOrderSetHistoryQuery(filterObject);
    let initialResponse = await axiosGetWithNext(queryString);
    if (initialResponse.error) throw (await initialResponse).error;
    let initialMappedData = mapOrderSetHistory(initialResponse.data.data);
    orderSetArray = orderSetArray.concat(initialMappedData);

    let nextLink = initialResponse.data.nextLink;
    dispatch(updateStepperValue({ value: stepValue }));

    if (nextLink) {
      const pageSize = initialResponse.data.data.length;
      let fetchCount =
        Math.ceil(initialResponse.data.totalEntries / pageSize) - 1;
      stepValue = parseFloat((85 / fetchCount).toFixed(2));

      for (let i = 0; i < fetchCount; i++) {
        let response = await axiosGetWithNext(nextLink);
        if (response.error) throw response.error;
        nextLink = response.data.nextLink;
        let mappedData = mapOrderSetHistory(response.data.data);
        orderSetArray = orderSetArray.concat(mappedData);
        dispatch(updateStepperValue({ value: stepValue }));
      }
    } else {
      dispatch(updateStepperValue({ value: 85 }));
    }

    dispatch(resetStepperValue());
    dispatch(getOrderSetReportSuccess({ orderSets: orderSetArray }));
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Order Set Reports" }));
    dispatch(resetStepperValue());
  }
};

export const fetchOrderSetVariantReport =
  (filterObject) => async (dispatch) => {
    try {
      dispatch(
        setIsStepper({
          stepBool: true,
          stepTitle: "Generating Order Set Item Report",
        })
      );
      let orderSetItemArray = [];
      let stepValue = 15;

      let queryString = buildOrderVariantQuery({
        ...filterObject,
        status:
          filterObject.status === "all-orders"
            ? "draft,submitted"
            : filterObject.status === "in-progress"
            ? "draft"
            : filterObject.status,
        qtyNotZero: true,
      });
      let initialResponse = await axiosGetWithNext(queryString);
      if (initialResponse.error) throw initialResponse.error;
      let initialMappedData = mapOrderVariantReport(initialResponse.data.data);
      orderSetItemArray = orderSetItemArray.concat(initialMappedData);

      let nextLink = initialResponse.data.nextLink;
      dispatch(updateStepperValue({ value: stepValue }));

      if (nextLink) {
        const pageSize = initialResponse.data.data.length;
        let fetchCount =
          Math.ceil(initialResponse.data.totalEntries / pageSize) - 1;
        stepValue = parseFloat((85 / fetchCount).toFixed(2));

        for (let i = 0; i < fetchCount; i++) {
          let response = await axiosGetWithNext(nextLink);
          if (response.error) throw response.error;
          nextLink = response.data.nextLink;
          let mappedData = mapOrderVariantReport(response.data.data);
          orderSetItemArray = orderSetItemArray.concat(mappedData);
          dispatch(updateStepperValue({ value: stepValue }));
        }
      } else {
        dispatch(updateStepperValue({ value: 85 }));
      }

      dispatch(resetStepperValue());
      dispatch(
        getOrderSetVariantsReportSuccess({ orderSetItems: orderSetItemArray })
      );
    } catch (err) {
      dispatch(setFailure({ error: err.toString() }));
      dispatch(
        setError({ error: err.toString(), source: "Order Set Reports" })
      );
      dispatch(resetStepperValue());
    }
  };
