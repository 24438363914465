import React from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";

import { isEqual } from "lodash";

import { updateQuote } from "../../../redux/slices/planningTool/rfqSlice";
import {
  defaultFormValues,
  intValidation,
  moneyAdornment,
  moneyValidation,
  useCustomForm,
  useCustomRegister,
} from "../../../utility/inputHelpers";
import FormSaveStatus from "../../Utility/FormSaveStatus";

const StyledForm = styled("form")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gridGap: theme.spacing(3),
}));

// to avoid repeating, we create our default component here
const DefaultField = React.forwardRef((props, ref) => (
  <TextField fullWidth variant="outlined" {...props} ref={ref} />
));

const QuoteForm = () => {
  const dispatch = useDispatch();

  const {
    id,
    supplierQuoteNumber,
    description,
    ideas,
    brandingArea,
    preProCost,
    colorOptions,
    decorationOptions,
    itemDimensions,
    preProLeadTimeInDays,
    pricingBasedOn,
  } = useSelector((state) => state.rfq.currentQuote);

  const savedQuote = defaultFormValues({
    supplierQuoteNumber,
    description,
    ideas,
    brandingArea,
    preProCost: preProCost && (+preProCost).toFixed(2), // move the toFixed to map?
    colorOptions,
    decorationOptions,
    itemDimensions,
    preProLeadTimeInDays,
    pricingBasedOn,
  });

  // console.log(savedQuote);

  const {
    register,
    formState: { errors },
    handleSubmit,
    isSaved,
  } = useCustomForm({ mode: "onBlur", defaultValues: savedQuote }); // mode = when to validate input

  const handleSendUpdates = (data) => {
    if (!isEqual(data, savedQuote)) {
      dispatch(updateQuote(id, data));
    }
  };

  const reg = useCustomRegister(register, errors, {
    // handleSubmit will give handleSendUpdates the form values
    onBlur: handleSubmit(handleSendUpdates),
  });

  return (
    <StyledForm onSubmit={(e) => e.preventDefault()}>
      <FormSaveStatus
        isSaved={isSaved}
        handleSubmit={handleSubmit}
        onSave={handleSendUpdates}
      />
      {isSaved.toString()}
      <DefaultField
        label="Supplier Quote Number"
        {...reg("supplierQuoteNumber")}
      />
      <DefaultField
        label="Item Pro Cost *"
        InputProps={moneyAdornment}
        {...reg("preProCost", {
          ...moneyValidation,
        })}
      />
      <DefaultField
        label="Item Pro Lead Time in Days *"
        {...reg("preProLeadTimeInDays", { ...intValidation })}
      />
      <DefaultField label="Item Dimensions *" {...reg("itemDimensions")} />
      <DefaultField label="Pricing Based On *" {...reg("pricingBasedOn")} />
      <DefaultField
        label="Notes"
        multiline
        minRows={3}
        {...reg("description")}
      />
      <DefaultField
        label="Ideas for cost savings, enhancements or other ideas"
        multiline
        minRows={3}
        {...reg("ideas")}
      />
      <DefaultField label="Branding Area" {...reg("brandingArea")} />
      <DefaultField label="Color Options" {...reg("colorOptions")} />
      <DefaultField
        label="Decoration Options"
        {...reg("decorationOptions")}
      />{" "}
    </StyledForm>
  );
};

export default React.memo(QuoteForm);
