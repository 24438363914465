import { createQueryKeys } from "@lukemorales/query-key-factory";
import { useQuery } from "@tanstack/react-query";

import { Program } from "@models";
import client from "@services/api";
import { buildPaginatedQuery } from "@utils/reactQuery";

export const programsKeyFactory = createQueryKeys("programs", {
  paginated: (params) => ({
    queryKey: [params],
    queryFn: () => client.get<Program[]>("programs", { params }),
  }),
  detail: (id: string) => ({
    queryKey: [id],
    queryFn: () =>
      client.get<Program>(`programs/${id}`).then((res) => res.data),
  }),
});

export const useProgramQuery = (id?: string | null) => {
  return useQuery({ ...programsKeyFactory.detail(id!), enabled: !!id });
};

export const useProgramsPaginated = buildPaginatedQuery(
  programsKeyFactory.paginated
);
