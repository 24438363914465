import { createQueryKeys } from "@lukemorales/query-key-factory";
import { keepPreviousData, useQuery } from "@tanstack/react-query";

import { Address } from "@models";
import client from "@services/api";

export const addressesKeyFactory = createQueryKeys("addresses", {
  list: (params) => ({
    queryKey: [params],
    queryFn: () =>
      client.get<Address[]>("addresses", { params }).then((res) => res.data),
  }),
});

export const useAddressesQuery = (filter: Record<string, any>) => {
  return useQuery({
    ...addressesKeyFactory.list({ filter }),
    placeholderData: keepPreviousData,
    staleTime: 20_000,
  });
};
