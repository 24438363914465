/** @jsxImportSource @emotion/react */
import tw, { styled } from "twin.macro";

import { useState } from "react";

import { Flare } from "@mui/icons-material";
import {
  Button,
  ButtonProps,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
} from "@mui/material";

export const Contained = tw.div`px-2 2xl:w-[95%] mx-auto`;

export type StyledButtonProps = ButtonProps & {
  outlined?: boolean;
  cta?: boolean;
  to?: string;
  danger?: boolean;
  loading?: boolean;
  disabled?: boolean;
};

export const StyledButton = ({
  outlined,
  cta,
  danger,
  loading,
  disabled,
  ...props
}: StyledButtonProps) => (
  <Button
    css={[
      tw`px-4 text-gray-700 no-underline transition-all duration-200 bg-white shadow-sm`,
      tw`hover:(shadow-md bg-white)`,

      disabled && tw`text-gray-500 shadow-none`,

      cta && tw`text-white bg-slate-600! hover:(bg-slate-500)!`,
      cta && disabled && tw`bg-slate-400! text-slate-100!`,

      outlined &&
        tw`border border-solid shadow-none border-slate-300 hover:border-slate-500`,
      outlined && disabled && tw`border-slate-200`,

      danger &&
        tw`border border-solid border-transparent shadow-none 
           hover:(text-red-600 border-red-400)`,
    ]}
    disabled={loading || disabled}
    {...props}
    {...(loading && { endIcon: <CircularProgress size={16} /> })}
  />
);

export const ConfirmButton = ({ confirm, children, onClick, ...props }) => {
  const [open, setOpen] = useState(false);
  const close = () => setOpen(false);
  return (
    <>
      <StyledButton {...props} onClick={() => setOpen(true)}>
        {children}
      </StyledButton>
      <Dialog open={open} onClose={close}>
        <DialogTitle>{confirm}</DialogTitle>
        <DialogActions>
          <StyledButton outlined onClick={close}>
            Cancel
          </StyledButton>
          <StyledButton
            cta
            onClick={() => {
              onClick();
              close();
            }}
          >
            Confirm
          </StyledButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export const SmallIndexCounter = tw.div`
  flex items-center justify-center 
  w-8 h-8 mr-1 
  text-sm
  rounded-full 
  bg-slate-50 text-slate-700`;

export const BeaconIcon = tw(
  Flare
)`bg-white text-lg text-blue-600 rounded-md p-px`;

export const PageTitle = tw.h1`text-2xl font-bold text-gray-700 mt-6 mb-3`;

export const FixedHeightScrollLastChild = styled(Contained)({
  "&": {
    ...tw`flex flex-col`,
    height:
      "calc(100vh - var(--header-height) - var(--space-after-header, 0px))",
  },
  "& > *": tw`flex-none`,
  "& > *:last-child": tw`flex-initial`,
});
