import { UseQueryResult, useQueries } from "@tanstack/react-query";
import _ from "lodash";

import { Order } from "@models/Order";
import { OrderSet } from "@models/OrderSet";
import { OrderSetVariant } from "@models/OrderSetVariant";

import { orderSetsKeyFactory } from "../../queries/orderSetQueries";

export type ExtendedOrderSetVariant = OrderSetVariant & {
  totalQty?: number;
  totalEstimatedCost?: number;
};
// insures both orderVariants and orderSetVariants are sorted by the same key
const variantSortKey = "variant.id";

// Declare the combine function outside of the hook
// so that it's reference is stable and doesn't cause re-renders
function combineOrderSetAndOrdersQuery([orderSetQuery, ordersQuery]: [
  UseQueryResult<OrderSet, Error>,
  UseQueryResult<Order[], Error>,
]) {
  if (orderSetQuery.isPending || ordersQuery.isPending) {
    return { isPending: true as const };
  }

  if (orderSetQuery.isLoading || ordersQuery.isLoading) {
    return { isLoading: true as const };
  }

  if (orderSetQuery.error || ordersQuery.error) {
    return { error: (orderSetQuery.error || ordersQuery.error) as Error };
  }

  const orders = ordersQuery.data!;
  const orderSet = orderSetQuery.data!;
  const sortedOrdersData = _(orders)
    .map((order) => ({
      ...order,
      orderVariants: _.sortBy(order.orderVariants, variantSortKey),
    }))
    .sortBy("address.state.id", "address.id", "territoryName", "subStateName")
    .value();

  const orderSetVariants: ExtendedOrderSetVariant[] = _(
    orderSet.orderSetVariants
  )
    .sortBy(variantSortKey)
    .map((osv, i) => ({
      ...osv,
      totalQty: sortedOrdersData.reduce(
        (totalQty, order) => totalQty + (order.orderVariants[i]?.qty || 0),
        0
      ),
      totalEstimatedCost: sortedOrdersData.reduce(
        (totalEstimatedCost, order) =>
          totalEstimatedCost +
          +(order.orderVariants[i]?.totalEstimatedCost || 0),
        0
      ),
    }))

    .value();

  return {
    orderSet: orderSet,
    orders: sortedOrdersData,
    orderSetVariants,
    orderSetQuery,
    ordersQuery,
  };
}

export const useOrderSet = (orderSetId?: string | null) => {
  return useQueries({
    queries: [
      {
        ...orderSetsKeyFactory.detail(orderSetId),
        enabled: !!orderSetId,
      },
      {
        ...orderSetsKeyFactory.detail(orderSetId)._ctx.orders,
        enabled: !!orderSetId,
      },
    ],
    combine: combineOrderSetAndOrdersQuery,
  });
};
