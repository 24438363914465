import React, { useEffect } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import { CircularProgress, Grid, TextField } from "@mui/material";

import { useDebounce } from "../../../hooks/UtilityHooks";
import { updatePricingTierQuote } from "../../../redux/slices/planningTool/rfqSlice";
import {
  intValidation,
  moneyAdornment,
  moneyValidation,
  useCustomRegister,
} from "../../../utility/inputHelpers";
import BasicTable from "../RFQ/BasicTable";

const PricingTierQuotesTable = () => {
  const dispatch = useDispatch();
  const [rowsLoading, setRowsLoading] = useState({});
  const { pricingTierQuotes } = useSelector((state) => state.rfq.currentQuote);
  // change from an array to an obj with the id as the key
  const savedRows = pricingTierQuotes.reduce(
    (a, { id, price, leadTimeInDays }) => {
      a[id] = {
        price: price ? (+price).toFixed(2) : "",
        leadTimeInDays,
      };
      return a;
    },
    {}
  );

  const {
    register,
    watch,
    formState: { errors },
  } = useForm({ mode: "onChange", defaultValues: savedRows }); // mode = when to validate input

  const allRows = watch(); // watch with no arguments returns the whole form obj

  const rowWasModified = (id) =>
    !(
      +allRows[id].price === +savedRows[id].price &&
      +allRows[id].leadTimeInDays === +savedRows[id].leadTimeInDays
    );

  const handleSendUpdates = () => {
    Object.entries(allRows).forEach(async ([tierId, data]) => {
      if (
        !errors[tierId] && // no errors
        Object.values(data).every(Boolean) && // each field has a value
        rowWasModified(tierId) // row is different from saved value
      ) {
        setRowsLoading({ ...rowsLoading, [tierId]: true });
        await dispatch(updatePricingTierQuote(tierId, data));
        setRowsLoading({ ...rowsLoading, [tierId]: false });
      }
    });
  };

  const debouncedHandleSendUpdates = useDebounce(handleSendUpdates, 1000);

  useEffect(
    () => debouncedHandleSendUpdates(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [allRows]
  );

  const reg = useCustomRegister(register, errors);

  return (
    <form onSubmit={(e) => e.preventDefault()} autoComplete="off">
      <BasicTable
        rows={[
          ["Quantity", "Price", "Lead time (days)", ""],
          ...pricingTierQuotes.map(({ id, qty }) => [
            // Quantity
            qty.toLocaleString("en-US"),
            // ===========
            // Price Input
            <TextField
              variant="outlined"
              placeholder="0.00"
              InputProps={moneyAdornment}
              fullWidth
              {...reg(`${id}.price`, {
                required: true,
                ...moneyValidation,
              })}
            />,
            // ===============
            // Lead time input
            <TextField
              variant="outlined"
              placeholder="0"
              fullWidth
              {...reg(`${id}.leadTimeInDays`, {
                required: true,
                ...intValidation,
              })}
            />,
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              style={{
                width: "24px",
                margin: "0 -16px 0 -24px",
              }}
            >
              {rowsLoading[id] && <CircularProgress size={24} />}
            </Grid>,
          ]),
        ]}
      />
    </form>
  );
};

export default React.memo(PricingTierQuotesTable);
