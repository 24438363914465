import { createQueryKeys } from "@lukemorales/query-key-factory";
import { useQuery } from "@tanstack/react-query";

import { Item } from "@models";
import client from "@services/api";
import { buildResourceMutations } from "@utils/reactQuery";

export const itemsKeyFactory = createQueryKeys("items", {
  detail: (id: string) => ({
    queryKey: [id],
    queryFn: () => client.get<Item>(`items/${id}`).then((res) => res.data),
  }),
  list: (params) => ({
    queryKey: [params],
    queryFn: () => client.get<Item[]>("items", { params }),
  }),
});

export const useItemQuery = (id: string | null) => {
  return useQuery({
    ...itemsKeyFactory.detail(id!),
    enabled: !!id,
  });
};

export const { useUpdate: useUpdateItemMutation } =
  buildResourceMutations<Item>({
    resource: "items",
    type: "item",
    detailQueryKey: (id) => itemsKeyFactory.detail(id).queryKey,
    listQueryKey: itemsKeyFactory.list._def,
  });
