import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import ListSubHeader from "@mui/material/ListSubheader";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";

import PropTypes from "prop-types";

import {
  getWindowSelections,
  monthMap,
} from "../../../utility/utilityFunctions";

const ItemOrderTypeSelector = ({
  handleOrderType,
  orderType,
  setType,
  classes,
  filterType,
}) => {
  const orderTypes = [
    { id: "all", label: "All" },
    { id: "on-demand", label: "On Demand" },
    { id: "inventory", label: "Inventory" },
  ];

  const currentType = useSelector((state) => state.filters.itemOrderType);
  const isGlobalLoading = useSelector((state) => state.globalLoad.isLoading);

  const currentPreOrderWindows = getWindowSelections("Pre Order");
  const currentAdHocWindows = getWindowSelections("Ad Hoc");

  const handleChangeSelect = (evt) => {
    setType(evt.target.value);
    handleOrderType(evt.target.value, "itemOrderType", filterType);
  };

  useEffect(() => {
    if (orderType !== currentType) {
      setType(currentType);
    }
  });

  return (
    <>
      <FormControl
        fullWidth
        variant="outlined"
        size="small"
        className={classes.queryField}
        disabled={isGlobalLoading}
      >
        <InputLabel id="orderType-select">Order Type</InputLabel>
        <Select
          variant="outlined"
          label={"Order Type"}
          name="orderType"
          labelId="itemOrderType-select"
          id="itemOrderType"
          value={orderType}
          onChange={handleChangeSelect}
          MenuProps={{
            style: { zIndex: "2500" },

            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          }}
        >
          {orderTypes.map((orderType, index) => (
            <MenuItem value={orderType.id} key={index}>
              <Typography variant="body2">{orderType.label}</Typography>
            </MenuItem>
          ))}
          <ListSubHeader>Pre Order Windows:</ListSubHeader>
          {currentPreOrderWindows.map((window, index) => (
            <MenuItem
              value={`${window.month}-${window.year}-${window.programType}`}
              key={`pre-order-window-${index}`}
            >
              <Typography variant="body2">{`${monthMap[window.month]} - ${
                window.year
              }`}</Typography>
            </MenuItem>
          ))}
          <ListSubHeader>Ad Hoc Windows:</ListSubHeader>
          {currentAdHocWindows.map((window, index) => (
            <MenuItem
              value={`${window.month}-${window.year}-${window.programType}`}
              key={`ad-hoc-window-${index}`}
            >
              <Typography variant="body2">{`${monthMap[window.month]} - ${
                window.year
              }`}</Typography>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

ItemOrderTypeSelector.propTypes = {
  handleOrderType: PropTypes.func.isRequired,
  orderType: PropTypes.string.isRequired,
  setType: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default ItemOrderTypeSelector;
