import React, { useState } from "react";
import { useDispatch } from "react-redux";

import {
  StyledButton,
  StyledButtonProps,
} from "src/components/StyledComponents";

import { requestReport } from "./globalReportSlice";

type RequestReportButtonProps = StyledButtonProps & {
  reportName: string;
  title?: string;
};

const RequestReportButton = ({
  reportName,
  title = "Export Report",
  ...props
}: RequestReportButtonProps) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);

  const handleClick = () => {
    dispatch(requestReport(reportName));
    setLoading(true);
    setTimeout(() => setLoading(false), 2000);
  };
  return (
    <StyledButton cta onClick={handleClick} loading={loading} {...props}>
      {title}
    </StyledButton>
  );
};

export default RequestReportButton;
