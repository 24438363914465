import { Route, Routes } from "react-router-dom";

import AuthRoutes from "./AuthRoutes";
import SuspenseRoute from "./SuspenseRoute";
import UnauthRoutes from "./UnauthRoutes";

const RTARoutes = ({
  loggedIn,
  currentUser,
  role,
  isAuthenticated,
  handleLogout,
}) => {
  return (
    <Routes>
      <Route
        path="/approveOrDenyItem"
        element={
          <SuspenseRoute
            componentImport={() => import("../pages/ApproveOrDenyItem")}
          />
        }
      />
      {!loggedIn && !currentUser ? (
        <Route path="*" element={<UnauthRoutes />} />
      ) : (
        role &&
        isAuthenticated && (
          <Route
            path="*"
            element={<AuthRoutes role={role} handleLogout={handleLogout} />}
          />
        )
      )}
    </Routes>
  );
};

export default RTARoutes;
