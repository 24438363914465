import { useCallback } from "react";
import { useDispatch } from "react-redux";

import { useMutation, useQueryClient } from "@tanstack/react-query";
import _ from "lodash";
import { setError } from "src/redux/slices/errorSlice";
import {
  removeOrderVariantError,
  setOrderVariantError,
} from "src/redux/slices/ordering/orderSetSlice";

import { Order } from "@models/Order";
import { OrderSet } from "@models/OrderSet";
import { OrderVariant } from "@models/OrderVariant";
import client from "@services/api";

import { orderSetsKeyFactory } from "../../../queries/orderSetQueries";
import useOrderSetId from "../useOrderSetId";

export default function useSetOrderVariantQty() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const orderSetId = useOrderSetId();

  const startOrderSet = useCallback(async () => {
    client.post(`order-sets/${orderSetId}/start`).catch((err) => {
      console.error(err);
      queryClient.invalidateQueries({
        queryKey: orderSetsKeyFactory.detail(orderSetId).queryKey,
      });
    });
    // Optimistic update
    queryClient.setQueryData(
      orderSetsKeyFactory.detail(orderSetId).queryKey,
      (orderSet: OrderSet) => ({
        ...orderSet,
        status: "in-progress",
      })
    );
  }, [orderSetId, queryClient]);

  return useMutation({
    mutationFn: ({ id, qty }: { id: string; qty: number }) => {
      const orderSet = queryClient.getQueryData(
        orderSetsKeyFactory.detail(orderSetId).queryKey
      ) as OrderSet;

      if (orderSet.status === "inactive") {
        startOrderSet();
      }

      return client
        .update<OrderVariant>(`order-variants/${id}`, {
          type: "order-variant",
          qty,
        })
        .then((data) => ({ orderVariant: data.data }));
    },
    onSuccess: ({ orderVariant: newOrderVariant }) => {
      dispatch(removeOrderVariantError({ id: newOrderVariant.id }));

      const newBudgets = _.keyBy(newOrderVariant.budgets, "id");

      return queryClient.setQueryData(
        orderSetsKeyFactory.detail(orderSetId)._ctx.orders.queryKey,
        (orders: Order[]) => {
          return orders.map((order) => {
            const ovIndex = order.orderVariants.findIndex(
              (ov) => ov.id === newOrderVariant.id
            );

            // Is the order variant isn't on that order, we still need to update the budgets
            if (ovIndex === -1)
              return {
                ...order,
                orderVariants: order.orderVariants.map((ov) => ({
                  ...ov,
                  // Replace budgets with updated budgets
                  budgets: ov.budgets.map((b) => newBudgets[b.id] ?? b),
                })),
              };

            let oldOrderVariant: any;
            const orderVariants = order.orderVariants.map((ov) => {
              if (ov.id === newOrderVariant.id) {
                oldOrderVariant = ov;
                return newOrderVariant;
              }
              return ov;
            });

            const updateKey = (key: string) =>
              String(
                +order[key] - +oldOrderVariant[key] + +newOrderVariant[key]
              );

            return {
              ...order,
              totalQuantity:
                order.totalQuantity - oldOrderVariant.qty + newOrderVariant.qty,
              totalBeaconCost: updateKey("totalBeaconCost"),
              totalEstimatedCost: updateKey("totalEstimatedCost"),
              totalEstimatedTax: updateKey("totalEstimatedTax"),
              totalEstimatedShippingCost: updateKey(
                "totalEstimatedShippingCost"
              ),
              orderVariants,
            };
          });
        }
      );
    },
    onError: (error: any, { id }) => {
      if (Array.isArray(error.data.errors)) {
        const message = error.data.errors[0]?.title;
        // matches a number in parentheses at the end of the string
        const maxQuantity = message.match(/\((\d+)\)$/)?.[1];
        dispatch(setOrderVariantError({ id, error: message, maxQuantity }));
      } else {
        dispatch(
          setError({
            error: error.message,
            source: "useSetOrderVariantQty",
          })
        );
        console.error(error);
      }
    },
  });
}
