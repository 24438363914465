import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { Button, Modal, Paper, TextField, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { approveDenyPriorApprovalItem } from "../../redux/slices/compliance/complianceItemsSlice";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    padding: theme.spacing(4),
    width: 400,
  },
  buttons: {
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "space-between",
  },
}));

const RuleApproveDenyNotesModal = ({ items, closeModal, approving }) => {
  const classes = useStyles();
  const [currentStep, setCurrentStep] = useState(0);
  const [notes, setNotes] = useState([]);

  const dispatch = useDispatch();

  const handleNext = () => {
    if (currentStep < items.length - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handlePrevious = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleNoteChange = (event) => {
    const newNotes = [...notes];
    newNotes[currentStep] = event.target.value;
    setNotes(newNotes);
  };

  const currentItem = items[currentStep];

  const isLastStep = currentStep === items.length - 1;

  const handleFinishClick = () => {
    const itemsWithNotes = items.map((item, index) => ({
      ...item,
      note: notes[index],
    }));
    closeModal();
    dispatch(approveDenyPriorApprovalItem(itemsWithNotes, approving));
  };

  return (
    <Modal className={classes.modal} open={true} onClose={closeModal}>
      <Paper className={classes.paper}>
        <Typography variant="h6">Rule {currentStep + 1}</Typography>
        <Typography variant="body1">
          <b>Brand:</b> {currentItem.brand}
        </Typography>
        <Typography variant="body1">
          <b>Item Type:</b> {currentItem.itemType}
        </Typography>
        <Typography variant="body1">
          <b>State:</b> {currentItem.state}
        </Typography>
        <Typography variant="body1">
          <b>Rule Type:</b> {currentItem.ruleType}
        </Typography>
        <Typography variant="body1">
          <b>Rule Description:</b> {currentItem.ruleDesc}
        </Typography>
        <TextField
          value={notes[currentStep] || ""}
          onChange={handleNoteChange}
          placeholder="Add notes..."
          multiline
          rows={4}
          fullWidth
          margin="normal"
          variant="outlined"
        />

        <div className={classes.buttons}>
          <Button
            onClick={handlePrevious}
            disabled={currentStep === 0}
            variant="contained"
            color="primary"
          >
            Previous
          </Button>
          {isLastStep ? (
            <Button
              onClick={handleFinishClick}
              variant="contained"
              color="primary"
            >
              Finish
            </Button>
          ) : (
            <Button
              onClick={handleNext}
              disabled={currentStep === items.length - 1}
              variant="contained"
              color="primary"
            >
              Next
            </Button>
          )}
          <Button onClick={closeModal} variant="outlined" color="primary">
            Close
          </Button>
        </div>
      </Paper>
    </Modal>
  );
};

export default RuleApproveDenyNotesModal;
