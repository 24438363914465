import { useEffect } from "react";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
import Helmet from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { Button, Container, LinearProgress, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import HideFiltersButtonToggle from "src/components/Filtering/HideFiltersButtonToggle";

import FilterChipList from "../components/Filtering/FilterChipList";
import RFQsSearchTable from "../components/PlanningTool/RFQ/RFQSearchTable";
import RfqModal from "../components/PlanningTool/RFQ/RfqModal/RfqModal";
import { useInitialFilters } from "../hooks/UtilityHooks";
import {
  setChips,
  setClearFreeType,
  setSorted,
  updateMultipleFilters,
} from "../redux/slices/filterSlice";
import { openRFQModal } from "../redux/slices/planningTool/rfqSlice";

const defaultFilters = {
  itemNumber: "",
  planningToolProgramName: "",
  itemDesc: "",
  brand: [],
  bu: [],
  itemType: [],
  sortOrder: "asc",
  sortOrderBy: "",
};

const useStyles = makeStyles((theme) => ({
  ...theme.global,
}));

const PlanningToolRFQsSearch = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { isLoading, isNextLoading, nextLink, rfqItems } = useSelector(
    (state) => state.rfq
  );

  const handleBottomScroll = () => {
    if (nextLink && !isNextLoading) {
      if (scrollRef.current && scrollRef.current.scrollTop !== 0) {
        // dispatch(fetchNextPlanningToolPrograms(nextLink));
        // console.log("fetch next");
      }
    }
  };

  const scrollRef = useBottomScrollListener(handleBottomScroll, {
    offset: 500,
    debounceOptions: {
      leading: true,
      trailing: false,
    },
  });

  useEffect(() => {
    dispatch(updateMultipleFilters({ filterObject: defaultFilters }));
    dispatch(
      setClearFreeType({
        value: ["itemNumber", "planningToolProgramName", "itemDesc"],
      })
    );
    dispatch(setSorted());
    dispatch(setChips({ filterType: "RFQ" }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useInitialFilters("RFQ", defaultFilters);

  const handleSort = (sortObject) => {
    scrollRef.current.scrollTop = 0;
    dispatch(
      updateMultipleFilters({
        filterObject: {
          sortOrder: sortObject.order,
          sortOrderBy: sortObject.orderBy,
        },
      })
    );
    dispatch(setSorted());
  };

  return (
    <>
      <Helmet>
        <title>RTA | Planning Tool</title>
      </Helmet>
      <RfqModal />
      <Container className={classes.mainWrapper}>
        <div className={classes.titleBar}>
          <Typography className={classes.titleText}>Search RFQs</Typography>
          <div className={classes.configButtons}>
            <div className={classes.innerConfigDiv}>
              <Button
                className={classes.largeButton}
                variant="contained"
                color="secondary"
                style={{ marginRight: "10px" }}
                onClick={() => dispatch(openRFQModal())}
              >
                CREATE RFQ
              </Button>
              <Button
                className={classes.largeButton}
                variant="contained"
                color="secondary"
                component={Link}
                to="/planning/program/search"
              >
                Programs
              </Button>
            </div>
          </div>
        </div>
        <br />
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignContent: "center",
              marginBottom: "10px",
            }}
          >
            <HideFiltersButtonToggle />
            <FilterChipList classes={classes} />
            <br />
          </div>
          <RFQsSearchTable
            handleSort={handleSort}
            rfqItems={rfqItems}
            isLoading={isLoading}
            scrollRef={scrollRef}
          />
          {isNextLoading && (
            <div style={{ width: "100%" }}>
              <LinearProgress />
            </div>
          )}
          {!isNextLoading && (
            <div style={{ width: "100%", height: "4px" }}></div>
          )}
        </>
      </Container>
      <br />
    </>
  );
};

export default PlanningToolRFQsSearch;
