/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import _ from "lodash";

import { RFQ_ROLES } from "../../../constants/permissions";
import {
  useHandleSubmitErrors,
  useProgramInfo,
} from "../../../hooks/planningToolHooks";
import {
  createItem,
  deleteItemProgram,
  setNewEntityDefaults,
  updateItemProgram,
} from "../../../redux/slices/planningTool/itemProgramSlice";
import { openRFQModal } from "../../../redux/slices/planningTool/rfqSlice";
import { useCustomForm } from "../../../utility/inputHelpers";
import { ConfirmButton, StyledButton } from "../../StyledComponents";
import SaveStatusChip from "../../Utility/SaveStatusChip";
import UserCanView from "../../Utility/UserCanView";
import InfoAccordions from "./InfoAccordions";
import ItemForm from "./ItemForm";
import {
  compareWithOrderType,
  decoupleOrderType,
  fields,
  fieldsShouldNotDuplicate,
  formDataFromItem,
} from "./helpers";

const ItemEdit = ({ item }) => {
  const {
    isSubmitted,
    programId,
    orderType: programOrderType,
  } = useProgramInfo();
  const dispatch = useDispatch();
  const saving = useRef(0);
  const [isSaving, setIsSaving] = useState(false);
  const [isDuplicateLoading, setIsDuplicateLoading] = useState(false);

  const savedData = formDataFromItem(item);

  const compareFunction = compareWithOrderType(programOrderType);

  const formAttrs = useCustomForm({
    mode: "onBlur",
    defaultValues: savedData,
    compareFunction,
  });

  const { control, isSaved, handleSubmit, setError } = formAttrs;

  // Handles setting errors on program submit
  useHandleSubmitErrors(setError, item.id);

  const requiredFields = fields.default.required.concat(
    isSubmitted ? fields.submitted.required : []
  );

  const disabledFields = isSubmitted
    ? fields.submitted.disabled
    : fields.default.disabled;

  // Default props for controlled inputs
  const controlled = (name, rules) => ({
    name,
    control,
    rules: { ...rules, required: requiredFields.includes(name) },
    disabled: disabledFields.includes(name),
    onBlur: handleSubmit(handleSave),
  });

  const handleSave = (data) => {
    if (!compareFunction(data, savedData)) {
      saving.current++;
      setIsSaving(true);
      dispatch(
        updateItemProgram(
          item,
          decoupleOrderType(data, programOrderType),
          () => {
            saving.current--;
            setIsSaving(false);
          }
        )
      );
    }
  };

  const handleDelete = () => {
    if (item.id) {
      dispatch(deleteItemProgram(item.id));
    }
  };
  const handleDuplicate = (data) => {
    let dataToDuplicate = formDataFromItem(
      _.omit(data, fieldsShouldNotDuplicate)
    );
    dataToDuplicate = decoupleOrderType(dataToDuplicate, programOrderType);

    if (isSubmitted) {
      dispatch(setNewEntityDefaults({ type: "item", data: dataToDuplicate }));
    } else {
      setIsDuplicateLoading(true);
      dispatch(
        createItem(programId, dataToDuplicate, () =>
          setIsDuplicateLoading(false)
        )
      );
    }
  };

  return (
    <div tw="space-y-4">
      <div tw="flex justify-end">
        <SaveStatusChip isSaved={isSaved} isSaving={isSaving} />
      </div>
      <ItemForm form={formAttrs} controlled={controlled} item={item} />
      <div>Mandatory Code: {item.mandatoryCode}</div>
      <InfoAccordions item={item} />
      <div tw="flex justify-between">
        <ConfirmButton
          confirm={
            isSubmitted
              ? "Are you sure you want to remove this item from this program? This can't be undone."
              : "Are you sure you want to permanently delete this item? This can't be undone."
          }
          danger
          onClick={handleDelete}
          disabled={isSaving}
        >
          {isSubmitted ? "Cancel Item" : "Delete Item"}
        </ConfirmButton>
        <div tw="space-x-2">
          <UserCanView allow={RFQ_ROLES}>
            <Link
              to={`/planning/rfqs/program/${programId}/item/${item.itemId}`}
            >
              View RFQs
            </Link>
            <StyledButton
              outlined
              onClick={() =>
                dispatch(
                  openRFQModal({ init: { itemId: item.itemId, programId } })
                )
              }
            >
              CREATE RFQ
            </StyledButton>
          </UserCanView>
          <StyledButton
            outlined
            disabled={isDuplicateLoading}
            onClick={handleSubmit(handleDuplicate)}
          >
            Duplicate Item
          </StyledButton>
        </div>
      </div>
    </div>
  );
};

export default ItemEdit;
