/** @jsxImportSource @emotion/react */
import "twin.macro";

import React, { useState } from "react";
import Helmet from "react-helmet";

import { CircularProgress, Container } from "@mui/material/";
import makeStyles from "@mui/styles/makeStyles";

import PropTypes from "prop-types";

import { StyledButton } from "../StyledComponents";
import BackButton from "./BackButton";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
  ...theme.reports,
}));

const SimpleReportGenerator = ({
  reportTitle,
  reportGetFunc,
  children,
  disabled = false,
}) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);

  const handleGetReport = async () => {
    setIsLoading(true);
    await reportGetFunc();
    setIsLoading(false);
  };

  return (
    <>
      <Helmet>
        <title>RTA | {reportTitle}</title>
      </Helmet>
      <Container className={classes.mainWrapper}>
        <div tw="flex justify-end">
          <BackButton />
        </div>
        <br />
        <h1 tw="flex justify-center font-bold text-2xl">{reportTitle}</h1>
        <br />
        <div className={classes.searchComponents}>{children}</div>
        <br />
        <div className={classes.buttonRow}>
          <StyledButton cta onClick={handleGetReport} disabled={disabled}>
            GENERATE REPORT
          </StyledButton>
        </div>
        <br />
        <br />
        {isLoading && (
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <CircularProgress size={100} style={{ marginTop: "50px" }} />
          </div>
        )}
      </Container>
    </>
  );
};

SimpleReportGenerator.propTypes = {
  reportTitle: PropTypes.string.isRequired,
  reportGetFunc: PropTypes.func.isRequired,
  children: PropTypes.object.isRequired,
};

export default SimpleReportGenerator;
