import React, { useState } from "react";
import { useSelector } from "react-redux";

import CancelIcon from "@mui/icons-material/Cancel";
import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import PropTypes from "prop-types";

import { upCase } from "../../utility/utilityFunctions";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
}));

const RollupList = ({
  classes,
  label,
  rollups,
  selectedRollups,
  handleSelect,
}) => {
  return (
    <>
      <Typography className={classes.headerText}>{label}</Typography>
      <br />
      <List>
        {rollups.map((r, i) => (
          <ListItem
            key={`${i}-${r.id}`}
            role={undefined}
            dense
            button
            onClick={() => handleSelect(r)}
          >
            <ListItemIcon>
              <Checkbox
                edge="start"
                checked={Boolean(selectedRollups.find((sr) => sr.id === r.id))}
                tabIndex={-1}
                disableRipple
                inputProps={{ "aria-labelledby": r.id }}
              />
            </ListItemIcon>
            <ListItemText
              id={r.id}
              primary={`Sequence #: ${r.itemNumber}, Qty: ${r.totalQty}`}
            />
          </ListItem>
        ))}
      </List>
    </>
  );
};

RollupList.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  rollups: PropTypes.array.isRequired,
  selectedRollups: PropTypes.array.isRequired,
  handleSelect: PropTypes.func.isRequired,
};

const MultipleVariantModal = ({
  matchingRollupItems,
  onDemandRollupItems,
  selectedItemRollupsRef,
  handleContinue,
  handleCancel,
}) => {
  const classes = useStyles();
  const { orderType } = useSelector((state) => state.filters);
  const [selectedRollups, setSelectedRollups] = useState([]);

  const handleSelect = (item) => {
    if (selectedRollups.find((r) => r.id === item.id)) {
      setSelectedRollups(selectedRollups.filter((r) => r.id !== item.id));
    } else {
      setSelectedRollups(selectedRollups.concat(item));
    }
  };

  const handleSubmit = (type) => {
    let additionalItemRollups = [];

    if (type === "selected") {
      additionalItemRollups = additionalItemRollups.concat(selectedRollups);
    } else if (type === "all") {
      additionalItemRollups = additionalItemRollups
        .concat(matchingRollupItems)
        .concat(onDemandRollupItems);
    }

    selectedItemRollupsRef.current = [
      ...selectedItemRollupsRef.current,
      ...additionalItemRollups,
    ];
    handleContinue();
  };

  return (
    <div className={classes.relativeContainer}>
      <Dialog
        open
        onClose={handleCancel}
        fullWidth
        maxWidth="sm"
        style={{ zIndex: "15000" }}
      >
        <DialogContent>
          <IconButton
            className={classes.closeButton}
            onClick={handleCancel}
            size="large"
          >
            <CancelIcon fontSize="large" color="secondary" />
          </IconButton>
          <br />
          <br />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              textAlign: "center",
              alignItems: "center",
            }}
          >
            <Typography className={classes.headerText}>
              There are additional item-rollups for this item that can be added
              to the purchase order you are creating. You can select which
              item-rollups you would like to add to this purchase order from the
              list below, or click ALL ROLLUPS to add them all.
            </Typography>
            <br />
            {matchingRollupItems.length > 0 && (
              <>
                <RollupList
                  classes={classes}
                  label={`Order Type: ${upCase(orderType, "-")}`}
                  rollups={matchingRollupItems}
                  selectedRollups={selectedRollups}
                  handleSelect={handleSelect}
                />
                <br />
              </>
            )}
            {onDemandRollupItems.length > 0 && (
              <>
                <RollupList
                  classes={classes}
                  label="Order Type: On Demand"
                  rollups={onDemandRollupItems}
                  selectedRollups={selectedRollups}
                  handleSelect={handleSelect}
                />
                <br />
              </>
            )}
            <Typography className={classes.headerText}>
              Create Purchase Order With:
            </Typography>
            <br />
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                width: "100%",
              }}
            >
              <Button
                className={classes.largeButton}
                variant="contained"
                color="secondary"
                onClick={() => handleSubmit("original")}
              >
                ORIGINAL ROLLUPS
              </Button>
              <Button
                className={classes.largeButton}
                variant="contained"
                color="secondary"
                onClick={() => handleSubmit("selected")}
              >
                SELECTED ROLLUPS
              </Button>
              <Button
                className={classes.largeButton}
                variant="contained"
                color="secondary"
                onClick={() => handleSubmit("all")}
              >
                ALL ROLLUPS
              </Button>
            </div>
          </div>
          <br />
        </DialogContent>
      </Dialog>
    </div>
  );
};

MultipleVariantModal.propTypes = {
  matchingRollupItems: PropTypes.array,
  onDemandRollupItems: PropTypes.array,
  handleContinue: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  selectedItemRollupsRef: PropTypes.any.isRequired,
};

export default React.memo(MultipleVariantModal);
