import { createQueryKeys } from "@lukemorales/query-key-factory";
import { useQuery } from "@tanstack/react-query";

import { OrderVariant } from "@models";
import client from "@services/api";

export const orderVariantsKeyFactory = createQueryKeys("order-variants", {
  list: (params) => ({
    queryKey: [params],
    queryFn: () =>
      client
        .get<OrderVariant[]>("order-variants", { params })
        .then((res) => res.data),
  }),
});

export const useOrderVariantsQuery = (params: Record<string, any>) => {
  return useQuery({
    ...orderVariantsKeyFactory.list(params),
  });
};
