import React, { useEffect, useState } from "react";

import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

import { useTerritories } from "@features/territories";

const TerritorySelector = ({
  handleTerritories,
  selectedTerritories,
  ...props
}) => {
  const { data: territories = [] } = useTerritories({
    isAwaitingCode: false,
  });
  const [currentTerritories, setCurrentTerritories] =
    useState(selectedTerritories);

  useEffect(() => {
    if (currentTerritories.length !== selectedTerritories.length) {
      setCurrentTerritories(selectedTerritories);
    }
  }, [currentTerritories, selectedTerritories]);

  return (
    <Autocomplete
      multiple
      autoHighlight
      id="program-territories"
      size="small"
      limitTags={4}
      isOptionEqualToValue={(option, value) => option.name === value.name}
      options={territories}
      value={currentTerritories}
      getOptionLabel={(option) => option.name}
      onChange={(_evt, value) => handleTerritories(value)}
      {...props}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label="Territory"
          size="small"
        />
      )}
    />
  );
};

export default TerritorySelector;
