/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import React, { useState } from "react";

import { Close } from "@mui/icons-material";
import {
  Checkbox,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

import _ from "lodash";
import { SelectField } from "src/components/Forms/DefaultInputs";

import { Territory } from "@models/Territory";
import { useApiResource } from "@services/api";

type SubStateSelectionProps = {
  regionalTerritories: Territory[];
  selection: string[];
  setSelection: (selection: string[]) => void;
};

const SubStateSelection: React.FC<SubStateSelectionProps> = ({
  selection,
  setSelection,
  regionalTerritories,
}) => {
  const [territoryIdFilter, setTerritoryIdFilter] = useState("");
  const { data, isValidating } = useApiResource("sub-states", {
    filter: territoryIdFilter ? { territoryId: territoryIdFilter } : {},
    staleTime: Infinity,
  });
  const subStateList = _.sortBy(data ?? [], "name");

  const allSubStateIds = subStateList.map((obj) => obj.id);

  const handleToggleSubState = (subStateId: string) => {
    setSelection(_.xor(selection, [subStateId]));
  };

  const handleSelectAllSubStates = () => {
    if (_.difference(allSubStateIds, selection).length === 0) {
      setSelection(_.difference(selection, allSubStateIds));
    } else {
      setSelection(_.union(selection, allSubStateIds));
    }
  };

  return (
    <>
      <ListItem tw="sticky top-0 bg-white flex justify-between items-center gap-6 z-10">
        <SelectField
          tw="flex-1"
          label="Filter by Territory"
          options={regionalTerritories.map((terr) => ({
            label: terr.name,
            value: terr.id,
          }))}
          value={territoryIdFilter}
          onChange={(e) => setTerritoryIdFilter(e.target.value as string)}
          IconComponent={
            territoryIdFilter
              ? () => (
                  <IconButton
                    size="small"
                    tw="mr-2"
                    onClick={() => setTerritoryIdFilter("")}
                  >
                    <Close tw="text-xl" />
                  </IconButton>
                )
              : undefined
          }
        />
        <button tw="text-sm underline" onClick={handleSelectAllSubStates}>
          Select All
        </button>
      </ListItem>
      <List css={[isValidating && tw`opacity-50 pointer-events-none`]}>
        {subStateList.map((substate) => (
          <ListItemButton
            dense
            key={substate.id}
            onClick={() => handleToggleSubState(substate.id)}
          >
            <ListItemIcon>
              <Checkbox
                tw="-my-2"
                checked={!!selection.includes(substate.id)}
              />
            </ListItemIcon>
            <ListItemText
              primary={<div tw="flex gap-2">{substate.name}</div>}
            />
          </ListItemButton>
        ))}
      </List>
    </>
  );
};

export default SubStateSelection;
