/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useEffect, useState } from "react";
import { ControllerProps, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import AddToPhotosIcon from "@mui/icons-material/AddToPhotos";
import CancelIcon from "@mui/icons-material/Cancel";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Popper from "@mui/material/Popper";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";

import { pick, startCase } from "lodash";
import PropTypes from "prop-types";

import { useTerritories } from "@features/territories";
import { useCreateUserMutation, useUpdateUserMutation } from "@features/users";
import { Brand } from "@models/Brand";
import { State } from "@models/State";
import { Territory } from "@models/Territory";
import { User } from "@models/User";

import { ALL_ROLES, SUPPLIER } from "../../constants/permissions";
import {
  clearFilteredStates,
  fetchStatesByIds,
} from "../../redux/slices/territories/territorySlice";
import {
  CheckboxInput,
  SelectInput,
  TextInput,
} from "../Forms/ControlledInputs";
import { StyledButton } from "../StyledComponents";
import BrandSelector from "../Utility/Selectors/BrandSelector";

const roleOptions = ALL_ROLES.map((role) => ({
  id: role,
  name: startCase(role),
}));

const TopPopper = (props) => <Popper {...props} placement="top" />;

const TerritorySelector = ({
  handleTerritories,
  territories,
  selectedTerritories,
}: {
  handleTerritories: (value: Territory[]) => void;
  territories: Territory[];
  selectedTerritories: Territory[];
}) => {
  const [currentTerritories, setCurrentTerritories] =
    useState<Territory[]>(selectedTerritories);

  useEffect(() => {
    if (currentTerritories.length !== selectedTerritories.length) {
      setCurrentTerritories(selectedTerritories);
    }
  }, [currentTerritories, selectedTerritories]);

  return (
    <Autocomplete
      style={{
        maxWidth: "Calc(100% - 47px)",
      }}
      multiple
      fullWidth
      autoHighlight
      id="user-territories"
      options={territories}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => option.name}
      onChange={(_evt, value) => handleTerritories(value)}
      value={currentTerritories}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label="Territory"
          size="small"
        />
      )}
    />
  );
};

const StateSelector = ({
  handleStates,
  filteredStates,
  loading,
  selectedStates,
}) => {
  const [currentStates, setCurrentStates] = useState(selectedStates);

  useEffect(() => {
    if (currentStates.length !== selectedStates.length) {
      setCurrentStates(selectedStates);
    }
  }, [currentStates, selectedStates]);

  return (
    <Autocomplete
      multiple
      fullWidth
      freeSolo
      autoHighlight
      id="st-by-territory-selector"
      options={filteredStates}
      disabled={filteredStates.length === 0}
      loading={loading}
      getOptionLabel={(option) => option.code}
      onChange={(_evt, value) => handleStates(value)}
      PopperComponent={TopPopper}
      value={currentStates}
      renderInput={(params) => (
        <TextField
          {...params}
          label="State"
          id="st-by-territory"
          variant="outlined"
          size="small"
          autoComplete="new-password"
          InputProps={{
            ...params.InputProps,
            autoComplete: "new-password",
            spellCheck: "false",
            autoCorrect: "off",
            endAdornment: <>{params.InputProps.endAdornment}</>,
          }}
        />
      )}
    />
  );
};

const SupplierSelector = ({ controlled }) => {
  const suppliers = useSelector((state: any) => state.suppliers.supplierList);
  const supplierOptions = suppliers.map(({ id, name }) => ({
    id,
    label: name,
  }));

  return (
    <SelectInput
      tw="w-full"
      label="Supplier"
      options={supplierOptions}
      {...controlled}
    />
  );
};

const defaultValues = {
  id: "" as string | null,
  name: "",
  email: "",
  role: "read-only",
  status: true,
  isRetail: false,
  isOnPremise: false,
  supplierId: "",
  hasLimitedBrandAccess: false,
};

const formValues = (user: Partial<User> = {}) => ({
  ...defaultValues,
  ...pick(user, Object.keys(defaultValues)),
  status: user.status ? (user.status === "active" ? true : false) : false,
  supplierId: user.supplier ? user.supplier.id : "",
});

const EditUserModal = ({
  modal,
  handleModalClose,
  user,
  type,
}: {
  modal: boolean;
  handleModalClose: () => void;
  user: User | null;
  type: "new" | "edit";
}) => {
  const dispatch = useDispatch();

  const updateUserMutation = useUpdateUserMutation();
  const createUserMutation = useCreateUserMutation();

  const mutationsPending =
    updateUserMutation.isPending || createUserMutation.isPending;

  const { control, reset, watch, handleSubmit } = useForm({
    defaultValues: user ? formValues(user) : defaultValues,
  });

  const [role] = watch(["role"]);
  const hasLimitedBrandAccess = watch("hasLimitedBrandAccess");
  const isSupplier = ["supplier", "planning-tool-supplier"].includes(role);

  const controlled = (name: string, rules?: ControllerProps["rules"]) => ({
    name,
    control,
    rules: { ...rules },
  });
  const [currentTerritories, setCurrentTerritories] = useState<Territory[]>([]);
  const [currentStates, setCurrentStates] = useState<State[]>([]);
  const [currentBrands, setCurrentBrands] = useState<Brand[]>([]);
  const [updateSuccess, setUpdateSuccess] = useState(false);

  const [error, setError] = useState<string | null>(null);

  const { data: territories = [], isLoading: isTerritoriesLoading } =
    useTerritories({
      isAwaitingCode: false,
    });

  const filteredStates = useSelector(
    (state: any) => state.territories.filteredStateList
  );
  const isStatesLoading = useSelector(
    (state: any) => state.territories.isStatesLoading
  );

  const handleTerritories = (value) => {
    setCurrentTerritories(value);
    if (value.length > 0) {
      dispatch(fetchStatesByIds(value.map((terr) => terr.id)));
    } else {
      dispatch(clearFilteredStates());
      setCurrentStates([]);
    }
  };

  const handleAllTerritories = () => {
    setCurrentTerritories([...territories]);
    dispatch(
      fetchStatesByIds(territories ? territories.map((terr) => terr.id) : [])
    );
  };

  const handleAllStates = () => setCurrentStates(filteredStates);

  const handleFormSubmit = async (data) => {
    if (!data.isRetail && !data.isOnPremise && role !== SUPPLIER) {
      return setError(
        "You must select at least one option for On Premise or Retail"
      );
    }
    if (
      (currentTerritories.length === 0 || currentStates.length === 0) &&
      role !== SUPPLIER
    ) {
      return setError(
        "You must assign a user to at least one territory and one state"
      );
    }
    if (isSupplier && !data.supplierId) {
      return setError("Users with supplier roles must be assigned a supplier");
    }
    setError(null);

    const userData = {
      ...data,
      status: Boolean(data.status) ? "active" : "inactive",
      states: currentStates.map((state) => ({
        type: "state",
        id: state.id,
      })),
      territories: currentTerritories.map((terr) => ({
        type: "territory",
        id: terr.id,
      })),
      brands: currentBrands.map((brand) => ({
        type: "brand",
        id: brand.id,
      })),
      relationshipNames: ["states", "territories", "brands"],
    };
    if (type === "edit") {
      updateUserMutation.mutate(userData, {
        onSuccess: () => setUpdateSuccess(true),
        onError: (error) => setError(`${error.message}`),
      });
    } else {
      createUserMutation.mutate(userData, {
        onSuccess: () => setUpdateSuccess(true),
        onError: (error) => setError(`Error creating user ${error.message}`),
      });
    }
  };

  useEffect(() => {
    if (type === "edit" && user) {
      reset(formValues({ ...user }));
      setCurrentTerritories(user.territories);
      setCurrentStates(user.states);
      setCurrentBrands(user.brands);

      if (user.territories.length > 0) {
        dispatch(fetchStatesByIds(user.territories.map((terr) => terr.id)));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, user]);

  useEffect(() => {
    dispatch(clearFilteredStates());
    setUpdateSuccess(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!hasLimitedBrandAccess) {
      setCurrentBrands([]);
    }
  }, [hasLimitedBrandAccess]);

  return (
    <div tw="relative">
      <Dialog
        open={modal}
        onClose={() => {
          reset(formValues());
          handleModalClose();
        }}
        fullWidth
        maxWidth="sm"
        disableScrollLock
      >
        <IconButton
          onClick={() => {
            reset(formValues());
            handleModalClose();
          }}
          size="large"
          tw="absolute top-0 right-0"
        >
          <CancelIcon fontSize="large" color="secondary" />
        </IconButton>
        <DialogTitle>
          {user?.id ? `User Id: ${user?.id}` : "New User"}
        </DialogTitle>

        {isTerritoriesLoading ? (
          <DialogContent>
            <div tw="w-full flex flex-col items-center justify-center min-h-[60vh]">
              <CircularProgress />
            </div>
          </DialogContent>
        ) : (
          <DialogContent>
            <div tw="space-y-4">
              <CheckboxInput label="Active" {...controlled("status")} />
              <div tw="font-semibold">User Information</div>
              <TextInput
                label="Name"
                fullWidth
                {...controlled("name", { required: true })}
              />
              <TextInput
                label="Email Address"
                fullWidth
                {...controlled("email", { required: true })}
              />
              <br />
              <div tw="font-semibold">User Role</div>
              <div tw="flex gap-4">
                <SelectInput
                  options={roleOptions}
                  tw="flex-1"
                  {...(controlled("role") as any)} // Couldn't figure out this typescript error
                />
                {role !== SUPPLIER && (
                  <div tw="flex-1">
                    <CheckboxInput label="Retail" {...controlled("isRetail")} />
                    <CheckboxInput
                      label="On Premise"
                      {...controlled("isOnPremise")}
                    />
                  </div>
                )}
              </div>
              {role !== SUPPLIER && (
                <>
                  <br />
                  <div tw="font-semibold">Region / Key Acct. Assignment</div>
                  <div tw="w-full flex items-center justify-between">
                    <TerritorySelector
                      handleTerritories={handleTerritories}
                      territories={territories ?? []}
                      selectedTerritories={currentTerritories}
                    />
                    <Tooltip title="Assign All">
                      <IconButton onClick={handleAllTerritories} size="large">
                        <AddToPhotosIcon color="secondary" />
                      </IconButton>
                    </Tooltip>
                  </div>
                  <div tw="font-semibold">State Assignment</div>
                  <div tw="w-full flex items-center justify-between">
                    <StateSelector
                      handleStates={setCurrentStates}
                      filteredStates={filteredStates}
                      loading={isStatesLoading}
                      selectedStates={currentStates}
                    />
                    <Tooltip title="Assign All">
                      <span>
                        <IconButton
                          disabled={currentTerritories.length === 0}
                          onClick={handleAllStates}
                          size="large"
                        >
                          <AddToPhotosIcon color="secondary" />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </div>
                </>
              )}
              {isSupplier && (
                <SupplierSelector controlled={controlled("supplierId")} />
              )}
              <div tw="w-full flex items-center justify-between">
                <div tw="w-full">
                  <CheckboxInput
                    label="Has Limited Brand Access"
                    {...controlled("hasLimitedBrandAccess")}
                    disabled={role === "super"}
                  />
                  {hasLimitedBrandAccess && (
                    <BrandSelector
                      handleSelectedPrimaryBrand={() => {}}
                      handleSelectedBrands={setCurrentBrands}
                      selectedBrands={currentBrands.map((brand) => ({
                        id: brand.id,
                        label: brand.name ?? "",
                      }))}
                      disabled={role === "super"}
                    />
                  )}
                </div>
              </div>
              {!mutationsPending && error && (
                <div style={{ color: "#920000" }}>{error}</div>
              )}
              {!mutationsPending && updateSuccess && (
                <div>
                  {type === "edit"
                    ? "User Updated Successfully!"
                    : "User Created Successfully!"}
                </div>
              )}
              <StyledButton
                cta
                onClick={handleSubmit(handleFormSubmit)}
                loading={mutationsPending}
              >
                SUBMIT
              </StyledButton>
            </div>
          </DialogContent>
        )}
      </Dialog>
    </div>
  );
};

EditUserModal.propTypes = {
  handleModalClose: PropTypes.func.isRequired,
  currentUserId: PropTypes.string,
  modal: PropTypes.bool.isRequired,
};

export default EditUserModal;
