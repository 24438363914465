/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";

import { CircularProgress } from "@mui/material";

import { Contained, StyledButton } from "src/components/StyledComponents";
import { setError } from "src/redux/slices/errorSlice";
import { setFiltersOpen } from "src/redux/slices/filterSlice";

import {
  OrderSetActionBar,
  OrderSetOverview,
  PreOrderHeader,
} from "@features/orders";
import { useCreateOrderSetMutation } from "@features/orders";
import OrderSetTable from "@features/orders/orderSet/OrderSetTable";
import { CurrentOrderSetContextProvider } from "@features/orders/orderSet/data/CurrentOrderSetContext";
import OrderSetId from "@features/orders/orderSet/data/OrderSetIDContext";
import { OrderSetBudgetImpactAccordion } from "@features/orders/orderSetOverview/OrderSetBudgetImpact";
import useOrderWindowPrograms from "@features/orders/preOrder/usePreOrderPrograms";

const PreOrder = () => {
  const dispatch = useDispatch();
  const { hash } = useLocation();
  const { programId, orderWindowId } = useParams();
  const { currentChannel: channel, currentTerritory } = useSelector(
    (state: any) => state.user
  );

  const { data, orderSetQuery, programsQuery } = useOrderWindowPrograms(
    orderWindowId as string
  );

  const createOrderSetMutation = useCreateOrderSetMutation();

  const selected = data.find(({ program }) => program.id === programId);

  const [orderSetId, setOrderSetId] = useState<null | string>(null);
  const osIdFromSelected = selected?.orderSet?.id ?? null;

  const onOrderSetDelete = () => {};

  const handleCreateOrderSet = () => {
    createOrderSetMutation.mutate(
      {
        type: "pre-order",
        channel,
        program: {
          id: programId,
        },
        orderCalendarMonth: {
          id: orderWindowId,
        },
        ...(currentTerritory
          ? {
              territory: {
                id: currentTerritory,
              },
            }
          : { allRegions: true }),
        relationshipNames: ["program", "orderCalendarMonth", "territory"],
      },
      {
        onSuccess: (data) => setOrderSetId(data.id),
        onError: (err) =>
          dispatch(setError({ error: err.message, source: "createOrderSet" })),
      }
    );
  };

  useEffect(() => {
    dispatch(setFiltersOpen({ open: false }));
  }, [dispatch]);

  useEffect(() => {
    setOrderSetId(osIdFromSelected);
  }, [osIdFromSelected]);

  if (programsQuery.isLoading) return <CircularProgress />;

  return (
    <OrderSetId.Provider value={orderSetId}>
      <div tw="bg-neutral-100 min-h-[calc(100vh - var(--header-height))]">
        <Contained tw="space-y-6 py-6">
          <PreOrderHeader />
          {selected && !orderSetId && (
            <div tw="rounded-lg bg-neutral-100 px-4 py-8">
              <div tw="text-xl text-neutral-700 mb-3">
                {orderSetQuery.isLoading
                  ? "Loading Orders..."
                  : "No order started for this program."}
              </div>
              {!orderSetQuery.isLoading && (
                <StyledButton
                  cta
                  onClick={handleCreateOrderSet}
                  loading={createOrderSetMutation.isPending}
                >
                  Start Pre-Order
                </StyledButton>
              )}
            </div>
          )}
          {!selected && (
            <div tw="rounded-lg bg-neutral-100 px-4 py-8">
              <div tw="text-xl text-neutral-700 mb-3">
                The program you've selected isn't available on this order window
              </div>
            </div>
          )}

          {orderSetId && (
            <CurrentOrderSetContextProvider onOrderSetDelete={onOrderSetDelete}>
              {hash === "#overview" ? (
                <OrderSetOverview />
              ) : (
                <>
                  <OrderSetBudgetImpactAccordion />
                  <OrderSetTable />
                  <OrderSetActionBar canDelete={false} />
                </>
              )}
            </CurrentOrderSetContextProvider>
          )}
        </Contained>
      </div>
    </OrderSetId.Provider>
  );
};

export default PreOrder;
