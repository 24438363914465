import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

import { format } from "date-fns";
import PropTypes from "prop-types";

const thisYear = new Date().getFullYear();

const options = Array(6)
  .fill(null)
  .map((_, i) => thisYear - 3 + i)
  .map((year) =>
    Array(12)
      .fill(null)
      .map((_, i) => [String(i + 1), year])
  )
  .flat()
  .map(([m, y]) => ({
    id: m + "-" + y,
    name: format(
      new Date(`${y}-${String(m).padStart(2, "0")}-02`),
      "yyyy MMMM"
    ),
  }));

const InMarketMonthAutoComplete = ({
  classes,
  handleChange,
  reset,
  setReset,
  filterType,
}) => {
  const [open, setOpen] = useState(false);
  const [year, setYear] = useState("");
  const [monthYears, setMonthYears] = useState([]);

  const currentFilter = useSelector(
    (state) => state.filters.inMarketMonthYears
  );
  const isGlobalLoading = useSelector((state) => state.globalLoad.isLoading);

  const handleMonths = (value) => {
    setMonthYears(value);
  };

  useEffect(() => {
    if (currentFilter.length === 0) {
      setYear("");
      setMonthYears([]);
      setReset(false);
    }
  }, [currentFilter]);

  useEffect(() => {
    if (reset) {
      setYear("");
      setMonthYears([]);
      setReset(false);
    }
  }, [reset, setYear, setReset]);

  return (
    <>
      <Autocomplete
        multiple
        autoHighlight
        renderTags={() => null}
        fullWidth
        className={classes.queryField}
        classes={{
          popper: classes.liftedPopper,
        }}
        id="month-year-auto-complete"
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        inputValue={year}
        onInputChange={(_evt, value) => setYear(value)}
        onChange={(_evt, value) => {
          console.log(value);
          handleChange(value, "inMarketMonthYears", filterType);
          handleMonths(value);
        }}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(op) => op.name}
        options={options}
        value={monthYears}
        disabled={isGlobalLoading}
        renderInput={(params) => (
          <TextField
            {...params}
            label="In Market Month"
            id="in-market-month-filter"
            variant="outlined"
            size="small"
            InputProps={{
              ...params.InputProps,
              endAdornment: <>{params.InputProps.endAdornment}</>,
            }}
          />
        )}
      />
    </>
  );
};

InMarketMonthAutoComplete.propTypes = {
  classes: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  reset: PropTypes.bool.isRequired,
  setReset: PropTypes.func.isRequired,
  filterType: PropTypes.string.isRequired,
};

export default InMarketMonthAutoComplete;
