import React, { useState } from "react";
import { Link } from "react-router-dom";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import IconButton from "@mui/material/IconButton";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
//npm import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

import getYear from "date-fns/getYear";
import PropTypes from "prop-types";

//import NestedMenuItem from "./NestedMenuItem.js";

const DrawerItemsNav = ({ role, classes }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpen = (evt) => {
    setAnchorEl(evt.target);
    evt.stopPropagation(); //to ensure menu renders only above parent element
  }; //tried to handle these events at top level (TopDrawerNav) but was rendering all menus at once : (

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        style={{ padding: 0 }}
        onClick={(evt) => {
          handleOpen(evt);
          evt.stopPropagation();
        }}
        size="large"
      >
        <Typography variant="h5" className={classes.navigationText}>
          Items
        </Typography>
        <ExpandMoreIcon fontSize="large" className={classes.expandMoreIcon} />
      </IconButton>
      <Menu
        classes={{ paper: classes.menuBackground }}
        disableScrollLock
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        style={{
          marginTop: "10px",
          zIndex: "3000",
        }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose} component={Link} to="/items/all">
          <ListItemText
            primaryTypographyProps={{
              className: classes.headerListItemNew,
            }}
            primary={`${getYear(new Date())} Item Catalog`}
          />
        </MenuItem>
        <MenuItem onClick={handleClose} component={Link} to="/items/archive">
          <ListItemText
            primaryTypographyProps={{
              className: classes.headerListItemNew,
            }}
            primary="Archived Items"
          />
        </MenuItem>
        <MenuItem onClick={handleClose} component={Link} to="/historical-items">
          <ListItemText
            primaryTypographyProps={{
              className: classes.headerListItemNew,
            }}
            primary="C2M Archive"
          />
        </MenuItem>
      </Menu>
    </>
  );
};

DrawerItemsNav.propTypes = {
  classes: PropTypes.object.isRequired,
  role: PropTypes.string.isRequired,
};

export default DrawerItemsNav;
