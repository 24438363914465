import { StyledButton } from "src/components/StyledComponents";

import { useCurrentOrderSet } from "../orderSet/data/CurrentOrderSetContext";

const SetIsWorkInProgressButton = ({
  toggleWorkInProgress,
  isUpdateLoading,
}) => {
  const { orderSet } = useCurrentOrderSet();
  return (
    <StyledButton
      cta
      fullWidth
      onClick={toggleWorkInProgress}
      loading={isUpdateLoading}
    >
      {orderSet.isWorkComplete ? "Mark In-Progress" : "Mark as Complete"}
    </StyledButton>
  );
};

export default SetIsWorkInProgressButton;
