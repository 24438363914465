import React, { useEffect, useState } from "react";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
import Helmet from "react-helmet";
import { useDispatch, useSelector } from "react-redux";

import { GetApp } from "@mui/icons-material";
import { Button, Container, LinearProgress, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import HideFiltersButtonToggle from "src/components/Filtering/HideFiltersButtonToggle";

import { CSVLink } from "@utils/csv";

import FilterChipList from "../components/Filtering/FilterChipList";
import C2MOrderHistoryTable from "../components/OrderHistory/C2MOrderHistoryTable";
import { useInitialFilters } from "../hooks/UtilityHooks";
import {
  fetchC2MOrderHistoryReport,
  fetchNextC2MOrderHistory,
  resetHistoryReport,
  setTriggerCSVFalse,
} from "../redux/slices/c2mData/c2mOrderHistorySlice";

/*
This page allows users to view order history from C2M. All orders were not placed in RTA, and
there is no editing functionality available for these orders.
*/

const csvHeaders = [
  { label: "Sequence Number", key: "itemNumber" },
  { label: "Order Type", key: "orderType" },
  { label: "Order Number", key: "orderNumber" },
  { label: "Ordered By", key: "orderedBy" },
  { label: "Ship to Region", key: "territory" },
  { label: "Entity", key: "entity" },
  { label: "Key Account", key: "keyAccount" },
  { label: "Theme Name", key: "themeName" },
  { label: "Program Name", key: "programName" },
  { label: "BU", key: "businessUnit" },
  { label: "Brand(s)", key: "brands" },
  { label: "Item Type", key: "itemType" },
  { label: "Item Description", key: "itemDescription" },
  { label: "ABN", key: "abn" },
  { label: "Distributor Name", key: "distributorName" },
  { label: "Address1", key: "streetAddressOne" },
  { label: "Address2", key: "streetAddressTwo" },
  { label: "City", key: "city" },
  { label: "State", key: "stateCode" },
  { label: "Zip", key: "zip" },
  { label: "Gallo State Subledger", key: "galloStateSubledger" },
  { label: "Total Quantity", key: "qty" },
  { label: "Item Price", key: "itemPrice" },
  { label: "C2M Displayed Cost", key: "c2mDisplayedCost" },
  { label: "Status", key: "complianceStatus" },
  { label: "In Market Date", key: "inMarketDate" },
  { label: "Order Date", key: "orderDate" },
  { label: "Shipped Date", key: "shippedDate" },
  { label: "Order Status", key: "orderStatus" },
];

const defaultFilters = {
  itemNumber: "",
  orderNum: "",
  c2mTerritory: "",
  c2mProgramName: "",
  c2mBrands: "",
  c2mBusinessUnit: "",
  c2mDistributor: "",
  c2mOrderDateFrom: null,
  c2mOrderDateTo: null,
  c2mInMarketDateFrom: null,
  c2mInMarketDateTo: null,
  filterType: "c2m-order-history",
};

const useStyles = makeStyles((theme) => ({
  ...theme.global,
}));

const C2MOrderHistory = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [currentCSV, setCurrentCSV] = useState({ data: [], headers: [] });

  const {
    isLoading,
    isNextLoading,
    nextLink,
    history,
    historyReport,
    triggerCSVDownload,
  } = useSelector((state) => state.c2mOrderHistory);
  const filters = useSelector((state) => state.filters);

  const handleBottomScroll = () => {
    if (nextLink && !isNextLoading) {
      if (scrollRef.current && scrollRef.current.scrollTop !== 0) {
        dispatch(fetchNextC2MOrderHistory(nextLink));
      }
    }
  };

  const scrollRef = useBottomScrollListener(handleBottomScroll, {
    offset: 500,
    debounceOptions: {
      leading: true,
      trailing: false,
    },
  });

  const handleReport = () => dispatch(fetchC2MOrderHistoryReport(filters));

  useEffect(() => {
    if (
      triggerCSVDownload &&
      currentCSV.data.length === 0 &&
      historyReport.length > 0
    ) {
      setCurrentCSV({ data: historyReport, headers: csvHeaders });
    }
  }, [currentCSV.data.length, historyReport, triggerCSVDownload]);

  useEffect(() => {
    if (!triggerCSVDownload && currentCSV.data.length > 0) {
      setCurrentCSV({
        data: [],
        headers: [],
      });
    }
  }, [triggerCSVDownload, currentCSV, setCurrentCSV]);

  useInitialFilters("c2m-order-history", defaultFilters);

  useEffect(() => {
    dispatch(setTriggerCSVFalse());
    dispatch(resetHistoryReport());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>RTA | C2M Order History</title>
      </Helmet>
      <Container className={classes.mainWrapper}>
        <div className={classes.titleBar}>
          <Typography className={classes.titleText}>
            C2M Order History
          </Typography>
          <div className={classes.innerConfigDiv}>
            {!triggerCSVDownload && currentCSV.data.length === 0 && (
              <span>
                <Button
                  className={classes.largeButton}
                  variant="contained"
                  onClick={handleReport}
                  disabled={history.length === 0}
                >
                  GENERATE REPORT
                </Button>
              </span>
            )}
            {triggerCSVDownload && currentCSV.data.length > 0 && (
              <CSVLink
                data={currentCSV.data}
                headers={currentCSV.headers}
                filename="rta_c2m_history.csv"
              >
                <Button
                  className={classes.largeButton}
                  variant="contained"
                  color="secondary"
                  startIcon={<GetApp />}
                >
                  EXPORT REPORT
                </Button>
              </CSVLink>
            )}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignContent: "center",
            marginBottom: "10px",
          }}
        >
          <HideFiltersButtonToggle />
          <FilterChipList classes={classes} />
          <br />
        </div>
        <C2MOrderHistoryTable
          history={history}
          isLoading={isLoading}
          scrollRef={scrollRef}
        />
        {isNextLoading && (
          <div style={{ width: "100%" }}>
            <LinearProgress />
          </div>
        )}
        {!isNextLoading && <div style={{ width: "100%", height: "4px" }}></div>}
      </Container>
    </>
  );
};

export default C2MOrderHistory;
