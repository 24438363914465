import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";

import HideFiltersButtonToggle from "src/components/Filtering/HideFiltersButtonToggle";

import FilterChipList from "../components/Filtering/FilterChipList";
import CurrentPrograms from "../components/Ordering/CurrentPrograms";
import FocusPDFModal from "../components/Utility/Modals/FocusPDFModal";
import StepperLoader from "../components/Utility/StepperLoader";
import { useInitialFilters } from "../hooks/UtilityHooks";
import { useProgramSort } from "../hooks/UtilityHooks";
import { resetFocusPDFItems } from "../redux/slices/programs/programsSlice";

/*
The programs page holds all active programs in the current order window. A program is considered active
if the current date lies within the programs order window.
*/

const useStyles = makeStyles((theme) => ({
  ...theme.global,
}));

const Programs = ({ orderWindowType }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [programFilters, setProgramFilters] = useState([]);
  const [isPDFModalOpen, setPDFModalOpen] = useState(false);

  const activePrograms = useSelector((state) => state.programs.programs);
  const isLoading = useSelector((state) => state.programs.isLoading);
  const sortOption = useSelector((state) => state.filters.sortProgramsBy);
  const buFilters = useSelector((state) => state.filters.bu);
  const monthFilters = useSelector((state) => state.filters.month);
  const brandFilter = useSelector((state) => state.filters.brand);
  const currentUserRole = useSelector((state) => state.user.role);
  const isStepperLoad = useSelector(
    (state) => state.globalLoad.isProgStepperLoad
  );

  const currentPrograms = useProgramSort(
    activePrograms,
    sortOption,
    programFilters
  );

  const handleModalClose = () => {
    setPDFModalOpen(false);
    dispatch(resetFocusPDFItems());
  };

  const defaultFilters = {
    bu: [],
    month: [],
    brand: [],
    sortProgramsBy: "brand",
    orderWindowType,
  };

  useInitialFilters("program", defaultFilters);

  useEffect(() => {
    if (
      brandFilter.length === 0 &&
      buFilters.length === 0 &&
      monthFilters.length === 0
    ) {
      setProgramFilters([]);
    } else {
      setProgramFilters(
        brandFilter
          .map((a) => ({ type: "brand", value: a.name }))

          .concat(buFilters.map((b) => ({ type: "unit", value: b })))
          .concat(monthFilters.map((c) => ({ type: "focusMonth", value: c })))
      );
    }
  }, [brandFilter, buFilters, monthFilters, setProgramFilters]);

  if (isStepperLoad) {
    return <StepperLoader isProgStepper={true} />;
  }

  return (
    <>
      {activePrograms.length > 0 && isPDFModalOpen && (
        <FocusPDFModal
          open={isPDFModalOpen}
          handleClose={handleModalClose}
          programs={activePrograms}
        />
      )}
      <Helmet>
        <title>RTA | Programs</title>
      </Helmet>
      <Container className={classes.mainWrapper}>
        <div className={classes.titleBar}>
          <Typography className={classes.titleText}>
            {orderWindowType === "national"
              ? "National Pre-Orders"
              : "Special Order Window"}
          </Typography>

          {currentUserRole !== "compliance" &&
            currentUserRole !== "read-only" && (
              <div className={classes.configButtons}>
                <div className={classes.innerConfigDiv}>
                  {currentUserRole === "super" && (
                    <Button
                      className={classes.largeButton}
                      variant="contained"
                      color="secondary"
                      style={{ marginRight: "10px" }}
                      onClick={() => setPDFModalOpen(true)}
                    >
                      GENERATE LAUNCH PDF
                    </Button>
                  )}
                  <Button
                    className={classes.largeButton}
                    variant="contained"
                    color="secondary"
                    startIcon={<ExitToAppIcon />}
                    component={Link}
                    to={`/orders/${activePrograms[0]?.orderMonthId}/${activePrograms[0]?.id}`}
                    disabled={activePrograms.length === 0}
                  >
                    PLACE ORDERS
                  </Button>
                </div>
              </div>
            )}
        </div>
        <br />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignContent: "center",
            marginBottom: "10px",
          }}
        >
          <HideFiltersButtonToggle />
          <FilterChipList classes={classes} />
          <br />
        </div>
        {isLoading && <CircularProgress color="inherit" />}
        {!isLoading && activePrograms.length > 0 && (
          <CurrentPrograms
            currentPrograms={currentPrograms}
            orderWindowType={orderWindowType}
          />
        )}
        {!isLoading && activePrograms.length === 0 && (
          <div
            style={{
              width: "100%",
              height: "60vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography className={classes.headerText}>
              There are currently no active Pre Order Programs ...
            </Typography>
          </div>
        )}
      </Container>
    </>
  );
};

export default Programs;
