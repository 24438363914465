/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useState } from "react";
import { useDispatch } from "react-redux";

import axios from "axios";
import BackButton from "src/components/Reporting/BackButton";

import { StyledButton } from "../components/StyledComponents";
import SingleSupplierAutoComplete from "../components/Utility/AutoCompleteFields/SingleSupplierAutoComplete";
import { setError } from "../redux/slices/errorSlice";
import { saveAsCsv } from "../utility/utilityFunctions";

const ReportProductList = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [selectedSupplier, setSelectedSupplier] = useState(null);

  const handleReport = async () => {
    try {
      setLoading(true);
      let reportUrl = `/api/product-list-report`;
      if (selectedSupplier)
        reportUrl += `?filter[supplier-id]=${selectedSupplier.id}`;
      const { data } = await axios.get(reportUrl);
      saveAsCsv(data, `product-list-report.csv`);
    } catch (e) {
      dispatch(
        setError({ error: e.message, source: "Fetch product list report" })
      );
      console.error(e);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <div tw="flex justify-end p-2">
        <BackButton />
      </div>
      <div tw="flex flex-col items-center space-y-8">
        <h1 tw="font-bold text-2xl text-neutral-800">Product List Report</h1>
        <div tw="flex gap-4 justify-center w-full">
          <SingleSupplierAutoComplete
            tw="max-w-xs"
            label="Supplier (Optional)"
            classes={{}}
            initialValue={selectedSupplier}
            handleChange={setSelectedSupplier}
            reset={false}
            setReset={() => {}}
          />
          <StyledButton
            tw="flex-shrink-0"
            cta
            onClick={handleReport}
            loading={loading}
          >
            Generate Report
          </StyledButton>
        </div>
      </div>
    </>
  );
};

export default ReportProductList;
