import tw, { css, styled } from "twin.macro";

import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import PropTypes from "prop-types";

import { updateItemTypeById } from "../../redux/slices/items/itemTypeSlice";
import { intValidation } from "../../utility/inputHelpers";
import {
  CheckboxInput,
  SelectInput,
  TextInput,
} from "../Forms/ControlledInputs";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
}));

const ItemTypeModal = ({ open, handleClose, id, itemTypeList }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isUpdateLoading } = useSelector((state) => state.itemTypes);
  const [currentItemType] = useState(
    itemTypeList.filter((item) => {
      return id === item.id;
    })[0]
  );

  const controlled = (name, rules) => ({
    name,
    rules,
    control,
  });

  const { register, handleSubmit, control } = useForm({
    defaultValues: {
      description: currentItemType ? currentItemType.name : "",
      itemTypeCode: currentItemType ? currentItemType.externalId : "",
      packSize: currentItemType ? currentItemType.packSize : "",
      leadTime: currentItemType ? currentItemType.leadTime : "",
      categoryCode: currentItemType ? currentItemType.categoryCode : "",
      specType: currentItemType ? currentItemType.specType : "",
      posType: currentItemType ? currentItemType.posType : "",
      isActive: currentItemType ? currentItemType.isActive : "",
      rtaDeployment: currentItemType ? currentItemType.rtaDeployment : "",
      sendToContentHub: currentItemType ? currentItemType.sendToContentHub : "",
    },
  });

  const onSubmit = (data) => {
    if (currentItemType) {
      dispatch(
        updateItemTypeById({
          id: currentItemType.id,
          ...data,
        })
      );
    }
  };

  const categoryCodeOptions = [
    { label: "ADV", id: "ADV" },
    { label: "CAS", id: "CAS" },
    { label: "COU", id: "COU" },
    { label: "DSS", id: "DSS" },
    { label: "NEC", id: "NEC" },
    { label: "RAS", id: "RAS" },
    { label: "SDS", id: "SDS" },
    { label: "UNK", id: "UNK" },
  ];

  const specTypeOptions = [
    { label: "None", id: "" },
    { label: "Description", id: "Description" },
    { label: "Spec", id: "Spec" },
  ];

  const posTypeOptions = [
    { label: "Coupon", id: "Coupon" },
    { label: "Digital", id: "Digital" },
    { label: "Structure", id: "Structure" },
    { label: "Mass/Pallet Display", id: "Mass/Pallet Display" },
    { label: "Wearables", id: "Wearables" },
    { label: "On Premise", id: "On Premise" },
    { label: "Merch Vehicle", id: "Merch Vehicle" },
    { label: "Paper", id: "Paper" },
    { label: "MISC", id: "MISC" },
    { label: "Sign", id: "Sign" },
    { label: "Gift Box/Gift Bag", id: "Gift Box/Gift Bag" },
  ];

  return (
    <div className={classes.relativeContainer}>
      <Dialog
        open={open}
        onClose={() => {
          handleClose();
        }}
        fullWidth
      >
        <DialogTitle>
          <Typography className={classes.headerText}>
            {currentItemType
              ? `Editing ${currentItemType.name}`
              : "New Item Type"}
          </Typography>
        </DialogTitle>

        <DialogContent tw="mt-2">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "15px",
                mt: 1,
              }}
            >
              <TextInput
                name="description"
                label="Description"
                fullWidth
                disabled={currentItemType ? true : false}
                {...controlled("description", { required: true })}
              />
              <TextInput
                name="itemTypeCode"
                label="Item Type Code"
                fullWidth
                disabled={currentItemType ? true : false}
                {...controlled("itemTypeCode", { required: true })}
              />
              <TextInput
                name="packSize"
                label="Pack Size"
                fullWidth
                disabled={currentItemType ? true : false}
                {...controlled("packSize", {
                  required: true,
                  ...intValidation,
                })}
              />
              <TextInput
                name="leadTime"
                label="Lead Time in Days"
                fullWidth
                {...controlled("leadTime", {
                  required: true,
                  ...intValidation,
                })}
              />
              <SelectInput
                label="Category Code"
                options={categoryCodeOptions}
                control={control}
                fullWidth
                {...register("categoryCode")}
              />
              <SelectInput
                label="Spec Type"
                options={specTypeOptions}
                control={control}
                fullWidth
                {...register("specType")}
              />
              <SelectInput
                label="POS Type"
                options={posTypeOptions}
                control={control}
                fullWidth
                {...register("posType")}
              />
              <CheckboxInput
                label="RTA Deployment?"
                control={control}
                {...controlled("rtaDeployment")}
              />
              <CheckboxInput
                label="Send to Content Hub?"
                control={control}
                {...controlled("sendToContentHub")}
              />
              <Button
                className={classes.largeButton}
                variant="contained"
                color="secondary"
                id="profile"
                fullWidth
                onClick={handleSubmit(onSubmit)}
              >
                {isUpdateLoading ? <CircularProgress /> : "SUBMIT"}
              </Button>
            </Box>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

ItemTypeModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  id: PropTypes.string,
  itemTypeList: PropTypes.array,
};

export default React.memo(ItemTypeModal);
