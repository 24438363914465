import React from "react";
import { useDispatch, useSelector } from "react-redux";

import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";

import {
  setChips,
  setClear,
  setClearFreeType,
  setSorted,
  updateSingleFilter,
} from "../../redux/slices/filterSlice";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
}));

const mappedValues = [
  "on-demand",
  "pre-order",
  "ad-hoc",
  "prior-approval",
  "coupon-prior-approval",
  "internal-approval",
  "item-type",
  "metal-wood",
  "pricing",
  "coupon-offer-type",
  "coupon-item-type",
  "coupon-face-value",
  "national",
  "secondary",
];

const freeTypeFields = [
  "poNum",
  "rfpNum",
  "itemNumber",
  "itemDesc",
  "orderNum",
  "supplierReference",
  "c2mTerritory",
  "c2mProgramName",
  "c2mBrands",
  "c2mBusinessUnit",
  "c2mDistributor",
  "workfrontId",
  "programId",
  "couponBarcodeId",
  "budgetId",
];

const channelMap = {
  on_premise: "On Premise",
  retail: "Retail",
};

const FilterChipList = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const filterState = useSelector((state) => state.filters);
  const filterType = useSelector((state) => state.filters.filterType);
  const ocms = useSelector((state) => state.orderCalendarMonths.all);

  const handleLabel = (value, type) => {
    if (type === "type" && !isNaN(Number(value))) {
      return ocms.find((ocm) => ocm.id === value)?.orderWindowName;
    }
    if (value === "inventory" || value === "from-inventory") {
      return "Inventory";
    } else if (value === "to-inventory") {
      return "Restock";
    } else if (value === "on_premise" || value === "retail") {
      return channelMap[value];
    } else if (mappedValues.includes(value)) {
      return value
        .split("-")
        .map((v) => v[0].toUpperCase() + v.slice(1))
        .join(" ");
    } else return value;
  };

  const handleChipClick = (type, value) => {
    let dispatchObject = { filter: type, value: null };
    if (type === "month") {
      let currentFilterArray = filterState[type].filter((f) => f !== value);
      dispatchObject.value = currentFilterArray;
    }
    if (type === "itemOrderType" || type === "orderType" || type === "type") {
      if (filterType === "history-rollup" && type === "type") {
        dispatchObject.value = "all-orders";
      } else dispatchObject.value = "all";
    }

    if (
      [
        "bu",
        "brand",
        "user",
        "address",
        "territory",
        "exclusiveTerritory",
        "supplier",
        "purchaser",
        "programType",
        "inMarketYear",
        "inMarketMonthYears",
        "budgets",
      ].includes(type)
    ) {
      let currentFilterArray = filterState[type].filter(
        (f) => f.name !== value
      );
      dispatchObject.value = currentFilterArray;
    }
    if (type === "itemType") {
      let currentFilterArray = filterState[type].filter((f) => {
        return f.name !== value;
      });
      dispatchObject.value = currentFilterArray;
    }
    if (type === "program") {
      let currentFilterArray = filterState[type].filter(
        (f) => !value.startsWith(f.id)
      );
      dispatchObject.value = currentFilterArray;
    }
    if (type === "programType") {
      let currentFilterArray = filterState[type].filter(
        (f) => f.description !== value
      );
      dispatchObject.value = currentFilterArray;
    }
    if (type === "stateIds") {
      let currentFilterArray = filterState[type].filter(
        (f) => f.code !== value
      );
      dispatchObject.value = currentFilterArray;
    }
    if (type === "favItems") {
      dispatchObject.value = [];
    }
    if (freeTypeFields.includes(type))
      dispatch(setClearFreeType({ value: [type] }));
    dispatch(updateSingleFilter(dispatchObject));
    dispatch(setChips({ filterType: filterType }));
    dispatch(setSorted());
  };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {filterState.chipList.map((filter) =>
        filter.type !== "orderWindowMonthYear" ? (
          <Chip
            style={{ margin: "auto 2.5px" }}
            color="primary"
            key={`${filter.type}-${filter.value}`}
            label={handleLabel(filter.value, filter.type)}
            onDelete={() => handleChipClick(filter.type, filter.value)}
          />
        ) : (
          <Chip
            style={{ margin: "auto 2.5px" }}
            color="primary"
            key={`${filter.type}-${filter.value.name}`}
            label={handleLabel(filter.value.name, filter.type)}
            onDelete={() => handleChipClick(filter.type, filter.value.name)}
          />
        )
      )}
      {filterState.chipList.length > 0 && (
        <Typography
          variant="body2"
          color="textSecondary"
          className={classes.hoverText}
          style={{ marginLeft: "20px" }}
          onClick={() => {
            dispatch(setClear());
          }}
        >
          Clear Filters
        </Typography>
      )}
    </div>
  );
};

export default FilterChipList;
