import React from "react";
import { useSelector } from "react-redux";

import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import PropTypes from "prop-types";

const AddressBookTableHead = ({ classes, type }) => {
  let headCells = [
    { id: "id", label: type === "custom" ? "ID" : "ABN" },
    { id: "name", label: "Name" },
    { id: "phoneNumber", label: "Phone Number" },
    { id: "streetAddressOne", label: "Street Address One" },
    { id: "streetAddressTwo", label: "Street Address Two" },
    { id: "attn", label: "Attention" },
    { id: "city", label: "City" },
    { id: "state", label: "State" },
    { id: "zip", label: "Zip" },
    { id: "country", label: "Country" },
  ];

  if (type === "distributor")
    headCells = headCells.concat({
      id: "hasChangeRequests",
      label: "Changes Requested",
    });

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => {
          return (
            <TableCell
              className={classes.headerText}
              key={headCell.id}
              align="left"
            >
              {headCell.label}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

AddressBookTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
};

const useStyles = makeStyles((theme) => ({
  ...theme.global,
  clickableRow: {
    "&&:hover": {
      cursor: "pointer",
    },
  },
}));

const AddressBookTable = ({
  handleAddressClick,
  addresses,
  isLoading,
  scrollRef,
  type,
}) => {
  const classes = useStyles();

  const { role } = useSelector((state) => state.user);

  return (
    <>
      <TableContainer
        style={{ maxHeight: "Calc(100vh - 275px)" }}
        ref={scrollRef}
      >
        <Table stickyHeader className={classes.table}>
          <AddressBookTableHead classes={classes} type={type} role={role} />
          <TableBody>
            {!isLoading && addresses.length === 0 && (
              <TableRow>
                <TableCell align="left" colSpan={7}>
                  <Typography className={classes.headerText}>
                    {`There are currently no addresses that match your search..`}
                  </Typography>
                </TableCell>
              </TableRow>
            )}
            {!isLoading &&
              addresses.length > 0 &&
              addresses.map((row) => {
                if (row.id !== "1" && row.id !== "2") {
                  return (
                    <TableRow
                      key={row.id}
                      hover
                      className={classes.clickableRow}
                      onClick={() => {
                        handleAddressClick(
                          row.id,
                          type === "distributor" ? "change-request" : "edit"
                        );
                      }}
                    >
                      <TableCell align="left">{row.addDisplayId}</TableCell>
                      <TableCell align="left">{row.name}</TableCell>
                      <TableCell align="left">
                        {row.phoneNumber ?? "---"}
                      </TableCell>
                      <TableCell align="left">{row.streetAddressOne}</TableCell>
                      <TableCell align="left">{row.streetAddressTwo}</TableCell>
                      <TableCell align="left">{row.attn}</TableCell>
                      <TableCell align="left">{row.city}</TableCell>
                      <TableCell align="left">{row.state.code}</TableCell>
                      <TableCell align="left">{row.zip}</TableCell>
                      <TableCell align="left">{row.country}</TableCell>
                      {type === "distributor" && (
                        <TableCell align="left">
                          {row.hasChangeRequests ? "Yes" : "No"}
                        </TableCell>
                      )}
                    </TableRow>
                  );
                } else return null;
              })}
            {isLoading && (
              <TableRow>
                <TableCell align="left" colSpan={6}>
                  <CircularProgress />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

AddressBookTable.propTypes = {
  handleAddressClick: PropTypes.func,
  addresses: PropTypes.array,
  isLoading: PropTypes.bool,
};

export default AddressBookTable;
