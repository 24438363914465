import React from "react";
import { useNavigate } from "react-router-dom";

import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import PropTypes from "prop-types";

import { formatMoney } from "../../utility/utilityFunctions";
import ImageWrapper from "../Utility/ImageWrapper";

const headCells = [
  { id: "preview", label: "Preview" },
  { id: "itemNumber", label: "Sequence #" },
  { id: "brand", label: "Brand" },
  { id: "itemType", label: "Item Type" },
  { id: "packSize", label: "Pack Size" },
  { id: "stock", label: "On Hand" },
  { id: "estCost", label: "Est. Cost" },
];

const ItemTableHead = ({ classes }) => {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            className={classes.headerText}
            key={headCell.id}
            align="left"
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

ItemTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
};

const useStyles = makeStyles((theme) => ({
  ...theme.global,
}));

const ShelfInventoryTable = ({
  currentItems,
  isItemsLoading,
  handlePreview,
  scrollRef,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleClick = (id) => {
    navigate(`/shelf-inventory/${id}`);
  };

  return (
    <>
      <TableContainer
        className={classes.tableContainer}
        style={{ maxHeight: "Calc(100vh - 250px)" }}
        ref={scrollRef}
      >
        <Table className={classes.table} aria-label="item-catalog" stickyHeader>
          <ItemTableHead classes={classes} />
          <TableBody>
            {!isItemsLoading && currentItems.length === 0 && (
              <TableRow>
                <TableCell align="left" colSpan={7}>
                  <Typography className={classes.headerText}>
                    {`There are no items that match the current search criteria..`}
                  </Typography>
                </TableCell>
              </TableRow>
            )}
            {!isItemsLoading &&
              currentItems.length > 0 &&
              currentItems.map((item, index) => (
                <TableRow
                  key={item.id}
                  className={classes.orderHistoryRow}
                  onClick={() => handleClick(item.id)}
                  hover
                >
                  <TableCell align="left">
                    <ImageWrapper
                      id={item.itemNumber}
                      imgClass={classes.previewImageFloat}
                      alt={item.itemType}
                      imgUrl={item.imgUrlThumb}
                      handleClick={() => handlePreview(item.itemNumber)}
                    />
                  </TableCell>
                  <TableCell align="left">{item.itemNumber}</TableCell>
                  {item.brand !== "---" && item.brand.length > 1 ? (
                    <Tooltip
                      placement="left"
                      title={`${item.brand.join(", ")}`}
                    >
                      <TableCell align="left">
                        {item.brand[0]}
                        <MoreHorizIcon
                          fontSize="small"
                          color="inherit"
                          style={{ float: "right" }}
                        />
                      </TableCell>
                    </Tooltip>
                  ) : (
                    <TableCell align="left">
                      {item.brand !== "---" ? item.brand[0] : "---"}
                    </TableCell>
                  )}
                  <TableCell align="left">{item.itemType}</TableCell>
                  <TableCell align="left">{item.packSize}</TableCell>
                  <TableCell align="left">{item.stock}</TableCell>
                  <TableCell>{`${formatMoney(item.estCost, false)}`}</TableCell>
                </TableRow>
              ))}
            {isItemsLoading && (
              <TableRow>
                <TableCell align="left" colSpan={9}>
                  <CircularProgress />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

ShelfInventoryTable.propTypes = {
  currentItems: PropTypes.array,
  isItemsLoading: PropTypes.bool.isRequired,
  handlePreview: PropTypes.func.isRequired,
  scrollRef: PropTypes.any,
};

export default ShelfInventoryTable;
