/** @jsxImportSource @emotion/react */
import "twin.macro";

import StatusChip from "./StatusChip";

// import React from 'react'

const SaveStatusChip = ({ isSaved, isSaving }) => {
  const status = isSaving ? "info" : isSaved ? "success" : "warning";
  const label = isSaving ? "Saving..." : isSaved ? "Saved" : "Modified";
  return <StatusChip status={status} label={label} />;
};

export default SaveStatusChip;
