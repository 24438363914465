/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import makeStyles from "@mui/styles/makeStyles";

import { ResourceAutocomplete } from "@utils/forms";

import ReduxReportGenerator from "../components/Reporting/ReduxReportGenerator";
import { WarehouseSelector } from "../components/Reporting/ReportSelectors";
import { getInventoryItemReport } from "../redux/slices/reports/reportSlice";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
  ...theme.reports,
}));

const ReportInventory = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [currentWarehouse, setCurrentWarehouse] = useState("all");
  const [territory, setTerritory] = useState(null);
  const currentChannel = useSelector((state) => state.user.currentChannel);

  const handleReset = () => {
    setCurrentWarehouse("all");
    setTerritory(null);
  };

  const handleGetReport = () => {
    const params = {
      filter: {
        territoryId: territory?.id,
        warehouse: currentWarehouse === "all" ? null : currentWarehouse,
        channel: currentChannel,
      },
    };
    dispatch(getInventoryItemReport(params));
  };

  return (
    <>
      <ReduxReportGenerator
        reportTitle={"Item Inventory Report"}
        fileName={"inventory.csv"}
        reportResetFunc={handleReset}
        reportGetFunc={handleGetReport}
      >
        <div className={classes.searchComponents}>
          <WarehouseSelector
            currentWarehouse={currentWarehouse}
            setCurrentWarehouse={setCurrentWarehouse}
            classes={classes}
          />
          <ResourceAutocomplete
            tw="w-48"
            label="Territory"
            resource="territories"
            requestParams={{
              filter: {
                isActive: true,
                isAwaitingCode: false,
              },
            }}
            value={territory}
            onChange={(_, value) => setTerritory(value)}
          />
        </div>
      </ReduxReportGenerator>
    </>
  );
};

export default ReportInventory;
