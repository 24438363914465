import { useEffect, useRef, useState } from "react";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
import Helmet from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";

import GetAppIcon from "@mui/icons-material/GetApp";
import PrintIcon from "@mui/icons-material/Print";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";

import "date-fns";
import addDays from "date-fns/addDays";
import format from "date-fns/format";
import HideFiltersButtonToggle from "src/components/Filtering/HideFiltersButtonToggle";
import { OrderHistorySalesSummaryPDFLoader } from "src/components/PDFs/PDFLoader";
import BackButton from "src/components/Reporting/BackButton";
import { StyledButton } from "src/components/StyledComponents";

import { CSVLink } from "@utils/csv";

import FilterChipList from "../components/Filtering/FilterChipList";
import ItemPreviewModal from "../components/ItemPreview/ItemPreviewModal";
import OrderHistoryByVariantTable from "../components/OrderHistory/OrderHistoryByVariantTable";
import OrderHistoryTable from "../components/OrderHistory/OrderHistoryTable";
import TrackingModal from "../components/Utility/Modals/TrackingModal";
import { useChannelUpdate, useInitialFilters } from "../hooks/UtilityHooks";
import { setSorted, updateMultipleFilters } from "../redux/slices/filterSlice";
import {
  fetchNextOrderHistory,
  fetchNextOrderVariantHistory,
  fetchOrderReport,
  fetchOrderVariantReport,
} from "../redux/slices/ordering/orderHistorySlice";
import { getTracking } from "../redux/slices/trackingSlice";
import { formatMoney, formatMoneyString } from "../utility/utilityFunctions";

/*
Order history can be viewed in one of two ways, by variant or by order. This view will allow users
to toggle between the two, by determining which table to present based on the selected filters.
*/

const orderHeaders = [
  { label: "Order Number", key: "orderId" },
  { label: "Order Type / Window", key: "type" },
  { label: "Ordered By", key: "user" },
  { label: "Distributor / Name", key: "addressName" },
  { label: "Address ID", key: "distributorABN" },
  { label: "State", key: "state" },
  { label: "Territory", key: "territoryName" },
  { label: "Program", key: "program" },
  { label: "Brand", key: "brand" },
  { label: "BU", key: "businessUnit" },
  { label: "Order Date", key: "orderDate" },
  { label: "Ship Date", key: "shipDate" },
  { label: "Total Items", key: "totalQty" },
  { label: "Total Product Cost", key: "totalProductCost" },
  { label: "Total Beacon Cost", key: "totalBeaconCost" },
  { label: "Est. Freight", key: "totalEstFreight" },
  { label: "Est. Taxes", key: "totalEstTax" },
  { label: "Est. Total Cost", key: "totalEstExtendedCost" },
  { label: "Status", key: "status" },
  { label: "Approval Logs", key: "approvalLogging" },
];

const itemHeaders = [
  { label: "Sequence #", key: "itemNumber" },
  { label: "Order Type / Window", key: "orderType" },
  { label: "Order Number", key: "orderId" },
  { label: "Anaplan Sub Program ID", key: "anaplanSubProgramCodes" },
  { label: "Purchase Order #", key: "poId" },
  { label: "Ordered By", key: "user" },
  { label: "Territory", key: "territoryName" },
  { label: "Brand", key: "brand" },
  { label: "BU", key: "businessUnit" },
  { label: "Program", key: "program" },
  { label: "Item Type", key: "itemType" },
  { label: "POS Type", key: "itemTypePosType" },
  { label: "Item Description", key: "itemDesc" },
  { label: "Distributor / Name", key: "addressName" },
  { label: "Address ID", key: "distributorABN" },
  { label: "Attention", key: "attn" },
  { label: "Address One", key: "addressOne" },
  { label: "Address Two", key: "addressTwo" },
  { label: "City", key: "city" },
  { label: "State", key: "state" },
  { label: "Zip", key: "zip" },
  { label: "Total Qty", key: "totalQty" },
  { label: "Est. Cost/Unit", key: "estCost" },
  { label: "Act. Cost/Unit", key: "actCost" },
  { label: "Total Product Cost", key: "totalProductCost" },
  { label: "Total Beacon Cost", key: "totalBeaconCost" },
  { label: "Est. Freight", key: "totalEstFreight" },
  { label: "Est. Taxes", key: "totalEstTax" },
  { label: "Est. Total Cost", key: "totalEstCost" },
  { label: "Order Date", key: "orderDate" },
  { label: "In Market Month", key: "inMarketMonth" },
  { label: "In Market Date", key: "estShipDate" },
  { label: "Act. Ship Date", key: "shipDate" },
  { label: "Carrier", key: "carrier" },
  { label: "Tracking #", key: "tracking" },
  { label: "Status", key: "status" },
  { label: "Compliance Status", key: "complianceStatus" },
  { label: "Beacon", key: "includeBeacon" },
  { label: "Approval Logs", key: "approvalLogging" },
];

const defaultOrderFilters = {
  fromDate: format(addDays(new Date(), -60), "MM/dd/yyyy"),
  toDate: format(addDays(new Date(), 1), "MM/dd/yyyy"),
  user: [],
  address: [],
  groupBy: "order",
  brand: [],
  program: [],
  itemType: [],
  businessUnit: [],
  itemNumber: "",
  orderNum: "",
  type: "all",
  status: "not-draft",
  sortOrder: "asc",
  sortOrderBy: "orderDate",
  orderWindowType: null,
};

const defaultItemFilters = {
  fromDate: format(addDays(new Date(), -60), "MM/dd/yyyy"),
  toDate: format(addDays(new Date(), 1), "MM/dd/yyyy"),
  user: [],
  address: [],
  groupBy: "item",
  brand: [],
  program: [],
  itemType: [],
  businessUnit: [],
  itemNumber: "",
  orderNum: "",
  type: "all",
  status: "not-draft",
  sortOrder: "asc",
  sortOrderBy: "itemType",
  orderWindowType: null,
};

const useStyles = makeStyles((theme) => ({
  ...theme.global,
}));

const OrderHistory = () => {
  const [searchParams] = useSearchParams();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { filterOption } = useParams();

  const orderRef = useRef(null);
  const itemRef = useRef(null);

  const [currentView, setCurrentView] = useState(filterOption);
  const [currentItem, setCurrentItem] = useState({});
  const [previewModal, handlePreviewModal] = useState(false);
  const [isTrackingOpen, setTrackingOpen] = useState(false);
  const [currentCSVData, setCurrentCSVData] = useState({
    data: [],
    headers: [],
    group: filterOption === "byOrder" ? "order" : "item",
  });
  const currentGrouping = useSelector((state) => state.filters.groupBy);
  const nextLink = useSelector((state) => state.orderHistory.nextLink);
  const isNextLoading = useSelector(
    (state) => state.orderHistory.isNextLoading
  );
  const triggerCSV = useSelector(
    (state) => state.orderHistory.triggerCSVDownload
  );
  const orderReport = useSelector((state) => state.orderHistory.orderReport);
  const variantReport = useSelector(
    (state) => state.orderHistory.variantReport
  );

  const handlePrintOrderTable = useReactToPrint({
    content: () => orderRef.current,
  });

  const handlePrintItemTable = useReactToPrint({
    content: () => itemRef.current,
  });

  const handleBottomScroll = () => {
    if (nextLink && !isNextLoading) {
      if (scrollRef.current && scrollRef.current.scrollTop !== 0) {
        if (currentGrouping === "order") {
          dispatch(fetchNextOrderHistory(nextLink));
        } else {
          dispatch(fetchNextOrderVariantHistory(nextLink));
        }
      }
    }
  };

  const scrollRef = useBottomScrollListener(handleBottomScroll, {
    offset: 500,
    debounceOptions: {
      leading: true,
      trailing: false,
    },
  });

  const isOrdersLoading = useSelector((state) => state.orderHistory.isLoading);
  const currentOrders = useSelector((state) => state.orderHistory.orders);
  const currentOrderVariants = useSelector(
    (state) => state.orderHistory.variants
  );
  const currentUserRole = useSelector((state) => state.user.role);
  const currentChannel = useSelector((state) => state.user.currentChannel);
  const currentChannelFilter = useSelector((state) => state.filters.channel);
  const defaultFilters =
    filterOption === "byOrder" ? defaultOrderFilters : defaultItemFilters;
  defaultFilters.channel = currentChannel;

  const handleModalOpen = (itemNumber) => {
    let item = currentOrderVariants.find(
      (item) => item.itemNumber === itemNumber
    );
    setCurrentItem(item);
    handlePreviewModal(true);
  };

  const handleModalClose = () => {
    handlePreviewModal(false);
  };

  const handleTrackingClick = (id) => {
    dispatch(getTracking(id));
    setTrackingOpen(true);
  };

  const handleSort = (sortObject) => {
    scrollRef.current.scrollTop = 0;
    dispatch(
      updateMultipleFilters({
        filterObject: {
          sortOrder: sortObject.order,
          sortOrderBy: sortObject.orderBy,
        },
      })
    );
    dispatch(setSorted());
  };

  const handleOrderReport = () => {
    dispatch(fetchOrderReport());
  };

  const handleOrderVariantReport = () => {
    dispatch(fetchOrderVariantReport());
  };

  useChannelUpdate(currentChannel, currentChannelFilter, dispatch);

  if (searchParams.get("budgetId")) {
    defaultFilters.budgetId = searchParams.get("budgetId");
  }

  useInitialFilters("history-orders", defaultFilters);

  useEffect(() => {
    if (filterOption && currentView !== filterOption) {
      setCurrentView(filterOption);
      if (filterOption === "byOrder") {
        dispatch(updateMultipleFilters({ filterObject: defaultOrderFilters }));
      } else {
        dispatch(updateMultipleFilters({ filterObject: defaultItemFilters }));
      }
      dispatch(setSorted());
    }
  }, [currentView, setCurrentView, filterOption, dispatch]);

  useEffect(() => {
    if (triggerCSV && currentCSVData.data.length === 0) {
      let dataObject = {
        data: [],
        headers: [],
        group: currentGrouping,
      };
      dataObject.headers =
        dataObject.group === "order" ? orderHeaders : itemHeaders;
      dataObject.data =
        dataObject.group === "order"
          ? orderReport.map((order) => {
              return {
                orderId: order.id,
                type: order.type,
                user: order.user,
                territoryName: order.territoryName,
                addressName: order.address?.name ?? order.toInventoryAllocation,
                distributorABN: order.distributorAbn ?? "",
                state: order.address?.state?.code ?? order.subStateName,
                program: order.program,
                brand: order.brand.join(", "),
                businessUnit: order.businessUnit,
                orderDate: order.orderDate,
                shipDate: order.shipDate,
                totalQty: order.totalQty,
                totalProductCost: formatMoney(order.totalProductCost),
                totalBeaconCost: formatMoneyString(order.totalBeaconCost),
                totalEstFreight: formatMoney(order.totalEstFreight),
                totalEstTax: formatMoney(order.totalEstTax),
                totalEstExtendedCost: formatMoney(order.totalEstExtendedCost),
                approvalLogging: order.approvalLogging,
              };
            })
          : variantReport.map((item) => {
              return {
                itemNumber: item.itemNumber,
                orderType: item.orderType,
                orderId: item.orderId,
                anaplanSubProgramCodes: item.anaplanSubProgramCodes.join(", "),
                poId: item.poId,
                user: item.user,
                territoryName: item.territoryName,
                brand: item.brand.join(", "),
                businessUnit: item.businessUnit,
                program: item.program,
                itemType: item.itemType,
                itemTypePosType: item.itemTypePosType,
                itemDesc: item.itemDescription,
                addressName: item.addressName,
                distributorABN:
                  item.distributorAbn !== "---" ? item.distributorAbn : "",
                attn: item.attn ? item.attn : "---",
                addressOne: item.addressOne,
                addressTwo: item.addressTwo,
                city: item.city,
                state: item.state,
                zip: item.zip,
                totalQty: item.totalQty,
                estCost:
                  item.estCost !== "---"
                    ? formatMoney(item.estCost, false)
                    : item.estCost,
                actCost:
                  item.actCost !== "---"
                    ? formatMoney(item.actCost, false)
                    : item.actCost,
                totalProductCost: formatMoney(item.totalProductCost),
                totalBeaconCost: formatMoneyString(item.totalBeaconCost),
                totalEstFreight: formatMoney(item.totalEstFreight),
                totalEstTax: formatMoney(item.totalEstTax),
                totalEstCost: formatMoney(item.totalEstCost),
                orderDate: item.orderDate,
                inMarketMonth: item.inMarketMonth,
                estShipDate: item.estShipDate ?? item.inMarketDate,
                shipDate: item.shipDate,
                carrier: item.carrier,
                tracking: item.isTransferred
                  ? "Transferred qty’s from other territories"
                  : item.tracking,
                status:
                  !item.isComplianceCanceled &&
                  !item.isCanceled &&
                  !item.isDestroyed
                    ? item.status[0].toUpperCase() + item.status.slice(1)
                    : item.isDestroyed
                    ? "Destroyed"
                    : item.isCanceled
                    ? `Canceled, Reason: ${item.canceledNote}`
                    : `Compliance Canceled, Reason: ${item.canceledNote}`,
                complianceStatus: item.complianceStatus,
                includeBeacon: item.includeBeacon,
                approvalLogging: item.approvalLogging,
              };
            });
      setCurrentCSVData(dataObject);
    }
  }, [
    triggerCSV,
    orderReport,
    variantReport,
    currentCSVData.data.length,
    currentCSVData.group,
    currentGrouping,
  ]);

  useEffect(() => {
    if (!triggerCSV && currentCSVData.data.length > 0) {
      setCurrentCSVData({
        data: [],
        headers: [],
        group: currentGrouping,
      });
    }
  }, [triggerCSV, currentCSVData, setCurrentCSVData, currentGrouping]);

  return (
    <>
      <Helmet>
        <title>RTA | Order History</title>
        {(currentUserRole === "field2" || currentUserRole === "field1") && (
          <script type="text/javascript">{` Beacon('suggest', ['600af2ff1c64ad47e4b7201d','5ffdf334b9a8501b295cf995'])`}</script>
        )}
        {currentUserRole === "read-only" && (
          <script type="text/javascript">{` Beacon('suggest', ['600ed315c64fe14d0e1fe351'])`}</script>
        )}
      </Helmet>
      {previewModal && currentItem && (
        <ItemPreviewModal
          type={"catalog"}
          handleClose={handleModalClose}
          previewModal={previewModal}
          currentItem={currentItem}
        />
      )}
      {isTrackingOpen && (
        <TrackingModal open={isTrackingOpen} handleClose={setTrackingOpen} />
      )}
      <Container className={classes.mainWrapper}>
        <div className={classes.titleBar}>
          <Typography className={classes.titleText}>Order History</Typography>
          <div
            style={{
              display: "flex",
              minWidth: "150px",
              justifyContent: "flex-end",
            }}
          >
            <Tooltip title="Print Order History">
              <IconButton
                onClick={() => {
                  if (currentGrouping === "order") {
                    handlePrintOrderTable();
                  } else {
                    handlePrintItemTable();
                  }
                }}
                size="large"
              >
                <PrintIcon color="secondary" />
              </IconButton>
            </Tooltip>
            <BackButton url={"/dashboard"} />
            <OrderHistorySalesSummaryPDFLoader />
            {!triggerCSV && currentCSVData.data.length === 0 && (
              <StyledButton
                cta
                onClick={
                  currentGrouping === "order"
                    ? handleOrderReport
                    : handleOrderVariantReport
                }
                disabled={
                  (currentGrouping === "order" && currentOrders.length === 0) ||
                  (currentGrouping === "item" &&
                    currentOrderVariants.length === 0)
                }
                style={{ marginLeft: 10 }}
              >
                Generate CSV Report
              </StyledButton>
            )}
            {triggerCSV && currentCSVData.data.length > 0 && (
              <StyledButton
                cta
                startIcon={<GetAppIcon />}
                style={{ marginLeft: 10 }}
              >
                <CSVLink
                  data={currentCSVData.data}
                  headers={currentCSVData.headers}
                  filename="rta_order_history.csv"
                >
                  Export CSV Report
                </CSVLink>
              </StyledButton>
            )}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignContent: "center",
            marginBottom: "10px",
          }}
        >
          <HideFiltersButtonToggle />
          <FilterChipList classes={classes} />
          <br />
        </div>
        {currentGrouping === "order" && (
          <OrderHistoryTable
            orders={currentOrders}
            isOrdersLoading={isOrdersLoading}
            handleSort={handleSort}
            scrollRef={scrollRef}
            orderRef={orderRef}
          />
        )}
        {currentGrouping === "item" && (
          <OrderHistoryByVariantTable
            items={currentOrderVariants}
            isOrdersLoading={isOrdersLoading}
            handleSort={handleSort}
            scrollRef={scrollRef}
            itemRef={itemRef}
            handlePreview={handleModalOpen}
            handleTrackingClick={handleTrackingClick}
          />
        )}
        {isNextLoading && (
          <div style={{ width: "100%" }}>
            <LinearProgress />
          </div>
        )}
        {!isNextLoading && <div style={{ width: "100%", height: "4px" }}></div>}
      </Container>
      <br />
    </>
  );
};

export default OrderHistory;
