import React, { useEffect, useRef, useState } from "react";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
import { useDispatch, useSelector } from "react-redux";

import {
  FormControlLabel,
  LinearProgress,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { useDetailedInput } from "../../hooks/InputHooks";
import {
  clearAllAddressList,
  fetchAddresses,
  fetchAnyNextAddresses,
  setTriggerCSVFalse,
} from "../../redux/slices/addresses/addressSlice";
import AddressBookTable from "./AddressBookTable";
import DistributorEmailModal from "./DistributorEmailModal";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
  inputField: {
    marginBottom: "15px",
    width: "48%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
}));

const DistributorAddresses = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const debounce = useRef(null);

  const [distributorEmailModal, setDistributorEmailModal] = useState({
    open: false,
    id: null,
  });

  const [status, setStatus] = useState("active");

  const { addressList, isLoading, nextLink, isNextLoading } = useSelector(
    (state) => state.addresses
  );

  const handleBottomScroll = () => {
    if (nextLink && !isNextLoading && !isLoading) {
      if (scrollRef.current && scrollRef.current.scrollTop !== 0) {
        dispatch(fetchAnyNextAddresses(nextLink));
      }
    }
  };

  const scrollRef = useBottomScrollListener(handleBottomScroll, {
    offset: 500,
    debounceOptions: {
      leading: true,
      trailing: false,
    },
  });

  const handleModalClose = () =>
    setDistributorEmailModal({ open: false, id: null });

  const handleModalOpen = (id, _type) =>
    setDistributorEmailModal({ open: true, id: id });

  const handleAddressSearch = (value, _type, _filter) => {
    clearTimeout(debounce.current);

    debounce.current = setTimeout(() => {
      dispatch(fetchAddresses(value, false, false, false, "distributor"));
    }, 250);
  };

  const { value: search, bind: bindSearch } = useDetailedInput(
    "",
    handleAddressSearch
  );

  const handleActiveSwitch = () => {
    dispatch(
      fetchAddresses(
        search,
        false,
        false,
        false,
        "distributor",
        status === "active" ? false : true
      )
    );
    setStatus(status === "active" ? "inactive" : "active");
  };

  useEffect(() => {
    dispatch(fetchAddresses("", false, false, false, "distributor"));
    dispatch(clearAllAddressList());
    dispatch(setTriggerCSVFalse());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {distributorEmailModal.open && (
        <DistributorEmailModal
          {...distributorEmailModal}
          handleClose={handleModalClose}
        />
      )}
      <div className={classes.titleBar}>
        <Typography className={classes.titleText}>
          Edit Distributor Address Email Alerts
        </Typography>
      </div>
      <br />
      <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
        <TextField
          size="small"
          className={classes.inputField}
          variant="outlined"
          color="secondary"
          name="search"
          type="text"
          label="Search Addresses"
          value={search}
          {...bindSearch}
        />
        <div
          style={{
            width: "22%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            marginLeft: "3%",
          }}
        >
          <Typography className={classes.bodyText}>Status:</Typography>
          <FormControlLabel
            style={{ marginLeft: "5px" }}
            control={
              <Switch
                checked={status === "active"}
                onChange={handleActiveSwitch}
                name="active-switch"
              />
            }
            label={status === "active" ? "Active" : "Inactive"}
          />
        </div>
      </div>
      <br />
      <br />
      <AddressBookTable
        handleAddressClick={handleModalOpen}
        addresses={addressList}
        isLoading={isLoading}
        scrollRef={scrollRef}
        type="distributor"
      />
      {isNextLoading && (
        <div style={{ width: "100%" }}>
          <LinearProgress />
        </div>
      )}
      {!isNextLoading && <div style={{ width: "100%", height: "4px" }}></div>}
    </>
  );
};

export default DistributorAddresses;
