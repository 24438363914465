import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { v4 as uuidv4 } from "uuid";

import { query } from "@services/api";

import { queueReport } from "./globalReportSlice";
import reportDefinitions from "./reportDefinitions";

// builds a unique url for the request
const buildUrl = (resource: string, params: Record<string, any>) =>
  resource + "?" + query({ ...params, uuid: uuidv4() });

const useReport = (
  reportName: keyof ReturnType<typeof reportDefinitions>,
  [resourceName, params]: [resourceName: string, params: any]
) => {
  const dispatch = useDispatch();
  const requestReport = useSelector(
    (state: any) => state.globalReports.requestReport
  );

  useEffect(() => {
    if (requestReport === reportName) {
      const url = buildUrl(resourceName, params);
      dispatch(queueReport({ reportName, url }));
    }
  }, [requestReport, reportName, resourceName, params, dispatch]);
};

export default useReport;
