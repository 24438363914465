import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import WarningIcon from "@mui/icons-material/Warning";
import CircularProgress from "@mui/material/CircularProgress";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";

import PropTypes from "prop-types";

import { SUPPLIER_ROLES } from "../../constants/permissions";
import {
  formatMoney,
  formatMoneyUnlimitedDecimals,
} from "../../utility/utilityFunctions";

const headCells = [
  { id: "poNum", disablePadding: false, label: "PO. #", sort: false },
  {
    id: "poExternalSapId",
    disablePadding: false,
    label: "SAP PO #",
    sort: false,
  },
  {
    id: "supplierReference",
    disablePadding: false,
    label: "Supplier Ref #",
    sort: false,
  },
  {
    id: "invoiceNumber",
    disablePadding: false,
    label: "Invoice #",
    sort: false,
  },
  { id: "seqNum", disablePadding: false, label: "Seq. #", sort: true },
  { id: "supplier", disablePadding: false, label: "Supplier", sort: true },
  { id: "brand", disablePadding: false, label: "Brand", sort: true },
  { id: "projectNum", disablePadding: false, label: "Project #", sort: true },
  { id: "itemType", disablePadding: false, label: "Item Type", sort: false },
  {
    id: "itemDesc",
    disablePadding: false,
    label: "Item Description",
    sort: true,
  },
  { id: "quantity", disablePadding: false, label: "Quantity", sort: true },
  { id: "estCost", disablePadding: false, label: "Est. Cost/Unit", sort: true },
  { id: "actCost", disablePadding: false, label: "Act. Cost/Unit", sort: true },
  { id: "totalCost", disablePadding: false, label: "Total Cost", sort: false },
  { id: "status", disablePadding: false, label: "Status", sort: true },
  {
    id: "submittedDate",
    disablePadding: false,
    label: "Submitted Date",
    sort: true,
  },
  {
    id: "inMarketDate",
    disablePadding: false,
    label: "In Market Date",
    sort: true,
  },
  {
    id: "shipmentCount",
    disablePadding: false,
    label: "Shipment Count",
    sort: false,
  },
  {
    id: "shipperTape",
    disablePadding: false,
    label: "Shipper Tape",
    sort: false,
  },
  {
    id: "sentToFlowAt",
    disablePadding: false,
    label: "Sent To Flow Date",
    sort: false,
  },
  { id: "poCreator", disablePadding: false, label: "Purchaser", sort: true },
  { id: "allocated", disablePadding: false, label: "Allocated", sort: true }, //cdc or direct ship
];

// Toggle to On Demand or Pre Order – What if we don’t toggle and add a column for this?  Like we do in Order History

const EnhancedTableHead = (props) => {
  const { classes, order, orderBy, onRequestSort, role } = props;
  const isSupplier = SUPPLIER_ROLES.includes(role);
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const currentHeadCells = isSupplier
    ? headCells.filter(
        (cell) =>
          cell.id !== "supplier" &&
          cell.id !== "estCost" &&
          cell.id !== "sentToFlowAt"
      )
    : role === "purchaser"
    ? headCells.filter(
        (cell) =>
          cell.id !== "sentToFlowAt" &&
          cell.id !== "shipmentCount" &&
          cell.id !== "shipperTape" &&
          cell.id !== "invoiceNumber"
      )
    : !isSupplier && role !== "super"
    ? headCells.filter(
        (cell) =>
          cell.id !== "shipmentCount" &&
          cell.id !== "shipperTape" &&
          cell.id !== "invoiceNumber"
      )
    : headCells;

  return (
    <TableHead>
      <TableRow>
        {currentHeadCells.map((headCell) => {
          if (!headCell.sort) {
            return (
              <TableCell
                className={classes.headerText}
                key={headCell.id}
                align="left"
              >
                {headCell.label}
              </TableCell>
            );
          } else {
            return (
              <TableCell
                className={classes.headerText}
                key={headCell.id}
                align="left"
                padding={headCell.disablePadding ? "none" : "normal"}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <span className={classes.visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </span>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            );
          }
        })}
      </TableRow>
    </TableHead>
  );
};

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};

const useStyles = makeStyles((theme) => ({
  ...theme.global,
  clickableRow: {
    "&&:hover": {
      cursor: "pointer",
    },
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

const PurchaseOrderHistoryTable = ({
  pos,
  posLoading,
  handleSort,
  scrollRef,
  tableRef,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const role = useSelector((state) => state.user.role);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("itemNumber");

  const isSupplier = SUPPLIER_ROLES.includes(role);

  const handleRequestSort = (_event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    handleSort({ order: isAsc ? "desc" : "asc", orderBy: property });
  };

  const handleRowClick = (poNum) => {
    navigate(`/purchasing/purchase-order/${poNum}`);
  };

  return (
    <>
      <TableContainer
        className={classes.tableContainer}
        style={{ maxHeight: "Calc(100vh - 250px)" }}
        ref={scrollRef}
      >
        <Table
          stickyHeader
          className={classes.table}
          style={{ minWidth: "1325px" }}
          ref={tableRef}
        >
          <EnhancedTableHead
            classes={classes}
            onRequestSort={handleRequestSort}
            order={order}
            orderBy={orderBy}
            role={role}
          />
          <TableBody>
            {!posLoading && pos.length === 0 && (
              <TableRow>
                <TableCell
                  align="left"
                  colSpan={
                    ["supplier", "purchaser"].includes(role)
                      ? 17
                      : role === "select-purchaser"
                      ? 18
                      : 20
                  }
                >
                  <Typography className={classes.headerText}>
                    {`There are no items that match the current search criteria..`}
                  </Typography>
                </TableCell>
              </TableRow>
            )}
            {!posLoading &&
              pos.length > 0 &&
              pos
                .filter((poItem) => poItem.itemType !== "Set Up Fee")
                .map((row) => (
                  <TableRow
                    key={row.id}
                    hover
                    className={classes.clickableRow}
                    onClick={() => {
                      handleRowClick(row.poNum);
                    }}
                  >
                    <TableCell align="left">
                      {row.isRush ? (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Tooltip title="Order On Rush Status">
                            <WarningIcon
                              fontSize="small"
                              style={{ margin: "0 5px 0 0" }}
                            />
                          </Tooltip>
                          {row.poNum}
                        </div>
                      ) : (
                        row.poNum
                      )}
                    </TableCell>
                    <TableCell align="left">{row.poExternalSapId}</TableCell>
                    <TableCell align="left">
                      {row.supplierReference.length > 0
                        ? row.supplierReference
                        : "---"}
                    </TableCell>
                    {(isSupplier || role === "super") && (
                      <TableCell align="left">{row.invoiceNumber}</TableCell>
                    )}
                    <TableCell align="left">{row.displayId}</TableCell>
                    {!isSupplier && (
                      <TableCell align="left">{row.supplier}</TableCell>
                    )}
                    {row.brand &&
                    row.brand.length > 1 &&
                    row.brand !== "---" ? (
                      <Tooltip
                        placement="right-start"
                        title={`${row.brand.join(", ")}`}
                      >
                        <TableCell align="left">
                          {row.brand[0]}
                          <MoreHorizIcon
                            fontSize="small"
                            color="inherit"
                            style={{ float: "right" }}
                          />
                        </TableCell>
                      </Tooltip>
                    ) : (
                      <TableCell align="left">{row.brand[0]}</TableCell>
                    )}
                    <TableCell align="left">{row.projectNum}</TableCell>
                    <TableCell align="left">{row.itemType}</TableCell>
                    <TableCell align="left">{row.itemDesc}</TableCell>
                    <TableCell align="left">{row.totalQty}</TableCell>
                    {!isSupplier && (
                      <TableCell align="left">
                        {formatMoney(row.estCost, true)}
                      </TableCell>
                    )}
                    <TableCell align="left">
                      {formatMoneyUnlimitedDecimals(
                        isSupplier ? row.actCost / 100 : row.galloCost / 100,
                        true
                      )}
                    </TableCell>
                    <TableCell align="left">
                      {formatMoney(
                        isSupplier ? row.totalCost : row.totalGalloCost,
                        true
                      )}
                    </TableCell>
                    <TableCell align="left">
                      {isSupplier && row.status === "submitted"
                        ? "New"
                        : row.status[0].toUpperCase() + row.status.slice(1)}
                    </TableCell>
                    <TableCell align="left">{row.submittedDate}</TableCell>
                    <TableCell align="left">
                      {isSupplier && row.allocated === "CDC"
                        ? row.deliverToWarehouseDate
                        : row.inMarketDate}
                    </TableCell>
                    {["super", "supplier"].includes(role) && (
                      <>
                        <TableCell align="left">{row.shipmentCount}</TableCell>
                        <TableCell align="left">{row.keyAcctTape}</TableCell>
                      </>
                    )}
                    {["select-purchaser", "super"].includes(role) && (
                      <TableCell align="left">
                        {row.sentToFlowAt ?? "---"}
                      </TableCell>
                    )}
                    <TableCell align="left">{row.purchasedBy}</TableCell>
                    <TableCell align="left">{row.allocated}</TableCell>
                  </TableRow>
                ))}
            {posLoading && (
              <TableRow>
                <TableCell
                  align="left"
                  colSpan={
                    ["supplier", "purchaser"].includes(role)
                      ? 17
                      : role === "select-purchaser"
                      ? 18
                      : 20
                  }
                >
                  <CircularProgress />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

PurchaseOrderHistoryTable.propTypes = {
  pos: PropTypes.array,
  handleSort: PropTypes.func.isRequired,
  posLoading: PropTypes.bool.isRequired,
  scrollRef: PropTypes.any,
  tableRef: PropTypes.any,
};

export default PurchaseOrderHistoryTable;
