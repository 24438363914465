import React from "react";

import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";

Font.register({
  family: "Roboto",
  src: "/fonts/Roboto-Regular.ttf",
});

const styles = StyleSheet.create({
  cover: {
    alignContent: "center",
    justifyContent: "center",
  },
  logo: {
    width: 300,
    height: "auto",
  },
  page: {
    marginBottom: 5,
    marginTop: 10,
  },
  text: {
    fontFamily: "Roboto",
    fontSize: 16,
  },
  textTable: {
    fontFamily: "Roboto",
    fontSize: 10,
  },
  boldText: {
    fontWeight: "bold",
  },
  infoText: {
    fontFamily: "Roboto",
    fontSize: 14,
  },

  outerWrapper: {
    flexDirection: "row",
    height: "45%",
    margin: 10,
  },
  leftContainer: {
    flex: 1,
    flexDirection: "column",
  },
  rightContainer: {
    flex: 1,
    alignContent: "center",
    alignItems: "center",
    justifyContent: "center",
  },
  imageContainer: {
    margin: 5,
    height: "60%",
    alignItems: "center",
  },
  adaptiveImage: {
    flex: 1,
    objectFit: "contain",
    height: "100%",
  },
  infoAboveImgContainer: {
    height: "10%",
    margin: 5,
  },
  infoBelowImgContainer: {
    height: "25%",
    margin: 5,
  },
});

const OrderWindowSummaryPresPDF = ({
  focusItems,
  geographyFilterName,
  geographyFilterArray,
  currentMonthName,
  currentYear,
  programArray,
}) => {
  return (
    <Document>
      <Page size="LETTER" style={styles.cover}>
        <View
          style={{
            marginLeft: 20,
            alignItems: "center",
            paddingBottom: 40,
            marginTop: -75,
          }}
        >
          <Image
            style={styles.logo}
            source="https://res.cloudinary.com/brandhub/image/upload/v1723675083/prod/Gallo-wordmark-02_rdiacy.png"
          />
          <Text style={{ color: "#89713c" }}>Commerce Marketing</Text>
        </View>
        <View>
          <Image source="https://images.unsplash.com/photo-1519671482749-fd09be7ccebf?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1350&q=80" />
        </View>
        <View
          style={{ alignContent: "flex-start", marginLeft: 20, marginTop: 20 }}
        >
          <Text
            style={{
              fontSize: `${programArray.length >= 1 ? "20" : "30"}`,
              fontFamily: "Roboto",
              fontWeight: 900,
            }}
          >
            {programArray.length >= 1
              ? `Program: ${programArray.map((prog) => ` ${prog.name}`)}`
              : currentMonthName
              ? `Focus Order Summary: ${currentMonthName}, ${currentYear}`
              : "Focus Order Summary"}
          </Text>
          <Text>
            {geographyFilterName === "National"
              ? "National"
              : `${
                  geographyFilterName === "StateIds"
                    ? "States"
                    : geographyFilterName
                }: ${geographyFilterArray.map((t) =>
                  t.name ? t.name : t.code
                )}`}
          </Text>
        </View>
      </Page>

      {Object.keys(focusItems).map((group, counter) => (
        <Page key={`${group}-${counter}`} size="LETTER" style={styles.page}>
          <View
            style={{
              backgroundColor: "black",
              width: 600,
              height: 30,
            }}
          >
            <Text
              style={{
                fontSize: 25,
                fontWeight: 900,
                marginLeft: 20,
                color: "white",
                alignSelf: "flex-start",
              }}
            >
              {group}
            </Text>
          </View>

          {/* Image and item info on the left, address/ordered table on the right */}
          {Object.keys(focusItems[group])
            .sort(
              (a, b) =>
                focusItems[group][a][0].businessUnitNames.localeCompare(
                  focusItems[group][b][0].businessUnitNames
                ) || a.localeCompare(b)
            )
            .map((item, index) => {
              return (
                <View
                  style={{
                    ...styles.outerWrapper,
                    borderBottomWidth: index % 2 === 0 ? 1 : 0,
                  }}
                  wrap={false}
                  key={item}
                >
                  {/* Image and Info */}
                  <View style={styles.leftContainer}>
                    <View stlye={styles.infoAboveImgContainer}>
                      <Text style={styles.text}>
                        <Text style={styles.boldText}>Brand:</Text>{" "}
                        {focusItems[group][item][0].brand}
                      </Text>
                      <Text style={styles.text}>
                        <Text style={styles.boldText}>BU:</Text>{" "}
                        {focusItems[group][item][0].businessUnitNames}
                      </Text>
                    </View>
                    <View style={styles.imageContainer}>
                      <Image
                        source={{
                          uri: `https://res.cloudinary.com/brandhub/${focusItems[group][item][0].image}.jpg`,
                          method: "get",
                          headers: {
                            "Access-Control-Allow-Origin": "*",
                            crossOrigin: "anonymous",
                          },
                        }}
                        style={styles.adaptiveImage}
                      />
                    </View>
                    <View style={styles.infoBelowImgContainer}>
                      <Text style={styles.text}>
                        <Text style={styles.boldText}>Item Name:</Text>{" "}
                        {focusItems[group][item][0].itemName}
                      </Text>
                      <Text style={styles.text}>
                        <Text style={styles.boldText}>Item Number:</Text> {item}
                      </Text>
                      <Text style={styles.text}>
                        <Text style={styles.boldText}>Item Description:</Text>{" "}
                        {focusItems[group][item][0].itemDesc}
                      </Text>
                      <Text style={styles.text}>
                        <Text style={styles.boldText}>
                          Estimated Delivery Date:
                        </Text>{" "}
                        {focusItems[group][item][0].inMarketDate}
                      </Text>
                    </View>
                  </View>

                  {/* Table */}
                  <View style={styles.rightContainer}>
                    <View style={{ flexDirection: "column" }}>
                      {/* Headers */}
                      {focusItems[group][item].length > 0 &&
                        Boolean(focusItems[group][item][0].addressName) && (
                          <View
                            style={{
                              flexDirection: "row",
                              padding: 2,
                              borderWidth: 1,
                            }}
                          >
                            <View style={{ width: 70 }}>
                              <Text
                                style={{
                                  ...styles.textTable,
                                  fontWeight: "bold",
                                }}
                              >
                                Territory
                              </Text>
                            </View>
                            <View style={{ width: 150 }}>
                              <Text
                                style={{
                                  ...styles.textTable,
                                  fontWeight: "bold",
                                }}
                              >
                                Address
                              </Text>
                            </View>
                            <View style={{ width: 70 }}>
                              <Text
                                style={{
                                  ...styles.textTable,
                                  fontWeight: "bold",
                                }}
                              >
                                Total Ordered
                              </Text>
                            </View>
                          </View>
                        )}
                      <View style={{ flexWrap: "wrap" }}>
                        {/* Table Body */}
                        {focusItems[group][item].map((details, i) => {
                          if (details.addressName) {
                            return (
                              <View
                                key={`${i}`}
                                style={{
                                  flexDirection: "row",
                                  padding: 2,
                                  borderWidth: 1,
                                }}
                              >
                                <View style={{ width: 70, paddingLeft: 2 }}>
                                  <Text style={styles.textTable}>
                                    {details.orderTerritory}
                                  </Text>
                                </View>
                                <View style={{ width: 150, paddingLeft: 2 }}>
                                  <Text style={styles.textTable}>
                                    {details.addressName && details.addressCity
                                      ? `${details.addressName} - ${details.addressCity}`
                                      : ""}
                                  </Text>
                                </View>

                                <View style={{ width: 70, paddingLeft: 2 }}>
                                  <Text style={styles.textTable}>
                                    {details.totalQty}
                                  </Text>
                                </View>
                              </View>
                            );
                          }

                          /* Show territory order breakdown */
                          if (geographyFilterName === "National") {
                            return (
                              <View
                                key={`${i}`}
                                style={{
                                  flexDirection: "row",
                                  flexWrap: "wrap",
                                  padding: 2,
                                  borderWidth: 1,
                                }}
                              >
                                <View style={{ width: 70, paddingLeft: 2 }}>
                                  <Text style={styles.textTable}>
                                    {details.territory}
                                  </Text>
                                </View>
                                <View style={{ width: 50, paddingLeft: 2 }}>
                                  <Text style={styles.textTable}>
                                    {details.totalQty}
                                  </Text>
                                </View>
                              </View>
                            );
                          }

                          return null;
                        })}
                      </View>

                      {/* Total Order Row */}
                      <View
                        style={{
                          backgroundColor: "lightgrey",
                          padding: 2,
                          borderWidth: 1,
                        }}
                      >
                        <Text style={[styles.boldText, styles.textTable]}>
                          Total Ordered:{" "}
                          <Text style={styles.textTable}>
                            {focusItems[group][item][0].grandTotal}
                          </Text>
                        </Text>

                        {/* <Text style={styles.textTable}>
                          Total Ordered: {focusItems[group][item][0].grandTotal}
                        </Text> */}
                      </View>
                    </View>
                  </View>
                </View>
              );
            })}
        </Page>
      ))}
    </Document>
  );
};

export default OrderWindowSummaryPresPDF;
