/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useEffect } from "react";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
import Helmet from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { ExitToApp } from "@mui/icons-material";
import { Button, Container, LinearProgress, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import HideFiltersButtonToggle from "src/components/Filtering/HideFiltersButtonToggle";

import FilterChipList from "../components/Filtering/FilterChipList";
import PlanningToolTable from "../components/PlanningTool/Search/PlanningToolTable";
import Loading from "../components/Utility/Loading";
import UserCanView from "../components/Utility/UserCanView";
import { PLANNING_TOOL_SUPPLIER } from "../constants/permissions";
import { useChannelUpdate, useInitialFilters } from "../hooks/UtilityHooks";
import {
  setClearFreeType,
  updateMultipleFilters,
} from "../redux/slices/filterSlice";
import {
  clearPrograms,
  fetchNextFilteredPrograms,
} from "../redux/slices/planningTool/programSlice";

const defaultPlanningFilters = {
  itemNumber: "",
  programId: "",
  workfrontId: "",
  couponBarcodeId: "",

  channel: "",
  inMarketYear: [],
  orderWindowMonthYear: null,
  programType: [],
  brand: [],
  bu: [],

  sortOrder: "desc",
  sortOrderBy: "startDate",
  isPreOrder: false,
  // isPreOrderActive: true,
  isAdHoc: false,
  // isCanceled: false,
};

const useStyles = makeStyles((theme) => ({
  ...theme.global,
}));

const PlanningToolSearch = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const currentChannelFilter = useSelector((state) => state.filters.channel);

  const { nextLink, isNextLoading, pagesLoaded, isLoading, programs } =
    useSelector((state) => state.program);
  const { currentChannel } = useSelector((state) => state.user);

  const handleBottomScroll = () => {
    if (nextLink && !isNextLoading) {
      if (scrollRef.current && scrollRef.current.scrollTop !== 0) {
        dispatch(fetchNextFilteredPrograms(nextLink));
      }
    }
  };

  const scrollRef = useBottomScrollListener(handleBottomScroll, {
    offset: 500,
    debounceOptions: {
      leading: true,
      trailing: false,
    },
  });

  useEffect(() => {
    dispatch(updateMultipleFilters({ filterObject: defaultPlanningFilters }));
    dispatch(
      setClearFreeType({
        value: ["itemNumber", "programId", "workfrontId", "couponBarcodeId"],
      })
    );
    // Commented out to prevent initial fetch
    // dispatch(setSorted());
    return () => dispatch(clearPrograms());
  }, [dispatch]);

  useChannelUpdate(currentChannel, currentChannelFilter, dispatch);

  useInitialFilters("planningTool", defaultPlanningFilters);

  if (isLoading) {
    return <Loading partial={true} />;
  }

  return (
    <>
      <Helmet>
        <title>RTA | Planning Tool</title>
      </Helmet>
      <Container className={classes.mainWrapper}>
        <div className={classes.titleBar}>
          <Typography className={classes.titleText}>Search Programs</Typography>
          <div className={classes.configButtons}>
            <div className={classes.innerConfigDiv}>
              <Button
                className={classes.largeButton}
                tw="mr-3"
                variant="contained"
                color="secondary"
                startIcon={
                  <ExitToApp style={{ transform: "rotate(180deg)" }} />
                }
                component={Link}
                to={"/planning/dashboard"}
              >
                BACK TO PLANNING TOOL
              </Button>
              <UserCanView deny={[PLANNING_TOOL_SUPPLIER, "field2"]}>
                <Button
                  className={classes.largeButton}
                  variant="contained"
                  color="secondary"
                  component={Link}
                  to="/planning/program/create"
                >
                  CREATE PROGRAM
                </Button>
              </UserCanView>
            </div>
          </div>
        </div>
        <br />
        <>
          <div tw="flex items-center mb-3">
            <HideFiltersButtonToggle />
            <FilterChipList classes={classes} />
          </div>
          {pagesLoaded > 0 ? (
            <PlanningToolTable
              programs={programs}
              isLoading={isLoading}
              scrollRef={scrollRef}
            />
          ) : (
            <Typography variant="h5">
              Add some filters to get started
            </Typography>
          )}
          {isNextLoading && (
            <div style={{ width: "100%" }}>
              <LinearProgress />
            </div>
          )}
          {!isNextLoading && (
            <div style={{ width: "100%", height: "4px" }}></div>
          )}
        </>
      </Container>
      <br />
    </>
  );
};

export default PlanningToolSearch;
