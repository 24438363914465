import React, { useState } from "react";
import { useDispatch } from "react-redux";

import EditIcon from "@mui/icons-material/Edit";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";

import PropTypes from "prop-types";

import { setCustomAttention } from "../../redux/slices/addresses/addressSlice";
import EditAttnModal from "./EditAttnModal";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
}));

const AddressAttnTable = ({ addresses, isLoading, scrollRef }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [currentAddress, setCurrentAddress] = useState(null);
  const [isEditOpen, setEditOpen] = useState(false);

  const handleEdit = (id, value) => {
    dispatch(setCustomAttention(id, value));
  };

  const handleEditOpen = (id, value, name) => {
    setCurrentAddress({ id: id, attn: value, name: name });
    setEditOpen(true);
  };

  const handleEditClose = () => {
    setCurrentAddress(null);
    setEditOpen(false);
  };

  return (
    <>
      {currentAddress && (
        <EditAttnModal
          id={currentAddress.id}
          attention={currentAddress.attn}
          dist={currentAddress.name}
          handleEdit={handleEdit}
          editOpen={isEditOpen}
          handleClose={handleEditClose}
        />
      )}
      <TableContainer
        className={classes.tableContainer}
        style={{ maxHeight: "45vh" }}
        ref={scrollRef}
      >
        <Table stickyHeader className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.headerText} align="left">
                ABN / ID
              </TableCell>
              <TableCell className={classes.headerText} align="left">
                Distributor / Name
              </TableCell>
              <TableCell className={classes.headerText} align="left">
                Phone Number
              </TableCell>
              <TableCell className={classes.headerText} align="left">
                Street Address
              </TableCell>
              <TableCell className={classes.headerText} align="left">
                City
              </TableCell>
              <TableCell className={classes.headerText} align="left">
                State
              </TableCell>
              <TableCell className={classes.headerText} align="left">
                Zip
              </TableCell>
              <TableCell className={classes.headerText} align="left">
                Attention
              </TableCell>
              <TableCell className={classes.headerText} align="left">
                Type
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {!isLoading && addresses.length === 0 && (
              <TableRow>
                <TableCell align="left" colSpan={8}>
                  <Typography className={classes.headerText}>
                    {`There are no addresses that fit your search criteria...`}
                  </Typography>
                </TableCell>
              </TableRow>
            )}
            {!isLoading &&
              addresses.length > 0 &&
              addresses.map((address) => (
                <TableRow key={address.id}>
                  <TableCell align="left">{address.addDisplayId}</TableCell>
                  <TableCell align="left">{address.name}</TableCell>
                  <TableCell align="left">
                    {address.phoneNumber ?? "---"}
                  </TableCell>
                  <TableCell align="left">
                    {`${address.streetAddressOne} ${
                      address.streetAddressTwo
                        ? `, ${address.streetAddressTwo} `
                        : ""
                    }`}
                  </TableCell>
                  <TableCell align="left">{address.city}</TableCell>
                  <TableCell align="left">{address.state.code}</TableCell>
                  <TableCell align="left">{address.zip}</TableCell>
                  <TableCell align="left">{address.attn}</TableCell>
                  <TableCell align="right">{address.type}</TableCell>
                  <TableCell align="left">
                    <Tooltip title="Edit Attention">
                      <IconButton
                        onClick={() =>
                          handleEditOpen(address.id, address.attn, address.name)
                        }
                        size="large"
                      >
                        <EditIcon fontSize="small" color="inherit" />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            {isLoading && (
              <TableRow>
                <TableCell align="left" colSpan={9}>
                  <CircularProgress />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

AddressAttnTable.propTypes = {
  addresses: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default React.memo(AddressAttnTable);
