import { OrderVariant } from "@models";

import { orderVariantNotCompliantTypes } from "./constants";

export const isOrderVariantCompliant = (orderVariant: OrderVariant) =>
  orderVariant.triggeredRules.filter(
    (rule) =>
      orderVariantNotCompliantTypes.includes(rule.ruleType) &&
      rule.status !== "approved"
  ).length === 0;
