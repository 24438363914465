export const buildProgramQuery = ({
  orderWindowType,
  territoryId,
  channel,
  isAdHoc,
  active = true,
}) => {
  const mutualFilters = [
    "filter[is-pre-order]=true",
    channel && `filter[channel]=${channel}`,
    isAdHoc !== undefined && `filter[is-ad-hoc]=${isAdHoc}`,
    "filter[is-canceled]=false",
    "page[size]=50",
    `filter[is-pre-order-${active ? "active" : "visible"}]=true`,
    orderWindowType && `filter[order-window-type]=${orderWindowType}`,
  ]
    .filter(Boolean)
    .join("&");

  return !territoryId
    ? `/api/programs?${mutualFilters}`
    : `/api/programs?filter[territory-id]=${territoryId}&${mutualFilters}`;
};
