import { useDispatch } from "react-redux";

import { setError } from "src/redux/slices/errorSlice";

const useMutateError = () => {
  const dispatch = useDispatch();
  return (error: Error, source?: string) => {
    // Todo: use a better hook for dealing specifically with mutation errors.
    // Display more meaningful errors
    dispatch(
      setError({
        error: error.message,
        source: source,
      })
    );
    console.error(error);
  };
};

export default useMutateError;
