/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useState } from "react";
import { useForm } from "react-hook-form";

import { Close } from "@mui/icons-material";
import {
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
} from "@mui/material";

import _ from "lodash";
import { TextInput } from "src/components/Forms/ControlledInputs";
import { StyledButton } from "src/components/StyledComponents";

import { formatAddress } from "@features/addresses";

import useUpdateOrder from "./data/mutations/useUpdateOrder";

const EditOrderDetailsModal = ({ order, handleClose }) => {
  const updateOrder = useUpdateOrder();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<null | string>(null);
  const { control, handleSubmit } = useForm({
    defaultValues: _.pick(order, "attn", "notes"),
  });

  const onSubmit = async (data) => {
    setError(null);
    setLoading(true);
    updateOrder.mutate(
      { id: order.id, ...data },
      {
        onError: (err) => setError(err.message),
        onSuccess: () => handleClose(),
        onSettled: () => setLoading(false),
      }
    );
  };

  return (
    <Dialog open onClose={handleClose} fullWidth maxWidth="sm">
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent tw="overflow-visible flex flex-col gap-3">
          <div tw="flex justify-between items-baseline">
            <div>
              <div tw="text-lg font-medium text-neutral-700">
                {order.address.name}
              </div>
              <div tw="text-neutral-500">{formatAddress(order.address)}</div>
            </div>
            <IconButton edge="end" size="small" onClick={handleClose}>
              <Close fontSize="small" />
            </IconButton>
          </div>
          <TextInput control={control} name="attn" label="Attention" />
          <TextInput
            multiline
            minRows={4}
            control={control}
            name="notes"
            label="Order Notes"
          />
          {error && <Alert severity="error">{error}</Alert>}
        </DialogContent>
        <DialogActions>
          <StyledButton type="submit" loading={loading} cta>
            Save Details
          </StyledButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default EditOrderDetailsModal;
