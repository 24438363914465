import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import WarningIcon from "@mui/icons-material/Warning";
import CircularProgress from "@mui/material/CircularProgress";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";

import PropTypes from "prop-types";

import {
  clearOrderHistoryReports,
  setTriggerCSVFalse,
} from "../../redux/slices/ordering/orderHistorySlice";
import { formatMoney, formatMoneyString } from "../../utility/utilityFunctions";

const headCells = [
  { id: "id", disablePadding: false, label: "Order #", sort: true },
  {
    id: "orderAnaplanSubCodes",
    disablePadding: false,
    label: "12 Digit Code(s)",
    sort: true,
  },
  { id: "anaplanProgramTypes", label: "Anplan Program Type" },
  {
    id: "type",
    disablePadding: false,
    label: "Order Type / Window",
    sort: false,
  },
  { id: "user", disablePadding: false, label: "Ordered By", sort: false },
  {
    id: "addressName",
    disablePadding: false,
    label: "Distributor / Name",
    sort: true,
  },
  {
    id: "distributorAbn",
    label: "Address ID",
    sort: true,
  },
  { id: "state", disablePadding: false, label: "State", sort: true },
  { id: "territory", disablePadding: false, label: "Territory", sort: false },
  { id: "program", disablePadding: false, label: "Program", sort: false },
  { id: "brand", disablePadding: false, label: "Brand", sort: true },
  { id: "bu", disablePadding: false, label: "BU", sort: false },
  { id: "orderDate", disablePadding: false, label: "Order Date", sort: true },
  {
    id: "totalQty",
    disablePadding: false,
    label: "Total Items",
    sort: false,
  },
  {
    id: "totalProductCost",
    disablePadding: false,
    label: "Total Product Cost",
    sort: false,
  },
  {
    id: "totalBeaconCost",
    label: "Total Beacon Cost",
  },
  {
    id: "totalEstFreight",
    disablePadding: false,
    label: "Est. Freight",
    sort: false,
  },
  {
    id: "totalEstTax",
    disablePadding: false,
    label: "Est. Tax",
    sort: false,
  },
  {
    id: "totalEstExtendedCost",
    disablePadding: false,
    label: "Est. Total",
    sort: false,
  },
  { id: "status", disablePadding: false, label: "Order Status", sort: true },
  {
    id: "approvalLogging",
    disablePadding: false,
    label: "Approval Logs",
    sort: false,
  },
];

const EnhancedTableHead = (props) => {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => {
          if (!headCell.sort) {
            return (
              <TableCell
                className={classes.headerText}
                key={headCell.id}
                align="left"
              >
                {headCell.label}
              </TableCell>
            );
          } else {
            return (
              <TableCell
                className={classes.headerText}
                key={headCell.id}
                align="left"
                padding={headCell.disablePadding ? "none" : "normal"}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <span className={classes.visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </span>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            );
          }
        })}
      </TableRow>
    </TableHead>
  );
};

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  onRequestSort: PropTypes.func.isRequired,
};

const useStyles = makeStyles((theme) => ({
  ...theme.global,
}));

const OrderHistoryTable = ({
  orders,
  handleSort,
  isOrdersLoading,
  scrollRef,
  orderRef,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("orderDate");
  const grouping = useSelector((state) => state.filters.groupBy);

  const handleRequestSort = (_event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    handleSort({ order: isAsc ? "desc" : "asc", orderBy: property });
  };

  const handleRowClick = (id) => {
    if (grouping === "order") {
      navigate(`/orders/history/single-order/${id}`);
    }
  };

  useEffect(() => {
    dispatch(setTriggerCSVFalse());
    dispatch(clearOrderHistoryReports());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <TableContainer
        className={classes.tableContainer}
        style={{ maxHeight: "Calc(100vh - 250px)" }}
        ref={scrollRef}
      >
        <Table
          stickyHeader
          className={classes.table}
          style={{ minWidth: "1325px" }}
          ref={orderRef}
        >
          <EnhancedTableHead
            classes={classes}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {!isOrdersLoading && orders.length === 0 && (
              <TableRow>
                <TableCell align="left" colSpan={11}>
                  <Typography className={classes.headerText}>
                    {`You currently don't have any orders on record that match this search criteria..`}
                  </Typography>
                </TableCell>
              </TableRow>
            )}
            {!isOrdersLoading &&
              orders.length > 0 &&
              orders.map((row) => {
                return (
                  <TableRow
                    key={row.id}
                    hover
                    className={classes.orderHistoryRow}
                    onClick={() => {
                      handleRowClick(row.id);
                    }}
                  >
                    <TableCell align="left">
                      {row.hasCanceledOrDestroyedVariants ? (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Tooltip
                            placement="top-start"
                            title="Has Order Items that have been canceled or destroyed"
                          >
                            <WarningIcon
                              fontSize="small"
                              style={{ margin: "0 5px 0 0" }}
                            />
                          </Tooltip>
                          {row.id}
                        </div>
                      ) : (
                        row.id
                      )}
                    </TableCell>
                    <TableCell align="left">
                      {row.orderAnaplanSubCodes.join(" ")}
                    </TableCell>
                    <TableCell align="left">
                      {row.anaplanProgramTypes.join(" ")}
                    </TableCell>
                    <TableCell align="left">{row.type}</TableCell>
                    <TableCell align="left">{row.user}</TableCell>
                    <TableCell align="left">
                      {row.address?.name ?? row.toInventoryAllocation}
                    </TableCell>
                    <TableCell>
                      {row.distributorAbn ? row.distributorAbn : "---"}
                    </TableCell>
                    <TableCell align="left">
                      {row.address?.state?.code ?? row.subStateName}
                    </TableCell>
                    <TableCell align="left">{row.territoryName}</TableCell>
                    {row.program.split(", ").length > 1 ? (
                      <Tooltip placement="left" title={row.program}>
                        <TableCell align="left">
                          {row.program.split(", ")[0]}
                          <MoreHorizIcon
                            fontSize="small"
                            color="inherit"
                            style={{ float: "right" }}
                          />
                        </TableCell>
                      </Tooltip>
                    ) : (
                      <TableCell align="left">{row.program}</TableCell>
                    )}
                    {row.brand.length > 1 ? (
                      <Tooltip
                        placement="left"
                        title={`${row.brand.join(", ")}`}
                      >
                        <TableCell align="left">
                          {row.brand[0]}
                          <MoreHorizIcon
                            fontSize="small"
                            color="inherit"
                            style={{ float: "right" }}
                          />
                        </TableCell>
                      </Tooltip>
                    ) : (
                      <TableCell align="left">{row.brand[0]}</TableCell>
                    )}
                    <TableCell align="left">{row.businessUnit}</TableCell>
                    <TableCell align="left">{row.orderDate}</TableCell>
                    <TableCell align="left">{row.totalQty}</TableCell>
                    <TableCell align="left">
                      {formatMoney(row.totalProductCost)}
                    </TableCell>
                    <TableCell align="left">
                      {formatMoneyString(row.totalBeaconCost)}
                    </TableCell>
                    <TableCell align="left">
                      {formatMoney(row.totalEstFreight, false)}
                    </TableCell>
                    <TableCell align="left">
                      {formatMoney(row.totalEstTax, false)}
                    </TableCell>
                    <TableCell align="left">
                      {formatMoney(row.totalEstExtendedCost)}
                    </TableCell>
                    <TableCell align="left">
                      {row.status[0].toUpperCase() + row.status.slice(1)}
                    </TableCell>
                    <TableCell align="left">{row.approvalLogging}</TableCell>
                  </TableRow>
                );
              })}
            {isOrdersLoading && (
              <TableRow>
                <TableCell align="left" colSpan={11}>
                  <CircularProgress />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

OrderHistoryTable.propTypes = {
  orders: PropTypes.array,
  handleSort: PropTypes.func.isRequired,
  isOrdersLoading: PropTypes.bool.isRequired,
  scrollRef: PropTypes.any.isRequired,
};

export default OrderHistoryTable;
