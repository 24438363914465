import { createSlice } from "@reduxjs/toolkit";

import { buildFilters } from "../../../api/apiFunctions";
import { axiosGetWithNext, axiosPost } from "../../../api/axiosCalls";
import { setError } from "../errorSlice";
import {
  resetStepperValue,
  setIsStepper,
  startGlobalLoad,
  stopGlobalLoad,
  updateStepperValue,
} from "../globalLoadSlice";
import { mapOrderHistoryVariants } from "../ordering/maps";
import {
  setFailure as patchFailure,
  setIsLoading as patchLoading,
  patchSuccess,
} from "../patchOrderSlice";
import { buildTriggeredRuleQuery } from "./helpers";
import { mapCompItems } from "./maps";

let initialState = {
  isLoading: false,
  isNextLoading: false,
  isUpdateLoading: false,
  nextLink: null,
  pendingNextLink: null,
  items: [],
  pendingOrderVariants: [],
  selectedItems: [],
  compItemReport: [],
  triggerCSVDownload: false,
  error: null,
};

const loadingFailed = (state, action) => {
  const { error } = action.payload;
  state.isLoading = false;
  state.error = error;
};

const complianceItemsSlice = createSlice({
  name: "complianceItems",
  initialState,
  reducers: {
    setIsLoading(state) {
      state.isLoading = true;
    },
    setNextIsLoading(state) {
      state.isNextLoading = true;
    },
    setUpdateLoading(state) {
      state.isUpdateLoading = true;
    },
    getComplianceItemsSuccess(state, action) {
      const { items, nextLink } = action.payload;
      state.nextLink = nextLink;
      state.items = [...items];
      state.isLoading = false;
      state.error = null;
    },
    getNextComplianceItemsSuccess(state, action) {
      const { items, nextLink } = action.payload;
      state.nextLink = nextLink;
      state.items = state.items.concat(items);
      state.isNextLoading = false;
      state.error = null;
    },
    getPendingOrderVariantsSuccess(state, action) {
      const { items, nextLink } = action.payload;
      state.pendingNextLink = nextLink;
      state.pendingOrderVariants = [...items];
      state.isLoading = false;
      state.error = null;
    },
    approveDenySuccess(state, action) {
      const { ids, status } = action.payload;
      const updatedRules = state.items.map((item) => {
        if (ids.includes(item.id)) {
          return {
            ...item,
            status: status,
          };
        } else return { ...item };
      });
      state.items = updatedRules;
      state.isUpdateLoading = false;
      state.selectedItems = [];
      state.error = null;
    },
    updateCompItemSelection(state, action) {
      const { selectedItems } = action.payload;
      state.selectedItems = selectedItems;
    },
    cancelCompItem(state, action) {
      const { id } = action.payload;
      const filteredItems = state.pendingOrderVariants.filter(
        (item) => item.id !== id
      );
      state.pendingOrderVariants = filteredItems;
      state.isUpdateLoading = false;
      state.error = null;
    },
    getCompItemReportSuccess(state, action) {
      const { compItems } = action.payload;
      state.compItemReport = compItems;
      state.triggerCSVDownload = true;
      state.error = null;
    },
    setTriggerCSVFalse(state) {
      state.triggerCSVDownload = false;
    },
    clearCompItemReport(state) {
      state.compItemReport = [];
    },
    resetComplianceItems(state) {
      state.isLoading = false;
      state.isNextLoading = false;
      state.isUpdateLoading = false;
      state.nextLink = null;
      state.pendingNextLink = null;
      state.items = [];
      state.pendingOrderVariants = [];
      state.selectedItems = [];
      state.compItemReport = [];
      state.triggerCSVDownload = false;
      state.error = null;
    },
    setFailure: loadingFailed,
  },
});

export const {
  setIsLoading,
  setNextIsLoading,
  setUpdateLoading,
  getComplianceItemsSuccess,
  getNextComplianceItemsSuccess,
  getPendingOrderVariantsSuccess,
  approveDenySuccess,
  updateCompItemSelection,
  cancelCompItem,
  getCompItemReportSuccess,
  setTriggerCSVFalse,
  clearCompItemReport,
  resetComplianceItems,
  setFailure,
} = complianceItemsSlice.actions;

export default complianceItemsSlice.reducer;

export const fetchTriggeredRules = (filterObject) => async (dispatch) => {
  try {
    dispatch(setIsLoading());
    dispatch(startGlobalLoad());
    const queryString = buildTriggeredRuleQuery(filterObject);
    const response = await axiosGetWithNext(queryString);
    if (response.error) throw response.error;
    const mappedData = mapCompItems(response.data.data);
    dispatch(
      getComplianceItemsSuccess({
        items: mappedData,
        nextLink: response.data.nextLink,
      })
    );
    dispatch(stopGlobalLoad());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Compliance Items" }));
    dispatch(stopGlobalLoad());
  }
};

export const fetchNextTriggeredRules = (url) => async (dispatch) => {
  try {
    dispatch(setNextIsLoading());
    dispatch(startGlobalLoad());
    const response = await axiosGetWithNext(url);
    if (response.error) throw response.error;
    const mappedData = mapCompItems(response.data.data);
    dispatch(
      getNextComplianceItemsSuccess({
        items: mappedData,
        nextLink: response.data.nextLink,
      })
    );
    dispatch(stopGlobalLoad());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Compliance Items" }));
    dispatch(stopGlobalLoad());
  }
};

export const fetchTriggeredRulesByOrders = (orderIds) => async (dispatch) => {
  try {
    dispatch(setIsLoading());
    dispatch(startGlobalLoad());
    const ovQuery = buildFilters(
      {
        orderVariantIds: orderIds,
        pageSize: orderIds.length,
      },
      "",
      "",
      "/api/order-variants",
      "history-items"
    );
    const ovResponse = await axiosGetWithNext(ovQuery);
    if (ovResponse.error) throw ovResponse.error;
    const mappedData = mapOrderHistoryVariants(ovResponse.data.data).filter(
      (item) =>
        item.triggeredRules.length > 0 ||
        item.triggeredPriorApprovalRules.length > 0
    );
    dispatch(
      getPendingOrderVariantsSuccess({
        items: mappedData,
        nextLink: ovResponse.data.nextLink,
      })
    );
    dispatch(stopGlobalLoad());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Compliance Items" }));
    dispatch(stopGlobalLoad());
  }
};

export const approveDenyPriorApprovalItem =
  (items, approving) => async (dispatch) => {
    try {
      dispatch(setUpdateLoading());
      dispatch(patchLoading());
      for (let i = 0; i < items.length; i++) {
        let response = await axiosPost(
          approving
            ? `/api/triggered-rules/${items[i].id}/approve/`
            : `/api/triggered-rules/${items[i].id}/deny`,
          {
            data: {
              id: items[i].id,
              type: "triggered-rule",
              attributes: {
                note: items[i].note,
              },
            },
          }
        );
        if (response.error) throw response.error;
      }
      dispatch(
        approveDenySuccess({
          ids: items.map((item) => item.id),
          status: approving ? "Approved" : "Denied",
        })
      );
      dispatch(patchSuccess());
    } catch (err) {
      dispatch(setFailure({ error: err }));
      dispatch(patchFailure({ error: err }));
      dispatch(setError({ error: err, source: "Compliance Items" }));
    }
  };

export const fetchCompItemReport = () => async (dispatch, getState) => {
  const filterObject = getState().filters;
  try {
    dispatch(
      setIsStepper({
        stepBool: true,
        stepTitle: "Generating Compliance Item Report",
      })
    );
    let compItemArray = [];
    let stepValue = 10;

    const queryString = buildTriggeredRuleQuery(filterObject);
    let initialResponse = await axiosGetWithNext(queryString);
    if (initialResponse.error) throw initialResponse.error;

    let initialMappedData = mapCompItems(initialResponse.data.data);
    compItemArray = compItemArray.concat(initialMappedData);

    let nextLink = initialResponse.data.nextLink;
    dispatch(updateStepperValue({ value: stepValue }));

    if (nextLink) {
      const pageSize = initialResponse.data.data.length;
      let fetchCount =
        Math.ceil(initialResponse.data.totalEntries / pageSize) - 1;
      stepValue = parseFloat((90 / fetchCount).toFixed(2));

      for (let i = 0; i < fetchCount; i++) {
        let nextResponse = await axiosGetWithNext(nextLink);
        if (nextResponse.error) throw nextResponse.error;
        nextLink = nextResponse.data.nextLink;
        let mappedData = mapCompItems(nextResponse.data.data);
        compItemArray = compItemArray.concat(mappedData);
        dispatch(updateStepperValue({ value: stepValue }));
      }
    } else {
      dispatch(updateStepperValue({ value: 90 }));
    }

    dispatch(resetStepperValue());
    dispatch(getCompItemReportSuccess({ compItems: compItemArray }));
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(
      setError({ error: err.toString(), source: "Compliance Items Reports" })
    );
    dispatch(resetStepperValue());
  }
};
