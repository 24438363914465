/** @jsxImportSource @emotion/react */
import "twin.macro";

import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { useDispatch, useSelector } from "react-redux";

import { Container, Tab, Tabs, TextField, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { sub } from "date-fns";
import { setFiltersOpen } from "src/redux/slices/filterSlice";

import ComplianceOverrideTable from "../components/Compliance/ComplianceOverrideTable";
import OverrideListExportButton from "../components/Compliance/OverrideListExportButton";
import OverrideListTable from "../components/Compliance/OverrideListTable";
import OverrideNoteModal from "../components/Compliance/OverrideNoteModal";
import { DatePicker } from "../components/Forms/DefaultInputs";
import ItemPreviewModal from "../components/ItemPreview/ItemPreviewModal";
import { StyledButton } from "../components/StyledComponents";
import OrderPatchLoading from "../components/Utility/OrderPatchLoading";
import { useInput } from "../hooks/InputHooks";
import {
  createOverrides,
  fetchOverrideList,
  fetchRulesWithOverrides,
  resetOverrideRules,
  resetOverrides,
} from "../redux/slices/compliance/complianceRulesSlice";

/*
Some rules refer to an item type itself, and will cause items of that type to be unavailable to order
in certain states. This view gives users the option to search for an item, see all rules associated
with that item, and override any of them so the item can be ordered.
*/

const useStyles = makeStyles((theme) => ({
  ...theme.global,
}));

const ComplianceOverrides = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [selected, setSelected] = useState([]);
  const [numberSelectable, setNumberSelectable] = useState(0);
  const [hasSearched, setHasSearched] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [fromDate, setFromDate] = useState(sub(new Date(), { months: 3 }));
  const [toDate, setToDate] = useState(new Date());

  const [previewModal, setPreviewModal] = useState({
    open: false,
    item: null,
  });
  const [overrideModal, setOverrideModal] = useState(false);

  const {
    value: itemNumber,
    bind: bindItemNumber,
    reset: resetItemNumber,
  } = useInput("");

  const { isUpdateLoading, isLoading, overrideRules } = useSelector(
    (state) => state.complianceRules
  );

  const handlePreview = (item) => setPreviewModal({ open: true, item: item });

  const handleSelect = (identifier) => {
    const selectedIndex = selected.indexOf(identifier);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, identifier);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (identifier) => selected.indexOf(identifier) !== -1;

  const handleSelectAll = () => {
    if (selected.length === numberSelectable) {
      setSelected([]);
    } else {
      setSelected(
        overrideRules
          .filter((r) => !Boolean(r.overrideId))
          .map((r) => r.identifier)
      );
    }
  };

  const handleGetRuleListWithOverrides = () => {
    dispatch(resetOverrideRules());
    dispatch(fetchRulesWithOverrides(itemNumber));
    setHasSearched(true);
    resetItemNumber();
  };

  const handleFetchOverrideList = () => {
    dispatch(fetchOverrideList({ fromDate, toDate }));
  };

  const handleCreateOverrides = (note) => {
    const currentSelectedOverrides = selected.map((identifier) =>
      overrideRules.find((o) => o.identifier === identifier)
    );

    dispatch(createOverrides(currentSelectedOverrides, note));
    setSelected([]);
  };

  useEffect(() => {
    if (hasSearched && !isLoading && overrideRules.length > 0) {
      let selectable = overrideRules.filter((r) => !Boolean(r.overrideId));
      setNumberSelectable(selectable.length);
      setHasSearched(false);
    }
  }, [hasSearched, isLoading, overrideRules, setHasSearched]);

  useEffect(() => {
    dispatch(setFiltersOpen({ open: false }));
    return () => {
      dispatch(resetOverrides());
      dispatch(resetOverrideRules());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {previewModal.open && (
        <ItemPreviewModal
          type="catalog"
          currentItem={previewModal.item}
          handleClose={() => {
            setPreviewModal({ open: false, item: null });
          }}
          previewModal={previewModal.open}
        />
      )}
      {overrideModal && (
        <OverrideNoteModal
          open={overrideModal}
          handleSubmit={handleCreateOverrides}
          handleClose={() => {
            setOverrideModal(false);
          }}
        />
      )}
      <Helmet>
        <title>RTA | Compliance Overrides</title>
      </Helmet>
      <Container className={classes.mainWrapper} tw="mb-8">
        <div className={classes.titleBar}>
          <Typography className={classes.titleText}>
            Compliance Overrides
          </Typography>
        </div>
        <br />
        <Tabs
          value={tabIndex}
          onChange={(_, idx) => setTabIndex(idx)}
          aria-label="basic tabs example"
          tw="border-b border-neutral-200"
        >
          <Tab label="Manage Overrides" />
          <Tab label="List Overrides" />
        </Tabs>
        {tabIndex === 0 && (
          <>
            <div tw="flex justify-between items-start my-6">
              <div tw="flex gap-2">
                <TextField
                  variant="outlined"
                  color="secondary"
                  size="small"
                  type="text"
                  label="Sequence Number"
                  {...bindItemNumber}
                />
                <StyledButton
                  cta
                  disabled={
                    itemNumber.length === 0 || isUpdateLoading || isLoading
                  }
                  onClick={handleGetRuleListWithOverrides}
                >
                  SEARCH
                </StyledButton>
              </div>
              <StyledButton
                cta
                disabled={selected.length === 0 || isUpdateLoading || isLoading}
                onClick={() => setOverrideModal(true)}
              >
                OVERRIDE
              </StyledButton>
            </div>
            <ComplianceOverrideTable
              handlePreviewOpen={handlePreview}
              selected={selected}
              handleSelect={handleSelect}
              handleSelectAll={handleSelectAll}
              isSelected={isSelected}
              numberSelectable={numberSelectable}
            />
          </>
        )}
        {tabIndex === 1 && (
          <>
            <div tw="flex justify-between items-start my-6">
              <div tw="flex gap-2">
                <DatePicker
                  label="From Date"
                  value={fromDate}
                  onChange={setFromDate}
                />
                <DatePicker
                  label="To Date"
                  value={toDate}
                  onChange={setToDate}
                />
                <StyledButton
                  cta
                  disabled={isUpdateLoading || isLoading}
                  onClick={handleFetchOverrideList}
                  loading={isLoading}
                >
                  SEARCH
                </StyledButton>
              </div>
              <OverrideListExportButton />
            </div>
            <OverrideListTable />
          </>
        )}
        <OrderPatchLoading />
      </Container>
    </>
  );
};

export default ComplianceOverrides;
