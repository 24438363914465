/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useDispatch } from "react-redux";

import { Dialog, DialogContent, Typography } from "@mui/material";

import { CreateProgramForm } from "@features/programs";

import { createProgram } from "../../../redux/slices/planningTool/programSlice";

const CreateNationalProgram = ({ handleClose }) => {
  const dispatch = useDispatch();

  const handleSubmit = (data) => {
    dispatch(createProgram(data));
  };

  return (
    <Dialog fullWidth open onClose={handleClose} maxWidth="sm">
      <DialogContent>
        <Typography style={{ textAlign: "center" }}>
          Create National Program
        </Typography>

        <CreateProgramForm
          onSubmit={handleSubmit}
          programTypeIds={["1", "4"]}
        />
      </DialogContent>
    </Dialog>
  );
};

export default CreateNationalProgram;
