import React, { useEffect, useState } from "react";

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";

import { axiosGet } from "../../../api/axiosCalls";

const SubstateSelector = ({
  selectedSubstateId,
  setSelectedSubstateId,
  state,
  setError,
  disabled,
}) => {
  const [substateOptions, setSubstateOptions] = useState([]);

  useEffect(() => {
    const fetchSubstates = async () => {
      const subStates = await axiosGet(
        `/api/sub-states?filter[state_id]=${state.id}&filter[is-control]=false`
      );
      if (!subStates.error) {
        setSubstateOptions(subStates.data);
      } else {
        setError(subStates.error);
      }
    };
    if (state.id) {
      fetchSubstates();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  return (
    <FormControl
      fullWidth
      variant="outlined"
      style={{
        marginBottom: "15px",
      }}
      disabled={disabled}
    >
      <InputLabel id="state-select">Substate</InputLabel>
      <Select
        variant="outlined"
        label="Substate"
        name="substate"
        labelId="state-select"
        id="state"
        value={selectedSubstateId}
        onChange={(e) => {
          setSelectedSubstateId(e.target.value);
        }}
        MenuProps={{
          style: { zIndex: "16000" },
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        }}
      >
        {substateOptions.map((substate, index) => (
          <MenuItem value={substate.id} key={index}>
            <Typography>{substate.name}</Typography>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SubstateSelector;
