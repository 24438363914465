import { buildFilters } from "../../../api/apiFunctions";
import { separateByComma } from "../../../utility/utilityFunctions";

export const buildOrderVariantSummaryQuery = (filterObject) => {
  let customString = `filter[order-statuses]=${filterObject.orderStatuses}&filter[order-submitted-at-range]=${filterObject.dateRange}`;
  let queryString = buildFilters(
    filterObject,
    customString,
    "sort=-item-number",
    "/api/order-variant-summaries",
    "reports"
  );
  queryString += "&page[size]=100";

  return queryString;
};

export const buildRoadmapForecastQuery = (filterObject) => {
  let dateRangeString = filterObject.dateRange
    ? `filter[submitted-at-range]=${filterObject.dateRange}`
    : "";
  let itemProgramString =
    filterObject.itemProgram && filterObject.itemProgram.length > 0
      ? `filter[item-program-ids]=${separateByComma(
          filterObject.itemProgram,
          "id"
        )}`
      : "";
  let orderProgramString =
    filterObject.orderProgram && filterObject.orderProgram.length > 0
      ? `filter[order-program-ids]=${separateByComma(
          filterObject.orderProgram,
          "id"
        )}`
      : "";

  let customString = [dateRangeString, itemProgramString, orderProgramString]
    .filter((s) => s.length > 0)
    .join("&");

  return buildFilters(
    filterObject,
    customString,
    "",
    "/api/roadmap-report",
    "reports"
  );
};

export const buildReportByIdQuery = (id, filterObject) => {
  let filterStrings = [];

  // Add order status filter if present
  if (filterObject.orderStatus) {
    filterStrings.push(`filter[order-status]=${filterObject.orderStatus}`);
  }

  // Add filters specific to "focus-presentation"
  if (id === "focus-presentation") {
    if (filterObject.isNational) {
      filterStrings.push(`filter[national]=t`);
    }

    if (filterObject.dateRange) {
      filterStrings.push(
        `filter[delivery-date-range]=${filterObject.dateRange}`
      );
    }
  } else {
    // For IDs other than "focus-presentation", add program type filters
    if (filterObject.programType === "is-pre-order") {
      filterStrings.push("filter[is-ad-hoc]=false");
    } else if (filterObject.programType === "is-ad-hoc") {
      filterStrings.push("filter[is-ad-hoc]=true");
    }

    // Add purchaser select filter
    if (filterObject.isPurchaserSelect === "select") {
      filterStrings.push("filter[is-purchaser-select]=true");
    } else if (filterObject.isPurchaserSelect === "gallo") {
      filterStrings.push("filter[is-purchaser-select]=false");
    }
  }

  let customString = filterStrings.join("&");

  return buildFilters(
    filterObject,
    customString,
    "",
    `/api/reports/${id}`,
    "reports"
  );
};

export const buildInvoicingReportQuery = (filterObject) => {
  let poTypeString =
    filterObject.orderType !== "all"
      ? `&filter[po-type]=${filterObject.orderType}`
      : "";
  let customString =
    `filter[shipped-at-range]=${filterObject.dateRange}` + poTypeString;
  return buildFilters(
    filterObject,
    customString,
    "",
    "/api/invoicing-report",
    "reports"
  );
};

export const buildVariantAllocationQuery = (filterObject) => {
  let territoryFilter =
    filterObject.territories.length > 0
      ? `filter[territory-ids]=${separateByComma(
          filterObject.territories,
          "id"
        )}`
      : null;

  let skuFilter =
    filterObject.sku.length > 0 ? `filter[sku]=${filterObject.sku}` : null;

  let filters = [territoryFilter, skuFilter].filter((f) => f).join("&");
  let filterPreCursor = filters.length !== 0 ? "?" : "";

  return "/api/variant-allocation-report" + filterPreCursor + filters;
};
