import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  Chip,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import PropTypes from "prop-types";

import { openRFQModal } from "../../../redux/slices/planningTool/rfqSlice";
import StatusChip from "../../Utility/StatusChip";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
}));

const RFQSearchTable = ({ handleSort, rfqItems, isLoading, scrollRef }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const statusMap = {
    draft: "",
    sent: "info",
    canceled: "error",
  };

  const handleViewRfq = (e, id) => {
    e.stopPropagation();
    dispatch(openRFQModal({ loadRFQ: id }));
  };

  const handleGoToProgramView = (pId, id) => {
    navigate(`/planning/rfqs/program/${pId}/item/${id}`);
  };

  const headCells = [
    { id: "itemNumber", label: "Sequence #" },
    {
      id: "id",
      label: "RFQ",
      fn: (id) => (
        <Chip label="View RFQ" onClick={(e) => handleViewRfq(e, id)} />
      ),
    },
    { id: "brands", label: "Brand(s)" },
    { id: "programName", label: "Program" },
    { id: "itemType", label: "Item Type" },
    { id: "supplier", lable: "Supplier" },
    { id: "round", label: "Quote Round" },
    {
      id: "status",
      label: "Status",
      fn: (v) => <StatusChip status={statusMap[v]} label={v} />,
    },
    { id: "dateCreated", label: "Date Submitted" },
  ];

  return (
    <>
      <TableContainer
        className={classes.tableContainer}
        style={{ maxHeight: "Calc(100vh - 275px)" }}
        ref={scrollRef}
      >
        <Table
          stickyHeader
          className={classes.table}
          aria-label="planning-tool-rfqs-table"
        >
          <TableHead>
            <TableRow>
              {headCells.map((cell) => (
                <TableCell
                  className={classes.headerText}
                  key={cell.id}
                  align="left"
                >
                  {cell.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {!isLoading && rfqItems.length === 0 && (
              <TableRow>
                <TableCell
                  align="left"
                  colSpan={headCells.length}
                  className={classes.headerText}
                >
                  There are currently no RFQs that match this search criteria..
                </TableCell>
              </TableRow>
            )}
            {!isLoading &&
              rfqItems.length > 0 &&
              rfqItems.map((row, i) => (
                <TableRow
                  key={i}
                  className={classes.clickableRow}
                  onClick={() =>
                    handleGoToProgramView(row.programId, row.itemId)
                  }
                >
                  {headCells.map((cell, index) => (
                    <TableCell key={`cell-${index}`} align="left">
                      {typeof cell.fn === "function"
                        ? cell.fn(row[cell.id], row)
                        : row[cell.id]}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            {isLoading && (
              <TableRow>
                <TableCell align="left" colSpan={headCells.length}>
                  <CircularProgress />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

RFQSearchTable.propTypes = {
  handleSort: PropTypes.func.isRequired,
  rfqItems: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  scrollRef: PropTypes.any,
};

export default React.memo(RFQSearchTable, (prev, next) => {
  return prev.rfqItems === next.rfqItems && prev.isLoading === next.isLoading;
});
