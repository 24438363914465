/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useState } from "react";

import { Close } from "@mui/icons-material";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";

import { isBefore } from "date-fns";
import { DatePicker } from "src/components/Forms/DefaultInputs";
import { StyledButton } from "src/components/StyledComponents";
import { formatUtcDate, utcDate } from "src/utility/utilityFunctions";

import { useMutateError } from "@features/errors";
import { OrderSetVariant } from "@models/OrderSetVariant";

import useUpdateOrderSetVariantMutation from "./data/mutations/useUpdateOrderSetVariantMutation";

const EditOrderVariantDeliveryDateModal = ({
  orderSetVariant,
  onClose,
}: {
  orderSetVariant: OrderSetVariant;
  onClose: () => void;
}) => {
  const setMutateError = useMutateError();
  const useUpdateOrderSetVariant = useUpdateOrderSetVariantMutation();

  const [inMarketDate, setInMarketDate] = useState<Date | null>(
    new Date(
      orderSetVariant.inMarketDate ?? orderSetVariant.standardDeliveryDate
    )
  );

  const handleSave = async () => {
    if (!inMarketDate) return;
    const isRush = isBefore(
      inMarketDate,
      utcDate(orderSetVariant.standardDeliveryDate)
    );
    useUpdateOrderSetVariant.mutate(
      {
        id: orderSetVariant.id,
        inMarketDate: inMarketDate.toISOString(),
        isRush,
      },
      {
        onSuccess: () => onClose(),
        onError: (err) => setMutateError(err, "update orderSetVariant"),
      }
    );
  };
  return (
    <Dialog open onClose={onClose} fullWidth>
      <DialogTitle tw="flex justify-between items-center">
        Editing Delivery Date for item #{orderSetVariant.variant.variantSku}
        <IconButton onClick={onClose} edge="end">
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent tw="space-y-6">
        <div tw="text-neutral-500">
          Standard Delivery Date:{" "}
          {formatUtcDate(orderSetVariant.standardDeliveryDate)}
        </div>
        <DatePicker
          label="Requested Delivery Date"
          disabled={useUpdateOrderSetVariant.isPending}
          disablePast
          value={inMarketDate}
          onChange={setInMarketDate}
        />
      </DialogContent>
      <DialogActions>
        <StyledButton
          cta
          onClick={handleSave}
          loading={useUpdateOrderSetVariant.isPending}
        >
          Save And Close
        </StyledButton>
      </DialogActions>
    </Dialog>
  );
};

export default EditOrderVariantDeliveryDateModal;
