/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Close } from "@mui/icons-material";
import {
  Box,
  Dialog,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  Typography,
  capitalize,
} from "@mui/material";
import { Alert, AlertTitle } from "@mui/material";

import {
  clearImportStatus,
  uploadVariablePricing,
} from "../../redux/slices/variablePricing/variablePricingSlice";
import FileUpload from "../Utility/FileUpload";

const ModalContent = () => {
  const dispatch = useDispatch();

  const { importStatus } = useSelector((state) => state.variablePricing);

  const handleFileUpload = (file) => {
    dispatch(uploadVariablePricing(file));
  };

  useEffect(() => {
    dispatch(clearImportStatus());
  }, []);

  return (
    <Box tw="px-16 py-8">
      {importStatus.status !== "success" && (
        <FileUpload handleSubmit={handleFileUpload} accept={["text/csv"]} />
      )}
      {importStatus.status && (
        <Alert
          tw="mt-4"
          severity={importStatus.status}
          onClose={() => dispatch(clearImportStatus())}
        >
          <AlertTitle>{capitalize(importStatus.status)}</AlertTitle>
          {importStatus.errors?.length > 0 ? (
            <List>
              {(importStatus.errors || []).map((error) => (
                <ListItem>{error}</ListItem>
              ))}
            </List>
          ) : (
            <Typography variant="body1">Successfully uploaded file</Typography>
          )}
        </Alert>
      )}
    </Box>
  );
};

const VariablePricingImportModal = ({ open, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <Box tw="flex justify-between items-start">
        <DialogTitle>Import Variable Pricing</DialogTitle>
        <IconButton aria-label="close" onClick={onClose} size="large">
          <Close />
        </IconButton>
      </Box>
      <ModalContent />
    </Dialog>
  );
};

export default VariablePricingImportModal;
