import { useSelector } from "react-redux";

import { useSubStates } from "./subStateQueries";

export default function useCurrentRegionalTerritorySubStates() {
  const territoryId = useSelector((state: any) => state.user.currentTerritory);
  const territory = useSelector((state: any) =>
    state.territories.territoryList.find((t) => t.id === territoryId)
  );

  const isRegional = !!territoryId && territory?.type === "Regional";

  const { data = [] } = useSubStates({ territoryId }, { enabled: isRegional });

  return {
    data,
    subStateIds: data?.map((obj) => obj.id) ?? [],
    isRegional,
  };
}
