/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useEffect, useRef, useState } from "react";
import { CSVReader } from "react-papaparse";

import { Alert, Divider, TextField } from "@mui/material";

import { useQuery } from "@tanstack/react-query";
import { isDate } from "date-fns";
import { without } from "lodash";
import { SmartTable } from "src/components/SmartTable";

import { PurchaseOrder } from "@models/PurchaseOrder";
import client from "@services/api";
import { CSVLink } from "@utils/csv";

import { axiosPut } from "../api/axiosCalls";
import { executeOnEnter } from "../components/Filtering/utility";
import { Contained, StyledButton } from "../components/StyledComponents";
import {
  formatDateString,
  formatMoneyString,
  kebabCaseKeys,
} from "../utility/utilityFunctions";

const expectedCSVColumns = [
  "Gallo PO #",
  "Sequence #",
  "Freight Amount",
  "Invoice #",
  "Invoice Date",
];

const POLookupTable = () => {
  const [query, setQuery] = useState("");
  const [poId, setPoId] = useState("");
  const { data, refetch, ...tableProps } = useQuery({
    queryKey: ["purchase-orders", poId],
    queryFn: () =>
      client
        .get<PurchaseOrder[]>(`purchase-orders`, {
          params: { filter: { id: poId } },
        })
        .then((res) => res.data),
    enabled: !!poId,
  });

  const brokerFreights = data?.[0]?.brokerFreight;

  const handleSearch = () => {
    if (poId && poId === query) {
      refetch();
    } else {
      setPoId(query);
    }
  };

  return (
    <div tw="space-y-6">
      <div tw="flex space-x-4">
        <TextField
          label="Gallo PO #"
          size="small"
          value={query}
          onChange={(e) => setQuery(e.target.value.replace(/[^0-9]*/g, ""))}
          onKeyDown={executeOnEnter(handleSearch)}
        />
        <StyledButton cta onClick={handleSearch} loading={tableProps.isLoading}>
          Search
        </StyledButton>
      </div>
      {data && data.length === 0 && (
        <Alert severity="info">No results for "{poId}".</Alert>
      )}
      {brokerFreights && (
        <SmartTable
          rows={brokerFreights}
          columns={[
            { id: "purchaseOrderId", label: "Gallo PO #" },
            { id: "invoiceNumber", label: "Invoice #" },
            {
              id: "invoiceDate",
              label: "Invoice Date",
              render: formatDateString,
            },
            {
              id: "cost",
              label: "Freight Amount",
              align: "right",
              render: formatMoneyString,
            },
          ]}
          {...tableProps}
        />
      )}
    </div>
  );
};

const FreightForwarderDashboard = () => {
  const csvRef = useRef<any>(null);
  const [success, setSuccess] = useState<null | string>(null);
  const [errors, setErrors] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);

  const formatAndValidate = (data) => {
    setErrors([]);
    if (!data.length) {
      setErrors([
        "Please add one or more rows of data to your file. Keep in mind the first row should not be modified and kept as the header",
      ]);
      return;
    }
    // Check for missing columns
    const columns = Object.keys(data[0].data);
    const missingColumns = without(expectedCSVColumns, ...columns);
    if (missingColumns.length) {
      setErrors(
        missingColumns.map(
          (c) =>
            `Missing column header "${c}", header cells are case sensitive.`
        )
      );
      return;
    }

    const dataErrors: string[] = [];
    const mappedData = data.flatMap(({ data: row }, lineNumber) => {
      if (Object.values(row).every((d) => d === "")) {
        // Reject blank lines
        return [];
      }
      const formatError = (col: string, expected: string) =>
        `Line ${
          lineNumber + 1
        }: Invalid value for "${col}". Expected ${expected} but received ${
          row[col]
        }`;

      const id = Number(row["Gallo PO #"].replace("#", ""));
      if (isNaN(id)) dataErrors.push(formatError("Gallo PO #", "a number"));

      const itemNumber = Number(row["Sequence #"].replace("#", ""));
      if (isNaN(itemNumber))
        dataErrors.push(formatError("Sequence #", "a sequence number"));

      const brokerFreightCost = Number(
        row["Freight Amount"].replace(/[,$]/g, "")
      );
      if (isNaN(brokerFreightCost))
        dataErrors.push(formatError("Freight Amount", "a valid dollar amount"));

      const brokerFreightInvoiceNumber = row["Invoice #"].replace("#", "");

      const brokerFreightInvoiceDate = new Date(row["Invoice Date"]);
      if (!isDate(brokerFreightInvoiceDate)) {
        dataErrors.push(formatError("Invoice Date", "a valid date"));
      }

      return [
        kebabCaseKeys({
          id,
          itemNumber: itemNumber.toString(),
          brokerFreightCost,
          brokerFreightInvoiceDate,
          brokerFreightInvoiceNumber,
        }),
      ];
    });

    if (dataErrors.length) {
      setErrors(dataErrors);
      return;
    }
    return mappedData;
  };

  const handleFileUpload = async (data) => {
    try {
      const mappedData = formatAndValidate(data);
      setSuccess(null);
      if (!mappedData) return;
      const res = await axiosPut("/api/purchase-orders/add-broker-freight", {
        "po-attrs": mappedData,
      });
      if (res.error) {
        let errors: any = res.error;
        if (!Array.isArray(errors)) errors = [errors];
        setErrors(errors);
      } else {
        setSuccess(
          `Successfully updated ${data.length} entr${
            data.length === 1 ? "y" : "ies"
          }.`
        );
      }
    } catch (e: any) {
      console.error(e);
      setErrors([e.toString()]);
    } finally {
      setLoading(false);
    }
  };

  const handleFileUploadError = (e) => {
    console.error(e);
  };

  useEffect(() => {
    setLoading(false);
  }, [errors]);

  return (
    <Contained tw="max-w-[820px] w-full space-y-6">
      <div tw="flex flex-wrap justify-between items-center gap-3">
        <h1 tw="font-bold text-2xl text-neutral-700">
          Freight Forwarder Dashboard
        </h1>
        <div tw="flex gap-4">
          <CSVLink
            data={[expectedCSVColumns]}
            filename="broker-freight-template.csv"
          >
            <StyledButton outlined>Download template</StyledButton>
          </CSVLink>
          <CSVReader
            ref={csvRef}
            onFileLoad={handleFileUpload}
            onError={handleFileUploadError}
            noClick
            noDrag
            config={{
              header: true,
              beforeFirstChunk: () => setLoading(true),
            }}
            noProgressBar
          >
            {() => (
              <StyledButton
                cta
                onClick={(e) => csvRef.current?.open(e)}
                loading={loading}
              >
                Upload Freight Invoices
              </StyledButton>
            )}
          </CSVReader>
        </div>
      </div>

      {errors.length > 0 && (
        <Alert severity="error" onClose={() => setErrors([])}>
          <h3 tw="font-bold text-lg">No entries could be updated.</h3>
          <p>Fix the following errors and try again.</p>
          <br />
          <ul tw="space-y-2 list-inside">
            {errors.map((err, i) => (
              <li key={i} tw="list-disc">
                {err}
              </li>
            ))}
          </ul>
        </Alert>
      )}

      {success && (
        <Alert severity="success" onClose={() => setSuccess("")}>
          {success}
        </Alert>
      )}

      <Divider />

      <POLookupTable />
    </Contained>
  );
};

export default FreightForwarderDashboard;
