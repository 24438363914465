import React, { useEffect, useState } from "react";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
import Helmet from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import GetAppIcon from "@mui/icons-material/GetApp";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";

import HideFiltersButtonToggle from "src/components/Filtering/HideFiltersButtonToggle";

import { CSVLink } from "@utils/csv";

import FilterChipList from "../components/Filtering/FilterChipList";
import ItemRollupTable from "../components/Purchasing/ItemRollupTable";
import { useChannelUpdate, useInitialFilters } from "../hooks/UtilityHooks";
import { setSorted, updateMultipleFilters } from "../redux/slices/filterSlice";
import {
  clearRFPRollupReport,
  fetchNextRFPRollupItems,
  fetchRFPRollupReport,
  setTriggerCSVFalse,
} from "../redux/slices/purchasing/rfpSlice";
import { createNewRFP } from "../redux/slices/purchasing/rfpSlice";
import { formatMoney } from "../utility/utilityFunctions";

/*
The RFPRollup view utilizes the item-rollup endpoint, but groups items only if they are meant to go
to quote, and only if they don't have a quote created for them already.
*/

const defaultFilters = {
  orderType: "pre-order",
  brand: [],
  program: [],
  itemType: [],
  user: [],
  bus: [],
  itemNumber: "",
  sortOrder: "desc",
  sortOrderBy: "approvedDate",
};

const useStyles = makeStyles((theme) => ({
  ...theme.global,
}));

const RFPRollup = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const nextLink = useSelector((state) => state.rfp.nextLink);
  const isNextLoading = useSelector((state) => state.rfp.isNextLoading);

  const handleBottomScroll = () => {
    if (nextLink && !isNextLoading) {
      if (scrollRef.current && scrollRef.current.scrollTop !== 0) {
        dispatch(fetchNextRFPRollupItems(nextLink));
      }
    }
  };

  const scrollRef = useBottomScrollListener(handleBottomScroll, {
    offset: 500,
    debounceOptions: {
      leading: true,
      trailing: false,
    },
  });

  const [itemSelected, setItemSelected] = useState(false);
  const [currentCSV, setCurrentCSV] = useState({ data: [], headers: [] });

  const isRFPItemsLoading = useSelector((state) => state.rfp.isLoading);
  const currentRFPItems = useSelector((state) => state.rfp.rfpItems);
  const selectedRFPItem = useSelector((state) => state.rfp.selectedRFPItem);
  const currentChannel = useSelector((state) => state.user.currentChannel);
  const currentChannelFilter = useSelector((state) => state.filters.channel);
  const filterObject = useSelector((state) => state.filters);
  const rfpRollupReport = useSelector((state) => state.rfp.rfpRollupReport);
  const triggerCSV = useSelector((state) => state.rfp.triggerCSVDownload);

  defaultFilters.channel = currentChannel;

  const handleNewRFP = () => {
    let currentItem = currentRFPItems.find(
      (item) => item.itemId === +selectedRFPItem
    );
    dispatch(
      createNewRFP(
        selectedRFPItem,
        currentItem.programId,
        currentItem.orderVariantIds
      )
    );
  };

  const handleSort = (sortObject) => {
    scrollRef.current.scrollTop = 0;
    dispatch(
      updateMultipleFilters({
        filterObject: {
          sortOrder: sortObject.order,
          sortOrderBy: sortObject.orderBy,
        },
      })
    );
    dispatch(setSorted());
  };

  const handleRFPRollupReport = () => {
    dispatch(fetchRFPRollupReport(filterObject));
  };

  useEffect(() => {
    if (triggerCSV && currentCSV.data.length === 0) {
      let csvHeaders = [
        { label: "Sequence #", key: "itemNumber" },
        { label: "Ordered By", key: "user" },
        { label: "Territory", key: "territory" },
        { label: "Program", key: "program" },
        { label: "Brand", key: "brand" },
        { label: "Project #", key: "projectNum" },
        { label: "Item Type", key: "itemType" },
        { label: "Item Desc.", key: "itemDesc" },
        { label: "Total Ordered", key: "totalQty" },
        { label: "Est. Cost", key: "estCost" },
        { label: "Est. Total", key: "totalEstCost" },
        { label: "Order Approval Date", key: "approvedDate" },
        { label: "In-Market Date", key: "inMarketDate" },
      ];
      let csvData = [];
      rfpRollupReport.forEach((item) => {
        csvData.push({
          itemNumber: item.itemNumber,
          user: item.user,
          territory: item.territory.join(", "),
          program: item.program,
          brand: item.brand,
          projectNum: item.projectNum,
          itemType: item.itemType,
          itemDesc: item.itemDescription,
          totalItem: item.totalQty,
          estCost: formatMoney(item.estCost, true),
          totalEstCost: formatMoney(item.totalEstCost, true),
          approvedDate: item.approvedDate,
          inMarketDate: item.inMarketDate,
        });
      });
      setCurrentCSV({ data: csvData, headers: csvHeaders });
    }
  }, [rfpRollupReport, triggerCSV, currentCSV]);

  useChannelUpdate(currentChannel, currentChannelFilter, dispatch);

  useInitialFilters("itemRollup-rfp", defaultFilters);

  useEffect(() => {
    dispatch(setTriggerCSVFalse());
    dispatch(clearRFPRollupReport());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!triggerCSV && currentCSV.data.length > 0) {
      setCurrentCSV({
        data: [],
        headers: [],
      });
    }
  }, [triggerCSV, currentCSV, setCurrentCSV]);

  return (
    <>
      <Helmet>
        <title>RTA | RFP Rollup</title>
      </Helmet>
      <Container className={classes.mainWrapper}>
        <div className={classes.titleBar}>
          <Typography className={classes.titleText}>RFP Rollup</Typography>
          <div
            style={{
              display: "flex",
              width: "600px",
              justifyContent: "flex-end",
            }}
          >
            <Button
              className={classes.largeButton}
              variant="contained"
              color="secondary"
              disabled={!itemSelected}
              style={{ marginRight: "20px" }}
              onClick={() => {
                handleNewRFP();
                navigate("/purchasing/rfp#new");
              }}
            >
              CREATE PRICE REQUEST
            </Button>
            {!triggerCSV && currentCSV.data.length === 0 && (
              <span>
                <Button
                  className={classes.largeButton}
                  variant="contained"
                  onClick={handleRFPRollupReport}
                  disabled={currentRFPItems.length === 0}
                >
                  GENERATE REPORT
                </Button>
              </span>
            )}
            {triggerCSV && currentCSV.data.length > 0 && (
              <CSVLink
                data={currentCSV.data}
                headers={currentCSV.headers}
                filename="rta_rfp_rollup.csv"
              >
                <Button
                  className={classes.largeButton}
                  variant="contained"
                  color="secondary"
                  startIcon={<GetAppIcon />}
                >
                  EXPORT REPORT
                </Button>
              </CSVLink>
            )}
            {/* <Tooltip title="Print Purchase Order Items">
              <IconButton>
                <PrintIcon color="secondary" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Export File">
              <CSVLink data={currentOrders} headers={csvHeaders}>
              <IconButton>
                <GetAppIcon color="secondary" />
              </IconButton>
              </CSVLink>
            </Tooltip> */}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignContent: "center",
            marginBottom: "10px",
          }}
        >
          <HideFiltersButtonToggle />
          <FilterChipList classes={classes} />
          <br />
        </div>
        <ItemRollupTable
          items={currentRFPItems}
          isItemsLoading={isRFPItemsLoading}
          handleSort={handleSort}
          scrollRef={scrollRef}
          itemSelected={itemSelected}
          setItemSelected={setItemSelected}
          type={"rfp"}
        />
        {isNextLoading && (
          <div style={{ width: "100%" }}>
            <LinearProgress />
          </div>
        )}
        {!isNextLoading && <div style={{ width: "100%", height: "4px" }}></div>}
      </Container>
      <br />
    </>
  );
};

export default RFPRollup;
