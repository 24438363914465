/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useEffect } from "react";
import { useSelector } from "react-redux";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";

import PropTypes from "prop-types";

import { useOnDemandNationalCouponPrograms } from "@features/programs";

import { SelectField } from "../Forms/DefaultInputs";

const FiltersNationalCoupon = ({ handleFilters }) => {
  const { onDemandProgram } = useSelector((state) => state.currentOrder);
  const { program: programFilter } = useSelector((state) => state.filters);
  const programFilterId = programFilter?.[0]?.id;

  const setProgramFilter = (id) => {
    handleFilters([programs.find((p) => p.id === id)], "program", "item-all");
  };

  const programs = useOnDemandNationalCouponPrograms();
  const options = programs.map((p) => ({ label: p.name, value: p.id }));

  useEffect(() => {
    if (onDemandProgram && programFilter?.[0]?.id !== onDemandProgram.id) {
      const program = programs.find((p) => p.id === onDemandProgram.id);
      if (program) {
        handleFilters([program], "program", "item-all");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onDemandProgram?.id, programs]);

  useEffect(() => {
    if (programs.length > 0 && !programFilterId) {
      handleFilters([programs[0]], "program", "item-all");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [programs, programFilterId]);

  return (
    <>
      <List>
        <h2 tw="font-medium text-neutral-700">Select Program</h2>
        <ListItem>
          <SelectField
            label="Program"
            options={options}
            fullWidth
            value={programFilterId ?? ""}
            onChange={(e) => setProgramFilter(e.target.value)}
          />
        </ListItem>
      </List>
    </>
  );
};

FiltersNationalCoupon.propTypes = {
  handleFilters: PropTypes.func.isRequired,
};

export default FiltersNationalCoupon;
