import { useMutation, useQueryClient } from "@tanstack/react-query";

import { OrderSet } from "@models/OrderSet";
import { OrderSetVariant } from "@models/OrderSetVariant";
import client from "@services/api";

import { orderSetsKeyFactory } from "../../../queries/orderSetQueries";
import useOrderSetId from "../useOrderSetId";

export default function useUpdateOrderSetVariantMutation() {
  const orderSetId = useOrderSetId();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ id, ...data }: { id: string; [key: string]: any }) => {
      return client
        .update<OrderSetVariant>(`order-set-variants/${id}`, {
          __type: "order-set-variant",
          ...data,
        })
        .then((data) => data.data);
    },
    onSuccess: (orderSetVariant) => {
      queryClient.setQueryData(
        orderSetsKeyFactory.detail(orderSetId).queryKey,
        (orderSet: OrderSet) => ({
          ...orderSet,
          orderSetVariants: orderSet.orderSetVariants.map((osv) =>
            osv.id === orderSetVariant.id ? orderSetVariant : osv
          ),
        })
      );
    },
    onError: () => {
      queryClient.invalidateQueries({
        queryKey: orderSetsKeyFactory.detail(orderSetId).queryKey,
      });
    },
  });
}
