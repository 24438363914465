import _ from "lodash";

const yearToFY = (year: number) => `FY${year - 2000}`;

// FY23 is when we introduced budgets
const START_YEAR = 2023;
const CURRENT_YEAR = new Date().getFullYear();
const END_YEAR = CURRENT_YEAR + 1;

// The fiscal year starts in October. Eg. Oct 1st 2023 is the start of FY24
const getCurrentFiscalYear = () =>
  yearToFY(new Date().getMonth() > 9 ? CURRENT_YEAR : CURRENT_YEAR + 1);

const fiscalYearOptions = _.range(START_YEAR, END_YEAR + 1)
  .reverse()
  .map(yearToFY)
  .map((fy) => ({ id: fy, name: fy }));

const fiscalYear = {
  current: getCurrentFiscalYear,
  options: fiscalYearOptions,
};

export default fiscalYear;
