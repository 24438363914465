import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

import PropTypes from "prop-types";

const ocmYears = [
  { id: 2018, name: "2018" },
  { id: 2019, name: "2019" },
  { id: 2020, name: "2020" },
  { id: 2021, name: "2021" },
  { id: 2022, name: "2022" },
  { id: 2023, name: "2023" },
  { id: 2024, name: "2024" },
  { id: 2025, name: "2025" },
  { id: 2026, name: "2026" },
  { id: 2027, name: "2027" },
  { id: 2028, name: "2028" },
  { id: 2029, name: "2029" },
  { id: 2030, name: "2030" },
];

const InMarketYearAutoComplete = ({
  classes,
  handleChange,
  reset,
  setReset,
  filterType,
}) => {
  const [open, setOpen] = useState(false);
  const [year, setYear] = useState("");
  const [currentYears, setCurrentYear] = useState([]);

  const currentFilter = useSelector((state) => state.filters.inMarketYear);
  const isGlobalLoading = useSelector((state) => state.globalLoad.isLoading);

  const handleMonths = (value) => {
    setCurrentYear(value);
  };

  useEffect(() => {
    if (currentFilter.length === 0) {
      setYear("");
      setCurrentYear([]);
      setReset(false);
    }
  }, [currentFilter]);

  useEffect(() => {
    if (reset) {
      setYear("");
      setCurrentYear([]);
      setReset(false);
    }
  }, [reset, setYear, setReset]);

  return (
    <>
      <Autocomplete
        multiple
        autoHighlight
        renderTags={() => null}
        fullWidth
        className={classes.queryField}
        classes={{
          popper: classes.liftedPopper,
        }}
        id="year-auto-complete"
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        inputValue={year}
        onInputChange={(_evt, value) => setYear(value)}
        onChange={(_evt, value) => {
          handleChange(value, "inMarketYear", filterType);
          handleMonths(value);
        }}
        isOptionEqualToValue={(option, value) => option.name === value.name}
        getOptionLabel={(year) => year.name}
        options={ocmYears}
        value={currentYears}
        disabled={isGlobalLoading}
        renderInput={(params) => (
          <TextField
            {...params}
            label="In Market Year"
            id="year-auto-search"
            variant="outlined"
            size="small"
            InputProps={{
              ...params.InputProps,
              autoComplete: "new-password",
              endAdornment: <>{params.InputProps.endAdornment}</>,
            }}
          />
        )}
      />
    </>
  );
};

InMarketYearAutoComplete.propTypes = {
  classes: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  reset: PropTypes.bool.isRequired,
  setReset: PropTypes.func.isRequired,
  filterType: PropTypes.string.isRequired,
};

export default InMarketYearAutoComplete;
