import { createSlice } from "@reduxjs/toolkit";

import { axiosGet } from "../../../api/axiosCalls";
import { setError } from "../errorSlice";

let initialState = {
  isLoading: false,
  bus: [],
  error: null,
};

const loadingFailed = (state, action) => {
  const { error } = action.payload;
  state.isLoading = false;
  state.error = error;
};

const businessUnitSlice = createSlice({
  name: "businessUnits",
  initialState,
  reducers: {
    setIsLoading(state) {
      state.isLoading = true;
    },
    getBuSuccess(state, action) {
      const { bus } = action.payload;
      state.bus = bus;
      state.isLoading = false;
      state.error = null;
    },
    setFailure: loadingFailed,
  },
});

export const { setIsLoading, getBuSuccess, setFailure } =
  businessUnitSlice.actions;

export default businessUnitSlice.reducer;

export const fetchBUs = () => async (dispatch) => {
  try {
    dispatch(setIsLoading());
    const response = await axiosGet("/api/business-units");
    if (response.error) throw response.error;
    const mappedData = response.data.map((bu) => ({
      id: bu.id,
      name: bu.name,
    }));
    dispatch(getBuSuccess({ bus: mappedData }));
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Business Units" }));
  }
};
