import { useBottomScrollListener } from "react-bottom-scroll-listener";
import Helmet from "react-helmet";
import { useDispatch, useSelector } from "react-redux";

import { Container, LinearProgress, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import HideFiltersButtonToggle from "src/components/Filtering/HideFiltersButtonToggle";

import FilterChipList from "../components/Filtering/FilterChipList";
import C2MItemHistoryTable from "../components/ItemPreview/C2MItemHistoryTable";
import { useInitialFilters } from "../hooks/UtilityHooks";
import { fetchNextC2MItemHistory } from "../redux/slices/c2mData/c2mItemHistorySlice";

/*
This page allows users to view historical items from C2M. It is used purely as a reference point,
and no items in this view are available for ordering.
*/

const defaultFilters = {
  itemNumber: "",
  c2mName: "",
  c2mBrands: "",
  c2mItemType: "",
  c2mProgramTheme: "",
  filterType: "c2m-item-history",
};

const useStyles = makeStyles((theme) => ({
  ...theme.global,
}));

const C2MItemHistory = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { isLoading, isNextLoading, nextLink, itemHistory } = useSelector(
    (state) => state.c2mItemHistory
  );

  const handleBottomScroll = () => {
    if (nextLink && !isNextLoading) {
      if (scrollRef.current && scrollRef.current.scrollTop !== 0) {
        dispatch(fetchNextC2MItemHistory(nextLink));
      }
    }
  };

  const scrollRef = useBottomScrollListener(handleBottomScroll, {
    offset: 500,
    debounceOptions: {
      leading: true,
      trailing: false,
    },
  });

  useInitialFilters("c2m-item-history", defaultFilters);

  return (
    <>
      <Helmet>
        <title>RTA | Historical Items</title>
      </Helmet>
      <Container className={classes.mainWrapper}>
        <div className={classes.titleBar}>
          <Typography className={classes.titleText}>
            Historical Items
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignContent: "center",
            marginBottom: "10px",
          }}
        >
          <HideFiltersButtonToggle />
          <FilterChipList classes={classes} />
          <br />
        </div>
        <C2MItemHistoryTable
          itemHistory={itemHistory}
          isLoading={isLoading}
          scrollRef={scrollRef}
        />
        {isNextLoading && (
          <div style={{ width: "100%" }}>
            <LinearProgress />
          </div>
        )}
        {!isNextLoading && <div style={{ width: "100%", height: "4px" }}></div>}
      </Container>
    </>
  );
};

export default C2MItemHistory;
