/*
Global styles for the site, handled by Material UI
*/
const theme = {
  palette: {
    primary: {
      light: "#bebebe",
      main: "#cbcbcb",
      dark: "#d8d8d8",
      contrastText: "#000000",
    },
    secondary: {
      light: "#999999",
      main: "#737373",
      dark: "#595959",
      contrastText: "#000000",
    },
    text: {
      primary: "#4C4C4C",
      secondary: "#737373",
    },
  },
  itemGrid: {
    paperWrapper: {
      backgroundColor: "whitesmoke",
      width: "95%",
      height: "100%",
      padding: "10px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "space-between",
    },
    itemGridContainer: {
      maxWidth: "2000px",
      paddingBottom: "20px",
    },
    previewImg: {
      width: "150px",
      height: "150px",
      borderRadius: "10px",
      objectFit: "cover",
      "&:hover": {
        cursor: "pointer",
      },
    },
    singleItem: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
    },
    singleItemWrapper: {
      position: "relative",
      width: "100%",
      height: "auto",
      padding: "0 5px",
    },
    checkbox: {
      position: "absolute",
      top: "0px",
      right: "0px",
      padding: "0px",
    },
  },
  reports: {
    searchComponents: {
      width: "80%",
      minWidth: "min-content",
      display: "flex",
      gap: "1em",
      justifyContent: "center",
      alignItems: "center",
      margin: "0 auto",
    },
    searchComponentsWide: {
      width: "80%",
      minWidth: "800px",
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
      margin: "0 auto",
    },
    queryField: {
      width: "32%",
      maxWidth: "250px",
    },
    queryFieldWide: {
      width: "24%",
      maxWidth: "250px",
    },
    buttonRow: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    chipRow: {
      height: "32px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  dashboard: {
    dashboardGridItem: {
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    dashPaper: {
      backgroundColor: "white",
      width: "100%",
      // height: "150px",
      paddingBottom: "100%",
      position: "relative",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      transitionDuration: ".1s",
      "&:hover": {
        transition: "transform .2s ease-in-out",
        transform: "scale(1.05)",
      },
    },
    innerPaper: {
      position: "absolute",
      width: "Calc(100% - 50px)",
      height: "Calc(100% - 50px)",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
    },
    popover: {
      pointerEvents: "none",
      margin: "20px 25px 0 0",
    },
    icon: {
      height: "30%",
      width: "auto",
    },
    reportInnerPaper: {
      position: "absolute",
      width: "Calc(100% - 50px)",
      height: "Calc(100% - 0px)",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
    },
    reportIcon: {
      height: "50%",
      auto: "auto",
    },
    reportDivider: {
      width: "100%",
      marginTop: "10px",
      marginBottom: "10px",
    },
    divider: {
      width: "100%",
      marginTop: "10px",
      marginBottom: "50px",
    },
  },
  global: {
    titleImage: {
      display: "flex",
      alignItems: "center",
    },
    previewImg: {
      width: "75px",
      height: "75px",
      borderRadius: "10px",
      objectFit: "cover",
    },
    previewImageFloat: {
      width: "75px",
      height: "75px",
      maxWidth: "none",
      borderRadius: "10px",
      objectFit: "cover",
      "&:hover": {
        cursor: "pointer",
      },
    },
    mainWrapper: {
      backgroundColor: "#FFFFFF",
      maxWidth: "2000px",
      paddingTop: "24px",
    },
    container: {
      width: "70%",
      minWidth: "600px",
      margin: "0 auto",
    },
    navItem: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
    },
    navIcon: {
      color: "#FFFFFF",
    },
    navText: {
      fontSize: ".7rem",
      fontWeight: "600",
      color: "#FFFFFF",
    },
    hoverText: {
      "&:hover": {
        cursor: "pointer",
      },
    },
    titleText: {
      fontWeight: "600",
      fontSize: "1.5rem",
      color: "#4C4C4C",
      marginBottom: "5px",
    },
    logo: {
      width: "100px",
      height: "auto",
      marginRight: "50px",
    },
    headerText: {
      fontWeight: "500",
      fontSize: "1rem",
      color: "#4C4C4C",
    },
    headerListItem: {
      color: "black",
      "&:hover": {
        textDecoration: "underline",
      },
    },
    headerListItemNew: {
      color: "white",
      border: "solid white .5px",
      padding: "7px",
      "&:hover": {
        backgroundColor: "#8397B0",
        boxShadow: "5px 5px 5px rgba(68, 68, 68, 0.6)",
      },
    },
    bodyText: {
      fontSize: ".9rem",
      color: "#4C4C4C",
    },
    titleBar: {
      display: "flex",
      justifyContent: "space-between",
    },
    largeButton: {
      fontWeight: "600",
      fontSize: "1rem",
      textAlign: "center",
      color: "#FFFFFF",
      backgroundColor: "#8397B0",
    },
    cancelBtn: {
      borderColor: "transparent",
      "&:hover": {
        borderColor: "red",
        color: "red",
      },
    },
    tabContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginTop: "30px",
    },
    cartContainer: {
      maxHeight: "750px",
    },
    modalTabContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    centerWrapper: {
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    relativeContainer: {
      position: "relative",
    },
    closeButton: {
      position: "absolute",
      top: "0",
      right: "5px",
    },
    pageBreak: {
      border: "none",
      height: "2px",
      margin: "0",
      flexShrink: "0",
      backgroundColor: "#737373",
    },
    configButtons: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
    },
    innerConfigDiv: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
    },
    backdrop: {
      zIndex: 1250,
      color: "#fff",
    },
    showHideFilters: {
      display: "flex",
      flexDirection: "row",
      position: "relative",
      alignItems: "center",
      maxWidth: "150px",
      height: "32px",
      marginRight: "10px",
      "&:hover": {
        cursor: "pointer",
      },
    },
    liftedPopper: {
      zIndex: "2500",
    },
    orderHistoryRow: {
      "&&:hover": {
        cursor: "pointer",
      },
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    clickableCell: {
      "&:hover": {
        backgroundColor: "#737373",
        color: "white",
      },
    },
    clickableRow: {
      cursor: "pointer",
      transitionDuration: "300ms",
      "&:hover": {
        backgroundColor: "#F4F4F4",
      },
    },
    cancelRow: {
      backgroundColor: "#920000",
      "&&:hover": {
        cursor: "pointer",
        backgroundColor: "#920000",
      },
    },
    cancelCell: {
      color: "white",
    },
    flexColumnModal: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "space-around",
      width: "100%",
      textAlign: "center",
    },
    settingsMargin: {
      marginBottom: "15px",
    },
    fullWidthSpaceAround: {
      display: "flex",
      width: "100%",
      justifyContent: "space-around",
    },
    fullWidthSpaceBetween: {
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
    },
    fullWidth: {
      width: "100%",
    },
    fullWidthCenterColumn: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
    },
    inputRow: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-end",
      justifyContent: "space-between",
      padding: "10px",
    },
    inputField: {
      width: "49%",
      marginRight: "10px",
    },
    reviewGrid: {
      width: "100%",
      border: "1px solid #ccc",
      borderRadius: "5px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: "5px",
    },
    headerTextLine: {
      display: "flex",
    },
    headerTextLineStart: {
      marginRight: "10px",
    },
    wrapNewlines: {
      whiteSpace: "pre-wrap",
    },
  },
};

export default theme;
