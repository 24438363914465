/** @jsxImportSource @emotion/react */
import "twin.macro";

import HideFiltersButtonToggle from "src/components/Filtering/HideFiltersButtonToggle";
import {
  FixedHeightScrollLastChild,
  PageTitle,
} from "src/components/StyledComponents";
import { useInitialFilters } from "src/hooks/UtilityHooks";

import { BudgetsTable } from "@features/budgets";
import { useBudgetsPaginated } from "@features/budgets/queries";
import { useFilterParams } from "@features/filters";
import { RequestReportButton, useReport } from "@features/reports";

const BudgetSummary = () => {
  const [appliedFilters] = useFilterParams();

  const params = {
    filter: {
      externalId: appliedFilters.budgetId,
      brandIds: appliedFilters.brandIds,
      subStateIds: appliedFilters.stateIds,
      businessUnitIds: appliedFilters.buIds,
      year: appliedFilters.year,
      channel: appliedFilters.channel,
    },
  };

  useReport("budgets-summary", ["budgets", params]);
  const { data, ...tableProps } = useBudgetsPaginated(params);

  useInitialFilters("budgets", {});
  return (
    <FixedHeightScrollLastChild>
      <div tw="flex items-center justify-between">
        <PageTitle>Budgets</PageTitle>
        <RequestReportButton reportName="budgets-summary" title="Export CSV" />
      </div>
      <HideFiltersButtonToggle />
      <BudgetsTable tw="mb-2" rows={data ?? []} {...tableProps} />
    </FixedHeightScrollLastChild>
  );
};

export default BudgetSummary;
