import React from "react";

import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";

import Logo from "../../assets/RTA_Logo_Stacked.png";

Font.register({
  family: "Roboto",
  src: "/fonts/Roboto-Regular.ttf",
});

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    alignItems: "center",
  },
  headingText: {
    fontFamily: "Roboto",
    fontSize: 15,
    alignSelf: "center",
  },
  subHeadingText: {
    fontFamily: "Roboto",
    fontSize: 12,
  },
  text: {
    fontFamily: "Roboto",
    fontSize: 9,
    marginTop: 7,
    paddingBottom: 1,
    borderBottom: "1 solid #000",
  },
  specText: {
    fontFamily: "Roboto",
    fontSize: 8,
    marginTop: 7,
  },
  footerText: {
    fontFamily: "Roboto",
    fontSize: 8,
    color: "white",
  },
  sectionWrapper: {
    display: "flex",
    flexDirection: "column",
    width: 600,
    alignItems: "center",
  },
  section: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    width: 600,
    height: 600,
  },
  imageSection: {
    width: 600,
    height: "auto",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignContent: "center",
    justifyContent: "center",
  },
  image: {
    objectFit: "contain",
    width: 275,
    height: "auto",
    marginTop: 20,
  },
  detailSection: {
    width: 300,
    height: 150,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  textSection: {
    width: 300,
    height: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  pageBreak: {
    width: 600,
    height: 1,
    marginTop: 10,
    marginBottom: 10,
    backgroundColor: "black",
  },
  logoContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignContent: "center",
    width: 600,
    paddingLeft: 20,
    paddingRight: 20,
    height: 100,
  },
  logo: {
    width: 165,
    objectFit: "contain",
  },
});

const RFPPDF = ({ currentRFP }) => {
  return (
    <Document>
      <Page size="LETTER" style={styles.page}>
        <View style={styles.logoContainer}>
          <Image source={Logo} style={styles.logo} />
          <Text style={styles.headingText}>{`RFP: #${currentRFP.id}`}</Text>
        </View>

        <View style={styles.sectionWrapper}>
          <View
            style={{
              border: "1 solid #000",
              paddingTop: 5,
              paddingBottom: 5,
              marginBottom: 15,
              width: 600,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
            }}
          >
            <Text
              style={styles.subHeadingText}
            >{`Due Date: ${currentRFP.dueDate}`}</Text>
            <Text
              style={styles.subHeadingText}
            >{`In Market Date: ${currentRFP.inMarketDate}`}</Text>
          </View>
          <View style={styles.section}>
            <View style={styles.detailSection}>
              <Text style={styles.subHeadingText}>Item Details:</Text>
              <Text
                style={styles.text}
              >{`Sequence Number: ${currentRFP.itemNumber}`}</Text>
              <Text
                style={styles.text}
              >{`Item Type: ${currentRFP.itemType}`}</Text>
              <Text
                style={styles.text}
              >{`Item Description: ${currentRFP.itemDescription}`}</Text>
              <Text
                style={styles.text}
              >{`Project ID: ${currentRFP.projectNum}`}</Text>
              <Text
                style={styles.text}
              >{`Program: ${currentRFP.program}`}</Text>
              <Text style={styles.text}>{`Brand: ${currentRFP.brand}`}</Text>
              <Text
                style={styles.text}
              >{`Quantity: ${currentRFP.totalQty}`}</Text>
              <Text style={styles.text}>
                {`Supplier Notes: ${currentRFP.supplierNote}`}
              </Text>
            </View>
            <View style={styles.pageBreak} />
            <View style={styles.textSection}>
              <Text style={styles.subHeadingText}>Item Specifications:</Text>
              {currentRFP.itemSpec ? (
                currentRFP.itemSpec.map((spec, index) => {
                  if (spec.type === "spec") {
                    return (
                      <Text
                        key={index}
                        style={styles.specText}
                      >{`${spec.key} : ${spec.value}`}</Text>
                    );
                  } else {
                    return (
                      <>
                        <Text style={styles.text} key={"websiteDesc"}>
                          Website Description:
                        </Text>
                        {Object.keys(spec.value).map((wSpec, i) => (
                          <Text style={styles.specText} key={`wd-${i}`}>
                            {`${wSpec}:  ${spec.value[wSpec]}`}
                          </Text>
                        ))}
                      </>
                    );
                  }
                })
              ) : (
                <Text style={styles.text}>Item Specifications are Pending</Text>
              )}
            </View>
          </View>
          <View style={styles.imageSection}>
            {currentRFP.imgUrlLg.map((image, i) => (
              <Image style={styles.image} key={`image-${i}`} src={image} />
            ))}
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default RFPPDF;
