import { createSlice } from "@reduxjs/toolkit";

/*
This piece of state controls whether the error modal is displayed. Every error will trigger a
dispatch to setError, which will trigger the error modal to open with the current error, and
source displayed.
*/

let initialState = {
  currentError: null,
  errorSource: null,
};

const errorSlice = createSlice({
  name: "error",
  initialState,
  reducers: {
    setError(state, action) {
      const { error, source } = action.payload;
      state.currentError = String(error).replace(/^Base /, "");
      state.errorSource = source;
    },
    clearError(state) {
      state.currentError = null;
      state.errorSource = null;
    },
  },
});

export const { setError, clearError } = errorSlice.actions;

export default errorSlice.reducer;
