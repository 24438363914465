import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import axios from "axios";
import format from "date-fns/format";
import subDays from "date-fns/subDays";
import { saveAs } from "file-saver";

import { DatePicker } from "../components/Forms/DefaultInputs";
import SimpleReportGenerator from "../components/Reporting/SimpleReportGenerator";
import { useNoFetch } from "../hooks/UtilityHooks";
import { setError } from "../redux/slices/errorSlice";

const ReportComplianceAudit = () => {
  const dispatch = useDispatch();

  const [fromDate, setFromDate] = useState(
    format(subDays(new Date(), 7), "MM/dd/yyyy")
  );
  const [toDate, setToDate] = useState(format(new Date(), "MM/dd/yyyy"));

  const isLoading = useSelector((state) => state.reports.isLoading);

  const handleGetReport = async () => {
    try {
      const filterObject = {
        dateRange: `${fromDate} - ${toDate}`,
      };

      const { data } = await axios.get(
        `/api/compliance-audit-log-report?filter[date-range]=${filterObject.dateRange}`
      );
      console.log(data);
      const blob = new Blob([data], {
        type: "text/csv;charset=utf-8",
      });
      saveAs(blob, `compliance_audit_report.csv`);
    } catch (error) {
      dispatch(
        setError({
          error: error.toString(),
          source: "Generating coupon compliance audit report",
        })
      );
    }
  };

  useNoFetch(dispatch);

  return (
    <SimpleReportGenerator
      reportTitle={"Compliance Audit Report"}
      reportGetFunc={handleGetReport}
    >
      <DatePicker
        label="Order From Date"
        value={fromDate}
        onChange={(value) => setFromDate(format(value, "MM/dd/yyyy"))}
        disabled={isLoading}
      />

      <DatePicker
        label="Order To Date"
        value={toDate}
        onChange={(value) => setToDate(format(value, "MM/dd/yyyy"))}
        disabled={isLoading}
      />
    </SimpleReportGenerator>
  );
};

export default ReportComplianceAudit;
