import { kebabCaseKeys } from "src/utility/utilityFunctions";

import { createSlice } from "@reduxjs/toolkit";

import { axiosPatch, axiosPost, axiosPut } from "../../api/axiosCalls";
import {
  cancelCompItem,
  updateCompItemSelection,
} from "./compliance/complianceItemsSlice";
import { setError } from "./errorSlice";
import { setRedirect } from "./globalLoadSlice";
import { buildOrderVariantPatch } from "./ordering/helpers";
import { fetchOrder, setOrderHasUpdated } from "./ordering/orderHistorySlice";
import { fetchFilteredOrderSets } from "./ordering/orderSetHistorySlice";
import { fetchPOItemRollups } from "./purchasing/purchaseOrderSlice";
import { fetchRFPRollupItems } from "./purchasing/rfpSlice";

/*
The patch order slice handles the loading state for all cells in the order grid, and also handles
the loading state for smaller operations. When isLoading is true, most views will display a
small loading indicator (<OrderPatchLoading />) to the user.
*/

let initialState = {
  isLoading: false,
  cellsLoading: [],
  cellsError: [],
  error: null,
};

const loadingFailed = (state, action) => {
  const { error, cell } = action.payload;
  if (cell) {
    let currentErrors = [...state.cellsError];
    let currentLoading = [...state.cellsLoading];
    let currentCell = currentLoading.find(
      (c) => c.id === cell.id && c.orderNumber === cell.orderNumber
    );
    currentLoading.splice(currentLoading.indexOf(currentCell), 1);
    currentErrors.push(cell);
    state.cellsError = currentErrors;
    state.cellsLoading = currentLoading;
    state.isLoading = false;
  } else {
    state.isLoading = false;
    state.error = error;
  }
};

const patchOrderSlice = createSlice({
  name: "patchOrder",
  initialState,
  reducers: {
    setIsLoading(state) {
      state.isLoading = true;
    },

    patchSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },

    setFailure: loadingFailed,
  },
});

export const {
  setIsLoading,

  patchSuccess,

  setFailure,
} = patchOrderSlice.actions;

export default patchOrderSlice.reducer;

export const updateOrderVariantQty = (id, qty) => async (dispatch) => {
  try {
    dispatch(setIsLoading());
    const patchData = buildOrderVariantPatch(id, qty);
    const response = await axiosPatch(`/api/order-variants/${id}`, patchData);
    if (response.error) throw response.error;
    dispatch(patchSuccess());
    dispatch(setOrderHasUpdated({ value: true, newOrderId: null }));
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Patch Orders" }));
  }
};

//Cancels all order items in provided array
export const cancelMultipleOrderVariants =
  (orderVariantArray, filters, type, cancelNote) => async (dispatch) => {
    try {
      dispatch(setIsLoading());
      await Promise.all(
        orderVariantArray.map(async (id) => {
          let postData = {
            "cancelation-type": "order",
            "cancelation-note": cancelNote,
          };
          let response = await axiosPost(
            `/api/order-variants/${id}/cancel`,
            postData
          );
          if (response.error) throw response.error;
        })
      );
      if (type === "po") {
        dispatch(fetchPOItemRollups(filters));
      } else {
        dispatch(fetchRFPRollupItems(filters));
      }
      dispatch(patchSuccess());
    } catch (err) {
      dispatch(setFailure({ error: err.toString() }));
      dispatch(setError({ error: err.toString(), source: "Patch Orders" }));
    }
  };

//cancels all order-variants sent with a status of compliance canceled
export const complianceCancelOrderVariants =
  (itemIds, cancelNote) => async (dispatch) => {
    try {
      dispatch(setIsLoading());
      await Promise.all(
        itemIds.map(async (id) => {
          let postData = {
            "cancelation-type": "compliance",
            "cancelation-note": cancelNote,
          };
          let response = await axiosPost(
            `/api/order-variants/${id}/cancel`,
            postData
          );
          if (response.error) throw response.error;
          dispatch(cancelCompItem({ id: id }));
        })
      );
      dispatch(updateCompItemSelection({ selectedItems: [] }));
      dispatch(patchSuccess());
    } catch (err) {
      dispatch(setFailure({ error: err.toString() }));
      dispatch(setError({ error: err.toString(), source: "Patch Orders" }));
    }
  };

export const cancelOrderById = (id, type, note) => async (dispatch) => {
  try {
    dispatch(setIsLoading());
    const postData = { "cancelation-type": type, "cancelation-note": note };
    const response = await axiosPost(`/api/orders/${id}/cancel`, postData);
    if (response.error) throw response.error;
    dispatch(fetchOrder(id));
    dispatch(patchSuccess());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Patch Orders" }));
  }
};

export const cancelOrderVariantById =
  (id, type, note, orderId) => async (dispatch) => {
    try {
      dispatch(setIsLoading());
      const postData = {
        "cancelation-type": type,
        "cancelation-note": note,
      };
      const response = await axiosPost(
        `/api/order-variants/${id}/cancel`,
        postData
      );
      if (response.error) throw response.error;
      dispatch(fetchOrder(orderId));
      dispatch(patchSuccess());
    } catch (err) {
      dispatch(setFailure({ error: err.toString() }));
      dispatch(setError({ error: err.toString(), source: "Patch Orders" }));
    }
  };

export const updateOrderVariantAddress =
  (id, addressId) => async (dispatch) => {
    try {
      dispatch(setIsLoading());
      const postData = { "address-id": addressId };
      const response = await axiosPost(
        `/api/order-variants/${id}/update-address`,
        postData
      );
      if (response.error) throw response.error;
      dispatch(
        setOrderHasUpdated({
          value: true,
          newOrderId: response.data.order.id,
        })
      );
      dispatch(patchSuccess());
    } catch (err) {
      dispatch(setFailure({ error: err.toString() }));
      dispatch(setError({ error: err.toString(), source: "Patch Orders" }));
    }
  };

export const overrideOrderVariantCompliance =
  (id, note) => async (dispatch) => {
    try {
      dispatch(setIsLoading());
      const response = await axiosPost(`/api/order-variants/${id}/override`, {
        note: note,
      });
      if (response.error) throw response.error;
      dispatch(
        setOrderHasUpdated({
          value: true,
          newOrderId: null,
        })
      );
      dispatch(patchSuccess());
    } catch (err) {
      dispatch(setFailure({ error: err.toString() }));
      dispatch(setError({ error: err.toString(), source: "Patch Orders" }));
    }
  };

export const updateOrderAddress = (id, addressId) => async (dispatch) => {
  try {
    dispatch(setIsLoading());
    const postData = { "address-id": addressId };
    const response = await axiosPost(
      `/api/orders/${id}/update-address`,
      postData
    );
    if (response.error) throw response.error;
    dispatch(
      setOrderHasUpdated({
        value: true,
        newOrderId: null,
      })
    );
    dispatch(patchSuccess());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Patch Orders" }));
  }
};

export const updateOrderToInventoryAllocation =
  (id, territoryId, subStateId, callback) => async (dispatch) => {
    try {
      dispatch(setIsLoading());
      const data = kebabCaseKeys({
        data: {
          type: "order",
          relationships: {
            territory: {
              data: {
                type: "territory",
                id: territoryId,
              },
            },
            "sub-state": {
              data: subStateId ? { type: "sub-state", id: subStateId } : null,
            },
          },
        },
      });
      const response = await axiosPut(`/api/orders/${id}`, data);
      if (response.error) throw response.error;
      dispatch(
        setOrderHasUpdated({
          value: true,
          newOrderId: null,
        })
      );
      dispatch(patchSuccess());
      callback?.();
    } catch (err) {
      dispatch(setFailure({ error: err.toString() }));
      dispatch(setError({ error: err.toString(), source: "Patch Orders" }));
    }
  };

//Updates status on order set from submitted to in-progress
// export const restartOrdSet =
//   (programId, value, orderSetId, orderMonthId) => async (dispatch) => {
//     try {
//       dispatch(setIsLoading());
//       if (programId) {
//         dispatch(
//           setProgramStatus({
//             program: programId,
//             orderMonthId: orderMonthId,
//             status: value,
//           })
//         );
//       }
//       const response = await axiosPost(
//         `/api/order-sets/${orderSetId}/restart`,
//         {}
//       );
//       if (response.error) throw response.error;
//       dispatch(setOrderStatus({ status: value }));
//       dispatch(patchSuccess());
//     } catch (err) {
//       dispatch(setFailure({ error: err.toString() }));
//     }
//   };

// //updates status on order set from submitted to approved
// export const approveOrdSet =
//   (orderSetId, value, filters) => async (dispatch) => {
//     try {
//       dispatch(setIsLoading());
//       const response = await axiosPost(
//         `/api/order-sets/${orderSetId}/approve`,
//         {}
//       );
//       if (response.error) throw response.error;
//       if (filters) {
//         dispatch(fetchFilteredOrderSets(filters));
//       }
//       dispatch(setOrderStatus({ status: value }));
//       dispatch(patchSuccess());
//       if (!filters) {
//         dispatch(setRedirect({ url: "/orders/approvals" }));
//       }
//     } catch (err) {
//       dispatch(setFailure({ error: err.toString() }));
//       dispatch(setError({ error: err.toString(), source: "Patch Orders" }));
//     }
//   };

//accepts an array of subbmitted order ids and moves them all to approved status
export const approveMultipleOrderSets =
  (orderSetArray, filters) => async (dispatch) => {
    try {
      dispatch(setIsLoading());
      await Promise.all(
        orderSetArray.map(async (id) => {
          let response = await axiosPost(
            `/api/order-sets/${id}/approve`,
            {},
            { timeout: 10 * 60 * 1000 }
          );
          if (response.error) throw response.error;
        })
      );
      dispatch(fetchFilteredOrderSets(filters));
      dispatch(patchSuccess());
    } catch (err) {
      dispatch(setFailure({ error: err.toString() }));
      dispatch(setError({ error: err.toString(), source: "Patch Orders" }));
    }
  };

export const denyOrdSet =
  (orderSetId, filters, note, redirectUrl = "/orders/approvals") =>
  async (dispatch) => {
    try {
      dispatch(setIsLoading());
      const postData = {
        data: {
          note: note,
        },
      };
      const response = await axiosPost(
        `/api/order-sets/${orderSetId}/deny`,
        postData
      );
      if (response.error) throw response.error;
      dispatch(patchSuccess());
      if (!filters) {
        dispatch(setRedirect({ url: redirectUrl }));
      } else {
        dispatch(fetchFilteredOrderSets(filters));
      }
    } catch (err) {
      dispatch(setFailure({ error: err.toString() }));
      dispatch(setError({ error: err.toString(), source: "Patch Orders" }));
    }
  };
