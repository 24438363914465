import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";

import Logo from "../../assets/RTA_Logo_Stacked.png";
import { formatMoney } from "../../utility/utilityFunctions";

Font.register({
  family: "Roboto",
  src: "/fonts/Roboto-Regular.ttf",
});

const styles = StyleSheet.create({
  page: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  titleText: {
    fontFamily: "Roboto",
    fontSize: 13,
    marginBottom: 10,
  },
  text: {
    fontFamily: "Roboto",
    fontSize: 9,
    marginBottom: 5,
  },
  image: {
    height: 30,
    width: "auto",
    marginLeft: 20,
    marginBottom: 5,
  },
  sectionTitle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-end",
    paddingLeft: 40,
    paddingRight: 40,
    width: 600,
    height: 80,
  },
  sectionTop: {
    display: "flex",
    flexDirection: "row",
    width: 600,
    height: 80,
  },
  innerTop: {
    display: "flex",
    flexDirection: "column",
    width: 300,
    height: 80,
    paddingLeft: 40,
    paddingRight: 40,
    paddingTop: 20,
    paddingBottom: 20,
    boxSizing: "border-box",
  },
  sectionMid: {
    display: "flex",
    flexDirection: "row",
    width: 600,
  },
  innerMid: {
    display: "flex",
    flexDirection: "column",
    width: 300,
    paddingLeft: 40,
    paddingRight: 40,
    paddingTop: 20,
    paddingBottom: 20,
    boxSizing: "border-box",
  },
  sectionBottom: {
    display: "flex",
    flexDirection: "row",
    width: 600,
  },
  innerBottomFullWidthWrapper: {
    width: 600,
    display: "flex",
    flexDirection: "column",
  },
  innerBottomFullWidth: {
    width: 520,
    paddingTop: 10,
    paddingBottom: 10,
    textAlign: "center",
  },
  innerBottom: {
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    width: 200,
    height: 30,
    paddingTop: 20,
    paddingBottom: 20,
  },
  bottomPad: {
    width: 40,
  },
  sectionFooter: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "flex-start",
    paddingTop: 20,
    width: 600,
    height: 75,
  },
  pageBreak: {
    width: 600,
    height: 1,
    marginTop: 10,
    backgroundColor: "black",
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
    borderStyle: "solid",
  },
  tableCell: {
    width: "15%",
    borderStyle: "solid",
    fontFamily: "Roboto",
    fontSize: 9,
    borderTop: 1,
    borderLeft: 1,
    padding: 2,
  },
});

const PurchaseOrderPDF = ({ po, displayGalloCost }) => {
  let poItems = po.poVariants.filter((i) => i.itemType !== "Set Up Fee");
  let program = poItems[0].program;
  let setUpFee = po.poVariants.find((i) => i.itemType === "Set Up Fee");

  const baseFreight = displayGalloCost ? po.baseGalloFreight : po.baseFreight;
  const baseCost = displayGalloCost ? po.totalGalloCost : po.totalCost;
  const totalCost = baseCost + baseFreight + po.totalTax;

  return (
    <Document>
      <Page size="LETTER" style={styles.page}>
        <View style={styles.sectionTitle}>
          <Text style={styles.titleText}>
            {`${
              po.type === "fulfillment" ? "Fulfillment " : ""
            }Purchase Order #${po.id} - ${po.brand.join(", ")} - Page 1`}
          </Text>
          <Image style={styles.image} source={Logo} />
        </View>
        <View style={styles.pageBreak} />
        <View style={styles.sectionTop}>
          <View style={styles.innerTop}>
            <Text style={styles.text}>{`Supplier: ${po.supplier}`}</Text>
            <Text style={styles.text}>{`Purchased By: ${po.purchasedBy}`}</Text>
            <Text
              style={styles.text}
            >{`Submitted At: ${po.submittedDate}`}</Text>
            <Text
              style={styles.text}
            >{`Supplier Reference: ${po.supplierReference}`}</Text>
          </View>
          <View style={styles.innerTop}>
            <Text style={styles.text}>
              {`Delivery Date: ${
                !po.isDirectShip && po.deliverToWarehouseDate
                  ? po.deliverToWarehouseDate
                  : po.inMarketDate
              }`}
            </Text>
            <Text style={styles.text}>{`Terms: ${po.terms}`}</Text>
            <Text style={styles.text}>
              {`Invoice Number: ${po.invoiceNum}`}
            </Text>
            {po.allocatedTerritory && (
              <Text style={styles.text}>
                {`Allocated Territory: ${po.allocatedTerritory}`}
              </Text>
            )}
          </View>
        </View>
        <View style={styles.pageBreak} />
        <View style={styles.sectionMid}>
          <View style={styles.innerMid}>
            <Text style={styles.titleText}>Supplier Notes</Text>
            <Text style={styles.text}>{`Shipper Copy:`}</Text>
            {po.shippingLabel.map((label, i) => (
              <Text style={styles.text} key={i}>
                {label}
              </Text>
            ))}
            <Text style={styles.text}>{`RFP Number: ${po.rfpNumber}`}</Text>
            <Text style={styles.text}>{`Ship Method: ${po.method}`}</Text>
            <Text style={styles.text}>{`Shipper Tape: ${po.keyAcctTape}`}</Text>
            <Text style={styles.text}>{`Notes: ${po.supplierNotes}`}</Text>
            <Text style={styles.text}>{`Include Beacon: ${
              po.includeBeacon ? "Yes" : "No"
            }`}</Text>
            <Text style={styles.text}>
              {po.isDirectShip
                ? "Shipping Direct, please see Allocation/Ship List"
                : "Shipping to CDC"}
            </Text>
            <Text style={styles.text}>
              {`Program: ${
                program && program !== "---" ? program.join(", ") : "---"
              }`}
            </Text>
          </View>
          {poItems
            .map((item) => ({
              ...item,
              itemNumber: item.itemNumber.split("-")[0],
            }))
            .reduce((acc, item) => {
              if (!acc.some((i) => i.itemNumber === item.itemNumber))
                acc.push(item);
              return acc;
            }, [])
            .map((item, i) => (
              <View style={styles.innerMid} key={i}>
                <Text style={styles.titleText}>Spec Details</Text>
                <Text style={styles.text}>
                  {`Sequence Number: ${item.itemNumber}`}
                </Text>
                <Text style={styles.text}>
                  {`Is Permanent Material: ${
                    item.isMetalOrWood ? "Yes" : "No"
                  }`}
                </Text>
                {item.itemSpec ? (
                  item.itemSpec.map((spec, index) => {
                    if (spec.type === "spec") {
                      return (
                        <Text key={index} style={styles.text}>
                          {`${spec.key}: ${spec.value}`}
                        </Text>
                      );
                    } else {
                      return (
                        <>
                          <Text style={styles.text} key={"websiteDesc"}>
                            Website Description:
                          </Text>
                          {Object.keys(spec.value).map((wSpec, i) => (
                            <Text style={styles.text} key={`wd-${i}`}>
                              {`${wSpec}  ${spec.value[wSpec]}`}
                            </Text>
                          ))}
                        </>
                      );
                    }
                  })
                ) : (
                  <Text style={styles.text}>
                    Item Specifications are Pending
                  </Text>
                )}
                {item.isCoupon && (
                  <>
                    <Text style={styles.text}>
                      {`Coupon Type: ${
                        item.couponTypeCode ? item.couponTypeCode : "---"
                      }`}
                    </Text>
                    <Text style={styles.text}>
                      {`Coupon Offer Type: ${
                        item.couponOfferType ? item.couponOfferType : "---"
                      }`}
                    </Text>
                    <Text style={styles.text}>
                      {`Coupon Description: ${
                        item.couponOfferDescription
                          ? item.couponOfferDescription
                          : "---"
                      }`}
                    </Text>
                  </>
                )}
              </View>
            ))}
        </View>
        <View style={styles.pageBreak} />
        <View style={styles.sectionBottom}>
          <View style={styles.bottomPad} />
          <View style={styles.innerBottomFullWidthWrapper}>
            <View style={styles.innerBottomFullWidth}>
              <View
                style={{
                  ...styles.tableRow,
                  backgroundColor: "lightgrey",
                }}
              >
                <Text style={styles.tableCell}>Item Number</Text>
                <Text style={styles.tableCell}>Item Type</Text>
                <Text style={styles.tableCell}>Qty</Text>
                <Text style={styles.tableCell}>Pack Size</Text>
                <Text style={styles.tableCell}>Cost/Unit</Text>
                <Text style={styles.tableCell}>Total Cost</Text>
                <Text style={{ ...styles.tableCell, borderRight: 1 }}>
                  Pack Out Requested
                </Text>
              </View>
              {poItems.map((item, index) => (
                <View
                  key={index}
                  style={
                    index === poItems.length - 1
                      ? { ...styles.tableRow, borderBottomWidth: 1 }
                      : styles.tableRow
                  }
                >
                  <Text style={{ ...styles.tableCell }}>{item.itemNumber}</Text>
                  <Text style={{ ...styles.tableCell }}>{item.itemType}</Text>
                  <Text style={{ ...styles.tableCell }}>{item.totalQty}</Text>
                  <Text style={{ ...styles.tableCell }}>{item.packSize}</Text>
                  <Text style={{ ...styles.tableCell }}>
                    {formatMoney(item.actCost)}
                  </Text>
                  <Text style={{ ...styles.tableCell }}>
                    {formatMoney(item.totalCost)}
                  </Text>
                  <Text style={{ ...styles.tableCell, borderRight: 1 }}>
                    {item.packOut ? "Yes" : "No"}
                  </Text>
                </View>
              ))}
            </View>
          </View>
          <View style={styles.bottomPad} />
        </View>
        <View style={styles.pageBreak} />
        <View style={styles.sectionBottom}>
          <View style={styles.innerBottom}>
            <Text style={styles.text}>
              {`Set Up Fee: ${
                setUpFee ? formatMoney(setUpFee.actCost, true) : "---"
              }`}
            </Text>
          </View>
          <View style={styles.innerBottom}>
            <Text style={styles.text}>
              {`Total Freight: ${formatMoney(baseFreight, true)}`}
            </Text>
          </View>
          <View style={styles.innerBottom}>
            <Text style={styles.text}>
              {`Total Tax: ${formatMoney(po.totalTax, true)}`}
            </Text>
          </View>
        </View>
        <View style={styles.pageBreak} />
        <View style={styles.sectionFooter}>
          <Text style={styles.titleText}>
            {`Purchase Order Total: ${formatMoney(totalCost, true)}`}
          </Text>
        </View>
      </Page>
    </Document>
  );
};

export default PurchaseOrderPDF;
