/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import { upCase } from "../../../utility/utilityFunctions";

const valuify = (val) =>
  typeof val === "string" ? (
    <span tw="text-green-700">{`"${val}"`}</span>
  ) : val === null ? (
    <span tw="text-slate-400">null</span>
  ) : typeof val === "object" ? (
    <pre tw="text-purple-600 overflow-x-auto my-0">
      {JSON.stringify(val, null, 2)}
    </pre>
  ) : typeof val !== "number" ? (
    <span tw="text-purple-600">{String(val)}</span>
  ) : (
    <span tw="text-blue-600">{val}</span>
  );

const ChangeLog = ({ changes }) => {
  return (
    <div tw="max-w-xl mx-auto space-y-4 text-sm">
      {changes.map((row) => (
        <div key={row.id} tw="bg-white p-4 rounded-lg">
          <div tw="grid grid-cols-[max-content auto max-content] gap-4 items-center text-slate-500">
            <div tw="text-slate-800 flex items-center">
              <div
                css={[
                  tw`w-2 h-2 mr-2 rounded-full bg-slate-300`,
                  row.action === "updated" && tw`bg-blue-500`,
                  row.action === "created" && tw`bg-green-500`,
                  row.action === "deleted" && tw`bg-red-500`,
                ]}
              ></div>
              <b>
                {row.recordType !== "program"
                  ? `#${row.itemNumber || row.entityId}`
                  : "Program"}
              </b>
            </div>
            <div>
              <b>{row.action}</b> {` by ${row.userName || "system"}`}
            </div>
            <div tw="text-xs font-mono">{row.recordedAt}</div>
          </div>
          {row.changes?.length > 0 && (
            <div tw="mt-4">
              {row.changes
                .filter((change) => change !== null)
                .map((change, i) => {
                  const changedField =
                    change.field === "order_calendar_month_id"
                      ? row.recordType === "program"
                        ? "In Market Month"
                        : "Order Window Month"
                      : upCase(change.field, "_");
                  return (
                    <div
                      key={i}
                      tw="flex justify-between items-center gap-x-4 flex-wrap px-2 py-1 -mx-2 rounded transition hover:bg-slate-100"
                    >
                      <div>{changedField}</div>
                      <div tw="font-mono max-w-full">
                        {valuify(change.originalValue)}
                        <span tw="text-lg">{" ⇉ "}</span>
                        {valuify(change.updatedValue)}
                      </div>
                    </div>
                  );
                })}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default ChangeLog;
