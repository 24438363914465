/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useState } from "react";

import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { Menu, MenuItem } from "@mui/material";

import { saveAs } from "file-saver";
import { StyledButton } from "src/components/StyledComponents";
import cloudinary from "src/services/cloudinary";

type ImageDownloadMenuButtonProps = {
  index: number;
  cloudinaryIdsOrImageUrls: string[];
};

type fileTypeOptions = "jpg" | "pdf";

const ImageDownloadMenuButton: React.FC<ImageDownloadMenuButtonProps> = ({
  index,
  cloudinaryIdsOrImageUrls,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const createCloudinaryDownloadLink = (format, index) => {
    // Check if id is already a URL
    if (cloudinaryIdsOrImageUrls[index].startsWith("http")) {
      return `${cloudinaryIdsOrImageUrls[index]
        .split(".")
        .slice(0, -1)
        .join(".")}.${format}`;
    }

    // Otherwise, use the image id to create a cloudinary link
    return cloudinary.url(`${id}.${format}`, {
      resource_type: "image",
      flags: `attachment:image${index}`,
    });
  };

  const downloadImage = async (index: number, format: fileTypeOptions) => {
    const url = createCloudinaryDownloadLink(format, index);
    const response = await fetch(url);
    const blob = await response.blob();
    saveAs(blob, `image${index}.${format}`);
  };

  const downloadAllImages = async (format) => {
    const downloadPromises = cloudinaryIdsOrImageUrls.map((_, i) => {
      return downloadImage(i, format);
    });

    await Promise.all(downloadPromises);
  };

  return (
    <>
      <StyledButton
        outlined
        onClick={handleClick}
        startIcon={<FileDownloadIcon />}
      >
        Download Image{cloudinaryIdsOrImageUrls.length > 1 && "s"}
      </StyledButton>
      <Menu
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        tw="z-[999999]"
      >
        <MenuItem onClick={() => downloadImage(index, "jpg")}>
          Download as JPG
        </MenuItem>
        <MenuItem onClick={() => downloadImage(index, "pdf")}>
          Download as PDF
        </MenuItem>
        {cloudinaryIdsOrImageUrls.length > 1 && (
          <>
            <MenuItem onClick={() => downloadAllImages("jpg")}>
              Download all as JPG
            </MenuItem>
            <MenuItem onClick={() => downloadAllImages("pdf")}>
              Download all as PDF
            </MenuItem>
          </>
        )}
      </Menu>
    </>
  );
};

export default ImageDownloadMenuButton;
