import React from "react";

const FileDownloadLink = ({ children, file, filename, fileType, ...props }) => {
  return (
    <a
      href={`data:${fileType};charset=utf-8,${encodeURIComponent(file)}`}
      download={filename}
      {...props}
    >
      {children}
    </a>
  );
};

FileDownloadLink.defaultProps = {
  fileType: "text/plain",
};

export default FileDownloadLink;
