/** @jsxImportSource @emotion/react */
import "twin.macro";

import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { useDispatch } from "react-redux";

import { Container, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import axios from "axios";
import { saveAs } from "file-saver";
import BackButton from "src/components/Reporting/BackButton";
import { setFiltersOpen } from "src/redux/slices/filterSlice";

import { StyledButton } from "../components/StyledComponents";
import LocalErrorBoundary from "../components/Utility/LocalErrorBoundary";
import OrderWindowSelector from "../components/Utility/Selectors/OrderWindowSelector";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
  ...theme.reports,
}));

const ReportTerritoryDetail = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [ocm, setOcm] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleGetReport = () => {
    if (!ocm) return;
    setLoading(true);
    axios
      .get(
        `/api/pre-order-moq-analysis-report?filter[order-calendar-month-id]=${ocm}`
      )
      .then((res) => {
        const blob = new Blob([res.data], {
          type: "text/csv;charset=utf-8",
        });
        saveAs(blob, `pre-order-MOQ-analysis-${ocm.formattedDate}.csv`);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    dispatch(setFiltersOpen({ open: false }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LocalErrorBoundary>
      <Helmet>
        <title>RTA | Pre Order MOQ Analysis</title>
      </Helmet>
      <Container className={classes.mainWrapper}>
        <div className={classes.titleBar}>
          <Typography className={classes.titleText}>
            Pre Order MOQ Analysis Report
          </Typography>
          <BackButton />
        </div>
        <br />
        <div className={classes.searchComponents} tw="max-w-xs min-w-0!">
          <OrderWindowSelector
            separatePast
            orderWindow={ocm}
            setOrderWindow={setOcm}
            disabled={loading}
          />
        </div>
        <div className={classes.chipRow}></div>
        <div className={classes.buttonRow}>
          <StyledButton
            cta
            loading={loading}
            disabled={loading || !ocm}
            onClick={handleGetReport}
          >
            GENERATE REPORT
          </StyledButton>
        </div>
      </Container>
    </LocalErrorBoundary>
  );
};

ReportTerritoryDetail.propTypes = {};

export default ReportTerritoryDetail;
