import { createQueryKeys } from "@lukemorales/query-key-factory";

import { OrderSetVariantSummary } from "@models/OrderSetVariantSummary";
import client from "@services/api";
import { buildPaginatedQuery } from "@utils/reactQuery";

export const orderSetVariantSummariesKeyFactory = createQueryKeys(
  "order-set-variant-summaries",
  {
    paginated: (params) => ({
      queryKey: [params],
      queryFn: () =>
        client.get<OrderSetVariantSummary[]>("order-set-variant-summaries", {
          params,
        }),
    }),
  }
);

export const useOrderSetVariantSummariesPaginated =
  buildPaginatedQuery<OrderSetVariantSummary>(
    orderSetVariantSummariesKeyFactory.paginated
  );
