/** @jsxImportSource @emotion/react */
import "twin.macro";

import React from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Check, CheckCircle, MoreHoriz } from "@mui/icons-material";
import {
  FormControl,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from "@mui/material";

import useOrderWindowPrograms from "./usePreOrderPrograms";

const PreOrderProgramSelector = () => {
  const navigate = useNavigate();
  const { orderWindowId, programId } = useParams();
  const { data, orderSetQuery } = useOrderWindowPrograms(
    orderWindowId as string
  );

  const handleChange = (event) => {
    const programId = event.target.value;
    navigate(`/orders/${orderWindowId}/${programId}`);
    orderSetQuery.refetch();
  };

  return (
    <>
      <FormControl tw="w-full max-w-lg" size="small">
        <Select
          variant="outlined"
          name="programs"
          labelId="program-select"
          id="programs"
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            style: { maxHeight: "75vh" },
          }}
          value={programId}
          onChange={handleChange}
          onFocus={(evt) => evt.stopPropagation()}
          onBlur={(evt) => evt.stopPropagation()}
          onClose={(evt) => evt.stopPropagation()}
        >
          {data.map(({ program, orderSet }) => (
            <MenuItem value={program.id} key={program.id}>
              <div tw="flex justify-between items-center">
                <Typography noWrap tw="text-lg font-medium">
                  {`${
                    program.brands.length === 1
                      ? program.brands[0].name
                      : "Multi Brand"
                  } - ${program.name}`}
                </Typography>
                {(orderSet?.status === "submitted" ||
                  orderSet?.status === "approved") && (
                  <Tooltip title="Submitted">
                    <CheckCircle color="secondary" tw="ml-2 mr-4" />
                  </Tooltip>
                )}
                {orderSet?.status === "in-progress" &&
                  orderSet?.isWorkComplete && (
                    <Tooltip title="Saved for Review">
                      <Check color="secondary" tw="ml-2 mr-4" />
                    </Tooltip>
                  )}
                {(orderSet?.status === "in-progress" ||
                  orderSet?.status === "inactive") &&
                  !orderSet?.isWorkComplete && (
                    <Tooltip title="In Progress">
                      <MoreHoriz color="secondary" tw="ml-2 mr-4" />
                    </Tooltip>
                  )}
              </div>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

export default PreOrderProgramSelector;
