import { createSlice } from "@reduxjs/toolkit";

import { axiosGetWithNext } from "../../../api/axiosCalls";
import { setError } from "../errorSlice";
import { startGlobalLoad, stopGlobalLoad } from "../globalLoadSlice";
import { buildRFPHistoryQuery } from "./helpers";
import { mapRFPHistory } from "./maps";

let initialState = {
  isLoading: false,
  isNextLoading: false,
  rfpsPerPage: 20,
  nextPage: null,
  nextLink: null,
  rfps: [],
  error: null,
};

const startLoading = (state) => {
  state.isLoading = true;
};

const startNextLoading = (state) => {
  state.isNextLoading = true;
};

const loadingFailed = (state, action) => {
  const { error } = action.payload;
  state.isLoading = false;
  state.error = error;
};

const rfpHistorySlice = createSlice({
  name: "rfpHistory",
  initialState,
  reducers: {
    setIsLoading: startLoading,
    setNextIsLoading: startNextLoading,
    getRfqHistorySuccess(state, action) {
      const { rfps, nextLink } = action.payload;
      state.nextPage = nextLink ? true : false;
      state.nextLink = nextLink;
      state.rfps = [...rfps];
      state.isLoading = false;
      state.error = null;
    },
    getNextRfqHistorySuccess(state, action) {
      const { rfps, nextLink } = action.payload;
      state.nextPage = nextLink ? true : false;
      state.nextLink = nextLink;
      state.rfps = state.rfps.concat(rfps);
      state.isNextLoading = false;
      state.error = null;
    },
    resetRfqHistory(state) {
      state.isLoading = false;
      state.isNextLoading = false;
      state.rfpsPerPage = 20;
      state.nextPage = null;
      state.nextLink = null;
      state.rfps = [];
      state.error = null;
    },
    setFailure: loadingFailed,
  },
});

export const {
  setIsLoading,
  setNextIsLoading,
  getRfqHistorySuccess,
  getNextRfqHistorySuccess,
  resetRfqHistory,
  setFailure,
} = rfpHistorySlice.actions;

export default rfpHistorySlice.reducer;

export const fetchFilteredRFPHistory = (filterObject) => async (dispatch) => {
  try {
    dispatch(setIsLoading());
    dispatch(startGlobalLoad());
    const queryString = buildRFPHistoryQuery(filterObject);
    const response = await axiosGetWithNext(queryString);
    if (response.error) throw response.error;
    let mappedData = mapRFPHistory(response.data.data);
    dispatch(
      getRfqHistorySuccess({
        rfps: mappedData,
        nextLink: response.data.nextLink,
      })
    );
    dispatch(stopGlobalLoad());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "RFP History" }));
    dispatch(stopGlobalLoad());
  }
};

export const fetchNextFilteredRFPHistory = (url) => async (dispatch) => {
  try {
    dispatch(setNextIsLoading());
    dispatch(startGlobalLoad());
    const response = await axiosGetWithNext(url);
    if (response.error) throw response.error;
    let mappedData = mapRFPHistory(response.data.data);
    dispatch(
      getNextRfqHistorySuccess({
        rfps: mappedData,
        nextLink: response.data.nextLink,
      })
    );
    dispatch(stopGlobalLoad());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "RFP History" }));
    dispatch(stopGlobalLoad());
  }
};
