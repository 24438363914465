import { createSlice } from "@reduxjs/toolkit";

import { axiosGet } from "../../../api/axiosCalls";
import { setError } from "../errorSlice";
import { startGlobalLoad, stopGlobalLoad } from "../globalLoadSlice";
import { mapItems } from "./maps";

/*
Currently the shared item functionality is not being used, but the code was left in case Gallo
changes their mind on it's usage. All the shared item functionality did was allow Gallo to
create a public route for unathenticated users to view a set of items. It was our proposed
solution to the pdf need, and in the end Gallo decided they would still rather use printed pdfs
instead of this.
*/

let initialState = {
  isLoading: false,
  items: [],
  error: null,
};

const loadingFailed = (state, action) => {
  const { error } = action.payload;
  state.isLoading = false;
  state.error = error;
};

const sharedItemSlice = createSlice({
  name: "sharedItems",
  initialState,
  reducers: {
    setIsLoading(state) {
      state.isLoading = true;
    },
    getItemsSuccess(state, action) {
      const { items } = action.payload;
      state.items = items;
      state.isLoading = false;
      state.error = null;
    },
    clearSharedItems(state) {
      state.isLoading = false;
      state.items = [];
      state.error = null;
    },
    setFailure: loadingFailed,
  },
});

export const { setIsLoading, getItemsSuccess, clearSharedItems, setFailure } =
  sharedItemSlice.actions;

export default sharedItemSlice.reducer;

export const fetchSharedItemsByIds = (ids) => async (dispatch) => {
  try {
    dispatch(setIsLoading());
    dispatch(startGlobalLoad());
    const response = await axiosGet(
      `/api/items?filter[ids]=${ids}&page[size]=${ids.length}`
    );
    if (response.error) throw response.error;
    const mappedData = mapItems(response.data);
    dispatch(getItemsSuccess({ items: mappedData }));
    dispatch(stopGlobalLoad());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Shared Items" }));
    dispatch(stopGlobalLoad());
  }
};
