/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import { Divider } from "@mui/material";

import { formatMoneyString } from "src/utility/utilityFunctions";

import { OrderVariant } from "@models";

import PaperTooltip from "../../ui/PaperTooltip";
import { getExtendedCost } from "../helpers";
import { useCurrentOrderSet } from "./data/CurrentOrderSetContext";

export default function OrderVariantCostBreakdown({
  orderVariant,
}: {
  orderVariant: OrderVariant;
}) {
  const { orderSet } = useCurrentOrderSet();
  const isFromInventory = orderSet.type === "from-inventory";
  const extendedCost = getExtendedCost(orderVariant, orderSet.type);
  return (
    <PaperTooltip
      tw="mt-3"
      placement="right-end"
      title={
        <>
          <h3 tw="font-medium mb-3">Cost break-down</h3>
          <div tw="grid grid-cols-[auto 1fr] gap-x-4 gap-y-1 text-sm">
            <div tw="text-neutral-500 tracking-wide">Item</div>
            <div
              css={[
                tw`text-right`,
                isFromInventory && tw`line-through opacity-50`,
              ]}
            >
              {orderVariant.qty} x{" "}
              {formatMoneyString(orderVariant.estimatedCost)} ={" "}
              {formatMoneyString(orderVariant.totalEstimatedCost)}
            </div>

            {orderVariant.variant.item.includeBeacon && (
              <>
                <div tw="text-neutral-500 tracking-wide">Beacons</div>
                <div tw="text-right">
                  {formatMoneyString(orderVariant.totalBeaconCost)}
                </div>
              </>
            )}

            <div tw="text-neutral-500 tracking-wide">Freight</div>
            <div tw="text-right">
              {formatMoneyString(orderVariant.totalEstimatedShippingCost)}
            </div>

            <div tw="text-neutral-500 tracking-wide">Taxes</div>
            <div tw="text-right">
              {formatMoneyString(orderVariant.totalEstimatedTax)}
            </div>

            <Divider tw="col-span-2 my-1" />

            <div tw="text-neutral-500 tracking-wide">Total</div>
            <div tw="font-medium text-right">
              {formatMoneyString(extendedCost)}
            </div>
          </div>
        </>
      }
    >
      <div tw="hover:bg-neutral-100 rounded">
        ={formatMoneyString(extendedCost)}
      </div>
    </PaperTooltip>
  );
}
