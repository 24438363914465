import React, { Suspense } from "react";
import { useImage } from "react-image";

import CircularProgress from "@mui/material/CircularProgress";

import PropTypes from "prop-types";

import ImgErrorBoundary from "./ImgErrorBoundary";

/*
The image wrapper handles fallback loading states, and fallback images if there is an error
loading the image. The wrapper also accepts a handleClick function if the image is clickable
*/

const ImgComp = ({ imgUrl, alt, imgClass, id, handleClick }) => {
  const { src } = useImage({ srcList: [imgUrl, "/images/NotFound.png"] });
  return (
    <img
      src={src}
      alt={alt}
      className={imgClass}
      id={id}
      onClick={handleClick}
    />
  );
};

const ImageWrapper = ({ imgUrl, alt, imgClass, id, handleClick }) => {
  return (
    <Suspense fallback={<CircularProgress size={75} />}>
      <ImgErrorBoundary alt={alt} imgClass={imgClass} id={id}>
        <ImgComp
          imgUrl={imgUrl}
          alt={alt}
          imgClass={imgClass}
          id={id}
          handleClick={handleClick}
        />
      </ImgErrorBoundary>
    </Suspense>
  );
};

ImageWrapper.propTypes = {
  imgUrl: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
};

export default React.memo(ImageWrapper);
