import { sortBy } from "lodash";

import { createSlice } from "@reduxjs/toolkit";

import { handleErrors } from "../../api/apiFunctions";
import { axiosGet, axiosPost, axiosPut } from "../../api/axiosCalls";
import { camelCaseKeys, kebabCaseKeys } from "../../utility/utilityFunctions";
import { setError as setGlobalError } from "./errorSlice";

/*
// Order calendar month slice
*/

let initialState = {
  isLoading: false,
  error: null,
  future: [],
  all: [],
  windows: [],
};

const replaceOrPush = (ocmList, newOcm) => {
  const ocmExists = ocmList.some((ocm) => ocm.id === newOcm.id);
  let newList = [];
  if (ocmExists) {
    newList = ocmList.map((ocm) => (ocm.id === newOcm.id ? newOcm : ocm));
  } else {
    newList = [...ocmList, newOcm];
  }
  return sortBy(newList, "inMarketStartDate");
};

const ocmSlice = createSlice({
  name: "orderCalendarMonths",
  initialState,
  reducers: {
    setLoading(state, { payload }) {
      state.isLoading = payload;
    },
    setError(state, { payload }) {
      state.error = payload;
    },
    getFutureOcmsSuccess(state, { payload }) {
      state.future = [...payload];
      state.isLoading = false;
    },
    getAllOcmsSuccess(state, { payload }) {
      state.all = [...payload];
      state.isLoading = false;
    },
    getOrderWindowSuccess(state, { payload }) {
      state.windows = [...payload];
      state.isLoading = false;
    },
    updateOcmSuccess(state, { payload }) {
      if (state.future.length > 0) {
        state.future = replaceOrPush(state.future, payload);
      }
      if (state.all.length > 0) {
        state.all = replaceOrPush(state.all, payload);
      }
      state.isLoading = false;
      state.error = null;
    },
  },
});

export const {
  setLoading,
  setError,
  getFutureOcmsSuccess,
  getAllOcmsSuccess,
  getOrderWindowSuccess,
  updateOcmSuccess,
} = ocmSlice.actions;

export default ocmSlice.reducer;

/**
  id: "156"
  in-market-start-date: null
  order-window-close-date: "2021-05-19"
  order-window-name: null
  order-window-open-date: "2021-05-07"
  purchasing-close-date: "2021-05-24"
  report-date: null
  review-close-date: "2021-05-21"
  type: "order-calendar-month"
  visible-date: "2021-04-16" 
  */
export const fetchFutureOcms = () => async (dispatch) => {
  dispatch(setLoading(true));
  const args = Object.entries({
    "filter[has-future-in-market-start-date]": true,
    "filter[is-ad-hoc]": false,
    "page[size]": 50,
    sort: "order-window-open-date",
  })
    .map(([key, value]) => `${key}=${value}`)
    .join("&");

  try {
    const res = await axiosGet(`/api/order-calendar-months?${args}`);
    if (res.error) throw new Error(res.error);

    const mapped = res.data.map(camelCaseKeys);
    dispatch(getFutureOcmsSuccess(mapped));
  } catch (error) {
    const handled = handleErrors(error);
    dispatch(
      setGlobalError({ error: handled, source: "fetch order calendar months" })
    );
  }

  dispatch(setLoading(false));
};
export const fetchAllOcms = () => async (dispatch) => {
  dispatch(setLoading(true));
  const args = Object.entries({
    "filter[is-ad-hoc]": false,
    "page[size]": 50,
    sort: "-order-window-open-date",
  })
    .map(([key, value]) => `${key}=${value}`)
    .join("&");

  try {
    const res = await axiosGet(`/api/order-calendar-months?${args}`);
    if (res.error) throw new Error(res.error);

    const mapped = res.data.map(camelCaseKeys);
    dispatch(getAllOcmsSuccess(mapped));
  } catch (error) {
    const handled = handleErrors(error);
    dispatch(
      setGlobalError({ error: handled, source: "fetch order calendar months" })
    );
  }

  dispatch(setLoading(false));
};

export const fetchOpenWindows = () => async (dispatch) => {
  dispatch(setLoading(true));
  const args = Object.entries({
    "filter[is-ad-hoc]": false,
    "page[size]": 150,
    sort: "order-window-open-date",
  })
    .map(([key, value]) => `${key}=${value}`)
    .join("&");

  try {
    const res = await axiosGet(`/api/order-calendar-months?${args}`);
    if (res.error) throw new Error(res.error);

    const mapped = res.data.map(camelCaseKeys);

    dispatch(getOrderWindowSuccess(mapped));
  } catch (error) {
    const handled = handleErrors(error);
    dispatch(
      setGlobalError({ error: handled, source: "fetch order calendar months" })
    );
  }

  dispatch(setLoading(false));
};

export const createOrUpdateOCM =
  ({ id, ...ocmData }, callback) =>
  async (dispatch) => {
    try {
      dispatch(setLoading(true));
      dispatch(setError(null));
      const reqFunction = id ? axiosPut : axiosPost;
      const res = await reqFunction(
        `/api/order-calendar-months${id ? "/" + id : ""}`,
        {
          data: { attributes: kebabCaseKeys(ocmData) },
        }
      );
      if (res.error) throw new Error(res.error);
      const newOcm = camelCaseKeys(res.data);
      dispatch(updateOcmSuccess(newOcm));
      callback?.(newOcm);
    } catch (err) {
      console.error(err);
      dispatch(setError(err.message));
      dispatch(setLoading(false));
    }
  };
