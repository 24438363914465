/** @jsxImportSource @emotion/react */
import "twin.macro";
import tw from "twin.macro";

import React from "react";

import { MoreHoriz, Warning } from "@mui/icons-material";
import { Tooltip } from "@mui/material";

import { addDays, endOfDay, isBefore } from "date-fns";
import { capitalize } from "lodash";
import { SmartTable } from "src/components/SmartTable";
import {
  Column,
  PaginatedResourceProps,
} from "src/components/SmartTable/SmartTable";
import {
  formatDateString,
  formatMoneyString,
  formatUtcDate,
  utcDate,
} from "src/utility/utilityFunctions";

import { getAnaplanProgramsLabel } from "@features/anaplanPrograms/helpers";
import BudgetStatusChip from "@features/budgets/BudgetStatusChip";
import { OrderSetVariantSummary } from "@models/OrderSetVariantSummary";

type ApprovalsOrderSetVariantSummariesTableProps = PaginatedResourceProps & {
  data: OrderSetVariantSummary[];
  showAnaplanPrograms?: boolean;
  tableRef: React.MutableRefObject<any>;
};

const isOverDue = (osvs: OrderSetVariantSummary) =>
  osvs.orderCalendarMonth && osvs.orderSetStatus === "in-progress"
    ? isBefore(
        addDays(
          endOfDay(utcDate(osvs.orderCalendarMonth.purchasingCloseDate)),
          1
        ),
        endOfDay(new Date())
      )
    : false;

const formatStatus = (status) => {
  if (status === "inactive") {
    return "Not Started";
  } else if (status === "in-progress") {
    return "In Progress";
  } else if (status === "submitted") {
    return "Order Submitted";
  } else {
    return capitalize(status);
  }
};

const costColumn = {
  align: "right" as const,
  render: (value) => formatMoneyString(value),
};

const ApprovalsOrderSetVariantSummariesTable = ({
  data,
  showAnaplanPrograms,
  ...tableProps
}: ApprovalsOrderSetVariantSummariesTableProps) => {
  const rowLink = (row: OrderSetVariantSummary) =>
    `/orders/open/${row.orderSet.id}#rollup`;
  const columns: Column<OrderSetVariantSummary>[] = [
    {
      id: "budgets",
      label: "Budget Status",
      render: (budgets) => <BudgetStatusChip budgets={budgets} />,
      to: rowLink,
    },
    {
      id: "userName",
      label: "Ordered By",
      render: (name, row) => (
        <div tw="flex items-center">
          {isOverDue(row) && (
            <Tooltip
              placement="top-start"
              title="This order was not submitted on it's intended schedule, please notify your administrator if you are seeing this message."
            >
              <Warning fontSize="small" color="inherit" tw="pl-2" />
            </Tooltip>
          )}
          {name}
        </div>
      ),
      to: rowLink,
    },
    { id: "territoryNames", label: "Territory", to: rowLink },
    { id: "sequenceNumber", label: "Sequence #", sort: "item-number" },
    { id: "programName", label: "Program", sort: "program-name" },
    ...(showAnaplanPrograms
      ? [
          {
            id: "anaplanPrograms",
            label: "Anaplan Program",
            render: getAnaplanProgramsLabel,
          },
        ]
      : []),
    {
      id: "variant.item.brands",
      label: "Brand",
      render: (brands) => (
        <Tooltip
          placement="left"
          title={brands.length > 1 && `${brands.map((b) => b.name).join(", ")}`}
        >
          <div>
            {brands[0]?.name}
            {brands.length > 1 && (
              <MoreHoriz fontSize="small" color="inherit" />
            )}
          </div>
        </Tooltip>
      ),
    },
    {
      id: "itemTypeDescription",
      label: "Item Type",
      sort: "item-type-description",
    },
    {
      id: "variant.item.comment",
      label: "Item Desc.",
    },
    { id: "stateNames", label: "State" },
    { id: "qtyPerPack", label: "Qty / Pack" },
    {
      id: "totalItemQty",
      label: "Total Items",
    },
    { id: "estimatedCost", label: "Est. Cost", ...costColumn },
    {
      id: "totalEstimatedCost",
      label: "Est. Total Costs",
      ...costColumn,
      render: (v, row) =>
        formatMoneyString(row.orderSetType === "from-inventory" ? 0 : v),
    },
    {
      id: "totalBeaconCost",
      label: "Total Beacon Cost",
      ...costColumn,
    },
    {
      id: "totalEstimatedFreight",
      label: "Est. Freight",
      ...costColumn,
    },
    {
      id: "totalEstimatesTaxes",
      label: "Est. Taxes",
      ...costColumn,
    },
    {
      id: "totalExtendedCost",
      label: "Est. Extended Total",
      ...costColumn,
      render: (v, row) =>
        formatMoneyString(
          row.orderSetType === "from-inventory"
            ? v - +row.totalEstimatedCost
            : v
        ),
    },
    {
      id: "orderSetSubmittedAt",
      label: "Order Submitted",
      render: (v) => formatUtcDate(v),
    },
    {
      id: "orderCalendarMonth.orderWindowCloseDate",
      label: "Order Window Close",
      render: formatDateString,
    },
    {
      id: "orderCalendarMonth.inMarketStartDate",
      label: "In Market Date",
      render: formatDateString,
    },
    { id: "orderSetStatus", label: "Status", render: formatStatus },
  ];

  return (
    <SmartTable
      columns={columns}
      rows={data}
      {...tableProps}
      rowCss={(row) => isOverDue(row) && tw`bg-red-100 hover:bg-red-50`}
    />
  );
};

export default ApprovalsOrderSetVariantSummariesTable;
