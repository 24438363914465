/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useState } from "react";

import {
  CircularProgress,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import { useAnaplanPrograms } from "src/hooks/planningToolHooks";

const getYearRangeArray = () => {
  const start = new Date().getFullYear() - 1;
  const end = start + 4;
  return Array.from({ length: end - start }, (value, index) => start + index);
};

const filterPrograms = (programs, programNameIdSearch, year) => {
  if (programs) {
    let programsToDisplay = programs;

    // Filter by search term
    if (programNameIdSearch !== "") {
      programsToDisplay = programs.filter((prog) => {
        if (
          prog.name.includes(programNameIdSearch) ||
          prog.code.includes(programNameIdSearch)
        ) {
          return prog;
        }
        return false;
      });
    }

    // Filter by year
    if (year) {
      programsToDisplay = programsToDisplay.filter((p) => {
        return p.startDate.substring(0, 4) === year.toString();
      });
    }

    return programsToDisplay;
  }
};

const AnaplanProgramSearch = ({
  program,
  selectedAnaplanProgram,
  setSelectedAnaplanProgram,
}) => {
  const filterObject = {
    programTypeId: program.programTypeId,
    channel: program.channel,
    isActivated: false,
  };
  const { isLoading, programs } = useAnaplanPrograms(filterObject);

  const [programNameIdSearch, setProgramNameIdSearch] = useState("");

  const [year, setYear] = useState("");

  const yearRangeArray = getYearRangeArray();

  const filteredPrograms = filterPrograms(programs, programNameIdSearch, year);

  return (
    <>
      <Typography variant="h6" tw="mb-4">
        Select Anaplan Program
      </Typography>
      <Typography variant="p">Anaplan Program Search Filters:</Typography>
      <Stack direction="row" tw="mt-4">
        <TextField
          color="secondary"
          fullWidth
          name="codeOrName"
          type="text"
          label="Anaplan Id/Name"
          variant="outlined"
          size="small"
          onChange={(e) => setProgramNameIdSearch(e.target.value)}
          tw="pr-4"
        />
        <FormControl fullWidth tw="mb-4 w-4/12">
          <InputLabel size="small">Year</InputLabel>
          <Select
            value={year}
            label="Year"
            size="small"
            onChange={(e) => {
              setYear(e.target.value);
            }}
          >
            {yearRangeArray.map((year) => {
              return (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Stack>
      <Divider tw="mb-4" />

      {isLoading && <CircularProgress />}

      {!isLoading && filteredPrograms.length === 0 && (
        <Typography>No results...</Typography>
      )}

      {!isLoading && filteredPrograms.length > 0 && (
        <FormControl fullWidth>
          <InputLabel size="small">Anaplan Program</InputLabel>
          <Select
            value={selectedAnaplanProgram}
            label="Anaplan Program"
            size="small"
            onChange={(e) => {
              setSelectedAnaplanProgram(e.target.value);
            }}
          >
            {filteredPrograms.map((program) => (
              <MenuItem key={program.id} value={program}>
                {program.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </>
  );
};

export default AnaplanProgramSearch;
