/** @jsxImportSource @emotion/react */
// eslint-disable-next-line no-unused-vars
import tw from "twin.macro";

import { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ClearIcon from "@mui/icons-material/Clear";
import { CircularProgress } from "@mui/material";

import { isEqual } from "lodash";

import {
  axiosDelete,
  axiosGet,
  axiosPost,
  axiosPut,
} from "../../../api/axiosCalls";
import { setError } from "../../../redux/slices/errorSlice";
import { moneyAdornment, moneyValidation } from "../../../utility/inputHelpers";
import { formatMoneyString } from "../../../utility/utilityFunctions";
import { TextInput } from "../../Forms/ControlledInputs";
import { StyledButton } from "../../StyledComponents";
import CollapsibleComponent from "../CollapsibleComponent";

const VariablePricingInput = ({ itemProgramId }) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      varPricingRanges: [],
    },
  });
  const { fields, append, remove, update, replace } = useFieldArray({
    control,
    name: "varPricingRanges",
  });

  const controlled = (name, validationRule) => ({
    name,
    control,
    rules: validationRule,
  });

  const handleSave = async (data) => {
    const updatePrev = async (sortedTiers, currentIndex, qty) => {
      // Modifies the tier before the currentIndex
      if (
        currentIndex === 0 ||
        sortedTiers[currentIndex - 1].wasUpdated ||
        !sortedTiers[currentIndex - 1].backendId
      ) {
        return;
      }

      const body = {
        data: {
          attributes: {
            item_program_id: itemProgramId,
            min_range_value: sortedTiers[currentIndex - 1].qty,
            max_range_value: qty - 1,
            unit_price: sortedTiers[currentIndex - 1].unitPrice,
          },
        },
      };

      return axiosPut(
        `/api/item-programs/${itemProgramId}/variable-pricing/${
          sortedTiers[currentIndex - 1].backendId
        }`,
        body
      );
    };

    const getCurrentBody = (sortedTiers, indexOfCurrent, qty, unitPrice) => ({
      data: {
        attributes: {
          item_program_id: itemProgramId,
          min_range_value: qty,
          max_range_value:
            indexOfCurrent === sortedTiers.length - 1
              ? undefined
              : sortedTiers[indexOfCurrent + 1].qty - 1,
          unit_price: unitPrice,
        },
      },
    });

    const handlePricingRange = async (sortedTiers, priceRange, isUpdate) => {
      // Modifies the previous tiers max-range in the db then handles this tier
      const indexOfCurrent = sortedTiers.findIndex((pr) =>
        isUpdate
          ? pr.backendId === priceRange.backendId
          : pr.id === priceRange.id
      );

      await updatePrev(sortedTiers, indexOfCurrent, priceRange.qty);

      const body = getCurrentBody(
        sortedTiers,
        indexOfCurrent,
        priceRange.qty,
        priceRange.unitPrice
      );
      if (isUpdate) {
        await axiosPut(
          `/api/item-programs/${itemProgramId}/variable-pricing/${priceRange.backendId}`,
          body
        );
      } else {
        await axiosPost(
          `/api/item-programs/${itemProgramId}/variable-pricing`,
          body
        );
      }

      update(indexOfCurrent, {
        ...sortedTiers[indexOfCurrent],
        wasUpdated: true,
      });
    };

    setIsLoading(true);
    const sortedTiers = sortTiers();

    const newPricingRanges = sortedTiers.filter((pr) => !pr.isFromBackend);
    const existingPricingRanges = sortedTiers.filter(
      (pr) => pr.isFromBackend && pr.wasChanged
    );

    try {
      await Promise.all(
        existingPricingRanges.map((pr) =>
          handlePricingRange(sortedTiers, pr, true)
        )
      );
      await Promise.all(
        newPricingRanges.map((pr) => handlePricingRange(sortedTiers, pr, false))
      );
    } catch (error) {
      dispatch(
        setError({
          error: "Error saving tier(s)",
          source: "Creating/updating variable pricing",
        })
      );
      console.error("Error occurred:", error);
    } finally {
      await syncArray();
      setIsLoading(false);
    }
  };

  const handleAddPricingRange = () => {
    append({
      qty: "",
      unitPrice: "",
    });
  };

  const deletePricingRange = async (indexToRemove) => {
    /**
     * When deleting we handle 3 cases, deleting between two tiers, deleting the last tier, and deleting the first tier
     */

    const updateBackendRange = async (index, attributes) => {
      if (fields[index].backendId) {
        return await axiosPut(
          `/api/item-programs/${itemProgramId}/variable-pricing/${fields[index].backendId}`,
          { data: { attributes: attributes } }
        );
      }
      return Promise.resolve(); // return resolved promise if condition is not met
    };

    const deleteFromBackend = async (index) => {
      if (fields[index].backendId) {
        await axiosDelete(
          `/api/item-programs/${itemProgramId}/variable-pricing/${fields[index].backendId}`
        );
      }
      remove(index);
    };

    try {
      if (fields[indexToRemove - 1] && fields[indexToRemove + 1]) {
        // Deleting something between two tiers
        await updateBackendRange(indexToRemove - 1, {
          item_program_id: itemProgramId,
          min_range_value: fields[indexToRemove - 1].qty,
          max_range_value: fields[indexToRemove + 1].qty - 1,
          unit_price: fields[indexToRemove - 1].unitPrice,
        });
        await deleteFromBackend(indexToRemove);
      } else if (fields[indexToRemove - 1] && !fields[indexToRemove + 1]) {
        // Deleting something with only a tier below it
        await updateBackendRange(indexToRemove - 1, {
          item_program_id: itemProgramId,
          min_range_value: fields[indexToRemove - 1].qty,
          max_range_value: "",
          unit_price: fields[indexToRemove - 1].unitPrice,
        });
        await deleteFromBackend(indexToRemove);
      } else {
        // Deleting the first tier
        await deleteFromBackend(indexToRemove);
      }
    } catch (error) {
      console.error("Error updating backend range:", error);
      dispatch(
        setError({
          error: "Error deleting tier",
          source: "Delete/Update variable pricing",
        })
      );
    }
  };

  const syncArray = async () => {
    /**
     * This method is called on startup and onsave.  We call this on save so that we don't have to manage the array
     * while checking to see which field updates were successful and which were not.
     */
    try {
      setIsLoading(true);
      const res = await axiosGet(
        `/api/item-programs/${itemProgramId}/variable-pricing`
      );
      const transformedData = res.data.map((varPrice) => ({
        backendId: varPrice.id,
        qty: varPrice["min-range-value"],
        unitPrice: formatMoneyString(varPrice["unit-price"]).replace("$", ""),
        isFromBackend: true,
        wasChanged: false,
      }));
      replace(transformedData);
    } catch (error) {
      console.error("Error fetching variable pricing:", error);
      dispatch(
        setError({
          error: "Error getting tier data",
          source: "Get variable pricing",
        })
      );
    } finally {
      setIsLoading(false);
    }
  };

  // Get the variable pricing field array from the backend when the component loads
  useEffect(() => {
    const getVarPricing = async () => {
      if (itemProgramId && fields.length === 0) {
        await syncArray();
      }
    };

    getVarPricing();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sortTiers = () => {
    const sortedFields = [...fields].sort((a, b) => a.qty - b.qty);
    if (!isEqual(sortedFields, fields)) {
      replace(sortedFields);
    }
    return sortedFields;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  // Sets wasChanged to true and updates the value for the field that was changed in the array
  const handleInputChange = (index, originalValue, fieldName) => (e) => {
    e.stopPropagation();

    let newValue = e.target.value;
    if (originalValue !== newValue) {
      update(index, {
        ...fields[index],
        wasChanged: true,
        [fieldName]: newValue,
      });
    }
  };

  return (
    <CollapsibleComponent title="Item Variable Pricing Scheme">
      <div tw="text-center">
        {fields.map((pricingRange, index) => (
          <div tw="m-4" key={pricingRange.id} tabIndex={0}>
            <TextInput
              label="Qty."
              {...controlled(`varPricingRanges.${index}.qty`)}
              tw="mr-2"
              type="number"
              onBlur={handleInputChange(index, pricingRange.qty, "qty")}
              error={
                errors.varPricingRanges && errors.varPricingRanges[index]?.qty
              }
              helperText={
                errors.varPricingRanges &&
                errors.varPricingRanges[index]?.qty?.message
              }
            />
            <TextInput
              label="Unit Price"
              {...controlled(`varPricingRanges.${index}.unitPrice`, {
                moneyValidation,
                required: true,
              })}
              tw="mr-2"
              onBlur={handleInputChange(
                index,
                pricingRange.unitPrice,
                "unitPrice"
              )}
              InputProps={moneyAdornment}
            />
            {!isLoading && (
              <ClearIcon
                onClick={() => deletePricingRange(index)}
                tw="cursor-pointer"
              />
            )}
          </div>
        ))}

        <div tw="flex flex-col items-center justify-center">
          <StyledButton onClick={handleAddPricingRange} tw="mb-2">
            <AddCircleOutlineIcon tw="mr-2" /> Add Variable Pricing Range
          </StyledButton>
          {isLoading ? (
            <div tw="flex justify-center">
              <CircularProgress />
            </div>
          ) : (
            fields.length > 0 && (
              <StyledButton
                cta
                tw="whitespace-nowrap"
                onClick={handleSubmit(handleSave)}
                loading={isLoading}
              >
                Save Variable Pricing
              </StyledButton>
            )
          )}
        </div>
      </div>
    </CollapsibleComponent>
  );
};

export default VariablePricingInput;
