import React from "react";
import { useNavigate } from "react-router-dom";

import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import PropTypes from "prop-types";

import StatusChip from "../../Utility/StatusChip";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
}));

const QuoteSearchTable = ({ handleSort, quoteItems, isLoading, scrollRef }) => {
  const navigate = useNavigate();
  const classes = useStyles();

  const statusMap = {
    draft: "",
    sent: "warning",
    accepted: "info",
    complete: "success",
    canceled: "error",
  };

  const headCells = [
    { id: "supplierQuoteNumber", label: "Quote Number" },
    { id: "itemNumber", label: "Seq #" },
    { id: "brands", label: "Brand(s)" },
    { id: "itemType", label: "Item Type" },
    { id: "itemDesc", label: "Item Desc." },
    {
      id: "status",
      label: "Status",
      fn: (v) => (
        <StatusChip status={statusMap[v]} label={v === "sent" ? "new" : v} />
      ),
    },
    { id: "round", label: "Quote Round" },
  ];

  const handleGoToQuote = (id) => {
    navigate(`/planning/quote/${id}`);
  };

  return (
    <>
      <TableContainer
        className={classes.tableContainer}
        style={{ maxHeight: "Calc(100vh - 275px)" }}
        ref={scrollRef}
      >
        <Table
          stickyHeader
          className={classes.table}
          aria-label="planning-tool-rfqs-table"
        >
          <TableHead>
            <TableRow>
              {headCells.map((cell) => (
                <TableCell
                  className={classes.headerText}
                  key={cell.id}
                  align="left"
                >
                  {cell.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {!isLoading && quoteItems.length === 0 && (
              <TableRow>
                <TableCell
                  align="left"
                  colSpan={headCells.length}
                  className={classes.headerText}
                >
                  There are currently no Quotes that match this search
                  criteria..
                </TableCell>
              </TableRow>
            )}
            {!isLoading &&
              quoteItems.length > 0 &&
              quoteItems.map((row, i) => (
                <TableRow
                  key={i}
                  className={classes.clickableRow}
                  onClick={() => handleGoToQuote(row.id)}
                >
                  {headCells.map((cell, index) => (
                    <TableCell key={`cell-${index}`} align="left">
                      {typeof cell.fn === "function"
                        ? cell.fn(row[cell.id], row)
                        : row[cell.id]}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            {isLoading && (
              <TableRow>
                <TableCell align="left" colSpan={headCells.length}>
                  <CircularProgress />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

QuoteSearchTable.propTypes = {
  handleSort: PropTypes.func.isRequired,
  quoteItems: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  scrollRef: PropTypes.any,
};

export default React.memo(QuoteSearchTable, (prev, next) => {
  return (
    prev.quoteItems === next.quoteItems && prev.isLoading === next.isLoading
  );
});
