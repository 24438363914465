import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { useDispatch, useSelector } from "react-redux";

import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import makeStyles from "@mui/styles/makeStyles";

import { setFiltersOpen } from "src/redux/slices/filterSlice";

import AddressOptions from "../components/Profile/AddressOptions";
import CustomAddresses from "../components/Profile/CustomAddresses";
import DistributorAddresses from "../components/Profile/DistributorAddresses";
import DistributorEmails from "../components/Profile/DistributorEmails";
import FavoriteItems from "../components/Profile/FavoriteItems";
import General from "../components/Profile/General";
import OrderPatchLoading from "../components/Utility/OrderPatchLoading";
import { useNoFetch, useWindowHash } from "../hooks/UtilityHooks";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
  borderRight: {
    paddingRight: "10px",
    borderRight: "1px solid #4C4C4C",
    minWidth: "150px",
  },
}));

const Profile = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const tabs = [
    {
      hash: "#general",
      label: "General",
      component: <General />,
      nonPermittedUserRoles: [],
    },
    {
      hash: "#favoriteAddresses",
      label: "Favorite Addresses",
      component: <AddressOptions />,
      nonPermittedUserRoles: ["read-only", "compliance"],
    },
    {
      hash: "#customAddresses",
      label: "Custom Addresses",
      component: <CustomAddresses />,
      nonPermittedUserRoles: ["read-only", "compliance"],
    },
    {
      hash: "#distributorChangeRequests",
      label: "Distributor Change Requests",
      component: <DistributorAddresses />,
      nonPermittedUserRoles: ["read-only", "compliance"],
    },
    {
      hash: "#distributorEmailUpdate",
      label: "Distributor Email Update",
      component: <DistributorEmails />,
      nonPermittedUserRoles: ["read-only", "compliance"],
    },
    {
      hash: "#favoriteItem",
      label: "Favorite Items",
      component: <FavoriteItems classes={classes} />,
      nonPermittedUserRoles: ["read-only", "compliance"],
    },
  ];

  const [selectedIndex, setSelectedIndex] = useState(1);

  const currentUserRole = useSelector((state) => state.user.role);

  const handleChangeTab = useWindowHash(
    tabs.map((t) => t.hash),
    setSelectedIndex
  );

  useEffect(() => {
    dispatch(setFiltersOpen({ open: false }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useNoFetch(dispatch);

  return (
    <>
      <Helmet>
        <title>RTA | Profile</title>
      </Helmet>
      <Container className={classes.mainWrapper}>
        <Grid container spacing={2} alignItems="stretch" wrap="nowrap">
          <Grid item md={1} xs={false} />
          <Grid item md={2} xs={2} className={classes.borderRight}>
            <List component="nav" aria-label="settings options">
              {tabs.map((tab, index) => {
                return (
                  !tab.nonPermittedUserRoles.includes(currentUserRole) && (
                    <div key={index}>
                      <Divider />
                      <ListItem
                        button
                        selected={selectedIndex === index}
                        onClick={(evt) => {
                          handleChangeTab(evt, index);
                        }}
                      >
                        <ListItemText primary={tab.label} />
                      </ListItem>
                    </div>
                  )
                );
              })}
            </List>
          </Grid>
          <Grid item md={8} xs={10} style={{ paddingLeft: "20px" }}>
            {tabs.map((tab, index) => (
              <div key={index}>{selectedIndex === index && tab.component}</div>
            ))}
          </Grid>
          <Grid item md={1} xs={false} />
        </Grid>
      </Container>
      <br />
      <OrderPatchLoading />
    </>
  );
};

Profile.propTypes = {};

export default Profile;
