/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { useDispatch, useSelector } from "react-redux";

import GetAppIcon from "@mui/icons-material/GetApp";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";

import BackButton from "src/components/Reporting/BackButton";
import { setFiltersOpen } from "src/redux/slices/filterSlice";

import { CSVLink } from "@utils/csv";

import ReportTable from "../components/Reporting/ReportTable";
import { useInput } from "../hooks/InputHooks";
import { useReportCsv } from "../hooks/Reports/useReportCsv";
import { useNoFetch } from "../hooks/UtilityHooks";
import {
  clearReports,
  getInvoiceDoubleCheckReport,
} from "../redux/slices/reports/reportSlice";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
  ...theme.reports,
}));

const ReportInvoiceDoubleCheck = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [hasGenerated, setHasGenerated] = useState(false);

  const {
    value: invoiceNumber,
    bind: bindInvoiceNumber,
    reset: resetInvoiceNumber,
  } = useInput("");

  const isLoading = useSelector((state) => state.reports.isLoading);
  const report = useSelector((state) => state.reports.reportData);
  const reportType = useSelector((state) => state.reports.reportType);

  const { setCsvData, data, headers } = useReportCsv(
    "invoice-double-check",
    hasGenerated,
    setHasGenerated,
    isLoading,
    report
  );

  const handleReset = () => {
    dispatch(clearReports());
    resetInvoiceNumber();
    setCsvData({ data: [], headers: [] });
  };

  const handleGetReport = () => {
    dispatch(getInvoiceDoubleCheckReport(invoiceNumber));
    setHasGenerated(true);
    resetInvoiceNumber();
  };

  useEffect(() => {
    dispatch(setFiltersOpen({ open: false }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useNoFetch(dispatch);

  useEffect(() => {
    dispatch(clearReports());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>RTA | Invoice Check</title>
      </Helmet>
      <Container className={classes.mainWrapper}>
        <div className={classes.titleBar}>
          <Typography className={classes.titleText}>
            Invoice Check Report
          </Typography>
          <div className={classes.configButtons}>
            <div className={classes.innerConfigDiv}>
              <div tw="mr-2">
                <BackButton />
              </div>
              <CSVLink
                data={data}
                headers={headers}
                filename="invoice_double_check.csv"
              >
                <Button
                  className={classes.largeButton}
                  variant="contained"
                  color="secondary"
                  startIcon={<GetAppIcon />}
                  disabled={data.length === 0}
                >
                  EXPORT TO CSV
                </Button>
              </CSVLink>
            </div>
          </div>
        </div>
        <br />
        <div className={classes.searchComponents}>
          <TextField
            fullWidth
            variant="outlined"
            color="secondary"
            name="invoiceNumber"
            type="text"
            label="InvoiceNumber"
            className={classes.queryField}
            {...bindInvoiceNumber}
          />
        </div>
        <br />
        <div className={classes.buttonRow}>
          <Button
            className={classes.largeButton}
            variant="contained"
            color="secondary"
            onClick={() => handleGetReport()}
            style={{ marginRight: "20px" }}
          >
            GENERATE REPORT
          </Button>
          <Button
            className={classes.largeButton}
            variant="contained"
            color="secondary"
            onClick={handleReset}
          >
            RESET REPORT
          </Button>
        </div>
        <br />
        <br />
        {isLoading && (
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <CircularProgress size={100} style={{ marginTop: "50px" }} />
          </div>
        )}
        {!isLoading &&
          report.length > 0 &&
          reportType === "invoice-double-check" && (
            <ReportTable report={report} type="invoice-double-check" />
          )}
      </Container>
    </>
  );
};

ReportInvoiceDoubleCheck.propTypes = {};

export default ReportInvoiceDoubleCheck;
