import _ from "lodash";
import { separateByComma } from "src/utility/utilityFunctions";

import { query } from "@services/api";

const getIds = (val) => (!!val?.length ? separateByComma(val, "id") : null);

export const buildTriggeredRuleQuery = (filters) => {
  const params = {
    filter: {
      itemNumber: filters.itemNumber,
      activityDateRange: [filters.fromDate, filters.toDate]
        .filter(Boolean)
        .join(" - "),
      programId: getIds(filters.program)?.split(",").pop(),
      orderCalendarMonthId: filters.ocmIds,
      stateIds: getIds(filters.stateIds),
      brandIds: getIds(filters.brand),
      itemTypeIds: getIds(filters.itemType),
      type: filters.ruleType === "all" ? null : filters.ruleType,
      status: filters.status === "all" ? null : filters.status,
    },
    pageSize: 50,
  };
  return `/api/triggered-rules?${query(params)}`;
};
