/** @jsxImportSource @emotion/react */
import "twin.macro";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { Close } from "@mui/icons-material";
import { DialogTitle } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";

import { getCouponUrl } from "../../../api/couponApi";
import { setError } from "../../../redux/slices/errorSlice";

const TemplatedCouponModal = ({ handleClose }) => {
  const dispatch = useDispatch();
  const { pid: programId } = useParams();
  const [iframeUrl, setIframeUrl] = useState(null);

  const { id: userId, email } = useSelector((state) => state.user);

  useEffect(() => {
    if (!iframeUrl) {
      (async () => {
        try {
          const postbackUrl = `${process.env.REACT_APP_COUPON_POSTBACK_URL}?user_id=${userId}&program_id=${programId}`;

          const iframeUrl = await getCouponUrl(email, postbackUrl);

          setIframeUrl(iframeUrl);
        } catch (error) {
          console.log(error);
          dispatch(setError({ error: error.toString(), source: "Coupons" }));
        }
      })();
    }
  }, [iframeUrl, userId, email, programId, dispatch]);

  return (
    <Dialog open onClose={handleClose} fullWidth maxWidth="lg">
      <DialogTitle tw="flex items-baseline justify-between">
        Templated Coupon Wizard
        <IconButton onClick={handleClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <p tw="mb-4 text-neutral-600">
          Complete the wizard to create the custom coupon. Closing the modal
          will cause the wizard to start over.
        </p>
        {!iframeUrl && <CircularProgress />}
        {iframeUrl && (
          <iframe
            src={iframeUrl}
            title="Create a Coupon"
            tw="block w-full h-[67vh]"
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default TemplatedCouponModal;
