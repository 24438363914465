import { useSelector } from "react-redux";

import _ from "lodash";

import { useProgramsPaginated } from "@features/programs";

import { useOrderSetsPaginated } from "../queries/orderSetQueries";

export default function usePreOrderPrograms(orderWindowId: string | undefined) {
  const {
    id: userId,
    currentChannel: channel,
    currentTerritory,
  } = useSelector((state: any) => state.user);

  const programsQuery = useProgramsPaginated(
    {
      filter: {
        orderCalendarMonthIds: orderWindowId,
        isPreOrderActive: true,
        isPreOrder: true,
        isCanceled: false,
        channel,
      },
    },
    {
      initialPagesToLoad: 100,
      enabled: !!orderWindowId,
    }
  );
  const orderSetQuery = useOrderSetsPaginated(
    {
      filter: {
        isPreOrderActive: true,
        type: "pre-order",
        isAdHoc: false,
        userIds: userId,
        ...(currentTerritory
          ? { territoryIds: currentTerritory }
          : { allRegions: true }),
        channel,
      },
    },
    {
      initialPagesToLoad: 100,
      enabled: !!orderWindowId,
    }
  );

  const { data: orderSets } = orderSetQuery;
  const { data: programs } = programsQuery;

  const orderSetLookup = _.keyBy(orderSets, "program.id");

  const data = programs.map((program) => {
    const orderSet = orderSetLookup[program.id];
    return {
      program,
      orderSet,
    };
  });

  return { data, programsQuery, orderSetQuery };
}
