/** @jsxImportSource @emotion/react */
import "twin.macro";

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import TextField from "@mui/material/TextField";

import { addDays, subMonths } from "date-fns";
import PropTypes from "prop-types";

import { setClear } from "../../redux/slices/filterSlice";
import BrandAutoComplete from "../Utility/AutoCompleteFields/BrandAutoComplete";
import ItemTypeAutoComplete from "../Utility/AutoCompleteFields/ItemTypeAutoComplete";
import ProgramAutoComplete from "../Utility/AutoCompleteFields/ProgramAutoComplete";
import StateAutoComplete from "../Utility/AutoCompleteFields/StateAutoComplete";
import DateRangeSelector from "../Utility/Selectors/DateRangeSelector";
import OrderWindowSelector from "../Utility/Selectors/OrderWindowSelector";
import RuleTypeSelector from "../Utility/Selectors/RuleTypeSelector";
//import ProgramAutoComplete from "../Utility/AutoCompleteFields/ProgramAutoComplete";
import StatusSelector from "../Utility/Selectors/StatusSelector";
import { executeOnEnter } from "./utility";

const FiltersCompliance = ({
  reset,
  setReset,
  handleFilters,
  classes,
  bindSequenceNum,
  handleSearch,
  complianceType,
}) => {
  const dispatch = useDispatch();
  const [status, setStatus] = useState("all");
  const [type, setType] = useState("all");
  const isLoading = useSelector((state) => state.globalLoad.isLoading);
  const toDate = useSelector((state) => state.filters.toDate);
  const fromDate = useSelector((state) => state.filters.fromDate);

  const [orderWindow, setOrderWindow] = useState("");

  const handleOrderWindow = (value) => {
    setOrderWindow(value);
    handleFilters(value, "ocmIds", "planningTool");
  };

  return (
    <>
      <List onKeyPress={executeOnEnter(handleSearch)}>
        {complianceType === "items" && (
          <>
            <ListItem>
              <TextField
                className={classes.queryField}
                color="secondary"
                fullWidth
                name="sequenceNumber"
                type="text"
                label="Sequence #"
                {...bindSequenceNum}
                variant="outlined"
                size="small"
                disabled={isLoading}
              />
            </ListItem>
            <ListItem>
              <Button
                fullWidth
                className={classes.largeButton}
                variant="contained"
                color="secondary"
                onClick={handleSearch}
                disabled={isLoading}
              >
                SEARCH
              </Button>
            </ListItem>
            <ListItem />
            <Divider />
            <ListItem />
            <DateRangeSelector
              classes={classes}
              fromLabel={"Activity From Date"}
              toLabel={"Activity To Date"}
              fromDate={
                fromDate ? new Date(fromDate) : subMonths(new Date(), 6)
              }
              toDate={toDate ? new Date(toDate) : addDays(new Date(), 1)}
              onChangeFromDate={(value) => {
                handleFilters(value, "fromDate", "compliance");
              }}
              onChangeToDate={(value) =>
                handleFilters(value, "toDate", "compliance")
              }
              disabled={isLoading}
              wrapInListItem={true}
            />
            <ListItem>
              <StateAutoComplete
                classes={classes}
                handleChange={handleFilters}
                reset={reset}
                setReset={setReset}
                filterType={"compliance"}
              />
            </ListItem>
            <ListItem>
              <BrandAutoComplete
                classes={classes}
                handleChange={handleFilters}
                reset={reset}
                setReset={setReset}
                filterType={"compliance"}
              />
            </ListItem>
            <ListItem>
              <ItemTypeAutoComplete
                classes={classes}
                handleChange={handleFilters}
                reset={reset}
                setReset={setReset}
                filterType={"compliance"}
              />
            </ListItem>
          </>
        )}
        <ListItem>
          <StatusSelector
            handleStatus={handleFilters}
            status={status}
            setStatus={setStatus}
            classes={classes}
            filterType={
              complianceType === "items" ? "compliance" : "complianceRules"
            }
          />
        </ListItem>
        <ListItem>
          <RuleTypeSelector
            classes={classes}
            handleRuleType={handleFilters}
            setType={setType}
            ruleType={type}
            filterType={"compliance"}
          />
        </ListItem>
        {complianceType === "items" && (
          <>
            <ListItem>
              <ProgramAutoComplete
                classes={classes}
                handleChange={handleFilters}
                reset={reset}
                setReset={setReset}
                filterType={"compliance"}
              />
            </ListItem>
            <ListItem>
              <OrderWindowSelector
                tw="w-full"
                separatePast
                orderWindow={orderWindow}
                setOrderWindow={handleOrderWindow}
              />
            </ListItem>
          </>
        )}
        <Divider />
        <ListItem />
        <ListItem>
          <Button
            fullWidth
            className={classes.largeButton}
            variant="contained"
            color="secondary"
            disabled={isLoading}
            onClick={() => {
              dispatch(setClear());
            }}
          >
            CLEAR FILTERS
          </Button>
        </ListItem>
      </List>
    </>
  );
};

FiltersCompliance.propTypes = {
  reset: PropTypes.bool.isRequired,
  setReset: PropTypes.func.isRequired,
  handleFilters: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  bindSequenceNum: PropTypes.object.isRequired,
  handleSearch: PropTypes.func.isRequired,
};

export default FiltersCompliance;
