/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";

import { Contained, StyledButton } from "src/components/StyledComponents";
import { formatMoneyString } from "src/utility/utilityFunctions";

import { getExtendedCost } from "../helpers";
import UploadOrdersButtons from "./UploadOrdersButtons";
import { useCurrentOrderSet } from "./data/CurrentOrderSetContext";
import useDeleteAllOrdersMutation from "./data/mutations/useDeleteAllOrdersMutation";
import useDeleteOrderSetMutation from "./data/mutations/useDeleteOrderSetMutation";

const OrderSetActionBar = ({ canDelete = true }: { canDelete?: boolean }) => {
  const { hash } = useLocation();
  const orderVariantErrors: Record<string, any> = useSelector(
    (state: any) => state.orderSet.orderVariantErrors
  );
  const deleteOrderSet = useDeleteOrderSetMutation();
  const deleteAllOrders = useDeleteAllOrdersMutation();
  const { orderSet, orders } = useCurrentOrderSet();

  const orderSetExtendedTotal = orders.reduce(
    (total, order) => total + getExtendedCost(order, orderSet?.type),
    0
  );

  const hasQuantities = orders.some((order) => order.totalQuantity > 0);

  const handleRemoveAllOrders = () => {
    deleteAllOrders.mutate();
  };
  return (
    <div tw="fixed inset-0 top-auto bottom-4 z-20">
      <Contained>
        <div tw="rounded shadow-md bg-white flex justify-between items-center px-4 py-3 pl-28">
          <div tw="flex justify-end items-baseline gap-4">
            {canDelete && (
              <>
                <StyledButton
                  outlined
                  danger
                  onClick={() => deleteOrderSet.mutate()}
                >
                  Delete Order Set
                </StyledButton>
                <StyledButton outlined danger onClick={handleRemoveAllOrders}>
                  Delete All Orders
                </StyledButton>
              </>
            )}
          </div>
          <div tw="flex justify-end items-center gap-4">
            {orders.length > 0 ? (
              <h2 tw="text-neutral-600 text-lg">
                Total:{" "}
                <b tw="font-medium">
                  {formatMoneyString(orderSetExtendedTotal)}
                </b>
              </h2>
            ) : (
              <UploadOrdersButtons />
            )}
            <StyledButton
              component={Link}
              disabled={
                orderSet.status === "inactive" ||
                !hasQuantities ||
                Object.keys(orderVariantErrors).length !== 0
              }
              cta
              to={hash ? hash + "-overview" : "#overview"}
            >
              Order Overview
            </StyledButton>
          </div>
        </div>
      </Contained>
    </div>
  );
};

export default OrderSetActionBar;
