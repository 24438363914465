/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useEffect, useRef, useState } from "react";
import Helmet from "react-helmet";
import { CSVReader } from "react-papaparse";
import { useDispatch, useSelector } from "react-redux";

import GetAppIcon from "@mui/icons-material/GetApp";
import PublishIcon from "@mui/icons-material/Publish";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";

import BackButton from "src/components/Reporting/BackButton";
import { setFiltersOpen } from "src/redux/slices/filterSlice";

import { CSVLink } from "@utils/csv";

import ReportTable from "../components/Reporting/ReportTable";
import { useNumberOnlyInput } from "../hooks/InputHooks";
import { useReportCsv } from "../hooks/Reports/useReportCsv";
import { useNoFetch } from "../hooks/UtilityHooks";
import { setError } from "../redux/slices/errorSlice";
import { updateAllShippingParams } from "../redux/slices/purchasing/purchaseOrderSlice";
import {
  clearReports,
  getShipListReport,
} from "../redux/slices/reports/reportSlice";
import { mapShippingCSV } from "../utility/utilityFunctions";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
  ...theme.reports,
}));

const ReportBulkPOShipParams = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const csvRef = useRef(null);

  const {
    value: poId,
    bind: bindPoId,
    reset: resetPoId,
  } = useNumberOnlyInput("");

  const [hasGenerated, setHasGenerated] = useState(false);
  const [isUploadLoading, setUploadLoading] = useState(false);
  const [poIds, setPoIds] = useState("");

  //const currentUserRole = useSelector((state) => state.user.role);
  const isLoading = useSelector((state) => state.reports.isLoading);
  const report = useSelector((state) => state.reports.reportData);
  const reportType = useSelector((state) => state.reports.reportType);

  const { setCsvData, data, headers } = useReportCsv(
    reportType,
    hasGenerated,
    setHasGenerated,
    isLoading,
    report
  );

  const handleReset = () => {
    setPoIds("");
    dispatch(clearReports());
    setCsvData({ data: [], headers: [] });
  };

  const handleGetReport = () => {
    let formattedIds = poIds
      .split(",")
      .map((id) => id.replace(/\D/g, ""))
      .join(",");
    const filterObject = {
      poIds: formattedIds,
    };
    dispatch(getShipListReport(filterObject));
    setHasGenerated(true);
  };

  const handleOpenDialog = (evt) => {
    if (csvRef.current) {
      csvRef.current.open(evt);
    }
  };

  const handleFileUpload = (data) => {
    const mappedData = mapShippingCSV(data);
    if (mappedData.filter((data) => data.tax === "error").length > 0) {
      dispatch(
        setError({
          error: "The tax field must be a number or be left blank",
          source: "Shipping Information Upload",
        })
      );
    } else {
      dispatch(updateAllShippingParams(mappedData));
    }
    setUploadLoading(false);
  };

  const handleFileUploadError = (err, file, inputElem, reason) => {
    dispatch(
      setError({ error: err.toString(), source: "Purchase Order File Upload" })
    );
    console.log(err, file, inputElem, reason);
  };

  const handleIdSubmit = (evt) => {
    evt.preventDefault();
    if (poId.length > 0) {
      setPoIds(poIds.length > 0 ? `${poIds},${poId}` : poId);
      resetPoId();
    }
  };

  const handleChipClick = (value) => {
    let newIds = poIds
      .split(",")
      .filter((id) => id !== value)
      .join(",");
    setPoIds(newIds);
  };

  useEffect(() => {
    dispatch(setFiltersOpen({ open: false }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useNoFetch(dispatch);

  useEffect(() => {
    dispatch(clearReports());
    resetPoId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>RTA | PO Ship List Report</title>
      </Helmet>
      <Container className={classes.mainWrapper}>
        <div className={classes.titleBar}>
          <Typography className={classes.titleText}>
            PO Ship List Report
          </Typography>
          <div className={classes.configButtons}>
            <div className={classes.innerConfigDiv}>
              <div tw="mr-2">
                <BackButton />
              </div>
              <CSVReader
                ref={csvRef}
                onFileLoad={handleFileUpload}
                onError={handleFileUploadError}
                noClick
                noDrag
                config={{
                  header: true,
                  beforeFirstChunk: (_chunk) => {
                    setUploadLoading(true);
                  },
                }}
                noProgressBar
              >
                {({ file }) => (
                  <div style={{ position: "relative" }}>
                    <Button
                      className={classes.largeButton}
                      style={{
                        width: isUploadLoading ? "77.156px" : "auto",
                        marginRight: "10px",
                      }}
                      variant="contained"
                      color="secondary"
                      startIcon={<PublishIcon />}
                      onClick={handleOpenDialog}
                    >
                      {isUploadLoading ? (
                        <CircularProgress size={27.78} />
                      ) : (
                        "SHIPPING"
                      )}
                    </Button>
                    {file && (
                      <Typography
                        style={{
                          position: "absolute",
                          top: "Calc(100% + 5px)",
                        }}
                        variant="body2"
                        color="textSecondary"
                      >
                        {file.name}
                      </Typography>
                    )}
                  </div>
                )}
              </CSVReader>
              <CSVLink
                data={data}
                headers={headers}
                filename={`${reportType}.csv`}
              >
                <Button
                  className={classes.largeButton}
                  variant="contained"
                  color="secondary"
                  startIcon={<GetAppIcon />}
                  disabled={data.length === 0}
                >
                  EXPORT REPORT
                </Button>
              </CSVLink>
            </div>
          </div>
        </div>
        <br />
        <div className={classes.searchComponents}>
          <form className={classes.queryField} onSubmit={handleIdSubmit}>
            <TextField
              fullWidth
              variant="outlined"
              color="secondary"
              name="poId"
              type="text"
              label="Purchase Order Id"
              {...bindPoId}
            />
          </form>
        </div>
        <br />
        <div style={{ width: "100%", textAlign: "center" }}>
          <Typography
            className={classes.headerText}
            style={{ marginRight: "10px" }}
          >
            Selected Purchase Orders:
          </Typography>
        </div>
        <div className={classes.chipRow}>
          {poIds.length > 0 &&
            poIds
              .split(",")
              .map((id, i) => (
                <Chip
                  style={{ margin: "auto 2.5px" }}
                  color="primary"
                  key={i}
                  label={id}
                  onDelete={() => handleChipClick(id)}
                />
              ))}
        </div>
        <br />
        <div className={classes.buttonRow}>
          <Button
            className={classes.largeButton}
            variant="contained"
            color="secondary"
            onClick={() => handleGetReport()}
            style={{ marginRight: "20px" }}
            disabled={poIds.length === 0}
          >
            GENERATE REPORT
          </Button>
          <Button
            className={classes.largeButton}
            variant="contained"
            color="secondary"
            onClick={handleReset}
          >
            CLEAR FILTERS
          </Button>
        </div>
        <br />
        <br />
        {isLoading && (
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <CircularProgress size={100} style={{ marginTop: "50px" }} />
          </div>
        )}
        {!isLoading && report.length > 0 && reportType === "ship-list" && (
          <ReportTable report={report} type={reportType} />
        )}
      </Container>
    </>
  );
};

ReportBulkPOShipParams.propTypes = {};

export default ReportBulkPOShipParams;
