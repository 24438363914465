import { orderBy } from "lodash";

import { createSlice } from "@reduxjs/toolkit";

import { axiosGet, axiosPatch, axiosPost } from "../../../api/axiosCalls";
import { setError } from "../errorSlice";
import {
  setFailure as patchFailure,
  setIsLoading as patchLoading,
  patchSuccess,
} from "../patchOrderSlice";
import { buildTerritoryPatch, buildTerritoryPost } from "./helpers";
import { mapAndSortTerritories } from "./maps";

let initialState = {
  isLoading: false,
  isUpdateLoading: false,
  isStatesLoading: false,
  territoryList: [],
  filteredTerritoryList: [],
  stateList: [],
  provinceList: [],
  filteredStateList: [],
  compliantStateList: [],
  updateStatus: false,
  error: null,
};

const loadingFailed = (state, action) => {
  const { error } = action.payload;
  state.isLoading = false;
  state.isStatesLoading = false;
  state.isUpdateLoading = false;
  state.error = error;
};

const territorySlice = createSlice({
  name: "territories",
  initialState,
  reducers: {
    setIsLoading(state) {
      state.isLoading = true;
    },
    setUpdateLoading(state) {
      state.isUpdateLoading = true;
    },
    setStatesLoading(state) {
      state.isStatesLoading = true;
    },
    getAllTerritoriesSuccess(state, action) {
      const { territories } = action.payload;
      state.territoryList = territories;
      state.isLoading = false;
      state.error = null;
    },
    getStatesSuccess(state, action) {
      const { states } = action.payload;
      state.filteredStateList = states;
      state.isStatesLoading = false;
      state.error = null;
    },
    getAllStatesSuccess(state, action) {
      const { states, provinces } = action.payload;
      state.stateList = states;
      state.provinceList = provinces;
      state.isStatesLoading = false;
      state.error = null;
    },
    getCompliantStatesSuccess(state, action) {
      const { states } = action.payload;
      state.compliantStateList = states;
      state.isStatesLoading = false;
      state.error = null;
    },
    updateTerritorySuccess(state, action) {
      const { id, territory } = action.payload;
      const updatedTerritories = state.territoryList.map((terr) => {
        if (terr.id === id) {
          return territory;
        } else return { ...terr };
      });
      state.territoryList = updatedTerritories;
      state.isUpdateLoading = false;
      state.updateStatus = true;
      state.error = null;
    },
    addTerritorySuccess(state) {
      state.isUpdateLoading = false;
      state.updateStatus = true;
      state.error = null;
    },
    setUpdateSuccess(state, action) {
      const { updateStatus } = action.payload;
      state.updateStatus = updateStatus;
    },
    clearFilteredStates(state) {
      state.filteredStateList = [];
    },
    clearTerritories(state) {
      state.isLoading = false;
      state.isUpdateLoading = false;
      state.isStatesLoading = false;
      state.territoryList = [];
      state.filteredTerritoryList = [];
      state.stateList = [];
      state.provinceList = [];
      state.filteredStateList = [];
      state.compliantStateList = [];
      state.updateStatus = false;
      state.error = null;
    },
    setFailure: loadingFailed,
  },
});

export const {
  setIsLoading,
  setUpdateLoading,
  setStatesLoading,
  getAllTerritoriesSuccess,
  getStatesSuccess,
  getAllStatesSuccess,
  getCompliantStatesSuccess,
  updateTerritorySuccess,
  addTerritorySuccess,
  setUpdateSuccess,
  clearFilteredStates,
  clearTerritories,
  setFailure,
} = territorySlice.actions;

export default territorySlice.reducer;

export const fetchTerritories = () => async (dispatch) => {
  try {
    dispatch(setIsLoading());
    const response = await axiosGet("/api/territories");
    if (response.error) throw response.error;
    const mappedData = mapAndSortTerritories(response.data);
    dispatch(getAllTerritoriesSuccess({ territories: mappedData }));
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Territories" }));
  }
};

export const fetchStates = () => async (dispatch) => {
  try {
    dispatch(setStatesLoading());
    const response = await axiosGet("/api/states");
    if (response.error) throw response.error;
    const stateArray = response.data.filter((s) => s.country === "USA");
    const provinceArray = response.data.filter((s) => s.country === "CAN");
    const sortedStates = stateArray.sort((a, b) => {
      return a.code < b.code ? -1 : a.code > b.code ? 1 : 0;
    });
    const sortedProvinces = provinceArray.sort((a, b) => {
      return a.code < b.code ? -1 : a.code > b.code ? 1 : 0;
    });
    dispatch(
      getAllStatesSuccess({ states: sortedStates, provinces: sortedProvinces })
    );
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Territories" }));
  }
};

export const fetchStatesByIds = (ids) => async (dispatch) => {
  try {
    dispatch(setStatesLoading());
    if (ids.length === 1 && ids[0] === null) {
      dispatch(getStatesSuccess({ states: [] }));
    } else {
      const queryString =
        ids.length > 0
          ? `/api/states?filter[territory-id]=${ids.join(",")}`
          : "/api/states";
      const response = await axiosGet(queryString);
      if (response.error) throw response.error;
      const sortedStates = orderBy(
        response.data,
        [(state) => state.code, (state) => state.country.toLowerCase()],
        ["asc", "asc"]
      );
      dispatch(getStatesSuccess({ states: sortedStates }));
    }
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Territories" }));
  }
};

export const fetchAllCompliantStates = (id, type) => async (dispatch) => {
  try {
    dispatch(setStatesLoading());
    const typeMap = {
      order: "compliant_for_order_id",
      "order-variant": "compliant_for_order_item_id",
      "param-item": "compliant-for-shipping-parameter-id",
    };
    const response = await axiosGet(
      `/api/states?filter[${typeMap[type]}]=${id}`
    );
    if (response.error) throw response.error;
    dispatch(getCompliantStatesSuccess({ states: response.data }));
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Territories" }));
  }
};

export const updateTerritoryById = (id, data) => async (dispatch) => {
  try {
    dispatch(patchLoading());
    dispatch(setUpdateLoading());
    const patchData = buildTerritoryPatch(id, data);
    const response = await axiosPatch(`/api/territories/${id}`, patchData);
    if (response.error) throw response.error;
    const formattedTerritory = mapAndSortTerritories([response.data]);
    dispatch(
      updateTerritorySuccess({ id: id, territory: formattedTerritory[0] })
    );
    dispatch(patchSuccess());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(patchFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Territories" }));
  }
};

export const addNewTerritory = (data) => async (dispatch) => {
  try {
    dispatch(patchLoading());
    dispatch(setUpdateLoading());
    const postData = buildTerritoryPost(data);
    const newTerritoryResponse = await axiosPost("/api/territories", postData);
    if (newTerritoryResponse.error) throw newTerritoryResponse.error;
    const response = await axiosGet("/api/territories");
    if (response.error) throw response.error;
    const mappedData = mapAndSortTerritories(response.data);
    dispatch(getAllTerritoriesSuccess({ territories: mappedData }));
    dispatch(addTerritorySuccess());
    dispatch(patchSuccess());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(patchFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Territories" }));
  }
};
