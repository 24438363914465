import React from "react";

import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

const GeneralSelector = ({
  selected,
  setSelected,
  options,
  label,
  multiple,
  selectProps,
  ...props
}) => {
  const id = `selector-${label.replace(/\s/g, "-")}`;
  return (
    <FormControl variant="outlined" size="small" {...props} fullWidth>
      <InputLabel id={id}>{label}</InputLabel>
      <Select
        variant="outlined"
        labelId={id}
        value={selected}
        label={label}
        multiple={multiple}
        fullWidth
        onChange={(e) => setSelected(e.target.value)}
        {...selectProps}
      >
        {options.map(({ id, label }, i) => (
          <MenuItem key={i} value={id}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default GeneralSelector;
