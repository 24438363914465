import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import MuiAlert from "@mui/material/Alert";
import Fade from "@mui/material/Fade";
import Snackbar from "@mui/material/Snackbar";
import makeStyles from "@mui/styles/makeStyles";

/*
Utilizes the material ui Snackbar component to display loading states in the application. The component
is tied to the patchOrder slice, and will display whenver isLoading is true.
*/

const useStyles = makeStyles((theme) => ({
  ...theme.global,
  alertColor: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.secondary.dark,
  },
}));

const FadeTransition = (props) => (
  <Fade {...props} timeout={{ enter: 250, exit: 1000 }} />
);

const Alert = (props) => <MuiAlert elevation={6} variant="filled" {...props} />;

const OrderPatchLoading = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const isLoading = useSelector((state) => state.patchOrder.isLoading);
  const error = useSelector((state) => state.patchOrder.error);

  useEffect(() => {
    let timeOut;
    if (isLoading) {
      setOpen(true);
    }
    if (!isLoading) {
      timeOut = setTimeout(() => {
        setOpen(false);
      }, 1000);
    }
    return () => {
      clearTimeout(timeOut);
    };
  }, [setOpen, isLoading]);

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        open={open}
        TransitionComponent={FadeTransition}
        style={{ zIndex: "15000", marginLeft: "100px", marginBottom: "15px" }}
      >
        <div>
          {isLoading ? (
            <Alert severity="info" classes={{ filledInfo: classes.alertColor }}>
              ...Saving...
            </Alert>
          ) : !error ? (
            <Alert
              severity="success"
              classes={{ filledSuccess: classes.alertColor }}
            >
              Work is Saved!
            </Alert>
          ) : (
            <Alert severity="error">Something went wrong...</Alert>
          )}
        </div>
      </Snackbar>
    </>
  );
};

export default OrderPatchLoading;
