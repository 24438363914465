/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { TextField, Typography } from "@mui/material";

import { useInput } from "../../../hooks/InputHooks";
import {
  createNote,
  deleteNote,
  fetchNotes,
} from "../../../redux/slices/planningTool/noteSlice";
import { StyledButton } from "../../StyledComponents";
import CollapsibleComponent from "../CollapsibleComponent";

const Note = ({ note }) => {
  const dispatch = useDispatch();

  const { name } = useSelector((state) => state.user);
  const [deleteing, setDeleteing] = useState(false);

  const ownNote = name === note.userName;

  const handleDelete = (id) => {
    dispatch(deleteNote(id));
    setDeleteing(true);
  };

  return (
    <div
      css={[
        tw`flex mr-8 transition`,
        ownNote && tw`justify-end ml-8 mr-0`,
        deleteing && tw`opacity-50 pointer-events-none`,
      ]}
    >
      <div tw="py-4 px-6 max-w-screen-md flex flex-col bg-white rounded-md shadow-sm">
        <Typography tw="pr-8 whitespace-pre-line">{note.message}</Typography>
        <Typography
          component="div"
          tw="mt-4 text-xs text-neutral-500 flex items-baseline justify-between"
        >
          <div tw="flex gap-2">
            <span>{note.userName}</span>
            <span>{note.updatedAt}</span>
          </div>
          <div>
            <a
              href="#"
              onClick={() => {
                handleDelete(note.id);
              }}
              tw="transition hover:text-red-600 ml-6"
            >
              (Delete)
            </a>
          </div>
        </Typography>
      </div>
    </div>
  );
};

const NoteBoxContent = ({ notes, isUpdateLoading, error, handleNewNote }) => {
  const scrollContainerRef = useRef(null);
  const {
    value: newNote,
    bind: bindNewNote,
    setValue: setNewNote,
  } = useInput("");

  const handleSubmit = () =>
    handleNewNote(newNote, () => {
      setNewNote("");
    });

  useEffect(() => {
    // Scroll to bottom of container
    scrollContainerRef.current.scrollTop =
      scrollContainerRef.current.scrollHeight;
  }, []);

  return (
    <>
      <div
        // https://css-tricks.com/books/greatest-css-tricks/pin-scrolling-to-bottom/
        css={{
          "*": { overflowAnchor: "none" },
          "#anchor": { overflowAnchor: "auto", height: "1px" },
        }}
        tw="space-y-4 mb-8 -mx-3 p-3 max-h-56 overflow-y-auto shadow-inner"
        ref={scrollContainerRef}
      >
        {notes && notes.length > 0 ? (
          notes.map((n, i) => <Note key={`note-${i}`} note={n} />)
        ) : (
          <Typography tw="my-6 text-xl text-neutral-500!">
            No notes yet.
          </Typography>
        )}
        <div id="anchor"></div>
      </div>
      <div tw="flex items-end gap-4">
        <TextField
          label="New note"
          tw="bg-white"
          // size="small"
          multiline
          fullWidth
          disabled={isUpdateLoading}
          {...bindNewNote}
        />
        <StyledButton
          cta
          tw="flex-none"
          onClick={handleSubmit}
          loading={isUpdateLoading}
          disabled={newNote.trim().length === 0 || isUpdateLoading}
        >
          Add note
        </StyledButton>
      </div>
      {!isUpdateLoading && error && (
        <Typography tw="text-red-600">{error}</Typography>
      )}
    </>
  );
};

const NoteBox = ({ programId }) => {
  const dispatch = useDispatch();

  const notesState = useSelector((state) => state.notes);

  const { isUpdateLoading } = notesState;

  const noteCount = notesState.notes.length;

  const handleNewNote = (textContent, callBack) => {
    dispatch(createNote(programId, textContent, callBack));
  };

  useEffect(() => {
    if (!isUpdateLoading) {
      dispatch(fetchNotes(programId));
    }
  }, [programId, isUpdateLoading, dispatch]);

  return (
    <>
      <CollapsibleComponent
        title="Notes"
        subtitle={`${noteCount || "no"} note${
          noteCount.length !== 1 ? "s" : ""
        }`}
      >
        <NoteBoxContent {...notesState} handleNewNote={handleNewNote} />
      </CollapsibleComponent>
    </>
  );
};

export default NoteBox;
