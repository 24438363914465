import { createSlice } from "@reduxjs/toolkit";

import { axiosPost } from "../../../api/axiosCalls";

let initialState = {
  status: null,
  isLoading: false,
  error: null,
};

const loadingFailed = (state, action) => {
  const { error } = action.payload;
  state.isLoading = false;
  state.error = error;
};

const approveOrDenyItemSlice = createSlice({
  name: "itemApprovedOrDenied",
  initialState,
  reducers: {
    setIsLoading(state) {
      state.isLoading = true;
    },
    approveOrDenyItemSuccess(state, action) {
      const { response } = action.payload;
      state.status = response;
      state.isLoading = false;
      state.error = null;
    },
    setFailure: loadingFailed,
  },
});

export const { setIsLoading, setFailure, approveOrDenyItemSuccess } =
  approveOrDenyItemSlice.actions;

export default approveOrDenyItemSlice.reducer;

export const fetchApproveOrDenyItem =
  (token, itemStatus) => async (dispatch) => {
    try {
      dispatch(setIsLoading());
      const response = await axiosPost(
        `/api/public/triggered-rules/${token}/${
          itemStatus === "approved" ? "approve" : "deny"
        }`,
        {}
      );
      if (response.error) {
        throw response.error;
      }
      dispatch(
        approveOrDenyItemSuccess({
          response: response.status,
        })
      );
    } catch (err) {
      dispatch(setFailure({ error: err.toString() }));
    }
  };
