import { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";

import { ExitToApp } from "@mui/icons-material";
import {
  Button,
  Checkbox,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import clsx from "clsx";
import UserCanView from "src/components/Utility/UserCanView";
import { ADMIN_ROLES } from "src/constants/permissions";
import { setFiltersOpen } from "src/redux/slices/filterSlice";

import { allocationName, useItemQuery } from "@features/items";
import VariantAllocationTable from "@features/items/variantAllocations/VariantAllocationTable";
import { VariantAllocation } from "@models/VariantAllocation";

import Loading from "../components/Utility/Loading";
import SuccessModal from "../components/Utility/Modals/SuccessModal";
import VariantAllocationModal from "../features/items/variantAllocations/VariantAllocationModal";
import { useDetailedInput } from "../hooks/InputHooks";
import { useNoFetch } from "../hooks/UtilityHooks";
import { setHasUpdated } from "../redux/slices/items/itemSlice";

/*
Shelf inventory is a bit of a confusing term, but it stands for inventory allocations for Gallo. Each
variant can have any of it's inventory allocated to various territories, and this view handles creating
and editing those allocations.
*/

const useStyles = makeStyles((theme: any) => ({
  ...theme.global,
  twoWideContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  twoWide: {
    width: "48%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  formWrapper: {
    width: "70%",
    maxWidth: "1250px",
    padding: "20px",
    boxSizing: "border-box",
    textAlign: "center",
    [theme.breakpoints.down("lg")]: {
      width: "85%",
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
}));

const ShelfInventory = () => {
  const { id } = useParams();
  const classes = useStyles() as any;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showInactiveVariants, setShowInactiveVariants] = useState(false);
  const [selectedAllocation, setSelectedAllocation] = useState<
    null | "new" | VariantAllocation
  >(null);
  const [selectedVariantId, setSelectedVariantId] = useState<string | null>(
    null
  );
  const [successModal, setSuccessModal] = useState<any>({
    open: false,
    message: null,
    options: [],
  });

  const { data: item, isLoading, isFetching } = useItemQuery(id as string);

  const { value: search, bind: bindSearch } = useDetailedInput("", () => {});

  const variants = useMemo(
    () =>
      item?.variants!.filter((v) => showInactiveVariants || v.isActive) ?? [],
    [item, showInactiveVariants]
  );

  const variant = variants.find((v) => v.id === selectedVariantId);

  const searchSeqments = search.toLowerCase().split(" ").filter(Boolean);
  const variantAllocations = (variant?.variantAllocations ?? []).filter((a) => {
    const str = allocationName(a).toLowerCase();
    return searchSeqments.every((s) => str.includes(s));
  });

  const handleSuccessClose = (arg) => {
    if (arg === "continue") {
      setSuccessModal({
        open: false,
        message: null,
        options: [],
      });
      dispatch(setHasUpdated({ value: false }));
    } else {
      navigate("/admin/items");
    }
  };

  useEffect(() => {
    if (
      variants.length > 0 &&
      !variants.some((v) => v.id === selectedVariantId)
    ) {
      setSelectedVariantId(variants[0].id);
    }
  }, [variants, selectedVariantId]);

  useNoFetch(dispatch);

  useEffect(() => {
    dispatch(setFiltersOpen({ open: false }));
  }, [dispatch]);

  return (
    <>
      <Helmet>
        <title>RTA | Shelf Inventory</title>
      </Helmet>
      {successModal.open && (
        <SuccessModal {...successModal} handleClose={handleSuccessClose} />
      )}
      {selectedAllocation && variant && (
        <VariantAllocationModal
          variant={variant}
          allocation={selectedAllocation === "new" ? null : selectedAllocation}
          item={item!}
          handleClose={() => setSelectedAllocation(null)}
        />
      )}
      {(!item || item.id !== id || isFetching) && <Loading partial />}
      {item && item.id === id && !isLoading && variant && (
        <Container
          className={classes.mainWrapper}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className={classes.formWrapper}>
            <header className={classes.titleBar}>
              <Typography className={classes.headerText}>
                {`Setting Shelf Inventory for ${item.itemNumber}`}
              </Typography>
              <div className={classes.innerConfigDiv}>
                <UserCanView allow={ADMIN_ROLES}>
                  <Button
                    className={classes.largeButton}
                    variant="contained"
                    color="secondary"
                    onClick={() => setSelectedAllocation("new")}
                  >
                    NEW SHELF INVENTORY
                  </Button>
                </UserCanView>
                <Button
                  className={classes.largeButton}
                  style={{ marginLeft: "20px" }}
                  variant="contained"
                  color="secondary"
                  startIcon={
                    <ExitToApp style={{ transform: "rotate(180deg)" }} />
                  }
                  component={Link}
                  to={"/shelf-inventory-admin"}
                >
                  BACK TO ITEMS
                </Button>
              </div>
            </header>
            <br />
            <UserCanView allow={ADMIN_ROLES}>
              <Typography className={classes.bodyText} color="textSecondary">
                <em>
                  {`Clicking NEW SHELF INVENTORY will create a new shelf inventory entry for Sequence#: ${variant.variantSku}`}
                </em>
              </Typography>
            </UserCanView>
            <br />
            <Typography className={classes.bodyText}>
              <b>Item Type:</b>
              {` ${item.type}`}
            </Typography>
            <Typography className={classes.bodyText}>
              <b>Brand:</b>
              {` ${item.brands.map((b) => b.name).join(", ")}`}
            </Typography>
            <Typography className={classes.bodyText}>
              <b>Description:</b>
              {` ${item.comment}`}
            </Typography>
            <br />
            <Typography className={classes.bodyText}>
              <b>On Hand Inventory:</b>
              {` ${variant.cachedWarehouseQty}`}
            </Typography>
            <Typography className={classes.bodyText}>
              <b>Available Allocated Inventory:</b>
              {` ${variant.availableToOrderQty}`}
            </Typography>
            <Typography className={classes.bodyText}>
              <b>Pending Inventory: </b>
              {variant.cachedWarehouseQty ??
                0 - (variant.availableToOrderQty ?? 0)}
            </Typography>
            <Typography className={classes.bodyText}>
              <b>Available Unallocated Inventory:</b>
              {` ${variant.nationalAvailableToOrderQty}`}
            </Typography>
            <br />
            {item.variants!.some((v) => !v.isActive) && (
              <div className={classes.twoWideContainer}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showInactiveVariants}
                      onChange={() => setShowInactiveVariants((v) => !v)}
                    />
                  }
                  label="Show Inactive Variants"
                />
              </div>
            )}
            <div className={classes.twoWideContainer}>
              <FormControl
                size="small"
                variant="outlined"
                className={clsx(classes.twoWide, classes.settingsMargin)}
              >
                <InputLabel id="variant-label">Sequence #</InputLabel>
                <Select
                  variant="outlined"
                  label="Sequence #"
                  labelId="variant-label"
                  id="variant-select"
                  value={variant.id}
                  onChange={(evt) => setSelectedVariantId(evt.target.value)}
                >
                  {variants.map((v) => (
                    <MenuItem value={v.id} key={v.variantSku}>
                      {v.variantSku}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                size="small"
                className={classes.twoWide}
                variant="outlined"
                color="secondary"
                name="search"
                type="text"
                label="Search Territory Allocations"
                {...bindSearch}
              />
            </div>
            <br />
            {variantAllocations.length === 0 && (
              <>
                <Divider className={classes.fullWidth} />
                <br />
                <Typography className={classes.headerText}>
                  There are currently no shelf inventory entries for the
                  selected Sequence #.
                </Typography>
              </>
            )}
            {variantAllocations.length > 0 && (
              <VariantAllocationTable
                variantAllocations={variantAllocations}
                setSelectedAllocation={setSelectedAllocation}
              />
            )}
          </div>
        </Container>
      )}
    </>
  );
};

export default ShelfInventory;
