import React, { useEffect, useRef, useState } from "react";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
import Helmet from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";

import GetAppIcon from "@mui/icons-material/GetApp";
import PrintIcon from "@mui/icons-material/Print";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";

import HideFiltersButtonToggle from "src/components/Filtering/HideFiltersButtonToggle";

import { CSVLink } from "@utils/csv";

import ComplianceRulesTable from "../components/Compliance/ComplianceRulesTable";
import FilterChipList from "../components/Filtering/FilterChipList";
import { useInitialFilters } from "../hooks/UtilityHooks";
import {
  clearRulesReport,
  fetchNextRules,
  fetchRulesReport,
  setTriggerCSVFalse,
} from "../redux/slices/compliance/complianceRulesSlice";
import { setSorted, updateMultipleFilters } from "../redux/slices/filterSlice";
import { formatMoney } from "../utility/utilityFunctions";

/*
This page lets users view all current compliance rules, and acts as a reference point with no
real functionality.
*/

const defaultFilters = {
  ruleType: "all",
  status: "active",
  sortOrder: "asc",
  sortOrderBy: "ruleType",
};

const useStyles = makeStyles((theme) => ({
  ...theme.global,
}));

const ComplianceRules = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const tableRef = useRef(null);

  const [currentCSV, setCurrentCSV] = useState({ data: [], headers: [] });

  const currentUserRole = useSelector((state) => state.user.role);
  const filterObject = useSelector((state) => state.filters);
  const currentRules = useSelector((state) => state.complianceRules.rules);
  const isLoading = useSelector((state) => state.complianceRules.isLoading);
  const nextLink = useSelector((state) => state.complianceRules.nextLink);
  const isNextLoading = useSelector(
    (state) => state.complianceRules.isNextLoading
  );
  const rulesReport = useSelector((state) => state.complianceRules.rulesReport);
  const triggerCSV = useSelector(
    (state) => state.complianceRules.triggerCSVDownload
  );
  const filtersOpen = useSelector((state) => state.filters.filtersOpen);

  const handlePrint = useReactToPrint({
    content: () => tableRef.current,
  });

  const handleBottomScroll = () => {
    if (nextLink && !isNextLoading) {
      if (scrollRef.current && scrollRef.current.scrollTop !== 0) {
        dispatch(fetchNextRules(nextLink));
      }
    }
  };

  const scrollRef = useBottomScrollListener(handleBottomScroll, {
    offset: 500,
    triggerOnNoScroll: true,
    debounceOptions: {
      leading: true,
      trailing: false,
    },
  });

  const handleRulesReport = () => {
    dispatch(fetchRulesReport(filterObject));
  };

  const handleSort = (sortObject) => {
    scrollRef.current.scrollTop = 0;
    dispatch(
      updateMultipleFilters({
        filterObject: {
          sortOrder: sortObject.order,
          sortOrderBy: sortObject.orderBy,
        },
      })
    );
    dispatch(setSorted());
  };

  useEffect(() => {
    if (triggerCSV && currentCSV.data.length === 0) {
      let csvHeaders = [
        { label: "Rule Type", key: "ruleType" },
        { label: "Description", key: "ruleDesc" },
        { label: "Item Types", key: "itemTypes" },
        { label: "Item Type Code", key: "itemTypeCode" },
        { label: "Product Families", key: "productFamilies" },
        { label: "Price", key: "price" },
        { label: "State Codes", key: "stateCodes" },
        { label: "Is Active", key: "isActive" },
        { label: "Inserted At", key: "insertedAt" },
        { label: "Updated At", key: "updatedAt" },
      ];
      let csvData = [];
      rulesReport.forEach((rule) => {
        csvData.push({
          ruleType: rule.ruleType,
          ruleDesc: rule.desc,
          itemTypes: rule.itemTypes,
          itemTypeCode: rule.itemTypeCode,
          productFamilies: rule.productFamilies,
          price:
            rule.price !== "---" ? formatMoney(rule.price, false) : rule.price,
          stateCodes: rule.states,
          insertedAt: rule.insertedAt,
          updatedAt: rule.updatedAt,
          isActive: rule.isActive ? "TRUE" : "FALSE",
        });
      });
      setCurrentCSV({ data: csvData, headers: csvHeaders });
    }
  }, [rulesReport, triggerCSV, currentCSV]);

  useInitialFilters("compliance-rules", defaultFilters);

  useEffect(() => {
    dispatch(setTriggerCSVFalse());
    dispatch(clearRulesReport());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!triggerCSV && currentCSV.data.length > 0) {
      setCurrentCSV({
        data: [],
        headers: [],
      });
    }
  }, [triggerCSV, currentCSV, setCurrentCSV]);

  return (
    <>
      <Helmet>
        <title>RTA | Compliance Rules</title>
        {currentUserRole === "purchaser" && !filtersOpen && (
          <script type="text/javascript">{`Beacon('suggest', ['601438192042ff6d1b2a8ab3'])`}</script>
        )}
      </Helmet>
      <Container className={classes.mainWrapper}>
        <div className={classes.titleBar}>
          <Typography className={classes.titleText}>
            Compliance Rules
          </Typography>
          <div
            style={{
              display: "flex",
              width: "250px",
              justifyContent: "flex-end",
            }}
          >
            {!triggerCSV && currentCSV.data.length === 0 && (
              <span>
                <Button
                  className={classes.largeButton}
                  variant="contained"
                  onClick={handleRulesReport}
                  disabled={currentRules.length === 0}
                >
                  GENERATE REPORT
                </Button>
              </span>
            )}
            {triggerCSV && currentCSV.data.length > 0 && (
              <CSVLink
                data={currentCSV.data}
                headers={currentCSV.headers}
                filename="rta_compliance_rules.csv"
              >
                <Button
                  className={classes.largeButton}
                  variant="contained"
                  color="secondary"
                  startIcon={<GetAppIcon />}
                >
                  EXPORT REPORT
                </Button>
              </CSVLink>
            )}
            <Tooltip title="Print Rules">
              <IconButton onClick={handlePrint} size="large">
                <PrintIcon color="secondary" />
              </IconButton>
            </Tooltip>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignContent: "center",
            marginBottom: "10px",
          }}
        >
          <HideFiltersButtonToggle />
          <FilterChipList classes={classes} />
          <br />
        </div>
        <ComplianceRulesTable
          rules={currentRules}
          rulesLoading={isLoading}
          handleSort={handleSort}
          scrollRef={scrollRef}
          tableRef={tableRef}
        />
        {isNextLoading && (
          <div style={{ width: "100%" }}>
            <LinearProgress />
          </div>
        )}
        {!isNextLoading && <div style={{ width: "100%", height: "4px" }}></div>}
      </Container>
      <br />
    </>
  );
};

export default ComplianceRules;
