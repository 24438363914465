import { useEffect, useState } from "react";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
import Helmet from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import ViewModuleIcon from "@mui/icons-material/ViewModule";
import ViewStreamIcon from "@mui/icons-material/ViewStream";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";

import PropTypes from "prop-types";
import HideFiltersButtonToggle from "src/components/Filtering/HideFiltersButtonToggle";

import FilterChipList from "../components/Filtering/FilterChipList";
import ItemPreviewModal from "../components/ItemPreview/ItemPreviewModal";
import OrderVariantViewControl from "../components/Ordering/OrderVariantViewControl";
import { useChannelUpdate, useInitialFilters } from "../hooks/UtilityHooks";
import {
  setChips,
  setClearFreeType,
  setSorted,
  updateMultipleFilters,
} from "../redux/slices/filterSlice";
import {
  clearItemSelection,
  fetchNextFilteredItems,
} from "../redux/slices/items/itemSlice";
import { addToFavoriteItems } from "../redux/slices/users/userSlice";

/*
The item catalog allows users to view all items that are currently in the system, and get notes
on how to order those items. They can't order directly from the catalog, but when creating an order
they are presented with a similar view that is pre filtered to items that match the type of order
they are trying to place.
*/

const defaultCurrentFilters = {
  brand: [],
  itemType: [],
  bu: [],
  program: [],
  favItems: [],
  exclusiveTerritory: [],
  orderType: "",
  itemNumber: "",
  itemDesc: "",
  itemOrderType: "all",
  isItemArchived: false,
  rtaDeployment: true,
};

const defaultArchiveFilters = {
  brand: [],
  itemType: [],
  bu: [],
  program: [],
  favItems: [],
  exclusiveTerritory: [],
  orderType: "",
  itemNumber: "",
  itemDesc: "",
  itemOrderType: "all",
  isItemArchived: true,
  rtaDeployment: true,
};

const useStyles = makeStyles((theme) => ({
  ...theme.global,
}));

const ItemCatalog = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { catalogType } = useParams();

  const [currentType, setCurrentType] = useState(catalogType);
  const [currentView, setView] = useState("list");
  const [previewModal, handlePreviewModal] = useState(false);
  const [currentItem, handleCurrentItem] = useState({});

  const currentChannel = useSelector((state) => state.user.currentChannel);
  const nextLink = useSelector((state) => state.items.nextLink);
  const isNextLoading = useSelector((state) => state.items.isNextLoading);
  const filtersOpen = useSelector((state) => state.filters.filtersOpen);

  const handleBottomScroll = () => {
    if (nextLink && !isNextLoading) {
      if (scrollRef.current && scrollRef.current.scrollTop !== 0) {
        dispatch(fetchNextFilteredItems(nextLink));
      }
    }
  };

  const scrollRef = useBottomScrollListener(handleBottomScroll, {
    offset: 500,
    debounceOptions: {
      leading: true,
      trailing: false,
    },
  });
  const currentItems = useSelector((state) => state.items.items);
  const itemsLoading = useSelector((state) => state.items.isLoading);
  const currentUserRole = useSelector((state) => state.user.role);
  const currentTerritory = useSelector((state) => state.user.currentTerritory);
  const selectedItems = useSelector((state) => state.items.selectedItems);
  const favoriteItems = useSelector((state) => state.user.favoriteItems);
  const currentChannelFilter = useSelector((state) => state.filters.channel);
  const defaultFilters =
    catalogType === "all" ? defaultCurrentFilters : defaultArchiveFilters;

  defaultFilters.channel = currentChannel;
  defaultFilters.currentTerritoryId = currentTerritory;

  const handlePreview = (itemNumber) => {
    let item = currentItems.find((item) => item.itemNumber === itemNumber);
    handleCurrentItem(item);
    handlePreviewModal(true);
  };

  const handleModalClose = () => {
    handlePreviewModal(false);
  };

  const handleFavoriteItems = () => {
    const uniqueArray = [
      ...new Set(selectedItems.concat(favoriteItems.map((i) => i.id))),
    ];
    if (uniqueArray.length > 0) {
      dispatch(addToFavoriteItems(uniqueArray));
    }
    dispatch(clearItemSelection());
  };

  useEffect(() => {
    if (catalogType && currentType !== catalogType) {
      setCurrentType(catalogType);
      if (catalogType === "all") {
        dispatch(
          updateMultipleFilters({ filterObject: defaultCurrentFilters })
        );
      } else if (catalogType === "archive") {
        dispatch(
          updateMultipleFilters({ filterObject: defaultArchiveFilters })
        );
      }
      dispatch(setClearFreeType({ value: ["itemDesc", "itemNumber"] }));
      dispatch(setSorted());
      dispatch(setChips({ filterType: "item-all" }));
    }
  }, [catalogType, currentType, dispatch, setCurrentType]);

  useChannelUpdate(currentChannel, currentChannelFilter, dispatch);

  useInitialFilters(`item-${catalogType}`, defaultFilters);

  return (
    <>
      <Helmet>
        <title>RTA | Item Catalog</title>
        {currentUserRole === "purchaser" && !filtersOpen && (
          <script type="text/javascript">{` Beacon('suggest', ['601438192042ff6d1b2a8ab3'])`}</script>
        )}
        {currentUserRole === "read-only" && !filtersOpen && (
          <script type="text/javascript">{` Beacon('suggest', ['600ed315c64fe14d0e1fe351'])`}</script>
        )}
      </Helmet>
      {previewModal && currentItem && (
        <ItemPreviewModal
          type={"catalog"}
          currentItem={currentItem}
          handleClose={handleModalClose}
          previewModal={previewModal}
        />
      )}
      <Container className={classes.mainWrapper}>
        <div className={classes.titleBar}>
          <Typography className={classes.titleText} variant="h5">
            {catalogType === "all"
              ? "Item Catalog"
              : catalogType === "archive"
              ? "Item Archive"
              : "Visible Items"}
          </Typography>

          <div className={classes.innerConfigDiv}>
            {!["read-only", "compliance"].includes(currentUserRole) && (
              <Button
                className={classes.largeButton}
                style={{ marginRight: "20px" }}
                variant="contained"
                color="secondary"
                disabled={selectedItems.length === 0}
                onClick={handleFavoriteItems}
              >
                ADD TO FAVORITES
              </Button>
            )}
            <Tooltip title="View List">
              <IconButton
                onClick={() => {
                  setView("list");
                }}
                size="large"
              >
                <ViewStreamIcon
                  fontSize="large"
                  color={currentView === "list" ? "primary" : "inherit"}
                />
              </IconButton>
            </Tooltip>
            <Tooltip title="View Grid">
              <IconButton
                onClick={() => {
                  setView("grid");
                }}
                size="large"
              >
                <ViewModuleIcon
                  fontSize="large"
                  color={currentView === "grid" ? "primary" : "inherit"}
                />
              </IconButton>
            </Tooltip>
          </div>
        </div>
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignContent: "center",
              marginBottom: "10px",
            }}
          >
            <HideFiltersButtonToggle />
            <FilterChipList classes={classes} />
            <br />
          </div>
          <OrderVariantViewControl
            type={"catalog"}
            currentView={currentView}
            handlePreview={handlePreview}
            items={currentItems}
            catalogType={catalogType}
            isItemsLoading={itemsLoading}
            scrollRef={scrollRef}
          />
          {isNextLoading && (
            <div style={{ width: "100%" }}>
              <LinearProgress />
            </div>
          )}
          {!isNextLoading && (
            <div style={{ width: "100%", height: "4px" }}></div>
          )}
        </>
      </Container>
      <br />
    </>
  );
};

ItemCatalog.propTypes = {
  userType: PropTypes.string,
};

export default ItemCatalog;
