/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import { Tooltip } from "@mui/material";

import { addDays, format, formatDistanceToNowStrict } from "date-fns";

const PSTMinuteOffset = -7 * 60;
const diffFromPST =
  (-new Date().getTimezoneOffset() - PSTMinuteOffset) * 60 * 1000;

const AnaplanSyncTooltip = ({ children, lastSync }) => {
  const startOfDay = new Date();
  startOfDay.setHours(0, 0, 0, 0);
  const lastSyncTime = new Date(startOfDay.getTime() + diffFromPST);
  const nextTimeSync = addDays(lastSyncTime, 1);
  return (
    <Tooltip
      title={
        <div tw="text-sm font-normal p-2">
          <div>
            Last Anaplan Sync:{" "}
            {lastSync
              ? formatDistanceToNowStrict(lastSyncTime, {
                  addSuffix: true,
                })
              : "Never"}
          </div>
          <div>Next Sync: {format(nextTimeSync, "PPp")}</div>
        </div>
      }
      PopperProps={{
        sx: {
          "& .MuiTooltip-tooltip": tw`bg-neutral-700/90`,
        },
      }}
    >
      {children}
    </Tooltip>
  );
};

export default AnaplanSyncTooltip;
