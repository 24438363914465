const mapTerritory = (terr) => ({
  name: terr.name,
  id: terr.id,
  type: terr.type,
  code: terr["external-code"],
  billingId: terr["external-billing-id"],
  exemptFromBudgeting: terr["exempt-from-budgeting"],
  externalAnaplanId: terr["external-anaplan-id"],
  stateCodes: terr.states
    .map((s) => s.code)
    .sort((a, b) => {
      return a.toLowerCase()[0] < b.toLowerCase()[0]
        ? -1
        : a.toLowerCase()[0] > b.toLowerCase()[0]
        ? 1
        : 0;
    })
    .join(", "),
  states: terr.states,
  status: terr.status,
});

export const mapAndSortTerritories = (terrArray, role) => {
  const allRegionRoles = [
    "super",
    "purchaser",
    "select-purchaser",
    "read-only",
    "compliance",
  ];
  const allRegions = {
    name: "All Regions",
    id: null,
    type: "Regional",
    code: "ALR",
    states: [],
  };
  if (terrArray.length === 0) {
    return [];
  } else if (terrArray.length === 1) {
    if (allRegionRoles.includes(role)) {
      return [allRegions, mapTerritory(terrArray[0])];
    } else {
      return [mapTerritory(terrArray[0])];
    }
  } else {
    let regional = [];
    let customer = [];
    terrArray.forEach((terr) => {
      if (terr.type === "Regional") {
        regional.push(terr);
      } else {
        customer.push(terr);
      }
    });
    regional = regional.map(mapTerritory).sort((a, b) => {
      return a.name[0].toLowerCase() < b.name[0].toLowerCase()
        ? -1
        : a.name[0].toLowerCase() > b.name[0].toLowerCase()
        ? 1
        : 0;
    });
    customer = customer.map(mapTerritory).sort((a, b) => {
      return a.name[0].toLowerCase() < b.name[0].toLowerCase()
        ? -1
        : a.name[0].toLowerCase() > b.name[0].toLowerCase()
        ? 1
        : 0;
    });
    if (allRegionRoles.includes(role)) {
      return [allRegions].concat(regional).concat(customer);
    } else {
      return regional.concat(customer);
    }
  }
};
