import React from "react";
import { BottomScrollListener } from "react-bottom-scroll-listener";
import { useDispatch, useSelector } from "react-redux";

import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Checkbox from "@mui/material/Checkbox";
import Container from "@mui/material/Container";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";

import PropTypes from "prop-types";

import { fetchNextFilteredItems } from "../../redux/slices/items/itemSlice";
import { updateSelection } from "../../redux/slices/ordering/currentOrderSlice";
import { formatMoney } from "../../utility/utilityFunctions";
import ImageWrapper from "../Utility/ImageWrapper";
import Loading from "../Utility/Loading";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
  ...theme.itemGrid,
}));

const OrderVariantGridView = (props) => {
  const {
    type,
    currentItems,
    handlePreview,
    setCurrentItemAdded,
    isItemsLoading,
  } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const formattedType = `selected${type[0].toUpperCase() + type.slice(1)}Items`;

  const selectedItems = useSelector(
    (state) => state.currentOrder[formattedType]
  );
  const currentOrderVariants = useSelector(
    (state) => state.currentOrder[`${type}OrderVariants`]
  );
  const nextLink = useSelector((state) => state.items.nextLink);
  const isNextLoading = useSelector((state) => state.items.isNextLoading);
  const currentUserRole = useSelector((state) => state.user.role);
  const currentUserTerritory = useSelector(
    (state) => state.user.currentTerritory
  );

  const handleBottomScroll = () => {
    if (nextLink && !isNextLoading) {
      dispatch(fetchNextFilteredItems(nextLink));
    }
  };

  const handleClick = (_event, id) => {
    const selectedIndex = selectedItems.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedItems, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedItems.slice(1));
    } else if (selectedIndex === selectedItems.length - 1) {
      newSelected = newSelected.concat(selectedItems.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedItems.slice(0, selectedIndex),
        selectedItems.slice(selectedIndex + 1)
      );
    }

    dispatch(
      updateSelection({ type: formattedType, selectedItems: newSelected })
    );
  };

  const isSelected = (id) => selectedItems.indexOf(id) !== -1;

  const handleVariantAllocations = (variants) => {
    let variantAllocations = variants.reduce(
      (a, b) => a.concat(b.variantAllocations),
      []
    );
    let currentTerritories = variantAllocations.filter(
      (t) => t.territoryId === currentUserTerritory
    );
    if (currentTerritories.length > 0) {
      return currentTerritories.reduce((a, b) => a + b.qty, 0);
    } else return "---";
  };

  const handleCheckBoxDisable = (row) => {
    let currentVariantIds = currentOrderVariants.map((v) => v.variantId);
    if (
      row.variants.map((v) => v.id).every((v) => currentVariantIds.includes(v))
    ) {
      return "In Cart";
    } else if (type === "inventory" && !row.warehouse) {
      return "Unavailable";
    } else if (type === "inventory" && row.stock <= 0) {
      let terrQty = row.variants
        ? handleVariantAllocations(row.variants)
        : null;
      if (terrQty && terrQty !== "---" && terrQty > 0) {
        return null;
      } else if (row.poInMarketDate) {
        return `Out of Stock, Avail. ${row.poInMarketDate}`;
      } else {
        return "Out of Stock";
      }
    } else {
      return null;
    }
  };

  if (isItemsLoading) {
    return <Loading />;
  }

  return (
    <BottomScrollListener
      onBottom={handleBottomScroll}
      offset={100}
      debounce={0}
    >
      {(gridRef) => (
        <Container className={classes.mainWrapper}>
          <br />
          <Grid
            container
            spacing={5}
            className={classes.itemGridContainer}
            style={{
              height: "Calc(100% + 50px)",
              maxHeight: "Calc(100vh - 250px)",
              overflowY: "auto",
              overflowX: "hidden",
            }}
            ref={gridRef}
          >
            {currentItems.length === 0 && (
              <Typography className={classes.headerText}>
                {`There are no items that match the current search criteria..`}
              </Typography>
            )}
            {currentItems.length > 0 &&
              currentItems.map((item, index) => {
                const isItemSelected = !["read-only", "compliance"].includes(
                  currentUserRole
                )
                  ? isSelected(item.id)
                  : null;
                const labelId = `item-Checkbox-${index}`;

                return (
                  <Grid
                    className={classes.singleItem}
                    item
                    lg={4}
                    md={6}
                    sm={12}
                    xs={12}
                    key={item.id}
                  >
                    <Paper className={classes.paperWrapper}>
                      <div className={classes.singleItemWrapper}>
                        {!["read-only", "compliance"].includes(
                          currentUserRole
                        ) && (
                          <>
                            <Checkbox
                              className={classes.checkbox}
                              checked={isItemSelected}
                              inputProps={{ "aria-labelledby": labelId }}
                              onClick={(event) => event.stopPropagation()}
                              disabled={Boolean(handleCheckBoxDisable(item))}
                              onChange={(event) => {
                                handleClick(event, item.id);
                                event.stopPropagation();
                              }}
                            />
                            <FormHelperText>
                              {handleCheckBoxDisable(item)}
                            </FormHelperText>
                          </>
                        )}
                        <ImageWrapper
                          id={item.itemNumber}
                          imgClass={classes.previewImg}
                          alt={item.itemType}
                          imgUrl={item.imgUrlThumb}
                          handleClick={() => {
                            handlePreview(item.itemNumber);
                            setCurrentItemAdded(null);
                          }}
                        />
                      </div>
                      <br />
                      {item.brand.length > 1 ? (
                        <Tooltip
                          placement="left"
                          title={`${item.brand.join(", ")}`}
                        >
                          <span>
                            <Typography
                              className={classes.headerText}
                            >{`${item.brand[0]}`}</Typography>
                            <MoreHorizIcon
                              fontSize="small"
                              color="inherit"
                              style={{ float: "right" }}
                            />
                          </span>
                        </Tooltip>
                      ) : (
                        <Typography
                          className={classes.headerText}
                        >{`${item.brand[0]}`}</Typography>
                      )}
                      <br />
                      <Typography
                        className={classes.headerText}
                      >{`${item.itemType}`}</Typography>
                      {item.program.length > 1 ? (
                        <Tooltip
                          placement="left"
                          title={`${item.program.join(", ")}`}
                        >
                          <span>
                            <Typography>{item.program[0]}</Typography>
                            <MoreHorizIcon
                              fontSize="small"
                              color="inherit"
                              style={{ float: "right" }}
                            />
                          </span>
                        </Tooltip>
                      ) : (
                        <Typography>{item.program[0]}</Typography>
                      )}
                      <Typography variant="body1" color="textSecondary">
                        {`#${item.itemNumber}`}
                      </Typography>
                      <Typography variant="body1" color="textSecondary">
                        {`Pack Size: ${item.packSize}`}
                      </Typography>
                      {type === "inventory" && (
                        <>
                          <Typography variant="body1" color="textSecondary">
                            {`On Hand Nat.: ${
                              !item.warehouse
                                ? "Currently Unavailable"
                                : item.stock <= 0
                                ? "Out of Stock"
                                : item.stock
                            }`}
                          </Typography>
                        </>
                      )}
                      <Typography className={classes.headerText}>
                        {`${formatMoney(item.estCost, false)}`}
                      </Typography>
                      <br />
                    </Paper>
                  </Grid>
                );
              })}
          </Grid>
        </Container>
      )}
    </BottomScrollListener>
  );
};

OrderVariantGridView.propTypes = {
  type: PropTypes.string.isRequired,
  currentItems: PropTypes.array.isRequired,
  handlePreview: PropTypes.func.isRequired,
  setCurrentItemAdded: PropTypes.func.isRequired,
};

export default OrderVariantGridView;
