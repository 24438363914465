/** @jsxImportSource @emotion/react */
import "twin.macro";

import { Territory } from "@models/Territory";

import { SmartTable } from "../SmartTable";
import { PaginatedResourceProps } from "../SmartTable/SmartTable";

const TerritoryTable = ({
  handleTerritoryClick,
  territories,
  ...tableProps
}: PaginatedResourceProps & {
  territories: Territory[];
  handleTerritoryClick: (territory: Territory) => void;
}) => {
  return (
    <SmartTable
      tw="h-[calc(100vh-200px)]"
      rows={territories}
      onRowClick={handleTerritoryClick}
      columns={[
        {
          id: "externalCode",
          label: "Code",
        },
        {
          id: "name",
          label: "Name",
          render: (name) => <span tw="text-base font-medium">{name}</span>,
        },
        {
          id: "type",
          label: "Type",
          render: (type) => (type === "Customer" ? "Key Account" : type),
        },
        {
          id: "externalAnaplanId",
          label: "Anaplan ID",
        },
        {
          id: "externalBillingId",
          label: "Billing Id",
        },
        {
          id: "states",
          label: "States",
          render: (states) => states.map((s) => s.code).join(", "),
        },
      ]}
      {...tableProps}
    />
  );
};

export default TerritoryTable;
