/** @jsxImportSource @emotion/react */
import "twin.macro";

import { Close } from "@mui/icons-material";
import { Collapse, IconButton, Typography } from "@mui/material";

import { StyledButton } from "../../StyledComponents";

const NewWrapper = ({ name, open, setOpen, children }) => {
  return (
    <>
      <div tw="flex justify-between items-baseline mb-4 mt-8">
        <span tw="text-xl text-slate-700">{name}s</span>
        {!open && (
          <StyledButton cta onClick={() => setOpen(true)}>
            Create {name}
          </StyledButton>
        )}
      </div>

      <Collapse in={open}>
        <div tw="py-4 px-6 pb-6 mb-4 bg-white border-4 border-solid border-slate-300 rounded">
          <div tw="flex items-center justify-between  mb-6">
            <Typography variant="h6" tw="text-slate-700">
              Create new {name}
            </Typography>
            <IconButton onClick={() => setOpen(false)} size="large">
              <Close />
            </IconButton>
          </div>
          {children}
        </div>
      </Collapse>
    </>
  );
};
export default NewWrapper;
