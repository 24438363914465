/** @jsxImportSource @emotion/react */
import "twin.macro";

import { Skeleton } from "@mui/material";

import { Contained } from "src/components/StyledComponents";

const OrderSetSkeleton = () => {
  return (
    <Contained tw="space-y-6 my-12">
      {/* <div tw="flex justify-between items-center">
        <Skeleton variant="text" tw="w-64 h-16" />
        <Skeleton variant="text" tw="w-48 h-16" />
      </div> */}
      <div tw="mx-auto grid grid-cols-4 grid-rows-[120px 160px repeat(4, 40px)] gap-6">
        {Array(25)
          .fill(null)
          .map((_, i) => (
            <Skeleton
              key={`skeleton-${i}`}
              variant="rectangular"
              tw="rounded-lg h-full"
            />
          ))}
      </div>
    </Contained>
  );
};

export default OrderSetSkeleton;
