import { createSlice } from "@reduxjs/toolkit";

import { axiosGet, axiosPost } from "../../../api/axiosCalls";
import { setError } from "../errorSlice";
import { startGlobalLoad, stopGlobalLoad } from "../globalLoadSlice";
import { buildOrderSet, buildOrderSetVariantPost } from "./helpers";
import { mapOrderSet } from "./maps";

let initialState = {
  isLoading: false,
  orderUpdateLoading: false,
  inventoryOrderNumber: null,
  inventoryOrderVariants: [],
  selectedInventoryItems: [],
  inventoryOrderTerritory: null,
  inventoryOrderIsAllRegions: false,
  inventoryChannel: null,
  onDemandOrderNumber: null,
  selectedOnDemandItems: [],
  onDemandOrderVariants: [],
  onDemandOrderTerritory: null,
  onDemandOrderIsAllRegions: false,
  onDemandChannel: null,
  onDemandProgram: null,
  userId: null,
  userName: null,
  orderId: null,
  type: null,
  status: null,
  orderDate: null,
  totalQty: 0,
  totalCost: 0,
  error: null,
};

const loadingFailed = (state, action) => {
  const { error } = action.payload;
  state.isLoading = false;
  state.orderUpdateLoading = false;
  state.error = error;
};

const currentOrderSlice = createSlice({
  name: "currentOrder",
  initialState,
  reducers: {
    setIsLoading(state) {
      state.isLoading = true;
    },
    setUpdateLoading(state) {
      state.orderUpdateLoading = true;
    },
    createNewOrderSuccess(state, action) {
      const { type, orderId, variant, territoryId, channel } = action.payload;
      state[`${type}OrderNumber`] = orderId;
      state[`${type}OrderVariants`] = [{ variant }];
      state[`${type}OrderTerritory`] = territoryId;
      state[`${type}Channel`] = channel;
      state.orderId = orderId;
      state.isLoading = false;
      state.orderUpdateLoading = false;
      state.error = null;
    },
    getCurrentOrderSuccess(state, action) {
      const { order, variantReference, fromCreate } = action.payload;
      if (order.type === "Inventory") {
        state.inventoryOrderNumber = order.id;
        state.inventoryOrderVariants = variantReference;
        state.inventoryOrderTerritory = order.territoryId;
        state.inventoryOrderIsAllRegions = order.isAllRegions;
        state.inventoryChannel = order.channel;
      } else if (order.type === "On Demand") {
        state.onDemandOrderNumber = order.id;
        state.onDemandOrderVariants = variantReference;
        state.onDemandOrderTerritory = order.territoryId;
        state.onDemandOrderIsAllRegions = order.isAllRegions;
        state.onDemandChannel = order.channel;
        state.onDemandProgram = order.program;
      }
      state.isLoading = false;
      state.orderUpdateLoading = false;
      state.userId = order.userId;
      state.userName = order.userName;
      state.orderId = order.id;
      state.type = order.type;
      state.status = order.status;
      state.orderDate = order.orderDate;
      state.totalQty = order.totalQty;
      state.totalCost = order.totalEstCost;
      state.error = null;
      state.anaplanPrograms = order.anaplanPrograms;
      state.anaplanProgramsLabel = order.anaplanProgramsLabel;
      state.programId = order.program?.id;
      state.source = fromCreate ? "create" : "fetch";
    },
    addNewVariant(state, action) {
      const { variant, type } = action.payload;
      let variants;
      if (type === "inventory") {
        variants = [...state.inventoryOrderVariants];
      } else if (type === "onDemand") {
        variants = [...state.onDemandOrderVariants];
      }
      variants.push(variant);
      state[`${type}OrderVariants`] = variants;
      state.isLoading = false;
      state.orderUpdateLoading = false;
      state.error = null;
    },
    addBulkVariants(state, action) {
      const { variantArray, type } = action.payload;
      let variants;
      if (type === "inventory") {
        variants = [...state.inventoryOrderVariants];
      } else if (type === "onDemand") {
        variants = [...state.onDemandOrderVariants];
      }
      variants = variants.concat(variantArray);
      state[`${type}OrderVariants`] = variants;
      state.isLoading = false;
      state.orderUpdateLoading = false;
      state.error = null;
    },
    updateSelection(state, action) {
      const { type, selectedItems } = action.payload;
      state[type] = selectedItems;
    },
    updateOnDemandOrderProgramSuccess(state, action) {
      const { program } = action.payload;
      state.onDemandProgram = program;
      state.programId = program?.id;
    },
    clearItemSelections(state) {
      state.selectedInventoryItems = [];
      state.selectedOnDemandItems = [];
    },
    clearCurrentOrder(state) {
      state.isLoading = false;
      state.orderUpdateLoading = false;
      state.userId = null;
      state.userName = null;
      state.orderNumber = null;
      state.type = null;
      state.status = null;
      state.totalQty = 0;
      state.totalCost = 0;
      state.error = null;
      state.programId = null;
    },
    clearOrderByType(state, action) {
      const { type } = action.payload;
      if (type === "inventory") {
        state.inventoryOrderNumber = null;
        state.inventoryOrderVariants = [];
        state.inventoryOrderTerritory = null;
        state.inventoryOrderIsAllRegions = false;
        state.inventoryChannel = null;
      } else if (type === "onDemand") {
        state.onDemandOrderNumber = null;
        state.onDemandOrderVariants = [];
        state.onDemandOrderTerritory = null;
        state.onDemandOrderIsAllRegions = false;
        state.onDemandChannel = null;
        state.onDemandProgram = null;
      }
    },
    updateSuccess(state) {
      state.isLoading = false;
      state.orderUpdateLoading = false;
      state.error = null;
    },
    setFailure: loadingFailed,
  },
});

export const {
  setIsLoading,
  setUpdateLoading,
  createNewOrderSuccess,
  createNewBulkOrderSuccess,
  getCurrentOrderSuccess,
  addNewVariant,
  addBulkVariants,
  updateSelection,
  updateOnDemandOrderProgramSuccess,
  clearItemSelections,
  clearCurrentOrder,
  clearOrderByType,
  updateSuccess,
  setFailure,
} = currentOrderSlice.actions;

export default currentOrderSlice.reducer;

export const createNewBulkVariantOrder =
  (type, variantArray, territoryId, channel, programId) => async (dispatch) => {
    try {
      dispatch(setUpdateLoading());
      const osPostData = buildOrderSet(type, territoryId, channel, programId);
      const response = await axiosPost("/api/order-sets", osPostData);
      if (response.error) throw response.error;
      if (type === "preOrder") {
        let startResponse = await axiosPost(
          `/api/order-sets/${response.data.id}/start`,
          {}
        );
        if (startResponse.error) throw startResponse.error;
      }
      let currentVariants = variantArray.length > 0 ? [...variantArray] : [];
      let orderVariants = [];
      let orderErrors = [];
      await Promise.all(
        currentVariants.map(async (id) => {
          let variantPostData = buildOrderSetVariantPost(response.data.id, id);
          let variantResponse = await axiosPost(
            "/api/order-set-variants",
            variantPostData
          );
          if (variantResponse.error) {
            throw variantResponse.error;
          } else {
            orderVariants.push({
              id: variantResponse.data.id,
              variantId: variantResponse.data.variant.id,
              itemNumber: variantResponse.data["item-number"],
            });
          }
        })
      );
      if (orderVariants.length > 0 && type !== "preOrder") {
        dispatch(
          getCurrentOrderSuccess({
            order: mapOrderSet(response.data),
            variantReference: orderVariants,
            fromCreate: true,
          })
        );
      }

      if (orderErrors.length > 0) {
        throw orderErrors.join(", ");
      }
    } catch (err) {
      console.error(err);
      dispatch(setFailure({ error: err.toString() }));
      dispatch(setError({ error: err.toString(), source: "Current Orders" }));
    }
  };

export const fetchCurrentOrderByType =
  (type, userId, channel, territoryId) => async (dispatch) => {
    try {
      dispatch(setIsLoading());
      dispatch(startGlobalLoad());
      const formattedType =
        type === "inventory" ? "from-inventory" : "on-demand";
      const queryString = territoryId
        ? `/api/order-sets?filter[user-ids]=${userId}&filter[type]=${formattedType}&filter[status]=in-progress,inactive&filter[channel]=${channel}&filter[territory-ids]=${territoryId}&filter[all-regions]=false`
        : `/api/order-sets?filter[user-ids]=${userId}&filter[type]=${formattedType}&filter[status]=in-progress,inactive&filter[channel]=${channel}&filter[all-regions]=true`;
      const response = await axiosGet(queryString);
      if (response.error) throw response.error;
      let formattedOrder;
      let variantReferenceArray;
      if (response.data.length === 0) {
        formattedOrder = {
          userId: null,
          userName: null,
          id: null,
          type: null,
          status: null,
          orderDate: null,
          totalQty: 0,
          totalEstCost: 0,
        };
        variantReferenceArray = [];
      } else {
        formattedOrder = mapOrderSet(response.data[0]);
        variantReferenceArray = response.data[0]["order-set-variants"].map(
          (v) => ({
            id: v.id,
            variantId: v.variant.id,
            itemNumber: v["item-number"],
          })
        );
      }
      dispatch(
        getCurrentOrderSuccess({
          order: formattedOrder,
          variantReference: variantReferenceArray,
          fromCreate: false,
        })
      );
      dispatch(stopGlobalLoad());
    } catch (err) {
      dispatch(setFailure({ error: err.toString() }));
      dispatch(setError({ error: err.toString(), source: "Current Orders" }));
      dispatch(stopGlobalLoad());
    }
  };

export const addNewOrderVariant =
  (orderId, itemId, type) => async (dispatch) => {
    try {
      dispatch(setUpdateLoading());
      if (!orderId) {
        throw new Error(
          "No order present, please refresh your browser and try again."
        );
      }
      const postData = buildOrderSetVariantPost(orderId, itemId);
      const response = await axiosPost("/api/order-set-variants", postData);
      if (response.error) throw response.error;
      dispatch(
        addNewVariant({
          variant: {
            id: response.data.id,
            variantId: response.data.variant.id,
            itemNumber: response.data["item-number"],
          },
          type: type,
        })
      );
    } catch (err) {
      dispatch(setFailure({ error: err.toString() }));
      dispatch(setError({ error: err.toString(), source: "Current Orders" }));
    }
  };

export const addBulkOrderVariants =
  (orderId, variantArray, type) => async (dispatch) => {
    try {
      dispatch(setUpdateLoading());
      if (!orderId) {
        throw new Error(
          "No order present, please refresh your browser and try again."
        );
      }
      let orderVariants = [];
      let orderErrors = [];
      await Promise.all(
        variantArray.map(async (id) => {
          let postData = buildOrderSetVariantPost(orderId, id);
          let response = await axiosPost("/api/order-set-variants", postData);
          if (response.error) {
            orderErrors.push(response.error);
          } else {
            orderVariants.push({
              id: response.data.id,
              variantId: response.data.variant.id,
              itemNumber: response.data["item-number"],
            });
          }
        })
      );
      if (orderVariants.length > 0) {
        dispatch(addBulkVariants({ variantArray: orderVariants, type: type }));
      }
      if (orderErrors.length > 0) {
        throw orderErrors.join(", ");
      }
    } catch (err) {
      dispatch(setFailure({ error: err.toString() }));
      dispatch(setError({ error: err.toString(), source: "Current Orders" }));
    }
  };
