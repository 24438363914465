/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useState } from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";

import { CircularProgress, Typography } from "@mui/material";

import { Image } from "cloudinary-react";
import _ from "lodash";

import { useProgramInfo } from "../../../hooks/planningToolHooks";
import { setError } from "../../../redux/slices/errorSlice";
import {
  createItemProgram,
  updateItem,
} from "../../../redux/slices/planningTool/itemProgramSlice";
import { StyledButton } from "../../StyledComponents";
import AutocompleteItem from "../RFQ/RfqModal/AutocompleteItem";
import ItemForm from "./ItemForm";
import NewWrapper from "./NewWrapper";
import { decoupleOrderType, fetchItem } from "./helpers";
import { formDataFromItem, rerunDisabledFields } from "./helpers";

const RerunNew = () => {
  const dispatch = useDispatch();
  const {
    requiresOCM,
    orderCalendarMonthId,
    programId,
    becomesOnDemand: programBecomesOnDemand,
    isInventory: programisInventory,
    orderType: programOrderType,
    brandAssignmentLevel,
  } = useProgramInfo();
  const itemIdState = useState(null);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [itemInfo, setItemInfo] = useState({});
  const [itemId, setItemId] = itemIdState;

  const handleFetchItem = async () => {
    setLoading(true);
    try {
      const res = await fetchItem(itemId);
      const brands = brandAssignmentLevel === "item" ? res.itemBrands : [];
      setItemInfo({
        ...res,
        brands,
        orderCalendarMonthId,
        estimatedCost: res.mostRecentEstimatedCost,
        moq: res.mostRecentMoq,
        becomesOnDemand: programBecomesOnDemand || res.becomesOnDemand,
        isInventory: programisInventory || res.isInventory,
      });
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const createRerun = async ({
    orderCalendarMonthId,
    moq,
    estimatedCost,
    orderType,
    brands,
    ...formData
  }) => {
    if (programId) {
      try {
        setLoading(true);
        await updateItem(itemId, _.omit(formData, rerunDisabledFields));
        const ocmOrderType = decoupleOrderType(
          { orderCalendarMonthId, orderType },
          programOrderType
        );
        dispatch(
          createItemProgram(
            { programId, itemId, moq, estimatedCost, brands, ...ocmOrderType },
            () => {
              setOpen(false);
            }
          )
        );
      } catch (error) {
        setLoading(false);
        dispatch(setError({ error, source: "Create re-run" }));
      }
    }
  };

  const formAttrs = useForm({
    mode: "onChange",
    defaultValues: formDataFromItem({}),
  });
  const { reset, control, trigger, handleSubmit } = formAttrs;

  const controlled = (name, rules) => ({
    name,
    control,
    rules,
    disabled: rerunDisabledFields.includes(name),
  });

  useEffect(() => {
    if (Object.keys(itemInfo).length) {
      reset(formDataFromItem(itemInfo));

      if (requiresOCM) {
        trigger("orderCalendarMonthId");
      }
      trigger("removeFromCatalogDate");
    }
  }, [itemInfo, requiresOCM, reset, trigger]);

  useEffect(() => {
    if (!open) {
      setItemInfo({});
      setItemId(null);
      setLoading(false);
    }
  }, [open, setItemId]);

  return (
    <NewWrapper name="Re-run" open={open} setOpen={setOpen}>
      <div tw="flex flex-col gap-y-4">
        <div tw="text-sm text-gray-500">
          Search for an item's sequence number
        </div>
        <div tw="flex space-x-4">
          {open && (
            <AutocompleteItem
              tw="grow max-w-lg"
              itemIdState={itemIdState}
              programIdState={[""]}
            />
          )}
          <StyledButton outlined disabled={!itemId} onClick={handleFetchItem}>
            Pull-up item
          </StyledButton>
        </div>
        {loading && <CircularProgress tw="mt-6" />}
        {!loading && Object.keys(itemInfo).length > 0 && (
          <>
            <div tw="flex items-center">
              <Image tw="w-36 mr-2" src={itemInfo.images.imgUrlThumb} alt="" />
              <div>
                {itemInfo.programNames.length > 0 && (
                  <div tw="flex flex-wrap items-baseline gap-2 mb-2">
                    <Typography>Previously on:</Typography>
                    {itemInfo.programNames.map((name, i) => (
                      <Typography key={name + i} tw="bg-slate-100 rounded p-2">
                        {name}
                      </Typography>
                    ))}
                  </div>
                )}
              </div>
            </div>
            <ItemForm form={formAttrs} controlled={controlled} />

            <div tw="flex space-x-4">
              <StyledButton cta onClick={handleSubmit(createRerun)}>
                Add to program
              </StyledButton>
            </div>
          </>
        )}
      </div>
    </NewWrapper>
  );
};

export default RerunNew;
