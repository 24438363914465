import React, { useState } from "react";
import { Link } from "react-router-dom";

import { Divider, Grid, Paper, Typography } from "@mui/material";

import { without } from "lodash";
import PropTypes from "prop-types";

import {
  PLANNING_TOOL_ROLES,
  PLANNING_TOOL_SUPPLIER,
  RFQ_ROLES,
} from "../../constants/permissions";
import { useCurrentUserRole } from "../../hooks/permissionHooks";
import ImageWrapper from "../Utility/ImageWrapper";

const PlanningDash = ({ classes, InfoPopover }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const userRole = useCurrentUserRole();

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const planningSearchOpen = anchorEl
    ? anchorEl.id === "planning-search-parent"
    : false;
  const onDemandOrderOpen = anchorEl
    ? anchorEl.id === "on-demand-order-parent"
    : false;
  const nationalCreateOpen = anchorEl
    ? anchorEl.id === "planning-create-parent"
    : false;
  const activateRegionalCustomProgram = anchorEl
    ? anchorEl.id === "planning-activate-parent"
    : false;
  const rfqsOpen = anchorEl ? anchorEl.id === "rfqs-parent" : false;
  const reportsOpen = anchorEl ? anchorEl.id === "reports-parent" : false;

  const cardData = [
    {
      titleText: "Search Programs",
      ariaOwnsState: planningSearchOpen,
      ariaOwnsText: "planning-search",
      id: "planning-search-parent",
      link: "/planning/program/search",
      info: "Search and filter all programs.",
      icon: "https://res.cloudinary.com/brandhub/image/upload/v1610483653/prod/Icons/Item_Catalog_Current_ykkrrc.png",
      roles: PLANNING_TOOL_ROLES,
    },
    {
      titleText: "On-Demand Order",
      ariaOwnsState: onDemandOrderOpen,
      ariaOwnsText: "on-demand",
      id: "on-demand-parent",
      link: "/orders/items/onDemand",
      info: "Place orders for items that will need to be produced for your order",
      icon: "https://res.cloudinary.com/brandhub/image/upload/v1610483653/prod/Icons/Place_On_Demand_Order_zoymv2.png",
      roles: [PLANNING_TOOL_SUPPLIER],
    },
    {
      titleText: "Create National Program",
      ariaOwnsState: nationalCreateOpen,
      ariaOwnsText: "planning-create",
      id: "planning-create-parent",
      link: "/planning/program/create",
      info: "Create national programs and coupons, and view records.",
      icon: "https://res.cloudinary.com/brandhub/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1617273998/prod/Icons/SELECT_Icons_Shopper_Marketing_-_POS_el3abn.png",
      roles: without(PLANNING_TOOL_ROLES, PLANNING_TOOL_SUPPLIER, "field2"),
    },
    {
      titleText: "Activate Regional or Key Account Program",
      ariaOwnsState: activateRegionalCustomProgram,
      ariaOwnsText: "planning-activate",
      id: "planning-activate-parent",
      link: "/planning/program/activate",
      info: "Activate programs fed from anaplan",
      icon: "https://res.cloudinary.com/brandhub/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1617273998/prod/Icons/SELECT_Icons_Shopper_Marketing_-_POS_el3abn.png",
      roles: without(PLANNING_TOOL_ROLES, PLANNING_TOOL_SUPPLIER),
    },
    {
      titleText: "Search RFQs",
      ariaOwnsState: rfqsOpen,
      ariaOwnsText: "search-RFQs",
      id: "rfqs-parent",
      link: "/planning/rfqs",
      info: "Filtered quote view including product level quote details.",
      icon: "https://res.cloudinary.com/brandhub/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1617273999/prod/Icons/SELECT_Icons_Order_Management_m5zx9c.png",
      roles: RFQ_ROLES,
    },

    {
      titleText: "Reports",
      ariaOwnsState: reportsOpen,
      ariaOwnsText: "reports",
      id: "reports-parent",
      link: "/planning/reports",
      info: "Run program reports.",
      icon: "https://res.cloudinary.com/brandhub/image/upload/v1610483653/prod/Icons/Wrap_Up_Reports_hwy0en.png",
      roles: without(PLANNING_TOOL_ROLES, PLANNING_TOOL_SUPPLIER),
    },
    // {
    //   titleText: "Admin",
    //   ariaOwnsState: reportsOpen,
    //   ariaOwnsText: "reports",
    //   id: "reports-parent",
    //   link: "/settings",
    //   info: "Admin settings.",
    //   icon: "https://res.cloudinary.com/brandhub/image/upload/v1610483653/prod/Icons/Item_Rules_r2iepr.png",
    // },
    // {
    //   titleText: "Project Tracking",
    //   ariaOwnsState: reportsOpen,
    //   ariaOwnsText: "reports",
    //   id: "reports-parent",
    //   link: "/",
    //   info: "Track Projects.",
    //   icon: "https://res.cloudinary.com/brandhub/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1609786359/prod/Icons/item-catalog-archive-and-quote-history_tjl7au.png",
    // },
  ].filter(({ roles }) => roles.includes(userRole));

  return (
    <Grid
      container
      spacing={4}
      justifyContent="center"
      style={{ width: "80%" }}
    >
      <div style={{ textAlign: "center", width: "100%" }}>
        <Typography className={classes.titleText}>
          Planning Tool Dash
        </Typography>
      </div>
      {cardData.map((data, index) => (
        <Grid
          item
          lg={3}
          md={4}
          sm={6}
          xs={12}
          component={Link}
          to={data.link}
          key={index}
        >
          <div className={classes.dashboardGridItem}>
            <Paper
              className={classes.dashPaper}
              style={{ height: "auto" }}
              elevation={5}
            >
              <div
                id={data.id}
                className={classes.innerPaper}
                aria-owns={data.ariaOwnsState ? data.ariaOwnsText : undefined}
                aria-haspopup="true"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography className={classes.titleText}>
                    {data.titleText}
                  </Typography>
                </div>
                <Divider className={classes.divider} />
                <ImageWrapper
                  imgUrl={data.icon}
                  alt={data.titleText}
                  imgClass={classes.icon}
                  id={`${data.id}-image`}
                  handleClick={null}
                />
              </div>
            </Paper>
          </div>
          <InfoPopover
            id={data.id}
            info={data.info}
            classes={classes}
            open={data.ariaOwnsState}
            anchorEl={anchorEl}
            handlePopoverClose={handlePopoverClose}
          />
        </Grid>
      ))}
    </Grid>
  );
};

PlanningDash.propTypes = {
  classes: PropTypes.object.isRequired,
  InfoPopover: PropTypes.func.isRequired,
  currentMonth: PropTypes.number,
};

export default PlanningDash;
