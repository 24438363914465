/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Switch } from "@mui/material";

import axios from "axios";
import { format, subDays } from "date-fns";
import { saveAs } from "file-saver";
import SimpleReportGenerator from "src/components/Reporting/SimpleReportGenerator";
import { setError } from "src/redux/slices/errorSlice";

import { DatePicker } from "../components/Forms/DefaultInputs";

const ReportPODeliveryDate = () => {
  const dispatch = useDispatch();

  const [useInHands, setUseInHands] = useState(true);

  const [inHandsFromDate, setInHandsFromDate] = useState(
    format(subDays(new Date(), 7), "MM/dd/yyyy")
  );
  const [inHandsToDate, setInHandsToDate] = useState(
    format(new Date(), "MM/dd/yyyy")
  );
  const [submittedFromDate, setSubmittedFromDate] = useState(
    format(subDays(new Date(), 7), "MM/dd/yyyy")
  );
  const [submittedToDate, setSubmittedToDate] = useState(
    format(new Date(), "MM/dd/yyyy")
  );

  const isLoading = useSelector((state: any) => state.reports.isLoading);

  const handleGetReport = async () => {
    try {
      const dateRange = useInHands
        ? `${inHandsFromDate} - ${inHandsToDate}`
        : `${submittedFromDate} - ${submittedToDate}`;

      const filterKey = useInHands
        ? "in-hands-date-range"
        : "submitted-date-range";

      const { data } = await axios.get(
        `/api/in-market-report?filter[${filterKey}]=${dateRange}`
      );

      const blob = new Blob([data], {
        type: "text/csv;charset=utf-8",
      });
      saveAs(blob, `po_delivery_date_report.csv`);
    } catch (error: any) {
      dispatch(
        setError({
          error: error.toString(),
          source: "Generating po delivery date report",
        })
      );
    }
  };

  return (
    <SimpleReportGenerator
      reportTitle={"PO Delivery Date Report"}
      reportGetFunc={handleGetReport}
    >
      <div>
        <div tw="flex justify-center mb-4">
          <div tw="flex flex-col items-center">
            <div>Filter by:</div>
            <div tw="flex space-x-1 items-center">
              <div>In Hands</div>
              <Switch onChange={() => setUseInHands(!useInHands)} />
              <div>Submitted</div>
            </div>
          </div>
        </div>
        {useInHands ? (
          <div tw="flex">
            <DatePicker
              label="In Hands From Date"
              value={new Date(inHandsFromDate)}
              onChange={(value) =>
                setInHandsFromDate(format(value, "MM/dd/yyyy"))
              }
              disabled={isLoading}
              tw="mr-4"
            />

            <DatePicker
              label="In Hands To Date"
              value={new Date(inHandsToDate)}
              onChange={(value) =>
                setInHandsToDate(format(value, "MM/dd/yyyy"))
              }
              disabled={isLoading}
            />
          </div>
        ) : (
          <div tw="flex">
            <DatePicker
              label="Submitted From Date"
              value={new Date(submittedFromDate)}
              onChange={(value) =>
                setSubmittedFromDate(format(value, "MM/dd/yyyy"))
              }
              disabled={isLoading}
              tw="mr-4"
            />

            <DatePicker
              label="Submitted To Date"
              value={new Date(submittedToDate)}
              onChange={(value) =>
                setSubmittedToDate(format(value, "MM/dd/yyyy"))
              }
              disabled={isLoading}
            />
          </div>
        )}
      </div>
    </SimpleReportGenerator>
  );
};

export default ReportPODeliveryDate;
