/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useState } from "react";
import { useDispatch } from "react-redux";

import { updateSupplier } from "../../../../redux/slices/planningTool/itemProgramSlice";
import { StyledButton } from "../../../StyledComponents";
import SingleSupplierAutoComplete from "../../../Utility/AutoCompleteFields/SingleSupplierAutoComplete";

const FormSupplier = ({ itemProgramId, itemId, supplier, locked }) => {
  const dispatch = useDispatch();

  const [selectedSupplier, setSelectedSupplier] = useState(supplier ?? null);
  const [reset, setReset] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSetSupplier = (supplier) => {
    setSelectedSupplier(supplier);
  };

  const state = locked
    ? "locked"
    : !supplier && !selectedSupplier
    ? "empty"
    : supplier?.id !== selectedSupplier?.id
    ? "changed"
    : "saved";

  const buttonText = {
    empty: "Select supplier",
    changed: "Update",
    saved: "Saved",
    locked: "Already awarded",
  }[state];

  const handleSubmitSupplier = () => {
    if (!selectedSupplier) return;
    setLoading(true);
    dispatch(
      updateSupplier(itemProgramId, itemId, selectedSupplier.id, () => {
        setLoading(false);
      })
    );
  };

  return (
    <>
      <div tw="my-6 flex gap-4 max-w-lg">
        <SingleSupplierAutoComplete
          classes={{}}
          initialValue={selectedSupplier}
          handleChange={handleSetSupplier}
          reset={reset}
          setReset={setReset}
          disabled={locked}
        />
        <StyledButton
          cta
          tw="px-8 whitespace-nowrap"
          onClick={handleSubmitSupplier}
          loading={loading}
          disabled={state !== "changed" || loading}
        >
          {buttonText}
        </StyledButton>
      </div>
    </>
  );
};

export default FormSupplier;
