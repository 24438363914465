import { sortBy } from "lodash";
import { upCase } from "src/utility/utilityFunctions";

import { User } from "@models/User";

import { SmartTable } from "../SmartTable";
import { Column, SmartTableProps } from "../SmartTable/SmartTable";

export const userTableColumns: Column<User>[] = [
  {
    id: "id",
    label: "Id",
  },
  {
    id: "role",
    label: "Role",
    render: (_, user) => upCase(user.role),
  },
  {
    id: "name",
    label: "Name",
  },
  {
    id: "email",
    label: "Email",
  },
  {
    id: "territories",
    label: "Territories",
    render: (_, user) =>
      sortBy(user.territories, (terr) => terr.name.toLowerCase())
        .map((terr) => terr.name)
        .join(", "),
  },
];

// Creating a specific column arrangement for the report.
export let userReportColumns = [...userTableColumns];

// Insert new columns second to last
userReportColumns.splice(
  userReportColumns.length - 1,
  0,
  {
    id: "isOnPremise",
    label: "On Premise",
    render: (_, user) => (user.isOnPremise ? "Yes" : "No"),
  },
  {
    id: "isRetail",
    label: "Retail",
    render: (_, user) => (user.isOnPremise ? "Yes" : "No"),
  },
  {
    id: "states",
    label: "States",
    render: (_, user) =>
      sortBy(user.states, (state) => state.code.toLowerCase())
        .map((state) => state.code)
        .join(", "),
  }
);

type UserTableProps = {
  rows: User[];
  onRowClick: (user: User) => void;
} & Omit<SmartTableProps<User>, "rows" | "onRowClick" | "columns">;

export const UserTable: React.FC<UserTableProps> = ({
  rows,
  onRowClick,
  ...props
}) => (
  <SmartTable
    columns={userTableColumns}
    rows={rows}
    onRowClick={onRowClick}
    {...props}
  />
);
