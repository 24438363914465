import { createSlice } from "@reduxjs/toolkit";

import { axiosGetWithNext } from "../../../api/axiosCalls";
import { setError } from "../errorSlice";
import { startGlobalLoad, stopGlobalLoad } from "../globalLoadSlice";
import { buildFilteredAnaplanProgramQuery } from "./helpers";
import { mapAnaplanPrograms } from "./maps";

let initialState = {
  isLoading: false,
  isNextLoading: false,
  isSingleLoading: false,
  isUpdateLoading: false,

  programsPerPage: 50,
  totalPages: null,
  pagesLoaded: 0,
  nextLink: null,
  isItemsLoading: false,
  programList: [],
  currentPreOrderVariants: [],
  isPrograms: false,
  isActive: true,

  programs: [],
  hasSubmitted: false,
  isDraft: false,
  error: null,
};

const anaplanProgramSlice = createSlice({
  name: "anaplanProgram",
  initialState,
  reducers: {
    setIsLoading(state) {
      state.isLoading = true;
    },
    getProgramsSuccess(state, action) {
      const { programs, nextLink } = action.payload;
      state.pagesLoaded += 1;
      state.nextLink = nextLink;
      state.programs = [...programs];
      state.isNextLoading = false;
      state.isLoading = false;
      state.error = null;
    },
    setFailure: (state, action) => {
      const { error } = action.payload;
      state.isLoading = false;
      state.isItemsLoading = false;
      state.isNextLoading = false;
      state.isSingleLoading = false;
      state.isUpdateLoading = false;
      state.error = error;
    },
  },
});

export const { setIsLoading, getProgramsSuccess, setFailure } =
  anaplanProgramSlice.actions;

export default anaplanProgramSlice.reducer;

export const fetchFilteredAnaplanPrograms =
  (filterObject) => async (dispatch) => {
    try {
      dispatch(setIsLoading());
      dispatch(startGlobalLoad());
      const queryString = buildFilteredAnaplanProgramQuery(filterObject);
      const response = await axiosGetWithNext(queryString);
      if (response.error) throw response.error;
      const mappedData = mapAnaplanPrograms(response.data.data);
      dispatch(
        getProgramsSuccess({
          programs: mappedData,
          nextLink: response.data.nextLink,
        })
      );
      dispatch(stopGlobalLoad());
    } catch (err) {
      console.log(err);
      dispatch(setFailure({ error: err.toString() }));
      dispatch(
        setError({ error: err.toString(), source: "Fetch Filtered Programs" })
      );
      dispatch(stopGlobalLoad());
    }
  };
