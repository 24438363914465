import axios from "axios";

import client from "@services/api";

import { handleErrors } from "./apiFunctions";
import { axiosGet } from "./axiosCalls";

//After login, headers are updated with the current user's access token
//This call will only work if the bearer token is present in the headers
export const getUser = async () => {
  const response = await axiosGet(`/api/current-user`);
  return response;
};

export const loginUserWithAuthO = async (token, exp) => {
  const response = { status: "", error: null, data: null };
  await axios
    .post(`/auth/token`, {
      auth0_token: token,
    })
    .then((res) => {
      setAuthToken({ ...res.data, expires_at: exp });
      response.data = { ...res.data, expires_at: exp };
      response.status = "ok";
    })
    .catch((err) => {
      const error = handleErrors(err);
      console.log(error);
      response.status = "error";
      response.error = error;
    });
  return response;
};

//Removes user information from local storage on logout, and removes authenticated headers from axios
export const logoutUser = async (expired) => {
  localStorage.removeItem("brandhub-user");
  localStorage.removeItem("brandhub-role");
  delete axios.defaults.headers.common["Authorization"];
  delete client.headers["Authorization"];
  console.log("loggedout");
};

//Set's users info in local storage and updates axios headers on successful login
const setAuthToken = (token) => {
  const authToken = `Bearer ${token.access_token}`;
  localStorage.setItem("brandhub-user", JSON.stringify(token));
  axios.defaults.headers.common["Authorization"] = authToken;
  client.headers["Authorization"] = authToken;
};
