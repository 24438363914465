/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import Close from "@mui/icons-material/Close";
import { Dialog, DialogTitle, IconButton, TextField } from "@mui/material";

import { axiosGet, axiosPut } from "../../../api/axiosCalls";
import { setError } from "../../../redux/slices/errorSlice";
import { refreshItemProgram } from "../../../redux/slices/planningTool/itemProgramSlice";
import { stateNames } from "../../../utility/constants";
import { intValidation } from "../../../utility/inputHelpers";
import { CheckboxInput, TextInput } from "../../Forms/ControlledInputs";
import { StyledButton } from "../../StyledComponents";

const getStateCouponAllocations = (itemId) =>
  axiosGet(`/api/state-coupon-allocations?filter[item-id]=${itemId}`).then(
    (res) =>
      res.data.reduce((obj, s) => {
        obj[s["state-code"]] = { qty: String(s.qty), checked: true };
        return obj;
      }, {})
  );

const CouponQuantityModalContent = ({ itemId, itemProgramId, handleClose }) => {
  const dispatch = useDispatch();
  const { stateList } = useSelector((state) => state.territories);
  const [loading, setLoading] = useState(false);
  const [totalCoupon, setTotalCoupon] = useState("");
  const [states, setStates] = useState([]);
  const [savedStates, setSavedStates] = useState({});
  const notStates = "AS FM GU MH MP PR PW VI";

  const { setValue, getValues, control, reset } = useForm();

  const reg = (name, rules) => ({ name, control, rules });

  const handleChangeAll = (bool) => () => {
    states.forEach((code) => {
      setValue(`${code}.checked`, bool);
    });
  };

  const handleClear = () => {
    reset({});
    handleChangeAll(false)();
  };

  const handleDivideEvenly = () => {
    const values = getValues();
    const nChecked = states.reduce(
      (a, code) => a + Number(values[code].checked),
      0
    );
    if (!nChecked) return;
    const qtyDivided = String(Math.floor(totalCoupon / nChecked));
    states.forEach((state) => {
      values[state].checked && setValue(`${state}.qty`, String(qtyDivided));
    });
  };

  const handleSave = async () => {
    const values = getValues();

    const allocations = states
      .map((stateCode) => ({ ...values[stateCode], stateCode }))
      .filter(({ checked }) => checked)
      .map(({ stateCode, qty }) => ({
        "state-code": stateCode,
        qty: +qty || 0,
        "item-id": itemId,
      }));

    await axiosPut(
      `/api/state-coupon-allocations/${itemProgramId}/set-allocations`,
      {
        allocations,
      }
    );
  };

  const handleSaveAndClose = async () => {
    setLoading(true);
    try {
      await handleSave();
      dispatch(refreshItemProgram(itemProgramId, handleClose));
    } catch (error) {
      setLoading(false);
      console.log({ error });
      dispatch(
        setError({
          error: error.toString(),
          source: "Update state coupon allocations",
        })
      );
    }
  };

  useEffect(() => {
    // Trigger fetch
    setLoading(true);
    getStateCouponAllocations(itemId)
      .then(setSavedStates)
      .catch(
        (error) =>
          console.log(error) &&
          dispatch(
            setError({
              error: error.toString(),
              source: "Fetch state coupon allocations",
            })
          )
      )
      .finally(() => setLoading(false));
  }, [dispatch, itemId]);

  useEffect(() => {
    // apply saved values
    const list = stateList
      .map((state) => state.code)
      .filter((code) => !notStates.includes(code));
    setStates(list);

    const formData = list.reduce((obj, code) => {
      obj[code] = {
        qty: savedStates[code]?.qty || "",
        checked: !!savedStates[code],
      };
      return obj;
    }, {});

    reset(formData);
  }, [itemId, stateList, savedStates, reset]);

  return (
    <>
      <div tw="flex justify-between items-center">
        <DialogTitle>Coupon quantity</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          tw="mr-4"
          size="large"
        >
          <Close />
        </IconButton>
      </div>
      <div tw="flex justify-between items-start px-6 pb-4">
        <div tw="flex gap-4 justify-start flex-wrap items-start">
          <StyledButton outlined onClick={handleChangeAll(true)}>
            Check all
          </StyledButton>
          <StyledButton outlined onClick={handleChangeAll(false)}>
            Uncheck all
          </StyledButton>
          <StyledButton outlined onClick={handleClear}>
            Clear all fields
          </StyledButton>
        </div>
        <div tw="flex flex-col gap-4 md:flex-row md:gap-0">
          <TextField
            value={totalCoupon}
            onChange={(e) =>
              setTotalCoupon(intValidation.transform(e.target.value))
            }
            css={{
              ".MuiOutlinedInput-root": tw`md:rounded-r-none!`,
            }}
            variant="outlined"
            size="small"
            label="Total coupons"
          />
          <StyledButton
            cta
            tw="md:rounded-l-none whitespace-nowrap"
            onClick={handleDivideEvenly}
          >
            Divide evenly
          </StyledButton>
        </div>
      </div>
      <div
        css={[
          tw`p-2 max-h-[60vh] shadow-inner bg-slate-50 overflow-y-auto transition`,
          loading && tw`opacity-50 pointer-events-none`,
        ]}
      >
        <div tw="px-6 columns-1 md:columns-2 lg:columns-3 xl:columns-4 text-sm">
          {states.map((code) => (
            <div
              key={code}
              tw="flex gap-2 items-center p-1 transition duration-200 hover:bg-slate-200 rounded"
            >
              <label tw="flex items-center w-40 cursor-pointer">
                <CheckboxInput
                  tw="p-1 ml-3 mr-1"
                  {...reg(`${code}.checked`)}
                  size="small"
                  label={stateNames[code]}
                  labelProps={{
                    css: {
                      "& .MuiFormControlLabel-label": tw`text-sm whitespace-nowrap`,
                    },
                  }}
                />
              </label>
              <TextInput
                tw="mx-1 flex-1"
                css={{
                  ".MuiInputBase-input": tw`px-2 py-1 text-xs bg-white`,
                }}
                {...reg(`${code}.qty`, intValidation)}
              />
            </div>
          ))}
        </div>
      </div>
      <div tw="px-6 py-4 flex justify-end">
        <StyledButton cta onClick={handleSaveAndClose} loading={loading}>
          Save and close
        </StyledButton>
      </div>
    </>
  );
};

const CouponQuantityModal = ({ open, handleClose, ...ids }) => {
  return (
    <Dialog
      open={open}
      tw="mt-12"
      onClose={handleClose}
      fullWidth
      maxWidth="lg"
    >
      <CouponQuantityModalContent {...ids} handleClose={handleClose} />
    </Dialog>
  );
};

export default CouponQuantityModal;
