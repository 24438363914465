import { Link } from "react-router-dom";

import { ExitToApp } from "@mui/icons-material";

import { StyledButton } from "../StyledComponents";

const BackButton = ({ url = "/reports/landing" }: { url?: string }) => {
  return (
    <StyledButton
      outlined
      startIcon={<ExitToApp style={{ transform: "rotate(180deg)" }} />}
      component={Link}
      to={url}
    >
      Back
    </StyledButton>
  );
};

export default BackButton;
