import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";

import PropTypes from "prop-types";

import { SECONDARY_ORDER_WINDOWS } from "../../constants/permissions";
import useActiveOrderWindows from "../../hooks/useActiveOrderWindows";
import NestedMenuItem from "./NestedMenuItem";

const DrawerOrdersNav = ({ role, classes }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const { inventoryOrderNumber, onDemandOrderNumber, isLoading } = useSelector(
    (state) => state.currentOrder
  );

  const activeOrderWindows = useActiveOrderWindows();

  const hasActiveNational = activeOrderWindows.some(
    (ocm) => ocm.orderWindowType === "national"
  );
  const activeSecondary = activeOrderWindows.find(
    (ocm) => ocm.orderWindowType === "secondary"
  );

  const handleOpen = (evt) => {
    setAnchorEl(evt.target);
    evt.stopPropagation();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        style={{ padding: 0 }}
        onClick={(evt) => {
          handleOpen(evt);
          evt.stopPropagation();
        }}
        size="large"
      >
        <Typography variant="h5" className={classes.navigationText}>
          Order
        </Typography>
        <ExpandMoreIcon fontSize="large" className={classes.expandMoreIcon} />
      </IconButton>
      <Menu
        classes={{ paper: classes.menuBackground }}
        disableScrollLock
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        style={{
          marginTop: "10px",
          zIndex: "3000",
        }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={handleClose}
          component={Link}
          to="/programs"
          disabled={!hasActiveNational}
        >
          <ListItemText
            primaryTypographyProps={{
              className: classes.headerListItemNew,
            }}
            primary={`+ Monthly Pre Order`}
          />
        </MenuItem>
        {activeSecondary && SECONDARY_ORDER_WINDOWS.includes(role) && (
          <MenuItem
            onClick={handleClose}
            component={Link}
            to="/secondary-order-windows"
          >
            <ListItemText
              primaryTypographyProps={{
                className: classes.headerListItemNew,
              }}
              primary={`+ ${activeSecondary.orderWindowName}`}
            />
          </MenuItem>
        )}
        <MenuItem
          onClick={handleClose}
          component={Link}
          to="/orders/items/onDemand"
        >
          <ListItemText
            primaryTypographyProps={{
              className: classes.headerListItemNew,
            }}
            primary="+ New On-Demand Order"
          />
        </MenuItem>
        <MenuItem
          onClick={handleClose}
          component={Link}
          to="/orders/items/inventory"
        >
          <ListItemText
            primaryTypographyProps={{
              className: classes.headerListItemNew,
            }}
            primary="+ New Inventory Order"
          />
        </MenuItem>
        {!["read-only", "compliance"].includes(role) && [
          <Divider className={classes.divider} key="divider-orders-1" />,
          <NestedMenuItem
            key="nested-menu-orders-1"
            anchorEl={anchorEl}
            handleClose={handleClose}
            label="Draft Orders"
            loading={isLoading}
            classes={classes}
            childItems={[
              {
                link: onDemandOrderNumber
                  ? `/orders/open/${onDemandOrderNumber}`
                  : "/orders/open/onDemand", // how to set disabled={!onDemandOrderId} on this?
                primary: "On-Demand",
                disabled: !onDemandOrderNumber,
              },
              {
                link: inventoryOrderNumber
                  ? `/orders/open/${inventoryOrderNumber}`
                  : "/orders/open/inventory",
                primary: "Inventory",
                disabled: !inventoryOrderNumber,
              },
            ]}
          />,
        ]}
        {role !== "field1" && [
          <Divider className={classes.divider} key="divider-orders-2" />,
          <NestedMenuItem
            key="nested-menu-orders-2"
            anchorEl={anchorEl}
            handleClose={handleClose}
            label="Order Review / Approval"
            classes={classes}
            childItems={[
              {
                link: "/rollup",
                primary: "Order Window Rollup",
              },
              {
                link: "/orders/approvals",
                primary: "On Demand / Inventory Order",
              },
            ]}
          />,
        ]}
        {role !== "finance" && [
          <Divider className={classes.divider} key="divider-orders-3" />,
          <NestedMenuItem
            key="nested-menu-orders-3"
            anchorEl={anchorEl}
            handleClose={handleClose}
            label="Order History"
            classes={classes}
            childItems={[
              {
                link: "/orders/history/group/byOrder",
                primary: "By Order",
              },
              {
                link: "/orders/history/group/byItem",
                primary: "By Item",
              },
              {
                link: "/orders/history/c2m-order-history",
                primary: "C2M Order History",
              },
            ]}
          />,
        ]}
      </Menu>
    </>
  );
};

DrawerOrdersNav.propTypes = {
  classes: PropTypes.object.isRequired,
  inventoryOrderId: PropTypes.string,
  onDemandOrderId: PropTypes.string,
  role: PropTypes.string.isRequired,
};

export default DrawerOrdersNav;
