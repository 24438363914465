export const mapSuppliers = (suppliers) => {
  const mappedSuppliers = suppliers.map((supplier) => ({
    id: supplier.id,
    name: supplier.name,
    isPurchaserSelect: supplier["is-purchaser-select"],
    externalId: supplier["external-code"],
    goesToRFP: supplier["goes-to-rfp"] ? "Yes" : "No",
    isActive: supplier["is-active"],
    sapId: supplier["sap-id"],
    assignedUsers: supplier.users.map((user) => ({
      id: user.id,
      name: user.name,
      email: user.email,
      states: user.states,
      territories: user.territories,
      supplierId: user.supplier.id,
    })),
  }));
  return mappedSuppliers;
};
