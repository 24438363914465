import { useSelector } from "react-redux";

import { createQueryKeys } from "@lukemorales/query-key-factory";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import axios from "axios";
import _, { camelCase } from "lodash";
import { camelCaseKeys } from "src/utility/utilityFunctions";

import { Budget } from "@models/Budget";
import client, { query } from "@services/api";
import { buildPaginatedQuery, buildResourceMutations } from "@utils/reactQuery";

type OverBudgetBrand = { id: number; over_budget_count: number };

export const budgetsKeyFactory = createQueryKeys("budgets", {
  detail: (budgetId: string) => ({
    queryKey: [budgetId],
    queryFn: () =>
      client.get<Budget>(`budgets/${budgetId}`).then((res) => res.data),
  }),
  paginated: (params) => ({
    queryKey: [params],
    queryFn: () => client.get<Budget[]>("budgets", { params }),
  }),
  overBudgetBrands: (params) => ({
    queryKey: [params],
    queryFn: () =>
      axios
        .get<OverBudgetBrand[]>("/api/over-budget-brands", {
          params,
          paramsSerializer: (p) => query(p),
          timeout: Infinity,
        })
        .then((res) => res.data),
  }),
});

export const useBudgetQuery = (budgetId?: string) => {
  return useQuery({
    ...budgetsKeyFactory.detail(budgetId as string),
    enabled: !!budgetId,
  });
};

export const useOverBudgetBrandsQuery = (
  params: Record<string, any>,
  options: any
) => {
  return useQuery<OverBudgetBrand[]>({
    ...budgetsKeyFactory.overBudgetBrands(params),
    staleTime: 5 * 60 * 1000,
    ...options,
  });
};

export const useBudgetsPaginated = buildPaginatedQuery(
  budgetsKeyFactory.paginated,
  {
    placeholderData: keepPreviousData,
    staleTime: 5 * 60 * 1000,
  }
);

export const {
  useCreate: useCreateBudget,
  useUpdate,
  useDelete,
} = buildResourceMutations({
  resource: "budgets",
  detailQueryKey: (id) => budgetsKeyFactory.detail(id).queryKey,
  listQueryKey: budgetsKeyFactory.paginated._def,
});

type OrderWindowBudgetSpendResponse = {
  budgetId: string;
  orderWindowPendingAmount: string;
};

export const useOrderWindowBudgetSpendReport = (
  ocmId: string,
  brandId: string
) => {
  const { currentChannel: channel, currentTerritory: territoryId } =
    useSelector((state: any) => state.user);
  const filter = {
    orderCalendarMonthId: ocmId,
    brandId,
    channel,
    territoryId,
  };
  return useQuery({
    queryKey: ["order-window-budget-spend-report", filter],
    queryFn: () =>
      client
        .get<any[]>("order-window-budget-spend-report", {
          params: { filter },
          deserializeOutput: false,
        })
        .then(
          (res) => res.map(camelCaseKeys) as OrderWindowBudgetSpendResponse[]
        ),
    staleTime: 5 * 60 * 1000,
  });
};
