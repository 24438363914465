import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { TextField } from "@mui/material";
import { Autocomplete } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import makeStyles from "@mui/styles/makeStyles";

import { axiosGet } from "../../../../api/axiosCalls";
import { useDebounce } from "../../../../hooks/UtilityHooks";
import {
  clearProgramList,
  fetchProgramList,
} from "../../../../redux/slices/programs/programsSlice";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
}));

const AutocompleteProgram = ({ programIdState }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [programName, setProgramName] = useState("");
  const [selectedProgram, setSelectedProgram] = useState({});
  const [programId, setProgramId] = programIdState;
  const [loading, setLoading] = useState(false);
  const programList = useSelector((state) => state.programs.programList);

  const options = [selectedProgram, ...programList]
    .filter((p, i, a) => p.id && i === a.findLastIndex(({ id }) => id === p.id))
    .map((p) => ({
      id: p.id,
      label: `${p.name} | ${p.brands.map((b) => b.name).join(", ")}`,
    }));

  // Create a lookup for faster fetching
  const optionsLookup = options.reduce((obj, a) => {
    obj[a.id] = a;
    return obj;
  }, {});

  const getOpObj = (option) => {
    if (!option?.id) option = optionsLookup[option];
    return option;
  };

  const debouncedFetchProgramList = useDebounce(() => {
    dispatch(fetchProgramList(programName));
  }, 500);

  useEffect(() => {
    if (programName.length > 5) {
      setLoading(true);
      debouncedFetchProgramList();
    } else if (programList.length > 0) {
      dispatch(clearProgramList());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, programName]);

  useEffect(() => {
    setLoading(false);
  }, [programList]);

  useEffect(() => {
    let cancelled = false;
    if (programId && programId !== selectedProgram.id) {
      const fromList = programList.find(({ id }) => id === programId);
      if (fromList) {
        setSelectedProgram(fromList);
      } else {
        axiosGet(`/api/programs/${programId}`)
          .then(
            (res) =>
              !cancelled &&
              setSelectedProgram({
                id: res.data.id,
                name: res.data.name,
                brands: res.data.brands,
              })
          )
          .catch((e) => console.error(e));
      }
    }
    if (!programId) {
      setSelectedProgram({});
    }

    return () => (cancelled = true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [programId]);

  useEffect(() => {
    if (programId) {
      setProgramName(optionsLookup[programId]?.label || "");
    }
  }, [programId, selectedProgram, optionsLookup]);

  return (
    <Autocomplete
      disablePortal
      id="program-auto-complete"
      options={options}
      classes={{
        popper: classes.liftedPopper,
      }}
      loading={loading}
      onChange={(e, v) => setProgramId(v?.id || null)}
      onInputChange={(e, v) => setProgramName(v)}
      inputValue={programName}
      value={programId}
      clearOnBlur
      clearOnEscape
      // eslint-disable-next-line eqeqeq
      isOptionEqualToValue={(option, value) => option.id == value}
      getOptionLabel={(option) => getOpObj(option)?.label || ""}
      noOptionsText={
        programName.length > 5
          ? "No Programs Found"
          : "Type a program name to start searching"
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label="Program"
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default AutocompleteProgram;
