import axios from "axios";

import { createSlice } from "@reduxjs/toolkit";

import { handleErrors } from "../../api/apiFunctions";
import { setError } from "./errorSlice";

/*
The tracking slice doesn't use any of the definied axios calls because it's response is slightly
different than most other responses from our api. It works by relaying a response from ShipEngine
from our to our UI, after the API has made an authenticated request, so we are actually parsing
the raw response from ShipEngine in this call.
*/

let initialState = {
  isLoading: false,
  tracking: null,
  error: null,
};

const loadingFailed = (state, action) => {
  const { error } = action.payload;
  state.isLoading = false;
  state.tracking = null;
  state.error = error;
};

const trackingSlice = createSlice({
  name: "tracking",
  initialState,
  reducers: {
    setIsLoading(state) {
      state.isLoading = true;
    },
    getTrackingSucces(state, action) {
      const { tracking } = action.payload;
      state.tracking = tracking;
      state.isLoading = false;
      state.error = null;
    },
    setFailure: loadingFailed,
  },
});

export const { setIsLoading, getTrackingSucces, setFailure } =
  trackingSlice.actions;

export default trackingSlice.reducer;

export const getTracking = (id) => async (dispatch) => {
  try {
    dispatch(setIsLoading());
    const response = { status: "", error: null, data: null };
    await axios
      .get(`/api/order-variants/${id}/track`)
      .then((res) => {
        response.data = res.data["api_response"];
        response.status = "ok";
      })
      .catch((err) => {
        const error = handleErrors(err);
        console.log(error);
        response.status = "error";
        response.error = error;
      });
    if (response.error) {
      throw response.error;
    }
    dispatch(getTrackingSucces({ tracking: response.data }));
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Tracking" }));
  }
};
