import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { setRedirect } from "src/redux/slices/globalLoadSlice";

function useRedirect() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const redirect = useSelector((state) => state.globalLoad.redirect);

  useEffect(() => {
    if (redirect) {
      navigate(redirect);
      dispatch(setRedirect({ url: null }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [redirect]);
}

export default useRedirect;
