import React, { useState } from "react";

import PropTypes from "prop-types";

import AddItemConfirmation from "../Utility/AddItemConfirmation";
import ItemCatalogGrid from "./ItemCatalogGrid";
import ItemCatalogTable from "./ItemCatalogTable";
import OrderVariantGridView from "./OrderVariantGridView";
import OrderVariantTableView from "./OrderVariantTableView";

const OrderVariantViewControl = (props) => {
  const {
    type,
    currentView,
    handlePreview,
    items,
    catalogType,
    isItemsLoading,
    scrollRef,
    addPreOrderVariant,
    handleSort,
    isAdHoc,
  } = props;

  const [currentItemAdded, setCurrentItemAdded] = useState(null);

  return (
    <>
      {currentView === "list" && type === "catalog" && (
        <ItemCatalogTable
          currentItems={items}
          handlePreview={handlePreview}
          catalogType={catalogType}
          isItemsLoading={isItemsLoading}
          scrollRef={scrollRef}
          addPreOrderVariant={addPreOrderVariant}
          isAdHoc={isAdHoc ? true : false}
        />
      )}
      {((currentView === "grid" && type === "catalog") ||
        (currentView === "grid" && type === "new-program")) && (
        <ItemCatalogGrid
          currentItems={items}
          handlePreview={handlePreview}
          catalogType={catalogType}
          isItemsLoading={isItemsLoading}
          type={type}
          addPreOrderVariant={addPreOrderVariant}
          isAdHoc={isAdHoc ? true : false}
        />
      )}
      {currentView === "list" && type !== "catalog" && (
        <OrderVariantTableView
          type={type}
          currentItems={items}
          handlePreview={handlePreview}
          setCurrentItemAdded={setCurrentItemAdded}
          isItemsLoading={isItemsLoading}
          scrollRef={scrollRef}
          handleSort={handleSort}
        />
      )}
      {currentView === "grid" &&
        type !== "catalog" &&
        type !== "new-program" && (
          <OrderVariantGridView
            type={type}
            currentItems={items}
            handlePreview={handlePreview}
            setCurrentItemAdded={setCurrentItemAdded}
            isItemsLoading={isItemsLoading}
          />
        )}
      {type !== "program" && type !== "catalog" && type !== "new-program" && (
        <AddItemConfirmation type={type} item={currentItemAdded} />
      )}
    </>
  );
};

OrderVariantViewControl.propTypes = {
  type: PropTypes.string.isRequired,
  currentView: PropTypes.string.isRequired,
  handlePreview: PropTypes.func.isRequired,
  items: PropTypes.array,
  isItemsLoading: PropTypes.bool.isRequired,
  scrollRef: PropTypes.any,
  isAdHoc: PropTypes.bool,
};

export default React.memo(OrderVariantViewControl, (prev, next) => {
  return (
    prev.type === next.type &&
    prev.currentView === next.currentView &&
    prev.items.length === next.items.length &&
    prev.isItemsLoading === next.isItemsLoading
  );
});
