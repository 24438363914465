import { useContext } from "react";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

import { StyledButton } from "src/components/StyledComponents";

import { GlobalConfirmContext } from "./GlobalConfirmContext";

const ConfirmModal = () => {
  const { promise, setPromise, confirmContent, setConfirmContent } =
    useContext(GlobalConfirmContext);

  const handleClose = () => {
    setPromise(null);
    setConfirmContent(null);
  };

  const handleConfirm = () => {
    promise?.resolve(true);
    handleClose();
  };

  const handleCancel = () => {
    promise?.resolve(false);
    handleClose();
  };

  if (!confirmContent) return null;

  return (
    <Dialog open={promise !== null} fullWidth maxWidth="xs">
      <DialogTitle>{confirmContent.title}</DialogTitle>
      <DialogContent>
        {typeof confirmContent.message === "string" ? (
          <DialogContentText>{confirmContent.message}</DialogContentText>
        ) : (
          confirmContent.message
        )}
      </DialogContent>
      <DialogActions>
        {!confirmContent.hideCancelButton && (
          <StyledButton outlined onClick={handleCancel}>
            {confirmContent.cancelButtonText}
          </StyledButton>
        )}
        <StyledButton cta onClick={handleConfirm} autoFocus>
          {confirmContent.confirmButtonText}
        </StyledButton>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmModal;
