import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

const channels = [
  { value: "on_premise", name: "On Premise" },
  { value: "retail", name: "Retail" },
];

const ChannelSelector = ({
  classes,
  handleChange,
  selectedChannels,
  reset,
  setReset,
  filterType,
  required,
  disabled,
}) => {
  const [channel, setChannel] = useState("");
  const currentFilter = useSelector((state) => state.filters.channel);
  const isGlobalLoading = useSelector((state) => state.globalLoad.isLoading);

  useEffect(() => {
    if (currentFilter === null) {
      setChannel("");
      setReset(false);
    }
  }, [currentFilter, setReset]);

  useEffect(() => {
    if (reset) {
      setChannel("");
      setReset(false);
    }
  }, [reset, setReset]);

  return (
    <FormControl
      fullWidth
      size="small"
      className={classes.queryField}
      disabled={isGlobalLoading}
      required={required}
    >
      <InputLabel id="channel-select-label">Trade Class</InputLabel>
      <Select
        variant="outlined"
        labelId="channel-select-label"
        id="chanel-select"
        value={selectedChannels ? selectedChannels : channel}
        label="Trade Class"
        disabled={disabled}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          style: { zIndex: "16000" },
        }}
        onChange={(evt) => {
          handleChange(evt.target.value, "channel", filterType);
          setChannel(evt.target.value);
        }}
      >
        {channels.map((type, index) => (
          <MenuItem key={index} value={type.value}>
            {type.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default ChannelSelector;
