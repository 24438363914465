import React, { useEffect } from "react";
import Helmet from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { Container, Divider, Grid, Paper, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import PropTypes from "prop-types";
import { setFiltersOpen } from "src/redux/slices/filterSlice";

import ImageWrapper from "../components/Utility/ImageWrapper";
import { useNoFetch } from "../hooks/UtilityHooks";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
  ...theme.dashboard,
  paper: {
    padding: theme.spacing(1),
  },
}));

const PlanningToolReports = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const cardData = [
    {
      titleText: "Audit Report",
      link: "/reports/audit",
      iconId:
        "v1609786359/prod/Icons/item-catalog-archive-and-quote-history_tjl7au.png",
      disabled: false,
    },
    {
      titleText: "Calendar Report",
      link: "/reports/calendar",
      iconId: "v1609786359/prod/Icons/order-history-and-PO-history_lmnw4w.png",
      disabled: false,
    },
  ];
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(setFiltersOpen({ open: false }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useNoFetch(dispatch);

  return (
    <>
      <Helmet>
        <title>RTA | Planning Tool Reports</title>
      </Helmet>
      <Container
        className={classes.mainWrapper}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <br />
        <br />
        <br />
        <br />
        <Grid
          container
          spacing={6}
          justifyContent="center"
          style={{ width: "80%" }}
        >
          {cardData.map((data, index) =>
            data.disabled === false ? (
              <Grid
                item
                lg={3}
                md={4}
                sm={6}
                xs={12}
                component={Link}
                to={data.link}
                key={index}
              >
                <div className={classes.dashboardGridItem}>
                  <Paper className={classes.dashPaper} elevation={5}>
                    <div className={classes.reportInnerPaper}>
                      <div
                        style={{
                          width: "100%",
                          height: "30%",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Typography className={classes.titleText}>
                          {data.titleText}
                        </Typography>
                      </div>
                      <Divider className={classes.reportDivider} />
                      <ImageWrapper
                        imgUrl={`https://res.cloudinary.com/brandhub/image/upload/${data.iconId}`}
                        alt={data.titleText}
                        imgClass={classes.reportIcon}
                        id={`${data.id}-image`}
                        handleClick={null}
                      />
                    </div>
                  </Paper>
                </div>
              </Grid>
            ) : null
          )}
        </Grid>
      </Container>
      <br />
      <br />
    </>
  );
};

PlanningToolReports.propTypes = {};

export default PlanningToolReports;
