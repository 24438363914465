import { createSlice } from "@reduxjs/toolkit";

import { axiosPatch } from "../../../api/axiosCalls";
import { getUser, loginUserWithAuthO, logoutUser } from "../../../api/userApi";
import { setError } from "../errorSlice";
import { setRedirect } from "../globalLoadSlice";
import { mapItems } from "../items/maps";
import { mapAndSortTerritories } from "../territories/maps";
import { buildFavoriteItemsPatch } from "./helpers";

let initialState = {
  loginIsLoading: false,
  isUpdateLoading: false,
  isLoading: false,
  loggedIn: false,
  id: null,
  supplierId: null,
  firstName: null,
  lastName: null,
  name: null,
  initials: null,
  email: null,
  role: null,
  status: null,
  isOnPremise: null,
  isRetail: null,
  currentChannel: null,
  sessionExpire: null,
  timeOutSet: false,
  territories: [],
  states: [],
  currentTerritory: null,
  favoriteItems: [],
  logInError: null,
  error: null,
};

const loadingFailed = (state, action) => {
  const { error } = action.payload;
  state.isLoading = false;
  state.loginIsLoading = false;
  state.error = error;
};

const logInFailed = (state, action) => {
  const { error } = action.payload;
  state.loginIsLoading = false;
  state.logInError = error;
};

const updateFailed = (state, action) => {
  const { error } = action.payload;
  state.isUpdateLoading = false;
  state.error = error;
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setIsLoading(state) {
      state.isLoading = true;
    },
    setLoginLoading(state) {
      state.loginIsLoading = true;
    },
    setUpdateLoading(state) {
      state.isUpdateLoading = true;
    },
    setLoginSuccess(state, action) {
      const { expires } = action.payload;
      state.sessionExpire = expires;
      state.loginIsLoading = false;
      state.loggedIn = true;
      state.logInError = null;
      state.error = null;
    },
    getUserSuccess(state, action) {
      const { user } = action.payload;
      state.id = user.id;
      state.supplierId = user.supplierId;
      state.name = user.name;
      state.firstName = user.firstName;
      state.lastName = user.lastName;
      state.initials = user.initials;
      state.email = user.email;
      state.role = user.role;
      state.status = user.status;
      state.isOnPremise = user.isOnPremise;
      state.isRetail = user.isRetail;
      state.currentChannel = state.currentChannel
        ? state.currentChannel
        : user.currentChannel;
      state.territories = [...user.territories];
      state.states = user.states.length > 0 ? [...user.states] : [];
      state.currentTerritory = state.currentTerritory
        ? state.currentTerritory
        : user.currentTerritory;
      state.favoriteItems = user.favoriteItems;
      state.isLoading = false;
      state.loggedIn = true;
      state.logInError = null;
      state.error = null;
    },
    updateFavoriteItems(state, action) {
      const { items } = action.payload;
      state.favoriteItems = [...items];
      state.isUpdateLoading = false;
    },
    updateCurrentTerritory(state, action) {
      const { territory } = action.payload;
      state.currentTerritory = territory;
    },
    updateCurrentChannel(state, action) {
      const { channel } = action.payload;
      state.currentChannel = channel;
    },
    setExpires(state, action) {
      const { expires } = action.payload;
      state.sessionExpire = expires;
    },
    setTimeoutSet(state) {
      state.timeOutSet = !state.timeOutSet;
    },
    removeUser: (state) => {
      state.isLoading = false;
      state.id = "";
      state.supplierId = "";
      state.name = "";
      state.firstName = "";
      state.lastName = "";
      state.initials = "";
      state.email = "";
      state.role = "";
      state.isOnPremise = null;
      state.isRetail = null;
      state.currentChannel = null;
      state.currentTerritory = null;
      state.redirectLink = null;
      state.sessionExpire = null;
      state.timeOutSet = false;
      state.territories = [];
      state.states = [];
      state.favoriteItems = [];
      state.error = null;
      state.logInError = null;
      state.loggedIn = false;
    },
    setLogInFailure: logInFailed,
    setUpdateFailure: updateFailed,
    setFailure: loadingFailed,
  },
});

export const {
  setIsLoading,
  setLoginLoading,
  setUpdateLoading,
  getUserSuccess,
  setLoginSuccess,
  updateFavoriteItems,
  setExpires,
  setTimeoutSet,
  updateCurrentTerritory,
  updateCurrentChannel,
  removeUser,
  setLogInFailure,
  setUpdateFailure,
  setFailure,
} = userSlice.actions;

export default userSlice.reducer;

export const fetchUser = () => async (dispatch) => {
  try {
    dispatch(setIsLoading());
    const response = await getUser();
    if (response.error) {
      throw response.error;
    }
    window.localStorage.setItem("brandhub-role", response.data.role);
    let currentUser = {
      id: response.data.id,
      supplierId: response.data.supplier ? response.data.supplier.id : null,
      name: response.data.name.includes(",")
        ? response.data.name.split(", ")[1] +
          " " +
          response.data.name.split(", ")[0]
        : response.data.name,
      firstName: response.data.name.includes(",")
        ? response.data.name.split(", ")[1]
        : response.data.name.split(" ")[0],
      lastName: response.data.name.includes(",")
        ? response.data.name.split(", ")[0]
        : response.data.name.split(" ")[
            response.data.name.split(" ").length - 1
          ],
      initials: "",
      email: response.data.email,
      role: response.data.role,
      isOnPremise: response.data["is-on-premise"] ? true : false,
      isRetail: response.data["is-retail"]
        ? true
        : !response.data["is-on-premise"]
        ? true
        : false,
      currentChannel: response.data["is-retail"]
        ? "retail"
        : response.data["is-on-premise"]
        ? "on_premise"
        : "retail",
      territories: mapAndSortTerritories(
        response.data.territories,
        response.data.role
      ),
      states:
        response.data.states.length > 0
          ? response.data.states.map((state) => ({
              id: state.id,
              code: state.code,
            }))
          : [],
      currentTerritory: "",
      favoriteItems: mapItems(response.data["favorite-items"]),
      status: response.data.status ? response.data.status : "active",
    };
    currentUser.initials = `${currentUser.firstName[0]}${currentUser.lastName[0]}`;
    currentUser.currentTerritory =
      currentUser.territories.length > 0 ? currentUser.territories[0].id : null;
    dispatch(getUserSuccess({ user: currentUser }));
  } catch (err) {
    logoutUser();
    dispatch(setRedirect({ url: "/" }));
    dispatch(removeUser());
    dispatch(setFailure({ error: err.toString }));
  }
};

export const addToFavoriteItems = (idArray) => async (dispatch) => {
  try {
    dispatch(setUpdateLoading());
    const patchData = buildFavoriteItemsPatch(idArray);
    const response = await axiosPatch("/api/current-user", patchData);
    if (response.error) throw response.error;
    const items = mapItems(response.data["favorite-items"]);
    dispatch(updateFavoriteItems({ items: items }));
  } catch (err) {
    dispatch(setUpdateFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Users" }));
  }
};

export const loginWithCode = (code, exp) => async (dispatch) => {
  try {
    dispatch(setLoginLoading());
    const res = await loginUserWithAuthO(code, exp);
    if (res.error) {
      throw res.error;
    }
    dispatch(
      setLoginSuccess({
        expires: exp,
      })
    );
  } catch (err) {
    dispatch(setLogInFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Users" }));
  }
};
