/** @jsxImportSource @emotion/react */
import "twin.macro";

import { Link, useNavigate } from "react-router-dom";

import { ArrowBack } from "@mui/icons-material";
import { Skeleton } from "@mui/material";

import { PageTitle } from "src/components/StyledComponents";
import { formatMoneyString } from "src/utility/utilityFunctions";

import { useBudget } from "../useBudget";

export const BudgetInfo = ({ budgetId }) => {
  const navigate = useNavigate();
  const { budget } = useBudget(budgetId);
  const handleGoBack = (e) => {
    e.preventDefault();
    const hasPreviousPage = window.history.length > 1;
    if (hasPreviousPage) navigate(-1);
    else navigate("/budgets");
  };

  return (
    <div>
      <div tw="flex items-center mt-6 mb-3">
        <Link tw="p-2" to={"/budgets"} onClick={handleGoBack}>
          <ArrowBack />
        </Link>
        {budget && (
          <PageTitle tw="my-0">
            Budget {budget.subStateCode} {budget.brandName}
          </PageTitle>
        )}
      </div>
      {!budget && <Skeleton variant="text" width={200} height={48} />}
      {budget && (
        <div tw="flex gap-x-4 flex-wrap text-neutral-600">
          <div tw="p-3">
            <ul>
              <li>External ID: {budget.externalId}</li>
              <li>Brand: {budget.brandName}</li>
              <li>
                State:{" "}
                {!budget.subStateName ||
                budget.subStateCode === budget.subStateName
                  ? budget.subStateCode
                  : budget.subStateCode + " " + budget.subStateName}
              </li>
              <li>Year: {budget.year}</li>
            </ul>
          </div>
          <div tw="rounded-xl space-y-1 shadow-md p-3 flex-1">
            {[
              ["Budget Total", budget.totalAmount],
              ["Pending", budget.pendingAmount],
              ["Committed", budget.committedAmount],
              ["Spent", budget.spentAmount],
              ["Available", budget.availableAmount],
            ].map(([label, value]) => (
              <div
                tw="flex justify-between items-baseline last-of-type:font-medium first-of-type:(border-b mb-2)"
                key={label}
              >
                <div tw="text-neutral-800">{label}</div>
                <div tw="text-right text-neutral-900">
                  {formatMoneyString(value)}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
