/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import { Alert } from "@mui/material";

import _ from "lodash";

import { RFQ_ROLES } from "../../../constants/permissions";
import {
  useHandleSubmitErrors,
  useMapItemIdToItemProgramId,
  useProgramInfo,
} from "../../../hooks/planningToolHooks";
import {
  cloneCouponOffer,
  createItem,
  deleteItemProgram,
  setNewEntityDefaults,
  updateItemProgram,
} from "../../../redux/slices/planningTool/itemProgramSlice";
import { openRFQModal } from "../../../redux/slices/planningTool/rfqSlice";
import {
  defaultFormValues,
  useCustomForm,
} from "../../../utility/inputHelpers";
import ReadOnlyField from "../../Forms/ReadOnlyField";
import { ConfirmButton, StyledButton } from "../../StyledComponents";
import SaveStatusChip from "../../Utility/SaveStatusChip";
import ScrollIntoView from "../../Utility/ScrollIntoView";
import UserCanView from "../../Utility/UserCanView";
import CouponCopyModal from "./CouponCopy/CouponCopyModal";
import CouponForm from "./CouponForm";
import InfoAccordions from "./InfoAccordions";
import {
  compareWithOrderType,
  couponClonableFields,
  couponDataFromForm,
  fields,
  fieldsShouldNotDuplicate,
  formDataFromCoupon,
} from "./helpers";

const useSyncData = (setValue, data) => {
  const [key, value] = Object.entries(data)[0];
  useEffect(() => {
    setValue(key, value);
  }, [setValue, value, key]);
};

const CouponEdit = ({ item }) => {
  const dispatch = useDispatch();
  const {
    isSubmitted,
    programId,
    orderType: programOrderType,
  } = useProgramInfo();

  const clonedFromItemProgramId =
    useMapItemIdToItemProgramId(item.clonedFromId) || null;

  const [isSaving, setIsSaving] = useState(false);
  const [isDuplicateLoading, setIsDuplicateLoading] = useState(false);
  const [couponCopyModalOpen, setCouponCopyModalOpen] = useState(false);

  const savedData = useMemo(() => formDataFromCoupon(item), [item]);
  const { estimatedQty, couponEstimatedRedemption } = savedData;
  const clonableFields = _.pick(savedData, couponClonableFields);

  const compareFunction = compareWithOrderType(programOrderType);

  const formAttrs = useCustomForm({
    mode: "onBlur",
    defaultValues: savedData,
    compareFunction,
  });

  const {
    control,
    isSaved,
    handleSubmit,
    setError,
    clearErrors,
    setValue,
    formState: { errors },
  } = formAttrs;

  const requiredForSubmit = useHandleSubmitErrors(setError, item.id);
  // Only used for submit errors
  const errorArray = Object.entries(errors).map(
    ([field, { message }]) => `${field} ${message ?? "Is required"}`
  );

  const requiredFields = fields.default.required.concat(
    isSubmitted ? fields.submitted.required : []
  );

  let disabledFields = isSubmitted
    ? fields.submitted.disabled
    : fields.default.disabled;

  if (clonedFromItemProgramId) {
    disabledFields = disabledFields.concat(couponClonableFields);
  }

  // Default props for controlled inputs
  const controlled = (name, rules) => ({
    name,
    control,
    rules: { ...rules, required: requiredFields.includes(name) },
    disabled: disabledFields.includes(name),
    onBlur: () => {
      clearErrors();
      handleSubmit(handleSave)();
    },
  });

  const handleSave = (data) => {
    if (!compareFunction(defaultFormValues(data), savedData)) {
      setIsSaving(true);
      dispatch(
        updateItemProgram(
          item,
          couponDataFromForm(data, programOrderType),
          () => {
            setIsSaving(false);
          }
        )
      );
    }
  };

  const handleDelete = () => {
    if (item.id) {
      dispatch(deleteItemProgram(item.id, item.clones));
    }
  };

  const formatDataToDuplicate = (data) =>
    couponDataFromForm(
      _.omit(data, fieldsShouldNotDuplicate),
      programOrderType
    );

  const handleDuplicate = (data) => {
    const dataToDuplicate = formatDataToDuplicate(data);
    if (isSubmitted) {
      dispatch(setNewEntityDefaults({ type: "coupon", data: dataToDuplicate }));
    } else {
      setIsDuplicateLoading(true);
      dispatch(
        createItem(programId, dataToDuplicate, () =>
          setIsDuplicateLoading(false)
        )
      );
    }
  };

  const handleCloneOffer = (data) => {
    const dataToDuplicate = formatDataToDuplicate(data);
    if (isSubmitted) {
      dispatch(
        setNewEntityDefaults({
          type: "coupon",
          data: {
            ...dataToDuplicate,
            clonedFromItemProgramId: clonedFromItemProgramId || item.id,
          },
        })
      );
    } else {
      setIsDuplicateLoading(true);
      dispatch(
        cloneCouponOffer(clonedFromItemProgramId || item.id, {}, () =>
          setIsDuplicateLoading(false)
        )
      );
    }
  };

  useSyncData(setValue, { estimatedQty });
  useSyncData(setValue, { couponEstimatedRedemption });

  useEffect(() => {
    if (!clonedFromItemProgramId) return;
    Object.entries(clonableFields).forEach(([key, value]) => {
      setValue(key, value);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...Object.values(clonableFields), clonedFromItemProgramId]);

  return (
    <div tw="space-y-4">
      {requiredForSubmit && <ScrollIntoView trigger={requiredForSubmit} />}
      <div tw="flex justify-between items-end pb-2">
        <div tw="space-x-4 flex grow mr-4">
          <ReadOnlyField
            label="Barcode ID"
            value={item.couponBarcodeId || "N/A"}
          />

          <ReadOnlyField
            label="Sequence Number"
            value={item.itemNumber || "N/A"}
          />
        </div>
        <SaveStatusChip isSaved={isSaved} isSaving={isSaving} />
      </div>
      {requiredForSubmit && errorArray.length > 0 && (
        <Alert severity="error" onClose={clearErrors}>
          <ul>
            {errorArray.map((err) => (
              <li key={err}>{err}</li>
            ))}
          </ul>
        </Alert>
      )}
      <CouponForm
        form={formAttrs}
        controlled={controlled}
        item={item}
        clonedFromItemProgramId={clonedFromItemProgramId}
      />
      <div>Mandatory Code: {item.mandatoryCode}</div>
      <InfoAccordions item={item} />
      <div tw="flex justify-between">
        <ConfirmButton
          danger
          confirm={
            isSubmitted
              ? "Are you sure you want to cancel this coupon? This can't be undone."
              : "Are you sure you want to permanently delete this coupon? This can't be undone."
          }
          onClick={handleDelete}
          disabled={isSaving}
        >
          {isSubmitted ? "Cancel Coupon" : "Delete Coupon"}
        </ConfirmButton>
        <div tw="space-x-2">
          <UserCanView allow={RFQ_ROLES}>
            <Link
              to={`/planning/rfqs/program/${programId}/item/${item.itemId}`}
            >
              View RFQs
            </Link>
            <StyledButton
              outlined
              disabled={!item.itemNumber}
              onClick={() =>
                dispatch(
                  openRFQModal({ init: { itemId: item.itemId, programId } })
                )
              }
            >
              CREATE RFQ
            </StyledButton>
          </UserCanView>
          <StyledButton outlined onClick={() => setCouponCopyModalOpen(true)}>
            Coupon Copy
          </StyledButton>
          <StyledButton
            outlined
            onClick={handleSubmit(handleCloneOffer)}
            disabled={isDuplicateLoading}
          >
            Clone Offer
          </StyledButton>
          <StyledButton
            outlined
            onClick={handleSubmit(handleDuplicate)}
            disabled={isDuplicateLoading}
          >
            Duplicate Coupon
          </StyledButton>
        </div>
      </div>

      <CouponCopyModal
        open={couponCopyModalOpen}
        handleClose={() => setCouponCopyModalOpen(false)}
        item={item}
      />
    </div>
  );
};

export default CouponEdit;
