import React, { useState } from "react";
import { Link } from "react-router-dom";

import AccountBoxIcon from "@mui/icons-material/AccountBox";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
// import NotificationsIcon from "@mui/icons-material/Notifications";
import HelpIcon from "@mui/icons-material/Help";
import SettingsIcon from "@mui/icons-material/Settings";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";

import PropTypes from "prop-types";

import { FREIGHT_FORWARDER, SUPPLIER_ROLES } from "../../constants/permissions";

const UserNavMenu = ({ initials, handleLogout, classes, role }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpen = (evt) => {
    setAnchorEl(evt.target);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Tooltip title="User Options">
        <IconButton
          aria-owns={anchorEl ? "notifications" : undefined}
          aria-haspopup="true"
          onClick={(evt) => {
            handleOpen(evt);
          }}
          size="large"
        >
          <Avatar className={classes.avatar}>{initials}</Avatar>
        </IconButton>
      </Tooltip>
      <Menu
        disableScrollLock
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        style={{ marginTop: "10px", zIndex: "10001" }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {/* <MenuItem
        onClick={() => {
          handleClose();
        }}
      >
        <ListItemIcon>
          <NotificationsIcon color="secondary" fontSize="small" />
        </ListItemIcon>
        <ListItemText primary="Notifications" />
      </MenuItem>
      <Divider /> */}
        {![...SUPPLIER_ROLES, FREIGHT_FORWARDER].includes(role) && [
          <MenuItem
            key="profile"
            component={Link}
            to="/profile#general"
            onClick={() => {
              handleClose();
            }}
          >
            <ListItemIcon>
              <AccountBoxIcon color="secondary" fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Profile" />
          </MenuItem>,
          <Divider key="divider1" />,
        ]}
        {["super", "purchaser"].includes(role) && [
          <MenuItem
            key="settings"
            component={Link}
            to="/settings#users"
            onClick={() => {
              handleClose();
            }}
          >
            <ListItemIcon>
              <SettingsIcon color="secondary" fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Settings" />
          </MenuItem>,
          <Divider key="divider2" />,
        ]}
        {role === "supplier" && [
          <MenuItem
            key="help"
            component={Link}
            to="/help"
            onClick={() => {
              handleClose();
            }}
          >
            <ListItemIcon>
              <HelpIcon color="secondary" fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Help &amp; Info" />
          </MenuItem>,
          <Divider key="divider3" />,
        ]}
        <MenuItem
          component={Link}
          to="/"
          onClick={() => {
            handleLogout();
            handleClose();
          }}
        >
          <ListItemIcon>
            <ExitToAppIcon color="secondary" fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </MenuItem>
      </Menu>
    </>
  );
};

UserNavMenu.propTypes = {
  initials: PropTypes.string,
  handleLogout: PropTypes.func.isRequired,
  role: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

export default UserNavMenu;
