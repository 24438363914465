import { createSlice } from "@reduxjs/toolkit";

/*
This slice controls global loading states in the application. Each slice handles its own loading
for the most part, but the global load slice triggers some more generic loading effects in the
application. This slice also controls the stepper loader, which gives progress indications to 
the user.
*/

let initialState = {
  isLoading: false,
  isStepperLoad: false,
  isProgStepperLoad: false,
  stepperValue: 0,
  progStepperValue: 0,
  stepperTitle: null,
  progStepperTitle: null,
  redirect: null,
};

const globalLoadSlice = createSlice({
  name: "globalLoad",
  initialState,
  reducers: {
    startGlobalLoad(state) {
      state.isLoading = true;
    },
    stopGlobalLoad(state) {
      state.isLoading = false;
    },
    setIsStepper(state, action) {
      const { stepBool, stepTitle } = action.payload;
      state.isStepperLoad = stepBool;
      state.stepperTitle = stepTitle;
    },
    setIsProgStepper(state, action) {
      const { stepBool, stepTitle } = action.payload;
      state.isProgStepperLoad = stepBool;
      state.progStepperTitle = stepTitle;
    },
    updateStepperValue(state, action) {
      const { value } = action.payload;
      state.stepperValue += value;
    },
    updateProgStepperValue(state, action) {
      const { value } = action.payload;
      state.progStepperValue += value;
    },
    resetStepperValue(state) {
      state.stepperValue = 0;
      state.stepperTitle = null;
      state.isStepperLoad = false;
    },
    resetProgStepperValue(state) {
      state.progStepperValue = 0;
      state.progStepperTitle = null;
      state.isProgStepperLoad = false;
    },
    setRedirect(state, action) {
      const { url } = action.payload;
      state.redirect = url;
    },
    resetGlobalLoad(state) {
      state.isLoading = false;
      state.isStepperLoad = false;
      state.isProgStepperLoad = false;
      state.stepperValue = 0;
      state.progStepperValue = 0;
      state.stepperTitle = null;
      state.progStepperTitle = null;
      state.redirect = null;
    },
  },
});

export const {
  startGlobalLoad,
  stopGlobalLoad,
  setIsStepper,
  setIsProgStepper,
  updateStepperValue,
  updateProgStepperValue,
  resetStepperValue,
  resetProgStepperValue,
  setRedirect,
  resetGlobalLoad,
} = globalLoadSlice.actions;

export default globalLoadSlice.reducer;
