import axios from "axios";
import { encode } from "js-base64";

const instance = axios.create();
delete instance.defaults.headers.common["Authorization"];

export const getCouponUrl = async (email, postbackUrl) => {
  const requestUrl = process.env.REACT_APP_COUPON_REQUEST_URL;
  const siteId = process.env.REACT_APP_COUPON_REQUEST_SITE_ID;
  const userNamePassword = process.env.REACT_APP_COUPON_USERNAME_PASSWORD;

  const res = await instance.post(
    requestUrl,
    {
      SiteId: siteId,
      Resource: "couponCustomization",
      UserEmail: email,
      Roles: ["Coupons", "Shopper"],
      PostbackUrl: postbackUrl,
    },
    {
      headers: {
        Authorization: `Basic ${encode(userNamePassword)}`,
      },
    }
  );
  return res.data.AccessUrl;
};
