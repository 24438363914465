import { useDispatch } from "react-redux";

import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  clearCurrentOrder,
  clearOrderByType,
} from "src/redux/slices/ordering/currentOrderSlice";

import { OrderSet } from "@models/OrderSet";
import client from "@services/api";

import { orderSetsKeyFactory } from "../../../queries/orderSetQueries";
import useOrderSetId from "../useOrderSetId";

export default function useSubmitOrderSetMutation() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const orderSetId = useOrderSetId();

  return useMutation({
    mutationFn: () => {
      return client.post<OrderSet>(
        `order-sets/${orderSetId}/submit`,
        undefined,
        { axiosOptions: { timeout: 10 * 60_1000 } }
      );
    },
    onSuccess: () => {
      let orderSet;
      queryClient.setQueryData(
        orderSetsKeyFactory.detail(orderSetId).queryKey,
        (os: OrderSet) => {
          orderSet = os;
          return { ...os, status: "submitted" };
        }
      );

      dispatch(
        clearOrderByType({
          type: orderSet.type === "on-demand" ? "onDemand" : "inventory",
        })
      );
      dispatch(clearCurrentOrder());
      queryClient.invalidateQueries({
        queryKey: orderSetsKeyFactory.paginated._def,
      });
    },
  });
}
