import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import AppBar from "@mui/material/AppBar";
import Tooltip from "@mui/material/Tooltip";
import makeStyles from "@mui/styles/makeStyles";

import { without } from "lodash";
import PropTypes from "prop-types";

import Logo from "../../assets/RTA_Logo_Stacked_White.png";
import {
  ALL_ROLES,
  FREIGHT_FORWARDER,
  NON_SUPPLIER_ROLES,
  SUPPLIER,
  SUPPLIER_ROLES,
} from "../../constants/permissions";
import OnPremiseRetailSelector from "../Utility/Selectors/OnPremiseRetailSelector";
import RegionSelector from "../Utility/Selectors/RegionSelector";
import DrawerItemsNav from "./DrawerItemsNav";
import DrawerOrdersNav from "./DrawerOrdersNav";
import DrawerPONav from "./DrawerPONav";
import DrawerRFPNav from "./DrawerRFPNav";
import DrawerRFQNav from "./DrawerRFQNav";
import DrawerToolkitNav from "./DrawerToolkitNav";
import UserNav from "./UserNav";

const environment = process.env.REACT_APP_DEV_ENV;
const envMap = {
  dev: "#008080",
  staging: "#1927ad",
  prod: "black",
};

const useStyles = makeStyles((theme) => ({
  ...theme.global,
  menuBackground: {
    backgroundColor: theme.palette.secondary.dark,
  },
  appBar: {
    height: "87px",
    backgroundColor: envMap[environment] || envMap.dev,
    justifyContent: "center",
    alignItems: "flex-end",
    zIndex: "1201",
  },
  title: {
    flexGrow: 1,
  },
  logoLink: {
    filter: "brightness(100%)",
    height: "58px",
    width: "auto",
    marginLeft: "25px",
    "&&:hover": {
      cursor: "pointer",
    },
  },
  selectedNavigationText: {
    textDecoration: "underline",
    textDecorationColor: theme.palette.primary.light,
  },
  nav: {
    float: "right",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginRight: "10px",
  },
  navigationText: {
    fontWeight: 500,
    color: "white",
    margin: "0 10px",
    pointerEvents: "none",
    cursor: "none",
  },
  navTextContainer: {
    display: "flex",
    flexDirection: "row",
    "&&:hover": {
      cursor: "pointer",
    },
  },
  navBreak: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "fit-content",
    margin: "0",
  },
  headerListItem: {
    color: "white",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  navList: {
    backgroundColor: "black",
    overFlow: "hidden",
  },
  regionText: {
    color: "black",
  },
  avatar: {
    backgroundColor: "white",
    color: "black",
    float: "right",
  },
  expandMoreIcon: {
    marginRight: "20px",
    color: "white",
    pointerEvents: "none",
    cursor: "none",
  },
  divider: {
    backgroundColor: "white",
  },
}));

const TopDrawerNav = ({ handleLogout }) => {
  const classes = useStyles();

  const initials = useSelector((state) => state.user.initials);
  const role = useSelector((state) => state.user.role);
  const territories = useSelector((state) => state.user.territories);

  return (
    <>
      <AppBar className={classes.appBar} position="fixed">
        <div style={{ display: "static", width: "100%" }}>
          <Tooltip style={{ float: "left" }} title="Home">
            <Link to="/dashboard">
              <img src={Logo} alt="Logo" className={classes.logoLink} />
            </Link>
          </Tooltip>
          <div className={classes.nav}>
            {role !== FREIGHT_FORWARDER && (
              <>
                {SUPPLIER_ROLES.includes(role) && (
                  <>
                    <DrawerRFQNav classes={classes} />
                    <DrawerRFPNav classes={classes} />
                    <DrawerPONav classes={classes} />
                  </>
                )}
                {NON_SUPPLIER_ROLES.includes(role) && (
                  <>
                    <DrawerItemsNav role={role} classes={classes} />
                    <DrawerOrdersNav role={role} classes={classes} />
                    <DrawerToolkitNav role={role} classes={classes} />
                  </>
                )}
                {without(ALL_ROLES, SUPPLIER).includes(role) && (
                  <OnPremiseRetailSelector classes={classes} />
                )}
                {territories && territories.length > 0 && (
                  <RegionSelector classes={classes} />
                )}
              </>
            )}
            <UserNav
              role={role}
              classes={classes}
              initials={initials}
              handleLogout={handleLogout}
            />
          </div>
        </div>
      </AppBar>
    </>
  );
};

TopDrawerNav.propTypes = {
  handleLogout: PropTypes.func.isRequired,
  currentMonth: PropTypes.number,
};

export default React.memo(TopDrawerNav);
