/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useEffect } from "react";
import { Link } from "react-router-dom";

import { format } from "date-fns";
import { capitalize } from "lodash";
import { SmartTable } from "src/components/SmartTable";
import { Column } from "src/components/SmartTable/SmartTable";
import { formatMoneyString, utcDate } from "src/utility/utilityFunctions";

import { useFilterParams } from "@features/filters";
import { getExtendedCost } from "@features/orders";
import { useOrdersPaginated } from "@features/orders/queries/orderQueries";
import { Order } from "@models/Order";

const orderHistoryPreviewColumns: Column<Order>[] = [
  {
    id: "id",
    label: "Order #",
    sort: "id",
    render: (id) => (
      <Link
        tw="underline"
        target="_blank"
        to={`/orders/history/single-order/${id}`}
      >
        {id}
      </Link>
    ),
  },
  { id: "user.name", label: "Ordered By" },
  {
    id: "submittedAt",
    label: "Order Date",
    sort: "submitted-at",
    render: (d) => format(utcDate(d), "MM/dd/yyyy"),
  },
  {
    id: "_totalExtendedEstCost",
    label: "Est. Total Cost",
    align: "right",
    render: (_v, order) =>
      formatMoneyString(getExtendedCost(order, order.type)),
  },
  {
    id: "status",
    label: "Status",
    render: (status) => capitalize(status === "submitted" ? "pending" : status),
  },
];

const BudgetDetailOrderHistorySummary = ({ budgetId }) => {
  const [appliedFilters, setAppliedFilters] = useFilterParams();
  const { data, ...tableProps } = useOrdersPaginated({
    filter: { budgetId },
    sort: appliedFilters.sort,
  });

  useEffect(() => {
    setAppliedFilters({ sort: "-submitted-at" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <SmartTable
      columns={orderHistoryPreviewColumns}
      rows={data}
      {...tableProps}
    />
  );
};

export default BudgetDetailOrderHistorySummary;
