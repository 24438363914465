import React, { useState } from "react";
import { useRef } from "react";
import { useForm } from "react-hook-form";

import { Delete, Edit } from "@mui/icons-material";
import {
  Box,
  Button,
  ClickAwayListener,
  IconButton,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";

import { useCustomRegister } from "../../utility/inputHelpers";

const EditableTableCell = React.forwardRef(
  ({ children, onClick, editing, renderInput, align, ...props }, ref) => {
    const Component = renderInput || TextField;
    return (
      <TableCell
        style={{
          position: "relative",
          pointerEvents: "all",
          textAlign: align || "left",
        }}
        onClick={onClick}
      >
        {children}
        {editing && (
          <Box
            style={{
              position: "absolute",
              inset: 0,
              padding: "16px",
              background: "white",
            }}
          >
            <Component ref={ref} fullWidth {...props} />
          </Box>
        )}
      </TableCell>
    );
  }
);

const CrudTableRow = ({ id, row, columns, handleUpdate, handleDelete }) => {
  const [editing, setEditing] = useState(false);
  const focusWithinRef = useRef(false);
  const {
    register,
    setFocus,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: row });
  const [loading, setLoading] = useState(false);

  const handleStartEditing = (id) => {
    if (editing) return;
    setEditing(true);
    focusWithinRef.current = true;
    // Focus the input after a short delay
    setTimeout(() => setFocus(id || columns[0].id, { shouldSelect: true }), 10);
  };

  const handleBlur = () => {
    focusWithinRef.current = false;
    setTimeout(async () => {
      if (!focusWithinRef.current) {
        setEditing(false);
      }
    }, 20);
  };

  const onSubmit = async () => {
    setLoading(true);
    // Todo check if edited
    await handleSubmit((data) => handleUpdate(id, data))();
    setEditing(false);
    setLoading(false);
  };

  const submitOnEnter = (e) =>
    e.keyCode === 13 && focusWithinRef.current && onSubmit(e);

  const reg = useCustomRegister(register, errors);

  return (
    <ClickAwayListener onClickAway={handleBlur}>
      <TableRow onKeyDown={submitOnEnter}>
        {columns.map(({ id, checks, align, transform, renderInput }) => {
          let content = row[id];
          if (typeof transform === "function") {
            content = transform(content);
          }

          const cellProps = {
            key: id,
            editing,
            align,
            renderInput,
            disabled: loading,
          };

          return (
            <EditableTableCell
              {...cellProps}
              {...reg(id, { ...checks })}
              onClick={() => handleStartEditing(id)}
            >
              {content}
            </EditableTableCell>
          );
        })}
        <TableCell style={{ padding: "0" }}>
          {editing ? (
            <Button variant="contained" onClick={onSubmit}>
              Update
            </Button>
          ) : (
            <Box sx={{ display: "flex" }}>
              <IconButton onClick={() => handleStartEditing()} size="large">
                <Edit />
              </IconButton>
              <IconButton onClick={() => handleDelete(id)} size="large">
                <Delete />
              </IconButton>
            </Box>
          )}
        </TableCell>
      </TableRow>
    </ClickAwayListener>
  );
};

export default CrudTableRow;
