import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import AddToPhotosIcon from "@mui/icons-material/AddToPhotos";
import CancelIcon from "@mui/icons-material/Cancel";
import { Checkbox, FormControlLabel, FormHelperText } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Popper from "@mui/material/Popper";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";

import { Territory } from "@models/Territory";

import { useInput } from "../../hooks/InputHooks";
import {
  addNewTerritory,
  setUpdateSuccess,
  updateTerritoryById,
} from "../../redux/slices/territories/territorySlice";

const TopPopper = (props) => <Popper {...props} placement="top" />;

const StateSelector = ({ classes, handleStates, states, territoryStates }) => {
  const [currentStates, setCurrentStates] = useState(territoryStates);

  useEffect(() => {
    if (currentStates.length !== territoryStates.length) {
      setCurrentStates(territoryStates);
    }
  }, [currentStates, territoryStates]);

  return (
    <Autocomplete
      multiple
      fullWidth
      autoHighlight
      freeSolo
      id="state-selector"
      options={states}
      getOptionLabel={(option) => option.code}
      onChange={(_evt, value) => handleStates(value)}
      classes={{
        popper: classes.popper,
      }}
      PopperComponent={TopPopper}
      value={currentStates}
      renderInput={(params) => (
        <TextField
          {...params}
          label="State"
          id="state"
          variant="outlined"
          size="small"
          autoComplete="new-password"
          InputProps={{
            ...params.InputProps,
            autoComplete: "new-password",
            "aria-autocomplete": "none",
            spellCheck: "false",
            autoCorrect: "off",
            endAdornment: <>{params.InputProps.endAdornment}</>,
          }}
        />
      )}
    />
  );
};

const useStyles = makeStyles<any>((theme) => ({
  ...theme.global,
  popper: {
    zIndex: "16000",
  },
  selectedButton: {
    fontWeight: "600",
    fontSize: "1rem",
    textAlign: "center",
    color: "#737373",
  },
  completeRow: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));

type TerritoryModalProps = {
  open: boolean;
  handleClose: () => void;
  type: string;
  id?: string;
  territoryList: Territory[];
};

const TerritoryModal: React.FC<TerritoryModalProps> = ({
  open,
  handleClose,
  type,
  id,
  territoryList,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [exemptFromBudgeting, setExemptFromBudgeting] = useState(false);

  const {
    value: name,
    bind: bindName,
    setValue: setName,
    reset: resetName,
  } = useInput("");
  const {
    value: code,
    bind: bindCode,
    setValue: setCode,
    reset: resetCode,
  } = useInput("");
  const {
    value: billingId,
    bind: bindBillingId,
    setValue: setBillingId,
    reset: resetBillingId,
  } = useInput("");
  const {
    value: anaplanId,
    bind: bindAnaplanId,
    setValue: setAnaplanId,
    reset: resetAnaplanId,
  } = useInput("");

  const [terrType, setTerrType] = useState("Regional");
  const [currentStates, setCurrentStates] = useState<any[]>([]);
  const [currentTerritory, setCurrentTerritory] = useState<Territory | null>(
    null
  );
  const [error, setError] = useState<string | null>(null);

  const stateList = useSelector((state: any) => state.territories.stateList);
  const provinceList = useSelector(
    (state: any) => state.territories.provinceList
  );
  const isUpdateLoading = useSelector(
    (state: any) => state.territories.isUpdateLoading
  );
  const updateSuccess = useSelector(
    (state: any) => state.territories.updateStatus
  );
  const updateError = useSelector((state: any) => state.territories.error);

  const resetAndClose = () => {
    resetName();
    resetCode();
    resetBillingId();
    resetAnaplanId();
    handleClose();
  };

  const handleSubmit = () => {
    if (
      name.length === 0 ||
      code.length === 0 ||
      billingId.length === 0 ||
      currentStates.length === 0 ||
      (!exemptFromBudgeting &&
        anaplanId.length === 0 &&
        terrType !== "Regional")
    ) {
      setError("You must complete form before submitting");
    } else {
      setError(null);
      if (type === "edit") {
        dispatch(
          updateTerritoryById(id, {
            name,
            states: currentStates,
            externalCode: code,
            billingId,
            externalAnaplanId: anaplanId,
            exemptFromBudgeting,
          })
        );
      } else {
        dispatch(
          addNewTerritory({
            name,
            states: currentStates,
            externalCode: code,
            billingId,
            externalAnaplanId: anaplanId,
            exemptFromBudgeting,
            type: terrType,
          })
        );
      }
    }
  };

  const handleStates = useCallback(
    (value) => {
      setCurrentStates(value);
    },
    [setCurrentStates]
  );

  const handleAllStates = () => {
    setCurrentStates(stateList.concat(provinceList));
  };

  useEffect(() => {
    if (
      !isUpdateLoading &&
      (type === "edit" || updateSuccess) &&
      (!currentTerritory || currentTerritory.id !== id)
    ) {
      let territory = territoryList.find((terr) => terr.id === id);
      if (territory) {
        setCurrentTerritory(territory);
        setCode(territory.externalCode);
        setBillingId(territory.externalBillingId);
        setCurrentStates(territory.states);
        setName(territory.name);
        setTerrType(territory.type);
        setAnaplanId(territory.externalAnaplanId ?? "");
        setExemptFromBudgeting(territory.exemptFromBudgeting);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTerritory, id, isUpdateLoading, type, updateSuccess]);

  useEffect(() => {
    dispatch(setUpdateSuccess({ updateStatus: false }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (updateError) {
      setError(updateError);
    }
  }, [updateError]);

  return (
    <div className={classes.relativeContainer}>
      <Dialog
        open={open}
        onClose={resetAndClose}
        fullWidth
        maxWidth="md"
        style={{ zIndex: "15000" }}
        disableScrollLock
      >
        <DialogTitle>
          <Typography className={classes.headerText}>
            {type === "edit" ? `Editing ${name}` : "New Territory"}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <IconButton
            className={classes.closeButton}
            onClick={resetAndClose}
            size="large"
          >
            <CancelIcon fontSize="large" color="secondary" />
          </IconButton>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              className={classes.headerText}
              style={{ marginBottom: "15px" }}
            >
              Territory Information
            </Typography>
            <br />
            <TextField
              size="small"
              className={classes.settingsMargin}
              variant="outlined"
              color="secondary"
              name="name"
              type="text"
              label="Name"
              {...bindName}
              fullWidth
            />
            <TextField
              size="small"
              className={classes.settingsMargin}
              variant="outlined"
              color="secondary"
              name="code"
              type="text"
              label="Region / Key Acct. Code"
              {...bindCode}
              fullWidth
            />
            <TextField
              size="small"
              className={classes.settingsMargin}
              variant="outlined"
              color="secondary"
              name="billingId"
              type="text"
              label="Billing ID"
              {...bindBillingId}
              fullWidth
            />
            <div style={{ width: "100%" }}>
              <FormControlLabel
                label="Exempt from Budgets/Anaplan"
                control={
                  <Checkbox
                    color="secondary"
                    checked={exemptFromBudgeting}
                    onChange={() =>
                      setExemptFromBudgeting(!exemptFromBudgeting)
                    }
                  />
                }
              />
            </div>
            <TextField
              size="small"
              className={classes.settingsMargin}
              variant="outlined"
              color="secondary"
              name="anaplan-id"
              type="text"
              label="Anaplan ID"
              {...bindAnaplanId}
              fullWidth
              helperText="Required if exempt from budgets/anaplan is unchecked"
            />

            <br />
            <ButtonGroup color="secondary" aria-label="type-select" fullWidth>
              <Button
                disabled={type === "edit"}
                className={
                  terrType === "Regional"
                    ? classes.largeButton
                    : classes.selectedButton
                }
                variant={terrType === "Regional" ? "contained" : "outlined"}
                onClick={() => {
                  setTerrType("Regional");
                }}
              >
                REGIONAL
              </Button>
              <Button
                disabled={type === "edit"}
                className={
                  terrType === "Customer"
                    ? classes.largeButton
                    : classes.selectedButton
                }
                variant={terrType === "Customer" ? "contained" : "outlined"}
                onClick={() => {
                  setTerrType("Customer");
                }}
              >
                KEY ACCOUNT
              </Button>
            </ButtonGroup>
            <FormHelperText>
              Cannot be edited once territory is created
            </FormHelperText>

            <br />
            <Typography
              className={classes.headerText}
              style={{ marginBottom: "15px" }}
            >
              Territory State Assignment
            </Typography>
            <br />
            <div className={classes.completeRow}>
              <StateSelector
                classes={classes}
                handleStates={handleStates}
                states={stateList.concat(provinceList)}
                territoryStates={currentStates}
              />
              <Tooltip
                PopperProps={{ style: { zIndex: "16000" } }}
                title="Assign All"
              >
                <span>
                  <IconButton
                    disabled={currentStates.length === stateList.length}
                    onClick={handleAllStates}
                    size="large"
                  >
                    <AddToPhotosIcon color="secondary" />
                  </IconButton>
                </span>
              </Tooltip>
            </div>
            <br />
            <br />
            <Button
              className={classes.largeButton}
              variant="contained"
              color="secondary"
              id="profile"
              onClick={handleSubmit}
              style={{
                float: "right",
                marginBottom: "20px",
                minWidth: "62px",
              }}
            >
              {isUpdateLoading ? <CircularProgress /> : "SUBMIT"}
            </Button>
            {!isUpdateLoading && error && (
              <Typography
                className={classes.bodyText}
                style={{ color: "#920000" }}
              >
                {error}
              </Typography>
            )}
            {!isUpdateLoading && updateSuccess && (
              <Typography className={classes.bodyText}>
                {type === "edit"
                  ? "Update Successful!"
                  : "New Territory Added Successfully!"}
              </Typography>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default React.memo(TerritoryModal);
