import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import CancelIcon from "@mui/icons-material/Cancel";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";

import PropTypes from "prop-types";

import { updateItemSupplier } from "../../redux/slices/items/itemSlice";
import { updateSupplier } from "../../redux/slices/purchasing/purchaseOrderSlice";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
  popperIndex: {
    zIndex: "16000",
  },
}));

const EditSupplierModal = ({ poId, itemId, modalOpen, handleClose, type }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [supplier, setSupplier] = useState("");
  const [notInGroup, setNotInGroup] = useState(false);
  const [notInGroupInfo, setNotInGroupInfo] = useState(null);

  const options = useSelector((state) => state.suppliers.supplierList);
  const isLoading = useSelector((state) => state.suppliers.isLoading);
  const currentUserRole = useSelector((state) => state.user.role);

  const handleSupplierClick = (supplier) => {
    const { id, name, isPurchaserSelect } = supplier;
    if (
      (currentUserRole === "select-purchaser" && isPurchaserSelect) ||
      (currentUserRole === "purchaser" && !isPurchaserSelect) ||
      currentUserRole === "super"
    ) {
      if (type === "po") {
        dispatch(updateSupplier(poId, id));
      } else {
        dispatch(updateItemSupplier(itemId, id));
      }
      handleClose();
    } else {
      setNotInGroup(true);
      setNotInGroupInfo({
        id: id,
        name: name,
        isPurchaserSelect: isPurchaserSelect,
      });
    }
  };

  const handleReset = () => {
    setSupplier("");
    setNotInGroup(false);
    setNotInGroupInfo(null);
  };

  const handleSubmit = () => {
    if (type === "po") {
      dispatch(
        updateSupplier(
          poId,
          notInGroupInfo.id,
          notInGroupInfo.name,
          notInGroupInfo.isPurchaserSelect
        )
      );
    } else {
      dispatch(updateItemSupplier(itemId, notInGroupInfo.id));
    }
    handleClose();
  };

  useEffect(() => {
    setNotInGroup(false);
    setNotInGroupInfo(null);
  }, []);

  return (
    <div className={classes.relativeContainer}>
      <Dialog
        open={modalOpen}
        onClose={handleClose}
        fullWidth
        maxWidth="sm"
        style={{ zIndex: "15000" }}
      >
        <DialogContent>
          {isLoading && (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingTop: "100px",
                paddingBottom: "100px",
              }}
            >
              <CircularProgress />
            </div>
          )}
          {!isLoading && (
            <>
              <IconButton
                className={classes.closeButton}
                onClick={handleClose}
                size="large"
              >
                <CancelIcon fontSize="large" color="secondary" />
              </IconButton>
              <br />
              <Typography className={classes.headerText}>
                {`Editing Supplier for PO #${poId}`}
              </Typography>
              <br />
              <br />
              <Autocomplete
                fullWidth
                autoHighlight
                disableClearable
                className={classes.queryField}
                id="supplier-auto-complete"
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                inputValue={supplier}
                onInputChange={(_evt, value) => setSupplier(value)}
                onChange={(evt, value) => {
                  handleSupplierClick(value);
                }}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) =>
                  option.name === value.name
                }
                options={options}
                disabled={notInGroup}
                classes={{ popper: classes.popperIndex }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Supplier"
                    variant="outlined"
                    size="small"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: <>{params.InputProps.endAdornment}</>,
                    }}
                  />
                )}
              />
              <br />
            </>
          )}
          {notInGroup && (
            <>
              <br />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography className={classes.bodyText}>
                  {`You are attempting to update this purchase order's supplier to one that is not in your current purchasing group.  If you continue, you will no longer be able to edit this Purchase Order, or change the supplier back.  Please notify a ${
                    currentUserRole === "purchaser"
                      ? "Select Purchaser"
                      : "Gallo Purchaser"
                  } or an Administrator upon making this change.`}
                </Typography>
                <br />
                <Typography className={classes.bodyText}>
                  Do you wish to continue?
                </Typography>
              </div>
              <br />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  width: "100%",
                }}
              >
                <Button
                  className={classes.largeButton}
                  variant="contained"
                  color="secondary"
                  id="program-cancel"
                  onClick={handleReset}
                >
                  NO
                </Button>
                <Button
                  className={classes.largeButton}
                  variant="contained"
                  color="secondary"
                  id="program-submit"
                  onClick={handleSubmit}
                >
                  YES
                </Button>
              </div>
              <br />
            </>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

EditSupplierModal.propTypes = {
  poId: PropTypes.string,
  itemId: PropTypes.string,
  modalOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

export default React.memo(EditSupplierModal);
