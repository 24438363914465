/** @jsxImportSource @emotion/react */
import tw, { css } from "twin.macro";

import { useCallback, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";

import { LinearProgress, Table, TableContainer } from "@mui/material";

import OrderSetHead from "./OrderSetHead";
import OrderSetOrders from "./OrderSetOrders";
import { useCurrentOrderSet } from "./data/CurrentOrderSetContext";

const orderSetTableStyles = css({
  "&": tw`max-h-[max(calc(var(--table-head-height) + 300px), calc(100vh - var(--header-height)) - 160px)]`,
  table: tw`border-separate`,
  "& > table > tbody > tr": tw`h-12 max-h-12`,
  "td, th": tw`
    bg-white border-r border-neutral-200 w-[240px] min-w-[240px]
    first-of-type:(w-[300px] min-w-[300px]! sticky left-0 z-20 bg-white)
    last-of-type:border-r-0`,
});

const OrderSetTable = () => {
  const { isFetching } = useCurrentOrderSet();
  const formMethods = useForm<Record<string, any>>({
    defaultValues: {},
  });
  const [stateCodeFilter, setStateCodeFilter] = useState<null | string>(null);
  const [headerHeight, setHeaderHeight] = useState(0);
  // https://reactjs.org/docs/hooks-faq.html#how-can-i-measure-a-dom-node
  const measuredRef = useCallback((node: HTMLElement | null) => {
    if (node !== null) {
      setHeaderHeight(node.getBoundingClientRect().height);
    }
  }, []);

  return (
    <div tw="pb-16 transition-opacity relative">
      {isFetching && <LinearProgress tw="absolute inset-0 bottom-auto" />}
      <div
        tw="rounded shadow-md overflow-hidden border border-neutral-100"
        style={{ opacity: isFetching ? 0.5 : 1 }}
      >
        <TableContainer
          css={orderSetTableStyles}
          style={{ "--table-head-height": `${headerHeight}px` } as any}
        >
          <Table size="small">
            <OrderSetHead
              measuredRef={measuredRef}
              stateCodeFilter={stateCodeFilter}
              setStateCodeFilter={setStateCodeFilter}
            />
            <FormProvider {...formMethods}>
              <OrderSetOrders stateCodeFilter={stateCodeFilter} />
            </FormProvider>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default OrderSetTable;
