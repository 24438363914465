import { format } from "date-fns";

import {
  formatMoneyCents,
  formatMoneyString,
} from "../../utility/utilityFunctions";

const joinWithComma = (v) => v.join(", ");

const displayStatus = (v) =>
  ({
    "in-progress": "In Progress",
    inactive: "Not Started",
    submitted: "Order Submitted",
    ok: "Ok",
  })[v] || v;

const displayDate = (d) =>
  d && d !== "---" ? format(new Date(d), "MM/dd/yyyy") : "";

const createMapFunction = (schema) => (report) => {
  const schemaLookup = schema.reduce((acc, val) => {
    acc[val.key] = val;
    return acc;
  }, {});

  return report.map((row) =>
    Object.entries(row).reduce((acc, [key, value]) => {
      acc[key] = schemaLookup[key]?.f ? schemaLookup[key].f(value) : value;
      return acc;
    }, {})
  );
};

const orderHeadCells = [
  { label: "Person", key: "userName" },
  { label: "Program", key: "anaplanProgramsLabel" },
  { label: "Brand", key: "brand", f: joinWithComma },
  { label: "State", key: "state" },
  { label: "Est. Unit Costs", key: "totalProductCost", f: formatMoneyCents },
  {
    label: "Total Beacon Cost",
    key: "totalBeaconCost",
    f: formatMoneyString,
  },
  {
    label: "Est. Freight",
    key: "totalEstFreight",
    f: formatMoneyString,
  },
  {
    label: "Est. Taxes",
    key: "totalEstTaxes",
    f: formatMoneyString,
  },
  {
    label: "Est. Extended Total",
    key: "totalExtendedCost",
    f: formatMoneyString,
  },
  { label: "Order Submitted", key: "orderDate", f: displayDate },
  { label: "Order Window Close", key: "dueDate", f: displayDate },
  { label: "In Market Date", key: "inMarketDate", f: displayDate },
  { label: "Status", key: "status", f: displayStatus },
];
const itemHeadCells = [
  { label: "Person", key: "orderUserName" },
  { label: "Sequence #", key: "itemNumber" },
  { label: "Program", key: "anaplanProgramsLabel" },
  { label: "Brand", key: "brands", f: joinWithComma },
  { label: "Business unit", key: "bus", f: joinWithComma },
  { label: "Item Type", key: "itemTypeDescription" },
  { label: "Item Desc.", key: "itemDescription" },

  { label: "Address ID/ABN", key: "addressIdOrAbn" },
  { label: "Address Name", key: "addressName" },
  { label: "Street 1", key: "streetAddress1" },
  { label: "Street 2", key: "streetAddress2" },
  { label: "City", key: "city" },
  { label: "State", key: "state" },
  { label: "Zip code", key: "zip" },

  { label: "Qty / Pack", key: "qtyPerPack" },
  { label: "Quantity", key: "qty" },
  { label: "Est. Cost", key: "estimatedCost", f: formatMoneyString },
  {
    label: "Est. Total Costs",
    key: "totalEstimatedCost",
    f: formatMoneyString,
  },
  {
    label: "Total Beacon Cost",
    key: "totalBeaconCost",
    f: formatMoneyString,
  },
  {
    label: "Est. Freight",
    key: "totalEstimatedShippingCost",
    f: formatMoneyString,
  },
  {
    label: "Est. Taxes",
    key: "totalEstimatedTax",
    f: formatMoneyString,
  },
  {
    label: "Est. Extended Total",
    key: "totalExtendedCost",
    f: formatMoneyString,
  },
  { label: "Order Submitted", key: "orderSubmittedAt", f: displayDate },
  { label: "Order Window Close", key: "purchasingCloseDate", f: displayDate },
  { label: "In Market Date", key: "inMarketDate", f: displayDate },
  { label: "Status", key: "status", f: displayStatus },
];

export const orderApprovalOrder = {
    headers: orderHeadCells,
    mapFunction: createMapFunction(orderHeadCells),
  },
  orderApprovalItem = {
    headers: itemHeadCells,
    mapFunction: createMapFunction(itemHeadCells),
  };
