import { createSlice } from "@reduxjs/toolkit";

import { handleErrors } from "../../../api/apiFunctions";
import {
  axiosGet,
  axiosGetWithNext,
  axiosPost,
  axiosPut,
} from "../../../api/axiosCalls";
import { setError } from "../errorSlice";
import {
  setRedirect,
  startGlobalLoad,
  stopGlobalLoad,
} from "../globalLoadSlice";
import {
  setFailure as patchFailure,
  setIsLoading as patchLoading,
  patchSuccess,
} from "../patchOrderSlice";
import {
  buildDraftClonedProgram,
  buildDraftProgram,
  buildFilteredPlanningProgramQuery,
  buildProgram,
} from "./helpers";
import { clearSubmitErrors, handleSubmitErrors } from "./itemProgramSlice";
import { mapPlanningPrograms, mapProgram } from "./maps";

const unsetCurrentProgram = {
  id: null,
  name: null,
  externalWorkfrontId: null,
  status: null,
  orderType: null,
  primaryBrand: null,
  programType: null,
  type: null,
  sponsorRegion: null,
  inMarketMonth: null,
  channel: null,
  isTerritoryExclusive: false,
  isBrandFunded: false,
  territories: [],
  brands: [],
  beginDate: null,
  finishDate: null,
  items: [],
  orderWindowOpen: null,
};

let initialState = {
  isLoading: false,
  isNextLoading: false,
  isSingleLoading: false,
  isUpdateLoading: false,
  currentProgram: { ...unsetCurrentProgram },

  programsPerPage: 50,
  totalPages: null,
  pagesLoaded: 0,
  nextLink: null,
  isItemsLoading: false,
  programList: [],
  currentPreOrderVariants: [],
  isPrograms: false,
  isActive: true,

  programs: [],
  hasSubmitted: false,
  isDraft: false,
  error: null,
};

const loadingFailed = (state, action) => {
  const { error } = action.payload;
  state.isLoading = false;
  state.isItemsLoading = false;
  state.isNextLoading = false;
  state.isSingleLoading = false;
  state.isUpdateLoading = false;
  state.error = error;
};

const programSlice = createSlice({
  name: "program",
  initialState,
  reducers: {
    setIsLoading(state) {
      state.isLoading = true;
    },
    setNextIsLoading(state) {
      state.isNextLoading = true;
    },
    setIsUpdateLoading(state) {
      state.isUpdateLoading = true;
    },
    setIsSingleLoading(state) {
      state.isSingleLoading = true;
    },
    setTotalPages(state, action) {
      const { pageCount } = action.payload;
      state.totalPages = pageCount;
    },
    newProgramSuccess(state, action) {
      const { program } = action.payload;
      state.currentProgram = { ...program };
      state.isSingleLoading = false;
      state.isUpdateLoading = false;
      state.error = null;
    },
    updateProgramSuccess(state, action) {
      const { program } = action.payload;
      state.currentProgram = { ...program };
      state.isLoading = false;
    },
    copyProgramSuccess(state, action) {
      const { program } = action.payload;
      state.currentProgram = { ...program };
      state.isLoading = false;
    },
    submitProgramSuccess(state, action) {
      const { program } = action.payload;
      state.currentProgram = { ...program };
      state.status = "submitted";
      state.isLoading = false;
    },
    fetchSingleProgramSuccess(state, action) {
      const { program } = action.payload;
      state.currentProgram = { ...program };
      state.isLoading = false;
    },
    clearSingleProgram(state) {
      state.currentProgram = { ...unsetCurrentProgram };
    },
    getProgramsSuccess(state, action) {
      const { programs, nextLink } = action.payload;
      state.pagesLoaded += 1;
      state.nextLink = nextLink;
      state.programs = [...programs];
      state.isNextLoading = false;
      state.isLoading = false;
      state.error = null;
    },
    clearPrograms(state) {
      state.pagesLoaded = 0;
      state.nextLink = null;
      state.isNextLoading = false;
      state.programs = [];
      state.isLoading = false;
      state.error = null;
    },
    getNextProgramsSuccess(state, action) {
      const { programs, nextLink } = action.payload;
      let currentPrograms = [...state.programs];
      let updatedPrograms = currentPrograms.concat(programs);
      state.pagesLoaded += 1;
      state.nextLink = nextLink;
      state.programs = updatedPrograms;
      state.isNextLoading = false;
      state.error = null;
    },
    clearNextLink(state) {
      state.nextLink = null;
    },
    clearIsSingleLoading(state) {
      state.isSingleLoading = false;
    },
    clearisUpdateLoading(state) {
      state.isUpdateLoading = false;
    },
    cancelSuccess(state, action) {
      const { id } = action.payload;
      let updatedPrograms = state.programList.filter((prog) => prog.id !== id);
      state.currentProgram.status = "canceled";
      state.programList = updatedPrograms;
      state.isUpdateLoading = false;
      state.updateStatus = true;
      state.error = null;
    },
    setFailure: loadingFailed,
  },
});

export const {
  setIsLoading,
  setTotalPages,
  clearSingleProgram,
  setIsUpdateLoading,
  clearisUpdateLoading,
  setIsSingleLoading,
  clearIsSingleLoading,
  newProgramSuccess,
  updateProgramSuccess,
  copyProgramSuccess,
  submitProgramSuccess,
  cancelSuccess,
  setNextIsLoading,
  getNextProgramsSuccess,
  clearNextLink,
  fetchSingleProgramSuccess,
  getProgramsSuccess,
  clearPrograms,

  // getOrderCalendarSuccess,
  // resetPrograms,
  setFailure,
} = programSlice.actions;

export default programSlice.reducer;

export const fetchFilteredPrograms = (filterObject) => async (dispatch) => {
  try {
    dispatch(setIsLoading());
    dispatch(startGlobalLoad());
    const queryString = buildFilteredPlanningProgramQuery(filterObject);
    const response = await axiosGetWithNext(queryString);
    if (response.error) throw response.error;
    const mappedData = mapPlanningPrograms(response.data.data);
    dispatch(
      getProgramsSuccess({
        programs: mappedData,
        nextLink: response.data.nextLink,
      })
    );
    dispatch(stopGlobalLoad());
  } catch (err) {
    console.log(err);
    dispatch(setFailure({ error: err.toString() }));
    dispatch(
      setError({ error: err.toString(), source: "Fetch Filtered Programs" })
    );
    dispatch(stopGlobalLoad());
  }
};

export const fetchNextFilteredPrograms = (url) => async (dispatch) => {
  try {
    dispatch(setNextIsLoading());
    dispatch(clearNextLink());
    dispatch(startGlobalLoad());
    const response = await axiosGetWithNext(url);
    if (response.error) throw response.error;
    const mappedData = mapPlanningPrograms(response.data.data);
    dispatch(
      getNextProgramsSuccess({
        programs: mappedData,
        nextLink: response.data.nextLink,
      })
    );
    dispatch(stopGlobalLoad());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Items" }));
    dispatch(stopGlobalLoad());
  }
};

export const fetchSingleProgram = (programId) => async (dispatch) => {
  try {
    dispatch(startGlobalLoad());
    const res = await axiosGet(`/api/programs/${programId}`);
    if (res.error) throw res.error;
    const mappedData = mapProgram(res.data);
    dispatch(fetchSingleProgramSuccess({ program: mappedData }));
  } catch (error) {
    console.log(error);
    dispatch(setFailure({ error: error.toString() }));
    dispatch(
      setError({ error: error.toString(), source: "Fetch Single Program" })
    );
  }
  dispatch(stopGlobalLoad());
};

export const createProgram = (program, callback) => async (dispatch) => {
  try {
    dispatch(setIsSingleLoading());
    const body = buildDraftProgram(program);
    const res = await axiosPost("/api/programs", body);
    if (res.error) throw res.error;
    const mappedProgram = mapProgram(res.data);
    dispatch(newProgramSuccess({ program: mappedProgram }));
    dispatch(clearIsSingleLoading());
    dispatch(setRedirect({ url: `/planning/program/${mappedProgram.id}` }));
  } catch (err) {
    console.log(err);
    dispatch(setError({ error: err.toString(), source: "Create Program" }));
    dispatch(setFailure({ error: err.toString() }));
  } finally {
    callback?.();
  }
};

export const copyProgram =
  (parentProgramId, programDetails) => async (dispatch) => {
    try {
      dispatch(setIsUpdateLoading());
      const body = buildDraftClonedProgram(programDetails);
      const response = await axiosPost(
        `/api/programs/${parentProgramId}/clone`,
        body
      );
      if (response.error) throw response.error;
      const mappedData = mapProgram(response.data);
      dispatch(copyProgramSuccess({ program: mappedData }));
      dispatch(clearisUpdateLoading());
      dispatch(patchSuccess());
      dispatch(setRedirect({ url: `/planning/program/${mappedData.id}` }));
    } catch (err) {
      console.log(err);
      dispatch(patchFailure({ error: err.toString() }));
      dispatch(setFailure({ error: err.toString() }));
      dispatch(setError({ error: err.toString(), source: "Program Copy" }));
    }
  };

export const submitProgram =
  (id, programData, brands, territories, isExclusive, primaryBrand) =>
  async (dispatch) => {
    try {
      dispatch(setIsUpdateLoading());
      const programPostData = buildProgram({
        programData,
        brands,
        territories,
        isExclusive,
        primaryBrand,
      });
      const response = await axiosPost(
        `/api/programs/${id}/submit`,
        programPostData,
        { rawErrors: true }
      );
      if (response.error) throw response.error;
      const mappedData = mapProgram(response.data);
      dispatch(submitProgramSuccess({ program: mappedData }));
      dispatch(clearisUpdateLoading());
      dispatch(clearSubmitErrors());
      dispatch(patchSuccess());
    } catch (err) {
      dispatch(clearisUpdateLoading());
      dispatch(stopGlobalLoad());
      if (err?.response?.data?.errors?.[0]?.meta) {
        dispatch(handleSubmitErrors(err?.response?.data?.errors));
      } else {
        dispatch(
          setError({ error: handleErrors(err), source: "Submitting Program" })
        );
      }
      dispatch(setFailure({ error: err.toString() }));
    }
  };

export const updateProgram =
  ({
    id,
    programData,
    brands,
    territories,
    isExclusive,
    programType,
    primaryBrand,
    anaplanProgramId,
  }) =>
  async (dispatch) => {
    try {
      dispatch(setIsUpdateLoading());
      const programPutData = buildProgram({
        programData,
        brands,
        territories,
        isExclusive,
        programType,
        primaryBrand,
        anaplanProgramId,
      });
      const response = await axiosPut(`/api/programs/${id}`, programPutData);
      if (response.error) throw response.error;
      const mappedData = mapProgram(response.data);
      dispatch(updateProgramSuccess({ program: mappedData }));
      dispatch(clearisUpdateLoading());
      dispatch(patchSuccess());
    } catch (err) {
      console.error(err);
      dispatch(setFailure({ error: err.toString() }));
      dispatch(patchFailure({ error: err.toString() }));
      dispatch(setError({ error: err, source: "Program Update" }));
      dispatch(clearisUpdateLoading());
    }
  };

export const cancelProgram = (id) => async (dispatch) => {
  try {
    dispatch(patchLoading());
    dispatch(setIsUpdateLoading());
    const response = await axiosPost(`/api/programs/${id}/cancel`, {});
    if (response.error) throw response.error;
    dispatch(clearisUpdateLoading());
    dispatch(patchSuccess());
    dispatch(cancelSuccess({ id: id }));
  } catch (err) {
    dispatch(patchFailure({ error: err.toString() }));
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Program Cancel" }));
  }
};
