import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Button,
  ButtonGroup,
  Divider,
  List,
  ListItem,
  Typography,
} from "@mui/material";

import PropTypes from "prop-types";

import { fetchAllAdHocPrograms } from "../../redux/slices/programs/adHocProgramSlice";
import {
  fetchPrograms,
  setIsActive,
} from "../../redux/slices/programs/programsSlice";
import BUAutoComplete from "../Utility/AutoCompleteFields/BUAutoComplete";
import BrandAutoComplete from "../Utility/AutoCompleteFields/BrandAutoComplete";
import FocusMonthAutoComplete from "../Utility/AutoCompleteFields/FocusMonthAutoComplete";

const FiltersPrograms = ({
  reset,
  setReset,
  handleFilters,
  classes,
  programType,
}) => {
  const dispatch = useDispatch();

  const [value, setValue] = useState("brand");

  const { isActive } = useSelector((state) => state.programs);
  const { role, currentTerritory, currentChannel } = useSelector(
    (state) => state.user
  );

  const orderWindowType = useSelector(
    (state) => state.filters.defaultFilters?.orderWindowType
  );

  const handleActive = (active) => {
    dispatch(setIsActive({ value: active }));
    if (programType === "adHoc") {
      dispatch(fetchAllAdHocPrograms(currentTerritory, currentChannel, active));
    } else
      dispatch(
        fetchPrograms(orderWindowType, currentTerritory, currentChannel, active)
      );
  };

  useEffect(() => {
    handleActive(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderWindowType, currentChannel, currentTerritory]);

  return (
    <>
      <List>
        {["super", "purchaser", "select-purchaser"].includes(role) && (
          <ListItem
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <ButtonGroup
              orientation="vertical"
              fullWidth
              color="secondary"
              aria-label="active-toggle"
            >
              <Button
                className={
                  isActive ? classes.largeButton : classes.selectedButton
                }
                variant={isActive ? "contained" : "outlined"}
                onClick={() => handleActive(true)}
              >
                ACTIVE PROGRAMS
              </Button>
              <Button
                className={
                  !isActive ? classes.largeButton : classes.selectedButton
                }
                variant={!isActive ? "contained" : "outlined"}
                onClick={() => handleActive(false)}
              >
                PREVIEW PROGRAMS
              </Button>
            </ButtonGroup>
          </ListItem>
        )}
        <ListItem
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography className={classes.headerText}>
            {programType === "adHoc"
              ? "Sort Ad Hoc Programs By:"
              : "Sort Pre Order Programs By:"}
          </Typography>
          <br />
          <ButtonGroup
            orientation="vertical"
            fullWidth
            color="secondary"
            aria-label="program-sort"
          >
            <Button
              className={
                value === "brand" ? classes.largeButton : classes.selectedButton
              }
              variant={value === "brand" ? "contained" : "outlined"}
              onClick={() => {
                setValue("brand");
                handleFilters("brand", "sortProgramsBy", "program");
              }}
            >
              BRAND
            </Button>
            {programType !== "adHoc" && (
              <Button
                className={
                  value === "month"
                    ? classes.largeButton
                    : classes.selectedButton
                }
                variant={value === "month" ? "contained" : "outlined"}
                onClick={() => {
                  setValue("month");
                  handleFilters("month", "sortProgramsBy", "program");
                }}
              >
                FOCUS MONTH
              </Button>
            )}
            <Button
              className={
                value === "unit" ? classes.largeButton : classes.selectedButton
              }
              variant={value === "unit" ? "contained" : "outlined"}
              onClick={() => {
                setValue("unit");
                handleFilters("unit", "sortProgramsBy", "program");
              }}
            >
              BUSINESS UNIT
            </Button>
          </ButtonGroup>
        </ListItem>
        <ListItem />
        <Divider />
        <ListItem />
        <ListItem>
          <BrandAutoComplete
            classes={classes}
            handleChange={handleFilters}
            reset={reset}
            setReset={setReset}
            filterType={"program"}
          />
        </ListItem>
        {programType !== "adHoc" && (
          <ListItem>
            <FocusMonthAutoComplete
              classes={classes}
              handleChange={handleFilters}
              reset={reset}
              setReset={setReset}
              filterType={"program"}
            />
          </ListItem>
        )}
        <ListItem>
          <BUAutoComplete
            classes={classes}
            handleChange={handleFilters}
            reset={reset}
            setReset={setReset}
            filterType={"program"}
          />
        </ListItem>
      </List>
    </>
  );
};

FiltersPrograms.propTypes = {
  reset: PropTypes.bool.isRequired,
  setReset: PropTypes.func.isRequired,
  handleFilters: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default FiltersPrograms;
