/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import { CircularProgress, Paper } from "@mui/material";

import {
  clearCurrentItemProgram,
  fetchSingleItemProgram,
} from "../../../redux/slices/planningTool/itemProgramSlice";
import ItemForm from "../ItemPrograms/ItemForm";
import { formDataFromItem } from "../ItemPrograms/helpers";

const ProgramItemDetails = ({ programId, itemId }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  const {
    itemId: loadedItemId,
    programId: loadedProgramId,
    ...cur
  } = useSelector((state) => state.itemPrograms.current);

  const formAttrs = useForm({ defaultValues: formDataFromItem({}) });
  const { reset, control } = formAttrs;
  const controlled = (name) => ({ name, control, disabled: true });

  useEffect(() => {
    dispatch(fetchSingleItemProgram({ itemId, programId }));
    return () => dispatch(clearCurrentItemProgram());
  }, [itemId, programId, dispatch]);

  useEffect(() => {
    if (loading && itemId === loadedItemId && programId === loadedProgramId) {
      setLoading(false);
      reset(formDataFromItem(cur));
    }
  }, [cur, reset, loading, itemId, loadedItemId, programId, loadedProgramId]);

  return !cur.id ? (
    <CircularProgress />
  ) : (
    <Paper tw="px-6 py-8">
      {loading && <CircularProgress />}
      {!loading && <ItemForm form={formAttrs} controlled={controlled} />}
    </Paper>
  );
};

ProgramItemDetails.propTypes = {};

export default ProgramItemDetails;
