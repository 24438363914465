/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useState } from "react";

import { Alert, Chip, TextField } from "@mui/material";

import axios from "axios";
import { saveAs } from "file-saver";

import SimpleReportGenerator from "../components/Reporting/SimpleReportGenerator";
import { StyledButton } from "../components/StyledComponents";

const validateInvoiceNumber = async (num) => {
  const {
    data: { data },
  } = await axios.get(
    `/api/purchase-orders?filter[invoice-number]=${num}&page[size]=1`
  );
  return data.length !== 0;
};

const ReportQn = () => {
  const [invoiceValue, setInvoiceValue] = useState("");
  const [invoices, setInvoices] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleAddInvoice = async () => {
    if (invoices.indexOf(invoiceValue) !== -1) {
      return setError(`${invoiceValue} is already added to the report`);
    }

    setLoading(true);
    const valid = await validateInvoiceNumber(invoiceValue);
    setLoading(false);

    if (!valid) {
      return setError(`${invoiceValue} is not a valid invoice number`);
    }
    setInvoices(invoices.concat(invoiceValue));
    setInvoiceValue("");
    setError(null);
  };

  const handleRemoveInvoice = (num) => {
    setInvoices(invoices.filter((n) => n !== num));
  };

  const handleReport = async () => {
    setLoading(true);
    const { data } = await axios.get(
      `/api/print-invoice-report?invoice_numbers=${invoices.join(",")}`
    );

    const blob = new Blob([data], {
      type: "text/csv;charset=utf-8",
    });
    saveAs(blob, `qn-invoice-report.csv`);
    setLoading(false);
  };

  return (
    <SimpleReportGenerator
      reportTitle={"QN Invoice Report"}
      reportGetFunc={handleReport}
    >
      <div tw="max-w-lg mx-auto flex flex-col items-center space-y-6 ">
        <div tw="flex gap-2">
          <TextField
            size="small"
            label="Invoice number"
            value={invoiceValue}
            onChange={(e) => setInvoiceValue(e.target.value)}
          />
          <StyledButton cta onClick={handleAddInvoice} loading={loading}>
            Add invoice
          </StyledButton>
        </div>
        {error && <Alert severity="error">{error}</Alert>}
        <div tw="flex gap-2 flex-wrap">
          <div tw="text-neutral-500 text-sm">
            Invoices: {!invoices.length && "None"}
          </div>
          {invoices.map((num) => (
            <Chip
              size="small"
              key={num}
              label={num}
              onDelete={() => handleRemoveInvoice(num)}
            />
          ))}
        </div>
      </div>
    </SimpleReportGenerator>
  );
};

export default ReportQn;
