/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { useDispatch, useSelector } from "react-redux";

import GetAppIcon from "@mui/icons-material/GetApp";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";

import format from "date-fns/format";
import subDays from "date-fns/subDays";

import { CSVLink } from "@utils/csv";

import { DatePicker } from "../components/Forms/DefaultInputs";
import { StatusSelector } from "../components/Reporting/ReportSelectors";
import ReportTable from "../components/Reporting/ReportTable";
import TerritoryAutoComplete from "../components/Utility/AutoCompleteFields/TerritoryAutoComplete";
import UserAutoComplete from "../components/Utility/AutoCompleteFields/UserAutoComplete";
import { useReportCsv } from "../hooks/Reports/useReportCsv";
import { useNoFetch } from "../hooks/UtilityHooks";
import {
  resetFilters,
  setFiltersOpen,
  updateSingleFilter,
} from "../redux/slices/filterSlice";
import {
  clearReports,
  getOrderVariantSummaryReport,
} from "../redux/slices/reports/reportSlice";
import { formatDate } from "../utility/utilityFunctions";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
  ...theme.reports,
}));

const FinanceTracker = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [hasGenerated, setHasGenerated] = useState(false);
  const [reset, setReset] = useState(false);
  const [fromDate, setFromDate] = useState(
    format(formatDate(subDays(new Date(), 7)), "MM/dd/yyyy")
  );
  const [toDate, setToDate] = useState(
    format(formatDate(new Date()), "MM/dd/yyyy")
  );
  const [currentUsers, setCurrentUsers] = useState([]);
  const [currentTerritories, setCurrentTerritories] = useState([]);
  const [currentStatus, setCurrentStatus] = useState("submitted,approved");

  //const currentUserRole = useSelector((state) => state.user.role);
  const currentUserFilter = useSelector((state) => state.filters.user);
  const currentTerritoryFilter = useSelector(
    (state) => state.filters.territory
  );
  const isLoading = useSelector((state) => state.reports.isLoading);
  const report = useSelector((state) => state.reports.reportData);
  const reportType = useSelector((state) => state.reports.reportType);
  const currentChannel = useSelector((state) => state.user.currentChannel);

  const { setCsvData, data, headers } = useReportCsv(
    "finance-tracker",
    hasGenerated,
    setHasGenerated,
    isLoading,
    report
  );

  const handleUser = (value, _type, _filter) => {
    setCurrentUsers(value);
    dispatch(updateSingleFilter({ filter: "user", value: value }));
  };

  const handleTerritory = (value, _type, _filter) => {
    setCurrentTerritories(value);
    dispatch(updateSingleFilter({ filter: "territory", value: value }));
  };

  const handleUserChipClick = (id) => {
    let currentUserArray = currentUserFilter.filter((user) => user.id !== id);
    setCurrentUsers(currentUserArray);
    dispatch(updateSingleFilter({ filter: "user", value: currentUserArray }));
  };

  const handleTerritoryChipClick = (id) => {
    let currentTerrArray = currentTerritoryFilter.filter(
      (terr) => terr.id !== id
    );
    setCurrentTerritories(currentTerrArray);
    dispatch(
      updateSingleFilter({ filter: "territory", value: currentTerrArray })
    );
  };

  const handleReset = () => {
    setFromDate(format(formatDate(subDays(new Date(), 7)), "MM/dd/yyyy"));
    setToDate(format(formatDate(new Date()), "MM/dd/yyyy"));
    setCurrentStatus("submitted,approved");
    setReset(true);
    setCurrentUsers([]);
    setCurrentTerritories([]);
    dispatch(clearReports());
    dispatch(resetFilters());
    setCsvData({ data: [], headers: [] });
  };

  const handleGetReport = () => {
    const filterObject = {
      dateRange: `${fromDate} - ${toDate}`,
      user: currentUsers,
      territory: currentTerritories,
      orderStatuses: currentStatus,
      channel: currentChannel,
    };
    dispatch(getOrderVariantSummaryReport(filterObject));
    setHasGenerated(true);
  };

  useEffect(() => {
    dispatch(setFiltersOpen({ open: false }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useNoFetch(dispatch);

  useEffect(() => {
    dispatch(clearReports());
    dispatch(resetFilters());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>RTA | Report Finance Tracker</title>
      </Helmet>
      <Container className={classes.mainWrapper}>
        <div className={classes.titleBar}>
          <Typography className={classes.titleText}>
            Finance Tracker Report
          </Typography>
          <div className={classes.configButtons}>
            <div className={classes.innerConfigDiv}>
              <CSVLink
                data={data}
                headers={headers}
                filename="finance_tracker.csv"
              >
                <Button
                  className={classes.largeButton}
                  variant="contained"
                  color="secondary"
                  startIcon={<GetAppIcon />}
                  disabled={data.length === 0}
                >
                  EXPORT REPORT
                </Button>
              </CSVLink>
            </div>
          </div>
        </div>
        <br />
        <div className={classes.searchComponentsWide}>
          <DatePicker
            fullWidth
            style={{ marginTop: 0 }}
            color="secondary"
            className={classes.queryField}
            disableToolbar
            variant="inline"
            format="MM/dd/yyyy"
            margin="normal"
            id="reportFromDate"
            label="Order From Date"
            value={fromDate}
            onChange={(value) =>
              setFromDate(format(formatDate(value), "MM/dd/yyyy"))
            }
          />

          <DatePicker
            fullWidth
            style={{ marginTop: 0 }}
            color="secondary"
            className={classes.queryField}
            disableToolbar
            variant="inline"
            format="MM/dd/yyyy"
            margin="normal"
            id="reportToDate"
            label="Order To Date"
            value={toDate}
            onChange={(value) =>
              setToDate(format(formatDate(value), "MM/dd/yyyy"))
            }
          />

          <StatusSelector
            currentStatus={currentStatus}
            setCurrentStatus={setCurrentStatus}
            classes={classes}
            wide={true}
          />
        </div>
        <br />
        <div className={classes.searchComponents}>
          <UserAutoComplete
            classes={classes}
            handleChange={handleUser}
            reset={reset}
            setReset={setReset}
            filterType="report"
            id="report-user-complete"
          />
          <TerritoryAutoComplete
            classes={classes}
            handleChange={handleTerritory}
            reset={reset}
            setReset={setReset}
            filterType="report"
            type="inclusive"
            id="report-territory-complete"
            tw="max-w-[250px]"
          />
        </div>
        <br />
        <div style={{ width: "100%", textAlign: "center" }}>
          <Typography
            className={classes.headerText}
            style={{ marginRight: "10px" }}
          >
            Person Filters:
          </Typography>
        </div>
        <div className={classes.chipRow}>
          {currentUsers.length > 0 &&
            currentUsers.map((user) => (
              <Chip
                style={{ margin: "auto 2.5px" }}
                color="primary"
                key={user.id}
                label={user.name}
                onDelete={() => handleUserChipClick(user.id)}
              />
            ))}
        </div>
        <br />
        <div style={{ width: "100%", textAlign: "center" }}>
          <Typography
            className={classes.headerText}
            style={{ marginRight: "10px" }}
          >
            Territory Filters:
          </Typography>
        </div>
        <div className={classes.chipRow}>
          {currentTerritories.length > 0 &&
            currentTerritories.map((terr) => (
              <Chip
                style={{ margin: "auto 2.5px" }}
                color="primary"
                key={terr.id}
                label={terr.name}
                onDelete={() => handleTerritoryChipClick(terr.id)}
              />
            ))}
        </div>
        <br />
        <div className={classes.buttonRow}>
          <Button
            className={classes.largeButton}
            variant="contained"
            color="secondary"
            onClick={handleGetReport}
            style={{ marginRight: "20px" }}
          >
            GENERATE REPORT
          </Button>
          <Button
            className={classes.largeButton}
            variant="contained"
            color="secondary"
            onClick={handleReset}
          >
            CLEAR FILTERS
          </Button>
        </div>
        <br />
        <br />
        {isLoading && (
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <CircularProgress size={100} style={{ marginTop: "50px" }} />
          </div>
        )}
        {!isLoading &&
          report.length > 0 &&
          reportType === "finance-tracker" && (
            <ReportTable report={report} type="finance-tracker" />
          )}
      </Container>
    </>
  );
};

FinanceTracker.propTypes = {};

export default FinanceTracker;
