/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Box, Button } from "@mui/material";

import {
  createRFQ,
  refreshRFQs,
} from "../../../../redux/slices/planningTool/rfqSlice";
import AutocompleteItemFromProgram from "./AutocompleteItemFromProgram";
import AutocompleteProgram from "./AutocompleteProgram";

const ViewNew = ({ programIdState, itemIdState, classes }) => {
  const dispatch = useDispatch();
  const { programId: initProgramId, itemId: initItemId } = useSelector(
    (state) => state.rfq.RFQModalInit
  );
  const rfqsLoaded = useSelector((state) => state.rfq.rfqItems.length > 0);
  const [programId, setProgramId] = programIdState;
  const [itemId, setItemId] = itemIdState;

  const callback = () => {
    if (rfqsLoaded) {
      dispatch(refreshRFQs());
    }
  };

  const handleCreateRFQ = () => {
    dispatch(createRFQ(programId, itemId, callback));
  };

  useEffect(() => {
    if (initItemId) setItemId(initItemId);
    if (initProgramId) setProgramId(initProgramId);
  }, [initItemId, initProgramId, setItemId, setProgramId]);

  return (
    <>
      <div tw="flex flex-col gap-4">
        <div tw="max-w-lg">
          <AutocompleteProgram programIdState={programIdState} />
        </div>
        <div tw="max-w-2xl">
          {programId && (
            <AutocompleteItemFromProgram
              itemIdState={itemIdState}
              programIdState={programIdState}
            />
          )}
        </div>
      </div>
      <Box sx={{ mt: 6, display: "flex", justifyContent: "flex-end" }}>
        <Button
          classes={classes.button}
          variant="outlined"
          disabled={!programId || !itemId}
          onClick={() => handleCreateRFQ()}
        >
          Create RFQ
        </Button>
      </Box>
    </>
  );
};

export default ViewNew;
