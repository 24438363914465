import React from "react";

import CircularProgress from "@mui/material/CircularProgress";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";

import PropTypes from "prop-types";

const headCells = [
  { id: "id", label: "Supplier Id" },
  { id: "name", label: "Name" },
  { id: "externalId", label: "Supplier Code" },
  { id: "goesToRFP", label: "Goes to RFP" },
  { id: "isPurchaserSelect", label: "Is Select" },
  { id: "assignedUsers", label: "Assigned Users" },
];

const SupplierTableHead = ({ classes }) => (
  <TableHead>
    <TableRow>
      {headCells.map((headCell) => {
        return (
          <TableCell
            className={classes.headerText}
            key={headCell.id}
            align="left"
          >
            {headCell.label}
          </TableCell>
        );
      })}
    </TableRow>
  </TableHead>
);

SupplierTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
};

const useStyles = makeStyles((theme) => ({
  ...theme.global,
  clickableRow: {
    "&&:hover": {
      cursor: "pointer",
    },
  },
}));

const SupplierTable = ({ handleSupplierClick, suppliers, isLoading }) => {
  const classes = useStyles();

  return (
    <>
      <TableContainer style={{ maxHeight: "Calc(100vh - 275px)" }}>
        <Table stickyHeader className={classes.table}>
          <SupplierTableHead classes={classes} />
          <TableBody>
            {!isLoading && suppliers.length === 0 && (
              <TableRow>
                <TableCell align="left" colSpan={6}>
                  <Typography className={classes.headerText}>
                    {`There are currently no suppliers that match your search..`}
                  </Typography>
                </TableCell>
              </TableRow>
            )}
            {!isLoading &&
              suppliers.length > 0 &&
              suppliers.map((row) => (
                <TableRow
                  key={row.id}
                  hover
                  className={classes.clickableRow}
                  onClick={() => {
                    handleSupplierClick(row.id, "edit");
                  }}
                >
                  <TableCell align="left">{row.id}</TableCell>
                  <TableCell align="left">{row.name}</TableCell>
                  <TableCell align="left">{row.externalId}</TableCell>
                  <TableCell align="left">{row.goesToRFP}</TableCell>
                  <TableCell align="left">
                    {row.isPurchaserSelect ? "Yes" : "No"}
                  </TableCell>
                  <TableCell align="left">
                    {row.assignedUsers.map((user) => user.name).join(", ")}
                  </TableCell>
                </TableRow>
              ))}
            {isLoading && (
              <TableRow>
                <TableCell align="left" colSpan={6}>
                  <CircularProgress />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

SupplierTable.propTypes = {
  handleSupplierClick: PropTypes.func,
  suppliers: PropTypes.array,
  isLoading: PropTypes.bool.isRequired,
};

export default SupplierTable;
