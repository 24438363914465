/** @jsxImportSource @emotion/react */
import "twin.macro";

import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Button, Divider, List, ListItem, TextField } from "@mui/material";

import PropTypes from "prop-types";

import { setClear } from "../../redux/slices/filterSlice";
import BUAutoComplete from "../Utility/AutoCompleteFields/BUAutoComplete";
import BrandAutoComplete from "../Utility/AutoCompleteFields/BrandAutoComplete";
import InMarketMonthAutoComplete from "../Utility/AutoCompleteFields/InMarketMonthAutoComplete";
import InMarketYearAutoComplete from "../Utility/AutoCompleteFields/InMarketYearAutoComplete";
import ProgramTypeAutoComplete from "../Utility/AutoCompleteFields/ProgramTypeAutoComplete";
import SupplierAutoComplete from "../Utility/AutoCompleteFields/SupplierAutoComplete";
import UserAutoComplete from "../Utility/AutoCompleteFields/UserAutoComplete";
import ChannelSelector from "../Utility/Selectors/ChannelSelector";
import OrderWindowSelector from "../Utility/Selectors/OrderWindowSelector";
import { executeOnEnter } from "./utility";

const FiltersPlanningTool = ({
  classes,
  itemNumber,
  bindSequenceNum,
  programId,
  bindProgramId,
  programName,
  bindProgramName,
  workfrontId,
  bindWorkfrontId,
  couponBarcodeId,
  bindCouponBarcodeId,
  reset,
  setReset,
  handleFilters,
  handleSearch,
}) => {
  const dispatch = useDispatch();

  const isLoading = useSelector((state) => state.globalLoad.isLoading);

  const [orderWindow, setOrderWindow] = useState("");

  const handleOrderWindow = (value) => {
    setOrderWindow(value);
    handleFilters(value, "ocmIds", "planningTool");
  };

  useEffect(() => {
    if (reset) {
      setOrderWindow("");
      setReset(false);
    }
  }, [reset, setReset]);

  return (
    <>
      <List onKeyPress={executeOnEnter(handleSearch)}>
        <ListItem>
          <TextField
            color="secondary"
            fullWidth
            name="itemNumber"
            type="text"
            label="Sequence #"
            variant="outlined"
            size="small"
            value={itemNumber}
            {...bindSequenceNum}
            disabled={isLoading}
          />
        </ListItem>
        <ListItem>
          <TextField
            className={classes.queryField}
            color="secondary"
            fullWidth
            name="programId"
            type="text"
            label="Program ID"
            value={programId}
            {...bindProgramId}
            variant="outlined"
            size="small"
            disabled={isLoading}
          />
        </ListItem>
        <ListItem>
          <TextField
            className={classes.queryField}
            color="secondary"
            fullWidth
            name="programName"
            type="text"
            label="Program Name"
            value={programName}
            {...bindProgramName}
            variant="outlined"
            size="small"
            disabled={isLoading}
          />
        </ListItem>
        <ListItem>
          <TextField
            className={classes.queryField}
            color="secondary"
            fullWidth
            name="workfrontId"
            type="text"
            label="CPR/EPR ID"
            value={workfrontId}
            {...bindWorkfrontId}
            variant="outlined"
            size="small"
            disabled={isLoading}
          />
        </ListItem>
        <ListItem>
          <TextField
            className={classes.queryField}
            color="secondary"
            fullWidth
            name="couponbarcodeId"
            type="text"
            label="Coupon Barcode ID"
            value={couponBarcodeId}
            {...bindCouponBarcodeId}
            variant="outlined"
            size="small"
            disabled={isLoading}
          />
        </ListItem>
        <ListItem>
          <Button
            fullWidth
            className={classes.largeButton}
            variant="contained"
            color="secondary"
            onClick={handleSearch}
            disabled={isLoading}
          >
            SEARCH
          </Button>
        </ListItem>
        <ListItem />
        <Divider />
        <ListItem>
          <UserAutoComplete
            classes={classes}
            handleChange={handleFilters}
            reset={reset}
            setReset={setReset}
            filterType={"planningTool"}
            label="Creator"
          />
        </ListItem>
        <ListItem>
          <OrderWindowSelector
            tw="w-full"
            separatePast
            orderWindow={orderWindow}
            setOrderWindow={handleOrderWindow}
          />
        </ListItem>
        <ListItem>
          <InMarketMonthAutoComplete
            classes={classes}
            handleChange={handleFilters}
            reset={reset}
            setReset={setReset}
            filterType={"planningTool"}
          />
        </ListItem>
        <ListItem>
          <InMarketYearAutoComplete
            classes={classes}
            handleChange={handleFilters}
            reset={reset}
            setReset={setReset}
            filterType={"planningTool"}
          />
        </ListItem>
        <ListItem>
          <ProgramTypeAutoComplete
            classes={classes}
            handleChange={handleFilters}
            reset={reset}
            setReset={setReset}
            filterType={"planningTool"}
          />
        </ListItem>
        <ListItem>
          <BrandAutoComplete
            classes={classes}
            handleChange={handleFilters}
            reset={reset}
            setReset={setReset}
            filterType={"planningTool"}
          />
        </ListItem>
        <ListItem>
          <BUAutoComplete
            classes={classes}
            handleChange={handleFilters}
            reset={reset}
            setReset={setReset}
            filterType={"planningTool"}
          />
        </ListItem>
        <ListItem>
          <ChannelSelector
            classes={classes}
            handleChange={handleFilters}
            reset={reset}
            setReset={setReset}
            filterType={"planningTool"}
          />
        </ListItem>
        <ListItem>
          <SupplierAutoComplete
            classes={classes}
            handleChange={handleFilters}
            reset={reset}
            setReset={setReset}
            filterType={"planningTool"}
          />
        </ListItem>
        <ListItem>
          <Button
            fullWidth
            className={classes.largeButton}
            variant="contained"
            color="secondary"
            onClick={() => {
              dispatch(setClear());
            }}
            disabled={isLoading}
          >
            CLEAR FILTERS
          </Button>
        </ListItem>
      </List>
    </>
  );
};

FiltersPlanningTool.propTypes = {
  reset: PropTypes.bool.isRequired,
  setReset: PropTypes.func.isRequired,
  handleFilters: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  itemNumber: PropTypes.string.isRequired,
  bindSequenceNum: PropTypes.object.isRequired,
  workfrontId: PropTypes.string.isRequired,
  bindWorkfrontId: PropTypes.object.isRequired,
  programId: PropTypes.string.isRequired,
  bindProgramId: PropTypes.object.isRequired,
  couponBarcodeId: PropTypes.string.isRequired,
  bindCouponBarcodeId: PropTypes.object.isRequired,
};

export default FiltersPlanningTool;
