/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import { useRef, useState } from "react";

import { Close, CloudUploadOutlined } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  IconButton,
  Typography,
} from "@mui/material";

const FileUpload = ({ handleSubmit, accept }) => {
  const [selectedFile, setSelectedFile] = useState();
  const [dragging, setDragging] = useState(false);
  const [fileError, setFileError] = useState(null);
  const [loading, setLoading] = useState(false);
  const dragOverlayRef = useRef(null);

  const handleFile = (file) => {
    try {
      const fileExt = "." + file.name.split(".").at(-1);
      if (accept && !accept.includes(file.type) && !accept.includes(fileExt)) {
        throw `Invalid file type, please select from: ${accept.join(", ")}`;
      }
      setSelectedFile(file);
    } catch (error) {
      // Ignore other types of errors
      if (typeof error === "string") {
        setFileError(error);
      }
    }
  };

  const changeHandler = (event) => {
    handleFile(event.target.files[0]);
  };

  const handleSubmission = async () => {
    setLoading(true);
    await handleSubmit(selectedFile);
    setLoading(false);
  };

  const handle = (fn) => (e) => {
    e.preventDefault();
    e.stopPropagation();

    fn && fn(e);
  };

  const drop = (e) => {
    const { files } = e.dataTransfer;
    setDragging(false);

    if (files && files.length) {
      handleFile(files[0]);
    }
  };

  const dragEnter = (e) => {
    if (e.target !== dragOverlayRef.current) {
      setDragging(true);
    }
  };

  const dragLeave = (e) => {
    if (e.target === dragOverlayRef.current) {
      setDragging(false);
    }
  };

  return (
    <div
      onDragOver={handle()}
      onDrop={handle(drop)}
      onDragEnter={handle(dragEnter)}
      onDragLeave={handle(dragLeave)}
      css={tw`
        relative flex flex-col items-center px-4 py-12
        bg-white border-2 border-gray-300 border-dashed rounded-md`}
    >
      {!selectedFile ? (
        <>
          <div tw="flex items-center space-x-2">
            <CloudUploadOutlined />
            <Typography tw="font-medium text-gray-600">
              Drop a file to upload, or{" "}
              <label
                htmlFor="fileUpload"
                tw="text-blue-600 underline cursor-pointer"
              >
                browse
              </label>
            </Typography>
          </div>
          {fileError && (
            <Typography tw="block text-center text-red-400 pt-4">
              {fileError}
            </Typography>
          )}
        </>
      ) : (
        <>
          <Typography tw="text-gray-400">File Selected</Typography>
          <div tw="flex space-x-4 items-center">
            <IconButton
              role="button"
              onClick={handle(() => setSelectedFile(null))}
              size="large"
            >
              <Close fontSize="small" />
            </IconButton>
            <Typography tw="text-lg">{selectedFile.name}</Typography>
            <Button
              variant="outlined"
              onClick={handleSubmission}
              disabled={loading}
              endIcon={loading && <CircularProgress size={14} />}
            >
              Submit
            </Button>
          </div>
        </>
      )}
      <input
        type="file"
        id="fileUpload"
        name="fileUpload"
        tw="hidden"
        accept={accept?.join(",")}
        onChange={changeHandler}
      />
      {dragging && (
        <div
          ref={dragOverlayRef}
          css={tw`
            absolute inset-0
            flex items-center justify-center
            rounded-md
            bg-gray-300
            text-gray-100
            text-2xl
            `}
        >
          <h5>Drop files here.</h5>
        </div>
      )}
    </div>
  );
};

export default FileUpload;
