import { createSlice } from "@reduxjs/toolkit";

import {
  axiosGet,
  axiosGetWithNext,
  axiosPatch,
  axiosPost,
} from "../../../api/axiosCalls";
import { setError } from "../errorSlice";
import { setSorted } from "../filterSlice";
import {
  resetStepperValue,
  setIsStepper,
  startGlobalLoad,
  stopGlobalLoad,
  updateStepperValue,
} from "../globalLoadSlice";
import {
  setFailure as patchFailure,
  setIsLoading as patchLoading,
  patchSuccess,
} from "../patchOrderSlice";
import {
  buildItemProgramQuery,
  buildItemQuery,
  buildVariantAllocationRequest,
} from "./helpers";
import { mapItemReport, mapItems } from "./maps";

let initialState = {
  isLoading: false,
  isNextLoading: false,
  isUpdateLoading: false,
  orderType: null,
  itemsPerPage: 20,
  totalPages: null,
  pagesLoaded: 0,
  nextLink: null,
  items: [],
  selectedItems: [],
  itemReport: [],
  currentItem: null,
  hasUpdated: false,
  triggerCSVDownload: false,
  error: null,
  modalItemId: null,
};

const typeMap = {
  "from-inventory": "inventory",
  "on-demand": "onDemand",
  "pre-order": "pre-order",
  all: "allItems",
};

const loadingFailed = (state, action) => {
  const { error } = action.payload;
  state.isLoading = false;
  state.isUpdateLoading = false;
  state.error = error;
};

const itemSlice = createSlice({
  name: "item",
  initialState,
  reducers: {
    setModalItemId(state, action) {
      const id = action.payload;
      state.modalItemId = id;
    },
    setIsLoading(state) {
      state.isLoading = true;
    },
    setIsUpdateLoading(state) {
      state.isUpdateLoading = true;
    },
    setNextIsLoading(state) {
      state.isNextLoading = true;
    },
    setTotalPages(state, action) {
      const { pageCount } = action.payload;
      state.totalPages = pageCount;
    },
    getItemsSuccess(state, action) {
      const { orderType, items, nextLink } = action.payload;
      state.orderType = orderType;
      state.pagesLoaded += 1;
      state.nextLink = nextLink;
      state.items = [...items];
      state.isLoading = false;
      state.error = null;
    },
    getNextItemsSuccess(state, action) {
      const { items, nextLink } = action.payload;
      let currentItems = [...state.items];
      let updatedItems = currentItems.concat(items);
      state.pagesLoaded += 1;
      state.nextLink = nextLink;
      state.items = updatedItems;
      state.isNextLoading = false;
      state.error = null;
    },
    getItemSuccess(state, action) {
      const { item, setUpdated } = action.payload;
      state.currentItem = item;
      state.isLoading = false;
      state.isUpdateLoading = false;
      if (setUpdated) {
        state.hasUpdated = true;
      }
      state.error = null;
    },
    getItemReportSuccess(state, action) {
      const { items } = action.payload;
      state.itemReport = items;
      state.triggerCSVDownload = true;
      state.error = null;
    },
    setTriggerCSVFalse(state) {
      state.triggerCSVDownload = false;
    },
    clearItemReport(state) {
      state.itemReport = [];
    },
    clearNextLink(state) {
      state.nextLink = null;
    },
    updateItemSelection(state, action) {
      const { selectedItems } = action.payload;
      state.selectedItems = selectedItems;
    },
    clearItems(state) {
      state.items = [];
    },
    clearItemSelection(state) {
      state.selectedItems = [];
    },
    clearCurrentItem(state) {
      state.currentItem = null;
    },
    setHasUpdated(state, action) {
      const { value } = action.payload;
      state.hasUpdated = value;
    },
    resetItems(state) {
      state.isLoading = false;
      state.isUpdateLoading = false;
      state.isNextLoading = false;
      state.orderType = null;
      state.itemsPerPage = 20;
      state.totalPages = null;
      state.pagesLoaded = 0;
      state.nextLink = null;
      state.items = [];
      state.selectedItems = [];
      state.currentItem = null;
      state.hasUpdated = false;
      state.error = null;
    },
    setFailure: loadingFailed,
  },
});

export const {
  setModalItemId,
  setIsLoading,
  setIsUpdateLoading,
  setNextIsLoading,
  setTotalPages,
  getItemsSuccess,
  getNextItemsSuccess,
  getItemSuccess,
  updateItemsSuccess,
  clearNextLink,
  updateItemSelection,
  getItemReportSuccess,
  setTriggerCSVFalse,
  clearItemReport,
  clearItems,
  clearItemSelection,
  clearCurrentItem,
  setHasUpdated,
  resetItems,
  setFailure,
} = itemSlice.actions;

export default itemSlice.reducer;

export const fetchFilteredItems = (filterObject) => async (dispatch) => {
  try {
    dispatch(setIsLoading());
    dispatch(startGlobalLoad());
    const queryString = buildItemQuery(filterObject);
    const response = await axiosGetWithNext(queryString);
    if (response.error) throw response.error;
    const mappedData = mapItems(response.data.data);
    dispatch(
      getItemsSuccess({
        orderType: typeMap[filterObject.orderType],
        items: mappedData,
        nextLink: response.data.nextLink,
      })
    );
    dispatch(stopGlobalLoad());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Items" }));
    dispatch(stopGlobalLoad());
  }
};

export const fetchNextFilteredItems = (url) => async (dispatch) => {
  try {
    dispatch(setNextIsLoading());
    dispatch(clearNextLink());
    dispatch(startGlobalLoad());
    const response = await axiosGetWithNext(url);
    if (response.error) throw response.error;
    const mappedData = mapItems(response.data.data);
    dispatch(
      getNextItemsSuccess({
        items: mappedData,
        nextLink: response.data.nextLink,
      })
    );
    dispatch(stopGlobalLoad());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Items" }));
    dispatch(stopGlobalLoad());
  }
};

export const fetchSingleItem = (id) => async (dispatch) => {
  try {
    dispatch(setIsLoading());
    dispatch(startGlobalLoad());
    const response = await axiosGet(`/api/items/${id}`);
    if (response.error) throw response.error;
    let mappedData = mapItems([response.data])[0];
    dispatch(getItemSuccess({ item: mappedData, setUpdated: false }));
    dispatch(stopGlobalLoad());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Items" }));
    dispatch(stopGlobalLoad());
  }
};

export const fetchItemReport = (filterObject, type) => async (dispatch) => {
  try {
    dispatch(
      setIsStepper({
        stepBool: true,
        stepTitle: "Generating Item Report",
      })
    );

    const itemProgramQuery = filterObject.ocmIds && type === "pdf";
    const mapFunction = itemProgramQuery ? mapItemReport : mapItems;
    let itemArray = [];
    let stepValue = 10;

    const queryString = itemProgramQuery
      ? buildItemProgramQuery(filterObject)
      : buildItemQuery(filterObject);
    let initialResponse = await axiosGetWithNext(queryString);

    if (initialResponse.error) throw initialResponse.error;
    let initialMappedData = mapFunction(initialResponse.data.data);
    itemArray = itemArray.concat(initialMappedData);

    let nextLink = initialResponse.data.nextLink;
    dispatch(updateStepperValue({ value: stepValue }));

    if (nextLink) {
      const pageSize = initialResponse.data.data.length;
      let fetchCount =
        Math.ceil(initialResponse.data.totalEntries / pageSize) - 1;
      stepValue = parseFloat((90 / fetchCount).toFixed(2));

      for (let i = 0; i < fetchCount; i++) {
        let nextResponse = await axiosGetWithNext(nextLink);
        if (nextResponse.error) throw nextResponse.error;
        nextLink = nextResponse.data.nextLink;
        let mappedData = mapFunction(nextResponse.data.data);
        itemArray = itemArray.concat(mappedData);
        dispatch(updateStepperValue({ value: stepValue }));
      }
    } else {
      dispatch(updateStepperValue({ value: 90 }));
    }

    dispatch(resetStepperValue());
    if (type === "pdf") {
      dispatch(
        updateItemSelection({ selectedItems: itemArray.map((i) => i.id) })
      );
      dispatch(getItemsSuccess({ items: itemArray }));
    } else {
      dispatch(getItemReportSuccess({ items: itemArray }));
    }
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Item Reports" }));
    dispatch(resetStepperValue());
  }
};

export const updateItemSupplier = (id, supplierId) => async (dispatch) => {
  try {
    dispatch(patchLoading());
    const postData = {
      data: {
        supplier_id: supplierId,
      },
    };
    const response = await axiosPost(
      `/api/items/${id}/update-supplier`,
      postData
    );
    if (response.error) throw response.error;
    dispatch(setSorted());
    dispatch(patchSuccess());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(patchFailure({ error: err.toString() }));
    dispatch(
      setError({ error: err.toString(), source: "Item Supplier Update" })
    );
  }
};

export const createOrUpdateVariantAllocation =
  (id, variantId, qty, territoryId, subStateId, itemId) => async (dispatch) => {
    try {
      dispatch(patchLoading());
      dispatch(setIsUpdateLoading());
      const data = buildVariantAllocationRequest(
        qty,
        variantId,
        territoryId,
        subStateId
      );
      let response;
      if (id) {
        response = await axiosPatch(`/api/variant-allocations/${id}`, data);
      } else {
        response = await axiosPost(`/api/variant-allocations`, data);
      }
      if (response.error) throw response.error;
      const itemResponse = await axiosGet(`/api/items/${itemId}`);
      if (itemResponse.error) throw itemResponse.error;
      const mappedData = mapItems([itemResponse.data])[0];
      dispatch(getItemSuccess({ item: mappedData, setUpdated: true }));
      dispatch(patchSuccess());
    } catch (err) {
      dispatch(setFailure({ error: err.toString() }));
      dispatch(patchFailure({ error: err.toString() }));
      dispatch(setError({ error: err.toString(), source: "Items" }));
    }
  };
