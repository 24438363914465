/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Close } from "@mui/icons-material";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";

import _ from "lodash";
import { axiosPut } from "src/api/axiosCalls";
import OrderVariantList from "src/components/Purchasing/OrderVariantList";
import { StyledButton } from "src/components/StyledComponents";
import { fetchPOItemRollups } from "src/redux/slices/purchasing/purchaseOrderSlice";

import { OrderVariant } from "@models/OrderVariant";

const MarkAsTransferredModal = ({
  itemRollups,
  handleClose,
}: {
  itemRollups: any;
  handleClose: () => void;
}) => {
  const dispatch = useDispatch();
  const currentFilters = useSelector((state: any) => state.filters);

  const orderVariantIdsByVariant = _(itemRollups)
    .groupBy("itemNumber")
    .mapValues((irs) => irs.flatMap((ir) => ir.orderVariantIds))
    .value();

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [selectedOvs, setSelectedOvs] = useState<OrderVariant[]>([]);

  const handleMarkAsTransferred = async () => {
    setLoading(true);
    setSuccess(false);
    setError(null);
    const ovIdArray = _.map(selectedOvs, "id");
    const response = await axiosPut("/api/order-variants/mark-as-transferred", {
      ov_ids: ovIdArray,
    });
    if (!response.error) {
      dispatch(fetchPOItemRollups(currentFilters));
      setSuccess(true);
      setLoading(false);
    } else {
      setError(response.error);
      setLoading(false);
    }
  };

  return (
    <Dialog open maxWidth="lg">
      <DialogTitle tw="flex items-center justify-between">
        Choose Orders to Mark as Transferred
        <IconButton edge="end" onClick={handleClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {Object.entries(orderVariantIdsByVariant).map(
          ([itemNumber, orderVariantIds]) => (
            <OrderVariantList
              key={itemNumber}
              singleItemRollup={itemRollups.find(
                (ir) => ir.itemNumber === itemNumber
              )}
              orderVariantIds={orderVariantIds}
              selectedOvs={selectedOvs}
              setSelectedOvs={setSelectedOvs}
            />
          )
        )}
      </DialogContent>
      {error && <DialogContent>{error}</DialogContent>}
      {!success && !error && (
        <DialogActions>
          <StyledButton
            cta
            onClick={handleMarkAsTransferred}
            loading={loading}
            disabled={selectedOvs.length === 0}
          >
            Mark as Transferred
          </StyledButton>
        </DialogActions>
      )}
      {success && (
        <>
          <DialogActions>
            <div tw="mr-4">Success</div>
            <StyledButton
              cta
              onClick={handleClose}
              loading={loading}
              disabled={selectedOvs.length === 0}
            >
              Close
            </StyledButton>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default MarkAsTransferredModal;
