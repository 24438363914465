import { buildFilters } from "../../../api/apiFunctions";

export const buildOrderSet = (type, territoryId, channel, programId) => {
  let formattedType =
    type === "inventory"
      ? "from-inventory"
      : type === "onDemand"
      ? "on-demand"
      : "pre-order";

  let requestData = {
    data: {
      type: "order-set",
      attributes: {
        type: formattedType,
        channel: channel,
        "all-regions": !territoryId,
      },
    },
  };
  let relationships = {};
  if (programId) {
    relationships.program = {
      data: programId
        ? {
            type: "program",
            id: programId,
          }
        : null,
    };
  }
  if (territoryId) {
    relationships.territory = {
      data: territoryId
        ? {
            type: "territory",
            id: territoryId,
          }
        : null,
    };
  }
  if (Object.keys(relationships).length > 0) {
    requestData.data.relationships = relationships;
  }
  return requestData;
};

export const buildOrderSetVariantPost = (id, variant) => {
  return {
    data: {
      type: "order-set-variant",
      relationships: {
        "order-set": {
          data: id
            ? {
                type: "order-set",
                id: id,
              }
            : null,
        },
        variant: {
          data: variant
            ? {
                type: "variant",
                id: variant,
              }
            : null,
        },
      },
    },
  };
};

export const buildOrder = (id, addressId, type) => {
  return {
    data: {
      type: "order",
      attributes: {
        type: type,
      },
      relationships: {
        address: {
          data: addressId
            ? {
                type: "address",
                id: addressId,
              }
            : null,
        },
        "order-set": {
          data: id
            ? {
                type: "order-set",
                id: id,
              }
            : null,
        },
      },
    },
  };
};

export const buildOrderVariantPatch = (id, qty) => {
  return {
    data: {
      type: "order-variant",
      id: id,
      attributes: {
        qty: qty,
      },
    },
  };
};

/* ---------- Query Builders ---------- */
export const buildOrderHistoryQuery = (filterObject) => {
  const sortMap = {
    id: "id",
    address: "address-name",
    state: "state",
    orderDate: "submitted-at",
    shipDate: "ship-date",
    status: "status",
    brand: "brand",
  };
  let sortString = `sort=${filterObject.sortOrder === "desc" ? "-" : ""}${
    sortMap[filterObject.sortOrderBy]
  }`;
  return buildFilters(
    filterObject,
    "",
    sortString,
    "/api/orders",
    "history-orders"
  );
};

export const buildOrderVariantHistoryQuery = (filterObject) => {
  const sortMap = {
    itemNumber: "item-number",
    address: "address-name",
    itemType: "item-type-description",
    orderNum: "order-id",
    orderDate: "order-submitted-at",
    shipDate: "order-shipped-at",
    inMarketMonth: "in-market-date",
    brand: "item-brand",
    orderType: "order-type",
    businessUnit: "business-unit",
    addressName: "address-name",
  };
  let sortString = filterObject.sortOrder
    ? `sort=${filterObject.sortOrder === "desc" ? "-" : ""}${
        sortMap[filterObject.sortOrderBy]
      }`
    : "";

  return buildFilters(
    filterObject,
    "",
    sortString,
    "/api/order-variants",
    "history-items"
  );
};

export const buildOrderSetHistoryQuery = (filterObject) => {
  const sortMap = {
    user: "user-name",
    state: "address-state",
    program: "program-name",
    orderDate: "submitted-at",
  };
  let sortString = `sort=${filterObject.sortOrder === "desc" ? "-" : ""}${
    sortMap[filterObject.sortOrderBy]
  }`;
  return buildFilters(
    filterObject,
    "",
    sortString,
    "/api/order-sets",
    "order-set-history"
  );
};

export const buildOrderSetVariantHistoryQuery = (filterObject) => {
  const sortMap = {
    itemNumber: "item-number",
    program: "program-name",
    itemType: "item-type-description",
    user: "user-name",
    orderDate: "order-set-submitted-at",
  };
  let sortString = `sort=${filterObject.sortOrder === "desc" ? "-" : ""}${
    sortMap[filterObject.sortOrderBy]
  }`;
  return buildFilters(
    filterObject,
    "",
    sortString,
    "/api/order-set-variant-summaries",
    "order-set-variants"
  );
};

export const buildOrderVariantQuery = (filterObject) => {
  const sortMap = {
    itemNumber: "item-number",
    program: "program-name",
    itemType: "item-type-description",
    user: "user-name",
    orderDate: "order-submitted-at",
  };
  let sortString = `sort=${filterObject.sortOrder === "desc" ? "-" : ""}${
    sortMap[filterObject.sortOrderBy]
  }`;
  return buildFilters(
    filterObject,
    "",
    sortString,
    "/api/order-variants",
    "order-variants-report"
  );
};
