import addDays from "date-fns/addDays";

import { createSlice } from "@reduxjs/toolkit";

import {
  axiosDelete,
  axiosGet,
  axiosGetWithNext,
  axiosPatch,
  axiosPost,
} from "../../../api/axiosCalls";
import { setError } from "../errorSlice";
import {
  resetStepperValue,
  setIsStepper,
  startGlobalLoad,
  stopGlobalLoad,
  updateStepperValue,
} from "../globalLoadSlice";
import {
  setFailure as patchFailure,
  setIsLoading as patchLoading,
  patchSuccess,
} from "../patchOrderSlice";
import { updateValues } from "../suppliers/supplierSlice";
import {
  buildBidPatch,
  buildRequestForPrice,
  buildRequestForPricePatch,
  buildRollupQuery,
  buildSendBidPost,
} from "./helpers";
import { mapBids, mapItemRollups, mapRFP } from "./maps";

let initialState = {
  isLoading: false,
  isNextLoading: false,
  isUpdateLoading: false,
  nextPage: null,
  nextLink: null,
  rfpItems: [],
  selectedRFPItem: null,
  currentBidPrice: null,
  currentBidNote: "",
  rfpRollupReport: [],
  triggerCSVDownload: false,
  currentRFP: {
    id: null,
    status: null,
    dueDate: null,
    inMarketDate: null,
    bids: [],
    program: null,
    brand: null,
    itemType: null,
    itemDescription: null,
    itemNumber: null,
    projectNum: null,
    totalQty: null,
    supplierNote: "",
    itemSpec: null,
    imgUrlThumb: null,
    imgUrlLg: null,
  },
  error: null,
};

const resetCurrentRFP = (state) => {
  state.currentRFP.id = null;
  state.currentRFP.status = null;
  state.currentRFP.dueDate = null;
  state.currentRFP.inMarketDate = null;
  state.currentRFP.bids = [];
  state.currentRFP.program = null;
  state.currentRFP.brand = null;
  state.currentRFP.itemType = null;
  state.currentRFP.itemDescription = null;
  state.currentRFP.itemNumber = null;
  state.currentRFP.projectNum = null;
  state.currentRFP.totalQty = null;
  state.currentRFP.supplierNote = "";
  state.currentRFP.itemSpec = null;
  state.currentRFP.imgUrlThumb = null;
  state.currentRFP.imgUrlLg = null;
};

const loadingFailed = (state, action) => {
  const { error } = action.payload;
  state.isLoading = false;
  state.error = error;
};

const rfpSlice = createSlice({
  name: "rfpSlice",
  initialState,
  reducers: {
    setIsLoading(state) {
      state.isLoading = true;
    },
    setNextIsLoading(state) {
      state.isNextLoading = true;
    },
    setUpdateLoading(state) {
      state.isUpdateLoading = true;
    },
    getRFPItemsSuccess(state, action) {
      const { rfpItems, nextLink } = action.payload;
      state.nextPage = nextLink ? true : false;
      state.nextLink = nextLink;
      state.rfpItems = [...rfpItems];
      state.isLoading = false;
      state.error = null;
    },
    getNextRFPItemsSuccess(state, action) {
      const { rfpItems, nextLink } = action.payload;
      state.nextPage = nextLink ? true : false;
      state.nextLink = nextLink;
      state.rfpItems = state.rfpItems.concat(rfpItems);
      state.isNextLoading = false;
      state.error = null;
    },
    getSingleRFPSuccess(state, action) {
      const { rfp } = action.payload;
      state.currentRFP.id = rfp.id;
      state.currentRFP.status = rfp.status;
      state.currentRFP.dueDate = rfp.dueDate;
      state.currentRFP.inMarketDate = rfp.inMarketDate;
      state.currentRFP.bids = rfp.bids;
      state.currentRFP.program = rfp.program;
      state.currentRFP.brand = rfp.brand;
      state.currentRFP.itemType = rfp.itemType;
      state.currentRFP.itemDescription = rfp.itemDescription;
      state.currentRFP.itemNumber = rfp.itemNumber;
      state.currentRFP.projectNum = rfp.projectNum;
      state.currentRFP.totalQty = rfp.totalQty;
      state.currentRFP.supplierNote = rfp.supplierNote;
      state.currentRFP.itemSpec = rfp.itemSpec;
      state.currentRFP.imgUrlThumb = rfp.imgUrlThumb;
      state.currentRFP.imgUrlLg = rfp.imgUrlLg;
      state.isLoading = false;
      state.isUpdateLoading = false;
      state.error = null;
    },
    getRFPRollupReportSuccess(state, action) {
      const { rfpItems } = action.payload;
      state.rfpRollupReport = rfpItems;
      state.triggerCSVDownload = true;
      state.error = null;
    },
    setTriggerCSVFalse(state) {
      state.triggerCSVDownload = false;
    },
    clearRFPRollupReport(state) {
      state.rfpRollupReport = [];
    },
    setSelectedRFPItem(state, action) {
      const { itemId } = action.payload;
      state.selectedRFPItem = itemId;
    },
    updateSuccessful(state, action) {
      const { field, value } = action.payload;
      if (field) {
        state.currentRFP[field] = value;
      }
      state.isUpdateLoading = false;
      state.error = null;
    },
    updateBids(state, action) {
      const { bids } = action.payload;
      state.currentRFP.bids = bids;
    },
    updateBid(state, action) {
      const { bid } = action.payload;
      const updatedBids = state.currentRFP.bids.map((b) => {
        if (b.id === bid.id) {
          return { ...bid };
        } else return { ...b };
      });
      state.currentRFP.bids = updatedBids;
    },
    updateCurrentBidPrice(state, action) {
      const { price } = action.payload;
      state.currentBidPrice = price;
    },
    updateCurrentBidNote(state, action) {
      const { note } = action.payload;
      state.currentBidNote = note;
    },
    clearCurrentBidData(state) {
      state.currentBidPrice = null;
      state.currentBidNote = "";
    },
    award(state, action) {
      const { bid } = action.payload;
      const updatedBids = state.currentRFP.bids.map((b) => {
        if (b.id === bid.id) {
          return { ...bid };
        } else return { ...b };
      });
      state.currentRFP.bids = updatedBids;
      state.currentRFP.status = "awarded";
    },
    deleteSuccess(state) {
      state.isLoading = false;
      state.error = null;
      resetCurrentRFP(state);
    },
    resetRFP(state) {
      state.isLoading = false;
      state.rfpItems = [];
      state.rfpRollupReport = [];
      state.triggerCSVDownload = false;
      state.error = null;
      resetCurrentRFP(state);
    },
    setFailure: loadingFailed,
  },
});

export const {
  setIsLoading,
  setNextIsLoading,
  setUpdateLoading,
  getRFPItemsSuccess,
  getNextRFPItemsSuccess,
  getSingleRFPSuccess,
  getRFPRollupReportSuccess,
  setTriggerCSVFalse,
  clearRFPRollupReport,
  setSelectedRFPItem,
  updateBids,
  updateBid,
  updateCurrentBidNote,
  updateCurrentBidPrice,
  clearCurrentBidData,
  award,
  deleteSuccess,
  updateSuccessful,
  resetRFP,
  setFailure,
} = rfpSlice.actions;

export default rfpSlice.reducer;

/* ---------- Rfq rollup fetches ---------- */
export const fetchRFPRollupItems = (filterObject) => async (dispatch) => {
  try {
    dispatch(setIsLoading());
    dispatch(startGlobalLoad());
    const queryString = buildRollupQuery(filterObject, true);
    const response = await axiosGetWithNext(queryString);
    if (response.error) throw response.error;
    const mappedData = mapItemRollups(response.data.data);
    dispatch(
      getRFPItemsSuccess({
        rfpItems: mappedData,
        nextLink: response.data.nextLink,
      })
    );
    dispatch(stopGlobalLoad());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Request For Prices" }));
    dispatch(stopGlobalLoad());
  }
};

export const fetchNextRFPRollupItems = (url) => async (dispatch) => {
  try {
    dispatch(setNextIsLoading());
    dispatch(startGlobalLoad());
    const response = await axiosGetWithNext(url);
    if (response.error) throw response.error;
    let mappedData = mapItemRollups(response.data.data);
    dispatch(
      getNextRFPItemsSuccess({
        rfpItems: mappedData,
        nextLink: response.data.nextLink,
      })
    );
    dispatch(stopGlobalLoad());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Request For Prices" }));
    dispatch(stopGlobalLoad());
  }
};

export const fetchRFPRollupReport = (filterObject) => async (dispatch) => {
  try {
    dispatch(
      setIsStepper({
        stepBool: true,
        stepTitle: "Generating Request for Price Rollup Report",
      })
    );
    let rfpArray = [];
    let stepValue = 10;

    let queryString = buildRollupQuery(filterObject, true);
    let initialResponse = await axiosGetWithNext(queryString);
    if (initialResponse.error) throw initialResponse.error;

    let initialMappedData = mapItemRollups(initialResponse.data.data);
    rfpArray = rfpArray.concat(initialMappedData);

    let nextLink = initialResponse.data.nextLink;
    dispatch(updateStepperValue({ value: stepValue }));

    if (nextLink) {
      const pageSize = initialResponse.data.data.length;
      let fetchCount =
        Math.ceil(initialResponse.data.totalEntries / pageSize) - 1;
      stepValue = parseFloat((90 / fetchCount).toFixed(2));

      for (let i = 0; i < fetchCount; i++) {
        let nextResponse = await axiosGetWithNext(nextLink);
        if (nextResponse.error) {
          throw nextResponse.error;
        }
        nextLink = nextResponse.data.nextLink;
        let mappedItems = mapItemRollups(nextResponse.data.data);
        rfpArray = rfpArray.concat(mappedItems);
        dispatch(updateStepperValue({ value: stepValue }));
      }
    } else {
      dispatch(updateStepperValue({ value: 90 }));
    }

    dispatch(resetStepperValue());
    dispatch(getRFPRollupReportSuccess({ rfpItems: rfpArray }));
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "PO Rollup Reports" }));
    dispatch(resetStepperValue());
  }
};
/* ---------- ---------- ---------- */

export const fetchSingleRFP = (id) => async (dispatch) => {
  try {
    dispatch(setIsLoading());
    dispatch(startGlobalLoad());
    const response = await axiosGet(`/api/request-for-prices/${id}`);
    if (response.error) throw response.error;
    let mappedData = mapRFP(response.data);
    dispatch(getSingleRFPSuccess({ rfp: mappedData }));
    dispatch(stopGlobalLoad());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Request For Prices" }));
    dispatch(stopGlobalLoad());
  }
};

/* ---------- Rfq create, update and delete ---------- */
export const createNewRFP = (item, program, idArray) => async (dispatch) => {
  try {
    dispatch(setIsLoading());
    dispatch(startGlobalLoad());
    const dueDate = addDays(new Date(), 5);
    const postData = buildRequestForPrice(item, program, dueDate, idArray);
    const response = await axiosPost("/api/request-for-prices", postData);
    if (response.error) throw response.error;
    let mappedData = mapRFP(response.data);
    dispatch(getSingleRFPSuccess({ rfp: mappedData }));
    dispatch(stopGlobalLoad());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Request For Prices" }));
    dispatch(stopGlobalLoad());
  }
};

export const deleteDraftRFP = (id, status) => async (dispatch) => {
  try {
    dispatch(setIsLoading());
    dispatch(startGlobalLoad());
    if (status !== "draft") {
      throw new Error(
        "Cannot delete a Request for Price unless it is in draft status"
      );
    }
    const response = await axiosDelete(`/api/request-for-prices/${id}`, {});
    if (response.error) throw response.error;
    dispatch(deleteSuccess());
    dispatch(stopGlobalLoad());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Request For Prices" }));
    dispatch(stopGlobalLoad());
  }
};

export const updateRequestForPrice = (id, field, value) => async (dispatch) => {
  const fieldMap = {
    note: "supplierNote",
    "due-date": "dueDate",
    "in-market-date": "inMarketDate",
  };
  try {
    dispatch(patchLoading());
    dispatch(setUpdateLoading());
    const patchData = buildRequestForPricePatch(id, field, value);
    const response = await axiosPatch(
      `/api/request-for-prices/${id}`,
      patchData
    );
    if (response.error) throw response.error;
    dispatch(updateSuccessful({ field: fieldMap[field], value: value }));
    dispatch(patchSuccess());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(patchFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Request For Prices" }));
  }
};

export const completeCurrentRFP = (id) => async (dispatch) => {
  try {
    dispatch(patchLoading());
    dispatch(setUpdateLoading());
    const response = await axiosPost(
      `/api/request-for-prices/${id}/complete`,
      {}
    );
    if (response.error) throw response.error;
    dispatch(
      updateValues({
        values: [{ key: "awardedRFP", value: -1 }],
      })
    );
    dispatch(updateSuccessful({ field: null, value: null }));
    dispatch(patchSuccess());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(patchFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Request For Prices" }));
  }
};

export const cancelCurrentRFP = (id) => async (dispatch) => {
  try {
    dispatch(patchLoading());
    dispatch(setUpdateLoading());
    const response = await axiosPost(
      `/api/request-for-prices/${id}/cancel`,
      {}
    );
    if (response.error) throw response.error;
    dispatch(updateSuccessful({ field: null, value: null }));
    dispatch(patchSuccess());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(patchFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Request For Prices" }));
  }
};
/* ---------- ---------- ---------- */

/* ---------- RFP Bid Calls ---------- */
export const sendBids = (idArray, rfpId) => async (dispatch) => {
  try {
    dispatch(patchLoading());
    dispatch(setUpdateLoading());
    const postData = buildSendBidPost(rfpId, idArray);
    const response = await axiosPost(
      `/api/request-for-prices/${rfpId}/send`,
      postData
    );
    if (response.error) throw response.error;
    let mappedData = mapRFP(response.data);
    dispatch(getSingleRFPSuccess({ rfp: mappedData }));
    dispatch(patchSuccess());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(patchFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Request For Prices" }));
  }
};

export const resendBid = (id) => async (dispatch) => {
  try {
    dispatch(patchLoading());
    dispatch(setUpdateLoading());
    const response = await axiosPost(`/api/bids/${id}/resend`, {});
    if (response.error) throw response.error;
    const bid = mapBids([response.data]);
    dispatch(updateBid({ bid: bid[0] }));
    dispatch(updateSuccessful({ field: null, value: null }));
    dispatch(patchSuccess());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(patchFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Request For Prices" }));
  }
};

export const acceptCurrentBid = (id, price, note) => async (dispatch) => {
  try {
    dispatch(patchLoading());
    dispatch(setUpdateLoading());
    const postData = {
      data: {
        price: price,
        note: note,
      },
    };
    const response = await axiosPost(`/api/bids/${id}/accept`, postData);
    if (response.error) throw response.error;
    const bid = mapBids([response.data]);
    dispatch(updateBid({ bid: bid }));
    dispatch(
      updateValues({
        values: [
          { key: "newRFP", value: -1 },
          { key: "inProgressRFP", value: 1 },
        ],
      })
    );
    dispatch(updateSuccessful({ field: null, value: null }));
    dispatch(patchSuccess());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(patchFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Request For Prices" }));
  }
};

export const updateCurrentBid = (id, price, note) => async (dispatch) => {
  try {
    dispatch(patchLoading());
    dispatch(setUpdateLoading());
    const patchData = buildBidPatch(id, price, note);
    const response = await axiosPatch(`/api/bids/${id}`, patchData);
    if (response.error) throw response.error;
    const bid = mapBids([response.data]);
    dispatch(updateBid({ bid: bid[0] }));
    dispatch(updateSuccessful({ field: null, value: null }));
    dispatch(patchSuccess());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(patchFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Request For Prices" }));
  }
};

export const awardCurrentBid = (id) => async (dispatch) => {
  try {
    dispatch(patchLoading());
    dispatch(setUpdateLoading());
    const response = await axiosPost(`/api/bids/${id}/award`, {});
    if (response.error) throw response.error;
    const bid = mapBids([response.data]);
    dispatch(award({ bid: bid }));
    dispatch(updateSuccessful({ field: null, value: null }));
    dispatch(patchSuccess());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(patchFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Request For Prices" }));
  }
};

export const declineCurrentBid = (id) => async (dispatch) => {
  try {
    dispatch(patchLoading());
    dispatch(setUpdateLoading());
    const response = await axiosPost(`/api/bids/${id}/decline`, {});
    if (response.error) throw response.error;
    dispatch(resetRFP());
    dispatch(
      updateValues({
        values: [{ key: "newRFP", value: -1 }],
      })
    );
    dispatch(updateSuccessful({ field: null, value: null }));
    dispatch(patchSuccess());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(patchFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Request For Prices" }));
  }
};
/* ---------- ---------- ---------- */
