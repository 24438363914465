import axios from "axios";
import { Cloudinary, Util } from "cloudinary-core";

import { imgFileTypes } from "./constants";

const cloudinary = new Cloudinary({ cloud_name: "brandhub" });

export const openUploadWidget = (options, callback) => {
  const scOptions = Util.withSnakeCaseKeys(options);
  window.cloudinary.openUploadWidget(scOptions, callback);
};

export const downloadFileCloudinary = async (fileId, fileName) => {
  const isImg = imgFileTypes.includes(fileName.split(".").at(-1));
  let url = cloudinary.url(fileId, {
    resource_type: isImg ? "image" : "raw",
  });

  // Locally we get back an http url from cloudinary, and we always want to GET the https version of that url
  if (!url.includes("https")) {
    url = url.replace("http", "https");
  }
  axios
    .get(url, {
      responseType: "blob",
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${fileName}`); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
};
