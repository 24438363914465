import { jsonToCSV } from "react-papaparse";

import _ from "lodash";
import fp from "lodash/fp";
import { userReportColumns } from "src/components/Settings/UserTable";
import { Column } from "src/components/SmartTable/SmartTable";
import {
  formatDateString,
  formatMoneyString,
} from "src/utility/utilityFunctions";

import { budgetTableColumns } from "@features/budgets";
import { Order } from "@models/Order";
import { OrderVariant } from "@models/OrderVariant";
import { CsvSchema, csvDataFromSchema, formatCsvBlob } from "@utils/csv";

import { Report } from "./types";

const commaSeparated = (value: any[]) => value.join(", ");
const yesNo = (value: boolean) => (value ? "Yes" : "No");

const generateCsvStringArray = (csvSchema: CsvSchema) => (reportData: any[]) =>
  csvDataFromSchema(csvSchema, reportData);

const fromTableSchema = (tableSchema: Column<any>[]): CsvSchema =>
  tableSchema.map((col) => ({
    ...col,
    f: col.renderText ?? col.render,
  })) as any;

const csvReport = (csvSchema: CsvSchema): Report => ({
  fileExtension: "csv",
  onComplete: generateCsvStringArray(csvSchema),
  onDownload: (data: any[][]) => {
    // parse json data to csv text
    const csvData = jsonToCSV(data);
    return formatCsvBlob(csvData);
  },
});

const reportDefinitions = () => {
  const reports = {
    "budgets-summary": csvReport(fromTableSchema(budgetTableColumns)),
    "order-set-wrapup": {
      ...csvReport([]),
      onComplete: (data: Order[]) =>
        generateCsvStringArray([
          { id: "order.user.name", label: "Ordered By" },
          { id: "order.address.state.code", label: "Market" },
          {
            id: "variant.item.brands",
            label: "Brand",
            f: fp.compose(commaSeparated, fp.map("name")),
          },
          {
            id: "variant.item.brands",
            label: "BU",
            f: fp.compose(commaSeparated, fp.map("businessUnit.name")),
          },
          { id: "variant.item.type", label: "Item Type" },
          {
            id: "inMarketDate",
            label: "Month in Market",
            f: (d) => (d ? formatDateString(d) : "---"),
          },
          {
            id: "estimatedCost",
            label: "Estimated Cost",
            f: formatMoneyString,
          },
          { id: "qty", label: "Qty Ordered" },
          { id: "variant.variantSku", label: "Seq #" },
          { id: "order.programNames", label: "Program", f: commaSeparated },
          { id: "order.type", label: "Order Type" },
          { id: "isRush", label: "Rush Order", f: yesNo },
        ])(
          _(data)
            .flatMap("orderVariants")
            .sortBy("variant.variantSku")
            .value() as OrderVariant[]
        ),
    },
    "users-summary": csvReport(fromTableSchema(userReportColumns)),
    // "items-pdf": {
    //   fileExtension: "pdf",
    //   onDownload: (data) =>
    //     pdf(
    //       ProgramItemPDF({
    //         programName: "Item Report",
    //         items: chunk(data, 3),
    //       })
    //     ).toBlob(),
    // },
  };

  return reports;
};

export default reportDefinitions;
