import { useContext } from "react";
import { useParams } from "react-router-dom";

import OrderSetIdContext from "./OrderSetIDContext";

export default function useOrderSetId() {
  const { orderSetId: paramId } = useParams();
  const ctxId = useContext(OrderSetIdContext);

  const orderSetId = paramId ?? ctxId;
  if (!orderSetId) {
    throw new Error("No order set id found");
  }
  return orderSetId;
}
