/** @jsxImportSource @emotion/react */
import "twin.macro";

import React from "react";
import { useSelector } from "react-redux";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

const OverrideListTable = () => {
  const { overrides } = useSelector((state) => state.complianceRules);
  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Item Number</TableCell>
            <TableCell>Brand</TableCell>
            <TableCell>Item Type</TableCell>
            <TableCell>Rule Type</TableCell>
            <TableCell>Rule Description</TableCell>
            <TableCell>State</TableCell>
            <TableCell>Date Added</TableCell>
            <TableCell>Overriden By</TableCell>
            <TableCell>Notes</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {overrides.map((override) => (
            <TableRow key={override.id}>
              <TableCell>{override.itemNumber}</TableCell>
              <TableCell>{override.brandNames}</TableCell>
              <TableCell>{override.itemType}</TableCell>
              <TableCell>{override.ruleType}</TableCell>
              <TableCell>{override.ruleDescription}</TableCell>
              <TableCell>{override.stateCode}</TableCell>
              <TableCell>{override.insertedAt}</TableCell>
              <TableCell>{override.userName}</TableCell>
              <TableCell>{override.note}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default OverrideListTable;
